import { gql } from "@apollo/client";
import { SupplierDetailQuery } from "../../generated/graphql";
import { ArrayElement } from "../../types/ArrayElement";

export type SupplierDetailAccount = ArrayElement<SupplierDetailQuery["inventory_account"]>;

const SUPPLIER_DETAIL_QUERY = gql`
  query SupplierDetail($supplier_id: Int!) {
    inventory_account(where: { id: { _eq: $supplier_id } }) {
      id
      local_name
      account_description
      website
      logo_url
      email
      telephone_number
      account_roles {
        role {
          role_name
          id
        }
      }
      account_locations(where: { parent_location_id: { _is_null: true } }) {
        id
        name: location_name
        parent_location_id
        child_locations {
          name: location_name
          address {
            name
          }
          child_locations {
            name: location_name
            address {
              name
            }
            child_locations {
              name: location_name
              address {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default SUPPLIER_DETAIL_QUERY;
