// Allows you to pick the qty and see the total price as a sense check
// Multi component with inputs and button

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import SimpleRangedIntegerInput from "../SimpleRangedIntegerInput";
import { useAuth0 } from "../../../react-auth0-spa";
import SHIPMENT_QUERY from "../../../graphql/queries/shipment";
import ADD_SHIPMENT_LINE_MUTATION from "../../../graphql/mutations/addShipmentLine";
import {
  AddShipmentLineMutation,
  AddShipmentLineMutationVariables,
  ShipmentQuery,
  ShipmentQueryVariables,
  ProductStockQuery,
  ProductStockQueryVariables,
} from "../../../generated/graphql";
import { ClientOrderLineQueryItem } from "../../../graphql/queries/clientOrderLines";
import PRODUCT_STOCK_QUERY, { ProductStockQueryItem } from "../../../graphql/queries/productStock";
import ProductStockSelector from "../../ProductStockSelector";
import { SelectItem } from "../../../types/SelectItem";
import { ValueType } from "react-select";
import SHIPMENT_LINES_BY_SHIPMENT_ID_QUERY from "../../../graphql/queries/shipmentsLinesByShipmentId";
import SelectStockOwnerOption from "../../ProductStockSelector/SelectOwnerOption";
import { useToasts } from "react-toast-notifications";
import { handleApolloError } from "../../../utils/handleApolloError";

interface AddShipmentLineSelectorProps {
  shipment_id: number;
  shipment: ShipmentQuery["inventory_shipment_by_pk"];
  client_order_line: ClientOrderLineQueryItem;
  hide_callback: Dispatch<SetStateAction<boolean>>;
}

interface AddShipmentLineFormFields {
  qty: number;
  selectedProductStock: SelectItem<ProductStockQueryItem> | null;
}

const AddShipmentLineSelector = ({
  shipment,
  client_order_line,
  shipment_id,
}: AddShipmentLineSelectorProps) => {
  const { addToast } = useToasts();
  const [inputs, setInputs] = useState<AddShipmentLineFormFields>({
    qty: 0,
    selectedProductStock: null,
  });
  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  const { data: dataShipment } = useQuery<ShipmentQuery, ShipmentQueryVariables>(SHIPMENT_QUERY, {
    variables: { shipment_id: Number(shipment_id) },
    fetchPolicy: "network-only",
    onError: error => handleApolloError(error, addToast),
  });

  const { data: productStockResponse } = useQuery<ProductStockQuery, ProductStockQueryVariables>(
    PRODUCT_STOCK_QUERY,
    {
      fetchPolicy: "network-only",
      onError: error => handleApolloError(error, addToast),
      variables: {
        product_id: client_order_line.product!.id,
        location_id: shipment?.source_location_id,
        statusFilter: ["available"],
      },
    }
  );

  const [addShippingLine] = useMutation<AddShipmentLineMutation, AddShipmentLineMutationVariables>(
    ADD_SHIPMENT_LINE_MUTATION,
    {
      onCompleted() {
        addToast("Shipping Line Added Successfully", { appearance: "success" });
        // reset form
        setInputs({ qty: 0, selectedProductStock: null });
      },
      onError: error => handleApolloError(error, addToast),
    }
  );

  // if selected stock is less than inputted qty, reduce inputted qty
  useEffect(() => {
    if (inputs.selectedProductStock && inputs.qty > inputs.selectedProductStock.data.qty) {
      setInputs({ ...inputs, qty: inputs.selectedProductStock.data.qty });
    }
  }, [inputs.selectedProductStock]);

  const validateQty = (qty: number) => {
    const outstandingQty = client_order_line.outstanding_qty!;
    const selectedProductStockAvailableQty = inputs?.selectedProductStock?.data.qty;

    const max =
      selectedProductStockAvailableQty && selectedProductStockAvailableQty < outstandingQty
        ? selectedProductStockAvailableQty
        : outstandingQty;

    return { isValid: qty <= max && qty > 0, max };
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.name === "qty" && event.target.value !== "") {
      const { isValid } = validateQty(Number(event.target.value));
      if (!isValid)
        return addToast("Cannot go beyond minimum/maximum qty", { appearance: "error" });
    }

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAddShippingLine = async () => {
    if (!dataShipment?.inventory_shipment_by_pk || !inputs.qty || !inputs.selectedProductStock) {
      return addToast("Please fill in all fields", { appearance: "error" });
    }

    const updatedSourceStockQty = inputs.selectedProductStock!.data.qty - Number(inputs.qty);

    if (updatedSourceStockQty < 0) {
      return addToast("An error occurred, source stock cannot go below 0", { appearance: "error" });
    }

    await addShippingLine({
      variables: {
        client_order_line_id: client_order_line.id,
        product_id: client_order_line!.product!.id,
        source_location_id: shipment!.source_location_id,
        destination_location_id: shipment!.destination_location_id,
        source_product_stock_id: inputs.selectedProductStock!.data.id,
        source_product_stock_account_id: inputs.selectedProductStock!.data.account_id,
        updated_source_product_stock_qty: updatedSourceStockQty,
        reserved_qty: inputs.qty,
        user_id: user_id,
        shipment_id: shipment?.id,
      },
      refetchQueries: [
        { query: SHIPMENT_QUERY, variables: { shipment_id: Number(shipment_id) } },
        {
          query: SHIPMENT_LINES_BY_SHIPMENT_ID_QUERY,
          variables: {
            shipment_id: shipment?.id,
          },
        },
        {
          query: PRODUCT_STOCK_QUERY,
          variables: { product_id: client_order_line.product!.id },
        },
      ],
    });
  };

  const { max } = validateQty(inputs.qty);

  return (
    <>
      <td className="w-2/12 p-4">
        <div className="flex -ml-3">
          <SimpleRangedIntegerInput
            label=""
            placeholder={"Enter Qty"}
            change_handler={handleInputChange}
            id_ref="qty"
            minQty={0}
            maxQty={max} // max qty is the lesser of the max of selected source stock or the outstanding qty of selected client order line
            value_ref={inputs.qty}
            isMarginDisabled
          />
        </div>
      </td>
      <td className="w-4/12 p-4">
        <div className="flex -ml-3">
          <ProductStockSelector
            isLabelHidden
            value={inputs.selectedProductStock}
            noOptionsMessage="Please select a client order line"
            product_stock={productStockResponse?.inventory_product_stock || []}
            handleSelect={(
              selectedProductStock: ValueType<SelectItem<ProductStockQueryItem>, false>
            ) => {
              setInputs({ ...inputs, selectedProductStock: selectedProductStock || null });
            }}
            customOption={SelectStockOwnerOption}
          />
        </div>
      </td>
      <td className="w-2/12 p-4">
        <div className="flex -ml-3 -mb-3">
          <button
            className="flex-shrink-0 mb-3 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
            type="button"
            onClick={handleAddShippingLine}
          >
            Add
          </button>
        </div>
      </td>
    </>
  );
};
export default AddShipmentLineSelector;
