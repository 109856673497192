import { gql } from "@apollo/client";

const SHIPMENT_LINES_BY_SHIPMENT_ID_QUERY = gql`
  query ShipmentLinesByShipmentId($shipment_id: Int!) {
    inventory_shipment_line(where: { shipment_id: { _eq: $shipment_id } }) {
      shipment_id
      reserved_qty
      client_order_line_id
      client_order_line {
        client_order {
          client_account {
            id
            local_name
            international_name
          }
        }
      }
      source_product_stock_id
      product_stock {
        id
        account {
          id
          local_name
          international_name
        }
        product {
          id
          base_product {
            base_product_name
            image_url
          }
          case_qty
          weight_grams
          length_mm
          width_mm
          height_mm
        }
        qty
      }
      id
      shipment_line_status_id
      shipment_line_status {
        shipment_line_status_name
      }
    }
  }
`;

export default SHIPMENT_LINES_BY_SHIPMENT_ID_QUERY;
