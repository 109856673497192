import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL
    ? process.env.REACT_APP_SENTRY_URL
    : window.REACT_APP_SENTRY_URL,
});

JavascriptTimeAgo.locale(en);

const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={
      process.env.REACT_APP_AUTH0_DOMAIN
        ? process.env.REACT_APP_AUTH0_DOMAIN
        : window.REACT_APP_AUTH0_DOMAIN
    }
    client_id={
      process.env.REACT_APP_AUTH0_CLIENTID
        ? process.env.REACT_APP_AUTH0_CLIENTID
        : window.REACT_APP_AUTH0_CLIENTID
    }
    audience={
      process.env.REACT_APP_AUTH0_AUDIENCE
        ? process.env.REACT_APP_AUTH0_AUDIENCE
        : window.REACT_APP_AUTH0_AUDIENCE
    }
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
