import { gql } from "@apollo/client";
import { ClientOrderLinesQuery } from "../../generated/graphql";
import { ArrayElement } from "../../types/ArrayElement";

export type ClientOrderLineQueryItem = ArrayElement<
  ClientOrderLinesQuery["inventory_client_order_line"]
>;

const CLIENT_ORDER_LINES_QUERY = gql`
  query ClientOrderLines(
    $destination_location_id: Int
    $source_location_id: Int
    $statusFilter: [String!]
    $minQty: Int
  ) {
    inventory_client_order_line(
      where: {
        client_order: { destination: { id: { _eq: $destination_location_id } } }
        product: {
          product_stocks: {
            location_id: { _eq: $source_location_id }
            qty: { _gte: $minQty }
            stock_status: { stock_status_name: { _eq: "available" } }
          }
        }
        client_order_line_status: { line_status_name: { _in: $statusFilter } }
      }
    ) {
      id
      client_order_line_type_id
      product {
        id
        base_product {
          id
          base_product_name
          image_url
          base_product_description
        }
        case_qty
      }
      client_order_line_type {
        line_type_name
      }
      requested_qty
      unit_price
      product_pricing_id
      product_pricing {
        unit_price
        currency_code
      }

      client_order_id
      outstanding_qty
      client_order {
        account_id
        location_id
        destination {
          name: location_name
        }
        client_account {
          international_name
          local_name
        }
      }
    }
  }
`;

export default CLIENT_ORDER_LINES_QUERY;
