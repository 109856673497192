import React from "react";
import { useQuery } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import TitleHeading from "../../components/TitleHeading";
import { Link } from "react-router-dom";
import StatusBadge from "../../components/StatusBadge";
import SearchBar from "../../components/search/SearchBar";
import SUPPLIERS_QUERY from "../../graphql/queries/suppliers";
import { SuppliersQuery, SuppliersQueryVariables } from "../../generated/graphql";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";
import MenuSection from "../../components/layout/MenuSection";

const Suppliers = () => {
  const { loading, data } = useQuery<SuppliersQuery, SuppliersQueryVariables>(SUPPLIERS_QUERY, {
    fetchPolicy: "network-only",
  });

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const routeToUpdatePage = (supplier_id: number) => {
    openInNewTab(`supplier/edit/${supplier_id}`);
  };

  if (data == null || loading) return null;

  return (
    <>
      <MenuSection selectedMainMenu="Suppliers" selectedSubMenu="all-suppliers" />

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />

            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Suppliers" />
                  <SimpleLinkButton
                    label="Add Supplier"
                    keyboard_shortcut="n"
                    new_tab
                    url="/suppliers/add"
                    id_ref="add_supplier"
                  />
                  <SimpleLinkButton
                    label="Add Supplier Order"
                    keyboard_shortcut="c"
                    new_tab
                    url="/supplier-orders/add"
                    id_ref="add_supplier_order"
                  />
                  <SimpleLinkButton
                    label="Add Supplier Quote"
                    keyboard_shortcut="q"
                    new_tab
                    url="/supplier-quotes/add"
                    id_ref="add_supplier_quote"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>id</TH>
                        <TH>name</TH>
                        <TH>description</TH>
                        <TH>roles</TH>
                        <TH>status</TH>
                      </TableHead>
                      <tbody>
                        {data.inventory_account.map(inventory_account => {
                          return (
                            <TR key={inventory_account.id}>
                              <TD>{inventory_account.id}</TD>
                              <TD width={2} isEmphasised>
                                <Link to={`/supplier/${inventory_account.id}`}>
                                  {inventory_account.local_name}
                                </Link>
                              </TD>
                              <TD width={3}>{inventory_account.account_description}</TD>
                              <TD width={3}>
                                <span className="text-xs">
                                  {inventory_account.account_roles
                                    .map(account_role => {
                                      return account_role.role.role_name;
                                    })
                                    .join(", ")}
                                </span>
                              </TD>
                              <TD width={3}>
                                <StatusBadge
                                  label={inventory_account.account_status.account_status_name}
                                />
                                <button
                                  className="float-right"
                                  onClick={() => routeToUpdatePage(inventory_account.id)}
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Suppliers;
