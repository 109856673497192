import { gql } from "@apollo/client";

const ADD_SHIPMENT = gql`
  mutation AddShipment(
    $courier_service_id: Int!
    $arrival_time: timestamptz
    $destination_location_id: Int = 10
    $estimated_arrival_days: Int = 10
    $shipped_time: timestamptz
    $estimated_shipped_time: timestamptz
    $source_location_id: Int = 10
    $user_id: String!
  ) {
    insert_inventory_shipment_one(
      object: {
        arrival_time: $arrival_time
        courier_service_id: $courier_service_id
        destination_location_id: $destination_location_id
        estimated_shipped_time: $estimated_shipped_time
        estimated_arrival_days: $estimated_arrival_days
        shipment_status_id: 1
        shipped_time: $shipped_time
        source_location_id: $source_location_id
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;

export default ADD_SHIPMENT;
