import React from "react";
import { useQuery } from "@apollo/client";
import { useAuth0 } from "../../../react-auth0-spa";
import CLIENT_ORDER_LINES_QUERY from "../../../graphql/queries/clientOrderLines";
import {
  ClientOrderLinesQuery,
  ClientOrderLinesQueryVariables,
  ShipmentQuery,
} from "../../../generated/graphql";
import AddShipmentLineRow from "./AddShipmentLineRow";
import Table, { TableHead, TD, TH, TR } from "../../../components/Table";

interface AddShipmentLineTableProps {
  shipment: ShipmentQuery["inventory_shipment_by_pk"];
}

const AddShipmentLineTable = ({ shipment }: AddShipmentLineTableProps) => {
  const { user } = useAuth0();
  const user_id = user ? user.sub : null;
  const source_location_id = shipment?.source_location_id;

  const { loading: loading_client_order_lines, data: client_order_lines } = useQuery<
    ClientOrderLinesQuery,
    ClientOrderLinesQueryVariables
  >(CLIENT_ORDER_LINES_QUERY, {
    variables: {
      source_location_id,
      minQty: 1,
      statusFilter: ["new", "processing"],
    },
  });

  if (loading_client_order_lines || client_order_lines == null) return null;
  if (!shipment) return null;

  return (
    <>
      <h2 className="text-2xl mt-8  xl:text-2xl mb-4 text-gray-900 font-bold">
        Add Shipment Line from Client Order Line
      </h2>
      <form className="w-full flex flex-col lg:flex-row">
        <div className="w-full flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap">
          <Table id="table_shipment_lines">
            <TableHead>
              <TH>Line ID</TH>
              <TH>client</TH>
              <TH>product</TH>
              <TH>case qty</TH>
              <TH>requested qty</TH>
              <TH>outstanding qty</TH>
              <TH>destination</TH>
              <TH>shipment line qty</TH>
              <TH>product stock</TH>
              <TH></TH>
            </TableHead>

            <tbody>
              {client_order_lines.inventory_client_order_line.length === 0 ? (
                <TR>
                  <TD className="text-center" colSpan={9}>
                    No Matching Client Order Lines
                  </TD>
                </TR>
              ) : (
                client_order_lines.inventory_client_order_line.map(inventory_client_order_line => (
                  <AddShipmentLineRow
                    key={inventory_client_order_line.id}
                    client_order_line={inventory_client_order_line}
                    shipment={shipment}
                    user_id={user_id}
                  />
                ))
              )}
            </tbody>
          </Table>
        </div>
      </form>
    </>
  );
};

export default AddShipmentLineTable;
