import { gql } from "@apollo/client";

const LOCATIONS_QUERY = gql`
  query Locations {
    inventory_location {
      id
      name: location_name
    }
  }
`;

export default LOCATIONS_QUERY;
