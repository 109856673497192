import React, { useState } from "react";

const SimpleAccordion = ({ label, renderLabel, children, wrapperClassName }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={wrapperClassName}>
      <div className="flex px-1">
        {renderLabel ? (
          renderLabel()
        ) : (
          <h3 className="font-semibold mr-2 pl-1 text-gray-800">{label}</h3>
        )}
        <button
          className="focus:outline-none focus-visible:underline text-blue-700 text-sm font-semibold"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Collapse" : "Expand"}
        </button>
      </div>
      {isExpanded && <div>{children}</div>}
    </div>
  );
};

export default SimpleAccordion;
