import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/breakPoints";
import SideMenu from "../SideMenu";

const MenuWrapper = styled.div.attrs(() => ({
  className: "bg-gray-100 bg-opacity-25",
}))`
  width: 0px;
  @media (min-width: ${breakpoints.xl}) {
    width: 260px; // sidebar width
  }
`;

interface MenuSectionProps {
  selectedMainMenu: string;
  selectedSubMenu?: string;
}

const MenuSection = ({ selectedMainMenu, selectedSubMenu }: MenuSectionProps) => (
  <MenuWrapper>
    <div className="hidden xl:block">
      <SideMenu
        menuOpen={true}
        layout={"large"}
        selectedMainMenu={selectedMainMenu}
        selectedSubMenu={selectedSubMenu}
      />
    </div>
    <div className="block xl:hidden">
      <SideMenu
        menuOpen={false}
        layout={"mobile"}
        selectedMainMenu={selectedMainMenu}
        selectedSubMenu={selectedSubMenu}
      />
    </div>
  </MenuWrapper>
);

export default MenuSection;
