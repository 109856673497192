export const customReactSelectStyle = {
  input: (base: any) => ({
    ...base,
    padding: "7px",
  }),
  placeholder: (base: any) => ({
    ...base,
    padding: "7px",
  }),
  control: (base: any) => ({
    ...base,
    borderColor: "#e2e8f0",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    background: "#e2e8f0",
  }),
  singleValue: (base: any) => ({
    ...base,
    padding: "7px",
  }),
};
