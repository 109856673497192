import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import SideMenu from "../../components/SideMenu";
import TitleHeading from "../../components/TitleHeading";
import SearchBar from "../../components/search/SearchBar";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";

const Invoices = () => {
  const [showResult, setShowResult] = useState(false);
  const { getTokenSilently } = useAuth0();
  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    async function get_invoices() {
      try {
        setShowResult(false);
        const token = await getTokenSilently();

        const response = await fetch(
          process.env.REACT_APP_API_ORIGIN
            ? `${process.env.REACT_APP_API_ORIGIN}/xero-api/invoices`
            : `${window.REACT_APP_API_ORIGIN}/xero-api/invoices`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            mode: "cors",
            method: "GET",
          }
        );

        const responseData = await response.json();
        console.log("responseData");
        console.log(responseData);
        setInvoices(responseData.Invoices);
        setShowResult(true);
      } catch (error) {
        console.error(error);
      }
    }
    get_invoices();
  }, []);

  if (!showResult) return null;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="Invoices"
            selectedSubMenu="all-invoices"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="Invoices"
            selectedSubMenu="all-invoicess"
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Invoices" />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table className="w-full text-xs text-left">
                      <TableHead>
                        <TH>invoice id</TH>
                        <TH>contact name</TH>
                        <TH>reference</TH>
                        <TH>type</TH>
                        <TH>status</TH>
                        <TH>currency</TH>
                        <TH>subtotal</TH>
                        <TH>tax</TH>
                        <TH>total</TH>
                      </TableHead>

                      <tbody>
                        {invoices
                          .filter(x => x.Status !== "VOIDED")
                          .map(invoice => {
                            return (
                              <TR key={invoice.InvoiceID}>
                                <TD>
                                  {
                                    <a
                                      href={`/invoice/${invoice.InvoiceID}`}
                                      rel="noopener noreferrer"
                                    >
                                      {invoice.InvoiceID}
                                    </a>
                                  }
                                </TD>
                                <TD>{invoice.Contact.Name}</TD>
                                <TD>{invoice.Reference}</TD>
                                <TD>{invoice.Type}</TD>
                                <TD>{invoice.Status}</TD>
                                <TD>{invoice.CurrencyCode}</TD>
                                <TD>{invoice.SubTotal}</TD>
                                <TD>{invoice.TotalTax}</TD>
                                <TD>{invoice.Total}</TD>
                              </TR>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoices;
