import React, { useState, useEffect } from "react";
import _ from "lodash";

interface SimpleSelectProps<OptionType> {
  label: string;
  change_handler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  add_url?: string;
  refresh_handler?: () => void;
  id_ref: string;
  options: OptionType[];
  placeholder: string;
  required?: boolean;
  two_column?: boolean;
  selected_id: string | number | null;
  isDisabled?: boolean;
  name_func: (option: OptionType) => string;
}

const wrapperStyles = ({ two_column }: { two_column?: boolean }) =>
  ["w-full mb-2 md:mb-0", two_column && "md:w-1/2 mb-4 lg:mb-6"].join(" ");

const selectStyles = ({ isDisabled }: { isDisabled?: boolean }) =>
  [
    "block appearance-none w-full border py-3 px-4 pr-8 mb-3 rounded leading-tight",
    "focus:outline-none focus:bg-white focus:border-gray-500",
    isDisabled && "bg-gray-200",
  ].join(" ");

const SimpleSelect = <OptionType extends { id: string | number }>({
  label,
  change_handler,
  add_url,
  refresh_handler,
  id_ref,
  options,
  placeholder,
  required,
  two_column,
  selected_id,
  name_func,
  isDisabled,
}: SimpleSelectProps<OptionType>) => {
  const [value, setValue] = useState<string>("");
  useEffect(() => {
    setValue(selected_id ? `${selected_id}` : "");
  }, [options ? options.length : options, selected_id]);

  return (
    <div className={wrapperStyles({ two_column })}>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor={id_ref}
      >
        {label} {required && ` *`}
        {refresh_handler && (
          <button
            onClick={event => {
              event.preventDefault();
              refresh_handler();
            }}
          >
            <i className="fas fa-sync-alt mx-2"></i>
          </button>
        )}{" "}
        {add_url && (
          <a target="_blank" rel="noopener noreferrer" href={add_url}>
            <i className="fas fa-plus-circle"></i>
          </a>
        )}
      </label>
      <select
        disabled={isDisabled}
        onChange={event => {
          setValue(event.target.value);
          change_handler(event);
        }}
        required={required}
        className={`${value ? "text-gray-700" : "text-gray-500"} ${selectStyles({ isDisabled })}`}
        id={id_ref}
        name={id_ref}
      >
        <option className="bg-gray-200" value="">
          {placeholder}
        </option>
        {options &&
          options.map(option => {
            return selected_id && selected_id === option.id ? (
              <option selected value={option.id} key={option.id}>
                {name_func(option)}
              </option>
            ) : (
              <option value={option.id} key={option.id}>
                {name_func(option)}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default SimpleSelect;
