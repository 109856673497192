import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";

import SideMenu from "../../components/SideMenu";
import TitleHeading from "../../components/TitleHeading";
import SimpleSelect from "../../components/forms/SimpleSelect";
import SearchBar from "../../components/search/SearchBar";
import SimpleRangedIntegerInput from "../../components/forms/SimpleRangedIntegerInput";
import SimpleDateInput from "../../components/forms/SimpleDateInput";
import { useAuth0 } from "../../react-auth0-spa";
import ADD_SHIPMENT from "../../graphql/mutations/AddShipment";
import LOCATIONS_QUERY from "../../graphql/queries/locations";
import COURIER_SERVICE_QUERY from "../../graphql/queries/courierService";

import {
  LocationsQuery,
  AddShipmentMutation,
  AddShipmentMutationVariables,
  CourierServiceQuery,
} from "../../generated/graphql";
import { useToasts } from "react-toast-notifications";
import { handleApolloError } from "../../utils/handleApolloError";
import FieldError from "../../components/FieldError";

interface AddShipmentFormValues {
  courier_service_id: string;
  arrival_time: string;
  destination_location_id: string;
  source_location_id: string;
  estimated_shipped_time: string;
  estimated_arrival_days: string;
  shipped_time: string;
  user_id: string;
}

const validationSchema = Yup.object().shape({
  source_location_id: Yup.number()
    .required("Select a source")
    .nullable()
    .test("compareWithDestination", function (value) {
      const { destination_location_id } = this.parent;
      if (destination_location_id && destination_location_id === value) {
        return this.createError({ message: "Source cannot equal Destination" });
      }
      return true;
    }),
  destination_location_id: Yup.number()
    .nullable()
    .required("Select a destination")
    .test("compareWithSource", function (value) {
      const { source_location_id } = this.parent;
      if (source_location_id && source_location_id === value) {
        return this.createError({ message: "Destination cannot equal Source" });
      }
      return true;
    }),
  courier_service_id: Yup.number().required("Select a courier").nullable(),
  shipped_time: Yup.string(),
  arrival_time: Yup.string(),
  estmated_shipped_time: Yup.string(),
  estimated_arrival_days: Yup.number(),
});

const AddShipment = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [addShipment, { loading: mutationLoading }] = useMutation<
    AddShipmentMutation,
    AddShipmentMutationVariables
  >(ADD_SHIPMENT, {
    onError: error => handleApolloError(error, addToast),
    onCompleted(data) {
      addToast("Shipment Added", { appearance: "success" });
      history.push("/shipments/edit/" + data.insert_inventory_shipment_one?.id);
    },
  });

  const {
    loading: loading_locations,
    error: error_locations,
    data: locations,
    refetch: refreshLocations,
  } = useQuery<LocationsQuery>(LOCATIONS_QUERY, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loading_courierservice,
    error: error_courierservice,
    data: courierservice,
  } = useQuery<CourierServiceQuery>(COURIER_SERVICE_QUERY, {
    fetchPolicy: "network-only",
  });

  const onSubmit = (values: AddShipmentFormValues) => {
    addShipment({
      variables: {
        courier_service_id: Number(values.courier_service_id),
        source_location_id: Number(values.source_location_id),
        destination_location_id: Number(values.destination_location_id),
        estimated_shipped_time: values.estimated_shipped_time || undefined,
        estimated_arrival_days: values.estimated_arrival_days
          ? Number(values.estimated_arrival_days)
          : undefined,
        shipped_time: values.shipped_time || undefined,
        arrival_time: values.arrival_time || undefined,
        user_id: user_id,
      },
    });
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (courierservice == null || loading_courierservice) return null;
  if (locations == null || loading_locations) return null;

  const initialFormValues: AddShipmentFormValues = {
    courier_service_id: "",
    arrival_time: "",
    destination_location_id: "",
    source_location_id: "",
    estimated_shipped_time: "",
    estimated_arrival_days: "",
    shipped_time: "",
    user_id: user_id,
  };

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Shipment"
          selectedSubMenu="add-shipment"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Shipment"
          selectedSubMenu="add-shipment"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Shipments &gt; Add a new Shipment" />
                </div>
                <Formik
                  onSubmit={onSubmit}
                  initialValues={initialFormValues}
                  validationSchema={validationSchema}
                >
                  {({ values, handleChange, handleSubmit, touched, errors }) => {
                    return (
                      <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                        <div className="w-full lg:w-1/2 p-4 lg:p-0">
                          <div className="flex flex-wrap mb-2">
                            <SimpleSelect
                              selected_id={values.courier_service_id}
                              placeholder="Enter the Courier Service"
                              change_handler={handleChange}
                              required
                              label="Courier Service"
                              options={courierservice.inventory_courier_service}
                              id_ref="courier_service_id"
                              name_func={option => {
                                return option.name;
                              }}
                            />
                            {touched.courier_service_id && errors.courier_service_id && (
                              <FieldError>{errors.courier_service_id}</FieldError>
                            )}
                            {error_courierservice && (
                              <FieldError>{error_courierservice}</FieldError>
                            )}
                          </div>
                          <div className="flex flex-wrap mb-2">
                            <SimpleSelect
                              selected_id={values.source_location_id}
                              placeholder="Select the Source Location"
                              change_handler={handleChange}
                              required
                              refresh_handler={refreshLocations}
                              add_url="/locations/add"
                              label="Source Location"
                              options={locations.inventory_location}
                              id_ref="source_location_id"
                              name_func={option => {
                                return option.name;
                              }}
                            />
                            {touched.source_location_id && errors.source_location_id && (
                              <FieldError>{errors.source_location_id}</FieldError>
                            )}
                            {error_locations && <FieldError>{error_locations}</FieldError>}
                          </div>
                          <div className="flex flex-wrap mb-2">
                            <SimpleSelect
                              selected_id={values.destination_location_id}
                              required
                              placeholder="Select the Destination"
                              change_handler={handleChange}
                              refresh_handler={refreshLocations}
                              add_url="/locations/add"
                              label="Destination"
                              options={locations.inventory_location}
                              id_ref="destination_location_id"
                              name_func={option => {
                                return option.name;
                              }}
                            />
                            {touched.destination_location_id && errors.destination_location_id && (
                              <FieldError>{errors.destination_location_id}</FieldError>
                            )}
                          </div>

                          <div className="flex flex-wrap mb-2">
                            <SimpleDateInput
                              placeholder="Estimated Shipped Time"
                              change_handler={handleChange}
                              label="Shipped Timestamp"
                              id_ref="estimated_shipped_time"
                              value_ref={values.estimated_shipped_time}
                            />
                            {touched.estimated_shipped_time && errors.estimated_shipped_time && (
                              <FieldError>{errors.estimated_shipped_time}</FieldError>
                            )}
                          </div>

                          <div className="flex flex-wrap mb-2">
                            <SimpleDateInput
                              placeholder="Shipped Time"
                              change_handler={handleChange}
                              label="Actual Shipped Timestamp"
                              id_ref="shipped_time"
                              value_ref={values.shipped_time}
                            />
                            {touched.shipped_time && errors.shipped_time && (
                              <FieldError>{errors.shipped_time}</FieldError>
                            )}
                          </div>

                          <div className="flex flex-wrap mb-2">
                            <SimpleRangedIntegerInput
                              label="Estimated Arrival # Days"
                              placeholder="1"
                              change_handler={handleChange}
                              id_ref="estimated_arrival_days"
                              minQty={1}
                              maxQty={3000}
                              value_ref={values.estimated_arrival_days}
                            />
                            {touched.estimated_arrival_days && errors.estimated_arrival_days && (
                              <FieldError>{errors.estimated_arrival_days}</FieldError>
                            )}
                          </div>

                          <div className="flex flex-wrap mb-2">
                            <SimpleDateInput
                              placeholder="Arrival Time"
                              change_handler={handleChange}
                              label="Arrival Timestamp"
                              id_ref="arrival_time"
                              value_ref={values.arrival_time}
                            />
                            {touched.arrival_time && errors.arrival_time && (
                              <FieldError>{errors.arrival_time}</FieldError>
                            )}
                          </div>

                          <button
                            disabled={mutationLoading}
                            className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                            type="submit"
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShipment;
