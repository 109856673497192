import { gql } from "@apollo/client";

// 1. update shipment to status 4 (cancelled)
// 2. update all associated shipment_lines to status 4 (cancelled)
// 3. insert the updated product stocks (calculated using generateUpdatedStockOnCancellation.ts)

const CANCEL_SHIPMENT_MUTATION = gql`
  mutation CancelShipment(
    $shipment_id: Int!
    $updated_product_stocks: [inventory_product_stock_insert_input!]!
  ) {
    update_inventory_shipment_by_pk(
      pk_columns: { id: $shipment_id }
      _set: { shipment_status_id: 4 }
    ) {
      id
      shipment_status {
        id
        shipment_status_name
      }
    }
    update_inventory_shipment_line(
      where: { shipment_id: { _eq: $shipment_id } }
      _set: { shipment_line_status_id: 4 }
    ) {
      returning {
        id
        shipment_line_status {
          id
          name: shipment_line_status_name
        }
      }
    }
    insert_inventory_product_stock(
      objects: $updated_product_stocks
      on_conflict: { constraint: product_stock_pkey, update_columns: [qty, user_id] }
    ) {
      returning {
        id
        qty
        initial_qty
        stock_status {
          id
          name: stock_status_name
        }
        location {
          id
          location_name
        }
      }
    }
  }
`;

export default CANCEL_SHIPMENT_MUTATION;
