import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import TitleHeading from "../../components/TitleHeading";
import SearchBar from "../../components/search/SearchBar";
import SHIPMENTS_QUERY from "../../graphql/queries/shipments";
import { ShipmentsQuery, ShipmentsQueryVariables } from "../../generated/graphql";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";

const Shipments = () => {
  const { loading, data } = useQuery<ShipmentsQuery, ShipmentsQueryVariables>(SHIPMENTS_QUERY, {
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateShipment = (shipment_id: number) => {
    openInNewTab(`shipments/edit/${shipment_id}`);
  };

  if (data == null || loading) return null;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="Shipments"
            selectedSubMenu="all-shipments"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="Shipments"
            selectedSubMenu="all-shipments"
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Shipments" />
                  <SimpleLinkButton
                    label="Add new Shipment"
                    keyboard_shortcut="n"
                    new_tab
                    url="/shipments/add"
                    id_ref="add_shipment"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table textSize="text-xs">
                      <TableHead>
                        <TH>id</TH>
                        <TH>courier</TH>
                        <TH>created</TH>
                        <TH>estimated shipped</TH>
                        <TH>shipped</TH>
                        <TH>arrival</TH>
                        <TH>estimated arrival days</TH>
                        <TH>last_updated</TH>
                        <TH>status</TH>
                        <TH>source</TH>
                        <TH>destination</TH>
                        <TH>lines</TH>
                        <TH></TH>
                      </TableHead>

                      <tbody>
                        {data.inventory_shipment.map(inventory_shipment => {
                          return (
                            <TR key={inventory_shipment.id}>
                              <TD isEmphasised>
                                <Link to={`/shipments/edit/${inventory_shipment.id}`}>
                                  {inventory_shipment.id}
                                </Link>
                              </TD>
                              <TD isEmphasised>
                                {inventory_shipment.courier_service.courier_service_name}
                              </TD>

                              <TD>
                                {inventory_shipment.created_time
                                  ? inventory_shipment.created_time.substring(0, 10)
                                  : "TBD"}
                              </TD>
                              <TD>
                                {inventory_shipment.estimated_shipped_time
                                  ? inventory_shipment.estimated_shipped_time.substring(0, 10)
                                  : "TBD"}
                              </TD>
                              <TD>
                                {inventory_shipment.shipped_time
                                  ? inventory_shipment.shipped_time.substring(0, 10)
                                  : "TBD"}
                              </TD>
                              <TD>
                                {inventory_shipment.arrival_time
                                  ? inventory_shipment.arrival_time.substring(0, 10)
                                  : "TBD"}
                              </TD>
                              <TD>{inventory_shipment.estimated_arrival_days}</TD>
                              <TD>{inventory_shipment.last_updated.substring(0, 10)}</TD>
                              <TD>{inventory_shipment.shipment_status.shipment_status_name}</TD>
                              <TD>{inventory_shipment.source.location_name}</TD>
                              <TD>{inventory_shipment.destination.location_name}</TD>
                              <TD>
                                {inventory_shipment.shipment_lines_aggregate.aggregate?.count}
                              </TD>
                              <TD>
                                <Link
                                  rel="noopener noreferrer"
                                  to={`shipments/edit/${inventory_shipment.id}`}
                                  aria-label={`edit-shipment-${inventory_shipment.id}`}
                                >
                                  <i className="fas fa-edit"></i>
                                </Link>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipments;
