import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import SimpleTextInput from "./SimpleTextInput";
import SimpleSelect from "./SimpleSelect";
import SimpleButton from "./SimpleButton";
import { useAuth0 } from "../../react-auth0-spa";

const EditProductionorderForm = ({ order_id, setSuccessCode }) => {
  const [inputs, setInputs] = useState({ account_id: -1, location_id: -1 });

  const UPDATE_PRODUCTION_ORDER = gql`
    mutation UpdateProductionorder(
      $id: Int!
      $account_id: Int!
      $user_id: String!
      $location_id: Int!
      $production_order_reference: String!
    ) {
      update_inventory_production_order_by_pk(
        pk_columns: { id: $id }
        _set: {
          user_id: $user_id
          supplier_account_id: $account_id
          location_id: $location_id
          production_order_reference: $production_order_reference
        }
      ) {
        id
      }
    }
  `;

  const SUPPLIERS = gql`
    query GetSuppliers {
      inventory_account(where: { account_roles: { role: { id: { _eq: 2 } } } }) {
        id
        name: local_name
      }
    }
  `;

  const GET_PRODUCTION_ORDER = gql`
    query GetProductionorder($id: Int!) {
      inventory_production_order_by_pk(id: $id) {
        account_id: supplier_account_id
        location_id
        production_order_reference
      }
    }
  `;

  const [updateProductionorder, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_PRODUCTION_ORDER,
    {
      onCompleted() {
        setSuccessCode(200);
        setTimeout(() => setSuccessCode(0), 3000);
      },
    }
  );

  const LOCATIONS = gql`
    query GetLocations {
      inventory_location {
        id
        name: location_name
      }
    }
  `;

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    updateProductionorder({
      variables: {
        account_id: inputs.account_id,
        location_id: inputs.location_id,
        id: order_id,
        production_order_reference: inputs.production_order_reference,
        user_id: user_id,
      },
    });
  };

  const {
    loading_locations,
    error_locations,
    data: locations,
    refetch: refreshLocations,
  } = useQuery(LOCATIONS, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingSuppliers,
    error: errorSuppliers,
    data: dataSuppliers,
    refetch: refreshSuppliers,
  } = useQuery(SUPPLIERS, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingProductionorder,
    error: errorProductionorder,
    data: dataProductionorder,
  } = useQuery(GET_PRODUCTION_ORDER, {
    variables: { id: order_id },
    fetchPolicy: "network-only",
    onCompleted() {
      setInputs({
        account_id: dataProductionorder.inventory_production_order_by_pk.account_id,
        location_id: dataProductionorder.inventory_production_order_by_pk.location_id,
        production_order_reference:
          dataProductionorder.inventory_production_order_by_pk.production_order_reference,
      });
    },
  });

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (
    dataProductionorder == null ||
    loadingProductionorder ||
    locations == null ||
    loading_locations ||
    inputs === {} ||
    loadingSuppliers ||
    dataSuppliers == null
  )
    return null;

  return (
    <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
      <div className={`w-full flex -ml-6 -mt-3 px-3 -mb-4`}>
        <SimpleTextInput
          placeholder="Enter an Order Reference"
          value_ref={inputs.production_order_reference}
          required={true}
          change_handler={handleInputChange}
          label="Order Reference"
          id_ref="production_order_reference"
          two_column
        />

        <SimpleSelect
          add_url="/suppliers/add"
          change_handler={handleInputChange}
          placeholder="Select a Supplier"
          id_ref="account_id"
          label="Supplier"
          options={dataSuppliers.inventory_account}
          refresh_handler={refreshSuppliers}
          required
          selected_id={inputs.account_id}
          two_column
          name_func={option => {
            return option.name;
          }}
        />
        <SimpleSelect
          add_url="/locations/add"
          change_handler={handleInputChange}
          placeholder="Select a Location"
          id_ref="location_id"
          label="Destination"
          options={locations.inventory_location}
          refresh_handler={refreshLocations}
          required
          selected_id={inputs.location_id}
          two_column
          name_func={option => {
            return option.name;
          }}
        />
        <div className={`w-full mt-8 px-3 md:w-1/2 -mb-4`}>
          <SimpleButton label="Save" />
        </div>
      </div>
    </form>
  );
};
export default EditProductionorderForm;
