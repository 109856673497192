import { gql } from "@apollo/client";
import { ProductStockQuery } from "../../generated/graphql";
import { ArrayElement } from "../../types/ArrayElement";

export type ProductStockQueryItem = ArrayElement<ProductStockQuery["inventory_product_stock"]>;

const PRODUCT_STOCK_QUERY = gql`
  query ProductStock($product_id: Int!, $location_id: Int, $statusFilter: [String!]) {
    inventory_product_stock(
      where: {
        product_id: { _eq: $product_id }
        location_id: { _eq: $location_id }
        stock_status: { stock_status_name: { _in: $statusFilter } }
      }
    ) {
      id
      initial_qty
      qty
      stock_status {
        id
        stock_status_name
      }
      location {
        id
        location_name
      }
      product {
        id
        base_product {
          id
          base_product_name
        }
      }
      account_id
      account {
        id
        local_name
        international_name
      }
      source_client_invoice_line_id
      source_production_order_line_id
      source_shipment_line_id
    }
  }
`;

export default PRODUCT_STOCK_QUERY;
