import { gql } from "@apollo/client";

const COURIER_SERVICE_QUERY = gql`
  query CourierService {
    inventory_courier_service {
      id
      name: courier_service_name
    }
  }
`;

export default COURIER_SERVICE_QUERY;
