import React, { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "../../../react-auth0-spa";
import SHIPMENT_QUERY from "../../../graphql/queries/shipment";
import * as Yup from "yup";
import {
  CourierServiceQuery,
  CourierServiceQueryVariables,
  LocationsQuery,
  LocationsQueryVariables,
  ShipmentQuery,
  ShipmentQueryVariables,
  UpdateShipmentMutation,
  UpdateShipmentMutationVariables,
  useShipmentStatusQuery,
  CancelShipmentMutation,
  CancelShipmentMutationVariables,
} from "../../../generated/graphql";
import { Formik, FormikHelpers } from "formik";
import LOCATIONS_QUERY from "../../../graphql/queries/locations";
import COURIER_SERVICE_QUERY from "../../../graphql/queries/courierService";
import SimpleSelect from "../SimpleSelect";
import UPDATE_SHIPMENT_MUTATION from "../../../graphql/mutations/updateShipment";
import SimpleDateInput from "../SimpleDateInput";
import SimpleRangedIntegerInput from "../SimpleRangedIntegerInput";
import { ShipmentStatus } from "../../../types/ShipmentStatus";
import { breakpoints } from "../../../utils/breakPoints";
import { useToasts } from "react-toast-notifications";
import { handleApolloError } from "../../../utils/handleApolloError";
import CANCEL_SHIPMENT_MUTATION from "../../../graphql/mutations/cancelShipment";
import SHIPMENT_LINES_BY_SHIPMENT_ID_QUERY from "../../../graphql/queries/shipmentsLinesByShipmentId";
import FieldError from "../../FieldError";
import { generateUpdatedStockOnCancellation } from "./helpers/generateUpdatedStockOnCancellation";
import { generateUpdatedStockOnEdit } from "./helpers/generatedUpdatedStockOnEdit";
import { shipmentStatusNameToIdMap } from "../../../utils/shipmentStatusNameToIDMap";
import { shipmentStatusToLineStatusMap } from "./helpers/shipmentStatusToLineStatusMap";
import { disableInput } from "./helpers/disapleInput";
import {
  validateStatusChange,
  validShipmentStatusChanges,
} from "./helpers/validShipmentStatusChanges";

const SHIPMENT_NEW_STATUS_ID = shipmentStatusNameToIdMap[ShipmentStatus.NEW];
const SHIPMENT_DISPATCHED_STATUS_ID = shipmentStatusNameToIdMap[ShipmentStatus.DISPATCHED];
const SHIPMENT_COMPLETE_STATUS_ID = shipmentStatusNameToIdMap[ShipmentStatus.COMPLETE];

const SubmitBar = styled.div.attrs(() => ({
  className: "border p-4 bg-white mt-6 text-center z-50 shadow-2xl",
}))`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.xl}) {
    left: 260px; // sidebar width
    width: calc(100vw - 260px);
  }
`;

const Actions = styled.div`
  width: 200px;
  position: relative;
`;

const SaveStatus = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 10px;
  position: absolute;
  left: 170px;
  top: 12px;
  min-width: 180px;
  align-items: center;
  text-align: left;
`;

const InputGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

interface GridItemProps {
  gridColumn?: string;
}

const GridItem = styled.div<GridItemProps>`
  ${({ gridColumn }) =>
    gridColumn &&
    css`
      grid-column: ${gridColumn};
    `}
`;

const validationSchema = Yup.object({
  source_location_id: Yup.number().required("Select a source locatioon").nullable(),
  destination_location_id: Yup.number().required("Select a destination").nullable(),
  courier_service_id: Yup.number().required("Select a courier").nullable(),
  shipped_time: Yup.string().when("shipment_status_id", {
    is: (val: string) =>
      Number(val) === SHIPMENT_DISPATCHED_STATUS_ID || Number(val) === SHIPMENT_COMPLETE_STATUS_ID,
    then: Yup.string().required("Please enter a shipped time"),
  }),
  arrival_time: Yup.string().when("shipment_status_id", {
    is: SHIPMENT_COMPLETE_STATUS_ID,
    then: Yup.string().required("Please enter a shipped time"),
  }),
  estmated_shipped_time: Yup.string(),
  shipment_status_id: Yup.number().required("Select a status"),
  estimated_arrival_days: Yup.number(),
});

export interface EditShipmentFormValues {
  source_location_id: number;
  destination_location_id: number;
  courier_service_id: number;
  shipped_time: string;
  arrival_time: string;
  estimated_shipped_time: string;
  shipment_status_id: number;
  estimated_arrival_days: number;
}

interface EditShipmentFormProps {
  shipment: ShipmentQuery["inventory_shipment_by_pk"];
  hideShipmentLineForm: () => void;
}

const EditShipmentForm: FunctionComponent<EditShipmentFormProps> = ({
  shipment,
  hideShipmentLineForm,
}) => {
  const { addToast } = useToasts();
  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { loading: loadingShipment, refetch: refetch_shipment, data: dataShipment } = useQuery<
    ShipmentQuery,
    ShipmentQueryVariables
  >(SHIPMENT_QUERY, {
    variables: { shipment_id: shipment!.id },
    onError: error => handleApolloError(error, addToast),
    fetchPolicy: "network-only",
  });

  const onShipmentUpdate = (message = "Shipment Updated Successfully") => {
    addToast(message, { appearance: "success" });
    setHasUnsavedChanges(false);
    refetch_shipment();
  };

  const [updateShipment] = useMutation<UpdateShipmentMutation, UpdateShipmentMutationVariables>(
    UPDATE_SHIPMENT_MUTATION,
    {
      onCompleted: () => onShipmentUpdate(),
      onError: error => handleApolloError(error, addToast),
      refetchQueries: [
        { query: SHIPMENT_LINES_BY_SHIPMENT_ID_QUERY, variables: { shipment_id: shipment?.id } },
      ],
    }
  );

  const [cancelShipment] = useMutation<CancelShipmentMutation, CancelShipmentMutationVariables>(
    CANCEL_SHIPMENT_MUTATION,
    {
      onCompleted: () => onShipmentUpdate("Shipment Cancelled"),
      onError: error => handleApolloError(error, addToast),
    }
  );

  const { loading: loading_shipment_status, data: shipment_status } = useShipmentStatusQuery({
    onError: error => handleApolloError(error, addToast),
  });

  const { loading: loading_locations, data: locations } = useQuery<
    LocationsQuery,
    LocationsQueryVariables
  >(LOCATIONS_QUERY, {
    onError: error => handleApolloError(error, addToast),
  });

  const { loading: loading_courier_services, data: courier_services } = useQuery<
    CourierServiceQuery,
    CourierServiceQueryVariables
  >(COURIER_SERVICE_QUERY, {
    onError: error => handleApolloError(error, addToast),
  });

  if (!shipment) return null;
  if (!shipment_status || loading_shipment_status) return null;
  if (dataShipment == null || loadingShipment) return null;
  if (locations == null || loading_locations) return null;
  if (courier_services == null || loading_courier_services) return null;

  const initalFormValues: EditShipmentFormValues = {
    source_location_id: shipment.source_location_id,
    destination_location_id: shipment.destination_location_id,
    courier_service_id: shipment.courier_service.id,
    shipped_time: shipment.shipped_time || "",
    shipment_status_id: shipment.shipment_status.id,
    estimated_shipped_time: shipment.estimated_shipped_time || "",
    estimated_arrival_days: shipment.estimated_arrival_days,
    arrival_time: shipment.arrival_time || "",
  };

  const handleShipmentCancel = () => {
    const { updatedStock, error } = generateUpdatedStockOnCancellation(shipment, user_id);
    if (!updatedStock || error) {
      return addToast(error, { appearance: "error" });
    }

    return cancelShipment({
      variables: {
        shipment_id: shipment.id,
        updated_product_stocks: updatedStock,
      },
    });
  };

  const onSubmit = (
    values: EditShipmentFormValues,
    { resetForm }: FormikHelpers<EditShipmentFormValues>
  ) => {
    const newStatusObj = shipment_status.inventory_shipment_status.find(
      status => status.id === Number(values.shipment_status_id)
    );

    if (!newStatusObj) return addToast("Shipment Status is required");

    const newStatus = newStatusObj.name as ShipmentStatus;

    const isValidStatusChange = validateStatusChange(
      shipment.shipment_status.shipment_status_name as ShipmentStatus,
      newStatus
    );

    if (!isValidStatusChange) {
      resetForm();
      return addToast("Invalid shipment status change", { appearance: "error" });
    }

    if (newStatus === ShipmentStatus.CANCELLED) {
      const isConfirmed = window.confirm(
        "Are you sure you want to cancel this shipment? All stock will be reset"
      );

      isConfirmed && handleShipmentCancel();
      return;
    }

    if (
      newStatus === ShipmentStatus.COMPLETE &&
      !window.confirm(
        "Are you sure you want to mark this shipment as completed? Doing so will prevent any further edits."
      )
    ) {
      return;
    }

    const { reserved, dueIn, available } = generateUpdatedStockOnEdit(
      shipment!,
      newStatus,
      values,
      user_id
    );

    updateShipment({
      variables: {
        id: shipment.id,
        user_id: user_id,
        destination_location_id: values.destination_location_id,
        courier_service_id: values.courier_service_id,
        estimated_arrival_days: values.estimated_arrival_days,
        arrival_time: values.arrival_time || null,
        estimated_shipped_time: values.estimated_shipped_time || null,
        shipped_time: values.shipped_time || null,
        shipment_status_id: values.shipment_status_id,
        shipment_line_status_id: shipmentStatusToLineStatusMap[values.shipment_status_id],
        updated_product_stock: [...reserved, ...dueIn, ...available],
      },
    });

    if (values.shipment_status_id !== SHIPMENT_NEW_STATUS_ID) {
      hideShipmentLineForm();
    }
  };

  const shipmentStatus = shipment.shipment_status.shipment_status_name as ShipmentStatus;

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initalFormValues}
    >
      {({ handleChange: formikHandleChange, values, errors, touched, handleSubmit }) => {
        const handleChange = (e: React.ChangeEvent) => {
          setHasUnsavedChanges(true);
          formikHandleChange(e);
        };

        return (
          <>
            <form className="w-full" onSubmit={handleSubmit}>
              <InputGrid>
                <GridItem>
                  <SimpleSelect
                    change_handler={handleChange}
                    placeholder="Source Location"
                    id_ref="source_location_id"
                    label="Source Location"
                    options={locations.inventory_location}
                    required
                    selected_id={values.source_location_id}
                    name_func={option => {
                      return option.name;
                    }}
                    isDisabled // always disabled
                  />
                  {touched.source_location_id && errors.source_location_id && (
                    <FieldError>{errors.source_location_id}</FieldError>
                  )}
                </GridItem>

                <GridItem>
                  <SimpleSelect
                    change_handler={handleChange}
                    placeholder="Select a Destination Location"
                    id_ref="destination_location_id"
                    label="Destination Location"
                    options={locations.inventory_location}
                    required
                    selected_id={values.destination_location_id}
                    name_func={option => {
                      return option.name;
                    }}
                    isDisabled={disableInput(shipmentStatus, [ShipmentStatus.NEW])}
                  />
                  {touched.destination_location_id && errors.destination_location_id && (
                    <FieldError>{errors.destination_location_id}</FieldError>
                  )}
                </GridItem>

                <GridItem>
                  <SimpleSelect
                    placeholder="Enter the Courier Service"
                    change_handler={handleChange}
                    label="Courier Service"
                    options={courier_services.inventory_courier_service}
                    selected_id={values.courier_service_id}
                    id_ref="courier_service_id"
                    name_func={option => {
                      return option.name;
                    }}
                    isDisabled={disableInput(shipmentStatus, [
                      ShipmentStatus.NEW,
                      ShipmentStatus.DISPATCHED,
                    ])}
                  />
                  {touched.courier_service_id && errors.courier_service_id && (
                    <FieldError>{errors.courier_service_id}</FieldError>
                  )}
                </GridItem>

                <GridItem>
                  <SimpleSelect
                    placeholder="Enter Shipment Status"
                    change_handler={handleChange}
                    label="Shipment Status"
                    options={shipment_status.inventory_shipment_status.filter(status => {
                      const validOptions =
                        validShipmentStatusChanges[shipment.shipment_status.shipment_status_name];
                      return validOptions.includes(status.name as ShipmentStatus);
                    })}
                    selected_id={values.shipment_status_id}
                    id_ref="shipment_status_id"
                    name_func={option => {
                      return option.name;
                    }}
                    isDisabled={disableInput(shipmentStatus, [
                      ShipmentStatus.NEW,
                      ShipmentStatus.DISPATCHED,
                      ShipmentStatus.ERROR,
                    ])}
                  />
                  {touched.shipment_status_id && errors.shipment_status_id && (
                    <FieldError>{errors.shipment_status_id}</FieldError>
                  )}
                </GridItem>

                <GridItem>
                  <SimpleDateInput
                    placeholder="Est. Shipped Time"
                    change_handler={handleChange}
                    label="Est. Shipped Time"
                    id_ref="estimated_shipped_time"
                    value_ref={values.estimated_shipped_time}
                    isDisabled={disableInput(shipmentStatus, [
                      ShipmentStatus.NEW,
                      ShipmentStatus.DISPATCHED,
                    ])}
                  />
                  {touched.estimated_shipped_time && errors.estimated_shipped_time && (
                    <FieldError>{errors.estimated_shipped_time}</FieldError>
                  )}
                </GridItem>

                <GridItem>
                  <SimpleDateInput
                    placeholder="Shipped Time"
                    change_handler={handleChange}
                    label="Shipped Time"
                    id_ref="shipped_time"
                    value_ref={values.shipped_time}
                    isDisabled={disableInput(shipmentStatus, [
                      ShipmentStatus.NEW,
                      ShipmentStatus.DISPATCHED,
                    ])}
                  />
                  {touched.shipped_time && errors.shipped_time && (
                    <FieldError>{errors.shipped_time}</FieldError>
                  )}
                </GridItem>

                <GridItem>
                  <SimpleDateInput
                    placeholder="Arrival Time"
                    change_handler={handleChange}
                    label="Arrival Time"
                    id_ref="arrival_time"
                    value_ref={values.arrival_time}
                    isDisabled={disableInput(shipmentStatus, [
                      ShipmentStatus.NEW,
                      ShipmentStatus.DISPATCHED,
                    ])}
                  />
                  {touched.arrival_time && errors.arrival_time && (
                    <FieldError>{errors.arrival_time}</FieldError>
                  )}
                </GridItem>

                <GridItem>
                  <SimpleRangedIntegerInput
                    label="Est. days till arrival"
                    placeholder={"Est. days till arrival"}
                    change_handler={handleChange}
                    id_ref="estimated_arrival_days"
                    minQty={0}
                    value_ref={values.estimated_arrival_days}
                    isMarginDisabled
                    isDisabled={disableInput(shipmentStatus, [
                      ShipmentStatus.NEW,
                      ShipmentStatus.DISPATCHED,
                    ])}
                  />
                  {touched.estimated_arrival_days && errors.estimated_arrival_days && (
                    <FieldError>{errors.estimated_arrival_days}</FieldError>
                  )}
                </GridItem>
              </InputGrid>

              <SubmitBar>
                <Actions>
                  <button
                    className="bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm text-white p-3 rounded"
                    type="submit"
                  >
                    Save Changes
                  </button>
                  <SaveStatus>
                    <FontAwesomeIcon
                      color={hasUnsavedChanges ? "red" : "green"}
                      icon={hasUnsavedChanges ? faExclamationCircle : faCheckCircle}
                    />

                    {hasUnsavedChanges ? "Not Saved" : "Saved"}
                  </SaveStatus>
                </Actions>
              </SubmitBar>
            </form>
          </>
        );
      }}
    </Formik>
  );
};
export default EditShipmentForm;
