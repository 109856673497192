import { gql, useQuery } from "@apollo/client";
import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import MeetingCard from "./MeetingCard";

const GET_ACCOUNT_MEETINGS = gql`
  query AccountMeetings($account_id: Int!) {
    inventory_account_meeting_note(
      order_by: { meeting_note: { meeting_date: desc } }
      where: { account_id: { _eq: $account_id } }
    ) {
      id
      account {
        international_name
        local_name
        locale
      }
      user {
        uid
        name
      }
      meeting_note {
        id
        content
        meeting_date
        meeting_note_status {
          meeting_note_status_name
        }
        contacts
        folder {
          id
          attachment_folders {
            folder_id
            attachment {
              id
              attachment_uuid
              notes
              original_filename
              last_updated
            }
          }
        }
      }
    }
  }
`;

const Meetings = ({ account_id, account_type }) => {
  const {
    loading: loading_account_meetings,
    error: error_account_meetings,
    data: account_meetings,
    refetch: refetch_statuses,
  } = useQuery(GET_ACCOUNT_MEETINGS, {
    fetchPolicy: "network-only",
    variables: { account_id: account_id },
  });

  if (account_meetings == null || loading_account_meetings) return null;

  return (
    <>
      <h2 className="text-xl xl:text-xl font-bold">Meetings</h2>
      <div className="h-full">
        <div className="py-4">
          <Link
            className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 rounded"
            target="_blank"
            rel="noopener noreferrer"
            to={{
              pathname: `/meetings/add/${account_id}`,
              search: `?account_type=${account_type}`,
            }}
          >
            Add Meeting Note
          </Link>
        </div>
        <div className="grid grid-cols-1 gap-2">
          {account_meetings.inventory_account_meeting_note &&
            account_meetings.inventory_account_meeting_note.map(account_meeting_note => {
              return (
                <MeetingCard
                  key={account_meeting_note.id}
                  account_meeting_note={account_meeting_note}
                  account_type={account_type}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Meetings;
