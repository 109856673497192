import React from "react";
const SimpleButton = ({ label }) => {
  return (
    <button
      className="flex-shrink-0 bg-teal-500 hover:bg-teal-400 border-teal-500 hover:border-teal-400 text-sm border-4 text-white py-1 px-2 rounded"
      id="handlesubmit"
      type="submit"
    >
      {label}
    </button>
  );
};
export default SimpleButton;
