import { gql } from "@apollo/client";

const SUPPLIERS_QUERY = gql`
  query Suppliers {
    inventory_account(where: { account_roles: { role: { id: { _eq: 2 } } } }) {
      id
      local_name
      account_description
      account_status {
        account_status_name
      }
      account_roles {
        role {
          role_name
        }
      }
    }
  }
`;

export default SUPPLIERS_QUERY;
