import React, { useState } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { useQuery, useMutation, gql } from "@apollo/client";
import FileUploader from "./FileUploader";
import fileDownload from "js-file-download";
import objToQueryString from "../../styles/objToQueryString";
import Table, { TableHead, TD, TR } from "../../components/Table";

const GET_FOLDER = gql`
  query GetFolder($folder_id: Int!) {
    inventory_folder_by_pk(id: $folder_id) {
      id
      user_id
      attachment_folders {
        folder_id
        attachment {
          id
          attachment_uuid
          notes
          original_filename
          last_updated
        }
      }
    }
  }
`;

const ADD_ATTACHMENT = gql`
  mutation AddAttachment(
    $attachment_uuid: String!
    $original_filename: String!
    $folder_id: Int!
    $user_id: String!
  ) {
    insert_inventory_attachment_one(
      object: {
        attachment_uuid: $attachment_uuid
        original_filename: $original_filename
        user_id: $user_id
        attachment_folders: { data: { folder_id: $folder_id, user_id: $user_id } }
      }
    ) {
      id
    }
  }
`;

const Attachment = ({ folder_id }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { user, getTokenSilently } = useAuth0();
  const user_id = user ? user.sub : null;

  const [addAttachment] = useMutation(ADD_ATTACHMENT, {
    onCompleted() {
      refresh_folder();
    },
  });

  const { loading: loading_folder, data: data_folder, refetch: refresh_folder } = useQuery(
    GET_FOLDER,
    {
      fetchPolicy: "network-only",
      variables: {
        folder_id: folder_id,
      },
    }
  );

  const uploadFile = async formData => {
    const token = await getTokenSilently();

    const response = await fetch(
      process.env.REACT_APP_API_ORIGIN
        ? `${process.env.REACT_APP_API_ORIGIN}/gcs-file-uploader/upload`
        : `${window.REACT_APP_API_ORIGIN}/gcs-file-uploader/upload`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: formData,
      }
    );

    const json_data = await response.json();

    addAttachment({
      variables: {
        user_id: user_id,
        attachment_uuid: json_data.uuid_name,
        original_filename: selectedFile.name,
        folder_id: folder_id,
      },
    });
  };

  const submitForm = async event => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("filename", selectedFile.name);
    formData.append("file", selectedFile);
    uploadFile(formData);
  };

  const downloadAttachment = async (uuid_name, original_filename) => {
    const token = await getTokenSilently();

    const queryString = objToQueryString({
      filename: original_filename,
      uuid_name: uuid_name,
    });

    const path = "gcs-file-uploader/download";

    const response = await fetch(
      process.env.REACT_APP_API_ORIGIN
        ? `${process.env.REACT_APP_API_ORIGIN}/${path}?${queryString}`
        : `${window.REACT_APP_API_ORIGIN}/${path}?${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        mode: "cors",
      }
    );

    const blob = await response.blob();
    fileDownload(blob, original_filename);
  };

  if (data_folder == null || loading_folder) return null;

  return (
    <>
      <div className="w-full flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
        <Table id="table_product_pricings">
          <TableHead>
            <th className="p-4 m-0">attachment name</th>
            <th className="p-4 m-0">attachment id</th>
            <th className="p-4 m-0">last updated</th>
          </TableHead>
          <tbody>
            {data_folder.inventory_folder_by_pk.attachment_folders.map(attachment_folders => {
              return (
                <>
                  <TR key={attachment_folders.folder_id}>
                    <TD width={3}>
                      <h2 className="font-semibold product-result-truncate">
                        {attachment_folders.attachment.original_filename}
                      </h2>
                    </TD>
                    <TD width={3}>
                      <h2 className="font-semibold product-result-truncate">
                        {attachment_folders.attachment.attachment_uuid}
                      </h2>
                    </TD>

                    <TD width={3}>
                      <h2 className="font-semibold product-result-truncate">
                        {attachment_folders.attachment.last_updated}
                        <button
                          className="float-right"
                          onClick={() =>
                            downloadAttachment(
                              attachment_folders.attachment.attachment_uuid,
                              attachment_folders.attachment.original_filename
                            )
                          }
                        >
                          <i class="fas fa-download"></i>
                        </button>
                      </h2>
                    </TD>
                  </TR>
                </>
              );
            })}
          </tbody>
        </Table>

        <form onSubmit={submitForm}>
          <div className="p-4">
            <FileUploader
              onFileSelectSuccess={file => setSelectedFile(file)}
              onFileSelectError={({ error }) => alert(error)}
            />
            <button
              type="submit"
              className="flex-shrink-0 bg-red-500 hover:bg-red-700 border-red-500 hover:border-red-700 text-sm border-4 text-white py-1 px-2 rounded"
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default Attachment;
