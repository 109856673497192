import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import SearchBar from "../../components/search/SearchBar";
import Meetings from "../meetings/Meetings";
import { ClientDetailQuery, ClientDetailQueryVariables } from "../../generated/graphql";
import CLIENT_DETAIL_QUERY from "../../graphql/queries/clientDetail";
import AccountLocationsList from "./AccountLocationsList";
import AccountToolbar from "./AccountToolbar";
import PageHeader from "../../components/layout/PageHeader";
import PageSection from "../../components/layout/PageSection";
import AccountHeader from "../../components/AccountHeader";
import MenuSection from "../../components/layout/MenuSection";

const Client = () => {
  const { client_id } = useParams<{ client_id: string }>();

  const { loading: queryLoading, data: queryData } = useQuery<
    ClientDetailQuery,
    ClientDetailQueryVariables
  >(CLIENT_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      client_id: Number(client_id),
    },
  });

  if (queryData == null || queryLoading || queryData.inventory_account.length === 0) return null;

  const account = queryData.inventory_account[0];

  return (
    <>
      <MenuSection selectedMainMenu="Client" selectedSubMenu="all-client" />
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  mx-2 ml-4">
                <PageHeader label="Client" />
                <div className="h-full">
                  <AccountHeader account={account} />
                  <div className="ml-0.5 mb-4 font-bold text-gray-800">
                    {account.account_sector
                      ? account.account_sector.account_sector_name.toUpperCase()
                      : ""}
                  </div>
                  <AccountToolbar account={account} accountType={"client"} />

                  {account.account_description && (
                    <PageSection title="Description">
                      <p className=" font-semibold text-blue-700 product-result-truncate">
                        {account.account_description}
                      </p>
                    </PageSection>
                  )}

                  <PageSection title="Roles">
                    <p className="font-semibold text-blue-700 product-result-truncate">
                      {account.account_roles
                        .map(account_role => {
                          return account_role.role.role_name;
                        })
                        .join(", ")}
                    </p>
                  </PageSection>

                  <PageSection title="Referrals">
                    {queryData.referrers.length > 0 && (
                      <div className="mb-4">
                        <p className="font-bold">{account.local_name} was referred by: </p>
                        <ul className="pl-3">
                          {queryData.referrers.map(ref => (
                            <li>{ref.referrerAccountId.local_name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {queryData.referrals.length > 0 && (
                      <div className="mb-4">
                        <p className="font-bold">
                          {account.local_name} referred the following clients:{" "}
                        </p>
                        <ul className="pl-3">
                          {queryData.referrals.map(ref => (
                            <li>{ref.account.local_name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </PageSection>

                  <h2 className="text-xl xl:text-xl font-bold">Locations</h2>
                  {account.account_locations.length > 0 && (
                    <AccountLocationsList locations={account.account_locations} />
                  )}
                  <Meetings account_id={client_id} account_type={"client"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Client;
