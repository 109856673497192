import React from "react";
import { Link } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

type KeyboardShortcut = "n" | "c" | "b" | "d" | "q" | "p";

interface SimpleLinkButtonProps {
  label?: string;
  url: string;
  id_ref: string;
  new_tab?: boolean;
  keyboard_shortcut?: KeyboardShortcut;
}

const SimpleLinkButton = ({
  label,
  url,
  id_ref,
  new_tab,
  keyboard_shortcut,
}: SimpleLinkButtonProps) => {
  const colorMap = {
    n: "teal",
    c: "pink",
    b: "blue",
    d: "orange",
    q: "yellow",
    p: "green",
    a: "green",
  };

  useHotkeys(keyboard_shortcut || "", e => {
    const target = e.target as HTMLBodyElement;
    console.log(e);
    if (target.localName !== "input") {
      window.location.href = url;
    }
  });

  return (
    <Link
      id={id_ref}
      className={`flex-shrink-0 mr-2 hover:shadow-lg shadow text-sm border-4 text-white py-1 px-2 rounded ${
        keyboard_shortcut && colorMap[keyboard_shortcut]
          ? "bg-" + colorMap[keyboard_shortcut] + "-500"
          : "bg-teal-500"
      } ${
        keyboard_shortcut && colorMap[keyboard_shortcut]
          ? "border-" + colorMap[keyboard_shortcut] + "-500"
          : "bg-teal-500"
      }`}
      target={new_tab ? "_blank" : ""}
      to={url}
    >
      {label}{" "}
      {keyboard_shortcut && (
        <span
          className={`p-1 pt-0 ml-1 rounded-full ${
            keyboard_shortcut && colorMap[keyboard_shortcut]
              ? "bg-" + colorMap[keyboard_shortcut] + "-700"
              : "bg-teal-700"
          } ${
            keyboard_shortcut && colorMap[keyboard_shortcut]
              ? "border-" + colorMap[keyboard_shortcut] + "-700"
              : "bg-teal-700"
          }`}
        >
          {keyboard_shortcut}
        </span>
      )}
    </Link>
  );
};
export default SimpleLinkButton;
