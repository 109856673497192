import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import TitleHeading from "../../../components/TitleHeading";
import { useHotkeys } from "react-hotkeys-hook";
import EditShipmentForm from "../../../components/forms/EditShipmentForm";
import SearchBar from "../../../components/search/SearchBar";
import { useAuth0 } from "../../../react-auth0-spa";
import SHIPMENT_QUERY from "../../../graphql/queries/shipment";
import DELETE_SHIPMENT_LINE_MUTATION from "../../../graphql/mutations/deleteShipmentLine";
import {
  ShipmentQuery,
  ShipmentQueryVariables,
  GetCompatibleShipmentLinesQuery,
  GetCompatibleShipmentLinesQueryVariables,
  DeleteShipmentLineMutation,
  DeleteShipmentLineMutationVariables,
  ShipmentLinesByShipmentIdQuery,
  ShipmentLinesByShipmentIdQueryVariables,
} from "../../../generated/graphql";
import PRODUCT_STOCK_QUERY from "../../../graphql/queries/productStock";
import MenuSection from "../../../components/layout/MenuSection";
import AddShipmentLineTable from "./AddShipmentLineTable";
import Table, { TableHead, TD, TH, TR } from "../../../components/Table";
import SHIPMENT_LINES_BY_SHIPMENT_ID_QUERY from "../../../graphql/queries/shipmentsLinesByShipmentId";
import { useToasts } from "react-toast-notifications";
import { handleApolloError } from "../../../utils/handleApolloError";
import { ShipmentStatus } from "../../../types/ShipmentStatus";
import { ArrayElement } from "../../../types/ArrayElement";

const MATCHING_SHIPMENT_LINES = gql`
  query GetCompatibleShipmentLines($source_location_id: Int!, $destination_location_id: Int!) {
    inventory_shipment_line(
      where: {
        shipment_id: { _is_null: true }
        source_location_id: { _eq: $source_location_id }
        destination_location_id: { _eq: $destination_location_id }
      }
    ) {
      client_order_line_id
      source_product_stock_id
      product_stock {
        product {
          base_product {
            base_product_name
            image_url
          }
          case_qty
          weight_grams
          length_mm
          width_mm
          height_mm
        }
        qty
      }
      id
      shipment_line_status_id
      shipment_line_status {
        shipment_line_status_name
      }
    }
  }
`;

const AddShipmentLines = () => {
  const { addToast } = useToasts();
  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  const [showQuotes, setShowQuotes] = useState(false);
  const { shipment_id } = useParams<{ shipment_id: string }>();

  useHotkeys("n", e => {
    const target = e.target as HTMLBodyElement;
    if (target.localName !== "input") {
      setShowQuotes(true);
    }
  });

  const { loading: loading_shipment, data: data_shipment } = useQuery<
    ShipmentQuery,
    ShipmentQueryVariables
  >(SHIPMENT_QUERY, {
    variables: {
      shipment_id: Number(shipment_id),
    },
  });

  const source_location_id = data_shipment?.inventory_shipment_by_pk?.source_location_id;
  const destination_location_id = data_shipment?.inventory_shipment_by_pk?.destination_location_id;

  const { loading: loading_lines, data: shipment_lines, refetch: refetch_lines } = useQuery<
    ShipmentLinesByShipmentIdQuery,
    ShipmentLinesByShipmentIdQueryVariables
  >(SHIPMENT_LINES_BY_SHIPMENT_ID_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      shipment_id: Number(shipment_id),
    },
  });

  const [deleteShipmentLine, { loading: mutationLoadingDeleteShipmentLine }] = useMutation<
    DeleteShipmentLineMutation,
    DeleteShipmentLineMutationVariables
  >(DELETE_SHIPMENT_LINE_MUTATION, {
    onCompleted: () => {
      addToast("Shipping Line Deleted Successfully", {
        appearance: "success",
      });
      refetch_lines();
    },
    onError: error => handleApolloError(error, addToast),
  });

  const { loading, data: matching_shipment_lines } = useQuery<
    GetCompatibleShipmentLinesQuery,
    GetCompatibleShipmentLinesQueryVariables
  >(MATCHING_SHIPMENT_LINES, {
    onError: error => handleApolloError(error, addToast),
    variables: {
      source_location_id: source_location_id || 0,
      destination_location_id: destination_location_id || 0,
    },
  });

  if (
    loading_shipment ||
    loading_lines ||
    data_shipment == null ||
    loading ||
    shipment_lines == null ||
    matching_shipment_lines == null
  )
    return null;

  if (!data_shipment?.inventory_shipment_by_pk) {
    return <div>Error: could not load shipment</div>;
  }

  const isShipmentLineEditingAllowed =
    data_shipment.inventory_shipment_by_pk?.shipment_status.shipment_status_name ===
    ShipmentStatus.NEW;

  const isDeleteButtonEnabled = !mutationLoadingDeleteShipmentLine && isShipmentLineEditingAllowed;

  const formatQty = (qty: number) => {
    return qty.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const handleShipmentLineDelete = (
    inventory_shipment_line: ArrayElement<
      ShipmentLinesByShipmentIdQuery["inventory_shipment_line"]
    >,
    isShipmentLineEditingAllowed: boolean
  ) => {
    // only call mutation if the shipment line has not already been cancelled and is linked to shipment
    const isLinkedToShipment =
      inventory_shipment_line.shipment_id !== null &&
      inventory_shipment_line.shipment_line_status.shipment_line_status_name !== "cancelled";

    if (isLinkedToShipment && isShipmentLineEditingAllowed && !mutationLoadingDeleteShipmentLine) {
      deleteShipmentLine({
        variables: {
          shipment_line_id: inventory_shipment_line.id,
          source_product_stock_id: inventory_shipment_line.source_product_stock_id,
          updated_source_product_stock_qty:
            inventory_shipment_line.product_stock.qty + inventory_shipment_line.reserved_qty,
          user_id: user_id,
        },
        refetchQueries: [
          {
            query: PRODUCT_STOCK_QUERY,
            variables: {
              product_id: inventory_shipment_line.product_stock.product.id,
            },
          },
        ],
      });
    }
  };

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <MenuSection selectedMainMenu="Shipments" selectedSubMenu="add-shipment" />

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="h-screen container max-w-screen-xl lg:pr-8 mt-32 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-visible">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label={"Shipment #" + shipment_id} />
                </div>

                <EditShipmentForm
                  shipment={data_shipment.inventory_shipment_by_pk}
                  hideShipmentLineForm={() => setShowQuotes(false)}
                />

                <form className="w-full flex flex-col lg:flex-row">
                  <div className="w-full flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap">
                    <Table>
                      <TableHead>
                        <TH>ID</TH>
                        <TH>order Line ID</TH>
                        <TH>product</TH>
                        <TH>client</TH>
                        <TH>case qty</TH>
                        <TH>qty</TH>
                        <TH>weight</TH>
                        <TH>Source Stock</TH>
                        <TH>status</TH>
                        <TH></TH>
                      </TableHead>

                      <tbody>
                        {shipment_lines &&
                          shipment_lines.inventory_shipment_line.map(inventory_shipment_line => {
                            return (
                              <TR key={`inventory-shipment-line-${inventory_shipment_line.id}`}>
                                <TD>{inventory_shipment_line.id}</TD>
                                <TD> {inventory_shipment_line.client_order_line_id}</TD>
                                <TD width={2}>
                                  <h3 className="inline font-semibold text-blue-700 product-result-truncate">
                                    <img
                                      alt=""
                                      className="float-left mr-4 object-cover w-10 h-10 "
                                      src={
                                        inventory_shipment_line.product_stock.product.base_product
                                          .image_url
                                      }
                                    />
                                    {
                                      inventory_shipment_line.product_stock.product.base_product
                                        .base_product_name
                                    }
                                  </h3>
                                </TD>
                                <TD width={2}>
                                  {
                                    inventory_shipment_line.client_order_line.client_order
                                      .client_account.local_name
                                  }
                                </TD>
                                <TD>
                                  <h2 className="font-semibold text-blue-700 product-result-truncate">
                                    {formatQty(
                                      inventory_shipment_line.product_stock.product.case_qty
                                    )}
                                  </h2>
                                </TD>

                                <TD>
                                  <h2 className="font-semibold text-blue-700 product-result-truncate">
                                    {formatQty(inventory_shipment_line.reserved_qty)}
                                  </h2>
                                </TD>

                                <TD width={1}>
                                  <h2 className="font-semibold text-blue-700 product-result-truncate">
                                    {inventory_shipment_line.product_stock.product.weight_grams}
                                  </h2>
                                </TD>
                                <TD width={2}>
                                  <h2 className="font-semibold text-blue-700 product-result-truncate">
                                    {inventory_shipment_line.product_stock.account.local_name}
                                  </h2>
                                </TD>
                                <TD width={2}>
                                  <h2 className="font-semibold text-blue-700 product-result-truncate">
                                    {
                                      inventory_shipment_line.shipment_line_status
                                        .shipment_line_status_name
                                    }
                                  </h2>
                                </TD>
                                {}
                                <TD isFullWidth>
                                  {isDeleteButtonEnabled && (
                                    <button
                                      className={`flex-shrink-0 bg-red-500 hover:bg-red-700 border-red-500 hover:border-red-700 text-sm border-4 text-white py-1 px-2 rounded`}
                                      type="button"
                                      onClick={async event => {
                                        event.preventDefault();
                                        handleShipmentLineDelete(
                                          inventory_shipment_line,
                                          isShipmentLineEditingAllowed
                                        );
                                      }}
                                    >
                                      Delete
                                    </button>
                                  )}
                                </TD>
                              </TR>
                            );
                          })}

                        {isShipmentLineEditingAllowed && (
                          <TR>
                            <TD colSpan={9} isFullWidth>
                              <button
                                className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                type="button"
                                onClick={() => {
                                  setShowQuotes(true);
                                  // @ts-ignore
                                  window.location = "#table_shipment_lines";
                                }}
                              >
                                Add a new Line item{" "}
                                <span className="p-1 pt-0 ml-1 rounded-full bg-teal-700">n</span>
                              </button>
                            </TD>
                          </TR>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </form>
                <div style={{ marginBottom: "100px" }}>
                  {showQuotes && (
                    <AddShipmentLineTable shipment={data_shipment.inventory_shipment_by_pk} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShipmentLines;
