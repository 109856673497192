import { ShipmentStatus } from "../../../../types/ShipmentStatus";

export const validShipmentStatusChanges: { [startStatus: string]: ShipmentStatus[] } = {
  [ShipmentStatus.NEW]: [
    ShipmentStatus.NEW,
    ShipmentStatus.DISPATCHED,
    ShipmentStatus.COMPLETE,
    ShipmentStatus.CANCELLED,
    ShipmentStatus.ERROR,
  ],
  [ShipmentStatus.DISPATCHED]: [
    ShipmentStatus.NEW,
    ShipmentStatus.DISPATCHED,
    ShipmentStatus.COMPLETE,
    ShipmentStatus.CANCELLED,
    ShipmentStatus.ERROR,
  ],
  [ShipmentStatus.COMPLETE]: [ShipmentStatus.COMPLETE],
  [ShipmentStatus.ERROR]: [
    ShipmentStatus.NEW,
    ShipmentStatus.DISPATCHED,
    ShipmentStatus.COMPLETE,
    ShipmentStatus.CANCELLED,
    ShipmentStatus.ERROR,
  ],
  [ShipmentStatus.CANCELLED]: [ShipmentStatus.CANCELLED],
};

export const validateStatusChange = (prevStatus: ShipmentStatus, newStatus: ShipmentStatus) => {
  const validOptions = validShipmentStatusChanges[prevStatus];
  return validOptions.includes(newStatus);
};
