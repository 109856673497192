import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../../components/SideMenu";
import SimpleLinkButton from "../../../components/SimpleLinkButton";
import TitleHeading from "../../../components/TitleHeading";
import { Link } from "react-router-dom";
import StatusBadge from "../../../components/StatusBadge";
import HoverCell from "../../../components/forms/HoverCell";
import SearchBar from "../../../components/search/SearchBar";
import Table, { TableHead, TD, TH, TR } from "../../../components/Table";

const SUPPLIERS = gql`
  query GetSupplierOrders {
    inventory_production_order {
      id
      last_updated
      supplier_account: account {
        local_name
        id
      }
      production_order_lines_aggregate {
        aggregate {
          count
        }
      }
      production_order_status {
        production_order_status_name
      }
      location {
        name: location_name
        id
      }
    }
  }
`;

const SupplierOrders = () => {
  const [showResult, setShowResult] = useState(false);
  const { loading, error, data } = useQuery(SUPPLIERS, {
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateSupplierOrder = production_order_id => event => {
    event.preventDefault();
    openInNewTab(`supplier-orders/edit/${production_order_id}`);
  };

  if (data == null || loading) return null;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="Purchases"
            selectedSubMenu="all-supplier-orders"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="Purchases"
            selectedSubMenu="all-supplier-orders"
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Supplier Orders" />
                  <SimpleLinkButton
                    label="Add new Supplier Order"
                    keyboard_shortcut="n"
                    new_tab
                    url="/supplier-orders/add"
                    id_ref="add_production_order"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>id</TH>
                        <TH>supplier</TH>
                        <TH>location</TH>
                        <TH>lines</TH>
                        <TH>status</TH>
                        <TH />
                      </TableHead>

                      <tbody>
                        {data.inventory_production_order.map(inventory_production_order => {
                          return (
                            <TR key={inventory_production_order.id}>
                              <TD isEmphasised>
                                <Link to={`/supplier-orders/edit/${inventory_production_order.id}`}>
                                  {inventory_production_order.id}
                                </Link>
                              </TD>
                              <TD width={3} isEmphasised>
                                <HoverCell
                                  view_url={`/supplier/${inventory_production_order.supplier_account.id}`}
                                  edit_url={`/supplier/edit/${inventory_production_order.supplier_account.id}`}
                                >
                                  <Link
                                    to={`/supplier/${inventory_production_order.supplier_account.id}`}
                                  >
                                    {inventory_production_order.supplier_account.local_name}
                                  </Link>
                                </HoverCell>
                              </TD>
                              <TD width={4} isEmphasised>
                                <Link to={`/location/${inventory_production_order.location.id}`}>
                                  {inventory_production_order.location.name}{" "}
                                </Link>
                              </TD>
                              <TD width={2} isEmphasised>
                                {
                                  inventory_production_order.production_order_lines_aggregate
                                    .aggregate.count
                                }
                              </TD>
                              <TD width={3} isEmphasised>
                                <StatusBadge
                                  label={
                                    inventory_production_order.production_order_status
                                      .production_order_status_name
                                  }
                                />
                              </TD>
                              <TD>
                                <button
                                  className="float-right"
                                  onClick={updateSupplierOrder(inventory_production_order.id)}
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierOrders;
