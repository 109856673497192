import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: any;
  bigint: any;
  date: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  _nlike?: Maybe<Scalars["String"]>;
  _nsimilar?: Maybe<Scalars["String"]>;
  _similar?: Maybe<Scalars["String"]>;
};

/** expression to compare columns of type _text. All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: Maybe<Scalars["_text"]>;
  _gt?: Maybe<Scalars["_text"]>;
  _gte?: Maybe<Scalars["_text"]>;
  _in?: Maybe<Array<Scalars["_text"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["_text"]>;
  _lte?: Maybe<Scalars["_text"]>;
  _neq?: Maybe<Scalars["_text"]>;
  _nin?: Maybe<Array<Scalars["_text"]>>;
};

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
};

/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_Account = {
  __typename?: "inventory_account";
  account_created: Scalars["timestamptz"];
  account_description: Scalars["String"];
  /** An array relationship */
  account_locations: Array<Inventory_Location>;
  /** An aggregated array relationship */
  account_locations_aggregate: Inventory_Location_Aggregate;
  /** An array relationship */
  account_roles: Array<Inventory_Account_Role>;
  /** An aggregated array relationship */
  account_roles_aggregate: Inventory_Account_Role_Aggregate;
  /** An object relationship */
  account_sector: Inventory_Account_Sector;
  account_sector_id: Scalars["Int"];
  /** An object relationship */
  account_status: Inventory_Account_Status;
  account_status_id: Scalars["Int"];
  acquisition_channel: Scalars["String"];
  /** An array relationship */
  addresses: Array<Inventory_Address>;
  /** An aggregated array relationship */
  addresses_aggregate: Inventory_Address_Aggregate;
  /** An array relationship */
  client_invoices: Array<Inventory_Client_Invoice>;
  /** An aggregated array relationship */
  client_invoices_aggregate: Inventory_Client_Invoice_Aggregate;
  /** An array relationship */
  client_orders: Array<Inventory_Client_Order>;
  /** An aggregated array relationship */
  client_orders_aggregate: Inventory_Client_Order_Aggregate;
  email: Scalars["String"];
  /** An object relationship */
  folder?: Maybe<Inventory_Folder>;
  folder_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  international_name: Scalars["String"];
  last_updated: Scalars["timestamptz"];
  local_name: Scalars["String"];
  locale: Scalars["String"];
  /** An object relationship */
  localeByLocale: Inventory_Locale;
  logo_url: Scalars["String"];
  metadata: Scalars["jsonb"];
  /** An array relationship */
  product_stocks: Array<Inventory_Product_Stock>;
  /** An aggregated array relationship */
  product_stocks_aggregate: Inventory_Product_Stock_Aggregate;
  /** An array relationship */
  production_orders: Array<Inventory_Production_Order>;
  /** An aggregated array relationship */
  production_orders_aggregate: Inventory_Production_Order_Aggregate;
  /** An array relationship */
  supplier_invoices: Array<Inventory_Supplier_Invoice>;
  /** An aggregated array relationship */
  supplier_invoices_aggregate: Inventory_Supplier_Invoice_Aggregate;
  /** An array relationship */
  supplier_products: Array<Inventory_Supplier_Product>;
  /** An aggregated array relationship */
  supplier_products_aggregate: Inventory_Supplier_Product_Aggregate;
  telephone_number: Scalars["String"];
  user_id: Scalars["String"];
  website: Scalars["String"];
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountAccount_LocationsArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountAccount_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountAccount_RolesArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Role_Order_By>>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountAccount_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Role_Order_By>>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountAddressesArgs = {
  distinct_on?: Maybe<Array<Inventory_Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Address_Order_By>>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Address_Order_By>>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountClient_InvoicesArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountClient_Invoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountClient_OrdersArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountClient_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountMetadataArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountProduction_OrdersArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountProduction_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountSupplier_InvoicesArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountSupplier_Invoices_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountSupplier_ProductsArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Product_Order_By>>;
  where?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
};

/** columns and relationships of "inventory.account" */
export type Inventory_AccountSupplier_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Product_Order_By>>;
  where?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
};

/** aggregated selection of "inventory.account" */
export type Inventory_Account_Aggregate = {
  __typename?: "inventory_account_aggregate";
  aggregate?: Maybe<Inventory_Account_Aggregate_Fields>;
  nodes: Array<Inventory_Account>;
};

/** aggregate fields of "inventory.account" */
export type Inventory_Account_Aggregate_Fields = {
  __typename?: "inventory_account_aggregate_fields";
  avg?: Maybe<Inventory_Account_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Account_Max_Fields>;
  min?: Maybe<Inventory_Account_Min_Fields>;
  stddev?: Maybe<Inventory_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Account_Sum_Fields>;
  var_pop?: Maybe<Inventory_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Account_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Account_Variance_Fields>;
};

/** aggregate fields of "inventory.account" */
export type Inventory_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Account_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.account" */
export type Inventory_Account_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Account_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Account_Max_Order_By>;
  min?: Maybe<Inventory_Account_Min_Order_By>;
  stddev?: Maybe<Inventory_Account_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Account_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Account_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Account_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Account_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Account_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Account_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Inventory_Account_Append_Input = {
  metadata?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "inventory.account" */
export type Inventory_Account_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Account_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Account_Avg_Fields = {
  __typename?: "inventory_account_avg_fields";
  account_sector_id?: Maybe<Scalars["Float"]>;
  account_status_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.account" */
export type Inventory_Account_Avg_Order_By = {
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.account". All fields are combined with a logical 'AND'. */
export type Inventory_Account_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Account_Bool_Exp>>>;
  _not?: Maybe<Inventory_Account_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Account_Bool_Exp>>>;
  account_created?: Maybe<Timestamptz_Comparison_Exp>;
  account_description?: Maybe<String_Comparison_Exp>;
  account_locations?: Maybe<Inventory_Location_Bool_Exp>;
  account_roles?: Maybe<Inventory_Account_Role_Bool_Exp>;
  account_sector?: Maybe<Inventory_Account_Sector_Bool_Exp>;
  account_sector_id?: Maybe<Int_Comparison_Exp>;
  account_status?: Maybe<Inventory_Account_Status_Bool_Exp>;
  account_status_id?: Maybe<Int_Comparison_Exp>;
  acquisition_channel?: Maybe<String_Comparison_Exp>;
  addresses?: Maybe<Inventory_Address_Bool_Exp>;
  client_invoices?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
  client_orders?: Maybe<Inventory_Client_Order_Bool_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  folder?: Maybe<Inventory_Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  international_name?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  local_name?: Maybe<String_Comparison_Exp>;
  locale?: Maybe<String_Comparison_Exp>;
  localeByLocale?: Maybe<Inventory_Locale_Bool_Exp>;
  logo_url?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  product_stocks?: Maybe<Inventory_Product_Stock_Bool_Exp>;
  production_orders?: Maybe<Inventory_Production_Order_Bool_Exp>;
  supplier_invoices?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
  supplier_products?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
  telephone_number?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
  website?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.account" */
export enum Inventory_Account_Constraint {
  /** unique or primary key constraint */
  AccountInternationalNameKey = "account_international_name_key",
  /** unique or primary key constraint */
  AccountLocalNameKey = "account_local_name_key",
  /** unique or primary key constraint */
  AccountPkey = "account_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Inventory_Account_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Inventory_Account_Delete_Elem_Input = {
  metadata?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Inventory_Account_Delete_Key_Input = {
  metadata?: Maybe<Scalars["String"]>;
};

/** input type for incrementing integer column in table "inventory.account" */
export type Inventory_Account_Inc_Input = {
  account_sector_id?: Maybe<Scalars["Int"]>;
  account_status_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.account" */
export type Inventory_Account_Insert_Input = {
  account_created?: Maybe<Scalars["timestamptz"]>;
  account_description?: Maybe<Scalars["String"]>;
  account_locations?: Maybe<Inventory_Location_Arr_Rel_Insert_Input>;
  account_roles?: Maybe<Inventory_Account_Role_Arr_Rel_Insert_Input>;
  account_sector?: Maybe<Inventory_Account_Sector_Obj_Rel_Insert_Input>;
  account_sector_id?: Maybe<Scalars["Int"]>;
  account_status?: Maybe<Inventory_Account_Status_Obj_Rel_Insert_Input>;
  account_status_id?: Maybe<Scalars["Int"]>;
  acquisition_channel?: Maybe<Scalars["String"]>;
  addresses?: Maybe<Inventory_Address_Arr_Rel_Insert_Input>;
  client_invoices?: Maybe<Inventory_Client_Invoice_Arr_Rel_Insert_Input>;
  client_orders?: Maybe<Inventory_Client_Order_Arr_Rel_Insert_Input>;
  email?: Maybe<Scalars["String"]>;
  folder?: Maybe<Inventory_Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  international_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  local_name?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
  localeByLocale?: Maybe<Inventory_Locale_Obj_Rel_Insert_Input>;
  logo_url?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Scalars["jsonb"]>;
  product_stocks?: Maybe<Inventory_Product_Stock_Arr_Rel_Insert_Input>;
  production_orders?: Maybe<Inventory_Production_Order_Arr_Rel_Insert_Input>;
  supplier_invoices?: Maybe<Inventory_Supplier_Invoice_Arr_Rel_Insert_Input>;
  supplier_products?: Maybe<Inventory_Supplier_Product_Arr_Rel_Insert_Input>;
  telephone_number?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Account_Max_Fields = {
  __typename?: "inventory_account_max_fields";
  account_created?: Maybe<Scalars["timestamptz"]>;
  account_description?: Maybe<Scalars["String"]>;
  account_sector_id?: Maybe<Scalars["Int"]>;
  account_status_id?: Maybe<Scalars["Int"]>;
  acquisition_channel?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  international_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  local_name?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  telephone_number?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.account" */
export type Inventory_Account_Max_Order_By = {
  account_created?: Maybe<Order_By>;
  account_description?: Maybe<Order_By>;
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  acquisition_channel?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  international_name?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  local_name?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  telephone_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note = {
  __typename?: "inventory_account_meeting_note";
  /** An object relationship */
  account: Inventory_Account;
  account_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  meeting_note: Inventory_Meeting_Note;
  meeting_note_id: Scalars["Int"];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Aggregate = {
  __typename?: "inventory_account_meeting_note_aggregate";
  aggregate?: Maybe<Inventory_Account_Meeting_Note_Aggregate_Fields>;
  nodes: Array<Inventory_Account_Meeting_Note>;
};

/** aggregate fields of "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Aggregate_Fields = {
  __typename?: "inventory_account_meeting_note_aggregate_fields";
  avg?: Maybe<Inventory_Account_Meeting_Note_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Account_Meeting_Note_Max_Fields>;
  min?: Maybe<Inventory_Account_Meeting_Note_Min_Fields>;
  stddev?: Maybe<Inventory_Account_Meeting_Note_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Account_Meeting_Note_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Account_Meeting_Note_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Account_Meeting_Note_Sum_Fields>;
  var_pop?: Maybe<Inventory_Account_Meeting_Note_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Account_Meeting_Note_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Account_Meeting_Note_Variance_Fields>;
};

/** aggregate fields of "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Account_Meeting_Note_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Account_Meeting_Note_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Account_Meeting_Note_Max_Order_By>;
  min?: Maybe<Inventory_Account_Meeting_Note_Min_Order_By>;
  stddev?: Maybe<Inventory_Account_Meeting_Note_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Account_Meeting_Note_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Account_Meeting_Note_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Account_Meeting_Note_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Account_Meeting_Note_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Account_Meeting_Note_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Account_Meeting_Note_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Account_Meeting_Note_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Meeting_Note_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Account_Meeting_Note_Avg_Fields = {
  __typename?: "inventory_account_meeting_note_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.account_meeting_note". All fields are combined with a logical 'AND'. */
export type Inventory_Account_Meeting_Note_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Account_Meeting_Note_Bool_Exp>>>;
  _not?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Account_Meeting_Note_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  account_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  meeting_note?: Maybe<Inventory_Meeting_Note_Bool_Exp>;
  meeting_note_id?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.account_meeting_note" */
export enum Inventory_Account_Meeting_Note_Constraint {
  /** unique or primary key constraint */
  AccountMeetingNotePkey = "account_meeting_note_pkey",
}

/** input type for incrementing integer column in table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  meeting_note_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_note?: Maybe<Inventory_Meeting_Note_Obj_Rel_Insert_Input>;
  meeting_note_id?: Maybe<Scalars["Int"]>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Account_Meeting_Note_Max_Fields = {
  __typename?: "inventory_account_meeting_note_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_note_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Account_Meeting_Note_Min_Fields = {
  __typename?: "inventory_account_meeting_note_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_note_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Mutation_Response = {
  __typename?: "inventory_account_meeting_note_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Account_Meeting_Note>;
};

/** input type for inserting object relation for remote table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Obj_Rel_Insert_Input = {
  data: Inventory_Account_Meeting_Note_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Meeting_Note_On_Conflict>;
};

/** on conflict condition type for table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_On_Conflict = {
  constraint: Inventory_Account_Meeting_Note_Constraint;
  update_columns: Array<Inventory_Account_Meeting_Note_Update_Column>;
  where?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_note?: Maybe<Inventory_Meeting_Note_Order_By>;
  meeting_note_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.account_meeting_note" */
export enum Inventory_Account_Meeting_Note_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MeetingNoteId = "meeting_note_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_note_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Account_Meeting_Note_Stddev_Fields = {
  __typename?: "inventory_account_meeting_note_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Account_Meeting_Note_Stddev_Pop_Fields = {
  __typename?: "inventory_account_meeting_note_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Account_Meeting_Note_Stddev_Samp_Fields = {
  __typename?: "inventory_account_meeting_note_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Account_Meeting_Note_Sum_Fields = {
  __typename?: "inventory_account_meeting_note_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  meeting_note_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.account_meeting_note" */
export enum Inventory_Account_Meeting_Note_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MeetingNoteId = "meeting_note_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Account_Meeting_Note_Var_Pop_Fields = {
  __typename?: "inventory_account_meeting_note_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Account_Meeting_Note_Var_Samp_Fields = {
  __typename?: "inventory_account_meeting_note_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Account_Meeting_Note_Variance_Fields = {
  __typename?: "inventory_account_meeting_note_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.account_meeting_note" */
export type Inventory_Account_Meeting_Note_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.account_metadata_keys" */
export type Inventory_Account_Metadata_Keys = {
  __typename?: "inventory_account_metadata_keys";
  jsonb_object_keys?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "inventory.account_metadata_keys" */
export type Inventory_Account_Metadata_Keys_Aggregate = {
  __typename?: "inventory_account_metadata_keys_aggregate";
  aggregate?: Maybe<Inventory_Account_Metadata_Keys_Aggregate_Fields>;
  nodes: Array<Inventory_Account_Metadata_Keys>;
};

/** aggregate fields of "inventory.account_metadata_keys" */
export type Inventory_Account_Metadata_Keys_Aggregate_Fields = {
  __typename?: "inventory_account_metadata_keys_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Account_Metadata_Keys_Max_Fields>;
  min?: Maybe<Inventory_Account_Metadata_Keys_Min_Fields>;
};

/** aggregate fields of "inventory.account_metadata_keys" */
export type Inventory_Account_Metadata_Keys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Account_Metadata_Keys_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.account_metadata_keys" */
export type Inventory_Account_Metadata_Keys_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Account_Metadata_Keys_Max_Order_By>;
  min?: Maybe<Inventory_Account_Metadata_Keys_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.account_metadata_keys". All fields are combined with a logical 'AND'. */
export type Inventory_Account_Metadata_Keys_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Account_Metadata_Keys_Bool_Exp>>>;
  _not?: Maybe<Inventory_Account_Metadata_Keys_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Account_Metadata_Keys_Bool_Exp>>>;
  jsonb_object_keys?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Inventory_Account_Metadata_Keys_Max_Fields = {
  __typename?: "inventory_account_metadata_keys_max_fields";
  jsonb_object_keys?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.account_metadata_keys" */
export type Inventory_Account_Metadata_Keys_Max_Order_By = {
  jsonb_object_keys?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Account_Metadata_Keys_Min_Fields = {
  __typename?: "inventory_account_metadata_keys_min_fields";
  jsonb_object_keys?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.account_metadata_keys" */
export type Inventory_Account_Metadata_Keys_Min_Order_By = {
  jsonb_object_keys?: Maybe<Order_By>;
};

/** ordering options when selecting data from "inventory.account_metadata_keys" */
export type Inventory_Account_Metadata_Keys_Order_By = {
  jsonb_object_keys?: Maybe<Order_By>;
};

/** select columns of table "inventory.account_metadata_keys" */
export enum Inventory_Account_Metadata_Keys_Select_Column {
  /** column name */
  JsonbObjectKeys = "jsonb_object_keys",
}

/** aggregate min on columns */
export type Inventory_Account_Min_Fields = {
  __typename?: "inventory_account_min_fields";
  account_created?: Maybe<Scalars["timestamptz"]>;
  account_description?: Maybe<Scalars["String"]>;
  account_sector_id?: Maybe<Scalars["Int"]>;
  account_status_id?: Maybe<Scalars["Int"]>;
  acquisition_channel?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  international_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  local_name?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  telephone_number?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.account" */
export type Inventory_Account_Min_Order_By = {
  account_created?: Maybe<Order_By>;
  account_description?: Maybe<Order_By>;
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  acquisition_channel?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  international_name?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  local_name?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  telephone_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.account" */
export type Inventory_Account_Mutation_Response = {
  __typename?: "inventory_account_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Account>;
};

/** input type for inserting object relation for remote table "inventory.account" */
export type Inventory_Account_Obj_Rel_Insert_Input = {
  data: Inventory_Account_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_On_Conflict>;
};

/** on conflict condition type for table "inventory.account" */
export type Inventory_Account_On_Conflict = {
  constraint: Inventory_Account_Constraint;
  update_columns: Array<Inventory_Account_Update_Column>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.account" */
export type Inventory_Account_Order_By = {
  account_created?: Maybe<Order_By>;
  account_description?: Maybe<Order_By>;
  account_locations_aggregate?: Maybe<Inventory_Location_Aggregate_Order_By>;
  account_roles_aggregate?: Maybe<Inventory_Account_Role_Aggregate_Order_By>;
  account_sector?: Maybe<Inventory_Account_Sector_Order_By>;
  account_sector_id?: Maybe<Order_By>;
  account_status?: Maybe<Inventory_Account_Status_Order_By>;
  account_status_id?: Maybe<Order_By>;
  acquisition_channel?: Maybe<Order_By>;
  addresses_aggregate?: Maybe<Inventory_Address_Aggregate_Order_By>;
  client_invoices_aggregate?: Maybe<Inventory_Client_Invoice_Aggregate_Order_By>;
  client_orders_aggregate?: Maybe<Inventory_Client_Order_Aggregate_Order_By>;
  email?: Maybe<Order_By>;
  folder?: Maybe<Inventory_Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  international_name?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  local_name?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
  localeByLocale?: Maybe<Inventory_Locale_Order_By>;
  logo_url?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Inventory_Product_Stock_Aggregate_Order_By>;
  production_orders_aggregate?: Maybe<Inventory_Production_Order_Aggregate_Order_By>;
  supplier_invoices_aggregate?: Maybe<Inventory_Supplier_Invoice_Aggregate_Order_By>;
  supplier_products_aggregate?: Maybe<Inventory_Supplier_Product_Aggregate_Order_By>;
  telephone_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.account" */
export type Inventory_Account_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Inventory_Account_Prepend_Input = {
  metadata?: Maybe<Scalars["jsonb"]>;
};

/** columns and relationships of "inventory.account_referral" */
export type Inventory_Account_Referral = {
  __typename?: "inventory_account_referral";
  /** An object relationship */
  account: Inventory_Account;
  account_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  referrerAccountId: Inventory_Account;
  referrer_account_id: Scalars["Int"];
};

/** aggregated selection of "inventory.account_referral" */
export type Inventory_Account_Referral_Aggregate = {
  __typename?: "inventory_account_referral_aggregate";
  aggregate?: Maybe<Inventory_Account_Referral_Aggregate_Fields>;
  nodes: Array<Inventory_Account_Referral>;
};

/** aggregate fields of "inventory.account_referral" */
export type Inventory_Account_Referral_Aggregate_Fields = {
  __typename?: "inventory_account_referral_aggregate_fields";
  avg?: Maybe<Inventory_Account_Referral_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Account_Referral_Max_Fields>;
  min?: Maybe<Inventory_Account_Referral_Min_Fields>;
  stddev?: Maybe<Inventory_Account_Referral_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Account_Referral_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Account_Referral_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Account_Referral_Sum_Fields>;
  var_pop?: Maybe<Inventory_Account_Referral_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Account_Referral_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Account_Referral_Variance_Fields>;
};

/** aggregate fields of "inventory.account_referral" */
export type Inventory_Account_Referral_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Account_Referral_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.account_referral" */
export type Inventory_Account_Referral_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Account_Referral_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Account_Referral_Max_Order_By>;
  min?: Maybe<Inventory_Account_Referral_Min_Order_By>;
  stddev?: Maybe<Inventory_Account_Referral_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Account_Referral_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Account_Referral_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Account_Referral_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Account_Referral_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Account_Referral_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Account_Referral_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.account_referral" */
export type Inventory_Account_Referral_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Account_Referral_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Referral_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Account_Referral_Avg_Fields = {
  __typename?: "inventory_account_referral_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  referrer_account_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.account_referral". All fields are combined with a logical 'AND'. */
export type Inventory_Account_Referral_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Account_Referral_Bool_Exp>>>;
  _not?: Maybe<Inventory_Account_Referral_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Account_Referral_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  account_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  referrerAccountId?: Maybe<Inventory_Account_Bool_Exp>;
  referrer_account_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.account_referral" */
export enum Inventory_Account_Referral_Constraint {
  /** unique or primary key constraint */
  AccountReferralPkey = "account_referral_pkey",
}

/** input type for incrementing integer column in table "inventory.account_referral" */
export type Inventory_Account_Referral_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  referrer_account_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.account_referral" */
export type Inventory_Account_Referral_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  referrerAccountId?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  referrer_account_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Inventory_Account_Referral_Max_Fields = {
  __typename?: "inventory_account_referral_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  referrer_account_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Account_Referral_Min_Fields = {
  __typename?: "inventory_account_referral_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  referrer_account_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.account_referral" */
export type Inventory_Account_Referral_Mutation_Response = {
  __typename?: "inventory_account_referral_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Account_Referral>;
};

/** input type for inserting object relation for remote table "inventory.account_referral" */
export type Inventory_Account_Referral_Obj_Rel_Insert_Input = {
  data: Inventory_Account_Referral_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Referral_On_Conflict>;
};

/** on conflict condition type for table "inventory.account_referral" */
export type Inventory_Account_Referral_On_Conflict = {
  constraint: Inventory_Account_Referral_Constraint;
  update_columns: Array<Inventory_Account_Referral_Update_Column>;
  where?: Maybe<Inventory_Account_Referral_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.account_referral" */
export type Inventory_Account_Referral_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  referrerAccountId?: Maybe<Inventory_Account_Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.account_referral" */
export type Inventory_Account_Referral_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.account_referral" */
export enum Inventory_Account_Referral_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ReferrerAccountId = "referrer_account_id",
}

/** input type for updating data in table "inventory.account_referral" */
export type Inventory_Account_Referral_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  referrer_account_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Inventory_Account_Referral_Stddev_Fields = {
  __typename?: "inventory_account_referral_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  referrer_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Account_Referral_Stddev_Pop_Fields = {
  __typename?: "inventory_account_referral_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  referrer_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Account_Referral_Stddev_Samp_Fields = {
  __typename?: "inventory_account_referral_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  referrer_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Account_Referral_Sum_Fields = {
  __typename?: "inventory_account_referral_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  referrer_account_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.account_referral" */
export enum Inventory_Account_Referral_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ReferrerAccountId = "referrer_account_id",
}

/** aggregate var_pop on columns */
export type Inventory_Account_Referral_Var_Pop_Fields = {
  __typename?: "inventory_account_referral_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  referrer_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Account_Referral_Var_Samp_Fields = {
  __typename?: "inventory_account_referral_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  referrer_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Account_Referral_Variance_Fields = {
  __typename?: "inventory_account_referral_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  referrer_account_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.account_referral" */
export type Inventory_Account_Referral_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  referrer_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.account_role" */
export type Inventory_Account_Role = {
  __typename?: "inventory_account_role";
  /** An object relationship */
  account: Inventory_Account;
  account_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  role: Inventory_Role;
  role_id: Scalars["Int"];
};

/** aggregated selection of "inventory.account_role" */
export type Inventory_Account_Role_Aggregate = {
  __typename?: "inventory_account_role_aggregate";
  aggregate?: Maybe<Inventory_Account_Role_Aggregate_Fields>;
  nodes: Array<Inventory_Account_Role>;
};

/** aggregate fields of "inventory.account_role" */
export type Inventory_Account_Role_Aggregate_Fields = {
  __typename?: "inventory_account_role_aggregate_fields";
  avg?: Maybe<Inventory_Account_Role_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Account_Role_Max_Fields>;
  min?: Maybe<Inventory_Account_Role_Min_Fields>;
  stddev?: Maybe<Inventory_Account_Role_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Account_Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Account_Role_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Account_Role_Sum_Fields>;
  var_pop?: Maybe<Inventory_Account_Role_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Account_Role_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Account_Role_Variance_Fields>;
};

/** aggregate fields of "inventory.account_role" */
export type Inventory_Account_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.account_role" */
export type Inventory_Account_Role_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Account_Role_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Account_Role_Max_Order_By>;
  min?: Maybe<Inventory_Account_Role_Min_Order_By>;
  stddev?: Maybe<Inventory_Account_Role_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Account_Role_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Account_Role_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Account_Role_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Account_Role_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Account_Role_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Account_Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.account_role" */
export type Inventory_Account_Role_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Account_Role_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Role_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Account_Role_Avg_Fields = {
  __typename?: "inventory_account_role_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  role_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.account_role". All fields are combined with a logical 'AND'. */
export type Inventory_Account_Role_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Account_Role_Bool_Exp>>>;
  _not?: Maybe<Inventory_Account_Role_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Account_Role_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  account_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  role?: Maybe<Inventory_Role_Bool_Exp>;
  role_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.account_role" */
export enum Inventory_Account_Role_Constraint {
  /** unique or primary key constraint */
  AccountRolePkey = "account_role_pkey",
  /** unique or primary key constraint */
  AccountRoleUnique = "account_role_unique",
}

/** input type for incrementing integer column in table "inventory.account_role" */
export type Inventory_Account_Role_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  role_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.account_role" */
export type Inventory_Account_Role_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  role?: Maybe<Inventory_Role_Obj_Rel_Insert_Input>;
  role_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Inventory_Account_Role_Max_Fields = {
  __typename?: "inventory_account_role_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  role_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Account_Role_Min_Fields = {
  __typename?: "inventory_account_role_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  role_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.account_role" */
export type Inventory_Account_Role_Mutation_Response = {
  __typename?: "inventory_account_role_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Account_Role>;
};

/** input type for inserting object relation for remote table "inventory.account_role" */
export type Inventory_Account_Role_Obj_Rel_Insert_Input = {
  data: Inventory_Account_Role_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Role_On_Conflict>;
};

/** on conflict condition type for table "inventory.account_role" */
export type Inventory_Account_Role_On_Conflict = {
  constraint: Inventory_Account_Role_Constraint;
  update_columns: Array<Inventory_Account_Role_Update_Column>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.account_role" */
export type Inventory_Account_Role_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  role?: Maybe<Inventory_Role_Order_By>;
  role_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.account_role" */
export type Inventory_Account_Role_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.account_role" */
export enum Inventory_Account_Role_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  RoleId = "role_id",
}

/** input type for updating data in table "inventory.account_role" */
export type Inventory_Account_Role_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  role_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Inventory_Account_Role_Stddev_Fields = {
  __typename?: "inventory_account_role_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  role_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Account_Role_Stddev_Pop_Fields = {
  __typename?: "inventory_account_role_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  role_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Account_Role_Stddev_Samp_Fields = {
  __typename?: "inventory_account_role_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  role_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Account_Role_Sum_Fields = {
  __typename?: "inventory_account_role_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  role_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.account_role" */
export enum Inventory_Account_Role_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  RoleId = "role_id",
}

/** aggregate var_pop on columns */
export type Inventory_Account_Role_Var_Pop_Fields = {
  __typename?: "inventory_account_role_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  role_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Account_Role_Var_Samp_Fields = {
  __typename?: "inventory_account_role_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  role_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Account_Role_Variance_Fields = {
  __typename?: "inventory_account_role_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  role_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.account_role" */
export type Inventory_Account_Role_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.account_sector" */
export type Inventory_Account_Sector = {
  __typename?: "inventory_account_sector";
  account_sector_name: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "inventory.account_sector" */
export type Inventory_Account_Sector_Aggregate = {
  __typename?: "inventory_account_sector_aggregate";
  aggregate?: Maybe<Inventory_Account_Sector_Aggregate_Fields>;
  nodes: Array<Inventory_Account_Sector>;
};

/** aggregate fields of "inventory.account_sector" */
export type Inventory_Account_Sector_Aggregate_Fields = {
  __typename?: "inventory_account_sector_aggregate_fields";
  avg?: Maybe<Inventory_Account_Sector_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Account_Sector_Max_Fields>;
  min?: Maybe<Inventory_Account_Sector_Min_Fields>;
  stddev?: Maybe<Inventory_Account_Sector_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Account_Sector_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Account_Sector_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Account_Sector_Sum_Fields>;
  var_pop?: Maybe<Inventory_Account_Sector_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Account_Sector_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Account_Sector_Variance_Fields>;
};

/** aggregate fields of "inventory.account_sector" */
export type Inventory_Account_Sector_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Account_Sector_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.account_sector" */
export type Inventory_Account_Sector_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Account_Sector_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Account_Sector_Max_Order_By>;
  min?: Maybe<Inventory_Account_Sector_Min_Order_By>;
  stddev?: Maybe<Inventory_Account_Sector_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Account_Sector_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Account_Sector_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Account_Sector_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Account_Sector_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Account_Sector_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Account_Sector_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.account_sector" */
export type Inventory_Account_Sector_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Account_Sector_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Sector_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Account_Sector_Avg_Fields = {
  __typename?: "inventory_account_sector_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.account_sector". All fields are combined with a logical 'AND'. */
export type Inventory_Account_Sector_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Account_Sector_Bool_Exp>>>;
  _not?: Maybe<Inventory_Account_Sector_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Account_Sector_Bool_Exp>>>;
  account_sector_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.account_sector" */
export enum Inventory_Account_Sector_Constraint {
  /** unique or primary key constraint */
  AccountSectorAccountSectorNameKey = "account_sector_account_sector_name_key",
  /** unique or primary key constraint */
  AccountSectorPkey = "account_sector_pkey",
}

/** input type for incrementing integer column in table "inventory.account_sector" */
export type Inventory_Account_Sector_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.account_sector" */
export type Inventory_Account_Sector_Insert_Input = {
  account_sector_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Account_Sector_Max_Fields = {
  __typename?: "inventory_account_sector_max_fields";
  account_sector_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Max_Order_By = {
  account_sector_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Account_Sector_Min_Fields = {
  __typename?: "inventory_account_sector_min_fields";
  account_sector_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Min_Order_By = {
  account_sector_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.account_sector" */
export type Inventory_Account_Sector_Mutation_Response = {
  __typename?: "inventory_account_sector_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Account_Sector>;
};

/** input type for inserting object relation for remote table "inventory.account_sector" */
export type Inventory_Account_Sector_Obj_Rel_Insert_Input = {
  data: Inventory_Account_Sector_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Sector_On_Conflict>;
};

/** on conflict condition type for table "inventory.account_sector" */
export type Inventory_Account_Sector_On_Conflict = {
  constraint: Inventory_Account_Sector_Constraint;
  update_columns: Array<Inventory_Account_Sector_Update_Column>;
  where?: Maybe<Inventory_Account_Sector_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.account_sector" */
export type Inventory_Account_Sector_Order_By = {
  account_sector_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.account_sector" */
export type Inventory_Account_Sector_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.account_sector" */
export enum Inventory_Account_Sector_Select_Column {
  /** column name */
  AccountSectorName = "account_sector_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inventory.account_sector" */
export type Inventory_Account_Sector_Set_Input = {
  account_sector_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Account_Sector_Stddev_Fields = {
  __typename?: "inventory_account_sector_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Account_Sector_Stddev_Pop_Fields = {
  __typename?: "inventory_account_sector_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Account_Sector_Stddev_Samp_Fields = {
  __typename?: "inventory_account_sector_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Account_Sector_Sum_Fields = {
  __typename?: "inventory_account_sector_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.account_sector" */
export enum Inventory_Account_Sector_Update_Column {
  /** column name */
  AccountSectorName = "account_sector_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** aggregate var_pop on columns */
export type Inventory_Account_Sector_Var_Pop_Fields = {
  __typename?: "inventory_account_sector_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Account_Sector_Var_Samp_Fields = {
  __typename?: "inventory_account_sector_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Account_Sector_Variance_Fields = {
  __typename?: "inventory_account_sector_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.account_sector" */
export type Inventory_Account_Sector_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "inventory.account" */
export enum Inventory_Account_Select_Column {
  /** column name */
  AccountCreated = "account_created",
  /** column name */
  AccountDescription = "account_description",
  /** column name */
  AccountSectorId = "account_sector_id",
  /** column name */
  AccountStatusId = "account_status_id",
  /** column name */
  AcquisitionChannel = "acquisition_channel",
  /** column name */
  Email = "email",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  InternationalName = "international_name",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocalName = "local_name",
  /** column name */
  Locale = "locale",
  /** column name */
  LogoUrl = "logo_url",
  /** column name */
  Metadata = "metadata",
  /** column name */
  TelephoneNumber = "telephone_number",
  /** column name */
  UserId = "user_id",
  /** column name */
  Website = "website",
}

/** input type for updating data in table "inventory.account" */
export type Inventory_Account_Set_Input = {
  account_created?: Maybe<Scalars["timestamptz"]>;
  account_description?: Maybe<Scalars["String"]>;
  account_sector_id?: Maybe<Scalars["Int"]>;
  account_status_id?: Maybe<Scalars["Int"]>;
  acquisition_channel?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  international_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  local_name?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
  logo_url?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Scalars["jsonb"]>;
  telephone_number?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.account_status" */
export type Inventory_Account_Status = {
  __typename?: "inventory_account_status";
  account_status_name: Scalars["String"];
  /** An array relationship */
  accounts: Array<Inventory_Account>;
  /** An aggregated array relationship */
  accounts_aggregate: Inventory_Account_Aggregate;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** columns and relationships of "inventory.account_status" */
export type Inventory_Account_StatusAccountsArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Order_By>>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** columns and relationships of "inventory.account_status" */
export type Inventory_Account_StatusAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Order_By>>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** aggregated selection of "inventory.account_status" */
export type Inventory_Account_Status_Aggregate = {
  __typename?: "inventory_account_status_aggregate";
  aggregate?: Maybe<Inventory_Account_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Account_Status>;
};

/** aggregate fields of "inventory.account_status" */
export type Inventory_Account_Status_Aggregate_Fields = {
  __typename?: "inventory_account_status_aggregate_fields";
  avg?: Maybe<Inventory_Account_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Account_Status_Max_Fields>;
  min?: Maybe<Inventory_Account_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Account_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Account_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Account_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Account_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Account_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Account_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Account_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.account_status" */
export type Inventory_Account_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Account_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.account_status" */
export type Inventory_Account_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Account_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Account_Status_Max_Order_By>;
  min?: Maybe<Inventory_Account_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Account_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Account_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Account_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Account_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Account_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Account_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Account_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.account_status" */
export type Inventory_Account_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Account_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Account_Status_Avg_Fields = {
  __typename?: "inventory_account_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.account_status". All fields are combined with a logical 'AND'. */
export type Inventory_Account_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Account_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Account_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Account_Status_Bool_Exp>>>;
  account_status_name?: Maybe<String_Comparison_Exp>;
  accounts?: Maybe<Inventory_Account_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.account_status" */
export enum Inventory_Account_Status_Constraint {
  /** unique or primary key constraint */
  AccountStatusAccountStatusNameKey = "account_status_account_status_name_key",
  /** unique or primary key constraint */
  AccountStatusPkey = "account_status_pkey",
}

/** input type for incrementing integer column in table "inventory.account_status" */
export type Inventory_Account_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.account_status" */
export type Inventory_Account_Status_Insert_Input = {
  account_status_name?: Maybe<Scalars["String"]>;
  accounts?: Maybe<Inventory_Account_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Account_Status_Max_Fields = {
  __typename?: "inventory_account_status_max_fields";
  account_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Max_Order_By = {
  account_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Account_Status_Min_Fields = {
  __typename?: "inventory_account_status_min_fields";
  account_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Min_Order_By = {
  account_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.account_status" */
export type Inventory_Account_Status_Mutation_Response = {
  __typename?: "inventory_account_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Account_Status>;
};

/** input type for inserting object relation for remote table "inventory.account_status" */
export type Inventory_Account_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Account_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.account_status" */
export type Inventory_Account_Status_On_Conflict = {
  constraint: Inventory_Account_Status_Constraint;
  update_columns: Array<Inventory_Account_Status_Update_Column>;
  where?: Maybe<Inventory_Account_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.account_status" */
export type Inventory_Account_Status_Order_By = {
  account_status_name?: Maybe<Order_By>;
  accounts_aggregate?: Maybe<Inventory_Account_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.account_status" */
export type Inventory_Account_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.account_status" */
export enum Inventory_Account_Status_Select_Column {
  /** column name */
  AccountStatusName = "account_status_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inventory.account_status" */
export type Inventory_Account_Status_Set_Input = {
  account_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Account_Status_Stddev_Fields = {
  __typename?: "inventory_account_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Account_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_account_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Account_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_account_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Account_Status_Sum_Fields = {
  __typename?: "inventory_account_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.account_status" */
export enum Inventory_Account_Status_Update_Column {
  /** column name */
  AccountStatusName = "account_status_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** aggregate var_pop on columns */
export type Inventory_Account_Status_Var_Pop_Fields = {
  __typename?: "inventory_account_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Account_Status_Var_Samp_Fields = {
  __typename?: "inventory_account_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Account_Status_Variance_Fields = {
  __typename?: "inventory_account_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.account_status" */
export type Inventory_Account_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Account_Stddev_Fields = {
  __typename?: "inventory_account_stddev_fields";
  account_sector_id?: Maybe<Scalars["Float"]>;
  account_status_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.account" */
export type Inventory_Account_Stddev_Order_By = {
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Account_Stddev_Pop_Fields = {
  __typename?: "inventory_account_stddev_pop_fields";
  account_sector_id?: Maybe<Scalars["Float"]>;
  account_status_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.account" */
export type Inventory_Account_Stddev_Pop_Order_By = {
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Account_Stddev_Samp_Fields = {
  __typename?: "inventory_account_stddev_samp_fields";
  account_sector_id?: Maybe<Scalars["Float"]>;
  account_status_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.account" */
export type Inventory_Account_Stddev_Samp_Order_By = {
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Account_Sum_Fields = {
  __typename?: "inventory_account_sum_fields";
  account_sector_id?: Maybe<Scalars["Int"]>;
  account_status_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.account" */
export type Inventory_Account_Sum_Order_By = {
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.account" */
export enum Inventory_Account_Update_Column {
  /** column name */
  AccountCreated = "account_created",
  /** column name */
  AccountDescription = "account_description",
  /** column name */
  AccountSectorId = "account_sector_id",
  /** column name */
  AccountStatusId = "account_status_id",
  /** column name */
  AcquisitionChannel = "acquisition_channel",
  /** column name */
  Email = "email",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  InternationalName = "international_name",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocalName = "local_name",
  /** column name */
  Locale = "locale",
  /** column name */
  LogoUrl = "logo_url",
  /** column name */
  Metadata = "metadata",
  /** column name */
  TelephoneNumber = "telephone_number",
  /** column name */
  UserId = "user_id",
  /** column name */
  Website = "website",
}

/** aggregate var_pop on columns */
export type Inventory_Account_Var_Pop_Fields = {
  __typename?: "inventory_account_var_pop_fields";
  account_sector_id?: Maybe<Scalars["Float"]>;
  account_status_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.account" */
export type Inventory_Account_Var_Pop_Order_By = {
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Account_Var_Samp_Fields = {
  __typename?: "inventory_account_var_samp_fields";
  account_sector_id?: Maybe<Scalars["Float"]>;
  account_status_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.account" */
export type Inventory_Account_Var_Samp_Order_By = {
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Account_Variance_Fields = {
  __typename?: "inventory_account_variance_fields";
  account_sector_id?: Maybe<Scalars["Float"]>;
  account_status_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.account" */
export type Inventory_Account_Variance_Order_By = {
  account_sector_id?: Maybe<Order_By>;
  account_status_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.address" */
export type Inventory_Address = {
  __typename?: "inventory_address";
  /** An object relationship */
  account: Inventory_Account;
  account_id: Scalars["Int"];
  address_description: Scalars["String"];
  address_line_1: Scalars["String"];
  address_line_2: Scalars["String"];
  address_line_3: Scalars["String"];
  city: Scalars["String"];
  /** An object relationship */
  country: Inventory_Country;
  country_code: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  locations: Array<Inventory_Location>;
  /** An aggregated array relationship */
  locations_aggregate: Inventory_Location_Aggregate;
  name: Scalars["String"];
  phone_number: Scalars["String"];
  postcode: Scalars["String"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.address" */
export type Inventory_AddressLocationsArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** columns and relationships of "inventory.address" */
export type Inventory_AddressLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** aggregated selection of "inventory.address" */
export type Inventory_Address_Aggregate = {
  __typename?: "inventory_address_aggregate";
  aggregate?: Maybe<Inventory_Address_Aggregate_Fields>;
  nodes: Array<Inventory_Address>;
};

/** aggregate fields of "inventory.address" */
export type Inventory_Address_Aggregate_Fields = {
  __typename?: "inventory_address_aggregate_fields";
  avg?: Maybe<Inventory_Address_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Address_Max_Fields>;
  min?: Maybe<Inventory_Address_Min_Fields>;
  stddev?: Maybe<Inventory_Address_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Address_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Address_Sum_Fields>;
  var_pop?: Maybe<Inventory_Address_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Address_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Address_Variance_Fields>;
};

/** aggregate fields of "inventory.address" */
export type Inventory_Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Address_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.address" */
export type Inventory_Address_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Address_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Address_Max_Order_By>;
  min?: Maybe<Inventory_Address_Min_Order_By>;
  stddev?: Maybe<Inventory_Address_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Address_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Address_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Address_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Address_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Address_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.address" */
export type Inventory_Address_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Address_Insert_Input>;
  on_conflict?: Maybe<Inventory_Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Address_Avg_Fields = {
  __typename?: "inventory_address_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.address" */
export type Inventory_Address_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.address". All fields are combined with a logical 'AND'. */
export type Inventory_Address_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Address_Bool_Exp>>>;
  _not?: Maybe<Inventory_Address_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Address_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  account_id?: Maybe<Int_Comparison_Exp>;
  address_description?: Maybe<String_Comparison_Exp>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  address_line_3?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<Inventory_Country_Bool_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  locations?: Maybe<Inventory_Location_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.address" */
export enum Inventory_Address_Constraint {
  /** unique or primary key constraint */
  AddressPkey = "address_pkey",
}

/** input type for incrementing integer column in table "inventory.address" */
export type Inventory_Address_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.address" */
export type Inventory_Address_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars["Int"]>;
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Inventory_Country_Obj_Rel_Insert_Input>;
  country_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  locations?: Maybe<Inventory_Location_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Address_Max_Fields = {
  __typename?: "inventory_address_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.address" */
export type Inventory_Address_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Address_Min_Fields = {
  __typename?: "inventory_address_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.address" */
export type Inventory_Address_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.address" */
export type Inventory_Address_Mutation_Response = {
  __typename?: "inventory_address_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Address>;
};

/** input type for inserting object relation for remote table "inventory.address" */
export type Inventory_Address_Obj_Rel_Insert_Input = {
  data: Inventory_Address_Insert_Input;
  on_conflict?: Maybe<Inventory_Address_On_Conflict>;
};

/** on conflict condition type for table "inventory.address" */
export type Inventory_Address_On_Conflict = {
  constraint: Inventory_Address_Constraint;
  update_columns: Array<Inventory_Address_Update_Column>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.address" */
export type Inventory_Address_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  account_id?: Maybe<Order_By>;
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Inventory_Country_Order_By>;
  country_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Inventory_Location_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.address" */
export type Inventory_Address_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.address" */
export enum Inventory_Address_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  AddressDescription = "address_description",
  /** column name */
  AddressLine_1 = "address_line_1",
  /** column name */
  AddressLine_2 = "address_line_2",
  /** column name */
  AddressLine_3 = "address_line_3",
  /** column name */
  City = "city",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Name = "name",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Postcode = "postcode",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.address" */
export type Inventory_Address_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Address_Stddev_Fields = {
  __typename?: "inventory_address_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.address" */
export type Inventory_Address_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Address_Stddev_Pop_Fields = {
  __typename?: "inventory_address_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.address" */
export type Inventory_Address_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Address_Stddev_Samp_Fields = {
  __typename?: "inventory_address_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.address" */
export type Inventory_Address_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Address_Sum_Fields = {
  __typename?: "inventory_address_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.address" */
export type Inventory_Address_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.address" */
export enum Inventory_Address_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  AddressDescription = "address_description",
  /** column name */
  AddressLine_1 = "address_line_1",
  /** column name */
  AddressLine_2 = "address_line_2",
  /** column name */
  AddressLine_3 = "address_line_3",
  /** column name */
  City = "city",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Name = "name",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Postcode = "postcode",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Address_Var_Pop_Fields = {
  __typename?: "inventory_address_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.address" */
export type Inventory_Address_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Address_Var_Samp_Fields = {
  __typename?: "inventory_address_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.address" */
export type Inventory_Address_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Address_Variance_Fields = {
  __typename?: "inventory_address_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.address" */
export type Inventory_Address_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.attachment" */
export type Inventory_Attachment = {
  __typename?: "inventory_attachment";
  /** An array relationship */
  attachment_folders: Array<Inventory_Attachment_Folder>;
  /** An aggregated array relationship */
  attachment_folders_aggregate: Inventory_Attachment_Folder_Aggregate;
  attachment_uuid: Scalars["String"];
  id: Scalars["Int"];
  is_deleted: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  metadata: Scalars["jsonb"];
  notes: Scalars["String"];
  original_filename: Scalars["String"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.attachment" */
export type Inventory_AttachmentAttachment_FoldersArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Folder_Order_By>>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** columns and relationships of "inventory.attachment" */
export type Inventory_AttachmentAttachment_Folders_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Folder_Order_By>>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** columns and relationships of "inventory.attachment" */
export type Inventory_AttachmentMetadataArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "inventory.attachment" */
export type Inventory_Attachment_Aggregate = {
  __typename?: "inventory_attachment_aggregate";
  aggregate?: Maybe<Inventory_Attachment_Aggregate_Fields>;
  nodes: Array<Inventory_Attachment>;
};

/** aggregate fields of "inventory.attachment" */
export type Inventory_Attachment_Aggregate_Fields = {
  __typename?: "inventory_attachment_aggregate_fields";
  avg?: Maybe<Inventory_Attachment_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Attachment_Max_Fields>;
  min?: Maybe<Inventory_Attachment_Min_Fields>;
  stddev?: Maybe<Inventory_Attachment_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Attachment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Attachment_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Attachment_Sum_Fields>;
  var_pop?: Maybe<Inventory_Attachment_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Attachment_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Attachment_Variance_Fields>;
};

/** aggregate fields of "inventory.attachment" */
export type Inventory_Attachment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Attachment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.attachment" */
export type Inventory_Attachment_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Attachment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Attachment_Max_Order_By>;
  min?: Maybe<Inventory_Attachment_Min_Order_By>;
  stddev?: Maybe<Inventory_Attachment_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Attachment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Attachment_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Attachment_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Attachment_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Attachment_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Attachment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Inventory_Attachment_Append_Input = {
  metadata?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "inventory.attachment" */
export type Inventory_Attachment_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Attachment_Insert_Input>;
  on_conflict?: Maybe<Inventory_Attachment_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Attachment_Avg_Fields = {
  __typename?: "inventory_attachment_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.attachment". All fields are combined with a logical 'AND'. */
export type Inventory_Attachment_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Attachment_Bool_Exp>>>;
  _not?: Maybe<Inventory_Attachment_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Attachment_Bool_Exp>>>;
  attachment_folders?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
  attachment_uuid?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_deleted?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  original_filename?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.attachment" */
export enum Inventory_Attachment_Constraint {
  /** unique or primary key constraint */
  AttachmentAttachmentUuidKey = "attachment_attachment_uuid_key",
  /** unique or primary key constraint */
  AttachmentPkey = "attachment_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Inventory_Attachment_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Inventory_Attachment_Delete_Elem_Input = {
  metadata?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Inventory_Attachment_Delete_Key_Input = {
  metadata?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.attachment_folder" */
export type Inventory_Attachment_Folder = {
  __typename?: "inventory_attachment_folder";
  /** An object relationship */
  attachment: Inventory_Attachment;
  attachment_id: Scalars["Int"];
  /** An object relationship */
  folder: Inventory_Folder;
  folder_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Aggregate = {
  __typename?: "inventory_attachment_folder_aggregate";
  aggregate?: Maybe<Inventory_Attachment_Folder_Aggregate_Fields>;
  nodes: Array<Inventory_Attachment_Folder>;
};

/** aggregate fields of "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Aggregate_Fields = {
  __typename?: "inventory_attachment_folder_aggregate_fields";
  avg?: Maybe<Inventory_Attachment_Folder_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Attachment_Folder_Max_Fields>;
  min?: Maybe<Inventory_Attachment_Folder_Min_Fields>;
  stddev?: Maybe<Inventory_Attachment_Folder_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Attachment_Folder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Attachment_Folder_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Attachment_Folder_Sum_Fields>;
  var_pop?: Maybe<Inventory_Attachment_Folder_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Attachment_Folder_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Attachment_Folder_Variance_Fields>;
};

/** aggregate fields of "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Attachment_Folder_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Attachment_Folder_Max_Order_By>;
  min?: Maybe<Inventory_Attachment_Folder_Min_Order_By>;
  stddev?: Maybe<Inventory_Attachment_Folder_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Attachment_Folder_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Attachment_Folder_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Attachment_Folder_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Attachment_Folder_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Attachment_Folder_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Attachment_Folder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Attachment_Folder_Insert_Input>;
  on_conflict?: Maybe<Inventory_Attachment_Folder_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Attachment_Folder_Avg_Fields = {
  __typename?: "inventory_attachment_folder_avg_fields";
  attachment_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Avg_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.attachment_folder". All fields are combined with a logical 'AND'. */
export type Inventory_Attachment_Folder_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Attachment_Folder_Bool_Exp>>>;
  _not?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Attachment_Folder_Bool_Exp>>>;
  attachment?: Maybe<Inventory_Attachment_Bool_Exp>;
  attachment_id?: Maybe<Int_Comparison_Exp>;
  folder?: Maybe<Inventory_Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.attachment_folder" */
export enum Inventory_Attachment_Folder_Constraint {
  /** unique or primary key constraint */
  AttachmentFolderPkey = "attachment_folder_pkey",
}

/** input type for incrementing integer column in table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Inc_Input = {
  attachment_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Insert_Input = {
  attachment?: Maybe<Inventory_Attachment_Obj_Rel_Insert_Input>;
  attachment_id?: Maybe<Scalars["Int"]>;
  folder?: Maybe<Inventory_Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Attachment_Folder_Max_Fields = {
  __typename?: "inventory_attachment_folder_max_fields";
  attachment_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Max_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Attachment_Folder_Min_Fields = {
  __typename?: "inventory_attachment_folder_min_fields";
  attachment_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Min_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Mutation_Response = {
  __typename?: "inventory_attachment_folder_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Attachment_Folder>;
};

/** input type for inserting object relation for remote table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Obj_Rel_Insert_Input = {
  data: Inventory_Attachment_Folder_Insert_Input;
  on_conflict?: Maybe<Inventory_Attachment_Folder_On_Conflict>;
};

/** on conflict condition type for table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_On_Conflict = {
  constraint: Inventory_Attachment_Folder_Constraint;
  update_columns: Array<Inventory_Attachment_Folder_Update_Column>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Order_By = {
  attachment?: Maybe<Inventory_Attachment_Order_By>;
  attachment_id?: Maybe<Order_By>;
  folder?: Maybe<Inventory_Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.attachment_folder" */
export enum Inventory_Attachment_Folder_Select_Column {
  /** column name */
  AttachmentId = "attachment_id",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Set_Input = {
  attachment_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Attachment_Folder_Stddev_Fields = {
  __typename?: "inventory_attachment_folder_stddev_fields";
  attachment_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Stddev_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Attachment_Folder_Stddev_Pop_Fields = {
  __typename?: "inventory_attachment_folder_stddev_pop_fields";
  attachment_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Stddev_Pop_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Attachment_Folder_Stddev_Samp_Fields = {
  __typename?: "inventory_attachment_folder_stddev_samp_fields";
  attachment_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Stddev_Samp_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Attachment_Folder_Sum_Fields = {
  __typename?: "inventory_attachment_folder_sum_fields";
  attachment_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Sum_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.attachment_folder" */
export enum Inventory_Attachment_Folder_Update_Column {
  /** column name */
  AttachmentId = "attachment_id",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Attachment_Folder_Var_Pop_Fields = {
  __typename?: "inventory_attachment_folder_var_pop_fields";
  attachment_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Var_Pop_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Attachment_Folder_Var_Samp_Fields = {
  __typename?: "inventory_attachment_folder_var_samp_fields";
  attachment_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Var_Samp_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Attachment_Folder_Variance_Fields = {
  __typename?: "inventory_attachment_folder_variance_fields";
  attachment_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.attachment_folder" */
export type Inventory_Attachment_Folder_Variance_Order_By = {
  attachment_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** input type for incrementing integer column in table "inventory.attachment" */
export type Inventory_Attachment_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.attachment" */
export type Inventory_Attachment_Insert_Input = {
  attachment_folders?: Maybe<Inventory_Attachment_Folder_Arr_Rel_Insert_Input>;
  attachment_uuid?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  metadata?: Maybe<Scalars["jsonb"]>;
  notes?: Maybe<Scalars["String"]>;
  original_filename?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Attachment_Max_Fields = {
  __typename?: "inventory_attachment_max_fields";
  attachment_uuid?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  original_filename?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Max_Order_By = {
  attachment_uuid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  original_filename?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Attachment_Min_Fields = {
  __typename?: "inventory_attachment_min_fields";
  attachment_uuid?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  original_filename?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Min_Order_By = {
  attachment_uuid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  original_filename?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.attachment" */
export type Inventory_Attachment_Mutation_Response = {
  __typename?: "inventory_attachment_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Attachment>;
};

/** input type for inserting object relation for remote table "inventory.attachment" */
export type Inventory_Attachment_Obj_Rel_Insert_Input = {
  data: Inventory_Attachment_Insert_Input;
  on_conflict?: Maybe<Inventory_Attachment_On_Conflict>;
};

/** on conflict condition type for table "inventory.attachment" */
export type Inventory_Attachment_On_Conflict = {
  constraint: Inventory_Attachment_Constraint;
  update_columns: Array<Inventory_Attachment_Update_Column>;
  where?: Maybe<Inventory_Attachment_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.attachment" */
export type Inventory_Attachment_Order_By = {
  attachment_folders_aggregate?: Maybe<Inventory_Attachment_Folder_Aggregate_Order_By>;
  attachment_uuid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_deleted?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  original_filename?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.attachment" */
export type Inventory_Attachment_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Inventory_Attachment_Prepend_Input = {
  metadata?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "inventory.attachment" */
export enum Inventory_Attachment_Select_Column {
  /** column name */
  AttachmentUuid = "attachment_uuid",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Notes = "notes",
  /** column name */
  OriginalFilename = "original_filename",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.attachment" */
export type Inventory_Attachment_Set_Input = {
  attachment_uuid?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  metadata?: Maybe<Scalars["jsonb"]>;
  notes?: Maybe<Scalars["String"]>;
  original_filename?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Attachment_Stddev_Fields = {
  __typename?: "inventory_attachment_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Attachment_Stddev_Pop_Fields = {
  __typename?: "inventory_attachment_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Attachment_Stddev_Samp_Fields = {
  __typename?: "inventory_attachment_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Attachment_Sum_Fields = {
  __typename?: "inventory_attachment_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.attachment" */
export enum Inventory_Attachment_Update_Column {
  /** column name */
  AttachmentUuid = "attachment_uuid",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Metadata = "metadata",
  /** column name */
  Notes = "notes",
  /** column name */
  OriginalFilename = "original_filename",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Attachment_Var_Pop_Fields = {
  __typename?: "inventory_attachment_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Attachment_Var_Samp_Fields = {
  __typename?: "inventory_attachment_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Attachment_Variance_Fields = {
  __typename?: "inventory_attachment_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.attachment" */
export type Inventory_Attachment_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.base_product" */
export type Inventory_Base_Product = {
  __typename?: "inventory_base_product";
  base_product_description: Scalars["String"];
  base_product_name: Scalars["String"];
  hs_code: Scalars["String"];
  id: Scalars["Int"];
  image_url: Scalars["String"];
  last_updated: Scalars["timestamptz"];
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.base_product" */
export type Inventory_Base_Product_Aggregate = {
  __typename?: "inventory_base_product_aggregate";
  aggregate?: Maybe<Inventory_Base_Product_Aggregate_Fields>;
  nodes: Array<Inventory_Base_Product>;
};

/** aggregate fields of "inventory.base_product" */
export type Inventory_Base_Product_Aggregate_Fields = {
  __typename?: "inventory_base_product_aggregate_fields";
  avg?: Maybe<Inventory_Base_Product_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Base_Product_Max_Fields>;
  min?: Maybe<Inventory_Base_Product_Min_Fields>;
  stddev?: Maybe<Inventory_Base_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Base_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Base_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Base_Product_Sum_Fields>;
  var_pop?: Maybe<Inventory_Base_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Base_Product_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Base_Product_Variance_Fields>;
};

/** aggregate fields of "inventory.base_product" */
export type Inventory_Base_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Base_Product_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.base_product" */
export type Inventory_Base_Product_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Base_Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Base_Product_Max_Order_By>;
  min?: Maybe<Inventory_Base_Product_Min_Order_By>;
  stddev?: Maybe<Inventory_Base_Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Base_Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Base_Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Base_Product_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Base_Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Base_Product_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Base_Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.base_product" */
export type Inventory_Base_Product_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Base_Product_Insert_Input>;
  on_conflict?: Maybe<Inventory_Base_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Base_Product_Avg_Fields = {
  __typename?: "inventory_base_product_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.base_product". All fields are combined with a logical 'AND'. */
export type Inventory_Base_Product_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Base_Product_Bool_Exp>>>;
  _not?: Maybe<Inventory_Base_Product_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Base_Product_Bool_Exp>>>;
  base_product_description?: Maybe<String_Comparison_Exp>;
  base_product_name?: Maybe<String_Comparison_Exp>;
  hs_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.base_product" */
export enum Inventory_Base_Product_Constraint {
  /** unique or primary key constraint */
  BaseProductPkey = "base_product_pkey",
}

/** input type for incrementing integer column in table "inventory.base_product" */
export type Inventory_Base_Product_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.base_product" */
export type Inventory_Base_Product_Insert_Input = {
  base_product_description?: Maybe<Scalars["String"]>;
  base_product_name?: Maybe<Scalars["String"]>;
  hs_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Base_Product_Max_Fields = {
  __typename?: "inventory_base_product_max_fields";
  base_product_description?: Maybe<Scalars["String"]>;
  base_product_name?: Maybe<Scalars["String"]>;
  hs_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Max_Order_By = {
  base_product_description?: Maybe<Order_By>;
  base_product_name?: Maybe<Order_By>;
  hs_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Base_Product_Min_Fields = {
  __typename?: "inventory_base_product_min_fields";
  base_product_description?: Maybe<Scalars["String"]>;
  base_product_name?: Maybe<Scalars["String"]>;
  hs_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Min_Order_By = {
  base_product_description?: Maybe<Order_By>;
  base_product_name?: Maybe<Order_By>;
  hs_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.base_product" */
export type Inventory_Base_Product_Mutation_Response = {
  __typename?: "inventory_base_product_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Base_Product>;
};

/** input type for inserting object relation for remote table "inventory.base_product" */
export type Inventory_Base_Product_Obj_Rel_Insert_Input = {
  data: Inventory_Base_Product_Insert_Input;
  on_conflict?: Maybe<Inventory_Base_Product_On_Conflict>;
};

/** on conflict condition type for table "inventory.base_product" */
export type Inventory_Base_Product_On_Conflict = {
  constraint: Inventory_Base_Product_Constraint;
  update_columns: Array<Inventory_Base_Product_Update_Column>;
  where?: Maybe<Inventory_Base_Product_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.base_product" */
export type Inventory_Base_Product_Order_By = {
  base_product_description?: Maybe<Order_By>;
  base_product_name?: Maybe<Order_By>;
  hs_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.base_product" */
export type Inventory_Base_Product_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.base_product" */
export enum Inventory_Base_Product_Select_Column {
  /** column name */
  BaseProductDescription = "base_product_description",
  /** column name */
  BaseProductName = "base_product_name",
  /** column name */
  HsCode = "hs_code",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.base_product" */
export type Inventory_Base_Product_Set_Input = {
  base_product_description?: Maybe<Scalars["String"]>;
  base_product_name?: Maybe<Scalars["String"]>;
  hs_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Base_Product_Stddev_Fields = {
  __typename?: "inventory_base_product_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Base_Product_Stddev_Pop_Fields = {
  __typename?: "inventory_base_product_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Base_Product_Stddev_Samp_Fields = {
  __typename?: "inventory_base_product_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Base_Product_Sum_Fields = {
  __typename?: "inventory_base_product_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.base_product" */
export enum Inventory_Base_Product_Update_Column {
  /** column name */
  BaseProductDescription = "base_product_description",
  /** column name */
  BaseProductName = "base_product_name",
  /** column name */
  HsCode = "hs_code",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Base_Product_Var_Pop_Fields = {
  __typename?: "inventory_base_product_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Base_Product_Var_Samp_Fields = {
  __typename?: "inventory_base_product_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Base_Product_Variance_Fields = {
  __typename?: "inventory_base_product_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.base_product" */
export type Inventory_Base_Product_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.batch" */
export type Inventory_Batch = {
  __typename?: "inventory_batch";
  /** An object relationship */
  account: Inventory_Account;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  product_stocks: Array<Inventory_Product_Stock>;
  /** An aggregated array relationship */
  product_stocks_aggregate: Inventory_Product_Stock_Aggregate;
  supplier_account_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.batch" */
export type Inventory_BatchProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** columns and relationships of "inventory.batch" */
export type Inventory_BatchProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** aggregated selection of "inventory.batch" */
export type Inventory_Batch_Aggregate = {
  __typename?: "inventory_batch_aggregate";
  aggregate?: Maybe<Inventory_Batch_Aggregate_Fields>;
  nodes: Array<Inventory_Batch>;
};

/** aggregate fields of "inventory.batch" */
export type Inventory_Batch_Aggregate_Fields = {
  __typename?: "inventory_batch_aggregate_fields";
  avg?: Maybe<Inventory_Batch_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Batch_Max_Fields>;
  min?: Maybe<Inventory_Batch_Min_Fields>;
  stddev?: Maybe<Inventory_Batch_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Batch_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Batch_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Batch_Sum_Fields>;
  var_pop?: Maybe<Inventory_Batch_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Batch_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Batch_Variance_Fields>;
};

/** aggregate fields of "inventory.batch" */
export type Inventory_Batch_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Batch_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.batch" */
export type Inventory_Batch_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Batch_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Batch_Max_Order_By>;
  min?: Maybe<Inventory_Batch_Min_Order_By>;
  stddev?: Maybe<Inventory_Batch_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Batch_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Batch_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Batch_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Batch_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Batch_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Batch_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.batch" */
export type Inventory_Batch_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Batch_Insert_Input>;
  on_conflict?: Maybe<Inventory_Batch_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Batch_Avg_Fields = {
  __typename?: "inventory_batch_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.batch" */
export type Inventory_Batch_Avg_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.batch". All fields are combined with a logical 'AND'. */
export type Inventory_Batch_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Batch_Bool_Exp>>>;
  _not?: Maybe<Inventory_Batch_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Batch_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product_stocks?: Maybe<Inventory_Product_Stock_Bool_Exp>;
  supplier_account_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.batch" */
export enum Inventory_Batch_Constraint {
  /** unique or primary key constraint */
  BatchPkey = "batch_pkey",
}

/** input type for incrementing integer column in table "inventory.batch" */
export type Inventory_Batch_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.batch" */
export type Inventory_Batch_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stocks?: Maybe<Inventory_Product_Stock_Arr_Rel_Insert_Input>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Batch_Max_Fields = {
  __typename?: "inventory_batch_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.batch" */
export type Inventory_Batch_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Batch_Min_Fields = {
  __typename?: "inventory_batch_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.batch" */
export type Inventory_Batch_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.batch" */
export type Inventory_Batch_Mutation_Response = {
  __typename?: "inventory_batch_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Batch>;
};

/** input type for inserting object relation for remote table "inventory.batch" */
export type Inventory_Batch_Obj_Rel_Insert_Input = {
  data: Inventory_Batch_Insert_Input;
  on_conflict?: Maybe<Inventory_Batch_On_Conflict>;
};

/** on conflict condition type for table "inventory.batch" */
export type Inventory_Batch_On_Conflict = {
  constraint: Inventory_Batch_Constraint;
  update_columns: Array<Inventory_Batch_Update_Column>;
  where?: Maybe<Inventory_Batch_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.batch" */
export type Inventory_Batch_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Inventory_Product_Stock_Aggregate_Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.batch" */
export type Inventory_Batch_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.batch" */
export enum Inventory_Batch_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SupplierAccountId = "supplier_account_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.batch" */
export type Inventory_Batch_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Batch_Stddev_Fields = {
  __typename?: "inventory_batch_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.batch" */
export type Inventory_Batch_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Batch_Stddev_Pop_Fields = {
  __typename?: "inventory_batch_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.batch" */
export type Inventory_Batch_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Batch_Stddev_Samp_Fields = {
  __typename?: "inventory_batch_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.batch" */
export type Inventory_Batch_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Batch_Sum_Fields = {
  __typename?: "inventory_batch_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.batch" */
export type Inventory_Batch_Sum_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.batch" */
export enum Inventory_Batch_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SupplierAccountId = "supplier_account_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Batch_Var_Pop_Fields = {
  __typename?: "inventory_batch_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.batch" */
export type Inventory_Batch_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Batch_Var_Samp_Fields = {
  __typename?: "inventory_batch_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.batch" */
export type Inventory_Batch_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Batch_Variance_Fields = {
  __typename?: "inventory_batch_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.batch" */
export type Inventory_Batch_Variance_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.client_invoice" */
export type Inventory_Client_Invoice = {
  __typename?: "inventory_client_invoice";
  account_id: Scalars["Int"];
  /** An object relationship */
  client_account: Inventory_Account;
  client_invoice_status_id: Scalars["Int"];
  created_time: Scalars["timestamptz"];
  /** An object relationship */
  currency: Inventory_Currency;
  currency_code: Scalars["String"];
  id: Scalars["Int"];
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated: Scalars["timestamptz"];
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.client_invoice" */
export type Inventory_Client_Invoice_Aggregate = {
  __typename?: "inventory_client_invoice_aggregate";
  aggregate?: Maybe<Inventory_Client_Invoice_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Invoice>;
};

/** aggregate fields of "inventory.client_invoice" */
export type Inventory_Client_Invoice_Aggregate_Fields = {
  __typename?: "inventory_client_invoice_aggregate_fields";
  avg?: Maybe<Inventory_Client_Invoice_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Invoice_Max_Fields>;
  min?: Maybe<Inventory_Client_Invoice_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Invoice_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Invoice_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Invoice_Variance_Fields>;
};

/** aggregate fields of "inventory.client_invoice" */
export type Inventory_Client_Invoice_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Invoice_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Invoice_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Invoice_Max_Order_By>;
  min?: Maybe<Inventory_Client_Invoice_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Invoice_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Invoice_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Invoice_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Invoice_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Invoice_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Invoice_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Invoice_Avg_Fields = {
  __typename?: "inventory_client_invoice_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_invoice_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_invoice". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Invoice_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Invoice_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Invoice_Bool_Exp>>>;
  account_id?: Maybe<Int_Comparison_Exp>;
  client_account?: Maybe<Inventory_Account_Bool_Exp>;
  client_invoice_status_id?: Maybe<Int_Comparison_Exp>;
  created_time?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<Inventory_Currency_Bool_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_time?: Maybe<Timestamptz_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_invoice" */
export enum Inventory_Client_Invoice_Constraint {
  /** unique or primary key constraint */
  ClientInvoicePkey = "client_invoice_pkey",
}

/** input type for incrementing integer column in table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  client_invoice_status_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Insert_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  client_account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  client_invoice_status_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency?: Maybe<Inventory_Currency_Obj_Rel_Insert_Input>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line = {
  __typename?: "inventory_client_invoice_line";
  client_invoice_id: Scalars["Int"];
  client_invoice_line_status_id: Scalars["Int"];
  client_order_line_id: Scalars["Int"];
  id: Scalars["Int"];
  invoice_amount: Scalars["numeric"];
  last_updated: Scalars["timestamptz"];
  received_amount: Scalars["numeric"];
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Aggregate = {
  __typename?: "inventory_client_invoice_line_aggregate";
  aggregate?: Maybe<Inventory_Client_Invoice_Line_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Invoice_Line>;
};

/** aggregate fields of "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Aggregate_Fields = {
  __typename?: "inventory_client_invoice_line_aggregate_fields";
  avg?: Maybe<Inventory_Client_Invoice_Line_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Invoice_Line_Max_Fields>;
  min?: Maybe<Inventory_Client_Invoice_Line_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Invoice_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Invoice_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Invoice_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Invoice_Line_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Invoice_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Invoice_Line_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Invoice_Line_Variance_Fields>;
};

/** aggregate fields of "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Invoice_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Invoice_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Invoice_Line_Max_Order_By>;
  min?: Maybe<Inventory_Client_Invoice_Line_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Invoice_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Invoice_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Invoice_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Invoice_Line_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Invoice_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Invoice_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Invoice_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Invoice_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Invoice_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Invoice_Line_Avg_Fields = {
  __typename?: "inventory_client_invoice_line_avg_fields";
  client_invoice_id?: Maybe<Scalars["Float"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Avg_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_invoice_line". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Invoice_Line_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Invoice_Line_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Invoice_Line_Bool_Exp>>>;
  client_invoice_id?: Maybe<Int_Comparison_Exp>;
  client_invoice_line_status_id?: Maybe<Int_Comparison_Exp>;
  client_order_line_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_amount?: Maybe<Numeric_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  received_amount?: Maybe<Numeric_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_invoice_line" */
export enum Inventory_Client_Invoice_Line_Constraint {
  /** unique or primary key constraint */
  ClientInvoiceLinePkey = "client_invoice_line_pkey",
}

/** input type for incrementing integer column in table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Inc_Input = {
  client_invoice_id?: Maybe<Scalars["Int"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Insert_Input = {
  client_invoice_id?: Maybe<Scalars["Int"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Client_Invoice_Line_Max_Fields = {
  __typename?: "inventory_client_invoice_line_max_fields";
  client_invoice_id?: Maybe<Scalars["Int"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Max_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Invoice_Line_Min_Fields = {
  __typename?: "inventory_client_invoice_line_min_fields";
  client_invoice_id?: Maybe<Scalars["Int"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Min_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Mutation_Response = {
  __typename?: "inventory_client_invoice_line_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Invoice_Line>;
};

/** input type for inserting object relation for remote table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Invoice_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Invoice_Line_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_On_Conflict = {
  constraint: Inventory_Client_Invoice_Line_Constraint;
  update_columns: Array<Inventory_Client_Invoice_Line_Update_Column>;
  where?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_invoice_line" */
export enum Inventory_Client_Invoice_Line_Select_Column {
  /** column name */
  ClientInvoiceId = "client_invoice_id",
  /** column name */
  ClientInvoiceLineStatusId = "client_invoice_line_status_id",
  /** column name */
  ClientOrderLineId = "client_order_line_id",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceAmount = "invoice_amount",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ReceivedAmount = "received_amount",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Set_Input = {
  client_invoice_id?: Maybe<Scalars["Int"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status = {
  __typename?: "inventory_client_invoice_line_status";
  client_invoice_line_status_name: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Aggregate = {
  __typename?: "inventory_client_invoice_line_status_aggregate";
  aggregate?: Maybe<Inventory_Client_Invoice_Line_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Invoice_Line_Status>;
};

/** aggregate fields of "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Aggregate_Fields = {
  __typename?: "inventory_client_invoice_line_status_aggregate_fields";
  avg?: Maybe<Inventory_Client_Invoice_Line_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Invoice_Line_Status_Max_Fields>;
  min?: Maybe<Inventory_Client_Invoice_Line_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Invoice_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Invoice_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Invoice_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Invoice_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Invoice_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Invoice_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Invoice_Line_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Invoice_Line_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Invoice_Line_Status_Max_Order_By>;
  min?: Maybe<Inventory_Client_Invoice_Line_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Invoice_Line_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Invoice_Line_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Invoice_Line_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Invoice_Line_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Invoice_Line_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Invoice_Line_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Invoice_Line_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Invoice_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Invoice_Line_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Invoice_Line_Status_Avg_Fields = {
  __typename?: "inventory_client_invoice_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_invoice_line_status". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Invoice_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Invoice_Line_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Invoice_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Invoice_Line_Status_Bool_Exp>>>;
  client_invoice_line_status_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_invoice_line_status" */
export enum Inventory_Client_Invoice_Line_Status_Constraint {
  /** unique or primary key constraint */
  ClientInvoiceLineStatusClientInvoiceLineStatusNameKey = "client_invoice_line_status_client_invoice_line_status_name_key",
  /** unique or primary key constraint */
  ClientInvoiceLineStatusPkey = "client_invoice_line_status_pkey",
}

/** input type for incrementing integer column in table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Insert_Input = {
  client_invoice_line_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Client_Invoice_Line_Status_Max_Fields = {
  __typename?: "inventory_client_invoice_line_status_max_fields";
  client_invoice_line_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Max_Order_By = {
  client_invoice_line_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Invoice_Line_Status_Min_Fields = {
  __typename?: "inventory_client_invoice_line_status_min_fields";
  client_invoice_line_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Min_Order_By = {
  client_invoice_line_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Mutation_Response = {
  __typename?: "inventory_client_invoice_line_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Invoice_Line_Status>;
};

/** input type for inserting object relation for remote table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Invoice_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Invoice_Line_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_On_Conflict = {
  constraint: Inventory_Client_Invoice_Line_Status_Constraint;
  update_columns: Array<Inventory_Client_Invoice_Line_Status_Update_Column>;
  where?: Maybe<Inventory_Client_Invoice_Line_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Order_By = {
  client_invoice_line_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_invoice_line_status" */
export enum Inventory_Client_Invoice_Line_Status_Select_Column {
  /** column name */
  ClientInvoiceLineStatusName = "client_invoice_line_status_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Set_Input = {
  client_invoice_line_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Invoice_Line_Status_Stddev_Fields = {
  __typename?: "inventory_client_invoice_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Invoice_Line_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_client_invoice_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Invoice_Line_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_client_invoice_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Invoice_Line_Status_Sum_Fields = {
  __typename?: "inventory_client_invoice_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_invoice_line_status" */
export enum Inventory_Client_Invoice_Line_Status_Update_Column {
  /** column name */
  ClientInvoiceLineStatusName = "client_invoice_line_status_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Invoice_Line_Status_Var_Pop_Fields = {
  __typename?: "inventory_client_invoice_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Invoice_Line_Status_Var_Samp_Fields = {
  __typename?: "inventory_client_invoice_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Invoice_Line_Status_Variance_Fields = {
  __typename?: "inventory_client_invoice_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_invoice_line_status" */
export type Inventory_Client_Invoice_Line_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Invoice_Line_Stddev_Fields = {
  __typename?: "inventory_client_invoice_line_stddev_fields";
  client_invoice_id?: Maybe<Scalars["Float"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Stddev_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Invoice_Line_Stddev_Pop_Fields = {
  __typename?: "inventory_client_invoice_line_stddev_pop_fields";
  client_invoice_id?: Maybe<Scalars["Float"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Stddev_Pop_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Invoice_Line_Stddev_Samp_Fields = {
  __typename?: "inventory_client_invoice_line_stddev_samp_fields";
  client_invoice_id?: Maybe<Scalars["Float"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Stddev_Samp_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Invoice_Line_Sum_Fields = {
  __typename?: "inventory_client_invoice_line_sum_fields";
  client_invoice_id?: Maybe<Scalars["Int"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Sum_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_invoice_line" */
export enum Inventory_Client_Invoice_Line_Update_Column {
  /** column name */
  ClientInvoiceId = "client_invoice_id",
  /** column name */
  ClientInvoiceLineStatusId = "client_invoice_line_status_id",
  /** column name */
  ClientOrderLineId = "client_order_line_id",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceAmount = "invoice_amount",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ReceivedAmount = "received_amount",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Invoice_Line_Var_Pop_Fields = {
  __typename?: "inventory_client_invoice_line_var_pop_fields";
  client_invoice_id?: Maybe<Scalars["Float"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Var_Pop_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Invoice_Line_Var_Samp_Fields = {
  __typename?: "inventory_client_invoice_line_var_samp_fields";
  client_invoice_id?: Maybe<Scalars["Float"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Var_Samp_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Invoice_Line_Variance_Fields = {
  __typename?: "inventory_client_invoice_line_variance_fields";
  client_invoice_id?: Maybe<Scalars["Float"]>;
  client_invoice_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_invoice_line" */
export type Inventory_Client_Invoice_Line_Variance_Order_By = {
  client_invoice_id?: Maybe<Order_By>;
  client_invoice_line_status_id?: Maybe<Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Inventory_Client_Invoice_Max_Fields = {
  __typename?: "inventory_client_invoice_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  client_invoice_status_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_time?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Invoice_Min_Fields = {
  __typename?: "inventory_client_invoice_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  client_invoice_status_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_time?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Mutation_Response = {
  __typename?: "inventory_client_invoice_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Invoice>;
};

/** input type for inserting object relation for remote table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Invoice_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Invoice_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_invoice" */
export type Inventory_Client_Invoice_On_Conflict = {
  constraint: Inventory_Client_Invoice_Constraint;
  update_columns: Array<Inventory_Client_Invoice_Update_Column>;
  where?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_invoice" */
export type Inventory_Client_Invoice_Order_By = {
  account_id?: Maybe<Order_By>;
  client_account?: Maybe<Inventory_Account_Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  currency?: Maybe<Inventory_Currency_Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_time?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_invoice" */
export type Inventory_Client_Invoice_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_invoice" */
export enum Inventory_Client_Invoice_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  ClientInvoiceStatusId = "client_invoice_status_id",
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceTime = "invoice_time",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  client_invoice_status_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status = {
  __typename?: "inventory_client_invoice_status";
  client_invoice_status_name: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Aggregate = {
  __typename?: "inventory_client_invoice_status_aggregate";
  aggregate?: Maybe<Inventory_Client_Invoice_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Invoice_Status>;
};

/** aggregate fields of "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Aggregate_Fields = {
  __typename?: "inventory_client_invoice_status_aggregate_fields";
  avg?: Maybe<Inventory_Client_Invoice_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Invoice_Status_Max_Fields>;
  min?: Maybe<Inventory_Client_Invoice_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Invoice_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Invoice_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Invoice_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Invoice_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Invoice_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Invoice_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Invoice_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Invoice_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Invoice_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Invoice_Status_Max_Order_By>;
  min?: Maybe<Inventory_Client_Invoice_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Invoice_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Invoice_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Invoice_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Invoice_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Invoice_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Invoice_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Invoice_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Invoice_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Invoice_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Invoice_Status_Avg_Fields = {
  __typename?: "inventory_client_invoice_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_invoice_status". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Invoice_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Invoice_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Invoice_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Invoice_Status_Bool_Exp>>>;
  client_invoice_status_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_invoice_status" */
export enum Inventory_Client_Invoice_Status_Constraint {
  /** unique or primary key constraint */
  ClientInvoiceStatusClientInvoiceStatusNameKey = "client_invoice_status_client_invoice_status_name_key",
  /** unique or primary key constraint */
  ClientInvoiceStatusPkey = "client_invoice_status_pkey",
}

/** input type for incrementing integer column in table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Insert_Input = {
  client_invoice_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Client_Invoice_Status_Max_Fields = {
  __typename?: "inventory_client_invoice_status_max_fields";
  client_invoice_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Max_Order_By = {
  client_invoice_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Invoice_Status_Min_Fields = {
  __typename?: "inventory_client_invoice_status_min_fields";
  client_invoice_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Min_Order_By = {
  client_invoice_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Mutation_Response = {
  __typename?: "inventory_client_invoice_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Invoice_Status>;
};

/** input type for inserting object relation for remote table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Invoice_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Invoice_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_On_Conflict = {
  constraint: Inventory_Client_Invoice_Status_Constraint;
  update_columns: Array<Inventory_Client_Invoice_Status_Update_Column>;
  where?: Maybe<Inventory_Client_Invoice_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Order_By = {
  client_invoice_status_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_invoice_status" */
export enum Inventory_Client_Invoice_Status_Select_Column {
  /** column name */
  ClientInvoiceStatusName = "client_invoice_status_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Set_Input = {
  client_invoice_status_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Invoice_Status_Stddev_Fields = {
  __typename?: "inventory_client_invoice_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Invoice_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_client_invoice_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Invoice_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_client_invoice_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Invoice_Status_Sum_Fields = {
  __typename?: "inventory_client_invoice_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_invoice_status" */
export enum Inventory_Client_Invoice_Status_Update_Column {
  /** column name */
  ClientInvoiceStatusName = "client_invoice_status_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Invoice_Status_Var_Pop_Fields = {
  __typename?: "inventory_client_invoice_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Invoice_Status_Var_Samp_Fields = {
  __typename?: "inventory_client_invoice_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Invoice_Status_Variance_Fields = {
  __typename?: "inventory_client_invoice_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_invoice_status" */
export type Inventory_Client_Invoice_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Invoice_Stddev_Fields = {
  __typename?: "inventory_client_invoice_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_invoice_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Invoice_Stddev_Pop_Fields = {
  __typename?: "inventory_client_invoice_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_invoice_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Invoice_Stddev_Samp_Fields = {
  __typename?: "inventory_client_invoice_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_invoice_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Invoice_Sum_Fields = {
  __typename?: "inventory_client_invoice_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  client_invoice_status_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_invoice" */
export enum Inventory_Client_Invoice_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  ClientInvoiceStatusId = "client_invoice_status_id",
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceTime = "invoice_time",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Invoice_Var_Pop_Fields = {
  __typename?: "inventory_client_invoice_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_invoice_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Invoice_Var_Samp_Fields = {
  __typename?: "inventory_client_invoice_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_invoice_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Invoice_Variance_Fields = {
  __typename?: "inventory_client_invoice_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_invoice_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_invoice" */
export type Inventory_Client_Invoice_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  client_invoice_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.client_order" */
export type Inventory_Client_Order = {
  __typename?: "inventory_client_order";
  account_id: Scalars["Int"];
  /** An object relationship */
  client_account: Inventory_Account;
  /** An array relationship */
  client_order_lines: Array<Inventory_Client_Order_Line>;
  /** An aggregated array relationship */
  client_order_lines_aggregate: Inventory_Client_Order_Line_Aggregate;
  client_order_reference: Scalars["String"];
  /** An object relationship */
  client_order_status: Inventory_Client_Order_Status;
  client_order_status_id: Scalars["Int"];
  /** An object relationship */
  client_order_type: Inventory_Client_Order_Type;
  client_order_type_id: Scalars["Int"];
  created_time: Scalars["timestamptz"];
  /** An object relationship */
  destination: Inventory_Location;
  due_date?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  folder?: Maybe<Inventory_Folder>;
  folder_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  location_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.client_order" */
export type Inventory_Client_OrderClient_Order_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.client_order" */
export type Inventory_Client_OrderClient_Order_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** aggregated selection of "inventory.client_order" */
export type Inventory_Client_Order_Aggregate = {
  __typename?: "inventory_client_order_aggregate";
  aggregate?: Maybe<Inventory_Client_Order_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Order>;
};

/** aggregate fields of "inventory.client_order" */
export type Inventory_Client_Order_Aggregate_Fields = {
  __typename?: "inventory_client_order_aggregate_fields";
  avg?: Maybe<Inventory_Client_Order_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Order_Max_Fields>;
  min?: Maybe<Inventory_Client_Order_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Order_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Order_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Order_Variance_Fields>;
};

/** aggregate fields of "inventory.client_order" */
export type Inventory_Client_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_order" */
export type Inventory_Client_Order_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Order_Max_Order_By>;
  min?: Maybe<Inventory_Client_Order_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Order_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Order_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_order" */
export type Inventory_Client_Order_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Order_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Order_Avg_Fields = {
  __typename?: "inventory_client_order_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_order_status_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_order". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Order_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Order_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Order_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Order_Bool_Exp>>>;
  account_id?: Maybe<Int_Comparison_Exp>;
  client_account?: Maybe<Inventory_Account_Bool_Exp>;
  client_order_lines?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
  client_order_reference?: Maybe<String_Comparison_Exp>;
  client_order_status?: Maybe<Inventory_Client_Order_Status_Bool_Exp>;
  client_order_status_id?: Maybe<Int_Comparison_Exp>;
  client_order_type?: Maybe<Inventory_Client_Order_Type_Bool_Exp>;
  client_order_type_id?: Maybe<Int_Comparison_Exp>;
  created_time?: Maybe<Timestamptz_Comparison_Exp>;
  destination?: Maybe<Inventory_Location_Bool_Exp>;
  due_date?: Maybe<Timestamptz_Comparison_Exp>;
  folder?: Maybe<Inventory_Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_order" */
export enum Inventory_Client_Order_Constraint {
  /** unique or primary key constraint */
  ClientOrderClientOrderReferenceKey = "client_order_client_order_reference_key",
  /** unique or primary key constraint */
  ClientOrderPkey = "client_order_pkey",
}

/** input type for incrementing integer column in table "inventory.client_order" */
export type Inventory_Client_Order_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  client_order_status_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.client_order" */
export type Inventory_Client_Order_Insert_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  client_account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  client_order_lines?: Maybe<Inventory_Client_Order_Line_Arr_Rel_Insert_Input>;
  client_order_reference?: Maybe<Scalars["String"]>;
  client_order_status?: Maybe<Inventory_Client_Order_Status_Obj_Rel_Insert_Input>;
  client_order_status_id?: Maybe<Scalars["Int"]>;
  client_order_type?: Maybe<Inventory_Client_Order_Type_Obj_Rel_Insert_Input>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  destination?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  folder?: Maybe<Inventory_Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.client_order_line" */
export type Inventory_Client_Order_Line = {
  __typename?: "inventory_client_order_line";
  actioned_qty: Scalars["Int"];
  cancelled_qty: Scalars["Int"];
  /** An array relationship */
  client_invoice_lines: Array<Inventory_Client_Invoice_Line>;
  /** An aggregated array relationship */
  client_invoice_lines_aggregate: Inventory_Client_Invoice_Line_Aggregate;
  /** An object relationship */
  client_order: Inventory_Client_Order;
  client_order_id: Scalars["Int"];
  /** An object relationship */
  client_order_line_status: Inventory_Client_Order_Line_Status;
  client_order_line_status_id: Scalars["Int"];
  /** An object relationship */
  client_order_line_type: Inventory_Client_Order_Line_Type;
  client_order_line_type_id: Scalars["Int"];
  /** An object relationship */
  client_order_type: Inventory_Client_Order_Type;
  client_order_type_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  outstanding_qty?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  product?: Maybe<Inventory_Product>;
  product_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  product_pricing?: Maybe<Inventory_Product_Pricing>;
  product_pricing_id?: Maybe<Scalars["Int"]>;
  requested_qty: Scalars["Int"];
  reserved_qty: Scalars["Int"];
  /** An array relationship */
  shipment_lines: Array<Inventory_Shipment_Line>;
  /** An aggregated array relationship */
  shipment_lines_aggregate: Inventory_Shipment_Line_Aggregate;
  transfer_from_location_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.client_order_line" */
export type Inventory_Client_Order_LineClient_Invoice_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.client_order_line" */
export type Inventory_Client_Order_LineClient_Invoice_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.client_order_line" */
export type Inventory_Client_Order_LineShipment_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.client_order_line" */
export type Inventory_Client_Order_LineShipment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** aggregated selection of "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Aggregate = {
  __typename?: "inventory_client_order_line_aggregate";
  aggregate?: Maybe<Inventory_Client_Order_Line_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Order_Line>;
};

/** aggregate fields of "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Aggregate_Fields = {
  __typename?: "inventory_client_order_line_aggregate_fields";
  avg?: Maybe<Inventory_Client_Order_Line_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Order_Line_Max_Fields>;
  min?: Maybe<Inventory_Client_Order_Line_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Order_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Order_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Order_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Order_Line_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Order_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Order_Line_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Order_Line_Variance_Fields>;
};

/** aggregate fields of "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Order_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Order_Line_Max_Order_By>;
  min?: Maybe<Inventory_Client_Order_Line_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Order_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Order_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Order_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Order_Line_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Order_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Order_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Order_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Order_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Order_Line_Avg_Fields = {
  __typename?: "inventory_client_order_line_avg_fields";
  actioned_qty?: Maybe<Scalars["Float"]>;
  cancelled_qty?: Maybe<Scalars["Float"]>;
  client_order_id?: Maybe<Scalars["Float"]>;
  client_order_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_type_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  product_pricing_id?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  transfer_from_location_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Avg_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_order_line". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Order_Line_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Order_Line_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Order_Line_Bool_Exp>>>;
  actioned_qty?: Maybe<Int_Comparison_Exp>;
  cancelled_qty?: Maybe<Int_Comparison_Exp>;
  client_invoice_lines?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
  client_order?: Maybe<Inventory_Client_Order_Bool_Exp>;
  client_order_id?: Maybe<Int_Comparison_Exp>;
  client_order_line_status?: Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>;
  client_order_line_status_id?: Maybe<Int_Comparison_Exp>;
  client_order_line_type?: Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>;
  client_order_line_type_id?: Maybe<Int_Comparison_Exp>;
  client_order_type?: Maybe<Inventory_Client_Order_Type_Bool_Exp>;
  client_order_type_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outstanding_qty?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Inventory_Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  product_pricing?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
  product_pricing_id?: Maybe<Int_Comparison_Exp>;
  requested_qty?: Maybe<Int_Comparison_Exp>;
  reserved_qty?: Maybe<Int_Comparison_Exp>;
  shipment_lines?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
  transfer_from_location_id?: Maybe<Int_Comparison_Exp>;
  unit_price?: Maybe<Numeric_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_order_line" */
export enum Inventory_Client_Order_Line_Constraint {
  /** unique or primary key constraint */
  ClientOrderLinePkey = "client_order_line_pkey",
}

/** input type for incrementing integer column in table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Inc_Input = {
  actioned_qty?: Maybe<Scalars["Int"]>;
  cancelled_qty?: Maybe<Scalars["Int"]>;
  client_order_id?: Maybe<Scalars["Int"]>;
  client_order_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_type_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_id?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  transfer_from_location_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Insert_Input = {
  actioned_qty?: Maybe<Scalars["Int"]>;
  cancelled_qty?: Maybe<Scalars["Int"]>;
  client_invoice_lines?: Maybe<Inventory_Client_Invoice_Line_Arr_Rel_Insert_Input>;
  client_order?: Maybe<Inventory_Client_Order_Obj_Rel_Insert_Input>;
  client_order_id?: Maybe<Scalars["Int"]>;
  client_order_line_status?: Maybe<Inventory_Client_Order_Line_Status_Obj_Rel_Insert_Input>;
  client_order_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_type?: Maybe<Inventory_Client_Order_Line_Type_Obj_Rel_Insert_Input>;
  client_order_line_type_id?: Maybe<Scalars["Int"]>;
  client_order_type?: Maybe<Inventory_Client_Order_Type_Obj_Rel_Insert_Input>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  product?: Maybe<Inventory_Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing?: Maybe<Inventory_Product_Pricing_Obj_Rel_Insert_Input>;
  product_pricing_id?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  shipment_lines?: Maybe<Inventory_Shipment_Line_Arr_Rel_Insert_Input>;
  transfer_from_location_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Client_Order_Line_Max_Fields = {
  __typename?: "inventory_client_order_line_max_fields";
  actioned_qty?: Maybe<Scalars["Int"]>;
  cancelled_qty?: Maybe<Scalars["Int"]>;
  client_order_id?: Maybe<Scalars["Int"]>;
  client_order_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_type_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_id?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  transfer_from_location_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Max_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Order_Line_Min_Fields = {
  __typename?: "inventory_client_order_line_min_fields";
  actioned_qty?: Maybe<Scalars["Int"]>;
  cancelled_qty?: Maybe<Scalars["Int"]>;
  client_order_id?: Maybe<Scalars["Int"]>;
  client_order_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_type_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_id?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  transfer_from_location_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Min_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Mutation_Response = {
  __typename?: "inventory_client_order_line_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Order_Line>;
};

/** input type for inserting object relation for remote table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Order_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Line_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_On_Conflict = {
  constraint: Inventory_Client_Order_Line_Constraint;
  update_columns: Array<Inventory_Client_Order_Line_Update_Column>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_invoice_lines_aggregate?: Maybe<Inventory_Client_Invoice_Line_Aggregate_Order_By>;
  client_order?: Maybe<Inventory_Client_Order_Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status?: Maybe<Inventory_Client_Order_Line_Status_Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type?: Maybe<Inventory_Client_Order_Line_Type_Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type?: Maybe<Inventory_Client_Order_Type_Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product?: Maybe<Inventory_Product_Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing?: Maybe<Inventory_Product_Pricing_Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_lines_aggregate?: Maybe<Inventory_Shipment_Line_Aggregate_Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_order_line" */
export enum Inventory_Client_Order_Line_Select_Column {
  /** column name */
  ActionedQty = "actioned_qty",
  /** column name */
  CancelledQty = "cancelled_qty",
  /** column name */
  ClientOrderId = "client_order_id",
  /** column name */
  ClientOrderLineStatusId = "client_order_line_status_id",
  /** column name */
  ClientOrderLineTypeId = "client_order_line_type_id",
  /** column name */
  ClientOrderTypeId = "client_order_type_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutstandingQty = "outstanding_qty",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductPricingId = "product_pricing_id",
  /** column name */
  RequestedQty = "requested_qty",
  /** column name */
  ReservedQty = "reserved_qty",
  /** column name */
  TransferFromLocationId = "transfer_from_location_id",
  /** column name */
  UnitPrice = "unit_price",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Set_Input = {
  actioned_qty?: Maybe<Scalars["Int"]>;
  cancelled_qty?: Maybe<Scalars["Int"]>;
  client_order_id?: Maybe<Scalars["Int"]>;
  client_order_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_type_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_id?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  transfer_from_location_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status = {
  __typename?: "inventory_client_order_line_status";
  description: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  line_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Aggregate = {
  __typename?: "inventory_client_order_line_status_aggregate";
  aggregate?: Maybe<Inventory_Client_Order_Line_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Order_Line_Status>;
};

/** aggregate fields of "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Aggregate_Fields = {
  __typename?: "inventory_client_order_line_status_aggregate_fields";
  avg?: Maybe<Inventory_Client_Order_Line_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Order_Line_Status_Max_Fields>;
  min?: Maybe<Inventory_Client_Order_Line_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Order_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Order_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Order_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Order_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Order_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Order_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Order_Line_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Order_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Order_Line_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Order_Line_Status_Max_Order_By>;
  min?: Maybe<Inventory_Client_Order_Line_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Order_Line_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Order_Line_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Order_Line_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Order_Line_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Order_Line_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Order_Line_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Order_Line_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Order_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Line_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Order_Line_Status_Avg_Fields = {
  __typename?: "inventory_client_order_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_order_line_status". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Order_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  line_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_order_line_status" */
export enum Inventory_Client_Order_Line_Status_Constraint {
  /** unique or primary key constraint */
  ClientOrderLineStatusLineStatusNameKey = "client_order_line_status_line_status_name_key",
  /** unique or primary key constraint */
  ClientOrderLineStatusPkey = "client_order_line_status_pkey",
}

/** input type for incrementing integer column in table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Client_Order_Line_Status_Max_Fields = {
  __typename?: "inventory_client_order_line_status_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  line_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Max_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Order_Line_Status_Min_Fields = {
  __typename?: "inventory_client_order_line_status_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  line_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Min_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Mutation_Response = {
  __typename?: "inventory_client_order_line_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Order_Line_Status>;
};

/** input type for inserting object relation for remote table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Order_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Line_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_On_Conflict = {
  constraint: Inventory_Client_Order_Line_Status_Constraint;
  update_columns: Array<Inventory_Client_Order_Line_Status_Update_Column>;
  where?: Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_order_line_status" */
export enum Inventory_Client_Order_Line_Status_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LineStatusName = "line_status_name",
}

/** input type for updating data in table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Order_Line_Status_Stddev_Fields = {
  __typename?: "inventory_client_order_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Order_Line_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_client_order_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Order_Line_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_client_order_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Order_Line_Status_Sum_Fields = {
  __typename?: "inventory_client_order_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_order_line_status" */
export enum Inventory_Client_Order_Line_Status_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LineStatusName = "line_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Order_Line_Status_Var_Pop_Fields = {
  __typename?: "inventory_client_order_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Order_Line_Status_Var_Samp_Fields = {
  __typename?: "inventory_client_order_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Order_Line_Status_Variance_Fields = {
  __typename?: "inventory_client_order_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_order_line_status" */
export type Inventory_Client_Order_Line_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Order_Line_Stddev_Fields = {
  __typename?: "inventory_client_order_line_stddev_fields";
  actioned_qty?: Maybe<Scalars["Float"]>;
  cancelled_qty?: Maybe<Scalars["Float"]>;
  client_order_id?: Maybe<Scalars["Float"]>;
  client_order_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_type_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  product_pricing_id?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  transfer_from_location_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Stddev_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Order_Line_Stddev_Pop_Fields = {
  __typename?: "inventory_client_order_line_stddev_pop_fields";
  actioned_qty?: Maybe<Scalars["Float"]>;
  cancelled_qty?: Maybe<Scalars["Float"]>;
  client_order_id?: Maybe<Scalars["Float"]>;
  client_order_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_type_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  product_pricing_id?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  transfer_from_location_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Stddev_Pop_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Order_Line_Stddev_Samp_Fields = {
  __typename?: "inventory_client_order_line_stddev_samp_fields";
  actioned_qty?: Maybe<Scalars["Float"]>;
  cancelled_qty?: Maybe<Scalars["Float"]>;
  client_order_id?: Maybe<Scalars["Float"]>;
  client_order_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_type_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  product_pricing_id?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  transfer_from_location_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Stddev_Samp_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Order_Line_Sum_Fields = {
  __typename?: "inventory_client_order_line_sum_fields";
  actioned_qty?: Maybe<Scalars["Int"]>;
  cancelled_qty?: Maybe<Scalars["Int"]>;
  client_order_id?: Maybe<Scalars["Int"]>;
  client_order_line_status_id?: Maybe<Scalars["Int"]>;
  client_order_line_type_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_id?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  transfer_from_location_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Sum_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type = {
  __typename?: "inventory_client_order_line_type";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  line_type_name: Scalars["String"];
};

/** aggregated selection of "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Aggregate = {
  __typename?: "inventory_client_order_line_type_aggregate";
  aggregate?: Maybe<Inventory_Client_Order_Line_Type_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Order_Line_Type>;
};

/** aggregate fields of "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Aggregate_Fields = {
  __typename?: "inventory_client_order_line_type_aggregate_fields";
  avg?: Maybe<Inventory_Client_Order_Line_Type_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Order_Line_Type_Max_Fields>;
  min?: Maybe<Inventory_Client_Order_Line_Type_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Order_Line_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Order_Line_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Order_Line_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Order_Line_Type_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Order_Line_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Order_Line_Type_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Order_Line_Type_Variance_Fields>;
};

/** aggregate fields of "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Order_Line_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Order_Line_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Order_Line_Type_Max_Order_By>;
  min?: Maybe<Inventory_Client_Order_Line_Type_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Order_Line_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Order_Line_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Order_Line_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Order_Line_Type_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Order_Line_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Order_Line_Type_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Order_Line_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Order_Line_Type_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Line_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Order_Line_Type_Avg_Fields = {
  __typename?: "inventory_client_order_line_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_order_line_type". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Order_Line_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  line_type_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_order_line_type" */
export enum Inventory_Client_Order_Line_Type_Constraint {
  /** unique or primary key constraint */
  ClientOrderLineTypeLineTypeNameKey = "client_order_line_type_line_type_name_key",
  /** unique or primary key constraint */
  ClientOrderLineTypePkey = "client_order_line_type_pkey",
}

/** input type for incrementing integer column in table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  line_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Client_Order_Line_Type_Max_Fields = {
  __typename?: "inventory_client_order_line_type_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  line_type_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line_type_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Order_Line_Type_Min_Fields = {
  __typename?: "inventory_client_order_line_type_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  line_type_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line_type_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Mutation_Response = {
  __typename?: "inventory_client_order_line_type_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Order_Line_Type>;
};

/** input type for inserting object relation for remote table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Order_Line_Type_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Line_Type_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_On_Conflict = {
  constraint: Inventory_Client_Order_Line_Type_Constraint;
  update_columns: Array<Inventory_Client_Order_Line_Type_Update_Column>;
  where?: Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  line_type_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_order_line_type" */
export enum Inventory_Client_Order_Line_Type_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LineTypeName = "line_type_name",
}

/** input type for updating data in table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  line_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Order_Line_Type_Stddev_Fields = {
  __typename?: "inventory_client_order_line_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Order_Line_Type_Stddev_Pop_Fields = {
  __typename?: "inventory_client_order_line_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Order_Line_Type_Stddev_Samp_Fields = {
  __typename?: "inventory_client_order_line_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Order_Line_Type_Sum_Fields = {
  __typename?: "inventory_client_order_line_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_order_line_type" */
export enum Inventory_Client_Order_Line_Type_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LineTypeName = "line_type_name",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Order_Line_Type_Var_Pop_Fields = {
  __typename?: "inventory_client_order_line_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Order_Line_Type_Var_Samp_Fields = {
  __typename?: "inventory_client_order_line_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Order_Line_Type_Variance_Fields = {
  __typename?: "inventory_client_order_line_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_order_line_type" */
export type Inventory_Client_Order_Line_Type_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_order_line" */
export enum Inventory_Client_Order_Line_Update_Column {
  /** column name */
  ActionedQty = "actioned_qty",
  /** column name */
  CancelledQty = "cancelled_qty",
  /** column name */
  ClientOrderId = "client_order_id",
  /** column name */
  ClientOrderLineStatusId = "client_order_line_status_id",
  /** column name */
  ClientOrderLineTypeId = "client_order_line_type_id",
  /** column name */
  ClientOrderTypeId = "client_order_type_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutstandingQty = "outstanding_qty",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductPricingId = "product_pricing_id",
  /** column name */
  RequestedQty = "requested_qty",
  /** column name */
  ReservedQty = "reserved_qty",
  /** column name */
  TransferFromLocationId = "transfer_from_location_id",
  /** column name */
  UnitPrice = "unit_price",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Order_Line_Var_Pop_Fields = {
  __typename?: "inventory_client_order_line_var_pop_fields";
  actioned_qty?: Maybe<Scalars["Float"]>;
  cancelled_qty?: Maybe<Scalars["Float"]>;
  client_order_id?: Maybe<Scalars["Float"]>;
  client_order_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_type_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  product_pricing_id?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  transfer_from_location_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Var_Pop_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Order_Line_Var_Samp_Fields = {
  __typename?: "inventory_client_order_line_var_samp_fields";
  actioned_qty?: Maybe<Scalars["Float"]>;
  cancelled_qty?: Maybe<Scalars["Float"]>;
  client_order_id?: Maybe<Scalars["Float"]>;
  client_order_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_type_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  product_pricing_id?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  transfer_from_location_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Var_Samp_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Order_Line_Variance_Fields = {
  __typename?: "inventory_client_order_line_variance_fields";
  actioned_qty?: Maybe<Scalars["Float"]>;
  cancelled_qty?: Maybe<Scalars["Float"]>;
  client_order_id?: Maybe<Scalars["Float"]>;
  client_order_line_status_id?: Maybe<Scalars["Float"]>;
  client_order_line_type_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  product_pricing_id?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  transfer_from_location_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_order_line" */
export type Inventory_Client_Order_Line_Variance_Order_By = {
  actioned_qty?: Maybe<Order_By>;
  cancelled_qty?: Maybe<Order_By>;
  client_order_id?: Maybe<Order_By>;
  client_order_line_status_id?: Maybe<Order_By>;
  client_order_line_type_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_id?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  transfer_from_location_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Inventory_Client_Order_Max_Fields = {
  __typename?: "inventory_client_order_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  client_order_reference?: Maybe<Scalars["String"]>;
  client_order_status_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_reference?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Order_Min_Fields = {
  __typename?: "inventory_client_order_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  client_order_reference?: Maybe<Scalars["String"]>;
  client_order_status_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_reference?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_order" */
export type Inventory_Client_Order_Mutation_Response = {
  __typename?: "inventory_client_order_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Order>;
};

/** input type for inserting object relation for remote table "inventory.client_order" */
export type Inventory_Client_Order_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Order_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_order" */
export type Inventory_Client_Order_On_Conflict = {
  constraint: Inventory_Client_Order_Constraint;
  update_columns: Array<Inventory_Client_Order_Update_Column>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_order" */
export type Inventory_Client_Order_Order_By = {
  account_id?: Maybe<Order_By>;
  client_account?: Maybe<Inventory_Account_Order_By>;
  client_order_lines_aggregate?: Maybe<Inventory_Client_Order_Line_Aggregate_Order_By>;
  client_order_reference?: Maybe<Order_By>;
  client_order_status?: Maybe<Inventory_Client_Order_Status_Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type?: Maybe<Inventory_Client_Order_Type_Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  destination?: Maybe<Inventory_Location_Order_By>;
  due_date?: Maybe<Order_By>;
  folder?: Maybe<Inventory_Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_order" */
export type Inventory_Client_Order_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_order" */
export enum Inventory_Client_Order_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  ClientOrderReference = "client_order_reference",
  /** column name */
  ClientOrderStatusId = "client_order_status_id",
  /** column name */
  ClientOrderTypeId = "client_order_type_id",
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  DueDate = "due_date",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.client_order" */
export type Inventory_Client_Order_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  client_order_reference?: Maybe<Scalars["String"]>;
  client_order_status_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.client_order_status" */
export type Inventory_Client_Order_Status = {
  __typename?: "inventory_client_order_status";
  description: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  order_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Aggregate = {
  __typename?: "inventory_client_order_status_aggregate";
  aggregate?: Maybe<Inventory_Client_Order_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Order_Status>;
};

/** aggregate fields of "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Aggregate_Fields = {
  __typename?: "inventory_client_order_status_aggregate_fields";
  avg?: Maybe<Inventory_Client_Order_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Order_Status_Max_Fields>;
  min?: Maybe<Inventory_Client_Order_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Order_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Order_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Order_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Order_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Order_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Order_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Order_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Order_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Order_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Order_Status_Max_Order_By>;
  min?: Maybe<Inventory_Client_Order_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Order_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Order_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Order_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Order_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Order_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Order_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Order_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Order_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Order_Status_Avg_Fields = {
  __typename?: "inventory_client_order_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_order_status". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Order_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Order_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Order_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Order_Status_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  order_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_order_status" */
export enum Inventory_Client_Order_Status_Constraint {
  /** unique or primary key constraint */
  ClientOrderStatusOrderStatusNameKey = "client_order_status_order_status_name_key",
  /** unique or primary key constraint */
  ClientOrderStatusPkey = "client_order_status_pkey",
}

/** input type for incrementing integer column in table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  order_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Client_Order_Status_Max_Fields = {
  __typename?: "inventory_client_order_status_max_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  order_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Max_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  order_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Order_Status_Min_Fields = {
  __typename?: "inventory_client_order_status_min_fields";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  order_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Min_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  order_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Mutation_Response = {
  __typename?: "inventory_client_order_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Order_Status>;
};

/** input type for inserting object relation for remote table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Order_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_On_Conflict = {
  constraint: Inventory_Client_Order_Status_Constraint;
  update_columns: Array<Inventory_Client_Order_Status_Update_Column>;
  where?: Maybe<Inventory_Client_Order_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  order_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_order_status" */
export enum Inventory_Client_Order_Status_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrderStatusName = "order_status_name",
}

/** input type for updating data in table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  order_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Order_Status_Stddev_Fields = {
  __typename?: "inventory_client_order_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Order_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_client_order_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Order_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_client_order_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Order_Status_Sum_Fields = {
  __typename?: "inventory_client_order_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_order_status" */
export enum Inventory_Client_Order_Status_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrderStatusName = "order_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Order_Status_Var_Pop_Fields = {
  __typename?: "inventory_client_order_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Order_Status_Var_Samp_Fields = {
  __typename?: "inventory_client_order_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Order_Status_Variance_Fields = {
  __typename?: "inventory_client_order_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_order_status" */
export type Inventory_Client_Order_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Order_Stddev_Fields = {
  __typename?: "inventory_client_order_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_order_status_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Order_Stddev_Pop_Fields = {
  __typename?: "inventory_client_order_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_order_status_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Order_Stddev_Samp_Fields = {
  __typename?: "inventory_client_order_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_order_status_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Order_Sum_Fields = {
  __typename?: "inventory_client_order_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  client_order_status_id?: Maybe<Scalars["Int"]>;
  client_order_type_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.client_order_type" */
export type Inventory_Client_Order_Type = {
  __typename?: "inventory_client_order_type";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  order_type_name: Scalars["String"];
};

/** aggregated selection of "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Aggregate = {
  __typename?: "inventory_client_order_type_aggregate";
  aggregate?: Maybe<Inventory_Client_Order_Type_Aggregate_Fields>;
  nodes: Array<Inventory_Client_Order_Type>;
};

/** aggregate fields of "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Aggregate_Fields = {
  __typename?: "inventory_client_order_type_aggregate_fields";
  avg?: Maybe<Inventory_Client_Order_Type_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Client_Order_Type_Max_Fields>;
  min?: Maybe<Inventory_Client_Order_Type_Min_Fields>;
  stddev?: Maybe<Inventory_Client_Order_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Client_Order_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Client_Order_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Client_Order_Type_Sum_Fields>;
  var_pop?: Maybe<Inventory_Client_Order_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Client_Order_Type_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Client_Order_Type_Variance_Fields>;
};

/** aggregate fields of "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Client_Order_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Client_Order_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Client_Order_Type_Max_Order_By>;
  min?: Maybe<Inventory_Client_Order_Type_Min_Order_By>;
  stddev?: Maybe<Inventory_Client_Order_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Client_Order_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Client_Order_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Client_Order_Type_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Client_Order_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Client_Order_Type_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Client_Order_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Client_Order_Type_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Client_Order_Type_Avg_Fields = {
  __typename?: "inventory_client_order_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.client_order_type". All fields are combined with a logical 'AND'. */
export type Inventory_Client_Order_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Client_Order_Type_Bool_Exp>>>;
  _not?: Maybe<Inventory_Client_Order_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Client_Order_Type_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  order_type_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.client_order_type" */
export enum Inventory_Client_Order_Type_Constraint {
  /** unique or primary key constraint */
  ClientOrderTypeOrderTypeNameKey = "client_order_type_order_type_name_key",
  /** unique or primary key constraint */
  ClientOrderTypePkey = "client_order_type_pkey",
}

/** input type for incrementing integer column in table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  order_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Client_Order_Type_Max_Fields = {
  __typename?: "inventory_client_order_type_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  order_type_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  order_type_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Client_Order_Type_Min_Fields = {
  __typename?: "inventory_client_order_type_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  order_type_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  order_type_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Mutation_Response = {
  __typename?: "inventory_client_order_type_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Client_Order_Type>;
};

/** input type for inserting object relation for remote table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Obj_Rel_Insert_Input = {
  data: Inventory_Client_Order_Type_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Type_On_Conflict>;
};

/** on conflict condition type for table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_On_Conflict = {
  constraint: Inventory_Client_Order_Type_Constraint;
  update_columns: Array<Inventory_Client_Order_Type_Update_Column>;
  where?: Maybe<Inventory_Client_Order_Type_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  order_type_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.client_order_type" */
export enum Inventory_Client_Order_Type_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrderTypeName = "order_type_name",
}

/** input type for updating data in table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  order_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Client_Order_Type_Stddev_Fields = {
  __typename?: "inventory_client_order_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Client_Order_Type_Stddev_Pop_Fields = {
  __typename?: "inventory_client_order_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Client_Order_Type_Stddev_Samp_Fields = {
  __typename?: "inventory_client_order_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Client_Order_Type_Sum_Fields = {
  __typename?: "inventory_client_order_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_order_type" */
export enum Inventory_Client_Order_Type_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrderTypeName = "order_type_name",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Order_Type_Var_Pop_Fields = {
  __typename?: "inventory_client_order_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Order_Type_Var_Samp_Fields = {
  __typename?: "inventory_client_order_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Order_Type_Variance_Fields = {
  __typename?: "inventory_client_order_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_order_type" */
export type Inventory_Client_Order_Type_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.client_order" */
export enum Inventory_Client_Order_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  ClientOrderReference = "client_order_reference",
  /** column name */
  ClientOrderStatusId = "client_order_status_id",
  /** column name */
  ClientOrderTypeId = "client_order_type_id",
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  DueDate = "due_date",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Client_Order_Var_Pop_Fields = {
  __typename?: "inventory_client_order_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_order_status_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Client_Order_Var_Samp_Fields = {
  __typename?: "inventory_client_order_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_order_status_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Client_Order_Variance_Fields = {
  __typename?: "inventory_client_order_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  client_order_status_id?: Maybe<Scalars["Float"]>;
  client_order_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.client_order" */
export type Inventory_Client_Order_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  client_order_status_id?: Maybe<Order_By>;
  client_order_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.country" */
export type Inventory_Country = {
  __typename?: "inventory_country";
  /** An array relationship */
  addresses: Array<Inventory_Address>;
  /** An aggregated array relationship */
  addresses_aggregate: Inventory_Address_Aggregate;
  continent: Scalars["String"];
  country_code: Scalars["String"];
  country_name: Scalars["String"];
  internet_tld: Scalars["String"];
  last_updated: Scalars["timestamptz"];
  official_name: Scalars["String"];
  region: Scalars["String"];
  sovereignty: Scalars["String"];
};

/** columns and relationships of "inventory.country" */
export type Inventory_CountryAddressesArgs = {
  distinct_on?: Maybe<Array<Inventory_Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Address_Order_By>>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** columns and relationships of "inventory.country" */
export type Inventory_CountryAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Address_Order_By>>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** aggregated selection of "inventory.country" */
export type Inventory_Country_Aggregate = {
  __typename?: "inventory_country_aggregate";
  aggregate?: Maybe<Inventory_Country_Aggregate_Fields>;
  nodes: Array<Inventory_Country>;
};

/** aggregate fields of "inventory.country" */
export type Inventory_Country_Aggregate_Fields = {
  __typename?: "inventory_country_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Country_Max_Fields>;
  min?: Maybe<Inventory_Country_Min_Fields>;
};

/** aggregate fields of "inventory.country" */
export type Inventory_Country_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Country_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.country" */
export type Inventory_Country_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Country_Max_Order_By>;
  min?: Maybe<Inventory_Country_Min_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.country" */
export type Inventory_Country_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Country_Insert_Input>;
  on_conflict?: Maybe<Inventory_Country_On_Conflict>;
};

/** Boolean expression to filter rows from the table "inventory.country". All fields are combined with a logical 'AND'. */
export type Inventory_Country_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Country_Bool_Exp>>>;
  _not?: Maybe<Inventory_Country_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Country_Bool_Exp>>>;
  addresses?: Maybe<Inventory_Address_Bool_Exp>;
  continent?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  country_name?: Maybe<String_Comparison_Exp>;
  internet_tld?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  official_name?: Maybe<String_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  sovereignty?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.country" */
export enum Inventory_Country_Constraint {
  /** unique or primary key constraint */
  PkCountry = "PK_country",
}

/** input type for inserting data into table "inventory.country" */
export type Inventory_Country_Insert_Input = {
  addresses?: Maybe<Inventory_Address_Arr_Rel_Insert_Input>;
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Country_Max_Fields = {
  __typename?: "inventory_country_max_fields";
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.country" */
export type Inventory_Country_Max_Order_By = {
  continent?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  country_name?: Maybe<Order_By>;
  internet_tld?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  official_name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  sovereignty?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Country_Min_Fields = {
  __typename?: "inventory_country_min_fields";
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.country" */
export type Inventory_Country_Min_Order_By = {
  continent?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  country_name?: Maybe<Order_By>;
  internet_tld?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  official_name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  sovereignty?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.country" */
export type Inventory_Country_Mutation_Response = {
  __typename?: "inventory_country_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Country>;
};

/** input type for inserting object relation for remote table "inventory.country" */
export type Inventory_Country_Obj_Rel_Insert_Input = {
  data: Inventory_Country_Insert_Input;
  on_conflict?: Maybe<Inventory_Country_On_Conflict>;
};

/** on conflict condition type for table "inventory.country" */
export type Inventory_Country_On_Conflict = {
  constraint: Inventory_Country_Constraint;
  update_columns: Array<Inventory_Country_Update_Column>;
  where?: Maybe<Inventory_Country_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.country" */
export type Inventory_Country_Order_By = {
  addresses_aggregate?: Maybe<Inventory_Address_Aggregate_Order_By>;
  continent?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  country_name?: Maybe<Order_By>;
  internet_tld?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  official_name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  sovereignty?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.country" */
export type Inventory_Country_Pk_Columns_Input = {
  country_code: Scalars["String"];
};

/** select columns of table "inventory.country" */
export enum Inventory_Country_Select_Column {
  /** column name */
  Continent = "continent",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  CountryName = "country_name",
  /** column name */
  InternetTld = "internet_tld",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OfficialName = "official_name",
  /** column name */
  Region = "region",
  /** column name */
  Sovereignty = "sovereignty",
}

/** input type for updating data in table "inventory.country" */
export type Inventory_Country_Set_Input = {
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** update columns of table "inventory.country" */
export enum Inventory_Country_Update_Column {
  /** column name */
  Continent = "continent",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  CountryName = "country_name",
  /** column name */
  InternetTld = "internet_tld",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OfficialName = "official_name",
  /** column name */
  Region = "region",
  /** column name */
  Sovereignty = "sovereignty",
}

/** columns and relationships of "inventory.courier" */
export type Inventory_Courier = {
  __typename?: "inventory_courier";
  courier_description: Scalars["String"];
  courier_name: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "inventory.courier" */
export type Inventory_Courier_Aggregate = {
  __typename?: "inventory_courier_aggregate";
  aggregate?: Maybe<Inventory_Courier_Aggregate_Fields>;
  nodes: Array<Inventory_Courier>;
};

/** aggregate fields of "inventory.courier" */
export type Inventory_Courier_Aggregate_Fields = {
  __typename?: "inventory_courier_aggregate_fields";
  avg?: Maybe<Inventory_Courier_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Courier_Max_Fields>;
  min?: Maybe<Inventory_Courier_Min_Fields>;
  stddev?: Maybe<Inventory_Courier_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Courier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Courier_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Courier_Sum_Fields>;
  var_pop?: Maybe<Inventory_Courier_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Courier_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Courier_Variance_Fields>;
};

/** aggregate fields of "inventory.courier" */
export type Inventory_Courier_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Courier_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.courier" */
export type Inventory_Courier_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Courier_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Courier_Max_Order_By>;
  min?: Maybe<Inventory_Courier_Min_Order_By>;
  stddev?: Maybe<Inventory_Courier_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Courier_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Courier_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Courier_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Courier_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Courier_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Courier_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.courier" */
export type Inventory_Courier_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Courier_Insert_Input>;
  on_conflict?: Maybe<Inventory_Courier_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Courier_Avg_Fields = {
  __typename?: "inventory_courier_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.courier" */
export type Inventory_Courier_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.courier". All fields are combined with a logical 'AND'. */
export type Inventory_Courier_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Courier_Bool_Exp>>>;
  _not?: Maybe<Inventory_Courier_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Courier_Bool_Exp>>>;
  courier_description?: Maybe<String_Comparison_Exp>;
  courier_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.courier" */
export enum Inventory_Courier_Constraint {
  /** unique or primary key constraint */
  CourierPkey = "courier_pkey",
}

/** input type for incrementing integer column in table "inventory.courier" */
export type Inventory_Courier_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.courier" */
export type Inventory_Courier_Insert_Input = {
  courier_description?: Maybe<Scalars["String"]>;
  courier_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Courier_Max_Fields = {
  __typename?: "inventory_courier_max_fields";
  courier_description?: Maybe<Scalars["String"]>;
  courier_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.courier" */
export type Inventory_Courier_Max_Order_By = {
  courier_description?: Maybe<Order_By>;
  courier_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Courier_Min_Fields = {
  __typename?: "inventory_courier_min_fields";
  courier_description?: Maybe<Scalars["String"]>;
  courier_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.courier" */
export type Inventory_Courier_Min_Order_By = {
  courier_description?: Maybe<Order_By>;
  courier_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.courier" */
export type Inventory_Courier_Mutation_Response = {
  __typename?: "inventory_courier_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Courier>;
};

/** input type for inserting object relation for remote table "inventory.courier" */
export type Inventory_Courier_Obj_Rel_Insert_Input = {
  data: Inventory_Courier_Insert_Input;
  on_conflict?: Maybe<Inventory_Courier_On_Conflict>;
};

/** on conflict condition type for table "inventory.courier" */
export type Inventory_Courier_On_Conflict = {
  constraint: Inventory_Courier_Constraint;
  update_columns: Array<Inventory_Courier_Update_Column>;
  where?: Maybe<Inventory_Courier_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.courier" */
export type Inventory_Courier_Order_By = {
  courier_description?: Maybe<Order_By>;
  courier_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.courier" */
export type Inventory_Courier_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.courier" */
export enum Inventory_Courier_Select_Column {
  /** column name */
  CourierDescription = "courier_description",
  /** column name */
  CourierName = "courier_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** columns and relationships of "inventory.courier_service" */
export type Inventory_Courier_Service = {
  __typename?: "inventory_courier_service";
  courier_id: Scalars["Int"];
  courier_service_description: Scalars["String"];
  courier_service_name: Scalars["String"];
  courier_type_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "inventory.courier_service" */
export type Inventory_Courier_Service_Aggregate = {
  __typename?: "inventory_courier_service_aggregate";
  aggregate?: Maybe<Inventory_Courier_Service_Aggregate_Fields>;
  nodes: Array<Inventory_Courier_Service>;
};

/** aggregate fields of "inventory.courier_service" */
export type Inventory_Courier_Service_Aggregate_Fields = {
  __typename?: "inventory_courier_service_aggregate_fields";
  avg?: Maybe<Inventory_Courier_Service_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Courier_Service_Max_Fields>;
  min?: Maybe<Inventory_Courier_Service_Min_Fields>;
  stddev?: Maybe<Inventory_Courier_Service_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Courier_Service_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Courier_Service_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Courier_Service_Sum_Fields>;
  var_pop?: Maybe<Inventory_Courier_Service_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Courier_Service_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Courier_Service_Variance_Fields>;
};

/** aggregate fields of "inventory.courier_service" */
export type Inventory_Courier_Service_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Courier_Service_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.courier_service" */
export type Inventory_Courier_Service_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Courier_Service_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Courier_Service_Max_Order_By>;
  min?: Maybe<Inventory_Courier_Service_Min_Order_By>;
  stddev?: Maybe<Inventory_Courier_Service_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Courier_Service_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Courier_Service_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Courier_Service_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Courier_Service_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Courier_Service_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Courier_Service_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.courier_service" */
export type Inventory_Courier_Service_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Courier_Service_Insert_Input>;
  on_conflict?: Maybe<Inventory_Courier_Service_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Courier_Service_Avg_Fields = {
  __typename?: "inventory_courier_service_avg_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  courier_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Avg_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.courier_service". All fields are combined with a logical 'AND'. */
export type Inventory_Courier_Service_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Courier_Service_Bool_Exp>>>;
  _not?: Maybe<Inventory_Courier_Service_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Courier_Service_Bool_Exp>>>;
  courier_id?: Maybe<Int_Comparison_Exp>;
  courier_service_description?: Maybe<String_Comparison_Exp>;
  courier_service_name?: Maybe<String_Comparison_Exp>;
  courier_type_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.courier_service" */
export enum Inventory_Courier_Service_Constraint {
  /** unique or primary key constraint */
  CourierServicePkey = "courier_service_pkey",
}

/** input type for incrementing integer column in table "inventory.courier_service" */
export type Inventory_Courier_Service_Inc_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
  courier_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.courier_service" */
export type Inventory_Courier_Service_Insert_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_description?: Maybe<Scalars["String"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  courier_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Courier_Service_Max_Fields = {
  __typename?: "inventory_courier_service_max_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_description?: Maybe<Scalars["String"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  courier_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Max_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_service_description?: Maybe<Order_By>;
  courier_service_name?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Courier_Service_Min_Fields = {
  __typename?: "inventory_courier_service_min_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_description?: Maybe<Scalars["String"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  courier_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Min_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_service_description?: Maybe<Order_By>;
  courier_service_name?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.courier_service" */
export type Inventory_Courier_Service_Mutation_Response = {
  __typename?: "inventory_courier_service_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Courier_Service>;
};

/** input type for inserting object relation for remote table "inventory.courier_service" */
export type Inventory_Courier_Service_Obj_Rel_Insert_Input = {
  data: Inventory_Courier_Service_Insert_Input;
  on_conflict?: Maybe<Inventory_Courier_Service_On_Conflict>;
};

/** on conflict condition type for table "inventory.courier_service" */
export type Inventory_Courier_Service_On_Conflict = {
  constraint: Inventory_Courier_Service_Constraint;
  update_columns: Array<Inventory_Courier_Service_Update_Column>;
  where?: Maybe<Inventory_Courier_Service_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.courier_service" */
export type Inventory_Courier_Service_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_service_description?: Maybe<Order_By>;
  courier_service_name?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.courier_service" */
export type Inventory_Courier_Service_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.courier_service" */
export enum Inventory_Courier_Service_Select_Column {
  /** column name */
  CourierId = "courier_id",
  /** column name */
  CourierServiceDescription = "courier_service_description",
  /** column name */
  CourierServiceName = "courier_service_name",
  /** column name */
  CourierTypeId = "courier_type_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inventory.courier_service" */
export type Inventory_Courier_Service_Set_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_description?: Maybe<Scalars["String"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  courier_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Courier_Service_Stddev_Fields = {
  __typename?: "inventory_courier_service_stddev_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  courier_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Stddev_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Courier_Service_Stddev_Pop_Fields = {
  __typename?: "inventory_courier_service_stddev_pop_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  courier_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Stddev_Pop_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Courier_Service_Stddev_Samp_Fields = {
  __typename?: "inventory_courier_service_stddev_samp_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  courier_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Stddev_Samp_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Courier_Service_Sum_Fields = {
  __typename?: "inventory_courier_service_sum_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  courier_type_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Sum_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.courier_service" */
export enum Inventory_Courier_Service_Update_Column {
  /** column name */
  CourierId = "courier_id",
  /** column name */
  CourierServiceDescription = "courier_service_description",
  /** column name */
  CourierServiceName = "courier_service_name",
  /** column name */
  CourierTypeId = "courier_type_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** aggregate var_pop on columns */
export type Inventory_Courier_Service_Var_Pop_Fields = {
  __typename?: "inventory_courier_service_var_pop_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  courier_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Var_Pop_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Courier_Service_Var_Samp_Fields = {
  __typename?: "inventory_courier_service_var_samp_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  courier_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Var_Samp_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Courier_Service_Variance_Fields = {
  __typename?: "inventory_courier_service_variance_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  courier_type_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.courier_service" */
export type Inventory_Courier_Service_Variance_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** input type for updating data in table "inventory.courier" */
export type Inventory_Courier_Set_Input = {
  courier_description?: Maybe<Scalars["String"]>;
  courier_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Courier_Stddev_Fields = {
  __typename?: "inventory_courier_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.courier" */
export type Inventory_Courier_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Courier_Stddev_Pop_Fields = {
  __typename?: "inventory_courier_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.courier" */
export type Inventory_Courier_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Courier_Stddev_Samp_Fields = {
  __typename?: "inventory_courier_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.courier" */
export type Inventory_Courier_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Courier_Sum_Fields = {
  __typename?: "inventory_courier_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.courier" */
export type Inventory_Courier_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.courier_type" */
export type Inventory_Courier_Type = {
  __typename?: "inventory_courier_type";
  courier_type_description: Scalars["String"];
  courier_type_name: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "inventory.courier_type" */
export type Inventory_Courier_Type_Aggregate = {
  __typename?: "inventory_courier_type_aggregate";
  aggregate?: Maybe<Inventory_Courier_Type_Aggregate_Fields>;
  nodes: Array<Inventory_Courier_Type>;
};

/** aggregate fields of "inventory.courier_type" */
export type Inventory_Courier_Type_Aggregate_Fields = {
  __typename?: "inventory_courier_type_aggregate_fields";
  avg?: Maybe<Inventory_Courier_Type_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Courier_Type_Max_Fields>;
  min?: Maybe<Inventory_Courier_Type_Min_Fields>;
  stddev?: Maybe<Inventory_Courier_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Courier_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Courier_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Courier_Type_Sum_Fields>;
  var_pop?: Maybe<Inventory_Courier_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Courier_Type_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Courier_Type_Variance_Fields>;
};

/** aggregate fields of "inventory.courier_type" */
export type Inventory_Courier_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Courier_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.courier_type" */
export type Inventory_Courier_Type_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Courier_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Courier_Type_Max_Order_By>;
  min?: Maybe<Inventory_Courier_Type_Min_Order_By>;
  stddev?: Maybe<Inventory_Courier_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Courier_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Courier_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Courier_Type_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Courier_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Courier_Type_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Courier_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.courier_type" */
export type Inventory_Courier_Type_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Courier_Type_Insert_Input>;
  on_conflict?: Maybe<Inventory_Courier_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Courier_Type_Avg_Fields = {
  __typename?: "inventory_courier_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.courier_type". All fields are combined with a logical 'AND'. */
export type Inventory_Courier_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Courier_Type_Bool_Exp>>>;
  _not?: Maybe<Inventory_Courier_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Courier_Type_Bool_Exp>>>;
  courier_type_description?: Maybe<String_Comparison_Exp>;
  courier_type_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.courier_type" */
export enum Inventory_Courier_Type_Constraint {
  /** unique or primary key constraint */
  CourierTypePkey = "courier_type_pkey",
}

/** input type for incrementing integer column in table "inventory.courier_type" */
export type Inventory_Courier_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.courier_type" */
export type Inventory_Courier_Type_Insert_Input = {
  courier_type_description?: Maybe<Scalars["String"]>;
  courier_type_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Courier_Type_Max_Fields = {
  __typename?: "inventory_courier_type_max_fields";
  courier_type_description?: Maybe<Scalars["String"]>;
  courier_type_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Max_Order_By = {
  courier_type_description?: Maybe<Order_By>;
  courier_type_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Courier_Type_Min_Fields = {
  __typename?: "inventory_courier_type_min_fields";
  courier_type_description?: Maybe<Scalars["String"]>;
  courier_type_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Min_Order_By = {
  courier_type_description?: Maybe<Order_By>;
  courier_type_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.courier_type" */
export type Inventory_Courier_Type_Mutation_Response = {
  __typename?: "inventory_courier_type_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Courier_Type>;
};

/** input type for inserting object relation for remote table "inventory.courier_type" */
export type Inventory_Courier_Type_Obj_Rel_Insert_Input = {
  data: Inventory_Courier_Type_Insert_Input;
  on_conflict?: Maybe<Inventory_Courier_Type_On_Conflict>;
};

/** on conflict condition type for table "inventory.courier_type" */
export type Inventory_Courier_Type_On_Conflict = {
  constraint: Inventory_Courier_Type_Constraint;
  update_columns: Array<Inventory_Courier_Type_Update_Column>;
  where?: Maybe<Inventory_Courier_Type_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.courier_type" */
export type Inventory_Courier_Type_Order_By = {
  courier_type_description?: Maybe<Order_By>;
  courier_type_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.courier_type" */
export type Inventory_Courier_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.courier_type" */
export enum Inventory_Courier_Type_Select_Column {
  /** column name */
  CourierTypeDescription = "courier_type_description",
  /** column name */
  CourierTypeName = "courier_type_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inventory.courier_type" */
export type Inventory_Courier_Type_Set_Input = {
  courier_type_description?: Maybe<Scalars["String"]>;
  courier_type_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Courier_Type_Stddev_Fields = {
  __typename?: "inventory_courier_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Courier_Type_Stddev_Pop_Fields = {
  __typename?: "inventory_courier_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Courier_Type_Stddev_Samp_Fields = {
  __typename?: "inventory_courier_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Courier_Type_Sum_Fields = {
  __typename?: "inventory_courier_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.courier_type" */
export enum Inventory_Courier_Type_Update_Column {
  /** column name */
  CourierTypeDescription = "courier_type_description",
  /** column name */
  CourierTypeName = "courier_type_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** aggregate var_pop on columns */
export type Inventory_Courier_Type_Var_Pop_Fields = {
  __typename?: "inventory_courier_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Courier_Type_Var_Samp_Fields = {
  __typename?: "inventory_courier_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Courier_Type_Variance_Fields = {
  __typename?: "inventory_courier_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.courier_type" */
export type Inventory_Courier_Type_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.courier" */
export enum Inventory_Courier_Update_Column {
  /** column name */
  CourierDescription = "courier_description",
  /** column name */
  CourierName = "courier_name",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** aggregate var_pop on columns */
export type Inventory_Courier_Var_Pop_Fields = {
  __typename?: "inventory_courier_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.courier" */
export type Inventory_Courier_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Courier_Var_Samp_Fields = {
  __typename?: "inventory_courier_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.courier" */
export type Inventory_Courier_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Courier_Variance_Fields = {
  __typename?: "inventory_courier_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.courier" */
export type Inventory_Courier_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.currency" */
export type Inventory_Currency = {
  __typename?: "inventory_currency";
  currency_code: Scalars["String"];
  currency_name: Scalars["String"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "inventory.currency" */
export type Inventory_Currency_Aggregate = {
  __typename?: "inventory_currency_aggregate";
  aggregate?: Maybe<Inventory_Currency_Aggregate_Fields>;
  nodes: Array<Inventory_Currency>;
};

/** aggregate fields of "inventory.currency" */
export type Inventory_Currency_Aggregate_Fields = {
  __typename?: "inventory_currency_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Currency_Max_Fields>;
  min?: Maybe<Inventory_Currency_Min_Fields>;
};

/** aggregate fields of "inventory.currency" */
export type Inventory_Currency_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Currency_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.currency" */
export type Inventory_Currency_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Currency_Max_Order_By>;
  min?: Maybe<Inventory_Currency_Min_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.currency" */
export type Inventory_Currency_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Currency_Insert_Input>;
  on_conflict?: Maybe<Inventory_Currency_On_Conflict>;
};

/** Boolean expression to filter rows from the table "inventory.currency". All fields are combined with a logical 'AND'. */
export type Inventory_Currency_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Currency_Bool_Exp>>>;
  _not?: Maybe<Inventory_Currency_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Currency_Bool_Exp>>>;
  currency_code?: Maybe<String_Comparison_Exp>;
  currency_name?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.currency" */
export enum Inventory_Currency_Constraint {
  /** unique or primary key constraint */
  PkCurrency = "PK_currency",
}

/** input type for inserting data into table "inventory.currency" */
export type Inventory_Currency_Insert_Input = {
  currency_code?: Maybe<Scalars["String"]>;
  currency_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Currency_Max_Fields = {
  __typename?: "inventory_currency_max_fields";
  currency_code?: Maybe<Scalars["String"]>;
  currency_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.currency" */
export type Inventory_Currency_Max_Order_By = {
  currency_code?: Maybe<Order_By>;
  currency_name?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Currency_Min_Fields = {
  __typename?: "inventory_currency_min_fields";
  currency_code?: Maybe<Scalars["String"]>;
  currency_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.currency" */
export type Inventory_Currency_Min_Order_By = {
  currency_code?: Maybe<Order_By>;
  currency_name?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.currency" */
export type Inventory_Currency_Mutation_Response = {
  __typename?: "inventory_currency_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Currency>;
};

/** input type for inserting object relation for remote table "inventory.currency" */
export type Inventory_Currency_Obj_Rel_Insert_Input = {
  data: Inventory_Currency_Insert_Input;
  on_conflict?: Maybe<Inventory_Currency_On_Conflict>;
};

/** on conflict condition type for table "inventory.currency" */
export type Inventory_Currency_On_Conflict = {
  constraint: Inventory_Currency_Constraint;
  update_columns: Array<Inventory_Currency_Update_Column>;
  where?: Maybe<Inventory_Currency_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.currency" */
export type Inventory_Currency_Order_By = {
  currency_code?: Maybe<Order_By>;
  currency_name?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.currency" */
export type Inventory_Currency_Pk_Columns_Input = {
  currency_code: Scalars["String"];
};

/** select columns of table "inventory.currency" */
export enum Inventory_Currency_Select_Column {
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  CurrencyName = "currency_name",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inventory.currency" */
export type Inventory_Currency_Set_Input = {
  currency_code?: Maybe<Scalars["String"]>;
  currency_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** update columns of table "inventory.currency" */
export enum Inventory_Currency_Update_Column {
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  CurrencyName = "currency_name",
  /** column name */
  LastUpdated = "last_updated",
}

/** columns and relationships of "inventory.date_d" */
export type Inventory_Date_D = {
  __typename?: "inventory_date_d";
  date_key: Scalars["Int"];
  full_date: Scalars["date"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "inventory.date_d" */
export type Inventory_Date_D_Aggregate = {
  __typename?: "inventory_date_d_aggregate";
  aggregate?: Maybe<Inventory_Date_D_Aggregate_Fields>;
  nodes: Array<Inventory_Date_D>;
};

/** aggregate fields of "inventory.date_d" */
export type Inventory_Date_D_Aggregate_Fields = {
  __typename?: "inventory_date_d_aggregate_fields";
  avg?: Maybe<Inventory_Date_D_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Date_D_Max_Fields>;
  min?: Maybe<Inventory_Date_D_Min_Fields>;
  stddev?: Maybe<Inventory_Date_D_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Date_D_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Date_D_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Date_D_Sum_Fields>;
  var_pop?: Maybe<Inventory_Date_D_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Date_D_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Date_D_Variance_Fields>;
};

/** aggregate fields of "inventory.date_d" */
export type Inventory_Date_D_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Date_D_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.date_d" */
export type Inventory_Date_D_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Date_D_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Date_D_Max_Order_By>;
  min?: Maybe<Inventory_Date_D_Min_Order_By>;
  stddev?: Maybe<Inventory_Date_D_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Date_D_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Date_D_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Date_D_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Date_D_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Date_D_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Date_D_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.date_d" */
export type Inventory_Date_D_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Date_D_Insert_Input>;
  on_conflict?: Maybe<Inventory_Date_D_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Date_D_Avg_Fields = {
  __typename?: "inventory_date_d_avg_fields";
  date_key?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Avg_Order_By = {
  date_key?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.date_d". All fields are combined with a logical 'AND'. */
export type Inventory_Date_D_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Date_D_Bool_Exp>>>;
  _not?: Maybe<Inventory_Date_D_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Date_D_Bool_Exp>>>;
  date_key?: Maybe<Int_Comparison_Exp>;
  full_date?: Maybe<Date_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.date_d" */
export enum Inventory_Date_D_Constraint {
  /** unique or primary key constraint */
  PkDateD = "PK_date_d",
}

/** input type for incrementing integer column in table "inventory.date_d" */
export type Inventory_Date_D_Inc_Input = {
  date_key?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.date_d" */
export type Inventory_Date_D_Insert_Input = {
  date_key?: Maybe<Scalars["Int"]>;
  full_date?: Maybe<Scalars["date"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Date_D_Max_Fields = {
  __typename?: "inventory_date_d_max_fields";
  date_key?: Maybe<Scalars["Int"]>;
  full_date?: Maybe<Scalars["date"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Max_Order_By = {
  date_key?: Maybe<Order_By>;
  full_date?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Date_D_Min_Fields = {
  __typename?: "inventory_date_d_min_fields";
  date_key?: Maybe<Scalars["Int"]>;
  full_date?: Maybe<Scalars["date"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Min_Order_By = {
  date_key?: Maybe<Order_By>;
  full_date?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.date_d" */
export type Inventory_Date_D_Mutation_Response = {
  __typename?: "inventory_date_d_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Date_D>;
};

/** input type for inserting object relation for remote table "inventory.date_d" */
export type Inventory_Date_D_Obj_Rel_Insert_Input = {
  data: Inventory_Date_D_Insert_Input;
  on_conflict?: Maybe<Inventory_Date_D_On_Conflict>;
};

/** on conflict condition type for table "inventory.date_d" */
export type Inventory_Date_D_On_Conflict = {
  constraint: Inventory_Date_D_Constraint;
  update_columns: Array<Inventory_Date_D_Update_Column>;
  where?: Maybe<Inventory_Date_D_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.date_d" */
export type Inventory_Date_D_Order_By = {
  date_key?: Maybe<Order_By>;
  full_date?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.date_d" */
export type Inventory_Date_D_Pk_Columns_Input = {
  date_key: Scalars["Int"];
};

/** select columns of table "inventory.date_d" */
export enum Inventory_Date_D_Select_Column {
  /** column name */
  DateKey = "date_key",
  /** column name */
  FullDate = "full_date",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inventory.date_d" */
export type Inventory_Date_D_Set_Input = {
  date_key?: Maybe<Scalars["Int"]>;
  full_date?: Maybe<Scalars["date"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Date_D_Stddev_Fields = {
  __typename?: "inventory_date_d_stddev_fields";
  date_key?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Stddev_Order_By = {
  date_key?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Date_D_Stddev_Pop_Fields = {
  __typename?: "inventory_date_d_stddev_pop_fields";
  date_key?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Stddev_Pop_Order_By = {
  date_key?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Date_D_Stddev_Samp_Fields = {
  __typename?: "inventory_date_d_stddev_samp_fields";
  date_key?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Stddev_Samp_Order_By = {
  date_key?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Date_D_Sum_Fields = {
  __typename?: "inventory_date_d_sum_fields";
  date_key?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Sum_Order_By = {
  date_key?: Maybe<Order_By>;
};

/** update columns of table "inventory.date_d" */
export enum Inventory_Date_D_Update_Column {
  /** column name */
  DateKey = "date_key",
  /** column name */
  FullDate = "full_date",
  /** column name */
  LastUpdated = "last_updated",
}

/** aggregate var_pop on columns */
export type Inventory_Date_D_Var_Pop_Fields = {
  __typename?: "inventory_date_d_var_pop_fields";
  date_key?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Var_Pop_Order_By = {
  date_key?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Date_D_Var_Samp_Fields = {
  __typename?: "inventory_date_d_var_samp_fields";
  date_key?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Var_Samp_Order_By = {
  date_key?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Date_D_Variance_Fields = {
  __typename?: "inventory_date_d_variance_fields";
  date_key?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.date_d" */
export type Inventory_Date_D_Variance_Order_By = {
  date_key?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp = {
  __typename?: "inventory_exchange_rate_gbp";
  currency_code: Scalars["String"];
  date_key: Scalars["Int"];
  id: Scalars["Int"];
  to_gbp_rate: Scalars["numeric"];
};

/** aggregated selection of "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Aggregate = {
  __typename?: "inventory_exchange_rate_gbp_aggregate";
  aggregate?: Maybe<Inventory_Exchange_Rate_Gbp_Aggregate_Fields>;
  nodes: Array<Inventory_Exchange_Rate_Gbp>;
};

/** aggregate fields of "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Aggregate_Fields = {
  __typename?: "inventory_exchange_rate_gbp_aggregate_fields";
  avg?: Maybe<Inventory_Exchange_Rate_Gbp_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Exchange_Rate_Gbp_Max_Fields>;
  min?: Maybe<Inventory_Exchange_Rate_Gbp_Min_Fields>;
  stddev?: Maybe<Inventory_Exchange_Rate_Gbp_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Exchange_Rate_Gbp_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Exchange_Rate_Gbp_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Exchange_Rate_Gbp_Sum_Fields>;
  var_pop?: Maybe<Inventory_Exchange_Rate_Gbp_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Exchange_Rate_Gbp_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Exchange_Rate_Gbp_Variance_Fields>;
};

/** aggregate fields of "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Exchange_Rate_Gbp_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Exchange_Rate_Gbp_Max_Order_By>;
  min?: Maybe<Inventory_Exchange_Rate_Gbp_Min_Order_By>;
  stddev?: Maybe<Inventory_Exchange_Rate_Gbp_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Exchange_Rate_Gbp_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Exchange_Rate_Gbp_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Exchange_Rate_Gbp_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Exchange_Rate_Gbp_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Exchange_Rate_Gbp_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Exchange_Rate_Gbp_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Exchange_Rate_Gbp_Insert_Input>;
  on_conflict?: Maybe<Inventory_Exchange_Rate_Gbp_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Exchange_Rate_Gbp_Avg_Fields = {
  __typename?: "inventory_exchange_rate_gbp_avg_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  to_gbp_rate?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Avg_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.exchange_rate_gbp". All fields are combined with a logical 'AND'. */
export type Inventory_Exchange_Rate_Gbp_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Exchange_Rate_Gbp_Bool_Exp>>>;
  _not?: Maybe<Inventory_Exchange_Rate_Gbp_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Exchange_Rate_Gbp_Bool_Exp>>>;
  currency_code?: Maybe<String_Comparison_Exp>;
  date_key?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  to_gbp_rate?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.exchange_rate_gbp" */
export enum Inventory_Exchange_Rate_Gbp_Constraint {
  /** unique or primary key constraint */
  ExchangeRateGbpPkey = "exchange_rate_gbp_pkey",
}

/** input type for incrementing integer column in table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Inc_Input = {
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  to_gbp_rate?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Insert_Input = {
  currency_code?: Maybe<Scalars["String"]>;
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  to_gbp_rate?: Maybe<Scalars["numeric"]>;
};

/** aggregate max on columns */
export type Inventory_Exchange_Rate_Gbp_Max_Fields = {
  __typename?: "inventory_exchange_rate_gbp_max_fields";
  currency_code?: Maybe<Scalars["String"]>;
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  to_gbp_rate?: Maybe<Scalars["numeric"]>;
};

/** order by max() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Max_Order_By = {
  currency_code?: Maybe<Order_By>;
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Exchange_Rate_Gbp_Min_Fields = {
  __typename?: "inventory_exchange_rate_gbp_min_fields";
  currency_code?: Maybe<Scalars["String"]>;
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  to_gbp_rate?: Maybe<Scalars["numeric"]>;
};

/** order by min() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Min_Order_By = {
  currency_code?: Maybe<Order_By>;
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Mutation_Response = {
  __typename?: "inventory_exchange_rate_gbp_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Exchange_Rate_Gbp>;
};

/** input type for inserting object relation for remote table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Obj_Rel_Insert_Input = {
  data: Inventory_Exchange_Rate_Gbp_Insert_Input;
  on_conflict?: Maybe<Inventory_Exchange_Rate_Gbp_On_Conflict>;
};

/** on conflict condition type for table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_On_Conflict = {
  constraint: Inventory_Exchange_Rate_Gbp_Constraint;
  update_columns: Array<Inventory_Exchange_Rate_Gbp_Update_Column>;
  where?: Maybe<Inventory_Exchange_Rate_Gbp_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Order_By = {
  currency_code?: Maybe<Order_By>;
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.exchange_rate_gbp" */
export enum Inventory_Exchange_Rate_Gbp_Select_Column {
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  DateKey = "date_key",
  /** column name */
  Id = "id",
  /** column name */
  ToGbpRate = "to_gbp_rate",
}

/** input type for updating data in table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Set_Input = {
  currency_code?: Maybe<Scalars["String"]>;
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  to_gbp_rate?: Maybe<Scalars["numeric"]>;
};

/** aggregate stddev on columns */
export type Inventory_Exchange_Rate_Gbp_Stddev_Fields = {
  __typename?: "inventory_exchange_rate_gbp_stddev_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  to_gbp_rate?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Stddev_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Exchange_Rate_Gbp_Stddev_Pop_Fields = {
  __typename?: "inventory_exchange_rate_gbp_stddev_pop_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  to_gbp_rate?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Stddev_Pop_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Exchange_Rate_Gbp_Stddev_Samp_Fields = {
  __typename?: "inventory_exchange_rate_gbp_stddev_samp_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  to_gbp_rate?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Stddev_Samp_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Exchange_Rate_Gbp_Sum_Fields = {
  __typename?: "inventory_exchange_rate_gbp_sum_fields";
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  to_gbp_rate?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Sum_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** update columns of table "inventory.exchange_rate_gbp" */
export enum Inventory_Exchange_Rate_Gbp_Update_Column {
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  DateKey = "date_key",
  /** column name */
  Id = "id",
  /** column name */
  ToGbpRate = "to_gbp_rate",
}

/** aggregate var_pop on columns */
export type Inventory_Exchange_Rate_Gbp_Var_Pop_Fields = {
  __typename?: "inventory_exchange_rate_gbp_var_pop_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  to_gbp_rate?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Var_Pop_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Exchange_Rate_Gbp_Var_Samp_Fields = {
  __typename?: "inventory_exchange_rate_gbp_var_samp_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  to_gbp_rate?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Var_Samp_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Exchange_Rate_Gbp_Variance_Fields = {
  __typename?: "inventory_exchange_rate_gbp_variance_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  to_gbp_rate?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.exchange_rate_gbp" */
export type Inventory_Exchange_Rate_Gbp_Variance_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  to_gbp_rate?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_Folder = {
  __typename?: "inventory_folder";
  /** An array relationship */
  accounts: Array<Inventory_Account>;
  /** An aggregated array relationship */
  accounts_aggregate: Inventory_Account_Aggregate;
  /** An array relationship */
  attachment_folders: Array<Inventory_Attachment_Folder>;
  /** An aggregated array relationship */
  attachment_folders_aggregate: Inventory_Attachment_Folder_Aggregate;
  /** An array relationship */
  client_orders: Array<Inventory_Client_Order>;
  /** An aggregated array relationship */
  client_orders_aggregate: Inventory_Client_Order_Aggregate;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  product_pricings: Array<Inventory_Product_Pricing>;
  /** An aggregated array relationship */
  product_pricings_aggregate: Inventory_Product_Pricing_Aggregate;
  /** An array relationship */
  production_orders: Array<Inventory_Production_Order>;
  /** An aggregated array relationship */
  production_orders_aggregate: Inventory_Production_Order_Aggregate;
  /** An array relationship */
  supplier_quotes: Array<Inventory_Supplier_Quote>;
  /** An aggregated array relationship */
  supplier_quotes_aggregate: Inventory_Supplier_Quote_Aggregate;
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderAccountsArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Order_By>>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Order_By>>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderAttachment_FoldersArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Folder_Order_By>>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderAttachment_Folders_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Folder_Order_By>>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderClient_OrdersArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderClient_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderProduct_PricingsArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Pricing_Order_By>>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderProduct_Pricings_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Pricing_Order_By>>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderProduction_OrdersArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderProduction_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderSupplier_QuotesArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** columns and relationships of "inventory.folder" */
export type Inventory_FolderSupplier_Quotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** aggregated selection of "inventory.folder" */
export type Inventory_Folder_Aggregate = {
  __typename?: "inventory_folder_aggregate";
  aggregate?: Maybe<Inventory_Folder_Aggregate_Fields>;
  nodes: Array<Inventory_Folder>;
};

/** aggregate fields of "inventory.folder" */
export type Inventory_Folder_Aggregate_Fields = {
  __typename?: "inventory_folder_aggregate_fields";
  avg?: Maybe<Inventory_Folder_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Folder_Max_Fields>;
  min?: Maybe<Inventory_Folder_Min_Fields>;
  stddev?: Maybe<Inventory_Folder_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Folder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Folder_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Folder_Sum_Fields>;
  var_pop?: Maybe<Inventory_Folder_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Folder_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Folder_Variance_Fields>;
};

/** aggregate fields of "inventory.folder" */
export type Inventory_Folder_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Folder_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.folder" */
export type Inventory_Folder_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Folder_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Folder_Max_Order_By>;
  min?: Maybe<Inventory_Folder_Min_Order_By>;
  stddev?: Maybe<Inventory_Folder_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Folder_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Folder_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Folder_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Folder_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Folder_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Folder_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.folder" */
export type Inventory_Folder_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Folder_Insert_Input>;
  on_conflict?: Maybe<Inventory_Folder_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Folder_Avg_Fields = {
  __typename?: "inventory_folder_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.folder" */
export type Inventory_Folder_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.folder". All fields are combined with a logical 'AND'. */
export type Inventory_Folder_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Folder_Bool_Exp>>>;
  _not?: Maybe<Inventory_Folder_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Folder_Bool_Exp>>>;
  accounts?: Maybe<Inventory_Account_Bool_Exp>;
  attachment_folders?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
  client_orders?: Maybe<Inventory_Client_Order_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product_pricings?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
  production_orders?: Maybe<Inventory_Production_Order_Bool_Exp>;
  supplier_quotes?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.folder" */
export enum Inventory_Folder_Constraint {
  /** unique or primary key constraint */
  FolderPkey = "folder_pkey",
}

/** input type for incrementing integer column in table "inventory.folder" */
export type Inventory_Folder_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.folder" */
export type Inventory_Folder_Insert_Input = {
  accounts?: Maybe<Inventory_Account_Arr_Rel_Insert_Input>;
  attachment_folders?: Maybe<Inventory_Attachment_Folder_Arr_Rel_Insert_Input>;
  client_orders?: Maybe<Inventory_Client_Order_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_pricings?: Maybe<Inventory_Product_Pricing_Arr_Rel_Insert_Input>;
  production_orders?: Maybe<Inventory_Production_Order_Arr_Rel_Insert_Input>;
  supplier_quotes?: Maybe<Inventory_Supplier_Quote_Arr_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Folder_Max_Fields = {
  __typename?: "inventory_folder_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.folder" */
export type Inventory_Folder_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Folder_Min_Fields = {
  __typename?: "inventory_folder_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.folder" */
export type Inventory_Folder_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.folder" */
export type Inventory_Folder_Mutation_Response = {
  __typename?: "inventory_folder_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Folder>;
};

/** input type for inserting object relation for remote table "inventory.folder" */
export type Inventory_Folder_Obj_Rel_Insert_Input = {
  data: Inventory_Folder_Insert_Input;
  on_conflict?: Maybe<Inventory_Folder_On_Conflict>;
};

/** on conflict condition type for table "inventory.folder" */
export type Inventory_Folder_On_Conflict = {
  constraint: Inventory_Folder_Constraint;
  update_columns: Array<Inventory_Folder_Update_Column>;
  where?: Maybe<Inventory_Folder_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.folder" */
export type Inventory_Folder_Order_By = {
  accounts_aggregate?: Maybe<Inventory_Account_Aggregate_Order_By>;
  attachment_folders_aggregate?: Maybe<Inventory_Attachment_Folder_Aggregate_Order_By>;
  client_orders_aggregate?: Maybe<Inventory_Client_Order_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_pricings_aggregate?: Maybe<Inventory_Product_Pricing_Aggregate_Order_By>;
  production_orders_aggregate?: Maybe<Inventory_Production_Order_Aggregate_Order_By>;
  supplier_quotes_aggregate?: Maybe<Inventory_Supplier_Quote_Aggregate_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.folder" */
export type Inventory_Folder_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.folder" */
export enum Inventory_Folder_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.folder" */
export type Inventory_Folder_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Folder_Stddev_Fields = {
  __typename?: "inventory_folder_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.folder" */
export type Inventory_Folder_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Folder_Stddev_Pop_Fields = {
  __typename?: "inventory_folder_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.folder" */
export type Inventory_Folder_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Folder_Stddev_Samp_Fields = {
  __typename?: "inventory_folder_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.folder" */
export type Inventory_Folder_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Folder_Sum_Fields = {
  __typename?: "inventory_folder_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.folder" */
export type Inventory_Folder_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.folder" */
export enum Inventory_Folder_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Folder_Var_Pop_Fields = {
  __typename?: "inventory_folder_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.folder" */
export type Inventory_Folder_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Folder_Var_Samp_Fields = {
  __typename?: "inventory_folder_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.folder" */
export type Inventory_Folder_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Folder_Variance_Fields = {
  __typename?: "inventory_folder_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.folder" */
export type Inventory_Folder_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.locale" */
export type Inventory_Locale = {
  __typename?: "inventory_locale";
  country_code: Scalars["String"];
  language: Scalars["String"];
  last_updated: Scalars["timestamptz"];
  locale: Scalars["String"];
};

/** aggregated selection of "inventory.locale" */
export type Inventory_Locale_Aggregate = {
  __typename?: "inventory_locale_aggregate";
  aggregate?: Maybe<Inventory_Locale_Aggregate_Fields>;
  nodes: Array<Inventory_Locale>;
};

/** aggregate fields of "inventory.locale" */
export type Inventory_Locale_Aggregate_Fields = {
  __typename?: "inventory_locale_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Locale_Max_Fields>;
  min?: Maybe<Inventory_Locale_Min_Fields>;
};

/** aggregate fields of "inventory.locale" */
export type Inventory_Locale_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Locale_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.locale" */
export type Inventory_Locale_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Locale_Max_Order_By>;
  min?: Maybe<Inventory_Locale_Min_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.locale" */
export type Inventory_Locale_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Locale_Insert_Input>;
  on_conflict?: Maybe<Inventory_Locale_On_Conflict>;
};

/** Boolean expression to filter rows from the table "inventory.locale". All fields are combined with a logical 'AND'. */
export type Inventory_Locale_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Locale_Bool_Exp>>>;
  _not?: Maybe<Inventory_Locale_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Locale_Bool_Exp>>>;
  country_code?: Maybe<String_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  locale?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.locale" */
export enum Inventory_Locale_Constraint {
  /** unique or primary key constraint */
  LocalePkey = "locale_pkey",
}

/** input type for inserting data into table "inventory.locale" */
export type Inventory_Locale_Insert_Input = {
  country_code?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  locale?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Locale_Max_Fields = {
  __typename?: "inventory_locale_max_fields";
  country_code?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  locale?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.locale" */
export type Inventory_Locale_Max_Order_By = {
  country_code?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Locale_Min_Fields = {
  __typename?: "inventory_locale_min_fields";
  country_code?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  locale?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.locale" */
export type Inventory_Locale_Min_Order_By = {
  country_code?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.locale" */
export type Inventory_Locale_Mutation_Response = {
  __typename?: "inventory_locale_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Locale>;
};

/** input type for inserting object relation for remote table "inventory.locale" */
export type Inventory_Locale_Obj_Rel_Insert_Input = {
  data: Inventory_Locale_Insert_Input;
  on_conflict?: Maybe<Inventory_Locale_On_Conflict>;
};

/** on conflict condition type for table "inventory.locale" */
export type Inventory_Locale_On_Conflict = {
  constraint: Inventory_Locale_Constraint;
  update_columns: Array<Inventory_Locale_Update_Column>;
  where?: Maybe<Inventory_Locale_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.locale" */
export type Inventory_Locale_Order_By = {
  country_code?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  locale?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.locale" */
export type Inventory_Locale_Pk_Columns_Input = {
  locale: Scalars["String"];
};

/** select columns of table "inventory.locale" */
export enum Inventory_Locale_Select_Column {
  /** column name */
  CountryCode = "country_code",
  /** column name */
  Language = "language",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Locale = "locale",
}

/** input type for updating data in table "inventory.locale" */
export type Inventory_Locale_Set_Input = {
  country_code?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  locale?: Maybe<Scalars["String"]>;
};

/** update columns of table "inventory.locale" */
export enum Inventory_Locale_Update_Column {
  /** column name */
  CountryCode = "country_code",
  /** column name */
  Language = "language",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Locale = "locale",
}

/** columns and relationships of "inventory.location" */
export type Inventory_Location = {
  __typename?: "inventory_location";
  /** An object relationship */
  account: Inventory_Account;
  account_id: Scalars["Int"];
  /** An object relationship */
  address: Inventory_Address;
  address_id: Scalars["Int"];
  can_hold_stock: Scalars["Boolean"];
  /** An array relationship */
  child_locations: Array<Inventory_Location>;
  /** An aggregated array relationship */
  child_locations_aggregate: Inventory_Location_Aggregate;
  /** An array relationship */
  client_orders: Array<Inventory_Client_Order>;
  /** An aggregated array relationship */
  client_orders_aggregate: Inventory_Client_Order_Aggregate;
  id: Scalars["Int"];
  is_external: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location?: Maybe<Inventory_Location>;
  location_name: Scalars["String"];
  notes: Scalars["String"];
  parent_location_id?: Maybe<Scalars["Int"]>;
  /** An array relationship */
  product_stocks: Array<Inventory_Product_Stock>;
  /** An aggregated array relationship */
  product_stocks_aggregate: Inventory_Product_Stock_Aggregate;
  /** An array relationship */
  production_orders: Array<Inventory_Production_Order>;
  /** An aggregated array relationship */
  production_orders_aggregate: Inventory_Production_Order_Aggregate;
  /** An array relationship */
  shipments: Array<Inventory_Shipment>;
  /** An array relationship */
  shipmentsByDestinationLocationId: Array<Inventory_Shipment>;
  /** An aggregated array relationship */
  shipmentsByDestinationLocationId_aggregate: Inventory_Shipment_Aggregate;
  /** An aggregated array relationship */
  shipments_aggregate: Inventory_Shipment_Aggregate;
  /** An array relationship */
  supplier_quotes: Array<Inventory_Supplier_Quote>;
  /** An aggregated array relationship */
  supplier_quotes_aggregate: Inventory_Supplier_Quote_Aggregate;
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationChild_LocationsArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationChild_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationClient_OrdersArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationClient_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationProduction_OrdersArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationProduction_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationShipmentsArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Order_By>>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationShipmentsByDestinationLocationIdArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Order_By>>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationShipmentsByDestinationLocationId_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Order_By>>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationShipments_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Order_By>>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationSupplier_QuotesArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** columns and relationships of "inventory.location" */
export type Inventory_LocationSupplier_Quotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** aggregated selection of "inventory.location" */
export type Inventory_Location_Aggregate = {
  __typename?: "inventory_location_aggregate";
  aggregate?: Maybe<Inventory_Location_Aggregate_Fields>;
  nodes: Array<Inventory_Location>;
};

/** aggregate fields of "inventory.location" */
export type Inventory_Location_Aggregate_Fields = {
  __typename?: "inventory_location_aggregate_fields";
  avg?: Maybe<Inventory_Location_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Location_Max_Fields>;
  min?: Maybe<Inventory_Location_Min_Fields>;
  stddev?: Maybe<Inventory_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Location_Sum_Fields>;
  var_pop?: Maybe<Inventory_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Location_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Location_Variance_Fields>;
};

/** aggregate fields of "inventory.location" */
export type Inventory_Location_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Location_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.location" */
export type Inventory_Location_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Location_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Location_Max_Order_By>;
  min?: Maybe<Inventory_Location_Min_Order_By>;
  stddev?: Maybe<Inventory_Location_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Location_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Location_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Location_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Location_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Location_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.location" */
export type Inventory_Location_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Location_Insert_Input>;
  on_conflict?: Maybe<Inventory_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Location_Avg_Fields = {
  __typename?: "inventory_location_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.location" */
export type Inventory_Location_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.location". All fields are combined with a logical 'AND'. */
export type Inventory_Location_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Location_Bool_Exp>>>;
  _not?: Maybe<Inventory_Location_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Location_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  account_id?: Maybe<Int_Comparison_Exp>;
  address?: Maybe<Inventory_Address_Bool_Exp>;
  address_id?: Maybe<Int_Comparison_Exp>;
  can_hold_stock?: Maybe<Boolean_Comparison_Exp>;
  child_locations?: Maybe<Inventory_Location_Bool_Exp>;
  client_orders?: Maybe<Inventory_Client_Order_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_external?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Inventory_Location_Bool_Exp>;
  location_name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  parent_location_id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Inventory_Product_Stock_Bool_Exp>;
  production_orders?: Maybe<Inventory_Production_Order_Bool_Exp>;
  shipments?: Maybe<Inventory_Shipment_Bool_Exp>;
  shipmentsByDestinationLocationId?: Maybe<Inventory_Shipment_Bool_Exp>;
  supplier_quotes?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.location" */
export enum Inventory_Location_Constraint {
  /** unique or primary key constraint */
  UniqueLocation = "UNIQUE_location",
  /** unique or primary key constraint */
  LocationPkey = "location_pkey",
}

/** input type for incrementing integer column in table "inventory.location" */
export type Inventory_Location_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.location" */
export type Inventory_Location_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars["Int"]>;
  address?: Maybe<Inventory_Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars["Int"]>;
  can_hold_stock?: Maybe<Scalars["Boolean"]>;
  child_locations?: Maybe<Inventory_Location_Arr_Rel_Insert_Input>;
  client_orders?: Maybe<Inventory_Client_Order_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  is_external?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  location_name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  product_stocks?: Maybe<Inventory_Product_Stock_Arr_Rel_Insert_Input>;
  production_orders?: Maybe<Inventory_Production_Order_Arr_Rel_Insert_Input>;
  shipments?: Maybe<Inventory_Shipment_Arr_Rel_Insert_Input>;
  shipmentsByDestinationLocationId?: Maybe<Inventory_Shipment_Arr_Rel_Insert_Input>;
  supplier_quotes?: Maybe<Inventory_Supplier_Quote_Arr_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Location_Max_Fields = {
  __typename?: "inventory_location_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.location" */
export type Inventory_Location_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Location_Min_Fields = {
  __typename?: "inventory_location_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.location" */
export type Inventory_Location_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.location" */
export type Inventory_Location_Mutation_Response = {
  __typename?: "inventory_location_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Location>;
};

/** input type for inserting object relation for remote table "inventory.location" */
export type Inventory_Location_Obj_Rel_Insert_Input = {
  data: Inventory_Location_Insert_Input;
  on_conflict?: Maybe<Inventory_Location_On_Conflict>;
};

/** on conflict condition type for table "inventory.location" */
export type Inventory_Location_On_Conflict = {
  constraint: Inventory_Location_Constraint;
  update_columns: Array<Inventory_Location_Update_Column>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.location" */
export type Inventory_Location_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  account_id?: Maybe<Order_By>;
  address?: Maybe<Inventory_Address_Order_By>;
  address_id?: Maybe<Order_By>;
  can_hold_stock?: Maybe<Order_By>;
  child_locations_aggregate?: Maybe<Inventory_Location_Aggregate_Order_By>;
  client_orders_aggregate?: Maybe<Inventory_Client_Order_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  is_external?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Inventory_Location_Order_By>;
  location_name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Inventory_Product_Stock_Aggregate_Order_By>;
  production_orders_aggregate?: Maybe<Inventory_Production_Order_Aggregate_Order_By>;
  shipmentsByDestinationLocationId_aggregate?: Maybe<Inventory_Shipment_Aggregate_Order_By>;
  shipments_aggregate?: Maybe<Inventory_Shipment_Aggregate_Order_By>;
  supplier_quotes_aggregate?: Maybe<Inventory_Supplier_Quote_Aggregate_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.location" */
export type Inventory_Location_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.location" */
export enum Inventory_Location_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  AddressId = "address_id",
  /** column name */
  CanHoldStock = "can_hold_stock",
  /** column name */
  Id = "id",
  /** column name */
  IsExternal = "is_external",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationName = "location_name",
  /** column name */
  Notes = "notes",
  /** column name */
  ParentLocationId = "parent_location_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.location" */
export type Inventory_Location_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  address_id?: Maybe<Scalars["Int"]>;
  can_hold_stock?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  is_external?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Location_Stddev_Fields = {
  __typename?: "inventory_location_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.location" */
export type Inventory_Location_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Location_Stddev_Pop_Fields = {
  __typename?: "inventory_location_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.location" */
export type Inventory_Location_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Location_Stddev_Samp_Fields = {
  __typename?: "inventory_location_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.location" */
export type Inventory_Location_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Location_Sum_Fields = {
  __typename?: "inventory_location_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.location" */
export type Inventory_Location_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.location" */
export enum Inventory_Location_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  AddressId = "address_id",
  /** column name */
  CanHoldStock = "can_hold_stock",
  /** column name */
  Id = "id",
  /** column name */
  IsExternal = "is_external",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationName = "location_name",
  /** column name */
  Notes = "notes",
  /** column name */
  ParentLocationId = "parent_location_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Location_Var_Pop_Fields = {
  __typename?: "inventory_location_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.location" */
export type Inventory_Location_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Location_Var_Samp_Fields = {
  __typename?: "inventory_location_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.location" */
export type Inventory_Location_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Location_Variance_Fields = {
  __typename?: "inventory_location_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.location" */
export type Inventory_Location_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_Locations = {
  __typename?: "inventory_management_locations";
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  /** An array relationship */
  inventoryManagementLocationsAncestriesByLocationId: Array<Inventory_Management_Locations_Ancestry>;
  /** An aggregated array relationship */
  inventoryManagementLocationsAncestriesByLocationId_aggregate: Inventory_Management_Locations_Ancestry_Aggregate;
  /** An array relationship */
  inventory_management_locations: Array<Inventory_Management_Locations>;
  /** An aggregated array relationship */
  inventory_management_locations_aggregate: Inventory_Management_Locations_Aggregate;
  /** An array relationship */
  inventory_management_locations_ancestries: Array<Inventory_Management_Locations_Ancestry>;
  /** An aggregated array relationship */
  inventory_management_locations_ancestries_aggregate: Inventory_Management_Locations_Ancestry_Aggregate;
  /** An array relationship */
  inventory_management_stock_changes: Array<Inventory_Management_Stock_Change>;
  /** An aggregated array relationship */
  inventory_management_stock_changes_aggregate: Inventory_Management_Stock_Change_Aggregate;
  /** An array relationship */
  inventory_management_stock_position: Array<Inventory_Management_Stock_Position>;
  /** An aggregated array relationship */
  inventory_management_stock_position_aggregate: Inventory_Management_Stock_Position_Aggregate;
  location_type: Scalars["String"];
  name: Scalars["String"];
  parent_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  parent_location?: Maybe<Inventory_Management_Locations>;
  postcode?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventoryManagementLocationsAncestriesByLocationIdArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Ancestry_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventoryManagementLocationsAncestriesByLocationId_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Ancestry_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventory_Management_LocationsArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventory_Management_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventory_Management_Locations_AncestriesArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Ancestry_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventory_Management_Locations_Ancestries_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Ancestry_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventory_Management_Stock_ChangesArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Change_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventory_Management_Stock_Changes_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Change_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventory_Management_Stock_PositionArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_Bool_Exp>;
};

/** columns and relationships of "inventory_management_locations" */
export type Inventory_Management_LocationsInventory_Management_Stock_Position_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_Bool_Exp>;
};

/** aggregated selection of "inventory_management_locations" */
export type Inventory_Management_Locations_Aggregate = {
  __typename?: "inventory_management_locations_aggregate";
  aggregate?: Maybe<Inventory_Management_Locations_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Locations>;
};

/** aggregate fields of "inventory_management_locations" */
export type Inventory_Management_Locations_Aggregate_Fields = {
  __typename?: "inventory_management_locations_aggregate_fields";
  avg?: Maybe<Inventory_Management_Locations_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Locations_Max_Fields>;
  min?: Maybe<Inventory_Management_Locations_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Locations_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Locations_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Locations_Variance_Fields>;
};

/** aggregate fields of "inventory_management_locations" */
export type Inventory_Management_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Locations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_locations" */
export type Inventory_Management_Locations_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Locations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Locations_Max_Order_By>;
  min?: Maybe<Inventory_Management_Locations_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Locations_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Locations_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Locations_Variance_Order_By>;
};

/** columns and relationships of "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry = {
  __typename?: "inventory_management_locations_ancestry";
  ancestor_id: Scalars["Int"];
  /** An object relationship */
  inventoryManagementLocationByLocationId: Inventory_Management_Locations;
  /** An object relationship */
  inventory_management_location: Inventory_Management_Locations;
  location_id: Scalars["Int"];
};

/** aggregated selection of "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Aggregate = {
  __typename?: "inventory_management_locations_ancestry_aggregate";
  aggregate?: Maybe<Inventory_Management_Locations_Ancestry_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Locations_Ancestry>;
};

/** aggregate fields of "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Aggregate_Fields = {
  __typename?: "inventory_management_locations_ancestry_aggregate_fields";
  avg?: Maybe<Inventory_Management_Locations_Ancestry_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Locations_Ancestry_Max_Fields>;
  min?: Maybe<Inventory_Management_Locations_Ancestry_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Locations_Ancestry_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Locations_Ancestry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Locations_Ancestry_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Locations_Ancestry_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Locations_Ancestry_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Locations_Ancestry_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Locations_Ancestry_Variance_Fields>;
};

/** aggregate fields of "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Locations_Ancestry_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Locations_Ancestry_Max_Order_By>;
  min?: Maybe<Inventory_Management_Locations_Ancestry_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Locations_Ancestry_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Locations_Ancestry_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Locations_Ancestry_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Locations_Ancestry_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Locations_Ancestry_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Locations_Ancestry_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Locations_Ancestry_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Management_Locations_Ancestry_Insert_Input>;
};

/** aggregate avg on columns */
export type Inventory_Management_Locations_Ancestry_Avg_Fields = {
  __typename?: "inventory_management_locations_ancestry_avg_fields";
  ancestor_id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Avg_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_locations_ancestry". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Locations_Ancestry_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>>>;
  ancestor_id?: Maybe<Int_Comparison_Exp>;
  inventoryManagementLocationByLocationId?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  inventory_management_location?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing integer column in table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Inc_Input = {
  ancestor_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Insert_Input = {
  ancestor_id?: Maybe<Scalars["Int"]>;
  inventoryManagementLocationByLocationId?: Maybe<Inventory_Management_Locations_Obj_Rel_Insert_Input>;
  inventory_management_location?: Maybe<Inventory_Management_Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Inventory_Management_Locations_Ancestry_Max_Fields = {
  __typename?: "inventory_management_locations_ancestry_max_fields";
  ancestor_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Max_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Locations_Ancestry_Min_Fields = {
  __typename?: "inventory_management_locations_ancestry_min_fields";
  ancestor_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Min_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Mutation_Response = {
  __typename?: "inventory_management_locations_ancestry_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Management_Locations_Ancestry>;
};

/** input type for inserting object relation for remote table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Obj_Rel_Insert_Input = {
  data: Inventory_Management_Locations_Ancestry_Insert_Input;
};

/** ordering options when selecting data from "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  inventoryManagementLocationByLocationId?: Maybe<Inventory_Management_Locations_Order_By>;
  inventory_management_location?: Maybe<Inventory_Management_Locations_Order_By>;
  location_id?: Maybe<Order_By>;
};

/** select columns of table "inventory_management_locations_ancestry" */
export enum Inventory_Management_Locations_Ancestry_Select_Column {
  /** column name */
  AncestorId = "ancestor_id",
  /** column name */
  LocationId = "location_id",
}

/** input type for updating data in table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Set_Input = {
  ancestor_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Inventory_Management_Locations_Ancestry_Stddev_Fields = {
  __typename?: "inventory_management_locations_ancestry_stddev_fields";
  ancestor_id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Stddev_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Locations_Ancestry_Stddev_Pop_Fields = {
  __typename?: "inventory_management_locations_ancestry_stddev_pop_fields";
  ancestor_id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Stddev_Pop_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Locations_Ancestry_Stddev_Samp_Fields = {
  __typename?: "inventory_management_locations_ancestry_stddev_samp_fields";
  ancestor_id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Stddev_Samp_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Locations_Ancestry_Sum_Fields = {
  __typename?: "inventory_management_locations_ancestry_sum_fields";
  ancestor_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Sum_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Inventory_Management_Locations_Ancestry_Var_Pop_Fields = {
  __typename?: "inventory_management_locations_ancestry_var_pop_fields";
  ancestor_id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Var_Pop_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Locations_Ancestry_Var_Samp_Fields = {
  __typename?: "inventory_management_locations_ancestry_var_samp_fields";
  ancestor_id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Var_Samp_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Locations_Ancestry_Variance_Fields = {
  __typename?: "inventory_management_locations_ancestry_variance_fields";
  ancestor_id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_locations_ancestry" */
export type Inventory_Management_Locations_Ancestry_Variance_Order_By = {
  ancestor_id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** input type for inserting array relation for remote table "inventory_management_locations" */
export type Inventory_Management_Locations_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Management_Locations_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Management_Locations_Avg_Fields = {
  __typename?: "inventory_management_locations_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Avg_Order_By = {
  id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_locations". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Locations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Locations_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Locations_Bool_Exp>>>;
  addressline1?: Maybe<String_Comparison_Exp>;
  addressline2?: Maybe<String_Comparison_Exp>;
  addressline3?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  county?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inventoryManagementLocationsAncestriesByLocationId?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
  inventory_management_locations?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  inventory_management_locations_ancestries?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
  inventory_management_stock_changes?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
  inventory_management_stock_position?: Maybe<Inventory_Management_Stock_Position_Bool_Exp>;
  location_type?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parent_id?: Maybe<Int_Comparison_Exp>;
  parent_location?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory_management_locations" */
export enum Inventory_Management_Locations_Constraint {
  /** unique or primary key constraint */
  PkInventoryManagementLocations_1 = "PK_inventory_management_locations_1",
}

/** input type for incrementing integer column in table "inventory_management_locations" */
export type Inventory_Management_Locations_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  parent_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory_management_locations" */
export type Inventory_Management_Locations_Insert_Input = {
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  inventoryManagementLocationsAncestriesByLocationId?: Maybe<Inventory_Management_Locations_Ancestry_Arr_Rel_Insert_Input>;
  inventory_management_locations?: Maybe<Inventory_Management_Locations_Arr_Rel_Insert_Input>;
  inventory_management_locations_ancestries?: Maybe<Inventory_Management_Locations_Ancestry_Arr_Rel_Insert_Input>;
  inventory_management_stock_changes?: Maybe<Inventory_Management_Stock_Change_Arr_Rel_Insert_Input>;
  location_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  parent_location?: Maybe<Inventory_Management_Locations_Obj_Rel_Insert_Input>;
  postcode?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Management_Locations_Max_Fields = {
  __typename?: "inventory_management_locations_max_fields";
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  location_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  postcode?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Max_Order_By = {
  addressline1?: Maybe<Order_By>;
  addressline2?: Maybe<Order_By>;
  addressline3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  county?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Locations_Min_Fields = {
  __typename?: "inventory_management_locations_min_fields";
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  location_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  postcode?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Min_Order_By = {
  addressline1?: Maybe<Order_By>;
  addressline2?: Maybe<Order_By>;
  addressline3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  county?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory_management_locations" */
export type Inventory_Management_Locations_Mutation_Response = {
  __typename?: "inventory_management_locations_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Management_Locations>;
};

/** input type for inserting object relation for remote table "inventory_management_locations" */
export type Inventory_Management_Locations_Obj_Rel_Insert_Input = {
  data: Inventory_Management_Locations_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Locations_On_Conflict>;
};

/** on conflict condition type for table "inventory_management_locations" */
export type Inventory_Management_Locations_On_Conflict = {
  constraint: Inventory_Management_Locations_Constraint;
  update_columns: Array<Inventory_Management_Locations_Update_Column>;
  where?: Maybe<Inventory_Management_Locations_Bool_Exp>;
};

/** ordering options when selecting data from "inventory_management_locations" */
export type Inventory_Management_Locations_Order_By = {
  addressline1?: Maybe<Order_By>;
  addressline2?: Maybe<Order_By>;
  addressline3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  county?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inventoryManagementLocationsAncestriesByLocationId_aggregate?: Maybe<Inventory_Management_Locations_Ancestry_Aggregate_Order_By>;
  inventory_management_locations_aggregate?: Maybe<Inventory_Management_Locations_Aggregate_Order_By>;
  inventory_management_locations_ancestries_aggregate?: Maybe<Inventory_Management_Locations_Ancestry_Aggregate_Order_By>;
  inventory_management_stock_changes_aggregate?: Maybe<Inventory_Management_Stock_Change_Aggregate_Order_By>;
  inventory_management_stock_position_aggregate?: Maybe<Inventory_Management_Stock_Position_Aggregate_Order_By>;
  location_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  parent_location?: Maybe<Inventory_Management_Locations_Order_By>;
  postcode?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory_management_locations" */
export type Inventory_Management_Locations_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory_management_locations" */
export enum Inventory_Management_Locations_Select_Column {
  /** column name */
  Addressline1 = "addressline1",
  /** column name */
  Addressline2 = "addressline2",
  /** column name */
  Addressline3 = "addressline3",
  /** column name */
  City = "city",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  County = "county",
  /** column name */
  Id = "id",
  /** column name */
  LocationType = "location_type",
  /** column name */
  Name = "name",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  Postcode = "postcode",
}

/** input type for updating data in table "inventory_management_locations" */
export type Inventory_Management_Locations_Set_Input = {
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  location_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  postcode?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Management_Locations_Stddev_Fields = {
  __typename?: "inventory_management_locations_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Locations_Stddev_Pop_Fields = {
  __typename?: "inventory_management_locations_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Locations_Stddev_Samp_Fields = {
  __typename?: "inventory_management_locations_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Locations_Sum_Fields = {
  __typename?: "inventory_management_locations_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  parent_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Sum_Order_By = {
  id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** update columns of table "inventory_management_locations" */
export enum Inventory_Management_Locations_Update_Column {
  /** column name */
  Addressline1 = "addressline1",
  /** column name */
  Addressline2 = "addressline2",
  /** column name */
  Addressline3 = "addressline3",
  /** column name */
  City = "city",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  County = "county",
  /** column name */
  Id = "id",
  /** column name */
  LocationType = "location_type",
  /** column name */
  Name = "name",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  Postcode = "postcode",
}

/** aggregate var_pop on columns */
export type Inventory_Management_Locations_Var_Pop_Fields = {
  __typename?: "inventory_management_locations_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Locations_Var_Samp_Fields = {
  __typename?: "inventory_management_locations_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Locations_Variance_Fields = {
  __typename?: "inventory_management_locations_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  parent_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_locations" */
export type Inventory_Management_Locations_Variance_Order_By = {
  id?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory_management_owners" */
export type Inventory_Management_Owners = {
  __typename?: "inventory_management_owners";
  id: Scalars["Int"];
  name: Scalars["String"];
};

/** aggregated selection of "inventory_management_owners" */
export type Inventory_Management_Owners_Aggregate = {
  __typename?: "inventory_management_owners_aggregate";
  aggregate?: Maybe<Inventory_Management_Owners_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Owners>;
};

/** aggregate fields of "inventory_management_owners" */
export type Inventory_Management_Owners_Aggregate_Fields = {
  __typename?: "inventory_management_owners_aggregate_fields";
  avg?: Maybe<Inventory_Management_Owners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Owners_Max_Fields>;
  min?: Maybe<Inventory_Management_Owners_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Owners_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Owners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Owners_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Owners_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Owners_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Owners_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Owners_Variance_Fields>;
};

/** aggregate fields of "inventory_management_owners" */
export type Inventory_Management_Owners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Owners_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_owners" */
export type Inventory_Management_Owners_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Owners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Owners_Max_Order_By>;
  min?: Maybe<Inventory_Management_Owners_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Owners_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Owners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Owners_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Owners_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Owners_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Owners_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Owners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory_management_owners" */
export type Inventory_Management_Owners_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Management_Owners_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Owners_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Management_Owners_Avg_Fields = {
  __typename?: "inventory_management_owners_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_owners". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Owners_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Owners_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Owners_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Owners_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory_management_owners" */
export enum Inventory_Management_Owners_Constraint {
  /** unique or primary key constraint */
  InventoryManagementOwnersNameKey = "inventory_management_owners_name_key",
  /** unique or primary key constraint */
  InventoryManagementOwnersPkey = "inventory_management_owners_pkey",
}

/** input type for incrementing integer column in table "inventory_management_owners" */
export type Inventory_Management_Owners_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory_management_owners" */
export type Inventory_Management_Owners_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Management_Owners_Max_Fields = {
  __typename?: "inventory_management_owners_max_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Owners_Min_Fields = {
  __typename?: "inventory_management_owners_min_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory_management_owners" */
export type Inventory_Management_Owners_Mutation_Response = {
  __typename?: "inventory_management_owners_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Management_Owners>;
};

/** input type for inserting object relation for remote table "inventory_management_owners" */
export type Inventory_Management_Owners_Obj_Rel_Insert_Input = {
  data: Inventory_Management_Owners_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Owners_On_Conflict>;
};

/** on conflict condition type for table "inventory_management_owners" */
export type Inventory_Management_Owners_On_Conflict = {
  constraint: Inventory_Management_Owners_Constraint;
  update_columns: Array<Inventory_Management_Owners_Update_Column>;
  where?: Maybe<Inventory_Management_Owners_Bool_Exp>;
};

/** ordering options when selecting data from "inventory_management_owners" */
export type Inventory_Management_Owners_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory_management_owners" */
export type Inventory_Management_Owners_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory_management_owners" */
export enum Inventory_Management_Owners_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "inventory_management_owners" */
export type Inventory_Management_Owners_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Management_Owners_Stddev_Fields = {
  __typename?: "inventory_management_owners_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Owners_Stddev_Pop_Fields = {
  __typename?: "inventory_management_owners_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Owners_Stddev_Samp_Fields = {
  __typename?: "inventory_management_owners_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Owners_Sum_Fields = {
  __typename?: "inventory_management_owners_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory_management_owners" */
export enum Inventory_Management_Owners_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** aggregate var_pop on columns */
export type Inventory_Management_Owners_Var_Pop_Fields = {
  __typename?: "inventory_management_owners_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Owners_Var_Samp_Fields = {
  __typename?: "inventory_management_owners_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Owners_Variance_Fields = {
  __typename?: "inventory_management_owners_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_owners" */
export type Inventory_Management_Owners_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory_management_services" */
export type Inventory_Management_Services = {
  __typename?: "inventory_management_services";
  id: Scalars["Int"];
  /** An array relationship */
  inventory_management_stock_changes: Array<Inventory_Management_Stock_Change>;
  /** An aggregated array relationship */
  inventory_management_stock_changes_aggregate: Inventory_Management_Stock_Change_Aggregate;
  name: Scalars["String"];
};

/** columns and relationships of "inventory_management_services" */
export type Inventory_Management_ServicesInventory_Management_Stock_ChangesArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Change_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** columns and relationships of "inventory_management_services" */
export type Inventory_Management_ServicesInventory_Management_Stock_Changes_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Change_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** aggregated selection of "inventory_management_services" */
export type Inventory_Management_Services_Aggregate = {
  __typename?: "inventory_management_services_aggregate";
  aggregate?: Maybe<Inventory_Management_Services_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Services>;
};

/** aggregate fields of "inventory_management_services" */
export type Inventory_Management_Services_Aggregate_Fields = {
  __typename?: "inventory_management_services_aggregate_fields";
  avg?: Maybe<Inventory_Management_Services_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Services_Max_Fields>;
  min?: Maybe<Inventory_Management_Services_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Services_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Services_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Services_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Services_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Services_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Services_Variance_Fields>;
};

/** aggregate fields of "inventory_management_services" */
export type Inventory_Management_Services_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Services_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_services" */
export type Inventory_Management_Services_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Services_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Services_Max_Order_By>;
  min?: Maybe<Inventory_Management_Services_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Services_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Services_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Services_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Services_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Services_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Services_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Services_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory_management_services" */
export type Inventory_Management_Services_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Management_Services_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Services_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Management_Services_Avg_Fields = {
  __typename?: "inventory_management_services_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_services". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Services_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Services_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Services_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Services_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  inventory_management_stock_changes?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory_management_services" */
export enum Inventory_Management_Services_Constraint {
  /** unique or primary key constraint */
  PkInventoryManagementServices = "PK_inventory_management_services",
}

/** input type for incrementing integer column in table "inventory_management_services" */
export type Inventory_Management_Services_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory_management_services" */
export type Inventory_Management_Services_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  inventory_management_stock_changes?: Maybe<Inventory_Management_Stock_Change_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Management_Services_Max_Fields = {
  __typename?: "inventory_management_services_max_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Services_Min_Fields = {
  __typename?: "inventory_management_services_min_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory_management_services" */
export type Inventory_Management_Services_Mutation_Response = {
  __typename?: "inventory_management_services_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Management_Services>;
};

/** input type for inserting object relation for remote table "inventory_management_services" */
export type Inventory_Management_Services_Obj_Rel_Insert_Input = {
  data: Inventory_Management_Services_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Services_On_Conflict>;
};

/** on conflict condition type for table "inventory_management_services" */
export type Inventory_Management_Services_On_Conflict = {
  constraint: Inventory_Management_Services_Constraint;
  update_columns: Array<Inventory_Management_Services_Update_Column>;
  where?: Maybe<Inventory_Management_Services_Bool_Exp>;
};

/** ordering options when selecting data from "inventory_management_services" */
export type Inventory_Management_Services_Order_By = {
  id?: Maybe<Order_By>;
  inventory_management_stock_changes_aggregate?: Maybe<Inventory_Management_Stock_Change_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory_management_services" */
export type Inventory_Management_Services_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory_management_services" */
export enum Inventory_Management_Services_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "inventory_management_services" */
export type Inventory_Management_Services_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Management_Services_Stddev_Fields = {
  __typename?: "inventory_management_services_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Services_Stddev_Pop_Fields = {
  __typename?: "inventory_management_services_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Services_Stddev_Samp_Fields = {
  __typename?: "inventory_management_services_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Services_Sum_Fields = {
  __typename?: "inventory_management_services_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory_management_services" */
export enum Inventory_Management_Services_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** aggregate var_pop on columns */
export type Inventory_Management_Services_Var_Pop_Fields = {
  __typename?: "inventory_management_services_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Services_Var_Samp_Fields = {
  __typename?: "inventory_management_services_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Services_Variance_Fields = {
  __typename?: "inventory_management_services_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_services" */
export type Inventory_Management_Services_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory_management_sku" */
export type Inventory_Management_Sku = {
  __typename?: "inventory_management_sku";
  description: Scalars["String"];
  height?: Maybe<Scalars["numeric"]>;
  id: Scalars["Int"];
  image_url: Scalars["String"];
  /** An array relationship */
  inventory_management_stocks: Array<Inventory_Management_Stocks>;
  /** An aggregated array relationship */
  inventory_management_stocks_aggregate: Inventory_Management_Stocks_Aggregate;
  length?: Maybe<Scalars["numeric"]>;
  pack_size: Scalars["Int"];
  price_per_pack_gbp?: Maybe<Scalars["numeric"]>;
  sku_name: Scalars["String"];
  /** An array relationship */
  stock_position: Array<Inventory_Management_Stock_Position_By_Date>;
  /** An aggregated array relationship */
  stock_position_aggregate: Inventory_Management_Stock_Position_By_Date_Aggregate;
  width?: Maybe<Scalars["numeric"]>;
};

/** columns and relationships of "inventory_management_sku" */
export type Inventory_Management_SkuInventory_Management_StocksArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stocks_Order_By>>;
  where?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
};

/** columns and relationships of "inventory_management_sku" */
export type Inventory_Management_SkuInventory_Management_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stocks_Order_By>>;
  where?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
};

/** columns and relationships of "inventory_management_sku" */
export type Inventory_Management_SkuStock_PositionArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>;
};

/** columns and relationships of "inventory_management_sku" */
export type Inventory_Management_SkuStock_Position_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>;
};

/** aggregated selection of "inventory_management_sku" */
export type Inventory_Management_Sku_Aggregate = {
  __typename?: "inventory_management_sku_aggregate";
  aggregate?: Maybe<Inventory_Management_Sku_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Sku>;
};

/** aggregate fields of "inventory_management_sku" */
export type Inventory_Management_Sku_Aggregate_Fields = {
  __typename?: "inventory_management_sku_aggregate_fields";
  avg?: Maybe<Inventory_Management_Sku_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Sku_Max_Fields>;
  min?: Maybe<Inventory_Management_Sku_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Sku_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Sku_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Sku_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Sku_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Sku_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Sku_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Sku_Variance_Fields>;
};

/** aggregate fields of "inventory_management_sku" */
export type Inventory_Management_Sku_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Sku_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_sku" */
export type Inventory_Management_Sku_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Sku_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Sku_Max_Order_By>;
  min?: Maybe<Inventory_Management_Sku_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Sku_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Sku_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Sku_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Sku_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Sku_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Sku_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Sku_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory_management_sku" */
export type Inventory_Management_Sku_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Management_Sku_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Sku_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Management_Sku_Avg_Fields = {
  __typename?: "inventory_management_sku_avg_fields";
  height?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length?: Maybe<Scalars["Float"]>;
  pack_size?: Maybe<Scalars["Float"]>;
  price_per_pack_gbp?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Avg_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_sku". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Sku_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Sku_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Sku_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Sku_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  inventory_management_stocks?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
  length?: Maybe<Numeric_Comparison_Exp>;
  pack_size?: Maybe<Int_Comparison_Exp>;
  price_per_pack_gbp?: Maybe<Numeric_Comparison_Exp>;
  sku_name?: Maybe<String_Comparison_Exp>;
  stock_position?: Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>;
  width?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory_management_sku" */
export enum Inventory_Management_Sku_Constraint {
  /** unique or primary key constraint */
  PkInventoryManagementSku = "PK_inventory_management_sku",
}

/** input type for incrementing integer column in table "inventory_management_sku" */
export type Inventory_Management_Sku_Inc_Input = {
  height?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  length?: Maybe<Scalars["numeric"]>;
  pack_size?: Maybe<Scalars["Int"]>;
  price_per_pack_gbp?: Maybe<Scalars["numeric"]>;
  width?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "inventory_management_sku" */
export type Inventory_Management_Sku_Insert_Input = {
  description?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  inventory_management_stocks?: Maybe<Inventory_Management_Stocks_Arr_Rel_Insert_Input>;
  length?: Maybe<Scalars["numeric"]>;
  pack_size?: Maybe<Scalars["Int"]>;
  price_per_pack_gbp?: Maybe<Scalars["numeric"]>;
  sku_name?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["numeric"]>;
};

/** aggregate max on columns */
export type Inventory_Management_Sku_Max_Fields = {
  __typename?: "inventory_management_sku_max_fields";
  description?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  length?: Maybe<Scalars["numeric"]>;
  pack_size?: Maybe<Scalars["Int"]>;
  price_per_pack_gbp?: Maybe<Scalars["numeric"]>;
  sku_name?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["numeric"]>;
};

/** order by max() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Max_Order_By = {
  description?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  sku_name?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Sku_Min_Fields = {
  __typename?: "inventory_management_sku_min_fields";
  description?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  length?: Maybe<Scalars["numeric"]>;
  pack_size?: Maybe<Scalars["Int"]>;
  price_per_pack_gbp?: Maybe<Scalars["numeric"]>;
  sku_name?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["numeric"]>;
};

/** order by min() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Min_Order_By = {
  description?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  sku_name?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory_management_sku" */
export type Inventory_Management_Sku_Mutation_Response = {
  __typename?: "inventory_management_sku_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Management_Sku>;
};

/** input type for inserting object relation for remote table "inventory_management_sku" */
export type Inventory_Management_Sku_Obj_Rel_Insert_Input = {
  data: Inventory_Management_Sku_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Sku_On_Conflict>;
};

/** on conflict condition type for table "inventory_management_sku" */
export type Inventory_Management_Sku_On_Conflict = {
  constraint: Inventory_Management_Sku_Constraint;
  update_columns: Array<Inventory_Management_Sku_Update_Column>;
  where?: Maybe<Inventory_Management_Sku_Bool_Exp>;
};

/** ordering options when selecting data from "inventory_management_sku" */
export type Inventory_Management_Sku_Order_By = {
  description?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  inventory_management_stocks_aggregate?: Maybe<Inventory_Management_Stocks_Aggregate_Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  sku_name?: Maybe<Order_By>;
  stock_position_aggregate?: Maybe<Inventory_Management_Stock_Position_By_Date_Aggregate_Order_By>;
  width?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory_management_sku" */
export type Inventory_Management_Sku_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory_management_sku" */
export enum Inventory_Management_Sku_Select_Column {
  /** column name */
  Description = "description",
  /** column name */
  Height = "height",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  Length = "length",
  /** column name */
  PackSize = "pack_size",
  /** column name */
  PricePerPackGbp = "price_per_pack_gbp",
  /** column name */
  SkuName = "sku_name",
  /** column name */
  Width = "width",
}

/** input type for updating data in table "inventory_management_sku" */
export type Inventory_Management_Sku_Set_Input = {
  description?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  length?: Maybe<Scalars["numeric"]>;
  pack_size?: Maybe<Scalars["Int"]>;
  price_per_pack_gbp?: Maybe<Scalars["numeric"]>;
  sku_name?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["numeric"]>;
};

/** aggregate stddev on columns */
export type Inventory_Management_Sku_Stddev_Fields = {
  __typename?: "inventory_management_sku_stddev_fields";
  height?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length?: Maybe<Scalars["Float"]>;
  pack_size?: Maybe<Scalars["Float"]>;
  price_per_pack_gbp?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Stddev_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Sku_Stddev_Pop_Fields = {
  __typename?: "inventory_management_sku_stddev_pop_fields";
  height?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length?: Maybe<Scalars["Float"]>;
  pack_size?: Maybe<Scalars["Float"]>;
  price_per_pack_gbp?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Stddev_Pop_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Sku_Stddev_Samp_Fields = {
  __typename?: "inventory_management_sku_stddev_samp_fields";
  height?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length?: Maybe<Scalars["Float"]>;
  pack_size?: Maybe<Scalars["Float"]>;
  price_per_pack_gbp?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Stddev_Samp_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Sku_Sum_Fields = {
  __typename?: "inventory_management_sku_sum_fields";
  height?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  length?: Maybe<Scalars["numeric"]>;
  pack_size?: Maybe<Scalars["Int"]>;
  price_per_pack_gbp?: Maybe<Scalars["numeric"]>;
  width?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Sum_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** update columns of table "inventory_management_sku" */
export enum Inventory_Management_Sku_Update_Column {
  /** column name */
  Description = "description",
  /** column name */
  Height = "height",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  Length = "length",
  /** column name */
  PackSize = "pack_size",
  /** column name */
  PricePerPackGbp = "price_per_pack_gbp",
  /** column name */
  SkuName = "sku_name",
  /** column name */
  Width = "width",
}

/** aggregate var_pop on columns */
export type Inventory_Management_Sku_Var_Pop_Fields = {
  __typename?: "inventory_management_sku_var_pop_fields";
  height?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length?: Maybe<Scalars["Float"]>;
  pack_size?: Maybe<Scalars["Float"]>;
  price_per_pack_gbp?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Var_Pop_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Sku_Var_Samp_Fields = {
  __typename?: "inventory_management_sku_var_samp_fields";
  height?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length?: Maybe<Scalars["Float"]>;
  pack_size?: Maybe<Scalars["Float"]>;
  price_per_pack_gbp?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Var_Samp_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Sku_Variance_Fields = {
  __typename?: "inventory_management_sku_variance_fields";
  height?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length?: Maybe<Scalars["Float"]>;
  pack_size?: Maybe<Scalars["Float"]>;
  price_per_pack_gbp?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_sku" */
export type Inventory_Management_Sku_Variance_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length?: Maybe<Order_By>;
  pack_size?: Maybe<Order_By>;
  price_per_pack_gbp?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** columns and relationships of "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change = {
  __typename?: "inventory_management_stock_change";
  comment?: Maybe<Scalars["String"]>;
  created_ts: Scalars["timestamptz"];
  event_ts: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An object relationship */
  inventory_management_service: Inventory_Management_Services;
  /** An object relationship */
  inventory_management_sku: Inventory_Management_Sku;
  /** An object relationship */
  inventory_management_stock_owner_from?: Maybe<Inventory_Management_Owners>;
  /** An object relationship */
  inventory_management_stock_owner_to?: Maybe<Inventory_Management_Owners>;
  /** An object relationship */
  location_from?: Maybe<Inventory_Management_Locations>;
  location_from_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  location_to?: Maybe<Inventory_Management_Locations>;
  location_to_id?: Maybe<Scalars["Int"]>;
  order_ref?: Maybe<Scalars["String"]>;
  quantity: Scalars["Int"];
  service_id: Scalars["Int"];
  sku_id: Scalars["Int"];
  status: Scalars["String"];
  stock_owner_from?: Maybe<Scalars["Int"]>;
  stock_owner_to?: Maybe<Scalars["Int"]>;
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Aggregate = {
  __typename?: "inventory_management_stock_change_aggregate";
  aggregate?: Maybe<Inventory_Management_Stock_Change_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Stock_Change>;
};

/** aggregate fields of "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Aggregate_Fields = {
  __typename?: "inventory_management_stock_change_aggregate_fields";
  avg?: Maybe<Inventory_Management_Stock_Change_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Stock_Change_Max_Fields>;
  min?: Maybe<Inventory_Management_Stock_Change_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Stock_Change_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Stock_Change_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Stock_Change_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Stock_Change_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Stock_Change_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Stock_Change_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Stock_Change_Variance_Fields>;
};

/** aggregate fields of "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Stock_Change_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Stock_Change_Max_Order_By>;
  min?: Maybe<Inventory_Management_Stock_Change_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Stock_Change_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Stock_Change_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Stock_Change_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Stock_Change_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Stock_Change_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Stock_Change_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Stock_Change_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Management_Stock_Change_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Stock_Change_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Management_Stock_Change_Avg_Fields = {
  __typename?: "inventory_management_stock_change_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  location_from_id?: Maybe<Scalars["Float"]>;
  location_to_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
  stock_owner_from?: Maybe<Scalars["Float"]>;
  stock_owner_to?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Avg_Order_By = {
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_stock_change". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Stock_Change_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Stock_Change_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Stock_Change_Bool_Exp>>>;
  comment?: Maybe<String_Comparison_Exp>;
  created_ts?: Maybe<Timestamptz_Comparison_Exp>;
  event_ts?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inventory_management_service?: Maybe<Inventory_Management_Services_Bool_Exp>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Bool_Exp>;
  inventory_management_stock_owner_from?: Maybe<Inventory_Management_Owners_Bool_Exp>;
  inventory_management_stock_owner_to?: Maybe<Inventory_Management_Owners_Bool_Exp>;
  location_from?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  location_from_id?: Maybe<Int_Comparison_Exp>;
  location_to?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  location_to_id?: Maybe<Int_Comparison_Exp>;
  order_ref?: Maybe<String_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  service_id?: Maybe<Int_Comparison_Exp>;
  sku_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  stock_owner_from?: Maybe<Int_Comparison_Exp>;
  stock_owner_to?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory_management_stock_change" */
export enum Inventory_Management_Stock_Change_Constraint {
  /** unique or primary key constraint */
  PkInventoryManagementStockChange = "PK_inventory_management_stock_change",
}

/** input type for incrementing integer column in table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  location_from_id?: Maybe<Scalars["Int"]>;
  location_to_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  service_id?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  stock_owner_from?: Maybe<Scalars["Int"]>;
  stock_owner_to?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Insert_Input = {
  comment?: Maybe<Scalars["String"]>;
  created_ts?: Maybe<Scalars["timestamptz"]>;
  event_ts?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inventory_management_service?: Maybe<Inventory_Management_Services_Obj_Rel_Insert_Input>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Obj_Rel_Insert_Input>;
  inventory_management_stock_owner_from?: Maybe<Inventory_Management_Owners_Obj_Rel_Insert_Input>;
  inventory_management_stock_owner_to?: Maybe<Inventory_Management_Owners_Obj_Rel_Insert_Input>;
  location_from?: Maybe<Inventory_Management_Locations_Obj_Rel_Insert_Input>;
  location_from_id?: Maybe<Scalars["Int"]>;
  location_to?: Maybe<Inventory_Management_Locations_Obj_Rel_Insert_Input>;
  location_to_id?: Maybe<Scalars["Int"]>;
  order_ref?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  service_id?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  stock_owner_from?: Maybe<Scalars["Int"]>;
  stock_owner_to?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Management_Stock_Change_Max_Fields = {
  __typename?: "inventory_management_stock_change_max_fields";
  comment?: Maybe<Scalars["String"]>;
  created_ts?: Maybe<Scalars["timestamptz"]>;
  event_ts?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  location_from_id?: Maybe<Scalars["Int"]>;
  location_to_id?: Maybe<Scalars["Int"]>;
  order_ref?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  service_id?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  stock_owner_from?: Maybe<Scalars["Int"]>;
  stock_owner_to?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Max_Order_By = {
  comment?: Maybe<Order_By>;
  created_ts?: Maybe<Order_By>;
  event_ts?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  order_ref?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Stock_Change_Min_Fields = {
  __typename?: "inventory_management_stock_change_min_fields";
  comment?: Maybe<Scalars["String"]>;
  created_ts?: Maybe<Scalars["timestamptz"]>;
  event_ts?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  location_from_id?: Maybe<Scalars["Int"]>;
  location_to_id?: Maybe<Scalars["Int"]>;
  order_ref?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  service_id?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  stock_owner_from?: Maybe<Scalars["Int"]>;
  stock_owner_to?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Min_Order_By = {
  comment?: Maybe<Order_By>;
  created_ts?: Maybe<Order_By>;
  event_ts?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  order_ref?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Mutation_Response = {
  __typename?: "inventory_management_stock_change_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Management_Stock_Change>;
};

/** input type for inserting object relation for remote table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Obj_Rel_Insert_Input = {
  data: Inventory_Management_Stock_Change_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Stock_Change_On_Conflict>;
};

/** on conflict condition type for table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_On_Conflict = {
  constraint: Inventory_Management_Stock_Change_Constraint;
  update_columns: Array<Inventory_Management_Stock_Change_Update_Column>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** ordering options when selecting data from "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Order_By = {
  comment?: Maybe<Order_By>;
  created_ts?: Maybe<Order_By>;
  event_ts?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inventory_management_service?: Maybe<Inventory_Management_Services_Order_By>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Order_By>;
  inventory_management_stock_owner_from?: Maybe<Inventory_Management_Owners_Order_By>;
  inventory_management_stock_owner_to?: Maybe<Inventory_Management_Owners_Order_By>;
  location_from?: Maybe<Inventory_Management_Locations_Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to?: Maybe<Inventory_Management_Locations_Order_By>;
  location_to_id?: Maybe<Order_By>;
  order_ref?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory_management_stock_change" */
export enum Inventory_Management_Stock_Change_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  CreatedTs = "created_ts",
  /** column name */
  EventTs = "event_ts",
  /** column name */
  Id = "id",
  /** column name */
  LocationFromId = "location_from_id",
  /** column name */
  LocationToId = "location_to_id",
  /** column name */
  OrderRef = "order_ref",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ServiceId = "service_id",
  /** column name */
  SkuId = "sku_id",
  /** column name */
  Status = "status",
  /** column name */
  StockOwnerFrom = "stock_owner_from",
  /** column name */
  StockOwnerTo = "stock_owner_to",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Set_Input = {
  comment?: Maybe<Scalars["String"]>;
  created_ts?: Maybe<Scalars["timestamptz"]>;
  event_ts?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  location_from_id?: Maybe<Scalars["Int"]>;
  location_to_id?: Maybe<Scalars["Int"]>;
  order_ref?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  service_id?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  stock_owner_from?: Maybe<Scalars["Int"]>;
  stock_owner_to?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Management_Stock_Change_Stddev_Fields = {
  __typename?: "inventory_management_stock_change_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  location_from_id?: Maybe<Scalars["Float"]>;
  location_to_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
  stock_owner_from?: Maybe<Scalars["Float"]>;
  stock_owner_to?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Stock_Change_Stddev_Pop_Fields = {
  __typename?: "inventory_management_stock_change_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  location_from_id?: Maybe<Scalars["Float"]>;
  location_to_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
  stock_owner_from?: Maybe<Scalars["Float"]>;
  stock_owner_to?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Stock_Change_Stddev_Samp_Fields = {
  __typename?: "inventory_management_stock_change_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  location_from_id?: Maybe<Scalars["Float"]>;
  location_to_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
  stock_owner_from?: Maybe<Scalars["Float"]>;
  stock_owner_to?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Stock_Change_Sum_Fields = {
  __typename?: "inventory_management_stock_change_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  location_from_id?: Maybe<Scalars["Int"]>;
  location_to_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  service_id?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  stock_owner_from?: Maybe<Scalars["Int"]>;
  stock_owner_to?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Sum_Order_By = {
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
};

/** update columns of table "inventory_management_stock_change" */
export enum Inventory_Management_Stock_Change_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  CreatedTs = "created_ts",
  /** column name */
  EventTs = "event_ts",
  /** column name */
  Id = "id",
  /** column name */
  LocationFromId = "location_from_id",
  /** column name */
  LocationToId = "location_to_id",
  /** column name */
  OrderRef = "order_ref",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ServiceId = "service_id",
  /** column name */
  SkuId = "sku_id",
  /** column name */
  Status = "status",
  /** column name */
  StockOwnerFrom = "stock_owner_from",
  /** column name */
  StockOwnerTo = "stock_owner_to",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Management_Stock_Change_Var_Pop_Fields = {
  __typename?: "inventory_management_stock_change_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  location_from_id?: Maybe<Scalars["Float"]>;
  location_to_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
  stock_owner_from?: Maybe<Scalars["Float"]>;
  stock_owner_to?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Stock_Change_Var_Samp_Fields = {
  __typename?: "inventory_management_stock_change_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  location_from_id?: Maybe<Scalars["Float"]>;
  location_to_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
  stock_owner_from?: Maybe<Scalars["Float"]>;
  stock_owner_to?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Stock_Change_Variance_Fields = {
  __typename?: "inventory_management_stock_change_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  location_from_id?: Maybe<Scalars["Float"]>;
  location_to_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  service_id?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
  stock_owner_from?: Maybe<Scalars["Float"]>;
  stock_owner_to?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_stock_change" */
export type Inventory_Management_Stock_Change_Variance_Order_By = {
  id?: Maybe<Order_By>;
  location_from_id?: Maybe<Order_By>;
  location_to_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  stock_owner_from?: Maybe<Order_By>;
  stock_owner_to?: Maybe<Order_By>;
};

/** columns and relationships of "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position = {
  __typename?: "inventory_management_stock_position";
  /** An object relationship */
  inventory_management_locations?: Maybe<Inventory_Management_Locations>;
  /** An object relationship */
  inventory_management_owners?: Maybe<Inventory_Management_Owners>;
  /** An object relationship */
  inventory_management_sku?: Maybe<Inventory_Management_Sku>;
  loc_id?: Maybe<Scalars["Int"]>;
  owner_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Aggregate = {
  __typename?: "inventory_management_stock_position_aggregate";
  aggregate?: Maybe<Inventory_Management_Stock_Position_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Stock_Position>;
};

/** aggregate fields of "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Aggregate_Fields = {
  __typename?: "inventory_management_stock_position_aggregate_fields";
  avg?: Maybe<Inventory_Management_Stock_Position_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Stock_Position_Max_Fields>;
  min?: Maybe<Inventory_Management_Stock_Position_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Stock_Position_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Stock_Position_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Stock_Position_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Stock_Position_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Stock_Position_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Stock_Position_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Stock_Position_Variance_Fields>;
};

/** aggregate fields of "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Stock_Position_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Stock_Position_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Stock_Position_Max_Order_By>;
  min?: Maybe<Inventory_Management_Stock_Position_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Stock_Position_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Stock_Position_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Stock_Position_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Stock_Position_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Stock_Position_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Stock_Position_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Stock_Position_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Inventory_Management_Stock_Position_Avg_Fields = {
  __typename?: "inventory_management_stock_position_avg_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Avg_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_stock_position". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Stock_Position_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Stock_Position_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Stock_Position_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Stock_Position_Bool_Exp>>>;
  inventory_management_locations?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  inventory_management_owners?: Maybe<Inventory_Management_Owners_Bool_Exp>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Bool_Exp>;
  loc_id?: Maybe<Int_Comparison_Exp>;
  owner_id?: Maybe<Int_Comparison_Exp>;
  qty?: Maybe<Bigint_Comparison_Exp>;
  sku_id?: Maybe<Int_Comparison_Exp>;
};

/** columns and relationships of "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date = {
  __typename?: "inventory_management_stock_position_by_date";
  dts?: Maybe<Scalars["timestamp"]>;
  /** An object relationship */
  inventory_management_locations?: Maybe<Inventory_Management_Locations>;
  /** An object relationship */
  inventory_management_owners?: Maybe<Inventory_Management_Owners>;
  /** An object relationship */
  inventory_management_sku?: Maybe<Inventory_Management_Sku>;
  loc_id?: Maybe<Scalars["Int"]>;
  owner_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Aggregate = {
  __typename?: "inventory_management_stock_position_by_date_aggregate";
  aggregate?: Maybe<Inventory_Management_Stock_Position_By_Date_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Stock_Position_By_Date>;
};

/** aggregate fields of "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Aggregate_Fields = {
  __typename?: "inventory_management_stock_position_by_date_aggregate_fields";
  avg?: Maybe<Inventory_Management_Stock_Position_By_Date_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Stock_Position_By_Date_Max_Fields>;
  min?: Maybe<Inventory_Management_Stock_Position_By_Date_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Stock_Position_By_Date_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Stock_Position_By_Date_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Stock_Position_By_Date_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Stock_Position_By_Date_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Stock_Position_By_Date_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Stock_Position_By_Date_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Stock_Position_By_Date_Variance_Fields>;
};

/** aggregate fields of "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Stock_Position_By_Date_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Stock_Position_By_Date_Max_Order_By>;
  min?: Maybe<Inventory_Management_Stock_Position_By_Date_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Stock_Position_By_Date_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Stock_Position_By_Date_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Stock_Position_By_Date_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Stock_Position_By_Date_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Stock_Position_By_Date_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Stock_Position_By_Date_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Stock_Position_By_Date_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Inventory_Management_Stock_Position_By_Date_Avg_Fields = {
  __typename?: "inventory_management_stock_position_by_date_avg_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Avg_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_stock_position_by_date". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Stock_Position_By_Date_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>>>;
  dts?: Maybe<Timestamp_Comparison_Exp>;
  inventory_management_locations?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  inventory_management_owners?: Maybe<Inventory_Management_Owners_Bool_Exp>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Bool_Exp>;
  loc_id?: Maybe<Int_Comparison_Exp>;
  owner_id?: Maybe<Int_Comparison_Exp>;
  qty?: Maybe<Bigint_Comparison_Exp>;
  sku_id?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Inventory_Management_Stock_Position_By_Date_Max_Fields = {
  __typename?: "inventory_management_stock_position_by_date_max_fields";
  dts?: Maybe<Scalars["timestamp"]>;
  loc_id?: Maybe<Scalars["Int"]>;
  owner_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Max_Order_By = {
  dts?: Maybe<Order_By>;
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Stock_Position_By_Date_Min_Fields = {
  __typename?: "inventory_management_stock_position_by_date_min_fields";
  dts?: Maybe<Scalars["timestamp"]>;
  loc_id?: Maybe<Scalars["Int"]>;
  owner_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Min_Order_By = {
  dts?: Maybe<Order_By>;
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Order_By = {
  dts?: Maybe<Order_By>;
  inventory_management_locations?: Maybe<Inventory_Management_Locations_Order_By>;
  inventory_management_owners?: Maybe<Inventory_Management_Owners_Order_By>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Order_By>;
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** select columns of table "inventory_management_stock_position_by_date" */
export enum Inventory_Management_Stock_Position_By_Date_Select_Column {
  /** column name */
  Dts = "dts",
  /** column name */
  LocId = "loc_id",
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  Qty = "qty",
  /** column name */
  SkuId = "sku_id",
}

/** aggregate stddev on columns */
export type Inventory_Management_Stock_Position_By_Date_Stddev_Fields = {
  __typename?: "inventory_management_stock_position_by_date_stddev_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Stddev_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Stock_Position_By_Date_Stddev_Pop_Fields = {
  __typename?: "inventory_management_stock_position_by_date_stddev_pop_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Stddev_Pop_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Stock_Position_By_Date_Stddev_Samp_Fields = {
  __typename?: "inventory_management_stock_position_by_date_stddev_samp_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Stddev_Samp_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Stock_Position_By_Date_Sum_Fields = {
  __typename?: "inventory_management_stock_position_by_date_sum_fields";
  loc_id?: Maybe<Scalars["Int"]>;
  owner_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Sum_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Inventory_Management_Stock_Position_By_Date_Var_Pop_Fields = {
  __typename?: "inventory_management_stock_position_by_date_var_pop_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Var_Pop_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Stock_Position_By_Date_Var_Samp_Fields = {
  __typename?: "inventory_management_stock_position_by_date_var_samp_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Var_Samp_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Stock_Position_By_Date_Variance_Fields = {
  __typename?: "inventory_management_stock_position_by_date_variance_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_stock_position_by_date" */
export type Inventory_Management_Stock_Position_By_Date_Variance_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Inventory_Management_Stock_Position_Max_Fields = {
  __typename?: "inventory_management_stock_position_max_fields";
  loc_id?: Maybe<Scalars["Int"]>;
  owner_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Max_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Stock_Position_Min_Fields = {
  __typename?: "inventory_management_stock_position_min_fields";
  loc_id?: Maybe<Scalars["Int"]>;
  owner_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Min_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Order_By = {
  inventory_management_locations?: Maybe<Inventory_Management_Locations_Order_By>;
  inventory_management_owners?: Maybe<Inventory_Management_Owners_Order_By>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Order_By>;
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** select columns of table "inventory_management_stock_position" */
export enum Inventory_Management_Stock_Position_Select_Column {
  /** column name */
  LocId = "loc_id",
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  Qty = "qty",
  /** column name */
  SkuId = "sku_id",
}

/** aggregate stddev on columns */
export type Inventory_Management_Stock_Position_Stddev_Fields = {
  __typename?: "inventory_management_stock_position_stddev_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Stddev_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Stock_Position_Stddev_Pop_Fields = {
  __typename?: "inventory_management_stock_position_stddev_pop_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Stddev_Pop_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Stock_Position_Stddev_Samp_Fields = {
  __typename?: "inventory_management_stock_position_stddev_samp_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Stddev_Samp_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Stock_Position_Sum_Fields = {
  __typename?: "inventory_management_stock_position_sum_fields";
  loc_id?: Maybe<Scalars["Int"]>;
  owner_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Sum_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Inventory_Management_Stock_Position_Var_Pop_Fields = {
  __typename?: "inventory_management_stock_position_var_pop_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Var_Pop_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Stock_Position_Var_Samp_Fields = {
  __typename?: "inventory_management_stock_position_var_samp_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Var_Samp_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Stock_Position_Variance_Fields = {
  __typename?: "inventory_management_stock_position_variance_fields";
  loc_id?: Maybe<Scalars["Float"]>;
  owner_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_stock_position" */
export type Inventory_Management_Stock_Position_Variance_Order_By = {
  loc_id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory_management_stocks" */
export type Inventory_Management_Stocks = {
  __typename?: "inventory_management_stocks";
  /** An object relationship */
  inventory_management_location: Inventory_Management_Locations;
  /** An object relationship */
  inventory_management_sku: Inventory_Management_Sku;
  location_id: Scalars["Int"];
  quantity: Scalars["Int"];
  sku_id: Scalars["Int"];
  status: Scalars["String"];
};

/** aggregated selection of "inventory_management_stocks" */
export type Inventory_Management_Stocks_Aggregate = {
  __typename?: "inventory_management_stocks_aggregate";
  aggregate?: Maybe<Inventory_Management_Stocks_Aggregate_Fields>;
  nodes: Array<Inventory_Management_Stocks>;
};

/** aggregate fields of "inventory_management_stocks" */
export type Inventory_Management_Stocks_Aggregate_Fields = {
  __typename?: "inventory_management_stocks_aggregate_fields";
  avg?: Maybe<Inventory_Management_Stocks_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Management_Stocks_Max_Fields>;
  min?: Maybe<Inventory_Management_Stocks_Min_Fields>;
  stddev?: Maybe<Inventory_Management_Stocks_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Management_Stocks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Management_Stocks_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Management_Stocks_Sum_Fields>;
  var_pop?: Maybe<Inventory_Management_Stocks_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Management_Stocks_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Management_Stocks_Variance_Fields>;
};

/** aggregate fields of "inventory_management_stocks" */
export type Inventory_Management_Stocks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Management_Stocks_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Management_Stocks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Management_Stocks_Max_Order_By>;
  min?: Maybe<Inventory_Management_Stocks_Min_Order_By>;
  stddev?: Maybe<Inventory_Management_Stocks_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Management_Stocks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Management_Stocks_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Management_Stocks_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Management_Stocks_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Management_Stocks_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Management_Stocks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Management_Stocks_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Stocks_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Management_Stocks_Avg_Fields = {
  __typename?: "inventory_management_stocks_avg_fields";
  location_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Avg_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory_management_stocks". All fields are combined with a logical 'AND'. */
export type Inventory_Management_Stocks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Management_Stocks_Bool_Exp>>>;
  _not?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Management_Stocks_Bool_Exp>>>;
  inventory_management_location?: Maybe<Inventory_Management_Locations_Bool_Exp>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  sku_id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory_management_stocks" */
export enum Inventory_Management_Stocks_Constraint {
  /** unique or primary key constraint */
  PkInventoryManagementStocks = "PK_inventory_management_stocks",
}

/** input type for incrementing integer column in table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Inc_Input = {
  location_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Insert_Input = {
  inventory_management_location?: Maybe<Inventory_Management_Locations_Obj_Rel_Insert_Input>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Management_Stocks_Max_Fields = {
  __typename?: "inventory_management_stocks_max_fields";
  location_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Max_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Management_Stocks_Min_Fields = {
  __typename?: "inventory_management_stocks_min_fields";
  location_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Min_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Mutation_Response = {
  __typename?: "inventory_management_stocks_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Management_Stocks>;
};

/** input type for inserting object relation for remote table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Obj_Rel_Insert_Input = {
  data: Inventory_Management_Stocks_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Stocks_On_Conflict>;
};

/** on conflict condition type for table "inventory_management_stocks" */
export type Inventory_Management_Stocks_On_Conflict = {
  constraint: Inventory_Management_Stocks_Constraint;
  update_columns: Array<Inventory_Management_Stocks_Update_Column>;
  where?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
};

/** ordering options when selecting data from "inventory_management_stocks" */
export type Inventory_Management_Stocks_Order_By = {
  inventory_management_location?: Maybe<Inventory_Management_Locations_Order_By>;
  inventory_management_sku?: Maybe<Inventory_Management_Sku_Order_By>;
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory_management_stocks" */
export type Inventory_Management_Stocks_Pk_Columns_Input = {
  location_id: Scalars["Int"];
  sku_id: Scalars["Int"];
  status: Scalars["String"];
};

/** select columns of table "inventory_management_stocks" */
export enum Inventory_Management_Stocks_Select_Column {
  /** column name */
  LocationId = "location_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SkuId = "sku_id",
  /** column name */
  Status = "status",
}

/** input type for updating data in table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Set_Input = {
  location_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Management_Stocks_Stddev_Fields = {
  __typename?: "inventory_management_stocks_stddev_fields";
  location_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Stddev_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Management_Stocks_Stddev_Pop_Fields = {
  __typename?: "inventory_management_stocks_stddev_pop_fields";
  location_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Stddev_Pop_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Management_Stocks_Stddev_Samp_Fields = {
  __typename?: "inventory_management_stocks_stddev_samp_fields";
  location_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Stddev_Samp_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Management_Stocks_Sum_Fields = {
  __typename?: "inventory_management_stocks_sum_fields";
  location_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Sum_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** update columns of table "inventory_management_stocks" */
export enum Inventory_Management_Stocks_Update_Column {
  /** column name */
  LocationId = "location_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SkuId = "sku_id",
  /** column name */
  Status = "status",
}

/** aggregate var_pop on columns */
export type Inventory_Management_Stocks_Var_Pop_Fields = {
  __typename?: "inventory_management_stocks_var_pop_fields";
  location_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Var_Pop_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Management_Stocks_Var_Samp_Fields = {
  __typename?: "inventory_management_stocks_var_samp_fields";
  location_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Var_Samp_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Management_Stocks_Variance_Fields = {
  __typename?: "inventory_management_stocks_variance_fields";
  location_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory_management_stocks" */
export type Inventory_Management_Stocks_Variance_Order_By = {
  location_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.meeting_note" */
export type Inventory_Meeting_Note = {
  __typename?: "inventory_meeting_note";
  /** An array relationship */
  account_meeting_notes: Array<Inventory_Account_Meeting_Note>;
  /** An aggregated array relationship */
  account_meeting_notes_aggregate: Inventory_Account_Meeting_Note_Aggregate;
  contacts: Scalars["_text"];
  content: Scalars["String"];
  /** An object relationship */
  folder: Inventory_Folder;
  folder_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  meeting_date: Scalars["timestamptz"];
  /** An object relationship */
  meeting_note_status: Inventory_Meeting_Note_Status;
  meeting_note_status_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.meeting_note" */
export type Inventory_Meeting_NoteAccount_Meeting_NotesArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
};

/** columns and relationships of "inventory.meeting_note" */
export type Inventory_Meeting_NoteAccount_Meeting_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
};

/** aggregated selection of "inventory.meeting_note" */
export type Inventory_Meeting_Note_Aggregate = {
  __typename?: "inventory_meeting_note_aggregate";
  aggregate?: Maybe<Inventory_Meeting_Note_Aggregate_Fields>;
  nodes: Array<Inventory_Meeting_Note>;
};

/** aggregate fields of "inventory.meeting_note" */
export type Inventory_Meeting_Note_Aggregate_Fields = {
  __typename?: "inventory_meeting_note_aggregate_fields";
  avg?: Maybe<Inventory_Meeting_Note_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Meeting_Note_Max_Fields>;
  min?: Maybe<Inventory_Meeting_Note_Min_Fields>;
  stddev?: Maybe<Inventory_Meeting_Note_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Meeting_Note_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Meeting_Note_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Meeting_Note_Sum_Fields>;
  var_pop?: Maybe<Inventory_Meeting_Note_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Meeting_Note_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Meeting_Note_Variance_Fields>;
};

/** aggregate fields of "inventory.meeting_note" */
export type Inventory_Meeting_Note_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Meeting_Note_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Meeting_Note_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Meeting_Note_Max_Order_By>;
  min?: Maybe<Inventory_Meeting_Note_Min_Order_By>;
  stddev?: Maybe<Inventory_Meeting_Note_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Meeting_Note_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Meeting_Note_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Meeting_Note_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Meeting_Note_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Meeting_Note_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Meeting_Note_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Meeting_Note_Insert_Input>;
  on_conflict?: Maybe<Inventory_Meeting_Note_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Meeting_Note_Avg_Fields = {
  __typename?: "inventory_meeting_note_avg_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Avg_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.meeting_note". All fields are combined with a logical 'AND'. */
export type Inventory_Meeting_Note_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Meeting_Note_Bool_Exp>>>;
  _not?: Maybe<Inventory_Meeting_Note_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Meeting_Note_Bool_Exp>>>;
  account_meeting_notes?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
  contacts?: Maybe<_Text_Comparison_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  folder?: Maybe<Inventory_Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  meeting_date?: Maybe<Timestamptz_Comparison_Exp>;
  meeting_note_status?: Maybe<Inventory_Meeting_Note_Status_Bool_Exp>;
  meeting_note_status_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.meeting_note" */
export enum Inventory_Meeting_Note_Constraint {
  /** unique or primary key constraint */
  MeetingNotePkey = "meeting_note_pkey",
}

/** input type for incrementing integer column in table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Inc_Input = {
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  meeting_note_status_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Insert_Input = {
  account_meeting_notes?: Maybe<Inventory_Account_Meeting_Note_Arr_Rel_Insert_Input>;
  contacts?: Maybe<Scalars["_text"]>;
  content?: Maybe<Scalars["String"]>;
  folder?: Maybe<Inventory_Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_date?: Maybe<Scalars["timestamptz"]>;
  meeting_note_status?: Maybe<Inventory_Meeting_Note_Status_Obj_Rel_Insert_Input>;
  meeting_note_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Meeting_Note_Max_Fields = {
  __typename?: "inventory_meeting_note_max_fields";
  content?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_date?: Maybe<Scalars["timestamptz"]>;
  meeting_note_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Max_Order_By = {
  content?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_date?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Meeting_Note_Min_Fields = {
  __typename?: "inventory_meeting_note_min_fields";
  content?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_date?: Maybe<Scalars["timestamptz"]>;
  meeting_note_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Min_Order_By = {
  content?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_date?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Mutation_Response = {
  __typename?: "inventory_meeting_note_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Meeting_Note>;
};

/** input type for inserting object relation for remote table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Obj_Rel_Insert_Input = {
  data: Inventory_Meeting_Note_Insert_Input;
  on_conflict?: Maybe<Inventory_Meeting_Note_On_Conflict>;
};

/** on conflict condition type for table "inventory.meeting_note" */
export type Inventory_Meeting_Note_On_Conflict = {
  constraint: Inventory_Meeting_Note_Constraint;
  update_columns: Array<Inventory_Meeting_Note_Update_Column>;
  where?: Maybe<Inventory_Meeting_Note_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.meeting_note" */
export type Inventory_Meeting_Note_Order_By = {
  account_meeting_notes_aggregate?: Maybe<Inventory_Account_Meeting_Note_Aggregate_Order_By>;
  contacts?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  folder?: Maybe<Inventory_Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_date?: Maybe<Order_By>;
  meeting_note_status?: Maybe<Inventory_Meeting_Note_Status_Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.meeting_note" */
export type Inventory_Meeting_Note_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.meeting_note" */
export enum Inventory_Meeting_Note_Select_Column {
  /** column name */
  Contacts = "contacts",
  /** column name */
  Content = "content",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MeetingDate = "meeting_date",
  /** column name */
  MeetingNoteStatusId = "meeting_note_status_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Set_Input = {
  contacts?: Maybe<Scalars["_text"]>;
  content?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_date?: Maybe<Scalars["timestamptz"]>;
  meeting_note_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status = {
  __typename?: "inventory_meeting_note_status";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  meeting_note_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Aggregate = {
  __typename?: "inventory_meeting_note_status_aggregate";
  aggregate?: Maybe<Inventory_Meeting_Note_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Meeting_Note_Status>;
};

/** aggregate fields of "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Aggregate_Fields = {
  __typename?: "inventory_meeting_note_status_aggregate_fields";
  avg?: Maybe<Inventory_Meeting_Note_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Meeting_Note_Status_Max_Fields>;
  min?: Maybe<Inventory_Meeting_Note_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Meeting_Note_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Meeting_Note_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Meeting_Note_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Meeting_Note_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Meeting_Note_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Meeting_Note_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Meeting_Note_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Meeting_Note_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Meeting_Note_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Meeting_Note_Status_Max_Order_By>;
  min?: Maybe<Inventory_Meeting_Note_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Meeting_Note_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Meeting_Note_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Meeting_Note_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Meeting_Note_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Meeting_Note_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Meeting_Note_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Meeting_Note_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Meeting_Note_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Meeting_Note_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Meeting_Note_Status_Avg_Fields = {
  __typename?: "inventory_meeting_note_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.meeting_note_status". All fields are combined with a logical 'AND'. */
export type Inventory_Meeting_Note_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Meeting_Note_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Meeting_Note_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Meeting_Note_Status_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  meeting_note_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.meeting_note_status" */
export enum Inventory_Meeting_Note_Status_Constraint {
  /** unique or primary key constraint */
  MeetingNoteStatusMeetingNoteStatusNameKey = "meeting_note_status_meeting_note_status_name_key",
  /** unique or primary key constraint */
  MeetingNoteStatusPkey = "meeting_note_status_pkey",
}

/** input type for incrementing integer column in table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_note_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Meeting_Note_Status_Max_Fields = {
  __typename?: "inventory_meeting_note_status_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_note_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_note_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Meeting_Note_Status_Min_Fields = {
  __typename?: "inventory_meeting_note_status_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_note_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_note_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Mutation_Response = {
  __typename?: "inventory_meeting_note_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Meeting_Note_Status>;
};

/** input type for inserting object relation for remote table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Meeting_Note_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Meeting_Note_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_On_Conflict = {
  constraint: Inventory_Meeting_Note_Status_Constraint;
  update_columns: Array<Inventory_Meeting_Note_Status_Update_Column>;
  where?: Maybe<Inventory_Meeting_Note_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  meeting_note_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.meeting_note_status" */
export enum Inventory_Meeting_Note_Status_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MeetingNoteStatusName = "meeting_note_status_name",
}

/** input type for updating data in table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  meeting_note_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Meeting_Note_Status_Stddev_Fields = {
  __typename?: "inventory_meeting_note_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Meeting_Note_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_meeting_note_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Meeting_Note_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_meeting_note_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Meeting_Note_Status_Sum_Fields = {
  __typename?: "inventory_meeting_note_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.meeting_note_status" */
export enum Inventory_Meeting_Note_Status_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MeetingNoteStatusName = "meeting_note_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Meeting_Note_Status_Var_Pop_Fields = {
  __typename?: "inventory_meeting_note_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Meeting_Note_Status_Var_Samp_Fields = {
  __typename?: "inventory_meeting_note_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Meeting_Note_Status_Variance_Fields = {
  __typename?: "inventory_meeting_note_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.meeting_note_status" */
export type Inventory_Meeting_Note_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Meeting_Note_Stddev_Fields = {
  __typename?: "inventory_meeting_note_stddev_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Stddev_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Meeting_Note_Stddev_Pop_Fields = {
  __typename?: "inventory_meeting_note_stddev_pop_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Stddev_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Meeting_Note_Stddev_Samp_Fields = {
  __typename?: "inventory_meeting_note_stddev_samp_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Stddev_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Meeting_Note_Sum_Fields = {
  __typename?: "inventory_meeting_note_sum_fields";
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  meeting_note_status_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Sum_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.meeting_note" */
export enum Inventory_Meeting_Note_Update_Column {
  /** column name */
  Contacts = "contacts",
  /** column name */
  Content = "content",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MeetingDate = "meeting_date",
  /** column name */
  MeetingNoteStatusId = "meeting_note_status_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Meeting_Note_Var_Pop_Fields = {
  __typename?: "inventory_meeting_note_var_pop_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Var_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Meeting_Note_Var_Samp_Fields = {
  __typename?: "inventory_meeting_note_var_samp_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Var_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Meeting_Note_Variance_Fields = {
  __typename?: "inventory_meeting_note_variance_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  meeting_note_status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.meeting_note" */
export type Inventory_Meeting_Note_Variance_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_note_status_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.product" */
export type Inventory_Product = {
  __typename?: "inventory_product";
  /** An object relationship */
  base_product: Inventory_Base_Product;
  base_product_id: Scalars["Int"];
  case_qty: Scalars["Int"];
  /** An array relationship */
  client_order_lines: Array<Inventory_Client_Order_Line>;
  /** An aggregated array relationship */
  client_order_lines_aggregate: Inventory_Client_Order_Line_Aggregate;
  height_mm: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  length_mm: Scalars["Int"];
  /** An array relationship */
  product_pricings: Array<Inventory_Product_Pricing>;
  /** An aggregated array relationship */
  product_pricings_aggregate: Inventory_Product_Pricing_Aggregate;
  /** An array relationship */
  product_stocks: Array<Inventory_Product_Stock>;
  /** An aggregated array relationship */
  product_stocks_aggregate: Inventory_Product_Stock_Aggregate;
  user_id: Scalars["String"];
  weight_grams: Scalars["Int"];
  width_mm: Scalars["Int"];
};

/** columns and relationships of "inventory.product" */
export type Inventory_ProductClient_Order_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.product" */
export type Inventory_ProductClient_Order_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.product" */
export type Inventory_ProductProduct_PricingsArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Pricing_Order_By>>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** columns and relationships of "inventory.product" */
export type Inventory_ProductProduct_Pricings_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Pricing_Order_By>>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** columns and relationships of "inventory.product" */
export type Inventory_ProductProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** columns and relationships of "inventory.product" */
export type Inventory_ProductProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** aggregated selection of "inventory.product" */
export type Inventory_Product_Aggregate = {
  __typename?: "inventory_product_aggregate";
  aggregate?: Maybe<Inventory_Product_Aggregate_Fields>;
  nodes: Array<Inventory_Product>;
};

/** aggregate fields of "inventory.product" */
export type Inventory_Product_Aggregate_Fields = {
  __typename?: "inventory_product_aggregate_fields";
  avg?: Maybe<Inventory_Product_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Product_Max_Fields>;
  min?: Maybe<Inventory_Product_Min_Fields>;
  stddev?: Maybe<Inventory_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Product_Sum_Fields>;
  var_pop?: Maybe<Inventory_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Product_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Product_Variance_Fields>;
};

/** aggregate fields of "inventory.product" */
export type Inventory_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Product_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.product" */
export type Inventory_Product_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Product_Max_Order_By>;
  min?: Maybe<Inventory_Product_Min_Order_By>;
  stddev?: Maybe<Inventory_Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Product_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Product_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.product" */
export type Inventory_Product_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Product_Insert_Input>;
  on_conflict?: Maybe<Inventory_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Product_Avg_Fields = {
  __typename?: "inventory_product_avg_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_qty?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.product" */
export type Inventory_Product_Avg_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.product". All fields are combined with a logical 'AND'. */
export type Inventory_Product_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Product_Bool_Exp>>>;
  _not?: Maybe<Inventory_Product_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Product_Bool_Exp>>>;
  base_product?: Maybe<Inventory_Base_Product_Bool_Exp>;
  base_product_id?: Maybe<Int_Comparison_Exp>;
  case_qty?: Maybe<Int_Comparison_Exp>;
  client_order_lines?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
  height_mm?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  length_mm?: Maybe<Int_Comparison_Exp>;
  product_pricings?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
  product_stocks?: Maybe<Inventory_Product_Stock_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
  weight_grams?: Maybe<Int_Comparison_Exp>;
  width_mm?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.product" */
export enum Inventory_Product_Constraint {
  /** unique or primary key constraint */
  ProductPkey = "product_pkey",
}

/** input type for incrementing integer column in table "inventory.product" */
export type Inventory_Product_Inc_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  case_qty?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.product" */
export type Inventory_Product_Insert_Input = {
  base_product?: Maybe<Inventory_Base_Product_Obj_Rel_Insert_Input>;
  base_product_id?: Maybe<Scalars["Int"]>;
  case_qty?: Maybe<Scalars["Int"]>;
  client_order_lines?: Maybe<Inventory_Client_Order_Line_Arr_Rel_Insert_Input>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_pricings?: Maybe<Inventory_Product_Pricing_Arr_Rel_Insert_Input>;
  product_stocks?: Maybe<Inventory_Product_Stock_Arr_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["String"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Inventory_Product_Max_Fields = {
  __typename?: "inventory_product_max_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  case_qty?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inventory.product" */
export type Inventory_Product_Max_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Product_Min_Fields = {
  __typename?: "inventory_product_min_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  case_qty?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inventory.product" */
export type Inventory_Product_Min_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.product" */
export type Inventory_Product_Mutation_Response = {
  __typename?: "inventory_product_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Product>;
};

/** input type for inserting object relation for remote table "inventory.product" */
export type Inventory_Product_Obj_Rel_Insert_Input = {
  data: Inventory_Product_Insert_Input;
  on_conflict?: Maybe<Inventory_Product_On_Conflict>;
};

/** on conflict condition type for table "inventory.product" */
export type Inventory_Product_On_Conflict = {
  constraint: Inventory_Product_Constraint;
  update_columns: Array<Inventory_Product_Update_Column>;
  where?: Maybe<Inventory_Product_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.product" */
export type Inventory_Product_Order_By = {
  base_product?: Maybe<Inventory_Base_Product_Order_By>;
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  client_order_lines_aggregate?: Maybe<Inventory_Client_Order_Line_Aggregate_Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  product_pricings_aggregate?: Maybe<Inventory_Product_Pricing_Aggregate_Order_By>;
  product_stocks_aggregate?: Maybe<Inventory_Product_Stock_Aggregate_Order_By>;
  user_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.product" */
export type Inventory_Product_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** columns and relationships of "inventory.product_pricing" */
export type Inventory_Product_Pricing = {
  __typename?: "inventory_product_pricing";
  /** An array relationship */
  client_order_lines: Array<Inventory_Client_Order_Line>;
  /** An aggregated array relationship */
  client_order_lines_aggregate: Inventory_Client_Order_Line_Aggregate;
  /** An object relationship */
  currency: Inventory_Currency;
  currency_code: Scalars["String"];
  /** An object relationship */
  folder?: Maybe<Inventory_Folder>;
  folder_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  min_qty: Scalars["Int"];
  notes: Scalars["String"];
  /** An object relationship */
  product: Inventory_Product;
  product_id: Scalars["Int"];
  product_pricing_reference: Scalars["String"];
  unit_price: Scalars["numeric"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.product_pricing" */
export type Inventory_Product_PricingClient_Order_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.product_pricing" */
export type Inventory_Product_PricingClient_Order_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** aggregated selection of "inventory.product_pricing" */
export type Inventory_Product_Pricing_Aggregate = {
  __typename?: "inventory_product_pricing_aggregate";
  aggregate?: Maybe<Inventory_Product_Pricing_Aggregate_Fields>;
  nodes: Array<Inventory_Product_Pricing>;
};

/** aggregate fields of "inventory.product_pricing" */
export type Inventory_Product_Pricing_Aggregate_Fields = {
  __typename?: "inventory_product_pricing_aggregate_fields";
  avg?: Maybe<Inventory_Product_Pricing_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Product_Pricing_Max_Fields>;
  min?: Maybe<Inventory_Product_Pricing_Min_Fields>;
  stddev?: Maybe<Inventory_Product_Pricing_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Product_Pricing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Product_Pricing_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Product_Pricing_Sum_Fields>;
  var_pop?: Maybe<Inventory_Product_Pricing_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Product_Pricing_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Product_Pricing_Variance_Fields>;
};

/** aggregate fields of "inventory.product_pricing" */
export type Inventory_Product_Pricing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Product_Pricing_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Product_Pricing_Max_Order_By>;
  min?: Maybe<Inventory_Product_Pricing_Min_Order_By>;
  stddev?: Maybe<Inventory_Product_Pricing_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Product_Pricing_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Product_Pricing_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Product_Pricing_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Product_Pricing_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Product_Pricing_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Product_Pricing_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Product_Pricing_Insert_Input>;
  on_conflict?: Maybe<Inventory_Product_Pricing_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Product_Pricing_Avg_Fields = {
  __typename?: "inventory_product_pricing_avg_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Avg_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.product_pricing". All fields are combined with a logical 'AND'. */
export type Inventory_Product_Pricing_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Product_Pricing_Bool_Exp>>>;
  _not?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Product_Pricing_Bool_Exp>>>;
  client_order_lines?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
  currency?: Maybe<Inventory_Currency_Bool_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  folder?: Maybe<Inventory_Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  min_qty?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  product?: Maybe<Inventory_Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  product_pricing_reference?: Maybe<String_Comparison_Exp>;
  unit_price?: Maybe<Numeric_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.product_pricing" */
export enum Inventory_Product_Pricing_Constraint {
  /** unique or primary key constraint */
  ProductPricingPkey = "product_pricing_pkey",
  /** unique or primary key constraint */
  ProductPricingProductPricingReferenceKey = "product_pricing_product_pricing_reference_key",
}

/** input type for incrementing integer column in table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Inc_Input = {
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Insert_Input = {
  client_order_lines?: Maybe<Inventory_Client_Order_Line_Arr_Rel_Insert_Input>;
  currency?: Maybe<Inventory_Currency_Obj_Rel_Insert_Input>;
  currency_code?: Maybe<Scalars["String"]>;
  folder?: Maybe<Inventory_Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  product?: Maybe<Inventory_Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_reference?: Maybe<Scalars["String"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Product_Pricing_Max_Fields = {
  __typename?: "inventory_product_pricing_max_fields";
  currency_code?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_reference?: Maybe<Scalars["String"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Max_Order_By = {
  currency_code?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_reference?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Product_Pricing_Min_Fields = {
  __typename?: "inventory_product_pricing_min_fields";
  currency_code?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_reference?: Maybe<Scalars["String"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Min_Order_By = {
  currency_code?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_reference?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Mutation_Response = {
  __typename?: "inventory_product_pricing_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Product_Pricing>;
};

/** input type for inserting object relation for remote table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Obj_Rel_Insert_Input = {
  data: Inventory_Product_Pricing_Insert_Input;
  on_conflict?: Maybe<Inventory_Product_Pricing_On_Conflict>;
};

/** on conflict condition type for table "inventory.product_pricing" */
export type Inventory_Product_Pricing_On_Conflict = {
  constraint: Inventory_Product_Pricing_Constraint;
  update_columns: Array<Inventory_Product_Pricing_Update_Column>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.product_pricing" */
export type Inventory_Product_Pricing_Order_By = {
  client_order_lines_aggregate?: Maybe<Inventory_Client_Order_Line_Aggregate_Order_By>;
  currency?: Maybe<Inventory_Currency_Order_By>;
  currency_code?: Maybe<Order_By>;
  folder?: Maybe<Inventory_Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product?: Maybe<Inventory_Product_Order_By>;
  product_id?: Maybe<Order_By>;
  product_pricing_reference?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.product_pricing" */
export type Inventory_Product_Pricing_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.product_pricing" */
export enum Inventory_Product_Pricing_Select_Column {
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MinQty = "min_qty",
  /** column name */
  Notes = "notes",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductPricingReference = "product_pricing_reference",
  /** column name */
  UnitPrice = "unit_price",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Set_Input = {
  currency_code?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_pricing_reference?: Maybe<Scalars["String"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Product_Pricing_Stddev_Fields = {
  __typename?: "inventory_product_pricing_stddev_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Stddev_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Product_Pricing_Stddev_Pop_Fields = {
  __typename?: "inventory_product_pricing_stddev_pop_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Stddev_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Product_Pricing_Stddev_Samp_Fields = {
  __typename?: "inventory_product_pricing_stddev_samp_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Stddev_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Product_Pricing_Sum_Fields = {
  __typename?: "inventory_product_pricing_sum_fields";
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  unit_price?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Sum_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** update columns of table "inventory.product_pricing" */
export enum Inventory_Product_Pricing_Update_Column {
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MinQty = "min_qty",
  /** column name */
  Notes = "notes",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductPricingReference = "product_pricing_reference",
  /** column name */
  UnitPrice = "unit_price",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Product_Pricing_Var_Pop_Fields = {
  __typename?: "inventory_product_pricing_var_pop_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Var_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Product_Pricing_Var_Samp_Fields = {
  __typename?: "inventory_product_pricing_var_samp_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Var_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Product_Pricing_Variance_Fields = {
  __typename?: "inventory_product_pricing_variance_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  unit_price?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.product_pricing" */
export type Inventory_Product_Pricing_Variance_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** select columns of table "inventory.product" */
export enum Inventory_Product_Select_Column {
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  CaseQty = "case_qty",
  /** column name */
  HeightMm = "height_mm",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LengthMm = "length_mm",
  /** column name */
  UserId = "user_id",
  /** column name */
  WeightGrams = "weight_grams",
  /** column name */
  WidthMm = "width_mm",
}

/** input type for updating data in table "inventory.product" */
export type Inventory_Product_Set_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  case_qty?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Inventory_Product_Stddev_Fields = {
  __typename?: "inventory_product_stddev_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_qty?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.product" */
export type Inventory_Product_Stddev_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Product_Stddev_Pop_Fields = {
  __typename?: "inventory_product_stddev_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_qty?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.product" */
export type Inventory_Product_Stddev_Pop_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Product_Stddev_Samp_Fields = {
  __typename?: "inventory_product_stddev_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_qty?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.product" */
export type Inventory_Product_Stddev_Samp_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.product_stock" */
export type Inventory_Product_Stock = {
  __typename?: "inventory_product_stock";
  /** An object relationship */
  account: Inventory_Account;
  account_id: Scalars["Int"];
  /** An object relationship */
  batch: Inventory_Batch;
  batch_id: Scalars["Int"];
  /** An object relationship */
  client_invoice_line?: Maybe<Inventory_Client_Invoice_Line>;
  id: Scalars["Int"];
  initial_qty: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location: Inventory_Location;
  location_id: Scalars["Int"];
  /** An object relationship */
  product: Inventory_Product;
  product_id: Scalars["Int"];
  /** An object relationship */
  production_order_line?: Maybe<Inventory_Production_Order_Line>;
  qty: Scalars["Int"];
  /** An object relationship */
  shipment_line?: Maybe<Inventory_Shipment_Line>;
  /** An array relationship */
  shipment_lines: Array<Inventory_Shipment_Line>;
  /** An aggregated array relationship */
  shipment_lines_aggregate: Inventory_Shipment_Line_Aggregate;
  source_client_invoice_line_id?: Maybe<Scalars["Int"]>;
  source_production_order_line_id?: Maybe<Scalars["Int"]>;
  source_shipment_line_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  stock_status: Inventory_Stock_Status;
  stock_status_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.product_stock" */
export type Inventory_Product_StockShipment_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.product_stock" */
export type Inventory_Product_StockShipment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** aggregated selection of "inventory.product_stock" */
export type Inventory_Product_Stock_Aggregate = {
  __typename?: "inventory_product_stock_aggregate";
  aggregate?: Maybe<Inventory_Product_Stock_Aggregate_Fields>;
  nodes: Array<Inventory_Product_Stock>;
};

/** aggregate fields of "inventory.product_stock" */
export type Inventory_Product_Stock_Aggregate_Fields = {
  __typename?: "inventory_product_stock_aggregate_fields";
  avg?: Maybe<Inventory_Product_Stock_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Product_Stock_Max_Fields>;
  min?: Maybe<Inventory_Product_Stock_Min_Fields>;
  stddev?: Maybe<Inventory_Product_Stock_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Product_Stock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Product_Stock_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Product_Stock_Sum_Fields>;
  var_pop?: Maybe<Inventory_Product_Stock_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Product_Stock_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Product_Stock_Variance_Fields>;
};

/** aggregate fields of "inventory.product_stock" */
export type Inventory_Product_Stock_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.product_stock" */
export type Inventory_Product_Stock_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Product_Stock_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Product_Stock_Max_Order_By>;
  min?: Maybe<Inventory_Product_Stock_Min_Order_By>;
  stddev?: Maybe<Inventory_Product_Stock_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Product_Stock_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Product_Stock_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Product_Stock_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Product_Stock_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Product_Stock_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Product_Stock_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.product_stock" */
export type Inventory_Product_Stock_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Product_Stock_Insert_Input>;
  on_conflict?: Maybe<Inventory_Product_Stock_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Product_Stock_Avg_Fields = {
  __typename?: "inventory_product_stock_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  batch_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_qty?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Float"]>;
  source_production_order_line_id?: Maybe<Scalars["Float"]>;
  source_shipment_line_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.product_stock". All fields are combined with a logical 'AND'. */
export type Inventory_Product_Stock_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Product_Stock_Bool_Exp>>>;
  _not?: Maybe<Inventory_Product_Stock_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Product_Stock_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  account_id?: Maybe<Int_Comparison_Exp>;
  batch?: Maybe<Inventory_Batch_Bool_Exp>;
  batch_id?: Maybe<Int_Comparison_Exp>;
  client_invoice_line?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  initial_qty?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Inventory_Location_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Inventory_Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  production_order_line?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
  qty?: Maybe<Int_Comparison_Exp>;
  shipment_line?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
  shipment_lines?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
  source_client_invoice_line_id?: Maybe<Int_Comparison_Exp>;
  source_production_order_line_id?: Maybe<Int_Comparison_Exp>;
  source_shipment_line_id?: Maybe<Int_Comparison_Exp>;
  stock_status?: Maybe<Inventory_Stock_Status_Bool_Exp>;
  stock_status_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.product_stock" */
export enum Inventory_Product_Stock_Constraint {
  /** unique or primary key constraint */
  ProductStockPkey = "product_stock_pkey",
}

/** input type for incrementing integer column in table "inventory.product_stock" */
export type Inventory_Product_Stock_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  batch_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_qty?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["Int"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Int"]>;
  source_production_order_line_id?: Maybe<Scalars["Int"]>;
  source_shipment_line_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.product_stock" */
export type Inventory_Product_Stock_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars["Int"]>;
  batch?: Maybe<Inventory_Batch_Obj_Rel_Insert_Input>;
  batch_id?: Maybe<Scalars["Int"]>;
  client_invoice_line?: Maybe<Inventory_Client_Invoice_Line_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  initial_qty?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<Inventory_Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  production_order_line?: Maybe<Inventory_Production_Order_Line_Obj_Rel_Insert_Input>;
  qty?: Maybe<Scalars["Int"]>;
  shipment_line?: Maybe<Inventory_Shipment_Line_Obj_Rel_Insert_Input>;
  shipment_lines?: Maybe<Inventory_Shipment_Line_Arr_Rel_Insert_Input>;
  source_client_invoice_line_id?: Maybe<Scalars["Int"]>;
  source_production_order_line_id?: Maybe<Scalars["Int"]>;
  source_shipment_line_id?: Maybe<Scalars["Int"]>;
  stock_status?: Maybe<Inventory_Stock_Status_Obj_Rel_Insert_Input>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Product_Stock_Max_Fields = {
  __typename?: "inventory_product_stock_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  batch_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_qty?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["Int"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Int"]>;
  source_production_order_line_id?: Maybe<Scalars["Int"]>;
  source_shipment_line_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Product_Stock_Min_Fields = {
  __typename?: "inventory_product_stock_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  batch_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_qty?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["Int"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Int"]>;
  source_production_order_line_id?: Maybe<Scalars["Int"]>;
  source_shipment_line_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.product_stock" */
export type Inventory_Product_Stock_Mutation_Response = {
  __typename?: "inventory_product_stock_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Product_Stock>;
};

/** input type for inserting object relation for remote table "inventory.product_stock" */
export type Inventory_Product_Stock_Obj_Rel_Insert_Input = {
  data: Inventory_Product_Stock_Insert_Input;
  on_conflict?: Maybe<Inventory_Product_Stock_On_Conflict>;
};

/** on conflict condition type for table "inventory.product_stock" */
export type Inventory_Product_Stock_On_Conflict = {
  constraint: Inventory_Product_Stock_Constraint;
  update_columns: Array<Inventory_Product_Stock_Update_Column>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.product_stock" */
export type Inventory_Product_Stock_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  account_id?: Maybe<Order_By>;
  batch?: Maybe<Inventory_Batch_Order_By>;
  batch_id?: Maybe<Order_By>;
  client_invoice_line?: Maybe<Inventory_Client_Invoice_Line_Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Inventory_Location_Order_By>;
  location_id?: Maybe<Order_By>;
  product?: Maybe<Inventory_Product_Order_By>;
  product_id?: Maybe<Order_By>;
  production_order_line?: Maybe<Inventory_Production_Order_Line_Order_By>;
  qty?: Maybe<Order_By>;
  shipment_line?: Maybe<Inventory_Shipment_Line_Order_By>;
  shipment_lines_aggregate?: Maybe<Inventory_Shipment_Line_Aggregate_Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status?: Maybe<Inventory_Stock_Status_Order_By>;
  stock_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview = {
  __typename?: "inventory_product_stock_overview";
  /** An object relationship */
  account?: Maybe<Inventory_Account>;
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  location?: Maybe<Inventory_Location>;
  location_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  product?: Maybe<Inventory_Product>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  /** An object relationship */
  stock_status?: Maybe<Inventory_Stock_Status>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  supplier?: Maybe<Inventory_Account>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Aggregate = {
  __typename?: "inventory_product_stock_overview_aggregate";
  aggregate?: Maybe<Inventory_Product_Stock_Overview_Aggregate_Fields>;
  nodes: Array<Inventory_Product_Stock_Overview>;
};

/** aggregate fields of "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Aggregate_Fields = {
  __typename?: "inventory_product_stock_overview_aggregate_fields";
  avg?: Maybe<Inventory_Product_Stock_Overview_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Product_Stock_Overview_Max_Fields>;
  min?: Maybe<Inventory_Product_Stock_Overview_Min_Fields>;
  stddev?: Maybe<Inventory_Product_Stock_Overview_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Product_Stock_Overview_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Product_Stock_Overview_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Product_Stock_Overview_Sum_Fields>;
  var_pop?: Maybe<Inventory_Product_Stock_Overview_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Product_Stock_Overview_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Product_Stock_Overview_Variance_Fields>;
};

/** aggregate fields of "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Product_Stock_Overview_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Product_Stock_Overview_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Product_Stock_Overview_Max_Order_By>;
  min?: Maybe<Inventory_Product_Stock_Overview_Min_Order_By>;
  stddev?: Maybe<Inventory_Product_Stock_Overview_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Product_Stock_Overview_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Product_Stock_Overview_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Product_Stock_Overview_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Product_Stock_Overview_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Product_Stock_Overview_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Product_Stock_Overview_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Product_Stock_Overview_Insert_Input>;
  on_conflict?: Maybe<Inventory_Product_Stock_Overview_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Product_Stock_Overview_Avg_Fields = {
  __typename?: "inventory_product_stock_overview_avg_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Avg_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.product_stock_overview". All fields are combined with a logical 'AND'. */
export type Inventory_Product_Stock_Overview_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Product_Stock_Overview_Bool_Exp>>>;
  _not?: Maybe<Inventory_Product_Stock_Overview_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Product_Stock_Overview_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  account_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Inventory_Location_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Inventory_Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  qty?: Maybe<Bigint_Comparison_Exp>;
  stock_status?: Maybe<Inventory_Stock_Status_Bool_Exp>;
  stock_status_id?: Maybe<Int_Comparison_Exp>;
  supplier?: Maybe<Inventory_Account_Bool_Exp>;
  supplier_account_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.product_stock_overview" */
export enum Inventory_Product_Stock_Overview_Constraint {
  /** unique or primary key constraint */
  UniqueProductStockOverview = "unique_product_stock_overview",
}

/** input type for incrementing integer column in table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Inc_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<Inventory_Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  stock_status?: Maybe<Inventory_Stock_Status_Obj_Rel_Insert_Input>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  supplier?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Inventory_Product_Stock_Overview_Max_Fields = {
  __typename?: "inventory_product_stock_overview_max_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Product_Stock_Overview_Min_Fields = {
  __typename?: "inventory_product_stock_overview_min_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Mutation_Response = {
  __typename?: "inventory_product_stock_overview_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Product_Stock_Overview>;
};

/** input type for inserting object relation for remote table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Obj_Rel_Insert_Input = {
  data: Inventory_Product_Stock_Overview_Insert_Input;
  on_conflict?: Maybe<Inventory_Product_Stock_Overview_On_Conflict>;
};

/** on conflict condition type for table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_On_Conflict = {
  constraint: Inventory_Product_Stock_Overview_Constraint;
  update_columns: Array<Inventory_Product_Stock_Overview_Update_Column>;
  where?: Maybe<Inventory_Product_Stock_Overview_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Inventory_Location_Order_By>;
  location_id?: Maybe<Order_By>;
  product?: Maybe<Inventory_Product_Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status?: Maybe<Inventory_Stock_Status_Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier?: Maybe<Inventory_Account_Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** select columns of table "inventory.product_stock_overview" */
export enum Inventory_Product_Stock_Overview_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Qty = "qty",
  /** column name */
  StockStatusId = "stock_status_id",
  /** column name */
  SupplierAccountId = "supplier_account_id",
}

/** input type for updating data in table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Inventory_Product_Stock_Overview_Stddev_Fields = {
  __typename?: "inventory_product_stock_overview_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Product_Stock_Overview_Stddev_Pop_Fields = {
  __typename?: "inventory_product_stock_overview_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Product_Stock_Overview_Stddev_Samp_Fields = {
  __typename?: "inventory_product_stock_overview_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Product_Stock_Overview_Sum_Fields = {
  __typename?: "inventory_product_stock_overview_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["bigint"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.product_stock_overview" */
export enum Inventory_Product_Stock_Overview_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Qty = "qty",
  /** column name */
  StockStatusId = "stock_status_id",
  /** column name */
  SupplierAccountId = "supplier_account_id",
}

/** aggregate var_pop on columns */
export type Inventory_Product_Stock_Overview_Var_Pop_Fields = {
  __typename?: "inventory_product_stock_overview_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Product_Stock_Overview_Var_Samp_Fields = {
  __typename?: "inventory_product_stock_overview_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Product_Stock_Overview_Variance_Fields = {
  __typename?: "inventory_product_stock_overview_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.product_stock_overview" */
export type Inventory_Product_Stock_Overview_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.product_stock" */
export type Inventory_Product_Stock_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.product_stock" */
export enum Inventory_Product_Stock_Select_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  BatchId = "batch_id",
  /** column name */
  Id = "id",
  /** column name */
  InitialQty = "initial_qty",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Qty = "qty",
  /** column name */
  SourceClientInvoiceLineId = "source_client_invoice_line_id",
  /** column name */
  SourceProductionOrderLineId = "source_production_order_line_id",
  /** column name */
  SourceShipmentLineId = "source_shipment_line_id",
  /** column name */
  StockStatusId = "stock_status_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.product_stock" */
export type Inventory_Product_Stock_Set_Input = {
  account_id?: Maybe<Scalars["Int"]>;
  batch_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_qty?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["Int"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Int"]>;
  source_production_order_line_id?: Maybe<Scalars["Int"]>;
  source_shipment_line_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Product_Stock_Stddev_Fields = {
  __typename?: "inventory_product_stock_stddev_fields";
  account_id?: Maybe<Scalars["Float"]>;
  batch_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_qty?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Float"]>;
  source_production_order_line_id?: Maybe<Scalars["Float"]>;
  source_shipment_line_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Stddev_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Product_Stock_Stddev_Pop_Fields = {
  __typename?: "inventory_product_stock_stddev_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  batch_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_qty?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Float"]>;
  source_production_order_line_id?: Maybe<Scalars["Float"]>;
  source_shipment_line_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Stddev_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Product_Stock_Stddev_Samp_Fields = {
  __typename?: "inventory_product_stock_stddev_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  batch_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_qty?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Float"]>;
  source_production_order_line_id?: Maybe<Scalars["Float"]>;
  source_shipment_line_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Stddev_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Product_Stock_Sum_Fields = {
  __typename?: "inventory_product_stock_sum_fields";
  account_id?: Maybe<Scalars["Int"]>;
  batch_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_qty?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  qty?: Maybe<Scalars["Int"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Int"]>;
  source_production_order_line_id?: Maybe<Scalars["Int"]>;
  source_shipment_line_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Sum_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.product_stock" */
export enum Inventory_Product_Stock_Update_Column {
  /** column name */
  AccountId = "account_id",
  /** column name */
  BatchId = "batch_id",
  /** column name */
  Id = "id",
  /** column name */
  InitialQty = "initial_qty",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Qty = "qty",
  /** column name */
  SourceClientInvoiceLineId = "source_client_invoice_line_id",
  /** column name */
  SourceProductionOrderLineId = "source_production_order_line_id",
  /** column name */
  SourceShipmentLineId = "source_shipment_line_id",
  /** column name */
  StockStatusId = "stock_status_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Product_Stock_Var_Pop_Fields = {
  __typename?: "inventory_product_stock_var_pop_fields";
  account_id?: Maybe<Scalars["Float"]>;
  batch_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_qty?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Float"]>;
  source_production_order_line_id?: Maybe<Scalars["Float"]>;
  source_shipment_line_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Var_Pop_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Product_Stock_Var_Samp_Fields = {
  __typename?: "inventory_product_stock_var_samp_fields";
  account_id?: Maybe<Scalars["Float"]>;
  batch_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_qty?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Float"]>;
  source_production_order_line_id?: Maybe<Scalars["Float"]>;
  source_shipment_line_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Var_Samp_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Product_Stock_Variance_Fields = {
  __typename?: "inventory_product_stock_variance_fields";
  account_id?: Maybe<Scalars["Float"]>;
  batch_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_qty?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  qty?: Maybe<Scalars["Float"]>;
  source_client_invoice_line_id?: Maybe<Scalars["Float"]>;
  source_production_order_line_id?: Maybe<Scalars["Float"]>;
  source_shipment_line_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.product_stock" */
export type Inventory_Product_Stock_Variance_Order_By = {
  account_id?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_qty?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  qty?: Maybe<Order_By>;
  source_client_invoice_line_id?: Maybe<Order_By>;
  source_production_order_line_id?: Maybe<Order_By>;
  source_shipment_line_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Product_Sum_Fields = {
  __typename?: "inventory_product_sum_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  case_qty?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.product" */
export type Inventory_Product_Sum_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** update columns of table "inventory.product" */
export enum Inventory_Product_Update_Column {
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  CaseQty = "case_qty",
  /** column name */
  HeightMm = "height_mm",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LengthMm = "length_mm",
  /** column name */
  UserId = "user_id",
  /** column name */
  WeightGrams = "weight_grams",
  /** column name */
  WidthMm = "width_mm",
}

/** aggregate var_pop on columns */
export type Inventory_Product_Var_Pop_Fields = {
  __typename?: "inventory_product_var_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_qty?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.product" */
export type Inventory_Product_Var_Pop_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Product_Var_Samp_Fields = {
  __typename?: "inventory_product_var_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_qty?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.product" */
export type Inventory_Product_Var_Samp_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Product_Variance_Fields = {
  __typename?: "inventory_product_variance_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_qty?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.product" */
export type Inventory_Product_Variance_Order_By = {
  base_product_id?: Maybe<Order_By>;
  case_qty?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.production_order" */
export type Inventory_Production_Order = {
  __typename?: "inventory_production_order";
  /** An object relationship */
  account: Inventory_Account;
  /** An object relationship */
  folder?: Maybe<Inventory_Folder>;
  folder_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location: Inventory_Location;
  location_id: Scalars["Int"];
  order_created?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  production_order_lines: Array<Inventory_Production_Order_Line>;
  /** An aggregated array relationship */
  production_order_lines_aggregate: Inventory_Production_Order_Line_Aggregate;
  production_order_reference: Scalars["String"];
  /** An object relationship */
  production_order_status: Inventory_Production_Order_Status;
  production_order_status_id: Scalars["Int"];
  supplier_account_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.production_order" */
export type Inventory_Production_OrderProduction_Order_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.production_order" */
export type Inventory_Production_OrderProduction_Order_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** aggregated selection of "inventory.production_order" */
export type Inventory_Production_Order_Aggregate = {
  __typename?: "inventory_production_order_aggregate";
  aggregate?: Maybe<Inventory_Production_Order_Aggregate_Fields>;
  nodes: Array<Inventory_Production_Order>;
};

/** aggregate fields of "inventory.production_order" */
export type Inventory_Production_Order_Aggregate_Fields = {
  __typename?: "inventory_production_order_aggregate_fields";
  avg?: Maybe<Inventory_Production_Order_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Production_Order_Max_Fields>;
  min?: Maybe<Inventory_Production_Order_Min_Fields>;
  stddev?: Maybe<Inventory_Production_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Production_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Production_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Production_Order_Sum_Fields>;
  var_pop?: Maybe<Inventory_Production_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Production_Order_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Production_Order_Variance_Fields>;
};

/** aggregate fields of "inventory.production_order" */
export type Inventory_Production_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.production_order" */
export type Inventory_Production_Order_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Production_Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Production_Order_Max_Order_By>;
  min?: Maybe<Inventory_Production_Order_Min_Order_By>;
  stddev?: Maybe<Inventory_Production_Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Production_Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Production_Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Production_Order_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Production_Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Production_Order_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Production_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.production_order" */
export type Inventory_Production_Order_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Production_Order_Insert_Input>;
  on_conflict?: Maybe<Inventory_Production_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Production_Order_Avg_Fields = {
  __typename?: "inventory_production_order_avg_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  production_order_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Avg_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.production_order". All fields are combined with a logical 'AND'. */
export type Inventory_Production_Order_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Production_Order_Bool_Exp>>>;
  _not?: Maybe<Inventory_Production_Order_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Production_Order_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  folder?: Maybe<Inventory_Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Inventory_Location_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  order_created?: Maybe<Timestamptz_Comparison_Exp>;
  production_order_lines?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
  production_order_reference?: Maybe<String_Comparison_Exp>;
  production_order_status?: Maybe<Inventory_Production_Order_Status_Bool_Exp>;
  production_order_status_id?: Maybe<Int_Comparison_Exp>;
  supplier_account_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.production_order" */
export enum Inventory_Production_Order_Constraint {
  /** unique or primary key constraint */
  ProductionOrderPkey = "production_order_pkey",
}

/** input type for incrementing integer column in table "inventory.production_order" */
export type Inventory_Production_Order_Inc_Input = {
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  production_order_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.production_order" */
export type Inventory_Production_Order_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  folder?: Maybe<Inventory_Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  order_created?: Maybe<Scalars["timestamptz"]>;
  production_order_lines?: Maybe<Inventory_Production_Order_Line_Arr_Rel_Insert_Input>;
  production_order_reference?: Maybe<Scalars["String"]>;
  production_order_status?: Maybe<Inventory_Production_Order_Status_Obj_Rel_Insert_Input>;
  production_order_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.production_order_line" */
export type Inventory_Production_Order_Line = {
  __typename?: "inventory_production_order_line";
  cancelled_qty: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  production_order: Inventory_Production_Order;
  production_order_id: Scalars["Int"];
  /** An object relationship */
  production_order_line_status: Inventory_Production_Order_Line_Status;
  production_order_line_status_id: Scalars["Int"];
  received_qty: Scalars["Int"];
  requested_qty: Scalars["Int"];
  /** An object relationship */
  supplier_quote: Inventory_Supplier_Quote;
  supplier_quote_id: Scalars["Int"];
  unit_cost: Scalars["numeric"];
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Aggregate = {
  __typename?: "inventory_production_order_line_aggregate";
  aggregate?: Maybe<Inventory_Production_Order_Line_Aggregate_Fields>;
  nodes: Array<Inventory_Production_Order_Line>;
};

/** aggregate fields of "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Aggregate_Fields = {
  __typename?: "inventory_production_order_line_aggregate_fields";
  avg?: Maybe<Inventory_Production_Order_Line_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Production_Order_Line_Max_Fields>;
  min?: Maybe<Inventory_Production_Order_Line_Min_Fields>;
  stddev?: Maybe<Inventory_Production_Order_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Production_Order_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Production_Order_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Production_Order_Line_Sum_Fields>;
  var_pop?: Maybe<Inventory_Production_Order_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Production_Order_Line_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Production_Order_Line_Variance_Fields>;
};

/** aggregate fields of "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Production_Order_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Production_Order_Line_Max_Order_By>;
  min?: Maybe<Inventory_Production_Order_Line_Min_Order_By>;
  stddev?: Maybe<Inventory_Production_Order_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Production_Order_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Production_Order_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Production_Order_Line_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Production_Order_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Production_Order_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Production_Order_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Production_Order_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Production_Order_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Production_Order_Line_Avg_Fields = {
  __typename?: "inventory_production_order_line_avg_fields";
  cancelled_qty?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  production_order_id?: Maybe<Scalars["Float"]>;
  production_order_line_status_id?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  supplier_quote_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Avg_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.production_order_line". All fields are combined with a logical 'AND'. */
export type Inventory_Production_Order_Line_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Production_Order_Line_Bool_Exp>>>;
  _not?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Production_Order_Line_Bool_Exp>>>;
  cancelled_qty?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  production_order?: Maybe<Inventory_Production_Order_Bool_Exp>;
  production_order_id?: Maybe<Int_Comparison_Exp>;
  production_order_line_status?: Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>;
  production_order_line_status_id?: Maybe<Int_Comparison_Exp>;
  received_qty?: Maybe<Int_Comparison_Exp>;
  requested_qty?: Maybe<Int_Comparison_Exp>;
  supplier_quote?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
  supplier_quote_id?: Maybe<Int_Comparison_Exp>;
  unit_cost?: Maybe<Numeric_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.production_order_line" */
export enum Inventory_Production_Order_Line_Constraint {
  /** unique or primary key constraint */
  ProductionOrderLinePkey = "production_order_line_pkey",
}

/** input type for incrementing integer column in table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Inc_Input = {
  cancelled_qty?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  production_order_id?: Maybe<Scalars["Int"]>;
  production_order_line_status_id?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  supplier_quote_id?: Maybe<Scalars["Int"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Insert_Input = {
  cancelled_qty?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order?: Maybe<Inventory_Production_Order_Obj_Rel_Insert_Input>;
  production_order_id?: Maybe<Scalars["Int"]>;
  production_order_line_status?: Maybe<Inventory_Production_Order_Line_Status_Obj_Rel_Insert_Input>;
  production_order_line_status_id?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  supplier_quote?: Maybe<Inventory_Supplier_Quote_Obj_Rel_Insert_Input>;
  supplier_quote_id?: Maybe<Scalars["Int"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Production_Order_Line_Max_Fields = {
  __typename?: "inventory_production_order_line_max_fields";
  cancelled_qty?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_id?: Maybe<Scalars["Int"]>;
  production_order_line_status_id?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  supplier_quote_id?: Maybe<Scalars["Int"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Max_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Production_Order_Line_Min_Fields = {
  __typename?: "inventory_production_order_line_min_fields";
  cancelled_qty?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_id?: Maybe<Scalars["Int"]>;
  production_order_line_status_id?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  supplier_quote_id?: Maybe<Scalars["Int"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Min_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Mutation_Response = {
  __typename?: "inventory_production_order_line_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Production_Order_Line>;
};

/** input type for inserting object relation for remote table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Obj_Rel_Insert_Input = {
  data: Inventory_Production_Order_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Production_Order_Line_On_Conflict>;
};

/** on conflict condition type for table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_On_Conflict = {
  constraint: Inventory_Production_Order_Line_Constraint;
  update_columns: Array<Inventory_Production_Order_Line_Update_Column>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order?: Maybe<Inventory_Production_Order_Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status?: Maybe<Inventory_Production_Order_Line_Status_Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote?: Maybe<Inventory_Supplier_Quote_Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.production_order_line" */
export enum Inventory_Production_Order_Line_Select_Column {
  /** column name */
  CancelledQty = "cancelled_qty",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductionOrderId = "production_order_id",
  /** column name */
  ProductionOrderLineStatusId = "production_order_line_status_id",
  /** column name */
  ReceivedQty = "received_qty",
  /** column name */
  RequestedQty = "requested_qty",
  /** column name */
  SupplierQuoteId = "supplier_quote_id",
  /** column name */
  UnitCost = "unit_cost",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Set_Input = {
  cancelled_qty?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_id?: Maybe<Scalars["Int"]>;
  production_order_line_status_id?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  supplier_quote_id?: Maybe<Scalars["Int"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status = {
  __typename?: "inventory_production_order_line_status";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  production_order_line_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Aggregate = {
  __typename?: "inventory_production_order_line_status_aggregate";
  aggregate?: Maybe<Inventory_Production_Order_Line_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Production_Order_Line_Status>;
};

/** aggregate fields of "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Aggregate_Fields = {
  __typename?: "inventory_production_order_line_status_aggregate_fields";
  avg?: Maybe<Inventory_Production_Order_Line_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Production_Order_Line_Status_Max_Fields>;
  min?: Maybe<Inventory_Production_Order_Line_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Production_Order_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Production_Order_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Production_Order_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Production_Order_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Production_Order_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Production_Order_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Production_Order_Line_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Production_Order_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Production_Order_Line_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Production_Order_Line_Status_Max_Order_By>;
  min?: Maybe<Inventory_Production_Order_Line_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Production_Order_Line_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Production_Order_Line_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Production_Order_Line_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Production_Order_Line_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Production_Order_Line_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Production_Order_Line_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Production_Order_Line_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Production_Order_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Production_Order_Line_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Production_Order_Line_Status_Avg_Fields = {
  __typename?: "inventory_production_order_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.production_order_line_status". All fields are combined with a logical 'AND'. */
export type Inventory_Production_Order_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  production_order_line_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.production_order_line_status" */
export enum Inventory_Production_Order_Line_Status_Constraint {
  /** unique or primary key constraint */
  ProductionOrderLineStatusPkey = "production_order_line_status_pkey",
}

/** input type for incrementing integer column in table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Production_Order_Line_Status_Max_Fields = {
  __typename?: "inventory_production_order_line_status_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_line_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_line_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Production_Order_Line_Status_Min_Fields = {
  __typename?: "inventory_production_order_line_status_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_line_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_line_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Mutation_Response = {
  __typename?: "inventory_production_order_line_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Production_Order_Line_Status>;
};

/** input type for inserting object relation for remote table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Production_Order_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Production_Order_Line_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_On_Conflict = {
  constraint: Inventory_Production_Order_Line_Status_Constraint;
  update_columns: Array<Inventory_Production_Order_Line_Status_Update_Column>;
  where?: Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_line_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.production_order_line_status" */
export enum Inventory_Production_Order_Line_Status_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductionOrderLineStatusName = "production_order_line_status_name",
}

/** input type for updating data in table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Production_Order_Line_Status_Stddev_Fields = {
  __typename?: "inventory_production_order_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Production_Order_Line_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_production_order_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Production_Order_Line_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_production_order_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Production_Order_Line_Status_Sum_Fields = {
  __typename?: "inventory_production_order_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.production_order_line_status" */
export enum Inventory_Production_Order_Line_Status_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductionOrderLineStatusName = "production_order_line_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Production_Order_Line_Status_Var_Pop_Fields = {
  __typename?: "inventory_production_order_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Production_Order_Line_Status_Var_Samp_Fields = {
  __typename?: "inventory_production_order_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Production_Order_Line_Status_Variance_Fields = {
  __typename?: "inventory_production_order_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.production_order_line_status" */
export type Inventory_Production_Order_Line_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Production_Order_Line_Stddev_Fields = {
  __typename?: "inventory_production_order_line_stddev_fields";
  cancelled_qty?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  production_order_id?: Maybe<Scalars["Float"]>;
  production_order_line_status_id?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  supplier_quote_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Stddev_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Production_Order_Line_Stddev_Pop_Fields = {
  __typename?: "inventory_production_order_line_stddev_pop_fields";
  cancelled_qty?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  production_order_id?: Maybe<Scalars["Float"]>;
  production_order_line_status_id?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  supplier_quote_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Stddev_Pop_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Production_Order_Line_Stddev_Samp_Fields = {
  __typename?: "inventory_production_order_line_stddev_samp_fields";
  cancelled_qty?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  production_order_id?: Maybe<Scalars["Float"]>;
  production_order_line_status_id?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  supplier_quote_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Stddev_Samp_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Production_Order_Line_Sum_Fields = {
  __typename?: "inventory_production_order_line_sum_fields";
  cancelled_qty?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  production_order_id?: Maybe<Scalars["Int"]>;
  production_order_line_status_id?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  requested_qty?: Maybe<Scalars["Int"]>;
  supplier_quote_id?: Maybe<Scalars["Int"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Sum_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** update columns of table "inventory.production_order_line" */
export enum Inventory_Production_Order_Line_Update_Column {
  /** column name */
  CancelledQty = "cancelled_qty",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductionOrderId = "production_order_id",
  /** column name */
  ProductionOrderLineStatusId = "production_order_line_status_id",
  /** column name */
  ReceivedQty = "received_qty",
  /** column name */
  RequestedQty = "requested_qty",
  /** column name */
  SupplierQuoteId = "supplier_quote_id",
  /** column name */
  UnitCost = "unit_cost",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Production_Order_Line_Var_Pop_Fields = {
  __typename?: "inventory_production_order_line_var_pop_fields";
  cancelled_qty?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  production_order_id?: Maybe<Scalars["Float"]>;
  production_order_line_status_id?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  supplier_quote_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Var_Pop_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Production_Order_Line_Var_Samp_Fields = {
  __typename?: "inventory_production_order_line_var_samp_fields";
  cancelled_qty?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  production_order_id?: Maybe<Scalars["Float"]>;
  production_order_line_status_id?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  supplier_quote_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Var_Samp_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Production_Order_Line_Variance_Fields = {
  __typename?: "inventory_production_order_line_variance_fields";
  cancelled_qty?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  production_order_id?: Maybe<Scalars["Float"]>;
  production_order_line_status_id?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  requested_qty?: Maybe<Scalars["Float"]>;
  supplier_quote_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.production_order_line" */
export type Inventory_Production_Order_Line_Variance_Order_By = {
  cancelled_qty?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  production_order_id?: Maybe<Order_By>;
  production_order_line_status_id?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  requested_qty?: Maybe<Order_By>;
  supplier_quote_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Inventory_Production_Order_Max_Fields = {
  __typename?: "inventory_production_order_max_fields";
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  order_created?: Maybe<Scalars["timestamptz"]>;
  production_order_reference?: Maybe<Scalars["String"]>;
  production_order_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Max_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_created?: Maybe<Order_By>;
  production_order_reference?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Production_Order_Min_Fields = {
  __typename?: "inventory_production_order_min_fields";
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  order_created?: Maybe<Scalars["timestamptz"]>;
  production_order_reference?: Maybe<Scalars["String"]>;
  production_order_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Min_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  order_created?: Maybe<Order_By>;
  production_order_reference?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.production_order" */
export type Inventory_Production_Order_Mutation_Response = {
  __typename?: "inventory_production_order_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Production_Order>;
};

/** input type for inserting object relation for remote table "inventory.production_order" */
export type Inventory_Production_Order_Obj_Rel_Insert_Input = {
  data: Inventory_Production_Order_Insert_Input;
  on_conflict?: Maybe<Inventory_Production_Order_On_Conflict>;
};

/** on conflict condition type for table "inventory.production_order" */
export type Inventory_Production_Order_On_Conflict = {
  constraint: Inventory_Production_Order_Constraint;
  update_columns: Array<Inventory_Production_Order_Update_Column>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.production_order" */
export type Inventory_Production_Order_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  folder?: Maybe<Inventory_Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Inventory_Location_Order_By>;
  location_id?: Maybe<Order_By>;
  order_created?: Maybe<Order_By>;
  production_order_lines_aggregate?: Maybe<Inventory_Production_Order_Line_Aggregate_Order_By>;
  production_order_reference?: Maybe<Order_By>;
  production_order_status?: Maybe<Inventory_Production_Order_Status_Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.production_order" */
export type Inventory_Production_Order_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.production_order" */
export enum Inventory_Production_Order_Select_Column {
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  OrderCreated = "order_created",
  /** column name */
  ProductionOrderReference = "production_order_reference",
  /** column name */
  ProductionOrderStatusId = "production_order_status_id",
  /** column name */
  SupplierAccountId = "supplier_account_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.production_order" */
export type Inventory_Production_Order_Set_Input = {
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  order_created?: Maybe<Scalars["timestamptz"]>;
  production_order_reference?: Maybe<Scalars["String"]>;
  production_order_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.production_order_status" */
export type Inventory_Production_Order_Status = {
  __typename?: "inventory_production_order_status";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  production_order_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Aggregate = {
  __typename?: "inventory_production_order_status_aggregate";
  aggregate?: Maybe<Inventory_Production_Order_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Production_Order_Status>;
};

/** aggregate fields of "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Aggregate_Fields = {
  __typename?: "inventory_production_order_status_aggregate_fields";
  avg?: Maybe<Inventory_Production_Order_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Production_Order_Status_Max_Fields>;
  min?: Maybe<Inventory_Production_Order_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Production_Order_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Production_Order_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Production_Order_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Production_Order_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Production_Order_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Production_Order_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Production_Order_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Production_Order_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Production_Order_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Production_Order_Status_Max_Order_By>;
  min?: Maybe<Inventory_Production_Order_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Production_Order_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Production_Order_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Production_Order_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Production_Order_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Production_Order_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Production_Order_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Production_Order_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Production_Order_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Production_Order_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Production_Order_Status_Avg_Fields = {
  __typename?: "inventory_production_order_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.production_order_status". All fields are combined with a logical 'AND'. */
export type Inventory_Production_Order_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Production_Order_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Production_Order_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Production_Order_Status_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  production_order_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.production_order_status" */
export enum Inventory_Production_Order_Status_Constraint {
  /** unique or primary key constraint */
  ProductionOrderStatusPkey = "production_order_status_pkey",
  /** unique or primary key constraint */
  ProductionOrderStatusProductionOrderStatusNameKey = "production_order_status_production_order_status_name_key",
}

/** input type for incrementing integer column in table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Production_Order_Status_Max_Fields = {
  __typename?: "inventory_production_order_status_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Production_Order_Status_Min_Fields = {
  __typename?: "inventory_production_order_status_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Mutation_Response = {
  __typename?: "inventory_production_order_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Production_Order_Status>;
};

/** input type for inserting object relation for remote table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Production_Order_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Production_Order_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_On_Conflict = {
  constraint: Inventory_Production_Order_Status_Constraint;
  update_columns: Array<Inventory_Production_Order_Status_Update_Column>;
  where?: Maybe<Inventory_Production_Order_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.production_order_status" */
export enum Inventory_Production_Order_Status_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductionOrderStatusName = "production_order_status_name",
}

/** input type for updating data in table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Production_Order_Status_Stddev_Fields = {
  __typename?: "inventory_production_order_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Production_Order_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_production_order_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Production_Order_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_production_order_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Production_Order_Status_Sum_Fields = {
  __typename?: "inventory_production_order_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.production_order_status" */
export enum Inventory_Production_Order_Status_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductionOrderStatusName = "production_order_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Production_Order_Status_Var_Pop_Fields = {
  __typename?: "inventory_production_order_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Production_Order_Status_Var_Samp_Fields = {
  __typename?: "inventory_production_order_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Production_Order_Status_Variance_Fields = {
  __typename?: "inventory_production_order_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.production_order_status" */
export type Inventory_Production_Order_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Production_Order_Stddev_Fields = {
  __typename?: "inventory_production_order_stddev_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  production_order_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Stddev_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Production_Order_Stddev_Pop_Fields = {
  __typename?: "inventory_production_order_stddev_pop_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  production_order_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Stddev_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Production_Order_Stddev_Samp_Fields = {
  __typename?: "inventory_production_order_stddev_samp_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  production_order_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Stddev_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Production_Order_Sum_Fields = {
  __typename?: "inventory_production_order_sum_fields";
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  production_order_status_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Sum_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.production_order" */
export enum Inventory_Production_Order_Update_Column {
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  OrderCreated = "order_created",
  /** column name */
  ProductionOrderReference = "production_order_reference",
  /** column name */
  ProductionOrderStatusId = "production_order_status_id",
  /** column name */
  SupplierAccountId = "supplier_account_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Production_Order_Var_Pop_Fields = {
  __typename?: "inventory_production_order_var_pop_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  production_order_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Var_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Production_Order_Var_Samp_Fields = {
  __typename?: "inventory_production_order_var_samp_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  production_order_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Var_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Production_Order_Variance_Fields = {
  __typename?: "inventory_production_order_variance_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  production_order_status_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.production_order" */
export type Inventory_Production_Order_Variance_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  production_order_status_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.role" */
export type Inventory_Role = {
  __typename?: "inventory_role";
  /** An array relationship */
  account_roles: Array<Inventory_Account_Role>;
  /** An aggregated array relationship */
  account_roles_aggregate: Inventory_Account_Role_Aggregate;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  role_description: Scalars["String"];
  role_name: Scalars["String"];
};

/** columns and relationships of "inventory.role" */
export type Inventory_RoleAccount_RolesArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Role_Order_By>>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** columns and relationships of "inventory.role" */
export type Inventory_RoleAccount_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Role_Order_By>>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** aggregated selection of "inventory.role" */
export type Inventory_Role_Aggregate = {
  __typename?: "inventory_role_aggregate";
  aggregate?: Maybe<Inventory_Role_Aggregate_Fields>;
  nodes: Array<Inventory_Role>;
};

/** aggregate fields of "inventory.role" */
export type Inventory_Role_Aggregate_Fields = {
  __typename?: "inventory_role_aggregate_fields";
  avg?: Maybe<Inventory_Role_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Role_Max_Fields>;
  min?: Maybe<Inventory_Role_Min_Fields>;
  stddev?: Maybe<Inventory_Role_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Role_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Role_Sum_Fields>;
  var_pop?: Maybe<Inventory_Role_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Role_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Role_Variance_Fields>;
};

/** aggregate fields of "inventory.role" */
export type Inventory_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Role_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.role" */
export type Inventory_Role_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Role_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Role_Max_Order_By>;
  min?: Maybe<Inventory_Role_Min_Order_By>;
  stddev?: Maybe<Inventory_Role_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Role_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Role_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Role_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Role_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Role_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.role" */
export type Inventory_Role_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Role_Insert_Input>;
  on_conflict?: Maybe<Inventory_Role_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Role_Avg_Fields = {
  __typename?: "inventory_role_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.role" */
export type Inventory_Role_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.role". All fields are combined with a logical 'AND'. */
export type Inventory_Role_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Role_Bool_Exp>>>;
  _not?: Maybe<Inventory_Role_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Role_Bool_Exp>>>;
  account_roles?: Maybe<Inventory_Account_Role_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  role_description?: Maybe<String_Comparison_Exp>;
  role_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.role" */
export enum Inventory_Role_Constraint {
  /** unique or primary key constraint */
  RolePkey = "role_pkey",
  /** unique or primary key constraint */
  RoleRoleNameKey = "role_role_name_key",
}

/** input type for incrementing integer column in table "inventory.role" */
export type Inventory_Role_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.role" */
export type Inventory_Role_Insert_Input = {
  account_roles?: Maybe<Inventory_Account_Role_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  role_description?: Maybe<Scalars["String"]>;
  role_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Role_Max_Fields = {
  __typename?: "inventory_role_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  role_description?: Maybe<Scalars["String"]>;
  role_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.role" */
export type Inventory_Role_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  role_description?: Maybe<Order_By>;
  role_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Role_Min_Fields = {
  __typename?: "inventory_role_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  role_description?: Maybe<Scalars["String"]>;
  role_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.role" */
export type Inventory_Role_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  role_description?: Maybe<Order_By>;
  role_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.role" */
export type Inventory_Role_Mutation_Response = {
  __typename?: "inventory_role_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Role>;
};

/** input type for inserting object relation for remote table "inventory.role" */
export type Inventory_Role_Obj_Rel_Insert_Input = {
  data: Inventory_Role_Insert_Input;
  on_conflict?: Maybe<Inventory_Role_On_Conflict>;
};

/** on conflict condition type for table "inventory.role" */
export type Inventory_Role_On_Conflict = {
  constraint: Inventory_Role_Constraint;
  update_columns: Array<Inventory_Role_Update_Column>;
  where?: Maybe<Inventory_Role_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.role" */
export type Inventory_Role_Order_By = {
  account_roles_aggregate?: Maybe<Inventory_Account_Role_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  role_description?: Maybe<Order_By>;
  role_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.role" */
export type Inventory_Role_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.role" */
export enum Inventory_Role_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  RoleDescription = "role_description",
  /** column name */
  RoleName = "role_name",
}

/** input type for updating data in table "inventory.role" */
export type Inventory_Role_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  role_description?: Maybe<Scalars["String"]>;
  role_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Role_Stddev_Fields = {
  __typename?: "inventory_role_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.role" */
export type Inventory_Role_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Role_Stddev_Pop_Fields = {
  __typename?: "inventory_role_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.role" */
export type Inventory_Role_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Role_Stddev_Samp_Fields = {
  __typename?: "inventory_role_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.role" */
export type Inventory_Role_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Role_Sum_Fields = {
  __typename?: "inventory_role_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.role" */
export type Inventory_Role_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.role" */
export enum Inventory_Role_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  RoleDescription = "role_description",
  /** column name */
  RoleName = "role_name",
}

/** aggregate var_pop on columns */
export type Inventory_Role_Var_Pop_Fields = {
  __typename?: "inventory_role_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.role" */
export type Inventory_Role_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Role_Var_Samp_Fields = {
  __typename?: "inventory_role_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.role" */
export type Inventory_Role_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Role_Variance_Fields = {
  __typename?: "inventory_role_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.role" */
export type Inventory_Role_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.shipment" */
export type Inventory_Shipment = {
  __typename?: "inventory_shipment";
  arrival_time?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  courier_service: Inventory_Courier_Service;
  courier_service_id: Scalars["Int"];
  created_time: Scalars["timestamptz"];
  /** An object relationship */
  destination: Inventory_Location;
  destination_location_id: Scalars["Int"];
  estimated_arrival_days: Scalars["Int"];
  estimated_shipped_time?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  shipment_lines: Array<Inventory_Shipment_Line>;
  /** An aggregated array relationship */
  shipment_lines_aggregate: Inventory_Shipment_Line_Aggregate;
  /** An object relationship */
  shipment_status: Inventory_Shipment_Status;
  shipment_status_id: Scalars["Int"];
  shipped_time?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  source: Inventory_Location;
  source_location_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.shipment" */
export type Inventory_ShipmentShipment_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.shipment" */
export type Inventory_ShipmentShipment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** aggregated selection of "inventory.shipment" */
export type Inventory_Shipment_Aggregate = {
  __typename?: "inventory_shipment_aggregate";
  aggregate?: Maybe<Inventory_Shipment_Aggregate_Fields>;
  nodes: Array<Inventory_Shipment>;
};

/** aggregate fields of "inventory.shipment" */
export type Inventory_Shipment_Aggregate_Fields = {
  __typename?: "inventory_shipment_aggregate_fields";
  avg?: Maybe<Inventory_Shipment_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Shipment_Max_Fields>;
  min?: Maybe<Inventory_Shipment_Min_Fields>;
  stddev?: Maybe<Inventory_Shipment_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Shipment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Shipment_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Shipment_Sum_Fields>;
  var_pop?: Maybe<Inventory_Shipment_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Shipment_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Shipment_Variance_Fields>;
};

/** aggregate fields of "inventory.shipment" */
export type Inventory_Shipment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.shipment" */
export type Inventory_Shipment_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Shipment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Shipment_Max_Order_By>;
  min?: Maybe<Inventory_Shipment_Min_Order_By>;
  stddev?: Maybe<Inventory_Shipment_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Shipment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Shipment_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Shipment_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Shipment_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Shipment_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Shipment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.shipment" */
export type Inventory_Shipment_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Shipment_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Shipment_Avg_Fields = {
  __typename?: "inventory_shipment_avg_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  estimated_arrival_days?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  shipment_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Avg_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.shipment". All fields are combined with a logical 'AND'. */
export type Inventory_Shipment_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Shipment_Bool_Exp>>>;
  _not?: Maybe<Inventory_Shipment_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Shipment_Bool_Exp>>>;
  arrival_time?: Maybe<Timestamptz_Comparison_Exp>;
  courier_service?: Maybe<Inventory_Courier_Service_Bool_Exp>;
  courier_service_id?: Maybe<Int_Comparison_Exp>;
  created_time?: Maybe<Timestamptz_Comparison_Exp>;
  destination?: Maybe<Inventory_Location_Bool_Exp>;
  destination_location_id?: Maybe<Int_Comparison_Exp>;
  estimated_arrival_days?: Maybe<Int_Comparison_Exp>;
  estimated_shipped_time?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  shipment_lines?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
  shipment_status?: Maybe<Inventory_Shipment_Status_Bool_Exp>;
  shipment_status_id?: Maybe<Int_Comparison_Exp>;
  shipped_time?: Maybe<Timestamptz_Comparison_Exp>;
  source?: Maybe<Inventory_Location_Bool_Exp>;
  source_location_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.shipment" */
export enum Inventory_Shipment_Constraint {
  /** unique or primary key constraint */
  ShipmentPkey = "shipment_pkey",
}

/** input type for incrementing integer column in table "inventory.shipment" */
export type Inventory_Shipment_Inc_Input = {
  courier_service_id?: Maybe<Scalars["Int"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  estimated_arrival_days?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  shipment_status_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.shipment" */
export type Inventory_Shipment_Insert_Input = {
  arrival_time?: Maybe<Scalars["timestamptz"]>;
  courier_service?: Maybe<Inventory_Courier_Service_Obj_Rel_Insert_Input>;
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  destination?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  estimated_arrival_days?: Maybe<Scalars["Int"]>;
  estimated_shipped_time?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_lines?: Maybe<Inventory_Shipment_Line_Arr_Rel_Insert_Input>;
  shipment_status?: Maybe<Inventory_Shipment_Status_Obj_Rel_Insert_Input>;
  shipment_status_id?: Maybe<Scalars["Int"]>;
  shipped_time?: Maybe<Scalars["timestamptz"]>;
  source?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  source_location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice = {
  __typename?: "inventory_shipment_invoice";
  created_time: Scalars["timestamptz"];
  /** An object relationship */
  currency: Inventory_Currency;
  currency_code: Scalars["String"];
  id: Scalars["Int"];
  invoice_cost: Scalars["numeric"];
  invoice_reference: Scalars["String"];
  invoice_type: Scalars["String"];
  last_updated: Scalars["timestamptz"];
  shipment_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Aggregate = {
  __typename?: "inventory_shipment_invoice_aggregate";
  aggregate?: Maybe<Inventory_Shipment_Invoice_Aggregate_Fields>;
  nodes: Array<Inventory_Shipment_Invoice>;
};

/** aggregate fields of "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Aggregate_Fields = {
  __typename?: "inventory_shipment_invoice_aggregate_fields";
  avg?: Maybe<Inventory_Shipment_Invoice_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Shipment_Invoice_Max_Fields>;
  min?: Maybe<Inventory_Shipment_Invoice_Min_Fields>;
  stddev?: Maybe<Inventory_Shipment_Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Shipment_Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Shipment_Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Shipment_Invoice_Sum_Fields>;
  var_pop?: Maybe<Inventory_Shipment_Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Shipment_Invoice_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Shipment_Invoice_Variance_Fields>;
};

/** aggregate fields of "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Shipment_Invoice_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Shipment_Invoice_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Shipment_Invoice_Max_Order_By>;
  min?: Maybe<Inventory_Shipment_Invoice_Min_Order_By>;
  stddev?: Maybe<Inventory_Shipment_Invoice_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Shipment_Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Shipment_Invoice_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Shipment_Invoice_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Shipment_Invoice_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Shipment_Invoice_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Shipment_Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Shipment_Invoice_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Shipment_Invoice_Avg_Fields = {
  __typename?: "inventory_shipment_invoice_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_cost?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Avg_Order_By = {
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.shipment_invoice". All fields are combined with a logical 'AND'. */
export type Inventory_Shipment_Invoice_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Shipment_Invoice_Bool_Exp>>>;
  _not?: Maybe<Inventory_Shipment_Invoice_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Shipment_Invoice_Bool_Exp>>>;
  created_time?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<Inventory_Currency_Bool_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_cost?: Maybe<Numeric_Comparison_Exp>;
  invoice_reference?: Maybe<String_Comparison_Exp>;
  invoice_type?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  shipment_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.shipment_invoice" */
export enum Inventory_Shipment_Invoice_Constraint {
  /** unique or primary key constraint */
  ShipmentInvoicePkey = "shipment_invoice_pkey",
}

/** input type for incrementing integer column in table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  invoice_cost?: Maybe<Scalars["numeric"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Insert_Input = {
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency?: Maybe<Inventory_Currency_Obj_Rel_Insert_Input>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_cost?: Maybe<Scalars["numeric"]>;
  invoice_reference?: Maybe<Scalars["String"]>;
  invoice_type?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Shipment_Invoice_Max_Fields = {
  __typename?: "inventory_shipment_invoice_max_fields";
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_cost?: Maybe<Scalars["numeric"]>;
  invoice_reference?: Maybe<Scalars["String"]>;
  invoice_type?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Max_Order_By = {
  created_time?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  invoice_reference?: Maybe<Order_By>;
  invoice_type?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Shipment_Invoice_Min_Fields = {
  __typename?: "inventory_shipment_invoice_min_fields";
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_cost?: Maybe<Scalars["numeric"]>;
  invoice_reference?: Maybe<Scalars["String"]>;
  invoice_type?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Min_Order_By = {
  created_time?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  invoice_reference?: Maybe<Order_By>;
  invoice_type?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Mutation_Response = {
  __typename?: "inventory_shipment_invoice_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Shipment_Invoice>;
};

/** input type for inserting object relation for remote table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Obj_Rel_Insert_Input = {
  data: Inventory_Shipment_Invoice_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_Invoice_On_Conflict>;
};

/** on conflict condition type for table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_On_Conflict = {
  constraint: Inventory_Shipment_Invoice_Constraint;
  update_columns: Array<Inventory_Shipment_Invoice_Update_Column>;
  where?: Maybe<Inventory_Shipment_Invoice_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Order_By = {
  created_time?: Maybe<Order_By>;
  currency?: Maybe<Inventory_Currency_Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  invoice_reference?: Maybe<Order_By>;
  invoice_type?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.shipment_invoice" */
export enum Inventory_Shipment_Invoice_Select_Column {
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceCost = "invoice_cost",
  /** column name */
  InvoiceReference = "invoice_reference",
  /** column name */
  InvoiceType = "invoice_type",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ShipmentId = "shipment_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Set_Input = {
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_cost?: Maybe<Scalars["numeric"]>;
  invoice_reference?: Maybe<Scalars["String"]>;
  invoice_type?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Shipment_Invoice_Stddev_Fields = {
  __typename?: "inventory_shipment_invoice_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_cost?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Shipment_Invoice_Stddev_Pop_Fields = {
  __typename?: "inventory_shipment_invoice_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_cost?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Shipment_Invoice_Stddev_Samp_Fields = {
  __typename?: "inventory_shipment_invoice_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_cost?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Shipment_Invoice_Sum_Fields = {
  __typename?: "inventory_shipment_invoice_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  invoice_cost?: Maybe<Scalars["numeric"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Sum_Order_By = {
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.shipment_invoice" */
export enum Inventory_Shipment_Invoice_Update_Column {
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceCost = "invoice_cost",
  /** column name */
  InvoiceReference = "invoice_reference",
  /** column name */
  InvoiceType = "invoice_type",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ShipmentId = "shipment_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Shipment_Invoice_Var_Pop_Fields = {
  __typename?: "inventory_shipment_invoice_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_cost?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Shipment_Invoice_Var_Samp_Fields = {
  __typename?: "inventory_shipment_invoice_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_cost?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Shipment_Invoice_Variance_Fields = {
  __typename?: "inventory_shipment_invoice_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_cost?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.shipment_invoice" */
export type Inventory_Shipment_Invoice_Variance_Order_By = {
  id?: Maybe<Order_By>;
  invoice_cost?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.shipment_line" */
export type Inventory_Shipment_Line = {
  __typename?: "inventory_shipment_line";
  /** An object relationship */
  client_order_line: Inventory_Client_Order_Line;
  client_order_line_id: Scalars["Int"];
  damaged_qty: Scalars["Int"];
  destination_location_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location: Inventory_Location;
  /** An object relationship */
  locationBySourceLocationId: Inventory_Location;
  missing_qty: Scalars["Int"];
  outstanding_qty?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  product_stock: Inventory_Product_Stock;
  /** An array relationship */
  product_stocks: Array<Inventory_Product_Stock>;
  /** An aggregated array relationship */
  product_stocks_aggregate: Inventory_Product_Stock_Aggregate;
  received_qty: Scalars["Int"];
  reserved_qty: Scalars["Int"];
  /** An object relationship */
  shipment?: Maybe<Inventory_Shipment>;
  shipment_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  shipment_line_status: Inventory_Shipment_Line_Status;
  shipment_line_status_id: Scalars["Int"];
  source_location_id: Scalars["Int"];
  source_product_stock_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.shipment_line" */
export type Inventory_Shipment_LineProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** columns and relationships of "inventory.shipment_line" */
export type Inventory_Shipment_LineProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** aggregated selection of "inventory.shipment_line" */
export type Inventory_Shipment_Line_Aggregate = {
  __typename?: "inventory_shipment_line_aggregate";
  aggregate?: Maybe<Inventory_Shipment_Line_Aggregate_Fields>;
  nodes: Array<Inventory_Shipment_Line>;
};

/** aggregate fields of "inventory.shipment_line" */
export type Inventory_Shipment_Line_Aggregate_Fields = {
  __typename?: "inventory_shipment_line_aggregate_fields";
  avg?: Maybe<Inventory_Shipment_Line_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Shipment_Line_Max_Fields>;
  min?: Maybe<Inventory_Shipment_Line_Min_Fields>;
  stddev?: Maybe<Inventory_Shipment_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Shipment_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Shipment_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Shipment_Line_Sum_Fields>;
  var_pop?: Maybe<Inventory_Shipment_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Shipment_Line_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Shipment_Line_Variance_Fields>;
};

/** aggregate fields of "inventory.shipment_line" */
export type Inventory_Shipment_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Shipment_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Shipment_Line_Max_Order_By>;
  min?: Maybe<Inventory_Shipment_Line_Min_Order_By>;
  stddev?: Maybe<Inventory_Shipment_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Shipment_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Shipment_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Shipment_Line_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Shipment_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Shipment_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Shipment_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Shipment_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Shipment_Line_Avg_Fields = {
  __typename?: "inventory_shipment_line_avg_fields";
  client_order_line_id?: Maybe<Scalars["Float"]>;
  damaged_qty?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  missing_qty?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
  shipment_line_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Avg_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.shipment_line". All fields are combined with a logical 'AND'. */
export type Inventory_Shipment_Line_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Shipment_Line_Bool_Exp>>>;
  _not?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Shipment_Line_Bool_Exp>>>;
  client_order_line?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
  client_order_line_id?: Maybe<Int_Comparison_Exp>;
  damaged_qty?: Maybe<Int_Comparison_Exp>;
  destination_location_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Inventory_Location_Bool_Exp>;
  locationBySourceLocationId?: Maybe<Inventory_Location_Bool_Exp>;
  missing_qty?: Maybe<Int_Comparison_Exp>;
  outstanding_qty?: Maybe<Int_Comparison_Exp>;
  product_stock?: Maybe<Inventory_Product_Stock_Bool_Exp>;
  product_stocks?: Maybe<Inventory_Product_Stock_Bool_Exp>;
  received_qty?: Maybe<Int_Comparison_Exp>;
  reserved_qty?: Maybe<Int_Comparison_Exp>;
  shipment?: Maybe<Inventory_Shipment_Bool_Exp>;
  shipment_id?: Maybe<Int_Comparison_Exp>;
  shipment_line_status?: Maybe<Inventory_Shipment_Line_Status_Bool_Exp>;
  shipment_line_status_id?: Maybe<Int_Comparison_Exp>;
  source_location_id?: Maybe<Int_Comparison_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.shipment_line" */
export enum Inventory_Shipment_Line_Constraint {
  /** unique or primary key constraint */
  ShipmentLinePkey = "shipment_line_pkey",
}

/** input type for incrementing integer column in table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Inc_Input = {
  client_order_line_id?: Maybe<Scalars["Int"]>;
  damaged_qty?: Maybe<Scalars["Int"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  missing_qty?: Maybe<Scalars["Int"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  shipment_line_status_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Insert_Input = {
  client_order_line?: Maybe<Inventory_Client_Order_Line_Obj_Rel_Insert_Input>;
  client_order_line_id?: Maybe<Scalars["Int"]>;
  damaged_qty?: Maybe<Scalars["Int"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  locationBySourceLocationId?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  missing_qty?: Maybe<Scalars["Int"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  product_stock?: Maybe<Inventory_Product_Stock_Obj_Rel_Insert_Input>;
  product_stocks?: Maybe<Inventory_Product_Stock_Arr_Rel_Insert_Input>;
  received_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  shipment?: Maybe<Inventory_Shipment_Obj_Rel_Insert_Input>;
  shipment_id?: Maybe<Scalars["Int"]>;
  shipment_line_status?: Maybe<Inventory_Shipment_Line_Status_Obj_Rel_Insert_Input>;
  shipment_line_status_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Shipment_Line_Max_Fields = {
  __typename?: "inventory_shipment_line_max_fields";
  client_order_line_id?: Maybe<Scalars["Int"]>;
  damaged_qty?: Maybe<Scalars["Int"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  missing_qty?: Maybe<Scalars["Int"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  shipment_line_status_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Max_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Shipment_Line_Min_Fields = {
  __typename?: "inventory_shipment_line_min_fields";
  client_order_line_id?: Maybe<Scalars["Int"]>;
  damaged_qty?: Maybe<Scalars["Int"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  missing_qty?: Maybe<Scalars["Int"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  shipment_line_status_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Min_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Mutation_Response = {
  __typename?: "inventory_shipment_line_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Shipment_Line>;
};

/** input type for inserting object relation for remote table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Obj_Rel_Insert_Input = {
  data: Inventory_Shipment_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_Line_On_Conflict>;
};

/** on conflict condition type for table "inventory.shipment_line" */
export type Inventory_Shipment_Line_On_Conflict = {
  constraint: Inventory_Shipment_Line_Constraint;
  update_columns: Array<Inventory_Shipment_Line_Update_Column>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.shipment_line" */
export type Inventory_Shipment_Line_Order_By = {
  client_order_line?: Maybe<Inventory_Client_Order_Line_Order_By>;
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Inventory_Location_Order_By>;
  locationBySourceLocationId?: Maybe<Inventory_Location_Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  product_stock?: Maybe<Inventory_Product_Stock_Order_By>;
  product_stocks_aggregate?: Maybe<Inventory_Product_Stock_Aggregate_Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment?: Maybe<Inventory_Shipment_Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status?: Maybe<Inventory_Shipment_Line_Status_Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.shipment_line" */
export type Inventory_Shipment_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.shipment_line" */
export enum Inventory_Shipment_Line_Select_Column {
  /** column name */
  ClientOrderLineId = "client_order_line_id",
  /** column name */
  DamagedQty = "damaged_qty",
  /** column name */
  DestinationLocationId = "destination_location_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MissingQty = "missing_qty",
  /** column name */
  OutstandingQty = "outstanding_qty",
  /** column name */
  ReceivedQty = "received_qty",
  /** column name */
  ReservedQty = "reserved_qty",
  /** column name */
  ShipmentId = "shipment_id",
  /** column name */
  ShipmentLineStatusId = "shipment_line_status_id",
  /** column name */
  SourceLocationId = "source_location_id",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Set_Input = {
  client_order_line_id?: Maybe<Scalars["Int"]>;
  damaged_qty?: Maybe<Scalars["Int"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  missing_qty?: Maybe<Scalars["Int"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  shipment_line_status_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status = {
  __typename?: "inventory_shipment_line_status";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  shipment_line_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Aggregate = {
  __typename?: "inventory_shipment_line_status_aggregate";
  aggregate?: Maybe<Inventory_Shipment_Line_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Shipment_Line_Status>;
};

/** aggregate fields of "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Aggregate_Fields = {
  __typename?: "inventory_shipment_line_status_aggregate_fields";
  avg?: Maybe<Inventory_Shipment_Line_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Shipment_Line_Status_Max_Fields>;
  min?: Maybe<Inventory_Shipment_Line_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Shipment_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Shipment_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Shipment_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Shipment_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Shipment_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Shipment_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Shipment_Line_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Shipment_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Shipment_Line_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Shipment_Line_Status_Max_Order_By>;
  min?: Maybe<Inventory_Shipment_Line_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Shipment_Line_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Shipment_Line_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Shipment_Line_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Shipment_Line_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Shipment_Line_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Shipment_Line_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Shipment_Line_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Shipment_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_Line_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Shipment_Line_Status_Avg_Fields = {
  __typename?: "inventory_shipment_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.shipment_line_status". All fields are combined with a logical 'AND'. */
export type Inventory_Shipment_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Shipment_Line_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Shipment_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Shipment_Line_Status_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  shipment_line_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.shipment_line_status" */
export enum Inventory_Shipment_Line_Status_Constraint {
  /** unique or primary key constraint */
  ShipmentLineStatusPkey = "shipment_line_status_pkey",
}

/** input type for incrementing integer column in table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Shipment_Line_Status_Max_Fields = {
  __typename?: "inventory_shipment_line_status_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_line_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_line_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Shipment_Line_Status_Min_Fields = {
  __typename?: "inventory_shipment_line_status_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_line_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_line_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Mutation_Response = {
  __typename?: "inventory_shipment_line_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Shipment_Line_Status>;
};

/** input type for inserting object relation for remote table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Shipment_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_Line_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_On_Conflict = {
  constraint: Inventory_Shipment_Line_Status_Constraint;
  update_columns: Array<Inventory_Shipment_Line_Status_Update_Column>;
  where?: Maybe<Inventory_Shipment_Line_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_line_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.shipment_line_status" */
export enum Inventory_Shipment_Line_Status_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ShipmentLineStatusName = "shipment_line_status_name",
}

/** input type for updating data in table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Shipment_Line_Status_Stddev_Fields = {
  __typename?: "inventory_shipment_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Shipment_Line_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_shipment_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Shipment_Line_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_shipment_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Shipment_Line_Status_Sum_Fields = {
  __typename?: "inventory_shipment_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.shipment_line_status" */
export enum Inventory_Shipment_Line_Status_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ShipmentLineStatusName = "shipment_line_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Shipment_Line_Status_Var_Pop_Fields = {
  __typename?: "inventory_shipment_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Shipment_Line_Status_Var_Samp_Fields = {
  __typename?: "inventory_shipment_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Shipment_Line_Status_Variance_Fields = {
  __typename?: "inventory_shipment_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.shipment_line_status" */
export type Inventory_Shipment_Line_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Shipment_Line_Stddev_Fields = {
  __typename?: "inventory_shipment_line_stddev_fields";
  client_order_line_id?: Maybe<Scalars["Float"]>;
  damaged_qty?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  missing_qty?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
  shipment_line_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Stddev_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Shipment_Line_Stddev_Pop_Fields = {
  __typename?: "inventory_shipment_line_stddev_pop_fields";
  client_order_line_id?: Maybe<Scalars["Float"]>;
  damaged_qty?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  missing_qty?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
  shipment_line_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Stddev_Pop_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Shipment_Line_Stddev_Samp_Fields = {
  __typename?: "inventory_shipment_line_stddev_samp_fields";
  client_order_line_id?: Maybe<Scalars["Float"]>;
  damaged_qty?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  missing_qty?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
  shipment_line_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Stddev_Samp_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Shipment_Line_Sum_Fields = {
  __typename?: "inventory_shipment_line_sum_fields";
  client_order_line_id?: Maybe<Scalars["Int"]>;
  damaged_qty?: Maybe<Scalars["Int"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  missing_qty?: Maybe<Scalars["Int"]>;
  outstanding_qty?: Maybe<Scalars["Int"]>;
  received_qty?: Maybe<Scalars["Int"]>;
  reserved_qty?: Maybe<Scalars["Int"]>;
  shipment_id?: Maybe<Scalars["Int"]>;
  shipment_line_status_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Sum_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.shipment_line" */
export enum Inventory_Shipment_Line_Update_Column {
  /** column name */
  ClientOrderLineId = "client_order_line_id",
  /** column name */
  DamagedQty = "damaged_qty",
  /** column name */
  DestinationLocationId = "destination_location_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MissingQty = "missing_qty",
  /** column name */
  OutstandingQty = "outstanding_qty",
  /** column name */
  ReceivedQty = "received_qty",
  /** column name */
  ReservedQty = "reserved_qty",
  /** column name */
  ShipmentId = "shipment_id",
  /** column name */
  ShipmentLineStatusId = "shipment_line_status_id",
  /** column name */
  SourceLocationId = "source_location_id",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Shipment_Line_Var_Pop_Fields = {
  __typename?: "inventory_shipment_line_var_pop_fields";
  client_order_line_id?: Maybe<Scalars["Float"]>;
  damaged_qty?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  missing_qty?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
  shipment_line_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Var_Pop_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Shipment_Line_Var_Samp_Fields = {
  __typename?: "inventory_shipment_line_var_samp_fields";
  client_order_line_id?: Maybe<Scalars["Float"]>;
  damaged_qty?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  missing_qty?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
  shipment_line_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Var_Samp_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Shipment_Line_Variance_Fields = {
  __typename?: "inventory_shipment_line_variance_fields";
  client_order_line_id?: Maybe<Scalars["Float"]>;
  damaged_qty?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  missing_qty?: Maybe<Scalars["Float"]>;
  outstanding_qty?: Maybe<Scalars["Float"]>;
  received_qty?: Maybe<Scalars["Float"]>;
  reserved_qty?: Maybe<Scalars["Float"]>;
  shipment_id?: Maybe<Scalars["Float"]>;
  shipment_line_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.shipment_line" */
export type Inventory_Shipment_Line_Variance_Order_By = {
  client_order_line_id?: Maybe<Order_By>;
  damaged_qty?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  missing_qty?: Maybe<Order_By>;
  outstanding_qty?: Maybe<Order_By>;
  received_qty?: Maybe<Order_By>;
  reserved_qty?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_line_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Inventory_Shipment_Max_Fields = {
  __typename?: "inventory_shipment_max_fields";
  arrival_time?: Maybe<Scalars["timestamptz"]>;
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  estimated_arrival_days?: Maybe<Scalars["Int"]>;
  estimated_shipped_time?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_status_id?: Maybe<Scalars["Int"]>;
  shipped_time?: Maybe<Scalars["timestamptz"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Max_Order_By = {
  arrival_time?: Maybe<Order_By>;
  courier_service_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  estimated_shipped_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  shipped_time?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Shipment_Min_Fields = {
  __typename?: "inventory_shipment_min_fields";
  arrival_time?: Maybe<Scalars["timestamptz"]>;
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  estimated_arrival_days?: Maybe<Scalars["Int"]>;
  estimated_shipped_time?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_status_id?: Maybe<Scalars["Int"]>;
  shipped_time?: Maybe<Scalars["timestamptz"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Min_Order_By = {
  arrival_time?: Maybe<Order_By>;
  courier_service_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  estimated_shipped_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  shipped_time?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.shipment" */
export type Inventory_Shipment_Mutation_Response = {
  __typename?: "inventory_shipment_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Shipment>;
};

/** input type for inserting object relation for remote table "inventory.shipment" */
export type Inventory_Shipment_Obj_Rel_Insert_Input = {
  data: Inventory_Shipment_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_On_Conflict>;
};

/** on conflict condition type for table "inventory.shipment" */
export type Inventory_Shipment_On_Conflict = {
  constraint: Inventory_Shipment_Constraint;
  update_columns: Array<Inventory_Shipment_Update_Column>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.shipment" */
export type Inventory_Shipment_Order_By = {
  arrival_time?: Maybe<Order_By>;
  courier_service?: Maybe<Inventory_Courier_Service_Order_By>;
  courier_service_id?: Maybe<Order_By>;
  created_time?: Maybe<Order_By>;
  destination?: Maybe<Inventory_Location_Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  estimated_shipped_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_lines_aggregate?: Maybe<Inventory_Shipment_Line_Aggregate_Order_By>;
  shipment_status?: Maybe<Inventory_Shipment_Status_Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  shipped_time?: Maybe<Order_By>;
  source?: Maybe<Inventory_Location_Order_By>;
  source_location_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.shipment" */
export type Inventory_Shipment_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.shipment" */
export enum Inventory_Shipment_Select_Column {
  /** column name */
  ArrivalTime = "arrival_time",
  /** column name */
  CourierServiceId = "courier_service_id",
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  DestinationLocationId = "destination_location_id",
  /** column name */
  EstimatedArrivalDays = "estimated_arrival_days",
  /** column name */
  EstimatedShippedTime = "estimated_shipped_time",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ShipmentStatusId = "shipment_status_id",
  /** column name */
  ShippedTime = "shipped_time",
  /** column name */
  SourceLocationId = "source_location_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.shipment" */
export type Inventory_Shipment_Set_Input = {
  arrival_time?: Maybe<Scalars["timestamptz"]>;
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_time?: Maybe<Scalars["timestamptz"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  estimated_arrival_days?: Maybe<Scalars["Int"]>;
  estimated_shipped_time?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_status_id?: Maybe<Scalars["Int"]>;
  shipped_time?: Maybe<Scalars["timestamptz"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.shipment_status" */
export type Inventory_Shipment_Status = {
  __typename?: "inventory_shipment_status";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  shipment_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.shipment_status" */
export type Inventory_Shipment_Status_Aggregate = {
  __typename?: "inventory_shipment_status_aggregate";
  aggregate?: Maybe<Inventory_Shipment_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Shipment_Status>;
};

/** aggregate fields of "inventory.shipment_status" */
export type Inventory_Shipment_Status_Aggregate_Fields = {
  __typename?: "inventory_shipment_status_aggregate_fields";
  avg?: Maybe<Inventory_Shipment_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Shipment_Status_Max_Fields>;
  min?: Maybe<Inventory_Shipment_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Shipment_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Shipment_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Shipment_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Shipment_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Shipment_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Shipment_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Shipment_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.shipment_status" */
export type Inventory_Shipment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Shipment_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Shipment_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Shipment_Status_Max_Order_By>;
  min?: Maybe<Inventory_Shipment_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Shipment_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Shipment_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Shipment_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Shipment_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Shipment_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Shipment_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Shipment_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Shipment_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Shipment_Status_Avg_Fields = {
  __typename?: "inventory_shipment_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.shipment_status". All fields are combined with a logical 'AND'. */
export type Inventory_Shipment_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Shipment_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Shipment_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Shipment_Status_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  shipment_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.shipment_status" */
export enum Inventory_Shipment_Status_Constraint {
  /** unique or primary key constraint */
  ShipmentStatusPkey = "shipment_status_pkey",
}

/** input type for incrementing integer column in table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Shipment_Status_Max_Fields = {
  __typename?: "inventory_shipment_status_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Shipment_Status_Min_Fields = {
  __typename?: "inventory_shipment_status_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Mutation_Response = {
  __typename?: "inventory_shipment_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Shipment_Status>;
};

/** input type for inserting object relation for remote table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Shipment_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.shipment_status" */
export type Inventory_Shipment_Status_On_Conflict = {
  constraint: Inventory_Shipment_Status_Constraint;
  update_columns: Array<Inventory_Shipment_Status_Update_Column>;
  where?: Maybe<Inventory_Shipment_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.shipment_status" */
export type Inventory_Shipment_Status_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  shipment_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.shipment_status" */
export type Inventory_Shipment_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.shipment_status" */
export enum Inventory_Shipment_Status_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ShipmentStatusName = "shipment_status_name",
}

/** input type for updating data in table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  shipment_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Shipment_Status_Stddev_Fields = {
  __typename?: "inventory_shipment_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Shipment_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_shipment_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Shipment_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_shipment_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Shipment_Status_Sum_Fields = {
  __typename?: "inventory_shipment_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.shipment_status" */
export enum Inventory_Shipment_Status_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ShipmentStatusName = "shipment_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Shipment_Status_Var_Pop_Fields = {
  __typename?: "inventory_shipment_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Shipment_Status_Var_Samp_Fields = {
  __typename?: "inventory_shipment_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Shipment_Status_Variance_Fields = {
  __typename?: "inventory_shipment_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.shipment_status" */
export type Inventory_Shipment_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Shipment_Stddev_Fields = {
  __typename?: "inventory_shipment_stddev_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  estimated_arrival_days?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  shipment_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Stddev_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Shipment_Stddev_Pop_Fields = {
  __typename?: "inventory_shipment_stddev_pop_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  estimated_arrival_days?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  shipment_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Stddev_Pop_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Shipment_Stddev_Samp_Fields = {
  __typename?: "inventory_shipment_stddev_samp_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  estimated_arrival_days?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  shipment_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Stddev_Samp_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Shipment_Sum_Fields = {
  __typename?: "inventory_shipment_sum_fields";
  courier_service_id?: Maybe<Scalars["Int"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  estimated_arrival_days?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  shipment_status_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Sum_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.shipment" */
export enum Inventory_Shipment_Update_Column {
  /** column name */
  ArrivalTime = "arrival_time",
  /** column name */
  CourierServiceId = "courier_service_id",
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  DestinationLocationId = "destination_location_id",
  /** column name */
  EstimatedArrivalDays = "estimated_arrival_days",
  /** column name */
  EstimatedShippedTime = "estimated_shipped_time",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ShipmentStatusId = "shipment_status_id",
  /** column name */
  ShippedTime = "shipped_time",
  /** column name */
  SourceLocationId = "source_location_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Shipment_Var_Pop_Fields = {
  __typename?: "inventory_shipment_var_pop_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  estimated_arrival_days?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  shipment_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Var_Pop_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Shipment_Var_Samp_Fields = {
  __typename?: "inventory_shipment_var_samp_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  estimated_arrival_days?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  shipment_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Var_Samp_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Shipment_Variance_Fields = {
  __typename?: "inventory_shipment_variance_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  destination_location_id?: Maybe<Scalars["Float"]>;
  estimated_arrival_days?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  shipment_status_id?: Maybe<Scalars["Float"]>;
  source_location_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.shipment" */
export type Inventory_Shipment_Variance_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  destination_location_id?: Maybe<Order_By>;
  estimated_arrival_days?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  source_location_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.stock_status" */
export type Inventory_Stock_Status = {
  __typename?: "inventory_stock_status";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  stock_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.stock_status" */
export type Inventory_Stock_Status_Aggregate = {
  __typename?: "inventory_stock_status_aggregate";
  aggregate?: Maybe<Inventory_Stock_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Stock_Status>;
};

/** aggregate fields of "inventory.stock_status" */
export type Inventory_Stock_Status_Aggregate_Fields = {
  __typename?: "inventory_stock_status_aggregate_fields";
  avg?: Maybe<Inventory_Stock_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Stock_Status_Max_Fields>;
  min?: Maybe<Inventory_Stock_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Stock_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Stock_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Stock_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Stock_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Stock_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Stock_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Stock_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.stock_status" */
export type Inventory_Stock_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Stock_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.stock_status" */
export type Inventory_Stock_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Stock_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Stock_Status_Max_Order_By>;
  min?: Maybe<Inventory_Stock_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Stock_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Stock_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Stock_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Stock_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Stock_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Stock_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Stock_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.stock_status" */
export type Inventory_Stock_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Stock_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Stock_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Stock_Status_Avg_Fields = {
  __typename?: "inventory_stock_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.stock_status". All fields are combined with a logical 'AND'. */
export type Inventory_Stock_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Stock_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Stock_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Stock_Status_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  stock_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.stock_status" */
export enum Inventory_Stock_Status_Constraint {
  /** unique or primary key constraint */
  StockStatusPkey = "stock_status_pkey",
}

/** input type for incrementing integer column in table "inventory.stock_status" */
export type Inventory_Stock_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.stock_status" */
export type Inventory_Stock_Status_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Stock_Status_Max_Fields = {
  __typename?: "inventory_stock_status_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  stock_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Stock_Status_Min_Fields = {
  __typename?: "inventory_stock_status_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  stock_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.stock_status" */
export type Inventory_Stock_Status_Mutation_Response = {
  __typename?: "inventory_stock_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Stock_Status>;
};

/** input type for inserting object relation for remote table "inventory.stock_status" */
export type Inventory_Stock_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Stock_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Stock_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.stock_status" */
export type Inventory_Stock_Status_On_Conflict = {
  constraint: Inventory_Stock_Status_Constraint;
  update_columns: Array<Inventory_Stock_Status_Update_Column>;
  where?: Maybe<Inventory_Stock_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.stock_status" */
export type Inventory_Stock_Status_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  stock_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.stock_status" */
export type Inventory_Stock_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.stock_status" */
export enum Inventory_Stock_Status_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockStatusName = "stock_status_name",
}

/** input type for updating data in table "inventory.stock_status" */
export type Inventory_Stock_Status_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Stock_Status_Stddev_Fields = {
  __typename?: "inventory_stock_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Stock_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_stock_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Stock_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_stock_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Stock_Status_Sum_Fields = {
  __typename?: "inventory_stock_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.stock_status" */
export enum Inventory_Stock_Status_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockStatusName = "stock_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Stock_Status_Var_Pop_Fields = {
  __typename?: "inventory_stock_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Stock_Status_Var_Samp_Fields = {
  __typename?: "inventory_stock_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Stock_Status_Variance_Fields = {
  __typename?: "inventory_stock_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.stock_status" */
export type Inventory_Stock_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice = {
  __typename?: "inventory_supplier_invoice";
  created_time: Scalars["timestamptz"];
  currency_code: Scalars["String"];
  id: Scalars["Int"];
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated: Scalars["timestamptz"];
  supplier_account_id: Scalars["Int"];
  supplier_invoice_status_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Aggregate = {
  __typename?: "inventory_supplier_invoice_aggregate";
  aggregate?: Maybe<Inventory_Supplier_Invoice_Aggregate_Fields>;
  nodes: Array<Inventory_Supplier_Invoice>;
};

/** aggregate fields of "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Aggregate_Fields = {
  __typename?: "inventory_supplier_invoice_aggregate_fields";
  avg?: Maybe<Inventory_Supplier_Invoice_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Supplier_Invoice_Max_Fields>;
  min?: Maybe<Inventory_Supplier_Invoice_Min_Fields>;
  stddev?: Maybe<Inventory_Supplier_Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Supplier_Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Supplier_Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Supplier_Invoice_Sum_Fields>;
  var_pop?: Maybe<Inventory_Supplier_Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Supplier_Invoice_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Supplier_Invoice_Variance_Fields>;
};

/** aggregate fields of "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Supplier_Invoice_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Supplier_Invoice_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Supplier_Invoice_Max_Order_By>;
  min?: Maybe<Inventory_Supplier_Invoice_Min_Order_By>;
  stddev?: Maybe<Inventory_Supplier_Invoice_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Supplier_Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Supplier_Invoice_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Supplier_Invoice_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Supplier_Invoice_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Supplier_Invoice_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Supplier_Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Supplier_Invoice_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Supplier_Invoice_Avg_Fields = {
  __typename?: "inventory_supplier_invoice_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Avg_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.supplier_invoice". All fields are combined with a logical 'AND'. */
export type Inventory_Supplier_Invoice_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Supplier_Invoice_Bool_Exp>>>;
  _not?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Supplier_Invoice_Bool_Exp>>>;
  created_time?: Maybe<Timestamptz_Comparison_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_time?: Maybe<Timestamptz_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  supplier_account_id?: Maybe<Int_Comparison_Exp>;
  supplier_invoice_status_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.supplier_invoice" */
export enum Inventory_Supplier_Invoice_Constraint {
  /** unique or primary key constraint */
  SupplierInvoicePkey = "supplier_invoice_pkey",
}

/** input type for incrementing integer column in table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Insert_Input = {
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line = {
  __typename?: "inventory_supplier_invoice_line";
  id: Scalars["Int"];
  invoice_amount: Scalars["numeric"];
  last_updated: Scalars["timestamptz"];
  production_order_line_id: Scalars["Int"];
  received_amount: Scalars["numeric"];
  supplier_invoice_id: Scalars["Int"];
  supplier_invoice_line_status_id: Scalars["Int"];
  user_id: Scalars["String"];
};

/** aggregated selection of "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Aggregate = {
  __typename?: "inventory_supplier_invoice_line_aggregate";
  aggregate?: Maybe<Inventory_Supplier_Invoice_Line_Aggregate_Fields>;
  nodes: Array<Inventory_Supplier_Invoice_Line>;
};

/** aggregate fields of "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Aggregate_Fields = {
  __typename?: "inventory_supplier_invoice_line_aggregate_fields";
  avg?: Maybe<Inventory_Supplier_Invoice_Line_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Supplier_Invoice_Line_Max_Fields>;
  min?: Maybe<Inventory_Supplier_Invoice_Line_Min_Fields>;
  stddev?: Maybe<Inventory_Supplier_Invoice_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Supplier_Invoice_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Supplier_Invoice_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Supplier_Invoice_Line_Sum_Fields>;
  var_pop?: Maybe<Inventory_Supplier_Invoice_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Supplier_Invoice_Line_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Supplier_Invoice_Line_Variance_Fields>;
};

/** aggregate fields of "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Supplier_Invoice_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Supplier_Invoice_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Supplier_Invoice_Line_Max_Order_By>;
  min?: Maybe<Inventory_Supplier_Invoice_Line_Min_Order_By>;
  stddev?: Maybe<Inventory_Supplier_Invoice_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Supplier_Invoice_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Supplier_Invoice_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Supplier_Invoice_Line_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Supplier_Invoice_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Supplier_Invoice_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Supplier_Invoice_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Supplier_Invoice_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Supplier_Invoice_Line_Avg_Fields = {
  __typename?: "inventory_supplier_invoice_line_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  production_order_line_id?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
  supplier_invoice_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Avg_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.supplier_invoice_line". All fields are combined with a logical 'AND'. */
export type Inventory_Supplier_Invoice_Line_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Supplier_Invoice_Line_Bool_Exp>>>;
  _not?: Maybe<Inventory_Supplier_Invoice_Line_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Supplier_Invoice_Line_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  invoice_amount?: Maybe<Numeric_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  production_order_line_id?: Maybe<Int_Comparison_Exp>;
  received_amount?: Maybe<Numeric_Comparison_Exp>;
  supplier_invoice_id?: Maybe<Int_Comparison_Exp>;
  supplier_invoice_line_status_id?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.supplier_invoice_line" */
export enum Inventory_Supplier_Invoice_Line_Constraint {
  /** unique or primary key constraint */
  SupplierInvoiceLinePkey = "supplier_invoice_line_pkey",
}

/** input type for incrementing integer column in table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  production_order_line_id?: Maybe<Scalars["Int"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  supplier_invoice_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_line_id?: Maybe<Scalars["Int"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  supplier_invoice_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Supplier_Invoice_Line_Max_Fields = {
  __typename?: "inventory_supplier_invoice_line_max_fields";
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_line_id?: Maybe<Scalars["Int"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  supplier_invoice_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Max_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Supplier_Invoice_Line_Min_Fields = {
  __typename?: "inventory_supplier_invoice_line_min_fields";
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_line_id?: Maybe<Scalars["Int"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  supplier_invoice_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Min_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Mutation_Response = {
  __typename?: "inventory_supplier_invoice_line_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Supplier_Invoice_Line>;
};

/** input type for inserting object relation for remote table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Obj_Rel_Insert_Input = {
  data: Inventory_Supplier_Invoice_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Line_On_Conflict>;
};

/** on conflict condition type for table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_On_Conflict = {
  constraint: Inventory_Supplier_Invoice_Line_Constraint;
  update_columns: Array<Inventory_Supplier_Invoice_Line_Update_Column>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.supplier_invoice_line" */
export enum Inventory_Supplier_Invoice_Line_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  InvoiceAmount = "invoice_amount",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductionOrderLineId = "production_order_line_id",
  /** column name */
  ReceivedAmount = "received_amount",
  /** column name */
  SupplierInvoiceId = "supplier_invoice_id",
  /** column name */
  SupplierInvoiceLineStatusId = "supplier_invoice_line_status_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  production_order_line_id?: Maybe<Scalars["Int"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  supplier_invoice_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status = {
  __typename?: "inventory_supplier_invoice_line_status";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  supplier_invoice_line_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Aggregate = {
  __typename?: "inventory_supplier_invoice_line_status_aggregate";
  aggregate?: Maybe<Inventory_Supplier_Invoice_Line_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Supplier_Invoice_Line_Status>;
};

/** aggregate fields of "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Aggregate_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_aggregate_fields";
  avg?: Maybe<Inventory_Supplier_Invoice_Line_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Supplier_Invoice_Line_Status_Max_Fields>;
  min?: Maybe<Inventory_Supplier_Invoice_Line_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Supplier_Invoice_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Supplier_Invoice_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Supplier_Invoice_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Supplier_Invoice_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Supplier_Invoice_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Supplier_Invoice_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Supplier_Invoice_Line_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Supplier_Invoice_Line_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Supplier_Invoice_Line_Status_Max_Order_By>;
  min?: Maybe<Inventory_Supplier_Invoice_Line_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Supplier_Invoice_Line_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Supplier_Invoice_Line_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Supplier_Invoice_Line_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Supplier_Invoice_Line_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Supplier_Invoice_Line_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Supplier_Invoice_Line_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Supplier_Invoice_Line_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Supplier_Invoice_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Line_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Supplier_Invoice_Line_Status_Avg_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.supplier_invoice_line_status". All fields are combined with a logical 'AND'. */
export type Inventory_Supplier_Invoice_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Supplier_Invoice_Line_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Supplier_Invoice_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Supplier_Invoice_Line_Status_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  supplier_invoice_line_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.supplier_invoice_line_status" */
export enum Inventory_Supplier_Invoice_Line_Status_Constraint {
  /** unique or primary key constraint */
  SupplierInvoiceLineStatusPkey = "supplier_invoice_line_status_pkey",
}

/** input type for incrementing integer column in table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_invoice_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Supplier_Invoice_Line_Status_Max_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_invoice_line_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_invoice_line_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Supplier_Invoice_Line_Status_Min_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_invoice_line_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_invoice_line_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Mutation_Response = {
  __typename?: "inventory_supplier_invoice_line_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Supplier_Invoice_Line_Status>;
};

/** input type for inserting object relation for remote table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Supplier_Invoice_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Line_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_On_Conflict = {
  constraint: Inventory_Supplier_Invoice_Line_Status_Constraint;
  update_columns: Array<Inventory_Supplier_Invoice_Line_Status_Update_Column>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_invoice_line_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.supplier_invoice_line_status" */
export enum Inventory_Supplier_Invoice_Line_Status_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SupplierInvoiceLineStatusName = "supplier_invoice_line_status_name",
}

/** input type for updating data in table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_invoice_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Supplier_Invoice_Line_Status_Stddev_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Supplier_Invoice_Line_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Supplier_Invoice_Line_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Supplier_Invoice_Line_Status_Sum_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.supplier_invoice_line_status" */
export enum Inventory_Supplier_Invoice_Line_Status_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SupplierInvoiceLineStatusName = "supplier_invoice_line_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Supplier_Invoice_Line_Status_Var_Pop_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Supplier_Invoice_Line_Status_Var_Samp_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Supplier_Invoice_Line_Status_Variance_Fields = {
  __typename?: "inventory_supplier_invoice_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.supplier_invoice_line_status" */
export type Inventory_Supplier_Invoice_Line_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Supplier_Invoice_Line_Stddev_Fields = {
  __typename?: "inventory_supplier_invoice_line_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  production_order_line_id?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
  supplier_invoice_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Supplier_Invoice_Line_Stddev_Pop_Fields = {
  __typename?: "inventory_supplier_invoice_line_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  production_order_line_id?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
  supplier_invoice_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Supplier_Invoice_Line_Stddev_Samp_Fields = {
  __typename?: "inventory_supplier_invoice_line_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  production_order_line_id?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
  supplier_invoice_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Supplier_Invoice_Line_Sum_Fields = {
  __typename?: "inventory_supplier_invoice_line_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  invoice_amount?: Maybe<Scalars["numeric"]>;
  production_order_line_id?: Maybe<Scalars["Int"]>;
  received_amount?: Maybe<Scalars["numeric"]>;
  supplier_invoice_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Sum_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.supplier_invoice_line" */
export enum Inventory_Supplier_Invoice_Line_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  InvoiceAmount = "invoice_amount",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductionOrderLineId = "production_order_line_id",
  /** column name */
  ReceivedAmount = "received_amount",
  /** column name */
  SupplierInvoiceId = "supplier_invoice_id",
  /** column name */
  SupplierInvoiceLineStatusId = "supplier_invoice_line_status_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Supplier_Invoice_Line_Var_Pop_Fields = {
  __typename?: "inventory_supplier_invoice_line_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  production_order_line_id?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
  supplier_invoice_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Supplier_Invoice_Line_Var_Samp_Fields = {
  __typename?: "inventory_supplier_invoice_line_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  production_order_line_id?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
  supplier_invoice_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Supplier_Invoice_Line_Variance_Fields = {
  __typename?: "inventory_supplier_invoice_line_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  invoice_amount?: Maybe<Scalars["Float"]>;
  production_order_line_id?: Maybe<Scalars["Float"]>;
  received_amount?: Maybe<Scalars["Float"]>;
  supplier_invoice_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.supplier_invoice_line" */
export type Inventory_Supplier_Invoice_Line_Variance_Order_By = {
  id?: Maybe<Order_By>;
  invoice_amount?: Maybe<Order_By>;
  production_order_line_id?: Maybe<Order_By>;
  received_amount?: Maybe<Order_By>;
  supplier_invoice_id?: Maybe<Order_By>;
  supplier_invoice_line_status_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Inventory_Supplier_Invoice_Max_Fields = {
  __typename?: "inventory_supplier_invoice_max_fields";
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Max_Order_By = {
  created_time?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_time?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Supplier_Invoice_Min_Fields = {
  __typename?: "inventory_supplier_invoice_min_fields";
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Min_Order_By = {
  created_time?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_time?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Mutation_Response = {
  __typename?: "inventory_supplier_invoice_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Supplier_Invoice>;
};

/** input type for inserting object relation for remote table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Obj_Rel_Insert_Input = {
  data: Inventory_Supplier_Invoice_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_On_Conflict>;
};

/** on conflict condition type for table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_On_Conflict = {
  constraint: Inventory_Supplier_Invoice_Constraint;
  update_columns: Array<Inventory_Supplier_Invoice_Update_Column>;
  where?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Order_By = {
  created_time?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoice_time?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.supplier_invoice" */
export enum Inventory_Supplier_Invoice_Select_Column {
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceTime = "invoice_time",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SupplierAccountId = "supplier_account_id",
  /** column name */
  SupplierInvoiceStatusId = "supplier_invoice_status_id",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Set_Input = {
  created_time?: Maybe<Scalars["timestamptz"]>;
  currency_code?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_time?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status = {
  __typename?: "inventory_supplier_invoice_status";
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  supplier_invoice_status_name: Scalars["String"];
};

/** aggregated selection of "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Aggregate = {
  __typename?: "inventory_supplier_invoice_status_aggregate";
  aggregate?: Maybe<Inventory_Supplier_Invoice_Status_Aggregate_Fields>;
  nodes: Array<Inventory_Supplier_Invoice_Status>;
};

/** aggregate fields of "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Aggregate_Fields = {
  __typename?: "inventory_supplier_invoice_status_aggregate_fields";
  avg?: Maybe<Inventory_Supplier_Invoice_Status_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Supplier_Invoice_Status_Max_Fields>;
  min?: Maybe<Inventory_Supplier_Invoice_Status_Min_Fields>;
  stddev?: Maybe<Inventory_Supplier_Invoice_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Supplier_Invoice_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Supplier_Invoice_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Supplier_Invoice_Status_Sum_Fields>;
  var_pop?: Maybe<Inventory_Supplier_Invoice_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Supplier_Invoice_Status_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Supplier_Invoice_Status_Variance_Fields>;
};

/** aggregate fields of "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Supplier_Invoice_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Supplier_Invoice_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Supplier_Invoice_Status_Max_Order_By>;
  min?: Maybe<Inventory_Supplier_Invoice_Status_Min_Order_By>;
  stddev?: Maybe<Inventory_Supplier_Invoice_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Supplier_Invoice_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Supplier_Invoice_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Supplier_Invoice_Status_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Supplier_Invoice_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Supplier_Invoice_Status_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Supplier_Invoice_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Supplier_Invoice_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Supplier_Invoice_Status_Avg_Fields = {
  __typename?: "inventory_supplier_invoice_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.supplier_invoice_status". All fields are combined with a logical 'AND'. */
export type Inventory_Supplier_Invoice_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Supplier_Invoice_Status_Bool_Exp>>>;
  _not?: Maybe<Inventory_Supplier_Invoice_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Supplier_Invoice_Status_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  supplier_invoice_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.supplier_invoice_status" */
export enum Inventory_Supplier_Invoice_Status_Constraint {
  /** unique or primary key constraint */
  SupplierInvoiceStatusPkey = "supplier_invoice_status_pkey",
}

/** input type for incrementing integer column in table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_invoice_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Supplier_Invoice_Status_Max_Fields = {
  __typename?: "inventory_supplier_invoice_status_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_invoice_status_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_invoice_status_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Supplier_Invoice_Status_Min_Fields = {
  __typename?: "inventory_supplier_invoice_status_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_invoice_status_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_invoice_status_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Mutation_Response = {
  __typename?: "inventory_supplier_invoice_status_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Supplier_Invoice_Status>;
};

/** input type for inserting object relation for remote table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Obj_Rel_Insert_Input = {
  data: Inventory_Supplier_Invoice_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Status_On_Conflict>;
};

/** on conflict condition type for table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_On_Conflict = {
  constraint: Inventory_Supplier_Invoice_Status_Constraint;
  update_columns: Array<Inventory_Supplier_Invoice_Status_Update_Column>;
  where?: Maybe<Inventory_Supplier_Invoice_Status_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  supplier_invoice_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.supplier_invoice_status" */
export enum Inventory_Supplier_Invoice_Status_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SupplierInvoiceStatusName = "supplier_invoice_status_name",
}

/** input type for updating data in table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  supplier_invoice_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Supplier_Invoice_Status_Stddev_Fields = {
  __typename?: "inventory_supplier_invoice_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Supplier_Invoice_Status_Stddev_Pop_Fields = {
  __typename?: "inventory_supplier_invoice_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Supplier_Invoice_Status_Stddev_Samp_Fields = {
  __typename?: "inventory_supplier_invoice_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Supplier_Invoice_Status_Sum_Fields = {
  __typename?: "inventory_supplier_invoice_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "inventory.supplier_invoice_status" */
export enum Inventory_Supplier_Invoice_Status_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SupplierInvoiceStatusName = "supplier_invoice_status_name",
}

/** aggregate var_pop on columns */
export type Inventory_Supplier_Invoice_Status_Var_Pop_Fields = {
  __typename?: "inventory_supplier_invoice_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Supplier_Invoice_Status_Var_Samp_Fields = {
  __typename?: "inventory_supplier_invoice_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Supplier_Invoice_Status_Variance_Fields = {
  __typename?: "inventory_supplier_invoice_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.supplier_invoice_status" */
export type Inventory_Supplier_Invoice_Status_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Inventory_Supplier_Invoice_Stddev_Fields = {
  __typename?: "inventory_supplier_invoice_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Supplier_Invoice_Stddev_Pop_Fields = {
  __typename?: "inventory_supplier_invoice_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Supplier_Invoice_Stddev_Samp_Fields = {
  __typename?: "inventory_supplier_invoice_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Supplier_Invoice_Sum_Fields = {
  __typename?: "inventory_supplier_invoice_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Sum_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.supplier_invoice" */
export enum Inventory_Supplier_Invoice_Update_Column {
  /** column name */
  CreatedTime = "created_time",
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  Id = "id",
  /** column name */
  InvoiceTime = "invoice_time",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SupplierAccountId = "supplier_account_id",
  /** column name */
  SupplierInvoiceStatusId = "supplier_invoice_status_id",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Supplier_Invoice_Var_Pop_Fields = {
  __typename?: "inventory_supplier_invoice_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Supplier_Invoice_Var_Samp_Fields = {
  __typename?: "inventory_supplier_invoice_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Supplier_Invoice_Variance_Fields = {
  __typename?: "inventory_supplier_invoice_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
  supplier_invoice_status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.supplier_invoice" */
export type Inventory_Supplier_Invoice_Variance_Order_By = {
  id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_invoice_status_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.supplier_product" */
export type Inventory_Supplier_Product = {
  __typename?: "inventory_supplier_product";
  /** An object relationship */
  account: Inventory_Account;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  product: Inventory_Product;
  product_id: Scalars["Int"];
  supplier_account_id: Scalars["Int"];
  supplier_product_name: Scalars["String"];
  supplier_product_reference: Scalars["String"];
  /** An array relationship */
  supplier_quotes: Array<Inventory_Supplier_Quote>;
  /** An aggregated array relationship */
  supplier_quotes_aggregate: Inventory_Supplier_Quote_Aggregate;
  user_id: Scalars["String"];
};

/** columns and relationships of "inventory.supplier_product" */
export type Inventory_Supplier_ProductSupplier_QuotesArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** columns and relationships of "inventory.supplier_product" */
export type Inventory_Supplier_ProductSupplier_Quotes_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** aggregated selection of "inventory.supplier_product" */
export type Inventory_Supplier_Product_Aggregate = {
  __typename?: "inventory_supplier_product_aggregate";
  aggregate?: Maybe<Inventory_Supplier_Product_Aggregate_Fields>;
  nodes: Array<Inventory_Supplier_Product>;
};

/** aggregate fields of "inventory.supplier_product" */
export type Inventory_Supplier_Product_Aggregate_Fields = {
  __typename?: "inventory_supplier_product_aggregate_fields";
  avg?: Maybe<Inventory_Supplier_Product_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Supplier_Product_Max_Fields>;
  min?: Maybe<Inventory_Supplier_Product_Min_Fields>;
  stddev?: Maybe<Inventory_Supplier_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Supplier_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Supplier_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Supplier_Product_Sum_Fields>;
  var_pop?: Maybe<Inventory_Supplier_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Supplier_Product_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Supplier_Product_Variance_Fields>;
};

/** aggregate fields of "inventory.supplier_product" */
export type Inventory_Supplier_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Supplier_Product_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Supplier_Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Supplier_Product_Max_Order_By>;
  min?: Maybe<Inventory_Supplier_Product_Min_Order_By>;
  stddev?: Maybe<Inventory_Supplier_Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Supplier_Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Supplier_Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Supplier_Product_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Supplier_Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Supplier_Product_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Supplier_Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Supplier_Product_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Supplier_Product_Avg_Fields = {
  __typename?: "inventory_supplier_product_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Avg_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.supplier_product". All fields are combined with a logical 'AND'. */
export type Inventory_Supplier_Product_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Supplier_Product_Bool_Exp>>>;
  _not?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Supplier_Product_Bool_Exp>>>;
  account?: Maybe<Inventory_Account_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product?: Maybe<Inventory_Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  supplier_account_id?: Maybe<Int_Comparison_Exp>;
  supplier_product_name?: Maybe<String_Comparison_Exp>;
  supplier_product_reference?: Maybe<String_Comparison_Exp>;
  supplier_quotes?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.supplier_product" */
export enum Inventory_Supplier_Product_Constraint {
  /** unique or primary key constraint */
  SupplierProductPkey = "supplier_product_pkey",
}

/** input type for incrementing integer column in table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Insert_Input = {
  account?: Maybe<Inventory_Account_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product?: Maybe<Inventory_Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_product_name?: Maybe<Scalars["String"]>;
  supplier_product_reference?: Maybe<Scalars["String"]>;
  supplier_quotes?: Maybe<Inventory_Supplier_Quote_Arr_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Inventory_Supplier_Product_Max_Fields = {
  __typename?: "inventory_supplier_product_max_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_product_name?: Maybe<Scalars["String"]>;
  supplier_product_reference?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Max_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_product_name?: Maybe<Order_By>;
  supplier_product_reference?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Supplier_Product_Min_Fields = {
  __typename?: "inventory_supplier_product_min_fields";
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_product_name?: Maybe<Scalars["String"]>;
  supplier_product_reference?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Min_Order_By = {
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_product_name?: Maybe<Order_By>;
  supplier_product_reference?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Mutation_Response = {
  __typename?: "inventory_supplier_product_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Supplier_Product>;
};

/** input type for inserting object relation for remote table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Obj_Rel_Insert_Input = {
  data: Inventory_Supplier_Product_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Product_On_Conflict>;
};

/** on conflict condition type for table "inventory.supplier_product" */
export type Inventory_Supplier_Product_On_Conflict = {
  constraint: Inventory_Supplier_Product_Constraint;
  update_columns: Array<Inventory_Supplier_Product_Update_Column>;
  where?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.supplier_product" */
export type Inventory_Supplier_Product_Order_By = {
  account?: Maybe<Inventory_Account_Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product?: Maybe<Inventory_Product_Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
  supplier_product_name?: Maybe<Order_By>;
  supplier_product_reference?: Maybe<Order_By>;
  supplier_quotes_aggregate?: Maybe<Inventory_Supplier_Quote_Aggregate_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.supplier_product" */
export type Inventory_Supplier_Product_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.supplier_product" */
export enum Inventory_Supplier_Product_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SupplierAccountId = "supplier_account_id",
  /** column name */
  SupplierProductName = "supplier_product_name",
  /** column name */
  SupplierProductReference = "supplier_product_reference",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
  supplier_product_name?: Maybe<Scalars["String"]>;
  supplier_product_reference?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Inventory_Supplier_Product_Stddev_Fields = {
  __typename?: "inventory_supplier_product_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Supplier_Product_Stddev_Pop_Fields = {
  __typename?: "inventory_supplier_product_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Supplier_Product_Stddev_Samp_Fields = {
  __typename?: "inventory_supplier_product_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Supplier_Product_Sum_Fields = {
  __typename?: "inventory_supplier_product_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  supplier_account_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Sum_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** update columns of table "inventory.supplier_product" */
export enum Inventory_Supplier_Product_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SupplierAccountId = "supplier_account_id",
  /** column name */
  SupplierProductName = "supplier_product_name",
  /** column name */
  SupplierProductReference = "supplier_product_reference",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Inventory_Supplier_Product_Var_Pop_Fields = {
  __typename?: "inventory_supplier_product_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Supplier_Product_Var_Samp_Fields = {
  __typename?: "inventory_supplier_product_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Supplier_Product_Variance_Fields = {
  __typename?: "inventory_supplier_product_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  supplier_account_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.supplier_product" */
export type Inventory_Supplier_Product_Variance_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  supplier_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "inventory.supplier_quote" */
export type Inventory_Supplier_Quote = {
  __typename?: "inventory_supplier_quote";
  /** An object relationship */
  currency: Inventory_Currency;
  currency_code: Scalars["String"];
  /** An object relationship */
  folder?: Maybe<Inventory_Folder>;
  folder_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location: Inventory_Location;
  location_id: Scalars["Int"];
  min_qty: Scalars["Int"];
  notes: Scalars["String"];
  /** An array relationship */
  production_order_lines: Array<Inventory_Production_Order_Line>;
  /** An aggregated array relationship */
  production_order_lines_aggregate: Inventory_Production_Order_Line_Aggregate;
  /** An object relationship */
  supplier_product: Inventory_Supplier_Product;
  supplier_product_id: Scalars["Int"];
  supplier_quote_reference: Scalars["String"];
  unit_cost: Scalars["numeric"];
  user_id: Scalars["String"];
  valid_from: Scalars["timestamptz"];
  valid_to?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "inventory.supplier_quote" */
export type Inventory_Supplier_QuoteProduction_Order_LinesArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** columns and relationships of "inventory.supplier_quote" */
export type Inventory_Supplier_QuoteProduction_Order_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** aggregated selection of "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Aggregate = {
  __typename?: "inventory_supplier_quote_aggregate";
  aggregate?: Maybe<Inventory_Supplier_Quote_Aggregate_Fields>;
  nodes: Array<Inventory_Supplier_Quote>;
};

/** aggregate fields of "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Aggregate_Fields = {
  __typename?: "inventory_supplier_quote_aggregate_fields";
  avg?: Maybe<Inventory_Supplier_Quote_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Inventory_Supplier_Quote_Max_Fields>;
  min?: Maybe<Inventory_Supplier_Quote_Min_Fields>;
  stddev?: Maybe<Inventory_Supplier_Quote_Stddev_Fields>;
  stddev_pop?: Maybe<Inventory_Supplier_Quote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inventory_Supplier_Quote_Stddev_Samp_Fields>;
  sum?: Maybe<Inventory_Supplier_Quote_Sum_Fields>;
  var_pop?: Maybe<Inventory_Supplier_Quote_Var_Pop_Fields>;
  var_samp?: Maybe<Inventory_Supplier_Quote_Var_Samp_Fields>;
  variance?: Maybe<Inventory_Supplier_Quote_Variance_Fields>;
};

/** aggregate fields of "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Aggregate_Order_By = {
  avg?: Maybe<Inventory_Supplier_Quote_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inventory_Supplier_Quote_Max_Order_By>;
  min?: Maybe<Inventory_Supplier_Quote_Min_Order_By>;
  stddev?: Maybe<Inventory_Supplier_Quote_Stddev_Order_By>;
  stddev_pop?: Maybe<Inventory_Supplier_Quote_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inventory_Supplier_Quote_Stddev_Samp_Order_By>;
  sum?: Maybe<Inventory_Supplier_Quote_Sum_Order_By>;
  var_pop?: Maybe<Inventory_Supplier_Quote_Var_Pop_Order_By>;
  var_samp?: Maybe<Inventory_Supplier_Quote_Var_Samp_Order_By>;
  variance?: Maybe<Inventory_Supplier_Quote_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Arr_Rel_Insert_Input = {
  data: Array<Inventory_Supplier_Quote_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Quote_On_Conflict>;
};

/** aggregate avg on columns */
export type Inventory_Supplier_Quote_Avg_Fields = {
  __typename?: "inventory_supplier_quote_avg_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  supplier_product_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Avg_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inventory.supplier_quote". All fields are combined with a logical 'AND'. */
export type Inventory_Supplier_Quote_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Inventory_Supplier_Quote_Bool_Exp>>>;
  _not?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Inventory_Supplier_Quote_Bool_Exp>>>;
  currency?: Maybe<Inventory_Currency_Bool_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  folder?: Maybe<Inventory_Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Inventory_Location_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  min_qty?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  production_order_lines?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
  supplier_product?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
  supplier_product_id?: Maybe<Int_Comparison_Exp>;
  supplier_quote_reference?: Maybe<String_Comparison_Exp>;
  unit_cost?: Maybe<Numeric_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
  valid_from?: Maybe<Timestamptz_Comparison_Exp>;
  valid_to?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inventory.supplier_quote" */
export enum Inventory_Supplier_Quote_Constraint {
  /** unique or primary key constraint */
  SupplierQuotePkey = "supplier_quote_pkey",
}

/** input type for incrementing integer column in table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Inc_Input = {
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  supplier_product_id?: Maybe<Scalars["Int"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Insert_Input = {
  currency?: Maybe<Inventory_Currency_Obj_Rel_Insert_Input>;
  currency_code?: Maybe<Scalars["String"]>;
  folder?: Maybe<Inventory_Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Inventory_Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  production_order_lines?: Maybe<Inventory_Production_Order_Line_Arr_Rel_Insert_Input>;
  supplier_product?: Maybe<Inventory_Supplier_Product_Obj_Rel_Insert_Input>;
  supplier_product_id?: Maybe<Scalars["Int"]>;
  supplier_quote_reference?: Maybe<Scalars["String"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_to?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inventory_Supplier_Quote_Max_Fields = {
  __typename?: "inventory_supplier_quote_max_fields";
  currency_code?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  supplier_product_id?: Maybe<Scalars["Int"]>;
  supplier_quote_reference?: Maybe<Scalars["String"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_to?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Max_Order_By = {
  currency_code?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  supplier_quote_reference?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  valid_from?: Maybe<Order_By>;
  valid_to?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inventory_Supplier_Quote_Min_Fields = {
  __typename?: "inventory_supplier_quote_min_fields";
  currency_code?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  supplier_product_id?: Maybe<Scalars["Int"]>;
  supplier_quote_reference?: Maybe<Scalars["String"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_to?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Min_Order_By = {
  currency_code?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  supplier_quote_reference?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  valid_from?: Maybe<Order_By>;
  valid_to?: Maybe<Order_By>;
};

/** response of any mutation on the table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Mutation_Response = {
  __typename?: "inventory_supplier_quote_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Inventory_Supplier_Quote>;
};

/** input type for inserting object relation for remote table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Obj_Rel_Insert_Input = {
  data: Inventory_Supplier_Quote_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Quote_On_Conflict>;
};

/** on conflict condition type for table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_On_Conflict = {
  constraint: Inventory_Supplier_Quote_Constraint;
  update_columns: Array<Inventory_Supplier_Quote_Update_Column>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** ordering options when selecting data from "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Order_By = {
  currency?: Maybe<Inventory_Currency_Order_By>;
  currency_code?: Maybe<Order_By>;
  folder?: Maybe<Inventory_Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Inventory_Location_Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  production_order_lines_aggregate?: Maybe<Inventory_Production_Order_Line_Aggregate_Order_By>;
  supplier_product?: Maybe<Inventory_Supplier_Product_Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  supplier_quote_reference?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  valid_from?: Maybe<Order_By>;
  valid_to?: Maybe<Order_By>;
};

/** primary key columns input for table: "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inventory.supplier_quote" */
export enum Inventory_Supplier_Quote_Select_Column {
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  MinQty = "min_qty",
  /** column name */
  Notes = "notes",
  /** column name */
  SupplierProductId = "supplier_product_id",
  /** column name */
  SupplierQuoteReference = "supplier_quote_reference",
  /** column name */
  UnitCost = "unit_cost",
  /** column name */
  UserId = "user_id",
  /** column name */
  ValidFrom = "valid_from",
  /** column name */
  ValidTo = "valid_to",
}

/** input type for updating data in table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Set_Input = {
  currency_code?: Maybe<Scalars["String"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  supplier_product_id?: Maybe<Scalars["Int"]>;
  supplier_quote_reference?: Maybe<Scalars["String"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
  user_id?: Maybe<Scalars["String"]>;
  valid_from?: Maybe<Scalars["timestamptz"]>;
  valid_to?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inventory_Supplier_Quote_Stddev_Fields = {
  __typename?: "inventory_supplier_quote_stddev_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  supplier_product_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Stddev_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inventory_Supplier_Quote_Stddev_Pop_Fields = {
  __typename?: "inventory_supplier_quote_stddev_pop_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  supplier_product_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Stddev_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inventory_Supplier_Quote_Stddev_Samp_Fields = {
  __typename?: "inventory_supplier_quote_stddev_samp_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  supplier_product_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Stddev_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Inventory_Supplier_Quote_Sum_Fields = {
  __typename?: "inventory_supplier_quote_sum_fields";
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  min_qty?: Maybe<Scalars["Int"]>;
  supplier_product_id?: Maybe<Scalars["Int"]>;
  unit_cost?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Sum_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** update columns of table "inventory.supplier_quote" */
export enum Inventory_Supplier_Quote_Update_Column {
  /** column name */
  CurrencyCode = "currency_code",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  MinQty = "min_qty",
  /** column name */
  Notes = "notes",
  /** column name */
  SupplierProductId = "supplier_product_id",
  /** column name */
  SupplierQuoteReference = "supplier_quote_reference",
  /** column name */
  UnitCost = "unit_cost",
  /** column name */
  UserId = "user_id",
  /** column name */
  ValidFrom = "valid_from",
  /** column name */
  ValidTo = "valid_to",
}

/** aggregate var_pop on columns */
export type Inventory_Supplier_Quote_Var_Pop_Fields = {
  __typename?: "inventory_supplier_quote_var_pop_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  supplier_product_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Var_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inventory_Supplier_Quote_Var_Samp_Fields = {
  __typename?: "inventory_supplier_quote_var_samp_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  supplier_product_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Var_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inventory_Supplier_Quote_Variance_Fields = {
  __typename?: "inventory_supplier_quote_variance_fields";
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  min_qty?: Maybe<Scalars["Float"]>;
  supplier_product_id?: Maybe<Scalars["Float"]>;
  unit_cost?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inventory.supplier_quote" */
export type Inventory_Supplier_Quote_Variance_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  min_qty?: Maybe<Order_By>;
  supplier_product_id?: Maybe<Order_By>;
  unit_cost?: Maybe<Order_By>;
};

/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars["jsonb"]>;
  _eq?: Maybe<Scalars["jsonb"]>;
  _gt?: Maybe<Scalars["jsonb"]>;
  _gte?: Maybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Scalars["jsonb"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["jsonb"]>;
  _lte?: Maybe<Scalars["jsonb"]>;
  _neq?: Maybe<Scalars["jsonb"]>;
  _nin?: Maybe<Array<Scalars["jsonb"]>>;
};

/** columns and relationships of "marketplace_client" */
export type Marketplace_Client = {
  __typename?: "marketplace_client";
  id: Scalars["Int"];
  name: Scalars["String"];
};

/** aggregated selection of "marketplace_client" */
export type Marketplace_Client_Aggregate = {
  __typename?: "marketplace_client_aggregate";
  aggregate?: Maybe<Marketplace_Client_Aggregate_Fields>;
  nodes: Array<Marketplace_Client>;
};

/** aggregate fields of "marketplace_client" */
export type Marketplace_Client_Aggregate_Fields = {
  __typename?: "marketplace_client_aggregate_fields";
  avg?: Maybe<Marketplace_Client_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Client_Max_Fields>;
  min?: Maybe<Marketplace_Client_Min_Fields>;
  stddev?: Maybe<Marketplace_Client_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Client_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Client_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Client_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Client_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Client_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Client_Variance_Fields>;
};

/** aggregate fields of "marketplace_client" */
export type Marketplace_Client_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Client_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_client" */
export type Marketplace_Client_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Client_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Client_Max_Order_By>;
  min?: Maybe<Marketplace_Client_Min_Order_By>;
  stddev?: Maybe<Marketplace_Client_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Client_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Client_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Client_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Client_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Client_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Client_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_client" */
export type Marketplace_Client_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Client_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Client_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Client_Avg_Fields = {
  __typename?: "marketplace_client_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_client" */
export type Marketplace_Client_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_client". All fields are combined with a logical 'AND'. */
export type Marketplace_Client_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Client_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Client_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Client_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_client" */
export enum Marketplace_Client_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceClient = "PK_marketplace_client",
}

/** input type for incrementing integer column in table "marketplace_client" */
export type Marketplace_Client_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_client" */
export type Marketplace_Client_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Marketplace_Client_Max_Fields = {
  __typename?: "marketplace_client_max_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "marketplace_client" */
export type Marketplace_Client_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Client_Min_Fields = {
  __typename?: "marketplace_client_min_fields";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "marketplace_client" */
export type Marketplace_Client_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_client" */
export type Marketplace_Client_Mutation_Response = {
  __typename?: "marketplace_client_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Client>;
};

/** input type for inserting object relation for remote table "marketplace_client" */
export type Marketplace_Client_Obj_Rel_Insert_Input = {
  data: Marketplace_Client_Insert_Input;
  on_conflict?: Maybe<Marketplace_Client_On_Conflict>;
};

/** on conflict condition type for table "marketplace_client" */
export type Marketplace_Client_On_Conflict = {
  constraint: Marketplace_Client_Constraint;
  update_columns: Array<Marketplace_Client_Update_Column>;
  where?: Maybe<Marketplace_Client_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_client" */
export type Marketplace_Client_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_client" */
export type Marketplace_Client_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_client" */
export enum Marketplace_Client_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** input type for updating data in table "marketplace_client" */
export type Marketplace_Client_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Client_Stddev_Fields = {
  __typename?: "marketplace_client_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_client" */
export type Marketplace_Client_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Client_Stddev_Pop_Fields = {
  __typename?: "marketplace_client_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_client" */
export type Marketplace_Client_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Client_Stddev_Samp_Fields = {
  __typename?: "marketplace_client_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_client" */
export type Marketplace_Client_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Client_Sum_Fields = {
  __typename?: "marketplace_client_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_client" */
export type Marketplace_Client_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_client" */
export enum Marketplace_Client_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
}

/** aggregate var_pop on columns */
export type Marketplace_Client_Var_Pop_Fields = {
  __typename?: "marketplace_client_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_client" */
export type Marketplace_Client_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Client_Var_Samp_Fields = {
  __typename?: "marketplace_client_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_client" */
export type Marketplace_Client_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Client_Variance_Fields = {
  __typename?: "marketplace_client_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_client" */
export type Marketplace_Client_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed";
  date_key: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  marketplace_merchant: Marketplace_Merchants;
  /** An object relationship */
  marketplace_unit_sku: Marketplace_Unit_Sku;
  merchant_id: Scalars["Int"];
  quantity: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** aggregated selection of "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_aggregate";
  aggregate?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate_Fields>;
  nodes: Array<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
};

/** aggregate fields of "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_aggregate_fields";
  avg?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Max_Fields>;
  min?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Min_Fields>;
  stddev?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Variance_Fields>;
};

/** aggregate fields of "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Max_Order_By>;
  min?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Min_Order_By>;
  stddev?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Avg_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_avg_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Avg_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_data_collector_merchant_sales_transformed". All fields are combined with a logical 'AND'. */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>>>;
  date_key?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Bool_Exp>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_data_collector_merchant_sales_transformed" */
export enum Marketplace_Data_Collector_Merchant_Sales_Transformed_Constraint {
  /** unique or primary key constraint */
  MarketplaceDataCollectorMerchantSalesTransformedPkey = "marketplace_data_collector_merchant_sales_transformed_pkey",
  /** unique or primary key constraint */
  PkMarketplaceMerchantDateUnitSkuIdUnique = "pk_marketplace_merchant_date_unit_sku_id_unique",
}

/** input type for incrementing integer column in table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Inc_Input = {
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Insert_Input = {
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Obj_Rel_Insert_Input>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Obj_Rel_Insert_Input>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Max_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_max_fields";
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Max_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Min_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_min_fields";
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Min_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Mutation_Response = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
};

/** input type for inserting object relation for remote table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Obj_Rel_Insert_Input = {
  data: Marketplace_Data_Collector_Merchant_Sales_Transformed_Insert_Input;
  on_conflict?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_On_Conflict>;
};

/** on conflict condition type for table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_On_Conflict = {
  constraint: Marketplace_Data_Collector_Merchant_Sales_Transformed_Constraint;
  update_columns: Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Update_Column>;
  where?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Order_By>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_data_collector_merchant_sales_transformed" */
export enum Marketplace_Data_Collector_Merchant_Sales_Transformed_Select_Column {
  /** column name */
  DateKey = "date_key",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** input type for updating data in table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Set_Input = {
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_stddev_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Pop_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_stddev_pop_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Pop_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Samp_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_stddev_samp_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Stddev_Samp_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Sum_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_sum_fields";
  date_key?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Sum_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_data_collector_merchant_sales_transformed" */
export enum Marketplace_Data_Collector_Merchant_Sales_Transformed_Update_Column {
  /** column name */
  DateKey = "date_key",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** aggregate var_pop on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Var_Pop_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_var_pop_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Var_Pop_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Var_Samp_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_var_samp_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Var_Samp_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Variance_Fields = {
  __typename?: "marketplace_data_collector_merchant_sales_transformed_variance_fields";
  date_key?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_data_collector_merchant_sales_transformed" */
export type Marketplace_Data_Collector_Merchant_Sales_Transformed_Variance_Order_By = {
  date_key?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_master_sku" */
export type Marketplace_Master_Sku = {
  __typename?: "marketplace_master_sku";
  client_id: Scalars["Int"];
  id: Scalars["Int"];
  price_ex_vat_gbp: Scalars["numeric"];
  title: Scalars["String"];
};

/** aggregated selection of "marketplace_master_sku" */
export type Marketplace_Master_Sku_Aggregate = {
  __typename?: "marketplace_master_sku_aggregate";
  aggregate?: Maybe<Marketplace_Master_Sku_Aggregate_Fields>;
  nodes: Array<Marketplace_Master_Sku>;
};

/** aggregate fields of "marketplace_master_sku" */
export type Marketplace_Master_Sku_Aggregate_Fields = {
  __typename?: "marketplace_master_sku_aggregate_fields";
  avg?: Maybe<Marketplace_Master_Sku_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Master_Sku_Max_Fields>;
  min?: Maybe<Marketplace_Master_Sku_Min_Fields>;
  stddev?: Maybe<Marketplace_Master_Sku_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Master_Sku_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Master_Sku_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Master_Sku_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Master_Sku_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Master_Sku_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Master_Sku_Variance_Fields>;
};

/** aggregate fields of "marketplace_master_sku" */
export type Marketplace_Master_Sku_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Master_Sku_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Master_Sku_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Master_Sku_Max_Order_By>;
  min?: Maybe<Marketplace_Master_Sku_Min_Order_By>;
  stddev?: Maybe<Marketplace_Master_Sku_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Master_Sku_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Master_Sku_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Master_Sku_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Master_Sku_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Master_Sku_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Master_Sku_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Master_Sku_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Master_Sku_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Master_Sku_Avg_Fields = {
  __typename?: "marketplace_master_sku_avg_fields";
  client_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  price_ex_vat_gbp?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Avg_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_master_sku". All fields are combined with a logical 'AND'. */
export type Marketplace_Master_Sku_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Master_Sku_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Master_Sku_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Master_Sku_Bool_Exp>>>;
  client_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  price_ex_vat_gbp?: Maybe<Numeric_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_master_sku" */
export enum Marketplace_Master_Sku_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMasterSku = "PK_marketplace_master_sku",
}

/** input type for incrementing integer column in table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Inc_Input = {
  client_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  price_ex_vat_gbp?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Insert_Input = {
  client_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  price_ex_vat_gbp?: Maybe<Scalars["numeric"]>;
  title?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku";
  marketplace_master_sku_id: Scalars["Int"];
  marketplace_unit_sku_id: Scalars["Int"];
  unit_sku_counts?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Aggregate = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_aggregate";
  aggregate?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Aggregate_Fields>;
  nodes: Array<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
};

/** aggregate fields of "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Aggregate_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_aggregate_fields";
  avg?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Max_Fields>;
  min?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Min_Fields>;
  stddev?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Variance_Fields>;
};

/** aggregate fields of "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Max_Order_By>;
  min?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Min_Order_By>;
  stddev?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Avg_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_avg_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Float"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Float"]>;
  unit_sku_counts?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Avg_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_master_sku_marketplace_unit_sku". All fields are combined with a logical 'AND'. */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp>>>;
  marketplace_master_sku_id?: Maybe<Int_Comparison_Exp>;
  marketplace_unit_sku_id?: Maybe<Int_Comparison_Exp>;
  unit_sku_counts?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_master_sku_marketplace_unit_sku" */
export enum Marketplace_Master_Sku_Marketplace_Unit_Sku_Constraint {
  /** unique or primary key constraint */
  MarketplaceMasterSkuIdMarketplaceUnitSkuIdPkey = "marketplace_master_sku_id_marketplace_unit_sku_id_pkey",
}

/** input type for incrementing integer column in table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Inc_Input = {
  marketplace_master_sku_id?: Maybe<Scalars["Int"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Int"]>;
  unit_sku_counts?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Insert_Input = {
  marketplace_master_sku_id?: Maybe<Scalars["Int"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Int"]>;
  unit_sku_counts?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Max_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_max_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Int"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Int"]>;
  unit_sku_counts?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Max_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Min_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_min_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Int"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Int"]>;
  unit_sku_counts?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Min_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Mutation_Response = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
};

/** input type for inserting object relation for remote table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Obj_Rel_Insert_Input = {
  data: Marketplace_Master_Sku_Marketplace_Unit_Sku_Insert_Input;
  on_conflict?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_On_Conflict>;
};

/** on conflict condition type for table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_On_Conflict = {
  constraint: Marketplace_Master_Sku_Marketplace_Unit_Sku_Constraint;
  update_columns: Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Update_Column>;
  where?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Pk_Columns_Input = {
  marketplace_master_sku_id: Scalars["Int"];
  marketplace_unit_sku_id: Scalars["Int"];
};

/** select columns of table "marketplace_master_sku_marketplace_unit_sku" */
export enum Marketplace_Master_Sku_Marketplace_Unit_Sku_Select_Column {
  /** column name */
  MarketplaceMasterSkuId = "marketplace_master_sku_id",
  /** column name */
  MarketplaceUnitSkuId = "marketplace_unit_sku_id",
  /** column name */
  UnitSkuCounts = "unit_sku_counts",
}

/** input type for updating data in table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Set_Input = {
  marketplace_master_sku_id?: Maybe<Scalars["Int"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Int"]>;
  unit_sku_counts?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_stddev_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Float"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Float"]>;
  unit_sku_counts?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Pop_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_stddev_pop_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Float"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Float"]>;
  unit_sku_counts?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Pop_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Samp_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_stddev_samp_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Float"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Float"]>;
  unit_sku_counts?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Stddev_Samp_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Sum_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_sum_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Int"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Int"]>;
  unit_sku_counts?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Sum_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** update columns of table "marketplace_master_sku_marketplace_unit_sku" */
export enum Marketplace_Master_Sku_Marketplace_Unit_Sku_Update_Column {
  /** column name */
  MarketplaceMasterSkuId = "marketplace_master_sku_id",
  /** column name */
  MarketplaceUnitSkuId = "marketplace_unit_sku_id",
  /** column name */
  UnitSkuCounts = "unit_sku_counts",
}

/** aggregate var_pop on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Var_Pop_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_var_pop_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Float"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Float"]>;
  unit_sku_counts?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Var_Pop_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Var_Samp_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_var_samp_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Float"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Float"]>;
  unit_sku_counts?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Var_Samp_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Variance_Fields = {
  __typename?: "marketplace_master_sku_marketplace_unit_sku_variance_fields";
  marketplace_master_sku_id?: Maybe<Scalars["Float"]>;
  marketplace_unit_sku_id?: Maybe<Scalars["Float"]>;
  unit_sku_counts?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_master_sku_marketplace_unit_sku" */
export type Marketplace_Master_Sku_Marketplace_Unit_Sku_Variance_Order_By = {
  marketplace_master_sku_id?: Maybe<Order_By>;
  marketplace_unit_sku_id?: Maybe<Order_By>;
  unit_sku_counts?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Marketplace_Master_Sku_Max_Fields = {
  __typename?: "marketplace_master_sku_max_fields";
  client_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  price_ex_vat_gbp?: Maybe<Scalars["numeric"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Max_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Master_Sku_Min_Fields = {
  __typename?: "marketplace_master_sku_min_fields";
  client_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  price_ex_vat_gbp?: Maybe<Scalars["numeric"]>;
  title?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Min_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Mutation_Response = {
  __typename?: "marketplace_master_sku_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Master_Sku>;
};

/** input type for inserting object relation for remote table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Obj_Rel_Insert_Input = {
  data: Marketplace_Master_Sku_Insert_Input;
  on_conflict?: Maybe<Marketplace_Master_Sku_On_Conflict>;
};

/** on conflict condition type for table "marketplace_master_sku" */
export type Marketplace_Master_Sku_On_Conflict = {
  constraint: Marketplace_Master_Sku_Constraint;
  update_columns: Array<Marketplace_Master_Sku_Update_Column>;
  where?: Maybe<Marketplace_Master_Sku_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_master_sku" */
export type Marketplace_Master_Sku_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_master_sku" */
export type Marketplace_Master_Sku_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_master_sku" */
export enum Marketplace_Master_Sku_Select_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  Id = "id",
  /** column name */
  PriceExVatGbp = "price_ex_vat_gbp",
  /** column name */
  Title = "title",
}

/** input type for updating data in table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Set_Input = {
  client_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  price_ex_vat_gbp?: Maybe<Scalars["numeric"]>;
  title?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Master_Sku_Stddev_Fields = {
  __typename?: "marketplace_master_sku_stddev_fields";
  client_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  price_ex_vat_gbp?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Stddev_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Master_Sku_Stddev_Pop_Fields = {
  __typename?: "marketplace_master_sku_stddev_pop_fields";
  client_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  price_ex_vat_gbp?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Stddev_Pop_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Master_Sku_Stddev_Samp_Fields = {
  __typename?: "marketplace_master_sku_stddev_samp_fields";
  client_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  price_ex_vat_gbp?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Stddev_Samp_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Master_Sku_Sum_Fields = {
  __typename?: "marketplace_master_sku_sum_fields";
  client_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  price_ex_vat_gbp?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Sum_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
};

/** update columns of table "marketplace_master_sku" */
export enum Marketplace_Master_Sku_Update_Column {
  /** column name */
  ClientId = "client_id",
  /** column name */
  Id = "id",
  /** column name */
  PriceExVatGbp = "price_ex_vat_gbp",
  /** column name */
  Title = "title",
}

/** aggregate var_pop on columns */
export type Marketplace_Master_Sku_Var_Pop_Fields = {
  __typename?: "marketplace_master_sku_var_pop_fields";
  client_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  price_ex_vat_gbp?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Var_Pop_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Master_Sku_Var_Samp_Fields = {
  __typename?: "marketplace_master_sku_var_samp_fields";
  client_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  price_ex_vat_gbp?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Var_Samp_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Master_Sku_Variance_Fields = {
  __typename?: "marketplace_master_sku_variance_fields";
  client_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  price_ex_vat_gbp?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_master_sku" */
export type Marketplace_Master_Sku_Variance_Order_By = {
  client_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_ex_vat_gbp?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_merchants" */
export type Marketplace_Merchants = {
  __typename?: "marketplace_merchants";
  addressline1: Scalars["String"];
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  client_id: Scalars["Int"];
  country_code: Scalars["String"];
  county?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  external_id: Scalars["Int"];
  first_name: Scalars["String"];
  id: Scalars["Int"];
  /** An array relationship */
  marketplace_data_collector_merchant_sales_transformeds: Array<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
  /** An aggregated array relationship */
  marketplace_data_collector_merchant_sales_transformeds_aggregate: Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate;
  name: Scalars["String"];
  phone_number: Scalars["String"];
  postcode: Scalars["String"];
  second_name: Scalars["String"];
  status: Scalars["String"];
};

/** columns and relationships of "marketplace_merchants" */
export type Marketplace_MerchantsMarketplace_Data_Collector_Merchant_Sales_TransformedsArgs = {
  distinct_on?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Order_By>>;
  where?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
};

/** columns and relationships of "marketplace_merchants" */
export type Marketplace_MerchantsMarketplace_Data_Collector_Merchant_Sales_Transformeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Order_By>>;
  where?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
};

/** aggregated selection of "marketplace_merchants" */
export type Marketplace_Merchants_Aggregate = {
  __typename?: "marketplace_merchants_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants>;
};

/** aggregate fields of "marketplace_merchants" */
export type Marketplace_Merchants_Aggregate_Fields = {
  __typename?: "marketplace_merchants_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants" */
export type Marketplace_Merchants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants" */
export type Marketplace_Merchants_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants" */
export type Marketplace_Merchants_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Avg_Fields = {
  __typename?: "marketplace_merchants_avg_fields";
  client_id?: Maybe<Scalars["Float"]>;
  external_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Avg_Order_By = {
  client_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Bool_Exp>>>;
  addressline1?: Maybe<String_Comparison_Exp>;
  addressline2?: Maybe<String_Comparison_Exp>;
  addressline3?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  client_id?: Maybe<Int_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  county?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  external_id?: Maybe<Int_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  marketplace_data_collector_merchant_sales_transformeds?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  second_name?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_merchants" */
export enum Marketplace_Merchants_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMerchants_1 = "PK_marketplace_merchants_1",
  /** unique or primary key constraint */
  UniqueExternalKey = "unique_external_key",
}

/** columns and relationships of "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order = {
  __typename?: "marketplace_merchants_floomx_replens_order";
  arrival_date: Scalars["timestamptz"];
  created_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  order_id: Scalars["String"];
};

/** aggregated selection of "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Aggregate = {
  __typename?: "marketplace_merchants_floomx_replens_order_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Floomx_Replens_Order>;
};

/** aggregate fields of "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Aggregate_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Floomx_Replens_Order_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Avg_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_avg_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Avg_Order_By = {
  merchant_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_floomx_replens_order". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp>>>;
  arrival_date?: Maybe<Timestamptz_Comparison_Exp>;
  created_date?: Maybe<Timestamptz_Comparison_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  order_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_merchants_floomx_replens_order" */
export enum Marketplace_Merchants_Floomx_Replens_Order_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMerchantsFloomxReplensOrder = "PK_marketplace_merchants_floomx_replens_order",
}

/** input type for incrementing integer column in table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Inc_Input = {
  merchant_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Insert_Input = {
  arrival_date?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items = {
  __typename?: "marketplace_merchants_floomx_replens_order_items";
  id: Scalars["Int"];
  order_id: Scalars["String"];
  quantity: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** aggregated selection of "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Aggregate = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Floomx_Replens_Order_Items>;
};

/** aggregate fields of "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Aggregate_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Avg_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Avg_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_floomx_replens_order_items". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  order_id?: Maybe<String_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_merchants_floomx_replens_order_items" */
export enum Marketplace_Merchants_Floomx_Replens_Order_Items_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMerchantsFloomxReplensOrderItems = "PK_marketplace_merchants_floomx_replens_order_items",
}

/** input type for incrementing integer column in table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Max_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_max_fields";
  id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Max_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Min_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_min_fields";
  id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Min_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Mutation_Response = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Floomx_Replens_Order_Items>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Floomx_Replens_Order_Items_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_On_Conflict = {
  constraint: Marketplace_Merchants_Floomx_Replens_Order_Items_Constraint;
  update_columns: Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Order_By = {
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_merchants_floomx_replens_order_items" */
export enum Marketplace_Merchants_Floomx_Replens_Order_Items_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  OrderId = "order_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** input type for updating data in table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Sum_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Sum_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_merchants_floomx_replens_order_items" */
export enum Marketplace_Merchants_Floomx_Replens_Order_Items_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  OrderId = "order_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Variance_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_items_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants_floomx_replens_order_items" */
export type Marketplace_Merchants_Floomx_Replens_Order_Items_Variance_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Max_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_max_fields";
  arrival_date?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Max_Order_By = {
  arrival_date?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Min_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_min_fields";
  arrival_date?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Min_Order_By = {
  arrival_date?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Mutation_Response = {
  __typename?: "marketplace_merchants_floomx_replens_order_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Floomx_Replens_Order>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Floomx_Replens_Order_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_On_Conflict = {
  constraint: Marketplace_Merchants_Floomx_Replens_Order_Constraint;
  update_columns: Array<Marketplace_Merchants_Floomx_Replens_Order_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Order_By = {
  arrival_date?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Pk_Columns_Input = {
  order_id: Scalars["String"];
};

/** select columns of table "marketplace_merchants_floomx_replens_order" */
export enum Marketplace_Merchants_Floomx_Replens_Order_Select_Column {
  /** column name */
  ArrivalDate = "arrival_date",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  OrderId = "order_id",
}

/** input type for updating data in table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Set_Input = {
  arrival_date?: Maybe<Scalars["timestamptz"]>;
  created_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Stddev_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_stddev_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Stddev_Order_By = {
  merchant_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_stddev_pop_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Stddev_Pop_Order_By = {
  merchant_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_stddev_samp_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Stddev_Samp_Order_By = {
  merchant_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Sum_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_sum_fields";
  merchant_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Sum_Order_By = {
  merchant_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_merchants_floomx_replens_order" */
export enum Marketplace_Merchants_Floomx_Replens_Order_Update_Column {
  /** column name */
  ArrivalDate = "arrival_date",
  /** column name */
  CreatedDate = "created_date",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  OrderId = "order_id",
}

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_var_pop_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Var_Pop_Order_By = {
  merchant_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_var_samp_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Var_Samp_Order_By = {
  merchant_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Floomx_Replens_Order_Variance_Fields = {
  __typename?: "marketplace_merchants_floomx_replens_order_variance_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants_floomx_replens_order" */
export type Marketplace_Merchants_Floomx_Replens_Order_Variance_Order_By = {
  merchant_id?: Maybe<Order_By>;
};

/** input type for incrementing integer column in table "marketplace_merchants" */
export type Marketplace_Merchants_Inc_Input = {
  client_id?: Maybe<Scalars["Int"]>;
  external_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants" */
export type Marketplace_Merchants_Insert_Input = {
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  client_id?: Maybe<Scalars["Int"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  external_id?: Maybe<Scalars["Int"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  marketplace_data_collector_merchant_sales_transformeds?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  second_name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Max_Fields = {
  __typename?: "marketplace_merchants_max_fields";
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  client_id?: Maybe<Scalars["Int"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  external_id?: Maybe<Scalars["Int"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  second_name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Max_Order_By = {
  addressline1?: Maybe<Order_By>;
  addressline2?: Maybe<Order_By>;
  addressline3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  county?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  second_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Min_Fields = {
  __typename?: "marketplace_merchants_min_fields";
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  client_id?: Maybe<Scalars["Int"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  external_id?: Maybe<Scalars["Int"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  second_name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Min_Order_By = {
  addressline1?: Maybe<Order_By>;
  addressline2?: Maybe<Order_By>;
  addressline3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  county?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  second_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants" */
export type Marketplace_Merchants_Mutation_Response = {
  __typename?: "marketplace_merchants_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants>;
};

/** input type for inserting object relation for remote table "marketplace_merchants" */
export type Marketplace_Merchants_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants" */
export type Marketplace_Merchants_On_Conflict = {
  constraint: Marketplace_Merchants_Constraint;
  update_columns: Array<Marketplace_Merchants_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants" */
export type Marketplace_Merchants_Order_By = {
  addressline1?: Maybe<Order_By>;
  addressline2?: Maybe<Order_By>;
  addressline3?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  county?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marketplace_data_collector_merchant_sales_transformeds_aggregate?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  second_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_merchants" */
export type Marketplace_Merchants_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** columns and relationships of "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw = {
  __typename?: "marketplace_merchants_raw";
  address1: Scalars["String"];
  address2: Scalars["String"];
  batch_timestamp: Scalars["timestamp"];
  city: Scalars["String"];
  client_id: Scalars["Int"];
  country: Scalars["String"];
  email: Scalars["String"];
  flc_id: Scalars["Int"];
  florist_status: Scalars["String"];
  id: Scalars["Int"];
  phone: Scalars["String"];
  postalCode: Scalars["String"];
  recipient_full_name: Scalars["String"];
  region: Scalars["String"];
  title: Scalars["String"];
  updated_timestamp: Scalars["timestamp"];
};

/** aggregated selection of "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Aggregate = {
  __typename?: "marketplace_merchants_raw_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Raw_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Raw>;
};

/** aggregate fields of "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Aggregate_Fields = {
  __typename?: "marketplace_merchants_raw_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Raw_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Raw_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Raw_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Raw_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Raw_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Raw_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Raw_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Raw_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Raw_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Raw_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Raw_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Raw_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Raw_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Raw_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Raw_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Raw_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Raw_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Raw_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Raw_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Raw_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Raw_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Raw_Insert_Input>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Raw_Avg_Fields = {
  __typename?: "marketplace_merchants_raw_avg_fields";
  client_id?: Maybe<Scalars["Float"]>;
  flc_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Avg_Order_By = {
  client_id?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_raw". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Raw_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Raw_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Raw_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Raw_Bool_Exp>>>;
  address1?: Maybe<String_Comparison_Exp>;
  address2?: Maybe<String_Comparison_Exp>;
  batch_timestamp?: Maybe<Timestamp_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  client_id?: Maybe<Int_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  flc_id?: Maybe<Int_Comparison_Exp>;
  florist_status?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  postalCode?: Maybe<String_Comparison_Exp>;
  recipient_full_name?: Maybe<String_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_timestamp?: Maybe<Timestamp_Comparison_Exp>;
};

/** input type for incrementing integer column in table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Inc_Input = {
  client_id?: Maybe<Scalars["Int"]>;
  flc_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Insert_Input = {
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  batch_timestamp?: Maybe<Scalars["timestamp"]>;
  city?: Maybe<Scalars["String"]>;
  client_id?: Maybe<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  flc_id?: Maybe<Scalars["Int"]>;
  florist_status?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  phone?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  recipient_full_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updated_timestamp?: Maybe<Scalars["timestamp"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Raw_Max_Fields = {
  __typename?: "marketplace_merchants_raw_max_fields";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  batch_timestamp?: Maybe<Scalars["timestamp"]>;
  city?: Maybe<Scalars["String"]>;
  client_id?: Maybe<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  flc_id?: Maybe<Scalars["Int"]>;
  florist_status?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  phone?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  recipient_full_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updated_timestamp?: Maybe<Scalars["timestamp"]>;
};

/** order by max() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Max_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  batch_timestamp?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  florist_status?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  postalCode?: Maybe<Order_By>;
  recipient_full_name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_timestamp?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Raw_Min_Fields = {
  __typename?: "marketplace_merchants_raw_min_fields";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  batch_timestamp?: Maybe<Scalars["timestamp"]>;
  city?: Maybe<Scalars["String"]>;
  client_id?: Maybe<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  flc_id?: Maybe<Scalars["Int"]>;
  florist_status?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  phone?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  recipient_full_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updated_timestamp?: Maybe<Scalars["timestamp"]>;
};

/** order by min() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Min_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  batch_timestamp?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  florist_status?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  postalCode?: Maybe<Order_By>;
  recipient_full_name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_timestamp?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Mutation_Response = {
  __typename?: "marketplace_merchants_raw_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Raw>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Raw_Insert_Input;
};

/** ordering options when selecting data from "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Order_By = {
  address1?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  batch_timestamp?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  florist_status?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  postalCode?: Maybe<Order_By>;
  recipient_full_name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_timestamp?: Maybe<Order_By>;
};

/** select columns of table "marketplace_merchants_raw" */
export enum Marketplace_Merchants_Raw_Select_Column {
  /** column name */
  Address1 = "address1",
  /** column name */
  Address2 = "address2",
  /** column name */
  BatchTimestamp = "batch_timestamp",
  /** column name */
  City = "city",
  /** column name */
  ClientId = "client_id",
  /** column name */
  Country = "country",
  /** column name */
  Email = "email",
  /** column name */
  FlcId = "flc_id",
  /** column name */
  FloristStatus = "florist_status",
  /** column name */
  Id = "id",
  /** column name */
  Phone = "phone",
  /** column name */
  PostalCode = "postalCode",
  /** column name */
  RecipientFullName = "recipient_full_name",
  /** column name */
  Region = "region",
  /** column name */
  Title = "title",
  /** column name */
  UpdatedTimestamp = "updated_timestamp",
}

/** input type for updating data in table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Set_Input = {
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  batch_timestamp?: Maybe<Scalars["timestamp"]>;
  city?: Maybe<Scalars["String"]>;
  client_id?: Maybe<Scalars["Int"]>;
  country?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  flc_id?: Maybe<Scalars["Int"]>;
  florist_status?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  phone?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  recipient_full_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  updated_timestamp?: Maybe<Scalars["timestamp"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Raw_Stddev_Fields = {
  __typename?: "marketplace_merchants_raw_stddev_fields";
  client_id?: Maybe<Scalars["Float"]>;
  flc_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Stddev_Order_By = {
  client_id?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Raw_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_raw_stddev_pop_fields";
  client_id?: Maybe<Scalars["Float"]>;
  flc_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Stddev_Pop_Order_By = {
  client_id?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Raw_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_raw_stddev_samp_fields";
  client_id?: Maybe<Scalars["Float"]>;
  flc_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Stddev_Samp_Order_By = {
  client_id?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Raw_Sum_Fields = {
  __typename?: "marketplace_merchants_raw_sum_fields";
  client_id?: Maybe<Scalars["Int"]>;
  flc_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Sum_Order_By = {
  client_id?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Raw_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_raw_var_pop_fields";
  client_id?: Maybe<Scalars["Float"]>;
  flc_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Var_Pop_Order_By = {
  client_id?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Raw_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_raw_var_samp_fields";
  client_id?: Maybe<Scalars["Float"]>;
  flc_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Var_Samp_Order_By = {
  client_id?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Raw_Variance_Fields = {
  __typename?: "marketplace_merchants_raw_variance_fields";
  client_id?: Maybe<Scalars["Float"]>;
  flc_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants_raw" */
export type Marketplace_Merchants_Raw_Variance_Order_By = {
  client_id?: Maybe<Order_By>;
  flc_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens = {
  __typename?: "marketplace_merchants_replens";
  arrival_date: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An object relationship */
  marketplace_merchant: Marketplace_Merchants;
  /** An object relationship */
  marketplace_merchants_replens_order: Marketplace_Merchants_Replens_Order;
  /** An object relationship */
  marketplace_unit_sku: Marketplace_Unit_Sku;
  merchant_id: Scalars["Int"];
  quantity: Scalars["Int"];
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id: Scalars["Int"];
  update_date: Scalars["timestamptz"];
  update_id: Scalars["String"];
};

/** aggregated selection of "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Aggregate = {
  __typename?: "marketplace_merchants_replens_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Replens_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Replens>;
};

/** aggregate fields of "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Aggregate_Fields = {
  __typename?: "marketplace_merchants_replens_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Replens_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Replens_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Replens_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Replens_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Replens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Replens_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Replens_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Replens_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Replens_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Replens_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Replens_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Replens_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Replens_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Replens_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Replens_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Replens_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Replens_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Replens_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Replens_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Replens_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Replens_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Replens_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Replens_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Replens_Avg_Fields = {
  __typename?: "marketplace_merchants_replens_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Avg_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_replens". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Replens_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Replens_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Replens_Bool_Exp>>>;
  arrival_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Bool_Exp>;
  marketplace_merchants_replens_order?: Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  uid?: Maybe<String_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
  update_date?: Maybe<Timestamptz_Comparison_Exp>;
  update_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_merchants_replens" */
export enum Marketplace_Merchants_Replens_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMerchantsReplens = "PK_marketplace_merchants_replens",
}

/** input type for incrementing integer column in table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Insert_Input = {
  arrival_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Obj_Rel_Insert_Input>;
  marketplace_merchants_replens_order?: Maybe<Marketplace_Merchants_Replens_Order_Obj_Rel_Insert_Input>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Obj_Rel_Insert_Input>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
  update_date?: Maybe<Scalars["timestamptz"]>;
  update_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Replens_Max_Fields = {
  __typename?: "marketplace_merchants_replens_max_fields";
  arrival_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
  update_date?: Maybe<Scalars["timestamptz"]>;
  update_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Max_Order_By = {
  arrival_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
  update_date?: Maybe<Order_By>;
  update_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Replens_Min_Fields = {
  __typename?: "marketplace_merchants_replens_min_fields";
  arrival_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
  update_date?: Maybe<Scalars["timestamptz"]>;
  update_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Min_Order_By = {
  arrival_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
  update_date?: Maybe<Order_By>;
  update_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Mutation_Response = {
  __typename?: "marketplace_merchants_replens_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Replens>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Replens_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Replens_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_On_Conflict = {
  constraint: Marketplace_Merchants_Replens_Constraint;
  update_columns: Array<Marketplace_Merchants_Replens_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
};

/** columns and relationships of "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order = {
  __typename?: "marketplace_merchants_replens_order";
  courier: Scalars["String"];
  /** An array relationship */
  marketplace_merchants_replens: Array<Marketplace_Merchants_Replens>;
  /** An aggregated array relationship */
  marketplace_merchants_replens_aggregate: Marketplace_Merchants_Replens_Aggregate;
  /** An array relationship */
  marketplace_replenishment_emails: Array<Marketplace_Replenishment_Email>;
  /** An aggregated array relationship */
  marketplace_replenishment_emails_aggregate: Marketplace_Replenishment_Email_Aggregate;
  order_id: Scalars["String"];
  order_type: Scalars["String"];
  tracking_number?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_OrderMarketplace_Merchants_ReplensArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
};

/** columns and relationships of "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_OrderMarketplace_Merchants_Replens_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
};

/** columns and relationships of "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_OrderMarketplace_Replenishment_EmailsArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Email_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Email_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
};

/** columns and relationships of "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_OrderMarketplace_Replenishment_Emails_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Email_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Email_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
};

/** aggregated selection of "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Aggregate = {
  __typename?: "marketplace_merchants_replens_order_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Replens_Order_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Replens_Order>;
};

/** aggregate fields of "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Aggregate_Fields = {
  __typename?: "marketplace_merchants_replens_order_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Replens_Order_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Replens_Order_Min_Fields>;
};

/** aggregate fields of "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Replens_Order_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Replens_Order_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Replens_Order_Min_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Replens_Order_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Replens_Order_On_Conflict>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_replens_order". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Replens_Order_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>>>;
  courier?: Maybe<String_Comparison_Exp>;
  marketplace_merchants_replens?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
  marketplace_replenishment_emails?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
  order_id?: Maybe<String_Comparison_Exp>;
  order_type?: Maybe<String_Comparison_Exp>;
  tracking_number?: Maybe<String_Comparison_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Order_By = {
  arrival_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Order_By>;
  marketplace_merchants_replens_order?: Maybe<Marketplace_Merchants_Replens_Order_Order_By>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
  update_date?: Maybe<Order_By>;
  update_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "marketplace_merchants_replens_order" */
export enum Marketplace_Merchants_Replens_Order_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMerchantsReplensOrder = "PK_marketplace_merchants_replens_order",
}

/** input type for inserting data into table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Insert_Input = {
  courier?: Maybe<Scalars["String"]>;
  marketplace_merchants_replens?: Maybe<Marketplace_Merchants_Replens_Arr_Rel_Insert_Input>;
  marketplace_replenishment_emails?: Maybe<Marketplace_Replenishment_Email_Arr_Rel_Insert_Input>;
  order_id?: Maybe<Scalars["String"]>;
  order_type?: Maybe<Scalars["String"]>;
  tracking_number?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Replens_Order_Max_Fields = {
  __typename?: "marketplace_merchants_replens_order_max_fields";
  courier?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  order_type?: Maybe<Scalars["String"]>;
  tracking_number?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Max_Order_By = {
  courier?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  order_type?: Maybe<Order_By>;
  tracking_number?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Replens_Order_Min_Fields = {
  __typename?: "marketplace_merchants_replens_order_min_fields";
  courier?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  order_type?: Maybe<Scalars["String"]>;
  tracking_number?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Min_Order_By = {
  courier?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  order_type?: Maybe<Order_By>;
  tracking_number?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Mutation_Response = {
  __typename?: "marketplace_merchants_replens_order_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Replens_Order>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Replens_Order_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Replens_Order_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_On_Conflict = {
  constraint: Marketplace_Merchants_Replens_Order_Constraint;
  update_columns: Array<Marketplace_Merchants_Replens_Order_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Order_By = {
  courier?: Maybe<Order_By>;
  marketplace_merchants_replens_aggregate?: Maybe<Marketplace_Merchants_Replens_Aggregate_Order_By>;
  marketplace_replenishment_emails_aggregate?: Maybe<Marketplace_Replenishment_Email_Aggregate_Order_By>;
  order_id?: Maybe<Order_By>;
  order_type?: Maybe<Order_By>;
  tracking_number?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Pk_Columns_Input = {
  order_id: Scalars["String"];
};

/** select columns of table "marketplace_merchants_replens_order" */
export enum Marketplace_Merchants_Replens_Order_Select_Column {
  /** column name */
  Courier = "courier",
  /** column name */
  OrderId = "order_id",
  /** column name */
  OrderType = "order_type",
  /** column name */
  TrackingNumber = "tracking_number",
}

/** input type for updating data in table "marketplace_merchants_replens_order" */
export type Marketplace_Merchants_Replens_Order_Set_Input = {
  courier?: Maybe<Scalars["String"]>;
  order_id?: Maybe<Scalars["String"]>;
  order_type?: Maybe<Scalars["String"]>;
  tracking_number?: Maybe<Scalars["String"]>;
};

/** update columns of table "marketplace_merchants_replens_order" */
export enum Marketplace_Merchants_Replens_Order_Update_Column {
  /** column name */
  Courier = "courier",
  /** column name */
  OrderId = "order_id",
  /** column name */
  OrderType = "order_type",
  /** column name */
  TrackingNumber = "tracking_number",
}

/** primary key columns input for table: "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_merchants_replens" */
export enum Marketplace_Merchants_Replens_Select_Column {
  /** column name */
  ArrivalDate = "arrival_date",
  /** column name */
  Id = "id",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  Uid = "uid",
  /** column name */
  UnitSkuId = "unit_sku_id",
  /** column name */
  UpdateDate = "update_date",
  /** column name */
  UpdateId = "update_id",
}

/** input type for updating data in table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Set_Input = {
  arrival_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
  update_date?: Maybe<Scalars["timestamptz"]>;
  update_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Replens_Stddev_Fields = {
  __typename?: "marketplace_merchants_replens_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Replens_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_replens_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Replens_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_replens_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Replens_Sum_Fields = {
  __typename?: "marketplace_merchants_replens_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Sum_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_merchants_replens" */
export enum Marketplace_Merchants_Replens_Update_Column {
  /** column name */
  ArrivalDate = "arrival_date",
  /** column name */
  Id = "id",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  Uid = "uid",
  /** column name */
  UnitSkuId = "unit_sku_id",
  /** column name */
  UpdateDate = "update_date",
  /** column name */
  UpdateId = "update_id",
}

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Replens_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_replens_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Replens_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_replens_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Replens_Variance_Fields = {
  __typename?: "marketplace_merchants_replens_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants_replens" */
export type Marketplace_Merchants_Replens_Variance_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales = {
  __typename?: "marketplace_merchants_sales";
  id: Scalars["Int"];
  /** An object relationship */
  marketplace_merchant: Marketplace_Merchants;
  /** An object relationship */
  marketplace_unit_sku: Marketplace_Unit_Sku;
  merchant_id: Scalars["Int"];
  quantity: Scalars["Int"];
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id: Scalars["Int"];
  update_date: Scalars["timestamptz"];
  update_id: Scalars["String"];
};

/** aggregated selection of "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Aggregate = {
  __typename?: "marketplace_merchants_sales_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Sales_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Sales>;
};

/** aggregate fields of "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Aggregate_Fields = {
  __typename?: "marketplace_merchants_sales_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Sales_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Sales_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Sales_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Sales_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Sales_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Sales_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Sales_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Sales_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Sales_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Sales_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Sales_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Sales_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Sales_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Sales_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Sales_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Sales_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Sales_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Sales_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Sales_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Sales_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Sales_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Sales_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Sales_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Sales_Avg_Fields = {
  __typename?: "marketplace_merchants_sales_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Avg_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_sales". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Sales_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Sales_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Sales_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Sales_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Bool_Exp>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  uid?: Maybe<String_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
  update_date?: Maybe<Timestamptz_Comparison_Exp>;
  update_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_merchants_sales" */
export enum Marketplace_Merchants_Sales_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMerchantsSales = "PK_marketplace_merchants_sales",
}

/** input type for incrementing integer column in table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Obj_Rel_Insert_Input>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Obj_Rel_Insert_Input>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
  update_date?: Maybe<Scalars["timestamptz"]>;
  update_id?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Sales_Max_Fields = {
  __typename?: "marketplace_merchants_sales_max_fields";
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
  update_date?: Maybe<Scalars["timestamptz"]>;
  update_id?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Max_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
  update_date?: Maybe<Order_By>;
  update_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Sales_Min_Fields = {
  __typename?: "marketplace_merchants_sales_min_fields";
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
  update_date?: Maybe<Scalars["timestamptz"]>;
  update_id?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Min_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
  update_date?: Maybe<Order_By>;
  update_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Mutation_Response = {
  __typename?: "marketplace_merchants_sales_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Sales>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Sales_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Sales_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_On_Conflict = {
  constraint: Marketplace_Merchants_Sales_Constraint;
  update_columns: Array<Marketplace_Merchants_Sales_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Sales_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Order_By = {
  id?: Maybe<Order_By>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Order_By>;
  marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
  update_date?: Maybe<Order_By>;
  update_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_merchants_sales" */
export enum Marketplace_Merchants_Sales_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  Uid = "uid",
  /** column name */
  UnitSkuId = "unit_sku_id",
  /** column name */
  UpdateDate = "update_date",
  /** column name */
  UpdateId = "update_id",
}

/** input type for updating data in table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  uid?: Maybe<Scalars["String"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
  update_date?: Maybe<Scalars["timestamptz"]>;
  update_id?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Sales_Stddev_Fields = {
  __typename?: "marketplace_merchants_sales_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Sales_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_sales_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Sales_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_sales_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Sales_Sum_Fields = {
  __typename?: "marketplace_merchants_sales_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Sum_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_merchants_sales" */
export enum Marketplace_Merchants_Sales_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  Uid = "uid",
  /** column name */
  UnitSkuId = "unit_sku_id",
  /** column name */
  UpdateDate = "update_date",
  /** column name */
  UpdateId = "update_id",
}

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Sales_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_sales_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Sales_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_sales_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Sales_Variance_Fields = {
  __typename?: "marketplace_merchants_sales_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants_sales" */
export type Marketplace_Merchants_Sales_Variance_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** select columns of table "marketplace_merchants" */
export enum Marketplace_Merchants_Select_Column {
  /** column name */
  Addressline1 = "addressline1",
  /** column name */
  Addressline2 = "addressline2",
  /** column name */
  Addressline3 = "addressline3",
  /** column name */
  City = "city",
  /** column name */
  ClientId = "client_id",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  County = "county",
  /** column name */
  Email = "email",
  /** column name */
  ExternalId = "external_id",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Postcode = "postcode",
  /** column name */
  SecondName = "second_name",
  /** column name */
  Status = "status",
}

/** input type for updating data in table "marketplace_merchants" */
export type Marketplace_Merchants_Set_Input = {
  addressline1?: Maybe<Scalars["String"]>;
  addressline2?: Maybe<Scalars["String"]>;
  addressline3?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  client_id?: Maybe<Scalars["Int"]>;
  country_code?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  external_id?: Maybe<Scalars["Int"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  second_name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks = {
  __typename?: "marketplace_merchants_starting_stocks";
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  stocks: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** aggregated selection of "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Aggregate = {
  __typename?: "marketplace_merchants_starting_stocks_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Starting_Stocks_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Starting_Stocks>;
};

/** aggregate fields of "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Aggregate_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Starting_Stocks_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Starting_Stocks_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Starting_Stocks_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Starting_Stocks_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Starting_Stocks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Starting_Stocks_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Starting_Stocks_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Starting_Stocks_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Starting_Stocks_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Starting_Stocks_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Starting_Stocks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Starting_Stocks_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Starting_Stocks_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Starting_Stocks_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Starting_Stocks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Starting_Stocks_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Starting_Stocks_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Starting_Stocks_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Starting_Stocks_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Starting_Stocks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Starting_Stocks_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Starting_Stocks_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Starting_Stocks_Avg_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_avg_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Avg_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_starting_stocks". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Starting_Stocks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Starting_Stocks_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Starting_Stocks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Starting_Stocks_Bool_Exp>>>;
  create_date?: Maybe<Timestamptz_Comparison_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  stocks?: Maybe<Int_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_merchants_starting_stocks" */
export enum Marketplace_Merchants_Starting_Stocks_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMerchantsStartingStocks = "PK_marketplace_merchants_starting_stocks",
}

/** input type for incrementing integer column in table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Inc_Input = {
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Insert_Input = {
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Starting_Stocks_Max_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_max_fields";
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Max_Order_By = {
  create_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Starting_Stocks_Min_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_min_fields";
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Min_Order_By = {
  create_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Mutation_Response = {
  __typename?: "marketplace_merchants_starting_stocks_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Starting_Stocks>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Starting_Stocks_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Starting_Stocks_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_On_Conflict = {
  constraint: Marketplace_Merchants_Starting_Stocks_Constraint;
  update_columns: Array<Marketplace_Merchants_Starting_Stocks_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Starting_Stocks_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Order_By = {
  create_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Pk_Columns_Input = {
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** select columns of table "marketplace_merchants_starting_stocks" */
export enum Marketplace_Merchants_Starting_Stocks_Select_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Stocks = "stocks",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** input type for updating data in table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Set_Input = {
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Starting_Stocks_Stddev_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_stddev_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Stddev_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Starting_Stocks_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_stddev_pop_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Stddev_Pop_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Starting_Stocks_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_stddev_samp_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Stddev_Samp_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Starting_Stocks_Sum_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_sum_fields";
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Sum_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_merchants_starting_stocks" */
export enum Marketplace_Merchants_Starting_Stocks_Update_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Stocks = "stocks",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Starting_Stocks_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_var_pop_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Var_Pop_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Starting_Stocks_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_var_samp_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Var_Samp_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Starting_Stocks_Variance_Fields = {
  __typename?: "marketplace_merchants_starting_stocks_variance_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants_starting_stocks" */
export type Marketplace_Merchants_Starting_Stocks_Variance_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Stddev_Fields = {
  __typename?: "marketplace_merchants_stddev_fields";
  client_id?: Maybe<Scalars["Float"]>;
  external_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Stddev_Order_By = {
  client_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_stddev_pop_fields";
  client_id?: Maybe<Scalars["Float"]>;
  external_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Stddev_Pop_Order_By = {
  client_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_stddev_samp_fields";
  client_id?: Maybe<Scalars["Float"]>;
  external_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Stddev_Samp_Order_By = {
  client_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks = {
  __typename?: "marketplace_merchants_stocks";
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  stocks: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** aggregated selection of "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Aggregate = {
  __typename?: "marketplace_merchants_stocks_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Stocks_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Stocks>;
};

/** aggregate fields of "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Aggregate_Fields = {
  __typename?: "marketplace_merchants_stocks_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Stocks_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Stocks_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Stocks_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Stocks_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Stocks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Stocks_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Stocks_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Stocks_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Stocks_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Stocks_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Stocks_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Stocks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Stocks_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Stocks_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Stocks_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Stocks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Stocks_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Stocks_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Stocks_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Stocks_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Stocks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Stocks_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Stocks_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Stocks_Avg_Fields = {
  __typename?: "marketplace_merchants_stocks_avg_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Avg_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_stocks". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Stocks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Stocks_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Stocks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Stocks_Bool_Exp>>>;
  create_date?: Maybe<Timestamptz_Comparison_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  stocks?: Maybe<Int_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_merchants_stocks" */
export enum Marketplace_Merchants_Stocks_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceMerchantsStocks = "PK_marketplace_merchants_stocks",
}

/** input type for incrementing integer column in table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Inc_Input = {
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Insert_Input = {
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Stocks_Max_Fields = {
  __typename?: "marketplace_merchants_stocks_max_fields";
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Max_Order_By = {
  create_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Stocks_Min_Fields = {
  __typename?: "marketplace_merchants_stocks_min_fields";
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Min_Order_By = {
  create_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Mutation_Response = {
  __typename?: "marketplace_merchants_stocks_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Stocks>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Stocks_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Stocks_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_On_Conflict = {
  constraint: Marketplace_Merchants_Stocks_Constraint;
  update_columns: Array<Marketplace_Merchants_Stocks_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Stocks_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Order_By = {
  create_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Pk_Columns_Input = {
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** select columns of table "marketplace_merchants_stocks" */
export enum Marketplace_Merchants_Stocks_Select_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Stocks = "stocks",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** input type for updating data in table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Set_Input = {
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Stocks_Stddev_Fields = {
  __typename?: "marketplace_merchants_stocks_stddev_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Stddev_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Stocks_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_stocks_stddev_pop_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Stddev_Pop_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Stocks_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_stocks_stddev_samp_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Stddev_Samp_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Stocks_Sum_Fields = {
  __typename?: "marketplace_merchants_stocks_sum_fields";
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Sum_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_merchants_stocks" */
export enum Marketplace_Merchants_Stocks_Update_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Stocks = "stocks",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** columns and relationships of "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2 = {
  __typename?: "marketplace_merchants_stocks_v2";
  create_date: Scalars["date"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  stocks: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** aggregated selection of "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Aggregate = {
  __typename?: "marketplace_merchants_stocks_v2_aggregate";
  aggregate?: Maybe<Marketplace_Merchants_Stocks_V2_Aggregate_Fields>;
  nodes: Array<Marketplace_Merchants_Stocks_V2>;
};

/** aggregate fields of "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Aggregate_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_aggregate_fields";
  avg?: Maybe<Marketplace_Merchants_Stocks_V2_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Merchants_Stocks_V2_Max_Fields>;
  min?: Maybe<Marketplace_Merchants_Stocks_V2_Min_Fields>;
  stddev?: Maybe<Marketplace_Merchants_Stocks_V2_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Merchants_Stocks_V2_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Merchants_Stocks_V2_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Merchants_Stocks_V2_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Merchants_Stocks_V2_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Merchants_Stocks_V2_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Merchants_Stocks_V2_Variance_Fields>;
};

/** aggregate fields of "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Merchants_Stocks_V2_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Merchants_Stocks_V2_Max_Order_By>;
  min?: Maybe<Marketplace_Merchants_Stocks_V2_Min_Order_By>;
  stddev?: Maybe<Marketplace_Merchants_Stocks_V2_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Merchants_Stocks_V2_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Merchants_Stocks_V2_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Merchants_Stocks_V2_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Merchants_Stocks_V2_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Merchants_Stocks_V2_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Merchants_Stocks_V2_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Merchants_Stocks_V2_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Stocks_V2_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Merchants_Stocks_V2_Avg_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Avg_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_merchants_stocks_v2". All fields are combined with a logical 'AND'. */
export type Marketplace_Merchants_Stocks_V2_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Merchants_Stocks_V2_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Merchants_Stocks_V2_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Merchants_Stocks_V2_Bool_Exp>>>;
  create_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  stocks?: Maybe<Int_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_merchants_stocks_v2" */
export enum Marketplace_Merchants_Stocks_V2_Constraint {
  /** unique or primary key constraint */
  MarketplaceMerchantsStocksV2Pkey = "marketplace_merchants_stocks_v2_pkey",
  /** unique or primary key constraint */
  PkMarketplaceMerchantsStocksV2 = "pk_marketplace_merchants_stocks_v2",
}

/** input type for incrementing integer column in table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Insert_Input = {
  create_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Marketplace_Merchants_Stocks_V2_Max_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_max_fields";
  create_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Max_Order_By = {
  create_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Merchants_Stocks_V2_Min_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_min_fields";
  create_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Min_Order_By = {
  create_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Mutation_Response = {
  __typename?: "marketplace_merchants_stocks_v2_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Merchants_Stocks_V2>;
};

/** input type for inserting object relation for remote table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Obj_Rel_Insert_Input = {
  data: Marketplace_Merchants_Stocks_V2_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Stocks_V2_On_Conflict>;
};

/** on conflict condition type for table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_On_Conflict = {
  constraint: Marketplace_Merchants_Stocks_V2_Constraint;
  update_columns: Array<Marketplace_Merchants_Stocks_V2_Update_Column>;
  where?: Maybe<Marketplace_Merchants_Stocks_V2_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Order_By = {
  create_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_merchants_stocks_v2" */
export enum Marketplace_Merchants_Stocks_V2_Select_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Stocks = "stocks",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** input type for updating data in table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Set_Input = {
  create_date?: Maybe<Scalars["date"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Merchants_Stocks_V2_Stddev_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Merchants_Stocks_V2_Stddev_Pop_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Merchants_Stocks_V2_Stddev_Samp_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Stocks_V2_Sum_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  stocks?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Sum_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_merchants_stocks_v2" */
export enum Marketplace_Merchants_Stocks_V2_Update_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Stocks = "stocks",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Stocks_V2_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Stocks_V2_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Stocks_V2_Variance_Fields = {
  __typename?: "marketplace_merchants_stocks_v2_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants_stocks_v2" */
export type Marketplace_Merchants_Stocks_V2_Variance_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Stocks_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_stocks_var_pop_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Var_Pop_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Stocks_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_stocks_var_samp_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Var_Samp_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Stocks_Variance_Fields = {
  __typename?: "marketplace_merchants_stocks_variance_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  stocks?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants_stocks" */
export type Marketplace_Merchants_Stocks_Variance_Order_By = {
  merchant_id?: Maybe<Order_By>;
  stocks?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Merchants_Sum_Fields = {
  __typename?: "marketplace_merchants_sum_fields";
  client_id?: Maybe<Scalars["Int"]>;
  external_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Sum_Order_By = {
  client_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_merchants" */
export enum Marketplace_Merchants_Update_Column {
  /** column name */
  Addressline1 = "addressline1",
  /** column name */
  Addressline2 = "addressline2",
  /** column name */
  Addressline3 = "addressline3",
  /** column name */
  City = "city",
  /** column name */
  ClientId = "client_id",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  County = "county",
  /** column name */
  Email = "email",
  /** column name */
  ExternalId = "external_id",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Postcode = "postcode",
  /** column name */
  SecondName = "second_name",
  /** column name */
  Status = "status",
}

/** aggregate var_pop on columns */
export type Marketplace_Merchants_Var_Pop_Fields = {
  __typename?: "marketplace_merchants_var_pop_fields";
  client_id?: Maybe<Scalars["Float"]>;
  external_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Var_Pop_Order_By = {
  client_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Merchants_Var_Samp_Fields = {
  __typename?: "marketplace_merchants_var_samp_fields";
  client_id?: Maybe<Scalars["Float"]>;
  external_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Var_Samp_Order_By = {
  client_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Merchants_Variance_Fields = {
  __typename?: "marketplace_merchants_variance_fields";
  client_id?: Maybe<Scalars["Float"]>;
  external_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_merchants" */
export type Marketplace_Merchants_Variance_Order_By = {
  client_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email = {
  __typename?: "marketplace_replenishment_email";
  from_email: Scalars["String"];
  from_name: Scalars["String"];
  html_content: Scalars["String"];
  id: Scalars["Int"];
  order_id: Scalars["String"];
  plain_text_content: Scalars["String"];
  subject: Scalars["String"];
  to_email: Scalars["String"];
  to_name: Scalars["String"];
};

/** aggregated selection of "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Aggregate = {
  __typename?: "marketplace_replenishment_email_aggregate";
  aggregate?: Maybe<Marketplace_Replenishment_Email_Aggregate_Fields>;
  nodes: Array<Marketplace_Replenishment_Email>;
};

/** aggregate fields of "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Aggregate_Fields = {
  __typename?: "marketplace_replenishment_email_aggregate_fields";
  avg?: Maybe<Marketplace_Replenishment_Email_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Replenishment_Email_Max_Fields>;
  min?: Maybe<Marketplace_Replenishment_Email_Min_Fields>;
  stddev?: Maybe<Marketplace_Replenishment_Email_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Replenishment_Email_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Replenishment_Email_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Replenishment_Email_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Replenishment_Email_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Replenishment_Email_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Replenishment_Email_Variance_Fields>;
};

/** aggregate fields of "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Replenishment_Email_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Replenishment_Email_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Replenishment_Email_Max_Order_By>;
  min?: Maybe<Marketplace_Replenishment_Email_Min_Order_By>;
  stddev?: Maybe<Marketplace_Replenishment_Email_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Replenishment_Email_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Replenishment_Email_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Replenishment_Email_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Replenishment_Email_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Replenishment_Email_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Replenishment_Email_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Replenishment_Email_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Replenishment_Email_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Replenishment_Email_Avg_Fields = {
  __typename?: "marketplace_replenishment_email_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_replenishment_email". All fields are combined with a logical 'AND'. */
export type Marketplace_Replenishment_Email_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Replenishment_Email_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Replenishment_Email_Bool_Exp>>>;
  from_email?: Maybe<String_Comparison_Exp>;
  from_name?: Maybe<String_Comparison_Exp>;
  html_content?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order_id?: Maybe<String_Comparison_Exp>;
  plain_text_content?: Maybe<String_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  to_email?: Maybe<String_Comparison_Exp>;
  to_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_replenishment_email" */
export enum Marketplace_Replenishment_Email_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceReplenishmentEmail = "PK_marketplace_replenishment_email",
}

/** input type for incrementing integer column in table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Insert_Input = {
  from_email?: Maybe<Scalars["String"]>;
  from_name?: Maybe<Scalars["String"]>;
  html_content?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
  plain_text_content?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  to_email?: Maybe<Scalars["String"]>;
  to_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Marketplace_Replenishment_Email_Max_Fields = {
  __typename?: "marketplace_replenishment_email_max_fields";
  from_email?: Maybe<Scalars["String"]>;
  from_name?: Maybe<Scalars["String"]>;
  html_content?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
  plain_text_content?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  to_email?: Maybe<Scalars["String"]>;
  to_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Max_Order_By = {
  from_email?: Maybe<Order_By>;
  from_name?: Maybe<Order_By>;
  html_content?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  plain_text_content?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to_email?: Maybe<Order_By>;
  to_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Replenishment_Email_Min_Fields = {
  __typename?: "marketplace_replenishment_email_min_fields";
  from_email?: Maybe<Scalars["String"]>;
  from_name?: Maybe<Scalars["String"]>;
  html_content?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
  plain_text_content?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  to_email?: Maybe<Scalars["String"]>;
  to_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Min_Order_By = {
  from_email?: Maybe<Order_By>;
  from_name?: Maybe<Order_By>;
  html_content?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  plain_text_content?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to_email?: Maybe<Order_By>;
  to_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Mutation_Response = {
  __typename?: "marketplace_replenishment_email_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Replenishment_Email>;
};

/** input type for inserting object relation for remote table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Obj_Rel_Insert_Input = {
  data: Marketplace_Replenishment_Email_Insert_Input;
  on_conflict?: Maybe<Marketplace_Replenishment_Email_On_Conflict>;
};

/** on conflict condition type for table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_On_Conflict = {
  constraint: Marketplace_Replenishment_Email_Constraint;
  update_columns: Array<Marketplace_Replenishment_Email_Update_Column>;
  where?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Order_By = {
  from_email?: Maybe<Order_By>;
  from_name?: Maybe<Order_By>;
  html_content?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  plain_text_content?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to_email?: Maybe<Order_By>;
  to_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_replenishment_email" */
export enum Marketplace_Replenishment_Email_Select_Column {
  /** column name */
  FromEmail = "from_email",
  /** column name */
  FromName = "from_name",
  /** column name */
  HtmlContent = "html_content",
  /** column name */
  Id = "id",
  /** column name */
  OrderId = "order_id",
  /** column name */
  PlainTextContent = "plain_text_content",
  /** column name */
  Subject = "subject",
  /** column name */
  ToEmail = "to_email",
  /** column name */
  ToName = "to_name",
}

/** input type for updating data in table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Set_Input = {
  from_email?: Maybe<Scalars["String"]>;
  from_name?: Maybe<Scalars["String"]>;
  html_content?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  order_id?: Maybe<Scalars["String"]>;
  plain_text_content?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  to_email?: Maybe<Scalars["String"]>;
  to_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Replenishment_Email_Stddev_Fields = {
  __typename?: "marketplace_replenishment_email_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Replenishment_Email_Stddev_Pop_Fields = {
  __typename?: "marketplace_replenishment_email_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Replenishment_Email_Stddev_Samp_Fields = {
  __typename?: "marketplace_replenishment_email_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Replenishment_Email_Sum_Fields = {
  __typename?: "marketplace_replenishment_email_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_replenishment_email" */
export enum Marketplace_Replenishment_Email_Update_Column {
  /** column name */
  FromEmail = "from_email",
  /** column name */
  FromName = "from_name",
  /** column name */
  HtmlContent = "html_content",
  /** column name */
  Id = "id",
  /** column name */
  OrderId = "order_id",
  /** column name */
  PlainTextContent = "plain_text_content",
  /** column name */
  Subject = "subject",
  /** column name */
  ToEmail = "to_email",
  /** column name */
  ToName = "to_name",
}

/** aggregate var_pop on columns */
export type Marketplace_Replenishment_Email_Var_Pop_Fields = {
  __typename?: "marketplace_replenishment_email_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Replenishment_Email_Var_Samp_Fields = {
  __typename?: "marketplace_replenishment_email_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Replenishment_Email_Variance_Fields = {
  __typename?: "marketplace_replenishment_email_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_replenishment_email" */
export type Marketplace_Replenishment_Email_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions = {
  __typename?: "marketplace_replenishment_suggestions";
  create_date: Scalars["timestamptz"];
  /** An object relationship */
  marketplace_merchant: Marketplace_Merchants;
  merchant_id: Scalars["Int"];
  quantity: Scalars["Int"];
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id: Scalars["Int"];
};

/** aggregated selection of "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Aggregate = {
  __typename?: "marketplace_replenishment_suggestions_aggregate";
  aggregate?: Maybe<Marketplace_Replenishment_Suggestions_Aggregate_Fields>;
  nodes: Array<Marketplace_Replenishment_Suggestions>;
};

/** aggregate fields of "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Aggregate_Fields = {
  __typename?: "marketplace_replenishment_suggestions_aggregate_fields";
  avg?: Maybe<Marketplace_Replenishment_Suggestions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Replenishment_Suggestions_Max_Fields>;
  min?: Maybe<Marketplace_Replenishment_Suggestions_Min_Fields>;
  stddev?: Maybe<Marketplace_Replenishment_Suggestions_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Replenishment_Suggestions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Replenishment_Suggestions_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Replenishment_Suggestions_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Replenishment_Suggestions_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Replenishment_Suggestions_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Replenishment_Suggestions_Variance_Fields>;
};

/** aggregate fields of "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Replenishment_Suggestions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Replenishment_Suggestions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Replenishment_Suggestions_Max_Order_By>;
  min?: Maybe<Marketplace_Replenishment_Suggestions_Min_Order_By>;
  stddev?: Maybe<Marketplace_Replenishment_Suggestions_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Replenishment_Suggestions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Replenishment_Suggestions_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Replenishment_Suggestions_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Replenishment_Suggestions_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Replenishment_Suggestions_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Replenishment_Suggestions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Replenishment_Suggestions_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Replenishment_Suggestions_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Replenishment_Suggestions_Avg_Fields = {
  __typename?: "marketplace_replenishment_suggestions_avg_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Avg_Order_By = {
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_replenishment_suggestions". All fields are combined with a logical 'AND'. */
export type Marketplace_Replenishment_Suggestions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Replenishment_Suggestions_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Replenishment_Suggestions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Replenishment_Suggestions_Bool_Exp>>>;
  create_date?: Maybe<Timestamptz_Comparison_Exp>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Bool_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  reorder_point?: Maybe<Int_Comparison_Exp>;
  stock_level?: Maybe<Int_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_replenishment_suggestions" */
export enum Marketplace_Replenishment_Suggestions_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceReplenishmentSuggestions = "PK_marketplace_replenishment_suggestions",
}

/** input type for incrementing integer column in table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Inc_Input = {
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Insert_Input = {
  create_date?: Maybe<Scalars["timestamptz"]>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Obj_Rel_Insert_Input>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Marketplace_Replenishment_Suggestions_Max_Fields = {
  __typename?: "marketplace_replenishment_suggestions_max_fields";
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Max_Order_By = {
  create_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Replenishment_Suggestions_Min_Fields = {
  __typename?: "marketplace_replenishment_suggestions_min_fields";
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Min_Order_By = {
  create_date?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Mutation_Response = {
  __typename?: "marketplace_replenishment_suggestions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Replenishment_Suggestions>;
};

/** input type for inserting object relation for remote table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Obj_Rel_Insert_Input = {
  data: Marketplace_Replenishment_Suggestions_Insert_Input;
  on_conflict?: Maybe<Marketplace_Replenishment_Suggestions_On_Conflict>;
};

/** on conflict condition type for table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_On_Conflict = {
  constraint: Marketplace_Replenishment_Suggestions_Constraint;
  update_columns: Array<Marketplace_Replenishment_Suggestions_Update_Column>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Order_By = {
  create_date?: Maybe<Order_By>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Pk_Columns_Input = {
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** select columns of table "marketplace_replenishment_suggestions" */
export enum Marketplace_Replenishment_Suggestions_Select_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReorderPoint = "reorder_point",
  /** column name */
  StockLevel = "stock_level",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** input type for updating data in table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Set_Input = {
  create_date?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Replenishment_Suggestions_Stddev_Fields = {
  __typename?: "marketplace_replenishment_suggestions_stddev_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Stddev_Order_By = {
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Replenishment_Suggestions_Stddev_Pop_Fields = {
  __typename?: "marketplace_replenishment_suggestions_stddev_pop_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Stddev_Pop_Order_By = {
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Replenishment_Suggestions_Stddev_Samp_Fields = {
  __typename?: "marketplace_replenishment_suggestions_stddev_samp_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Stddev_Samp_Order_By = {
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Replenishment_Suggestions_Sum_Fields = {
  __typename?: "marketplace_replenishment_suggestions_sum_fields";
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Sum_Order_By = {
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_replenishment_suggestions" */
export enum Marketplace_Replenishment_Suggestions_Update_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReorderPoint = "reorder_point",
  /** column name */
  StockLevel = "stock_level",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** columns and relationships of "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2 = {
  __typename?: "marketplace_replenishment_suggestions_v2";
  create_date: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  marketplace_master_sku: Marketplace_Master_Sku;
  /** An object relationship */
  marketplace_merchant: Marketplace_Merchants;
  merchant_id: Scalars["Int"];
  quantity: Scalars["Int"];
  reorder_point: Scalars["Int"];
  stock_gap?: Maybe<Scalars["numeric"]>;
  stock_level: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** aggregated selection of "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Aggregate = {
  __typename?: "marketplace_replenishment_suggestions_v2_aggregate";
  aggregate?: Maybe<Marketplace_Replenishment_Suggestions_V2_Aggregate_Fields>;
  nodes: Array<Marketplace_Replenishment_Suggestions_V2>;
};

/** aggregate fields of "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Aggregate_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_aggregate_fields";
  avg?: Maybe<Marketplace_Replenishment_Suggestions_V2_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Replenishment_Suggestions_V2_Max_Fields>;
  min?: Maybe<Marketplace_Replenishment_Suggestions_V2_Min_Fields>;
  stddev?: Maybe<Marketplace_Replenishment_Suggestions_V2_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Replenishment_Suggestions_V2_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Replenishment_Suggestions_V2_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Replenishment_Suggestions_V2_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Replenishment_Suggestions_V2_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Replenishment_Suggestions_V2_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Replenishment_Suggestions_V2_Variance_Fields>;
};

/** aggregate fields of "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Replenishment_Suggestions_V2_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Replenishment_Suggestions_V2_Max_Order_By>;
  min?: Maybe<Marketplace_Replenishment_Suggestions_V2_Min_Order_By>;
  stddev?: Maybe<Marketplace_Replenishment_Suggestions_V2_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Replenishment_Suggestions_V2_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Replenishment_Suggestions_V2_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Replenishment_Suggestions_V2_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Replenishment_Suggestions_V2_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Replenishment_Suggestions_V2_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Replenishment_Suggestions_V2_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Replenishment_Suggestions_V2_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Replenishment_Suggestions_V2_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Replenishment_Suggestions_V2_Avg_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_gap?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Avg_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_replenishment_suggestions_v2". All fields are combined with a logical 'AND'. */
export type Marketplace_Replenishment_Suggestions_V2_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Replenishment_Suggestions_V2_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Replenishment_Suggestions_V2_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Replenishment_Suggestions_V2_Bool_Exp>>>;
  create_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  marketplace_master_sku?: Maybe<Marketplace_Master_Sku_Bool_Exp>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Bool_Exp>;
  merchant_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  reorder_point?: Maybe<Int_Comparison_Exp>;
  stock_gap?: Maybe<Numeric_Comparison_Exp>;
  stock_level?: Maybe<Int_Comparison_Exp>;
  unit_sku_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_replenishment_suggestions_v2" */
export enum Marketplace_Replenishment_Suggestions_V2_Constraint {
  /** unique or primary key constraint */
  MarketplaceReplenishmentSuggestionsV2Pkey = "marketplace_replenishment_suggestions_v2_pkey",
  /** unique or primary key constraint */
  PkMarketplaceReplenishmentSuggestionsV2 = "pk_marketplace_replenishment_suggestions_v2",
}

/** input type for incrementing integer column in table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_gap?: Maybe<Scalars["numeric"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Insert_Input = {
  create_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  marketplace_master_sku?: Maybe<Marketplace_Master_Sku_Obj_Rel_Insert_Input>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Obj_Rel_Insert_Input>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_gap?: Maybe<Scalars["numeric"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Marketplace_Replenishment_Suggestions_V2_Max_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_max_fields";
  create_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_gap?: Maybe<Scalars["numeric"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Max_Order_By = {
  create_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Replenishment_Suggestions_V2_Min_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_min_fields";
  create_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_gap?: Maybe<Scalars["numeric"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Min_Order_By = {
  create_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Mutation_Response = {
  __typename?: "marketplace_replenishment_suggestions_v2_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Replenishment_Suggestions_V2>;
};

/** input type for inserting object relation for remote table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Obj_Rel_Insert_Input = {
  data: Marketplace_Replenishment_Suggestions_V2_Insert_Input;
  on_conflict?: Maybe<Marketplace_Replenishment_Suggestions_V2_On_Conflict>;
};

/** on conflict condition type for table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_On_Conflict = {
  constraint: Marketplace_Replenishment_Suggestions_V2_Constraint;
  update_columns: Array<Marketplace_Replenishment_Suggestions_V2_Update_Column>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_V2_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Order_By = {
  create_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  marketplace_master_sku?: Maybe<Marketplace_Master_Sku_Order_By>;
  marketplace_merchant?: Maybe<Marketplace_Merchants_Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_replenishment_suggestions_v2" */
export enum Marketplace_Replenishment_Suggestions_V2_Select_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReorderPoint = "reorder_point",
  /** column name */
  StockGap = "stock_gap",
  /** column name */
  StockLevel = "stock_level",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** input type for updating data in table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Set_Input = {
  create_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_gap?: Maybe<Scalars["numeric"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Replenishment_Suggestions_V2_Stddev_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_gap?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Replenishment_Suggestions_V2_Stddev_Pop_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_gap?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Replenishment_Suggestions_V2_Stddev_Samp_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_gap?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Replenishment_Suggestions_V2_Sum_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  merchant_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reorder_point?: Maybe<Scalars["Int"]>;
  stock_gap?: Maybe<Scalars["numeric"]>;
  stock_level?: Maybe<Scalars["Int"]>;
  unit_sku_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Sum_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** update columns of table "marketplace_replenishment_suggestions_v2" */
export enum Marketplace_Replenishment_Suggestions_V2_Update_Column {
  /** column name */
  CreateDate = "create_date",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MerchantId = "merchant_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReorderPoint = "reorder_point",
  /** column name */
  StockGap = "stock_gap",
  /** column name */
  StockLevel = "stock_level",
  /** column name */
  UnitSkuId = "unit_sku_id",
}

/** aggregate var_pop on columns */
export type Marketplace_Replenishment_Suggestions_V2_Var_Pop_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_gap?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Replenishment_Suggestions_V2_Var_Samp_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_gap?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Replenishment_Suggestions_V2_Variance_Fields = {
  __typename?: "marketplace_replenishment_suggestions_v2_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_gap?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_replenishment_suggestions_v2" */
export type Marketplace_Replenishment_Suggestions_V2_Variance_Order_By = {
  id?: Maybe<Order_By>;
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_gap?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Marketplace_Replenishment_Suggestions_Var_Pop_Fields = {
  __typename?: "marketplace_replenishment_suggestions_var_pop_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Var_Pop_Order_By = {
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Replenishment_Suggestions_Var_Samp_Fields = {
  __typename?: "marketplace_replenishment_suggestions_var_samp_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Var_Samp_Order_By = {
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Replenishment_Suggestions_Variance_Fields = {
  __typename?: "marketplace_replenishment_suggestions_variance_fields";
  merchant_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reorder_point?: Maybe<Scalars["Float"]>;
  stock_level?: Maybe<Scalars["Float"]>;
  unit_sku_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_replenishment_suggestions" */
export type Marketplace_Replenishment_Suggestions_Variance_Order_By = {
  merchant_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reorder_point?: Maybe<Order_By>;
  stock_level?: Maybe<Order_By>;
  unit_sku_id?: Maybe<Order_By>;
};

/** columns and relationships of "marketplace_unit_sku" */
export type Marketplace_Unit_Sku = {
  __typename?: "marketplace_unit_sku";
  height_cm: Scalars["numeric"];
  id: Scalars["Int"];
  image_url: Scalars["String"];
  length_cm: Scalars["numeric"];
  title: Scalars["String"];
  unit_cost_gbp?: Maybe<Scalars["numeric"]>;
  weight_kg: Scalars["numeric"];
  width_cm: Scalars["numeric"];
};

/** aggregated selection of "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Aggregate = {
  __typename?: "marketplace_unit_sku_aggregate";
  aggregate?: Maybe<Marketplace_Unit_Sku_Aggregate_Fields>;
  nodes: Array<Marketplace_Unit_Sku>;
};

/** aggregate fields of "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Aggregate_Fields = {
  __typename?: "marketplace_unit_sku_aggregate_fields";
  avg?: Maybe<Marketplace_Unit_Sku_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Marketplace_Unit_Sku_Max_Fields>;
  min?: Maybe<Marketplace_Unit_Sku_Min_Fields>;
  stddev?: Maybe<Marketplace_Unit_Sku_Stddev_Fields>;
  stddev_pop?: Maybe<Marketplace_Unit_Sku_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marketplace_Unit_Sku_Stddev_Samp_Fields>;
  sum?: Maybe<Marketplace_Unit_Sku_Sum_Fields>;
  var_pop?: Maybe<Marketplace_Unit_Sku_Var_Pop_Fields>;
  var_samp?: Maybe<Marketplace_Unit_Sku_Var_Samp_Fields>;
  variance?: Maybe<Marketplace_Unit_Sku_Variance_Fields>;
};

/** aggregate fields of "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marketplace_Unit_Sku_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Aggregate_Order_By = {
  avg?: Maybe<Marketplace_Unit_Sku_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marketplace_Unit_Sku_Max_Order_By>;
  min?: Maybe<Marketplace_Unit_Sku_Min_Order_By>;
  stddev?: Maybe<Marketplace_Unit_Sku_Stddev_Order_By>;
  stddev_pop?: Maybe<Marketplace_Unit_Sku_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marketplace_Unit_Sku_Stddev_Samp_Order_By>;
  sum?: Maybe<Marketplace_Unit_Sku_Sum_Order_By>;
  var_pop?: Maybe<Marketplace_Unit_Sku_Var_Pop_Order_By>;
  var_samp?: Maybe<Marketplace_Unit_Sku_Var_Samp_Order_By>;
  variance?: Maybe<Marketplace_Unit_Sku_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Arr_Rel_Insert_Input = {
  data: Array<Marketplace_Unit_Sku_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Unit_Sku_On_Conflict>;
};

/** aggregate avg on columns */
export type Marketplace_Unit_Sku_Avg_Fields = {
  __typename?: "marketplace_unit_sku_avg_fields";
  height_cm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_cm?: Maybe<Scalars["Float"]>;
  unit_cost_gbp?: Maybe<Scalars["Float"]>;
  weight_kg?: Maybe<Scalars["Float"]>;
  width_cm?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Avg_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marketplace_unit_sku". All fields are combined with a logical 'AND'. */
export type Marketplace_Unit_Sku_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marketplace_Unit_Sku_Bool_Exp>>>;
  _not?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marketplace_Unit_Sku_Bool_Exp>>>;
  height_cm?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  length_cm?: Maybe<Numeric_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  unit_cost_gbp?: Maybe<Numeric_Comparison_Exp>;
  weight_kg?: Maybe<Numeric_Comparison_Exp>;
  width_cm?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "marketplace_unit_sku" */
export enum Marketplace_Unit_Sku_Constraint {
  /** unique or primary key constraint */
  PkMarketplaceUnitSku = "PK_marketplace_unit_sku",
}

/** input type for incrementing integer column in table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Inc_Input = {
  height_cm?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  length_cm?: Maybe<Scalars["numeric"]>;
  unit_cost_gbp?: Maybe<Scalars["numeric"]>;
  weight_kg?: Maybe<Scalars["numeric"]>;
  width_cm?: Maybe<Scalars["numeric"]>;
};

/** input type for inserting data into table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Insert_Input = {
  height_cm?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  length_cm?: Maybe<Scalars["numeric"]>;
  title?: Maybe<Scalars["String"]>;
  unit_cost_gbp?: Maybe<Scalars["numeric"]>;
  weight_kg?: Maybe<Scalars["numeric"]>;
  width_cm?: Maybe<Scalars["numeric"]>;
};

/** aggregate max on columns */
export type Marketplace_Unit_Sku_Max_Fields = {
  __typename?: "marketplace_unit_sku_max_fields";
  height_cm?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  length_cm?: Maybe<Scalars["numeric"]>;
  title?: Maybe<Scalars["String"]>;
  unit_cost_gbp?: Maybe<Scalars["numeric"]>;
  weight_kg?: Maybe<Scalars["numeric"]>;
  width_cm?: Maybe<Scalars["numeric"]>;
};

/** order by max() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Max_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marketplace_Unit_Sku_Min_Fields = {
  __typename?: "marketplace_unit_sku_min_fields";
  height_cm?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  length_cm?: Maybe<Scalars["numeric"]>;
  title?: Maybe<Scalars["String"]>;
  unit_cost_gbp?: Maybe<Scalars["numeric"]>;
  weight_kg?: Maybe<Scalars["numeric"]>;
  width_cm?: Maybe<Scalars["numeric"]>;
};

/** order by min() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Min_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** response of any mutation on the table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Mutation_Response = {
  __typename?: "marketplace_unit_sku_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Marketplace_Unit_Sku>;
};

/** input type for inserting object relation for remote table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Obj_Rel_Insert_Input = {
  data: Marketplace_Unit_Sku_Insert_Input;
  on_conflict?: Maybe<Marketplace_Unit_Sku_On_Conflict>;
};

/** on conflict condition type for table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_On_Conflict = {
  constraint: Marketplace_Unit_Sku_Constraint;
  update_columns: Array<Marketplace_Unit_Sku_Update_Column>;
  where?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
};

/** ordering options when selecting data from "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** primary key columns input for table: "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "marketplace_unit_sku" */
export enum Marketplace_Unit_Sku_Select_Column {
  /** column name */
  HeightCm = "height_cm",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  LengthCm = "length_cm",
  /** column name */
  Title = "title",
  /** column name */
  UnitCostGbp = "unit_cost_gbp",
  /** column name */
  WeightKg = "weight_kg",
  /** column name */
  WidthCm = "width_cm",
}

/** input type for updating data in table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Set_Input = {
  height_cm?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  length_cm?: Maybe<Scalars["numeric"]>;
  title?: Maybe<Scalars["String"]>;
  unit_cost_gbp?: Maybe<Scalars["numeric"]>;
  weight_kg?: Maybe<Scalars["numeric"]>;
  width_cm?: Maybe<Scalars["numeric"]>;
};

/** aggregate stddev on columns */
export type Marketplace_Unit_Sku_Stddev_Fields = {
  __typename?: "marketplace_unit_sku_stddev_fields";
  height_cm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_cm?: Maybe<Scalars["Float"]>;
  unit_cost_gbp?: Maybe<Scalars["Float"]>;
  weight_kg?: Maybe<Scalars["Float"]>;
  width_cm?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Stddev_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marketplace_Unit_Sku_Stddev_Pop_Fields = {
  __typename?: "marketplace_unit_sku_stddev_pop_fields";
  height_cm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_cm?: Maybe<Scalars["Float"]>;
  unit_cost_gbp?: Maybe<Scalars["Float"]>;
  weight_kg?: Maybe<Scalars["Float"]>;
  width_cm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Stddev_Pop_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marketplace_Unit_Sku_Stddev_Samp_Fields = {
  __typename?: "marketplace_unit_sku_stddev_samp_fields";
  height_cm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_cm?: Maybe<Scalars["Float"]>;
  unit_cost_gbp?: Maybe<Scalars["Float"]>;
  weight_kg?: Maybe<Scalars["Float"]>;
  width_cm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Stddev_Samp_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marketplace_Unit_Sku_Sum_Fields = {
  __typename?: "marketplace_unit_sku_sum_fields";
  height_cm?: Maybe<Scalars["numeric"]>;
  id?: Maybe<Scalars["Int"]>;
  length_cm?: Maybe<Scalars["numeric"]>;
  unit_cost_gbp?: Maybe<Scalars["numeric"]>;
  weight_kg?: Maybe<Scalars["numeric"]>;
  width_cm?: Maybe<Scalars["numeric"]>;
};

/** order by sum() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Sum_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** update columns of table "marketplace_unit_sku" */
export enum Marketplace_Unit_Sku_Update_Column {
  /** column name */
  HeightCm = "height_cm",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  LengthCm = "length_cm",
  /** column name */
  Title = "title",
  /** column name */
  UnitCostGbp = "unit_cost_gbp",
  /** column name */
  WeightKg = "weight_kg",
  /** column name */
  WidthCm = "width_cm",
}

/** aggregate var_pop on columns */
export type Marketplace_Unit_Sku_Var_Pop_Fields = {
  __typename?: "marketplace_unit_sku_var_pop_fields";
  height_cm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_cm?: Maybe<Scalars["Float"]>;
  unit_cost_gbp?: Maybe<Scalars["Float"]>;
  weight_kg?: Maybe<Scalars["Float"]>;
  width_cm?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Var_Pop_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marketplace_Unit_Sku_Var_Samp_Fields = {
  __typename?: "marketplace_unit_sku_var_samp_fields";
  height_cm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_cm?: Maybe<Scalars["Float"]>;
  unit_cost_gbp?: Maybe<Scalars["Float"]>;
  weight_kg?: Maybe<Scalars["Float"]>;
  width_cm?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Var_Samp_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marketplace_Unit_Sku_Variance_Fields = {
  __typename?: "marketplace_unit_sku_variance_fields";
  height_cm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_cm?: Maybe<Scalars["Float"]>;
  unit_cost_gbp?: Maybe<Scalars["Float"]>;
  weight_kg?: Maybe<Scalars["Float"]>;
  width_cm?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "marketplace_unit_sku" */
export type Marketplace_Unit_Sku_Variance_Order_By = {
  height_cm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_cm?: Maybe<Order_By>;
  unit_cost_gbp?: Maybe<Order_By>;
  weight_kg?: Maybe<Order_By>;
  width_cm?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "inventory.account" */
  delete_inventory_account?: Maybe<Inventory_Account_Mutation_Response>;
  /** delete single row from the table: "inventory.account" */
  delete_inventory_account_by_pk?: Maybe<Inventory_Account>;
  /** delete data from the table: "inventory.account_meeting_note" */
  delete_inventory_account_meeting_note?: Maybe<Inventory_Account_Meeting_Note_Mutation_Response>;
  /** delete single row from the table: "inventory.account_meeting_note" */
  delete_inventory_account_meeting_note_by_pk?: Maybe<Inventory_Account_Meeting_Note>;
  /** delete data from the table: "inventory.account_referral" */
  delete_inventory_account_referral?: Maybe<Inventory_Account_Referral_Mutation_Response>;
  /** delete single row from the table: "inventory.account_referral" */
  delete_inventory_account_referral_by_pk?: Maybe<Inventory_Account_Referral>;
  /** delete data from the table: "inventory.account_role" */
  delete_inventory_account_role?: Maybe<Inventory_Account_Role_Mutation_Response>;
  /** delete single row from the table: "inventory.account_role" */
  delete_inventory_account_role_by_pk?: Maybe<Inventory_Account_Role>;
  /** delete data from the table: "inventory.account_sector" */
  delete_inventory_account_sector?: Maybe<Inventory_Account_Sector_Mutation_Response>;
  /** delete single row from the table: "inventory.account_sector" */
  delete_inventory_account_sector_by_pk?: Maybe<Inventory_Account_Sector>;
  /** delete data from the table: "inventory.account_status" */
  delete_inventory_account_status?: Maybe<Inventory_Account_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.account_status" */
  delete_inventory_account_status_by_pk?: Maybe<Inventory_Account_Status>;
  /** delete data from the table: "inventory.address" */
  delete_inventory_address?: Maybe<Inventory_Address_Mutation_Response>;
  /** delete single row from the table: "inventory.address" */
  delete_inventory_address_by_pk?: Maybe<Inventory_Address>;
  /** delete data from the table: "inventory.attachment" */
  delete_inventory_attachment?: Maybe<Inventory_Attachment_Mutation_Response>;
  /** delete single row from the table: "inventory.attachment" */
  delete_inventory_attachment_by_pk?: Maybe<Inventory_Attachment>;
  /** delete data from the table: "inventory.attachment_folder" */
  delete_inventory_attachment_folder?: Maybe<Inventory_Attachment_Folder_Mutation_Response>;
  /** delete single row from the table: "inventory.attachment_folder" */
  delete_inventory_attachment_folder_by_pk?: Maybe<Inventory_Attachment_Folder>;
  /** delete data from the table: "inventory.base_product" */
  delete_inventory_base_product?: Maybe<Inventory_Base_Product_Mutation_Response>;
  /** delete single row from the table: "inventory.base_product" */
  delete_inventory_base_product_by_pk?: Maybe<Inventory_Base_Product>;
  /** delete data from the table: "inventory.batch" */
  delete_inventory_batch?: Maybe<Inventory_Batch_Mutation_Response>;
  /** delete single row from the table: "inventory.batch" */
  delete_inventory_batch_by_pk?: Maybe<Inventory_Batch>;
  /** delete data from the table: "inventory.client_invoice" */
  delete_inventory_client_invoice?: Maybe<Inventory_Client_Invoice_Mutation_Response>;
  /** delete single row from the table: "inventory.client_invoice" */
  delete_inventory_client_invoice_by_pk?: Maybe<Inventory_Client_Invoice>;
  /** delete data from the table: "inventory.client_invoice_line" */
  delete_inventory_client_invoice_line?: Maybe<Inventory_Client_Invoice_Line_Mutation_Response>;
  /** delete single row from the table: "inventory.client_invoice_line" */
  delete_inventory_client_invoice_line_by_pk?: Maybe<Inventory_Client_Invoice_Line>;
  /** delete data from the table: "inventory.client_invoice_line_status" */
  delete_inventory_client_invoice_line_status?: Maybe<Inventory_Client_Invoice_Line_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.client_invoice_line_status" */
  delete_inventory_client_invoice_line_status_by_pk?: Maybe<Inventory_Client_Invoice_Line_Status>;
  /** delete data from the table: "inventory.client_invoice_status" */
  delete_inventory_client_invoice_status?: Maybe<Inventory_Client_Invoice_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.client_invoice_status" */
  delete_inventory_client_invoice_status_by_pk?: Maybe<Inventory_Client_Invoice_Status>;
  /** delete data from the table: "inventory.client_order" */
  delete_inventory_client_order?: Maybe<Inventory_Client_Order_Mutation_Response>;
  /** delete single row from the table: "inventory.client_order" */
  delete_inventory_client_order_by_pk?: Maybe<Inventory_Client_Order>;
  /** delete data from the table: "inventory.client_order_line" */
  delete_inventory_client_order_line?: Maybe<Inventory_Client_Order_Line_Mutation_Response>;
  /** delete single row from the table: "inventory.client_order_line" */
  delete_inventory_client_order_line_by_pk?: Maybe<Inventory_Client_Order_Line>;
  /** delete data from the table: "inventory.client_order_line_status" */
  delete_inventory_client_order_line_status?: Maybe<Inventory_Client_Order_Line_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.client_order_line_status" */
  delete_inventory_client_order_line_status_by_pk?: Maybe<Inventory_Client_Order_Line_Status>;
  /** delete data from the table: "inventory.client_order_line_type" */
  delete_inventory_client_order_line_type?: Maybe<Inventory_Client_Order_Line_Type_Mutation_Response>;
  /** delete single row from the table: "inventory.client_order_line_type" */
  delete_inventory_client_order_line_type_by_pk?: Maybe<Inventory_Client_Order_Line_Type>;
  /** delete data from the table: "inventory.client_order_status" */
  delete_inventory_client_order_status?: Maybe<Inventory_Client_Order_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.client_order_status" */
  delete_inventory_client_order_status_by_pk?: Maybe<Inventory_Client_Order_Status>;
  /** delete data from the table: "inventory.client_order_type" */
  delete_inventory_client_order_type?: Maybe<Inventory_Client_Order_Type_Mutation_Response>;
  /** delete single row from the table: "inventory.client_order_type" */
  delete_inventory_client_order_type_by_pk?: Maybe<Inventory_Client_Order_Type>;
  /** delete data from the table: "inventory.country" */
  delete_inventory_country?: Maybe<Inventory_Country_Mutation_Response>;
  /** delete single row from the table: "inventory.country" */
  delete_inventory_country_by_pk?: Maybe<Inventory_Country>;
  /** delete data from the table: "inventory.courier" */
  delete_inventory_courier?: Maybe<Inventory_Courier_Mutation_Response>;
  /** delete single row from the table: "inventory.courier" */
  delete_inventory_courier_by_pk?: Maybe<Inventory_Courier>;
  /** delete data from the table: "inventory.courier_service" */
  delete_inventory_courier_service?: Maybe<Inventory_Courier_Service_Mutation_Response>;
  /** delete single row from the table: "inventory.courier_service" */
  delete_inventory_courier_service_by_pk?: Maybe<Inventory_Courier_Service>;
  /** delete data from the table: "inventory.courier_type" */
  delete_inventory_courier_type?: Maybe<Inventory_Courier_Type_Mutation_Response>;
  /** delete single row from the table: "inventory.courier_type" */
  delete_inventory_courier_type_by_pk?: Maybe<Inventory_Courier_Type>;
  /** delete data from the table: "inventory.currency" */
  delete_inventory_currency?: Maybe<Inventory_Currency_Mutation_Response>;
  /** delete single row from the table: "inventory.currency" */
  delete_inventory_currency_by_pk?: Maybe<Inventory_Currency>;
  /** delete data from the table: "inventory.date_d" */
  delete_inventory_date_d?: Maybe<Inventory_Date_D_Mutation_Response>;
  /** delete single row from the table: "inventory.date_d" */
  delete_inventory_date_d_by_pk?: Maybe<Inventory_Date_D>;
  /** delete data from the table: "inventory.exchange_rate_gbp" */
  delete_inventory_exchange_rate_gbp?: Maybe<Inventory_Exchange_Rate_Gbp_Mutation_Response>;
  /** delete single row from the table: "inventory.exchange_rate_gbp" */
  delete_inventory_exchange_rate_gbp_by_pk?: Maybe<Inventory_Exchange_Rate_Gbp>;
  /** delete data from the table: "inventory.folder" */
  delete_inventory_folder?: Maybe<Inventory_Folder_Mutation_Response>;
  /** delete single row from the table: "inventory.folder" */
  delete_inventory_folder_by_pk?: Maybe<Inventory_Folder>;
  /** delete data from the table: "inventory.locale" */
  delete_inventory_locale?: Maybe<Inventory_Locale_Mutation_Response>;
  /** delete single row from the table: "inventory.locale" */
  delete_inventory_locale_by_pk?: Maybe<Inventory_Locale>;
  /** delete data from the table: "inventory.location" */
  delete_inventory_location?: Maybe<Inventory_Location_Mutation_Response>;
  /** delete single row from the table: "inventory.location" */
  delete_inventory_location_by_pk?: Maybe<Inventory_Location>;
  /** delete data from the table: "inventory_management_locations" */
  delete_inventory_management_locations?: Maybe<Inventory_Management_Locations_Mutation_Response>;
  /** delete data from the table: "inventory_management_locations_ancestry" */
  delete_inventory_management_locations_ancestry?: Maybe<Inventory_Management_Locations_Ancestry_Mutation_Response>;
  /** delete single row from the table: "inventory_management_locations" */
  delete_inventory_management_locations_by_pk?: Maybe<Inventory_Management_Locations>;
  /** delete data from the table: "inventory_management_owners" */
  delete_inventory_management_owners?: Maybe<Inventory_Management_Owners_Mutation_Response>;
  /** delete single row from the table: "inventory_management_owners" */
  delete_inventory_management_owners_by_pk?: Maybe<Inventory_Management_Owners>;
  /** delete data from the table: "inventory_management_services" */
  delete_inventory_management_services?: Maybe<Inventory_Management_Services_Mutation_Response>;
  /** delete single row from the table: "inventory_management_services" */
  delete_inventory_management_services_by_pk?: Maybe<Inventory_Management_Services>;
  /** delete data from the table: "inventory_management_sku" */
  delete_inventory_management_sku?: Maybe<Inventory_Management_Sku_Mutation_Response>;
  /** delete single row from the table: "inventory_management_sku" */
  delete_inventory_management_sku_by_pk?: Maybe<Inventory_Management_Sku>;
  /** delete data from the table: "inventory_management_stock_change" */
  delete_inventory_management_stock_change?: Maybe<Inventory_Management_Stock_Change_Mutation_Response>;
  /** delete single row from the table: "inventory_management_stock_change" */
  delete_inventory_management_stock_change_by_pk?: Maybe<Inventory_Management_Stock_Change>;
  /** delete data from the table: "inventory_management_stocks" */
  delete_inventory_management_stocks?: Maybe<Inventory_Management_Stocks_Mutation_Response>;
  /** delete single row from the table: "inventory_management_stocks" */
  delete_inventory_management_stocks_by_pk?: Maybe<Inventory_Management_Stocks>;
  /** delete data from the table: "inventory.meeting_note" */
  delete_inventory_meeting_note?: Maybe<Inventory_Meeting_Note_Mutation_Response>;
  /** delete single row from the table: "inventory.meeting_note" */
  delete_inventory_meeting_note_by_pk?: Maybe<Inventory_Meeting_Note>;
  /** delete data from the table: "inventory.meeting_note_status" */
  delete_inventory_meeting_note_status?: Maybe<Inventory_Meeting_Note_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.meeting_note_status" */
  delete_inventory_meeting_note_status_by_pk?: Maybe<Inventory_Meeting_Note_Status>;
  /** delete data from the table: "inventory.product" */
  delete_inventory_product?: Maybe<Inventory_Product_Mutation_Response>;
  /** delete single row from the table: "inventory.product" */
  delete_inventory_product_by_pk?: Maybe<Inventory_Product>;
  /** delete data from the table: "inventory.product_pricing" */
  delete_inventory_product_pricing?: Maybe<Inventory_Product_Pricing_Mutation_Response>;
  /** delete single row from the table: "inventory.product_pricing" */
  delete_inventory_product_pricing_by_pk?: Maybe<Inventory_Product_Pricing>;
  /** delete data from the table: "inventory.product_stock" */
  delete_inventory_product_stock?: Maybe<Inventory_Product_Stock_Mutation_Response>;
  /** delete single row from the table: "inventory.product_stock" */
  delete_inventory_product_stock_by_pk?: Maybe<Inventory_Product_Stock>;
  /** delete data from the table: "inventory.product_stock_overview" */
  delete_inventory_product_stock_overview?: Maybe<Inventory_Product_Stock_Overview_Mutation_Response>;
  /** delete data from the table: "inventory.production_order" */
  delete_inventory_production_order?: Maybe<Inventory_Production_Order_Mutation_Response>;
  /** delete single row from the table: "inventory.production_order" */
  delete_inventory_production_order_by_pk?: Maybe<Inventory_Production_Order>;
  /** delete data from the table: "inventory.production_order_line" */
  delete_inventory_production_order_line?: Maybe<Inventory_Production_Order_Line_Mutation_Response>;
  /** delete single row from the table: "inventory.production_order_line" */
  delete_inventory_production_order_line_by_pk?: Maybe<Inventory_Production_Order_Line>;
  /** delete data from the table: "inventory.production_order_line_status" */
  delete_inventory_production_order_line_status?: Maybe<Inventory_Production_Order_Line_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.production_order_line_status" */
  delete_inventory_production_order_line_status_by_pk?: Maybe<Inventory_Production_Order_Line_Status>;
  /** delete data from the table: "inventory.production_order_status" */
  delete_inventory_production_order_status?: Maybe<Inventory_Production_Order_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.production_order_status" */
  delete_inventory_production_order_status_by_pk?: Maybe<Inventory_Production_Order_Status>;
  /** delete data from the table: "inventory.role" */
  delete_inventory_role?: Maybe<Inventory_Role_Mutation_Response>;
  /** delete single row from the table: "inventory.role" */
  delete_inventory_role_by_pk?: Maybe<Inventory_Role>;
  /** delete data from the table: "inventory.shipment" */
  delete_inventory_shipment?: Maybe<Inventory_Shipment_Mutation_Response>;
  /** delete single row from the table: "inventory.shipment" */
  delete_inventory_shipment_by_pk?: Maybe<Inventory_Shipment>;
  /** delete data from the table: "inventory.shipment_invoice" */
  delete_inventory_shipment_invoice?: Maybe<Inventory_Shipment_Invoice_Mutation_Response>;
  /** delete single row from the table: "inventory.shipment_invoice" */
  delete_inventory_shipment_invoice_by_pk?: Maybe<Inventory_Shipment_Invoice>;
  /** delete data from the table: "inventory.shipment_line" */
  delete_inventory_shipment_line?: Maybe<Inventory_Shipment_Line_Mutation_Response>;
  /** delete single row from the table: "inventory.shipment_line" */
  delete_inventory_shipment_line_by_pk?: Maybe<Inventory_Shipment_Line>;
  /** delete data from the table: "inventory.shipment_line_status" */
  delete_inventory_shipment_line_status?: Maybe<Inventory_Shipment_Line_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.shipment_line_status" */
  delete_inventory_shipment_line_status_by_pk?: Maybe<Inventory_Shipment_Line_Status>;
  /** delete data from the table: "inventory.shipment_status" */
  delete_inventory_shipment_status?: Maybe<Inventory_Shipment_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.shipment_status" */
  delete_inventory_shipment_status_by_pk?: Maybe<Inventory_Shipment_Status>;
  /** delete data from the table: "inventory.stock_status" */
  delete_inventory_stock_status?: Maybe<Inventory_Stock_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.stock_status" */
  delete_inventory_stock_status_by_pk?: Maybe<Inventory_Stock_Status>;
  /** delete data from the table: "inventory.supplier_invoice" */
  delete_inventory_supplier_invoice?: Maybe<Inventory_Supplier_Invoice_Mutation_Response>;
  /** delete single row from the table: "inventory.supplier_invoice" */
  delete_inventory_supplier_invoice_by_pk?: Maybe<Inventory_Supplier_Invoice>;
  /** delete data from the table: "inventory.supplier_invoice_line" */
  delete_inventory_supplier_invoice_line?: Maybe<Inventory_Supplier_Invoice_Line_Mutation_Response>;
  /** delete single row from the table: "inventory.supplier_invoice_line" */
  delete_inventory_supplier_invoice_line_by_pk?: Maybe<Inventory_Supplier_Invoice_Line>;
  /** delete data from the table: "inventory.supplier_invoice_line_status" */
  delete_inventory_supplier_invoice_line_status?: Maybe<Inventory_Supplier_Invoice_Line_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.supplier_invoice_line_status" */
  delete_inventory_supplier_invoice_line_status_by_pk?: Maybe<Inventory_Supplier_Invoice_Line_Status>;
  /** delete data from the table: "inventory.supplier_invoice_status" */
  delete_inventory_supplier_invoice_status?: Maybe<Inventory_Supplier_Invoice_Status_Mutation_Response>;
  /** delete single row from the table: "inventory.supplier_invoice_status" */
  delete_inventory_supplier_invoice_status_by_pk?: Maybe<Inventory_Supplier_Invoice_Status>;
  /** delete data from the table: "inventory.supplier_product" */
  delete_inventory_supplier_product?: Maybe<Inventory_Supplier_Product_Mutation_Response>;
  /** delete single row from the table: "inventory.supplier_product" */
  delete_inventory_supplier_product_by_pk?: Maybe<Inventory_Supplier_Product>;
  /** delete data from the table: "inventory.supplier_quote" */
  delete_inventory_supplier_quote?: Maybe<Inventory_Supplier_Quote_Mutation_Response>;
  /** delete single row from the table: "inventory.supplier_quote" */
  delete_inventory_supplier_quote_by_pk?: Maybe<Inventory_Supplier_Quote>;
  /** delete data from the table: "marketplace_client" */
  delete_marketplace_client?: Maybe<Marketplace_Client_Mutation_Response>;
  /** delete single row from the table: "marketplace_client" */
  delete_marketplace_client_by_pk?: Maybe<Marketplace_Client>;
  /** delete data from the table: "marketplace_data_collector_merchant_sales_transformed" */
  delete_marketplace_data_collector_merchant_sales_transformed?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Mutation_Response>;
  /** delete single row from the table: "marketplace_data_collector_merchant_sales_transformed" */
  delete_marketplace_data_collector_merchant_sales_transformed_by_pk?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
  /** delete data from the table: "marketplace_master_sku" */
  delete_marketplace_master_sku?: Maybe<Marketplace_Master_Sku_Mutation_Response>;
  /** delete single row from the table: "marketplace_master_sku" */
  delete_marketplace_master_sku_by_pk?: Maybe<Marketplace_Master_Sku>;
  /** delete data from the table: "marketplace_master_sku_marketplace_unit_sku" */
  delete_marketplace_master_sku_marketplace_unit_sku?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Mutation_Response>;
  /** delete single row from the table: "marketplace_master_sku_marketplace_unit_sku" */
  delete_marketplace_master_sku_marketplace_unit_sku_by_pk?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
  /** delete data from the table: "marketplace_merchants" */
  delete_marketplace_merchants?: Maybe<Marketplace_Merchants_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants" */
  delete_marketplace_merchants_by_pk?: Maybe<Marketplace_Merchants>;
  /** delete data from the table: "marketplace_merchants_floomx_replens_order" */
  delete_marketplace_merchants_floomx_replens_order?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants_floomx_replens_order" */
  delete_marketplace_merchants_floomx_replens_order_by_pk?: Maybe<Marketplace_Merchants_Floomx_Replens_Order>;
  /** delete data from the table: "marketplace_merchants_floomx_replens_order_items" */
  delete_marketplace_merchants_floomx_replens_order_items?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants_floomx_replens_order_items" */
  delete_marketplace_merchants_floomx_replens_order_items_by_pk?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items>;
  /** delete data from the table: "marketplace_merchants_raw" */
  delete_marketplace_merchants_raw?: Maybe<Marketplace_Merchants_Raw_Mutation_Response>;
  /** delete data from the table: "marketplace_merchants_replens" */
  delete_marketplace_merchants_replens?: Maybe<Marketplace_Merchants_Replens_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants_replens" */
  delete_marketplace_merchants_replens_by_pk?: Maybe<Marketplace_Merchants_Replens>;
  /** delete data from the table: "marketplace_merchants_replens_order" */
  delete_marketplace_merchants_replens_order?: Maybe<Marketplace_Merchants_Replens_Order_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants_replens_order" */
  delete_marketplace_merchants_replens_order_by_pk?: Maybe<Marketplace_Merchants_Replens_Order>;
  /** delete data from the table: "marketplace_merchants_sales" */
  delete_marketplace_merchants_sales?: Maybe<Marketplace_Merchants_Sales_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants_sales" */
  delete_marketplace_merchants_sales_by_pk?: Maybe<Marketplace_Merchants_Sales>;
  /** delete data from the table: "marketplace_merchants_starting_stocks" */
  delete_marketplace_merchants_starting_stocks?: Maybe<Marketplace_Merchants_Starting_Stocks_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants_starting_stocks" */
  delete_marketplace_merchants_starting_stocks_by_pk?: Maybe<Marketplace_Merchants_Starting_Stocks>;
  /** delete data from the table: "marketplace_merchants_stocks" */
  delete_marketplace_merchants_stocks?: Maybe<Marketplace_Merchants_Stocks_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants_stocks" */
  delete_marketplace_merchants_stocks_by_pk?: Maybe<Marketplace_Merchants_Stocks>;
  /** delete data from the table: "marketplace_merchants_stocks_v2" */
  delete_marketplace_merchants_stocks_v2?: Maybe<Marketplace_Merchants_Stocks_V2_Mutation_Response>;
  /** delete single row from the table: "marketplace_merchants_stocks_v2" */
  delete_marketplace_merchants_stocks_v2_by_pk?: Maybe<Marketplace_Merchants_Stocks_V2>;
  /** delete data from the table: "marketplace_replenishment_email" */
  delete_marketplace_replenishment_email?: Maybe<Marketplace_Replenishment_Email_Mutation_Response>;
  /** delete single row from the table: "marketplace_replenishment_email" */
  delete_marketplace_replenishment_email_by_pk?: Maybe<Marketplace_Replenishment_Email>;
  /** delete data from the table: "marketplace_replenishment_suggestions" */
  delete_marketplace_replenishment_suggestions?: Maybe<Marketplace_Replenishment_Suggestions_Mutation_Response>;
  /** delete single row from the table: "marketplace_replenishment_suggestions" */
  delete_marketplace_replenishment_suggestions_by_pk?: Maybe<Marketplace_Replenishment_Suggestions>;
  /** delete data from the table: "marketplace_replenishment_suggestions_v2" */
  delete_marketplace_replenishment_suggestions_v2?: Maybe<Marketplace_Replenishment_Suggestions_V2_Mutation_Response>;
  /** delete single row from the table: "marketplace_replenishment_suggestions_v2" */
  delete_marketplace_replenishment_suggestions_v2_by_pk?: Maybe<Marketplace_Replenishment_Suggestions_V2>;
  /** delete data from the table: "marketplace_unit_sku" */
  delete_marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Mutation_Response>;
  /** delete single row from the table: "marketplace_unit_sku" */
  delete_marketplace_unit_sku_by_pk?: Maybe<Marketplace_Unit_Sku>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "inventory.account" */
  insert_inventory_account?: Maybe<Inventory_Account_Mutation_Response>;
  /** insert data into the table: "inventory.account_meeting_note" */
  insert_inventory_account_meeting_note?: Maybe<Inventory_Account_Meeting_Note_Mutation_Response>;
  /** insert a single row into the table: "inventory.account_meeting_note" */
  insert_inventory_account_meeting_note_one?: Maybe<Inventory_Account_Meeting_Note>;
  /** insert a single row into the table: "inventory.account" */
  insert_inventory_account_one?: Maybe<Inventory_Account>;
  /** insert data into the table: "inventory.account_referral" */
  insert_inventory_account_referral?: Maybe<Inventory_Account_Referral_Mutation_Response>;
  /** insert a single row into the table: "inventory.account_referral" */
  insert_inventory_account_referral_one?: Maybe<Inventory_Account_Referral>;
  /** insert data into the table: "inventory.account_role" */
  insert_inventory_account_role?: Maybe<Inventory_Account_Role_Mutation_Response>;
  /** insert a single row into the table: "inventory.account_role" */
  insert_inventory_account_role_one?: Maybe<Inventory_Account_Role>;
  /** insert data into the table: "inventory.account_sector" */
  insert_inventory_account_sector?: Maybe<Inventory_Account_Sector_Mutation_Response>;
  /** insert a single row into the table: "inventory.account_sector" */
  insert_inventory_account_sector_one?: Maybe<Inventory_Account_Sector>;
  /** insert data into the table: "inventory.account_status" */
  insert_inventory_account_status?: Maybe<Inventory_Account_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.account_status" */
  insert_inventory_account_status_one?: Maybe<Inventory_Account_Status>;
  /** insert data into the table: "inventory.address" */
  insert_inventory_address?: Maybe<Inventory_Address_Mutation_Response>;
  /** insert a single row into the table: "inventory.address" */
  insert_inventory_address_one?: Maybe<Inventory_Address>;
  /** insert data into the table: "inventory.attachment" */
  insert_inventory_attachment?: Maybe<Inventory_Attachment_Mutation_Response>;
  /** insert data into the table: "inventory.attachment_folder" */
  insert_inventory_attachment_folder?: Maybe<Inventory_Attachment_Folder_Mutation_Response>;
  /** insert a single row into the table: "inventory.attachment_folder" */
  insert_inventory_attachment_folder_one?: Maybe<Inventory_Attachment_Folder>;
  /** insert a single row into the table: "inventory.attachment" */
  insert_inventory_attachment_one?: Maybe<Inventory_Attachment>;
  /** insert data into the table: "inventory.base_product" */
  insert_inventory_base_product?: Maybe<Inventory_Base_Product_Mutation_Response>;
  /** insert a single row into the table: "inventory.base_product" */
  insert_inventory_base_product_one?: Maybe<Inventory_Base_Product>;
  /** insert data into the table: "inventory.batch" */
  insert_inventory_batch?: Maybe<Inventory_Batch_Mutation_Response>;
  /** insert a single row into the table: "inventory.batch" */
  insert_inventory_batch_one?: Maybe<Inventory_Batch>;
  /** insert data into the table: "inventory.client_invoice" */
  insert_inventory_client_invoice?: Maybe<Inventory_Client_Invoice_Mutation_Response>;
  /** insert data into the table: "inventory.client_invoice_line" */
  insert_inventory_client_invoice_line?: Maybe<Inventory_Client_Invoice_Line_Mutation_Response>;
  /** insert a single row into the table: "inventory.client_invoice_line" */
  insert_inventory_client_invoice_line_one?: Maybe<Inventory_Client_Invoice_Line>;
  /** insert data into the table: "inventory.client_invoice_line_status" */
  insert_inventory_client_invoice_line_status?: Maybe<Inventory_Client_Invoice_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.client_invoice_line_status" */
  insert_inventory_client_invoice_line_status_one?: Maybe<Inventory_Client_Invoice_Line_Status>;
  /** insert a single row into the table: "inventory.client_invoice" */
  insert_inventory_client_invoice_one?: Maybe<Inventory_Client_Invoice>;
  /** insert data into the table: "inventory.client_invoice_status" */
  insert_inventory_client_invoice_status?: Maybe<Inventory_Client_Invoice_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.client_invoice_status" */
  insert_inventory_client_invoice_status_one?: Maybe<Inventory_Client_Invoice_Status>;
  /** insert data into the table: "inventory.client_order" */
  insert_inventory_client_order?: Maybe<Inventory_Client_Order_Mutation_Response>;
  /** insert data into the table: "inventory.client_order_line" */
  insert_inventory_client_order_line?: Maybe<Inventory_Client_Order_Line_Mutation_Response>;
  /** insert a single row into the table: "inventory.client_order_line" */
  insert_inventory_client_order_line_one?: Maybe<Inventory_Client_Order_Line>;
  /** insert data into the table: "inventory.client_order_line_status" */
  insert_inventory_client_order_line_status?: Maybe<Inventory_Client_Order_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.client_order_line_status" */
  insert_inventory_client_order_line_status_one?: Maybe<Inventory_Client_Order_Line_Status>;
  /** insert data into the table: "inventory.client_order_line_type" */
  insert_inventory_client_order_line_type?: Maybe<Inventory_Client_Order_Line_Type_Mutation_Response>;
  /** insert a single row into the table: "inventory.client_order_line_type" */
  insert_inventory_client_order_line_type_one?: Maybe<Inventory_Client_Order_Line_Type>;
  /** insert a single row into the table: "inventory.client_order" */
  insert_inventory_client_order_one?: Maybe<Inventory_Client_Order>;
  /** insert data into the table: "inventory.client_order_status" */
  insert_inventory_client_order_status?: Maybe<Inventory_Client_Order_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.client_order_status" */
  insert_inventory_client_order_status_one?: Maybe<Inventory_Client_Order_Status>;
  /** insert data into the table: "inventory.client_order_type" */
  insert_inventory_client_order_type?: Maybe<Inventory_Client_Order_Type_Mutation_Response>;
  /** insert a single row into the table: "inventory.client_order_type" */
  insert_inventory_client_order_type_one?: Maybe<Inventory_Client_Order_Type>;
  /** insert data into the table: "inventory.country" */
  insert_inventory_country?: Maybe<Inventory_Country_Mutation_Response>;
  /** insert a single row into the table: "inventory.country" */
  insert_inventory_country_one?: Maybe<Inventory_Country>;
  /** insert data into the table: "inventory.courier" */
  insert_inventory_courier?: Maybe<Inventory_Courier_Mutation_Response>;
  /** insert a single row into the table: "inventory.courier" */
  insert_inventory_courier_one?: Maybe<Inventory_Courier>;
  /** insert data into the table: "inventory.courier_service" */
  insert_inventory_courier_service?: Maybe<Inventory_Courier_Service_Mutation_Response>;
  /** insert a single row into the table: "inventory.courier_service" */
  insert_inventory_courier_service_one?: Maybe<Inventory_Courier_Service>;
  /** insert data into the table: "inventory.courier_type" */
  insert_inventory_courier_type?: Maybe<Inventory_Courier_Type_Mutation_Response>;
  /** insert a single row into the table: "inventory.courier_type" */
  insert_inventory_courier_type_one?: Maybe<Inventory_Courier_Type>;
  /** insert data into the table: "inventory.currency" */
  insert_inventory_currency?: Maybe<Inventory_Currency_Mutation_Response>;
  /** insert a single row into the table: "inventory.currency" */
  insert_inventory_currency_one?: Maybe<Inventory_Currency>;
  /** insert data into the table: "inventory.date_d" */
  insert_inventory_date_d?: Maybe<Inventory_Date_D_Mutation_Response>;
  /** insert a single row into the table: "inventory.date_d" */
  insert_inventory_date_d_one?: Maybe<Inventory_Date_D>;
  /** insert data into the table: "inventory.exchange_rate_gbp" */
  insert_inventory_exchange_rate_gbp?: Maybe<Inventory_Exchange_Rate_Gbp_Mutation_Response>;
  /** insert a single row into the table: "inventory.exchange_rate_gbp" */
  insert_inventory_exchange_rate_gbp_one?: Maybe<Inventory_Exchange_Rate_Gbp>;
  /** insert data into the table: "inventory.folder" */
  insert_inventory_folder?: Maybe<Inventory_Folder_Mutation_Response>;
  /** insert a single row into the table: "inventory.folder" */
  insert_inventory_folder_one?: Maybe<Inventory_Folder>;
  /** insert data into the table: "inventory.locale" */
  insert_inventory_locale?: Maybe<Inventory_Locale_Mutation_Response>;
  /** insert a single row into the table: "inventory.locale" */
  insert_inventory_locale_one?: Maybe<Inventory_Locale>;
  /** insert data into the table: "inventory.location" */
  insert_inventory_location?: Maybe<Inventory_Location_Mutation_Response>;
  /** insert a single row into the table: "inventory.location" */
  insert_inventory_location_one?: Maybe<Inventory_Location>;
  /** insert data into the table: "inventory_management_locations" */
  insert_inventory_management_locations?: Maybe<Inventory_Management_Locations_Mutation_Response>;
  /** insert data into the table: "inventory_management_locations_ancestry" */
  insert_inventory_management_locations_ancestry?: Maybe<Inventory_Management_Locations_Ancestry_Mutation_Response>;
  /** insert a single row into the table: "inventory_management_locations_ancestry" */
  insert_inventory_management_locations_ancestry_one?: Maybe<Inventory_Management_Locations_Ancestry>;
  /** insert a single row into the table: "inventory_management_locations" */
  insert_inventory_management_locations_one?: Maybe<Inventory_Management_Locations>;
  /** insert data into the table: "inventory_management_owners" */
  insert_inventory_management_owners?: Maybe<Inventory_Management_Owners_Mutation_Response>;
  /** insert a single row into the table: "inventory_management_owners" */
  insert_inventory_management_owners_one?: Maybe<Inventory_Management_Owners>;
  /** insert data into the table: "inventory_management_services" */
  insert_inventory_management_services?: Maybe<Inventory_Management_Services_Mutation_Response>;
  /** insert a single row into the table: "inventory_management_services" */
  insert_inventory_management_services_one?: Maybe<Inventory_Management_Services>;
  /** insert data into the table: "inventory_management_sku" */
  insert_inventory_management_sku?: Maybe<Inventory_Management_Sku_Mutation_Response>;
  /** insert a single row into the table: "inventory_management_sku" */
  insert_inventory_management_sku_one?: Maybe<Inventory_Management_Sku>;
  /** insert data into the table: "inventory_management_stock_change" */
  insert_inventory_management_stock_change?: Maybe<Inventory_Management_Stock_Change_Mutation_Response>;
  /** insert a single row into the table: "inventory_management_stock_change" */
  insert_inventory_management_stock_change_one?: Maybe<Inventory_Management_Stock_Change>;
  /** insert data into the table: "inventory_management_stocks" */
  insert_inventory_management_stocks?: Maybe<Inventory_Management_Stocks_Mutation_Response>;
  /** insert a single row into the table: "inventory_management_stocks" */
  insert_inventory_management_stocks_one?: Maybe<Inventory_Management_Stocks>;
  /** insert data into the table: "inventory.meeting_note" */
  insert_inventory_meeting_note?: Maybe<Inventory_Meeting_Note_Mutation_Response>;
  /** insert a single row into the table: "inventory.meeting_note" */
  insert_inventory_meeting_note_one?: Maybe<Inventory_Meeting_Note>;
  /** insert data into the table: "inventory.meeting_note_status" */
  insert_inventory_meeting_note_status?: Maybe<Inventory_Meeting_Note_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.meeting_note_status" */
  insert_inventory_meeting_note_status_one?: Maybe<Inventory_Meeting_Note_Status>;
  /** insert data into the table: "inventory.product" */
  insert_inventory_product?: Maybe<Inventory_Product_Mutation_Response>;
  /** insert a single row into the table: "inventory.product" */
  insert_inventory_product_one?: Maybe<Inventory_Product>;
  /** insert data into the table: "inventory.product_pricing" */
  insert_inventory_product_pricing?: Maybe<Inventory_Product_Pricing_Mutation_Response>;
  /** insert a single row into the table: "inventory.product_pricing" */
  insert_inventory_product_pricing_one?: Maybe<Inventory_Product_Pricing>;
  /** insert data into the table: "inventory.product_stock" */
  insert_inventory_product_stock?: Maybe<Inventory_Product_Stock_Mutation_Response>;
  /** insert a single row into the table: "inventory.product_stock" */
  insert_inventory_product_stock_one?: Maybe<Inventory_Product_Stock>;
  /** insert data into the table: "inventory.product_stock_overview" */
  insert_inventory_product_stock_overview?: Maybe<Inventory_Product_Stock_Overview_Mutation_Response>;
  /** insert a single row into the table: "inventory.product_stock_overview" */
  insert_inventory_product_stock_overview_one?: Maybe<Inventory_Product_Stock_Overview>;
  /** insert data into the table: "inventory.production_order" */
  insert_inventory_production_order?: Maybe<Inventory_Production_Order_Mutation_Response>;
  /** insert data into the table: "inventory.production_order_line" */
  insert_inventory_production_order_line?: Maybe<Inventory_Production_Order_Line_Mutation_Response>;
  /** insert a single row into the table: "inventory.production_order_line" */
  insert_inventory_production_order_line_one?: Maybe<Inventory_Production_Order_Line>;
  /** insert data into the table: "inventory.production_order_line_status" */
  insert_inventory_production_order_line_status?: Maybe<Inventory_Production_Order_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.production_order_line_status" */
  insert_inventory_production_order_line_status_one?: Maybe<Inventory_Production_Order_Line_Status>;
  /** insert a single row into the table: "inventory.production_order" */
  insert_inventory_production_order_one?: Maybe<Inventory_Production_Order>;
  /** insert data into the table: "inventory.production_order_status" */
  insert_inventory_production_order_status?: Maybe<Inventory_Production_Order_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.production_order_status" */
  insert_inventory_production_order_status_one?: Maybe<Inventory_Production_Order_Status>;
  /** insert data into the table: "inventory.role" */
  insert_inventory_role?: Maybe<Inventory_Role_Mutation_Response>;
  /** insert a single row into the table: "inventory.role" */
  insert_inventory_role_one?: Maybe<Inventory_Role>;
  /** insert data into the table: "inventory.shipment" */
  insert_inventory_shipment?: Maybe<Inventory_Shipment_Mutation_Response>;
  /** insert data into the table: "inventory.shipment_invoice" */
  insert_inventory_shipment_invoice?: Maybe<Inventory_Shipment_Invoice_Mutation_Response>;
  /** insert a single row into the table: "inventory.shipment_invoice" */
  insert_inventory_shipment_invoice_one?: Maybe<Inventory_Shipment_Invoice>;
  /** insert data into the table: "inventory.shipment_line" */
  insert_inventory_shipment_line?: Maybe<Inventory_Shipment_Line_Mutation_Response>;
  /** insert a single row into the table: "inventory.shipment_line" */
  insert_inventory_shipment_line_one?: Maybe<Inventory_Shipment_Line>;
  /** insert data into the table: "inventory.shipment_line_status" */
  insert_inventory_shipment_line_status?: Maybe<Inventory_Shipment_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.shipment_line_status" */
  insert_inventory_shipment_line_status_one?: Maybe<Inventory_Shipment_Line_Status>;
  /** insert a single row into the table: "inventory.shipment" */
  insert_inventory_shipment_one?: Maybe<Inventory_Shipment>;
  /** insert data into the table: "inventory.shipment_status" */
  insert_inventory_shipment_status?: Maybe<Inventory_Shipment_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.shipment_status" */
  insert_inventory_shipment_status_one?: Maybe<Inventory_Shipment_Status>;
  /** insert data into the table: "inventory.stock_status" */
  insert_inventory_stock_status?: Maybe<Inventory_Stock_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.stock_status" */
  insert_inventory_stock_status_one?: Maybe<Inventory_Stock_Status>;
  /** insert data into the table: "inventory.supplier_invoice" */
  insert_inventory_supplier_invoice?: Maybe<Inventory_Supplier_Invoice_Mutation_Response>;
  /** insert data into the table: "inventory.supplier_invoice_line" */
  insert_inventory_supplier_invoice_line?: Maybe<Inventory_Supplier_Invoice_Line_Mutation_Response>;
  /** insert a single row into the table: "inventory.supplier_invoice_line" */
  insert_inventory_supplier_invoice_line_one?: Maybe<Inventory_Supplier_Invoice_Line>;
  /** insert data into the table: "inventory.supplier_invoice_line_status" */
  insert_inventory_supplier_invoice_line_status?: Maybe<Inventory_Supplier_Invoice_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.supplier_invoice_line_status" */
  insert_inventory_supplier_invoice_line_status_one?: Maybe<Inventory_Supplier_Invoice_Line_Status>;
  /** insert a single row into the table: "inventory.supplier_invoice" */
  insert_inventory_supplier_invoice_one?: Maybe<Inventory_Supplier_Invoice>;
  /** insert data into the table: "inventory.supplier_invoice_status" */
  insert_inventory_supplier_invoice_status?: Maybe<Inventory_Supplier_Invoice_Status_Mutation_Response>;
  /** insert a single row into the table: "inventory.supplier_invoice_status" */
  insert_inventory_supplier_invoice_status_one?: Maybe<Inventory_Supplier_Invoice_Status>;
  /** insert data into the table: "inventory.supplier_product" */
  insert_inventory_supplier_product?: Maybe<Inventory_Supplier_Product_Mutation_Response>;
  /** insert a single row into the table: "inventory.supplier_product" */
  insert_inventory_supplier_product_one?: Maybe<Inventory_Supplier_Product>;
  /** insert data into the table: "inventory.supplier_quote" */
  insert_inventory_supplier_quote?: Maybe<Inventory_Supplier_Quote_Mutation_Response>;
  /** insert a single row into the table: "inventory.supplier_quote" */
  insert_inventory_supplier_quote_one?: Maybe<Inventory_Supplier_Quote>;
  /** insert data into the table: "marketplace_client" */
  insert_marketplace_client?: Maybe<Marketplace_Client_Mutation_Response>;
  /** insert a single row into the table: "marketplace_client" */
  insert_marketplace_client_one?: Maybe<Marketplace_Client>;
  /** insert data into the table: "marketplace_data_collector_merchant_sales_transformed" */
  insert_marketplace_data_collector_merchant_sales_transformed?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Mutation_Response>;
  /** insert a single row into the table: "marketplace_data_collector_merchant_sales_transformed" */
  insert_marketplace_data_collector_merchant_sales_transformed_one?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
  /** insert data into the table: "marketplace_master_sku" */
  insert_marketplace_master_sku?: Maybe<Marketplace_Master_Sku_Mutation_Response>;
  /** insert data into the table: "marketplace_master_sku_marketplace_unit_sku" */
  insert_marketplace_master_sku_marketplace_unit_sku?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Mutation_Response>;
  /** insert a single row into the table: "marketplace_master_sku_marketplace_unit_sku" */
  insert_marketplace_master_sku_marketplace_unit_sku_one?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
  /** insert a single row into the table: "marketplace_master_sku" */
  insert_marketplace_master_sku_one?: Maybe<Marketplace_Master_Sku>;
  /** insert data into the table: "marketplace_merchants" */
  insert_marketplace_merchants?: Maybe<Marketplace_Merchants_Mutation_Response>;
  /** insert data into the table: "marketplace_merchants_floomx_replens_order" */
  insert_marketplace_merchants_floomx_replens_order?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Mutation_Response>;
  /** insert data into the table: "marketplace_merchants_floomx_replens_order_items" */
  insert_marketplace_merchants_floomx_replens_order_items?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Mutation_Response>;
  /** insert a single row into the table: "marketplace_merchants_floomx_replens_order_items" */
  insert_marketplace_merchants_floomx_replens_order_items_one?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items>;
  /** insert a single row into the table: "marketplace_merchants_floomx_replens_order" */
  insert_marketplace_merchants_floomx_replens_order_one?: Maybe<Marketplace_Merchants_Floomx_Replens_Order>;
  /** insert a single row into the table: "marketplace_merchants" */
  insert_marketplace_merchants_one?: Maybe<Marketplace_Merchants>;
  /** insert data into the table: "marketplace_merchants_raw" */
  insert_marketplace_merchants_raw?: Maybe<Marketplace_Merchants_Raw_Mutation_Response>;
  /** insert a single row into the table: "marketplace_merchants_raw" */
  insert_marketplace_merchants_raw_one?: Maybe<Marketplace_Merchants_Raw>;
  /** insert data into the table: "marketplace_merchants_replens" */
  insert_marketplace_merchants_replens?: Maybe<Marketplace_Merchants_Replens_Mutation_Response>;
  /** insert a single row into the table: "marketplace_merchants_replens" */
  insert_marketplace_merchants_replens_one?: Maybe<Marketplace_Merchants_Replens>;
  /** insert data into the table: "marketplace_merchants_replens_order" */
  insert_marketplace_merchants_replens_order?: Maybe<Marketplace_Merchants_Replens_Order_Mutation_Response>;
  /** insert a single row into the table: "marketplace_merchants_replens_order" */
  insert_marketplace_merchants_replens_order_one?: Maybe<Marketplace_Merchants_Replens_Order>;
  /** insert data into the table: "marketplace_merchants_sales" */
  insert_marketplace_merchants_sales?: Maybe<Marketplace_Merchants_Sales_Mutation_Response>;
  /** insert a single row into the table: "marketplace_merchants_sales" */
  insert_marketplace_merchants_sales_one?: Maybe<Marketplace_Merchants_Sales>;
  /** insert data into the table: "marketplace_merchants_starting_stocks" */
  insert_marketplace_merchants_starting_stocks?: Maybe<Marketplace_Merchants_Starting_Stocks_Mutation_Response>;
  /** insert a single row into the table: "marketplace_merchants_starting_stocks" */
  insert_marketplace_merchants_starting_stocks_one?: Maybe<Marketplace_Merchants_Starting_Stocks>;
  /** insert data into the table: "marketplace_merchants_stocks" */
  insert_marketplace_merchants_stocks?: Maybe<Marketplace_Merchants_Stocks_Mutation_Response>;
  /** insert a single row into the table: "marketplace_merchants_stocks" */
  insert_marketplace_merchants_stocks_one?: Maybe<Marketplace_Merchants_Stocks>;
  /** insert data into the table: "marketplace_merchants_stocks_v2" */
  insert_marketplace_merchants_stocks_v2?: Maybe<Marketplace_Merchants_Stocks_V2_Mutation_Response>;
  /** insert a single row into the table: "marketplace_merchants_stocks_v2" */
  insert_marketplace_merchants_stocks_v2_one?: Maybe<Marketplace_Merchants_Stocks_V2>;
  /** insert data into the table: "marketplace_replenishment_email" */
  insert_marketplace_replenishment_email?: Maybe<Marketplace_Replenishment_Email_Mutation_Response>;
  /** insert a single row into the table: "marketplace_replenishment_email" */
  insert_marketplace_replenishment_email_one?: Maybe<Marketplace_Replenishment_Email>;
  /** insert data into the table: "marketplace_replenishment_suggestions" */
  insert_marketplace_replenishment_suggestions?: Maybe<Marketplace_Replenishment_Suggestions_Mutation_Response>;
  /** insert a single row into the table: "marketplace_replenishment_suggestions" */
  insert_marketplace_replenishment_suggestions_one?: Maybe<Marketplace_Replenishment_Suggestions>;
  /** insert data into the table: "marketplace_replenishment_suggestions_v2" */
  insert_marketplace_replenishment_suggestions_v2?: Maybe<Marketplace_Replenishment_Suggestions_V2_Mutation_Response>;
  /** insert a single row into the table: "marketplace_replenishment_suggestions_v2" */
  insert_marketplace_replenishment_suggestions_v2_one?: Maybe<Marketplace_Replenishment_Suggestions_V2>;
  /** insert data into the table: "marketplace_unit_sku" */
  insert_marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Mutation_Response>;
  /** insert a single row into the table: "marketplace_unit_sku" */
  insert_marketplace_unit_sku_one?: Maybe<Marketplace_Unit_Sku>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "inventory.account" */
  update_inventory_account?: Maybe<Inventory_Account_Mutation_Response>;
  /** update single row of the table: "inventory.account" */
  update_inventory_account_by_pk?: Maybe<Inventory_Account>;
  /** update data of the table: "inventory.account_meeting_note" */
  update_inventory_account_meeting_note?: Maybe<Inventory_Account_Meeting_Note_Mutation_Response>;
  /** update single row of the table: "inventory.account_meeting_note" */
  update_inventory_account_meeting_note_by_pk?: Maybe<Inventory_Account_Meeting_Note>;
  /** update data of the table: "inventory.account_referral" */
  update_inventory_account_referral?: Maybe<Inventory_Account_Referral_Mutation_Response>;
  /** update single row of the table: "inventory.account_referral" */
  update_inventory_account_referral_by_pk?: Maybe<Inventory_Account_Referral>;
  /** update data of the table: "inventory.account_role" */
  update_inventory_account_role?: Maybe<Inventory_Account_Role_Mutation_Response>;
  /** update single row of the table: "inventory.account_role" */
  update_inventory_account_role_by_pk?: Maybe<Inventory_Account_Role>;
  /** update data of the table: "inventory.account_sector" */
  update_inventory_account_sector?: Maybe<Inventory_Account_Sector_Mutation_Response>;
  /** update single row of the table: "inventory.account_sector" */
  update_inventory_account_sector_by_pk?: Maybe<Inventory_Account_Sector>;
  /** update data of the table: "inventory.account_status" */
  update_inventory_account_status?: Maybe<Inventory_Account_Status_Mutation_Response>;
  /** update single row of the table: "inventory.account_status" */
  update_inventory_account_status_by_pk?: Maybe<Inventory_Account_Status>;
  /** update data of the table: "inventory.address" */
  update_inventory_address?: Maybe<Inventory_Address_Mutation_Response>;
  /** update single row of the table: "inventory.address" */
  update_inventory_address_by_pk?: Maybe<Inventory_Address>;
  /** update data of the table: "inventory.attachment" */
  update_inventory_attachment?: Maybe<Inventory_Attachment_Mutation_Response>;
  /** update single row of the table: "inventory.attachment" */
  update_inventory_attachment_by_pk?: Maybe<Inventory_Attachment>;
  /** update data of the table: "inventory.attachment_folder" */
  update_inventory_attachment_folder?: Maybe<Inventory_Attachment_Folder_Mutation_Response>;
  /** update single row of the table: "inventory.attachment_folder" */
  update_inventory_attachment_folder_by_pk?: Maybe<Inventory_Attachment_Folder>;
  /** update data of the table: "inventory.base_product" */
  update_inventory_base_product?: Maybe<Inventory_Base_Product_Mutation_Response>;
  /** update single row of the table: "inventory.base_product" */
  update_inventory_base_product_by_pk?: Maybe<Inventory_Base_Product>;
  /** update data of the table: "inventory.batch" */
  update_inventory_batch?: Maybe<Inventory_Batch_Mutation_Response>;
  /** update single row of the table: "inventory.batch" */
  update_inventory_batch_by_pk?: Maybe<Inventory_Batch>;
  /** update data of the table: "inventory.client_invoice" */
  update_inventory_client_invoice?: Maybe<Inventory_Client_Invoice_Mutation_Response>;
  /** update single row of the table: "inventory.client_invoice" */
  update_inventory_client_invoice_by_pk?: Maybe<Inventory_Client_Invoice>;
  /** update data of the table: "inventory.client_invoice_line" */
  update_inventory_client_invoice_line?: Maybe<Inventory_Client_Invoice_Line_Mutation_Response>;
  /** update single row of the table: "inventory.client_invoice_line" */
  update_inventory_client_invoice_line_by_pk?: Maybe<Inventory_Client_Invoice_Line>;
  /** update data of the table: "inventory.client_invoice_line_status" */
  update_inventory_client_invoice_line_status?: Maybe<Inventory_Client_Invoice_Line_Status_Mutation_Response>;
  /** update single row of the table: "inventory.client_invoice_line_status" */
  update_inventory_client_invoice_line_status_by_pk?: Maybe<Inventory_Client_Invoice_Line_Status>;
  /** update data of the table: "inventory.client_invoice_status" */
  update_inventory_client_invoice_status?: Maybe<Inventory_Client_Invoice_Status_Mutation_Response>;
  /** update single row of the table: "inventory.client_invoice_status" */
  update_inventory_client_invoice_status_by_pk?: Maybe<Inventory_Client_Invoice_Status>;
  /** update data of the table: "inventory.client_order" */
  update_inventory_client_order?: Maybe<Inventory_Client_Order_Mutation_Response>;
  /** update single row of the table: "inventory.client_order" */
  update_inventory_client_order_by_pk?: Maybe<Inventory_Client_Order>;
  /** update data of the table: "inventory.client_order_line" */
  update_inventory_client_order_line?: Maybe<Inventory_Client_Order_Line_Mutation_Response>;
  /** update single row of the table: "inventory.client_order_line" */
  update_inventory_client_order_line_by_pk?: Maybe<Inventory_Client_Order_Line>;
  /** update data of the table: "inventory.client_order_line_status" */
  update_inventory_client_order_line_status?: Maybe<Inventory_Client_Order_Line_Status_Mutation_Response>;
  /** update single row of the table: "inventory.client_order_line_status" */
  update_inventory_client_order_line_status_by_pk?: Maybe<Inventory_Client_Order_Line_Status>;
  /** update data of the table: "inventory.client_order_line_type" */
  update_inventory_client_order_line_type?: Maybe<Inventory_Client_Order_Line_Type_Mutation_Response>;
  /** update single row of the table: "inventory.client_order_line_type" */
  update_inventory_client_order_line_type_by_pk?: Maybe<Inventory_Client_Order_Line_Type>;
  /** update data of the table: "inventory.client_order_status" */
  update_inventory_client_order_status?: Maybe<Inventory_Client_Order_Status_Mutation_Response>;
  /** update single row of the table: "inventory.client_order_status" */
  update_inventory_client_order_status_by_pk?: Maybe<Inventory_Client_Order_Status>;
  /** update data of the table: "inventory.client_order_type" */
  update_inventory_client_order_type?: Maybe<Inventory_Client_Order_Type_Mutation_Response>;
  /** update single row of the table: "inventory.client_order_type" */
  update_inventory_client_order_type_by_pk?: Maybe<Inventory_Client_Order_Type>;
  /** update data of the table: "inventory.country" */
  update_inventory_country?: Maybe<Inventory_Country_Mutation_Response>;
  /** update single row of the table: "inventory.country" */
  update_inventory_country_by_pk?: Maybe<Inventory_Country>;
  /** update data of the table: "inventory.courier" */
  update_inventory_courier?: Maybe<Inventory_Courier_Mutation_Response>;
  /** update single row of the table: "inventory.courier" */
  update_inventory_courier_by_pk?: Maybe<Inventory_Courier>;
  /** update data of the table: "inventory.courier_service" */
  update_inventory_courier_service?: Maybe<Inventory_Courier_Service_Mutation_Response>;
  /** update single row of the table: "inventory.courier_service" */
  update_inventory_courier_service_by_pk?: Maybe<Inventory_Courier_Service>;
  /** update data of the table: "inventory.courier_type" */
  update_inventory_courier_type?: Maybe<Inventory_Courier_Type_Mutation_Response>;
  /** update single row of the table: "inventory.courier_type" */
  update_inventory_courier_type_by_pk?: Maybe<Inventory_Courier_Type>;
  /** update data of the table: "inventory.currency" */
  update_inventory_currency?: Maybe<Inventory_Currency_Mutation_Response>;
  /** update single row of the table: "inventory.currency" */
  update_inventory_currency_by_pk?: Maybe<Inventory_Currency>;
  /** update data of the table: "inventory.date_d" */
  update_inventory_date_d?: Maybe<Inventory_Date_D_Mutation_Response>;
  /** update single row of the table: "inventory.date_d" */
  update_inventory_date_d_by_pk?: Maybe<Inventory_Date_D>;
  /** update data of the table: "inventory.exchange_rate_gbp" */
  update_inventory_exchange_rate_gbp?: Maybe<Inventory_Exchange_Rate_Gbp_Mutation_Response>;
  /** update single row of the table: "inventory.exchange_rate_gbp" */
  update_inventory_exchange_rate_gbp_by_pk?: Maybe<Inventory_Exchange_Rate_Gbp>;
  /** update data of the table: "inventory.folder" */
  update_inventory_folder?: Maybe<Inventory_Folder_Mutation_Response>;
  /** update single row of the table: "inventory.folder" */
  update_inventory_folder_by_pk?: Maybe<Inventory_Folder>;
  /** update data of the table: "inventory.locale" */
  update_inventory_locale?: Maybe<Inventory_Locale_Mutation_Response>;
  /** update single row of the table: "inventory.locale" */
  update_inventory_locale_by_pk?: Maybe<Inventory_Locale>;
  /** update data of the table: "inventory.location" */
  update_inventory_location?: Maybe<Inventory_Location_Mutation_Response>;
  /** update single row of the table: "inventory.location" */
  update_inventory_location_by_pk?: Maybe<Inventory_Location>;
  /** update data of the table: "inventory_management_locations" */
  update_inventory_management_locations?: Maybe<Inventory_Management_Locations_Mutation_Response>;
  /** update data of the table: "inventory_management_locations_ancestry" */
  update_inventory_management_locations_ancestry?: Maybe<Inventory_Management_Locations_Ancestry_Mutation_Response>;
  /** update single row of the table: "inventory_management_locations" */
  update_inventory_management_locations_by_pk?: Maybe<Inventory_Management_Locations>;
  /** update data of the table: "inventory_management_owners" */
  update_inventory_management_owners?: Maybe<Inventory_Management_Owners_Mutation_Response>;
  /** update single row of the table: "inventory_management_owners" */
  update_inventory_management_owners_by_pk?: Maybe<Inventory_Management_Owners>;
  /** update data of the table: "inventory_management_services" */
  update_inventory_management_services?: Maybe<Inventory_Management_Services_Mutation_Response>;
  /** update single row of the table: "inventory_management_services" */
  update_inventory_management_services_by_pk?: Maybe<Inventory_Management_Services>;
  /** update data of the table: "inventory_management_sku" */
  update_inventory_management_sku?: Maybe<Inventory_Management_Sku_Mutation_Response>;
  /** update single row of the table: "inventory_management_sku" */
  update_inventory_management_sku_by_pk?: Maybe<Inventory_Management_Sku>;
  /** update data of the table: "inventory_management_stock_change" */
  update_inventory_management_stock_change?: Maybe<Inventory_Management_Stock_Change_Mutation_Response>;
  /** update single row of the table: "inventory_management_stock_change" */
  update_inventory_management_stock_change_by_pk?: Maybe<Inventory_Management_Stock_Change>;
  /** update data of the table: "inventory_management_stocks" */
  update_inventory_management_stocks?: Maybe<Inventory_Management_Stocks_Mutation_Response>;
  /** update single row of the table: "inventory_management_stocks" */
  update_inventory_management_stocks_by_pk?: Maybe<Inventory_Management_Stocks>;
  /** update data of the table: "inventory.meeting_note" */
  update_inventory_meeting_note?: Maybe<Inventory_Meeting_Note_Mutation_Response>;
  /** update single row of the table: "inventory.meeting_note" */
  update_inventory_meeting_note_by_pk?: Maybe<Inventory_Meeting_Note>;
  /** update data of the table: "inventory.meeting_note_status" */
  update_inventory_meeting_note_status?: Maybe<Inventory_Meeting_Note_Status_Mutation_Response>;
  /** update single row of the table: "inventory.meeting_note_status" */
  update_inventory_meeting_note_status_by_pk?: Maybe<Inventory_Meeting_Note_Status>;
  /** update data of the table: "inventory.product" */
  update_inventory_product?: Maybe<Inventory_Product_Mutation_Response>;
  /** update single row of the table: "inventory.product" */
  update_inventory_product_by_pk?: Maybe<Inventory_Product>;
  /** update data of the table: "inventory.product_pricing" */
  update_inventory_product_pricing?: Maybe<Inventory_Product_Pricing_Mutation_Response>;
  /** update single row of the table: "inventory.product_pricing" */
  update_inventory_product_pricing_by_pk?: Maybe<Inventory_Product_Pricing>;
  /** update data of the table: "inventory.product_stock" */
  update_inventory_product_stock?: Maybe<Inventory_Product_Stock_Mutation_Response>;
  /** update single row of the table: "inventory.product_stock" */
  update_inventory_product_stock_by_pk?: Maybe<Inventory_Product_Stock>;
  /** update data of the table: "inventory.product_stock_overview" */
  update_inventory_product_stock_overview?: Maybe<Inventory_Product_Stock_Overview_Mutation_Response>;
  /** update data of the table: "inventory.production_order" */
  update_inventory_production_order?: Maybe<Inventory_Production_Order_Mutation_Response>;
  /** update single row of the table: "inventory.production_order" */
  update_inventory_production_order_by_pk?: Maybe<Inventory_Production_Order>;
  /** update data of the table: "inventory.production_order_line" */
  update_inventory_production_order_line?: Maybe<Inventory_Production_Order_Line_Mutation_Response>;
  /** update single row of the table: "inventory.production_order_line" */
  update_inventory_production_order_line_by_pk?: Maybe<Inventory_Production_Order_Line>;
  /** update data of the table: "inventory.production_order_line_status" */
  update_inventory_production_order_line_status?: Maybe<Inventory_Production_Order_Line_Status_Mutation_Response>;
  /** update single row of the table: "inventory.production_order_line_status" */
  update_inventory_production_order_line_status_by_pk?: Maybe<Inventory_Production_Order_Line_Status>;
  /** update data of the table: "inventory.production_order_status" */
  update_inventory_production_order_status?: Maybe<Inventory_Production_Order_Status_Mutation_Response>;
  /** update single row of the table: "inventory.production_order_status" */
  update_inventory_production_order_status_by_pk?: Maybe<Inventory_Production_Order_Status>;
  /** update data of the table: "inventory.role" */
  update_inventory_role?: Maybe<Inventory_Role_Mutation_Response>;
  /** update single row of the table: "inventory.role" */
  update_inventory_role_by_pk?: Maybe<Inventory_Role>;
  /** update data of the table: "inventory.shipment" */
  update_inventory_shipment?: Maybe<Inventory_Shipment_Mutation_Response>;
  /** update single row of the table: "inventory.shipment" */
  update_inventory_shipment_by_pk?: Maybe<Inventory_Shipment>;
  /** update data of the table: "inventory.shipment_invoice" */
  update_inventory_shipment_invoice?: Maybe<Inventory_Shipment_Invoice_Mutation_Response>;
  /** update single row of the table: "inventory.shipment_invoice" */
  update_inventory_shipment_invoice_by_pk?: Maybe<Inventory_Shipment_Invoice>;
  /** update data of the table: "inventory.shipment_line" */
  update_inventory_shipment_line?: Maybe<Inventory_Shipment_Line_Mutation_Response>;
  /** update single row of the table: "inventory.shipment_line" */
  update_inventory_shipment_line_by_pk?: Maybe<Inventory_Shipment_Line>;
  /** update data of the table: "inventory.shipment_line_status" */
  update_inventory_shipment_line_status?: Maybe<Inventory_Shipment_Line_Status_Mutation_Response>;
  /** update single row of the table: "inventory.shipment_line_status" */
  update_inventory_shipment_line_status_by_pk?: Maybe<Inventory_Shipment_Line_Status>;
  /** update data of the table: "inventory.shipment_status" */
  update_inventory_shipment_status?: Maybe<Inventory_Shipment_Status_Mutation_Response>;
  /** update single row of the table: "inventory.shipment_status" */
  update_inventory_shipment_status_by_pk?: Maybe<Inventory_Shipment_Status>;
  /** update data of the table: "inventory.stock_status" */
  update_inventory_stock_status?: Maybe<Inventory_Stock_Status_Mutation_Response>;
  /** update single row of the table: "inventory.stock_status" */
  update_inventory_stock_status_by_pk?: Maybe<Inventory_Stock_Status>;
  /** update data of the table: "inventory.supplier_invoice" */
  update_inventory_supplier_invoice?: Maybe<Inventory_Supplier_Invoice_Mutation_Response>;
  /** update single row of the table: "inventory.supplier_invoice" */
  update_inventory_supplier_invoice_by_pk?: Maybe<Inventory_Supplier_Invoice>;
  /** update data of the table: "inventory.supplier_invoice_line" */
  update_inventory_supplier_invoice_line?: Maybe<Inventory_Supplier_Invoice_Line_Mutation_Response>;
  /** update single row of the table: "inventory.supplier_invoice_line" */
  update_inventory_supplier_invoice_line_by_pk?: Maybe<Inventory_Supplier_Invoice_Line>;
  /** update data of the table: "inventory.supplier_invoice_line_status" */
  update_inventory_supplier_invoice_line_status?: Maybe<Inventory_Supplier_Invoice_Line_Status_Mutation_Response>;
  /** update single row of the table: "inventory.supplier_invoice_line_status" */
  update_inventory_supplier_invoice_line_status_by_pk?: Maybe<Inventory_Supplier_Invoice_Line_Status>;
  /** update data of the table: "inventory.supplier_invoice_status" */
  update_inventory_supplier_invoice_status?: Maybe<Inventory_Supplier_Invoice_Status_Mutation_Response>;
  /** update single row of the table: "inventory.supplier_invoice_status" */
  update_inventory_supplier_invoice_status_by_pk?: Maybe<Inventory_Supplier_Invoice_Status>;
  /** update data of the table: "inventory.supplier_product" */
  update_inventory_supplier_product?: Maybe<Inventory_Supplier_Product_Mutation_Response>;
  /** update single row of the table: "inventory.supplier_product" */
  update_inventory_supplier_product_by_pk?: Maybe<Inventory_Supplier_Product>;
  /** update data of the table: "inventory.supplier_quote" */
  update_inventory_supplier_quote?: Maybe<Inventory_Supplier_Quote_Mutation_Response>;
  /** update single row of the table: "inventory.supplier_quote" */
  update_inventory_supplier_quote_by_pk?: Maybe<Inventory_Supplier_Quote>;
  /** update data of the table: "marketplace_client" */
  update_marketplace_client?: Maybe<Marketplace_Client_Mutation_Response>;
  /** update single row of the table: "marketplace_client" */
  update_marketplace_client_by_pk?: Maybe<Marketplace_Client>;
  /** update data of the table: "marketplace_data_collector_merchant_sales_transformed" */
  update_marketplace_data_collector_merchant_sales_transformed?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Mutation_Response>;
  /** update single row of the table: "marketplace_data_collector_merchant_sales_transformed" */
  update_marketplace_data_collector_merchant_sales_transformed_by_pk?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
  /** update data of the table: "marketplace_master_sku" */
  update_marketplace_master_sku?: Maybe<Marketplace_Master_Sku_Mutation_Response>;
  /** update single row of the table: "marketplace_master_sku" */
  update_marketplace_master_sku_by_pk?: Maybe<Marketplace_Master_Sku>;
  /** update data of the table: "marketplace_master_sku_marketplace_unit_sku" */
  update_marketplace_master_sku_marketplace_unit_sku?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Mutation_Response>;
  /** update single row of the table: "marketplace_master_sku_marketplace_unit_sku" */
  update_marketplace_master_sku_marketplace_unit_sku_by_pk?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
  /** update data of the table: "marketplace_merchants" */
  update_marketplace_merchants?: Maybe<Marketplace_Merchants_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants" */
  update_marketplace_merchants_by_pk?: Maybe<Marketplace_Merchants>;
  /** update data of the table: "marketplace_merchants_floomx_replens_order" */
  update_marketplace_merchants_floomx_replens_order?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants_floomx_replens_order" */
  update_marketplace_merchants_floomx_replens_order_by_pk?: Maybe<Marketplace_Merchants_Floomx_Replens_Order>;
  /** update data of the table: "marketplace_merchants_floomx_replens_order_items" */
  update_marketplace_merchants_floomx_replens_order_items?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants_floomx_replens_order_items" */
  update_marketplace_merchants_floomx_replens_order_items_by_pk?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items>;
  /** update data of the table: "marketplace_merchants_raw" */
  update_marketplace_merchants_raw?: Maybe<Marketplace_Merchants_Raw_Mutation_Response>;
  /** update data of the table: "marketplace_merchants_replens" */
  update_marketplace_merchants_replens?: Maybe<Marketplace_Merchants_Replens_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants_replens" */
  update_marketplace_merchants_replens_by_pk?: Maybe<Marketplace_Merchants_Replens>;
  /** update data of the table: "marketplace_merchants_replens_order" */
  update_marketplace_merchants_replens_order?: Maybe<Marketplace_Merchants_Replens_Order_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants_replens_order" */
  update_marketplace_merchants_replens_order_by_pk?: Maybe<Marketplace_Merchants_Replens_Order>;
  /** update data of the table: "marketplace_merchants_sales" */
  update_marketplace_merchants_sales?: Maybe<Marketplace_Merchants_Sales_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants_sales" */
  update_marketplace_merchants_sales_by_pk?: Maybe<Marketplace_Merchants_Sales>;
  /** update data of the table: "marketplace_merchants_starting_stocks" */
  update_marketplace_merchants_starting_stocks?: Maybe<Marketplace_Merchants_Starting_Stocks_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants_starting_stocks" */
  update_marketplace_merchants_starting_stocks_by_pk?: Maybe<Marketplace_Merchants_Starting_Stocks>;
  /** update data of the table: "marketplace_merchants_stocks" */
  update_marketplace_merchants_stocks?: Maybe<Marketplace_Merchants_Stocks_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants_stocks" */
  update_marketplace_merchants_stocks_by_pk?: Maybe<Marketplace_Merchants_Stocks>;
  /** update data of the table: "marketplace_merchants_stocks_v2" */
  update_marketplace_merchants_stocks_v2?: Maybe<Marketplace_Merchants_Stocks_V2_Mutation_Response>;
  /** update single row of the table: "marketplace_merchants_stocks_v2" */
  update_marketplace_merchants_stocks_v2_by_pk?: Maybe<Marketplace_Merchants_Stocks_V2>;
  /** update data of the table: "marketplace_replenishment_email" */
  update_marketplace_replenishment_email?: Maybe<Marketplace_Replenishment_Email_Mutation_Response>;
  /** update single row of the table: "marketplace_replenishment_email" */
  update_marketplace_replenishment_email_by_pk?: Maybe<Marketplace_Replenishment_Email>;
  /** update data of the table: "marketplace_replenishment_suggestions" */
  update_marketplace_replenishment_suggestions?: Maybe<Marketplace_Replenishment_Suggestions_Mutation_Response>;
  /** update single row of the table: "marketplace_replenishment_suggestions" */
  update_marketplace_replenishment_suggestions_by_pk?: Maybe<Marketplace_Replenishment_Suggestions>;
  /** update data of the table: "marketplace_replenishment_suggestions_v2" */
  update_marketplace_replenishment_suggestions_v2?: Maybe<Marketplace_Replenishment_Suggestions_V2_Mutation_Response>;
  /** update single row of the table: "marketplace_replenishment_suggestions_v2" */
  update_marketplace_replenishment_suggestions_v2_by_pk?: Maybe<Marketplace_Replenishment_Suggestions_V2>;
  /** update data of the table: "marketplace_unit_sku" */
  update_marketplace_unit_sku?: Maybe<Marketplace_Unit_Sku_Mutation_Response>;
  /** update single row of the table: "marketplace_unit_sku" */
  update_marketplace_unit_sku_by_pk?: Maybe<Marketplace_Unit_Sku>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_AccountArgs = {
  where: Inventory_Account_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_Meeting_NoteArgs = {
  where: Inventory_Account_Meeting_Note_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_Meeting_Note_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_ReferralArgs = {
  where: Inventory_Account_Referral_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_Referral_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_RoleArgs = {
  where: Inventory_Account_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_Role_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_SectorArgs = {
  where: Inventory_Account_Sector_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_Sector_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_StatusArgs = {
  where: Inventory_Account_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Account_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_AddressArgs = {
  where: Inventory_Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Address_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_AttachmentArgs = {
  where: Inventory_Attachment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Attachment_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Attachment_FolderArgs = {
  where: Inventory_Attachment_Folder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Attachment_Folder_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Base_ProductArgs = {
  where: Inventory_Base_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Base_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_BatchArgs = {
  where: Inventory_Batch_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Batch_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_InvoiceArgs = {
  where: Inventory_Client_Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Invoice_LineArgs = {
  where: Inventory_Client_Invoice_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Invoice_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Invoice_Line_StatusArgs = {
  where: Inventory_Client_Invoice_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Invoice_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Invoice_StatusArgs = {
  where: Inventory_Client_Invoice_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Invoice_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_OrderArgs = {
  where: Inventory_Client_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_LineArgs = {
  where: Inventory_Client_Order_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_Line_StatusArgs = {
  where: Inventory_Client_Order_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_Line_TypeArgs = {
  where: Inventory_Client_Order_Line_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_Line_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_StatusArgs = {
  where: Inventory_Client_Order_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_TypeArgs = {
  where: Inventory_Client_Order_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Client_Order_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_CountryArgs = {
  where: Inventory_Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Country_By_PkArgs = {
  country_code: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_CourierArgs = {
  where: Inventory_Courier_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Courier_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Courier_ServiceArgs = {
  where: Inventory_Courier_Service_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Courier_Service_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Courier_TypeArgs = {
  where: Inventory_Courier_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Courier_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_CurrencyArgs = {
  where: Inventory_Currency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Currency_By_PkArgs = {
  currency_code: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Date_DArgs = {
  where: Inventory_Date_D_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Date_D_By_PkArgs = {
  date_key: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Exchange_Rate_GbpArgs = {
  where: Inventory_Exchange_Rate_Gbp_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Exchange_Rate_Gbp_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_FolderArgs = {
  where: Inventory_Folder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Folder_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_LocaleArgs = {
  where: Inventory_Locale_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Locale_By_PkArgs = {
  locale: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_LocationArgs = {
  where: Inventory_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Location_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_LocationsArgs = {
  where: Inventory_Management_Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_Locations_AncestryArgs = {
  where: Inventory_Management_Locations_Ancestry_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_Locations_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_OwnersArgs = {
  where: Inventory_Management_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_Owners_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_ServicesArgs = {
  where: Inventory_Management_Services_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_Services_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_SkuArgs = {
  where: Inventory_Management_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_Stock_ChangeArgs = {
  where: Inventory_Management_Stock_Change_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_Stock_Change_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_StocksArgs = {
  where: Inventory_Management_Stocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Management_Stocks_By_PkArgs = {
  location_id: Scalars["Int"];
  sku_id: Scalars["Int"];
  status: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Meeting_NoteArgs = {
  where: Inventory_Meeting_Note_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Meeting_Note_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Meeting_Note_StatusArgs = {
  where: Inventory_Meeting_Note_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Meeting_Note_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_ProductArgs = {
  where: Inventory_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Product_PricingArgs = {
  where: Inventory_Product_Pricing_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Product_Pricing_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Product_StockArgs = {
  where: Inventory_Product_Stock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Product_Stock_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Product_Stock_OverviewArgs = {
  where: Inventory_Product_Stock_Overview_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Production_OrderArgs = {
  where: Inventory_Production_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Production_Order_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Production_Order_LineArgs = {
  where: Inventory_Production_Order_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Production_Order_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Production_Order_Line_StatusArgs = {
  where: Inventory_Production_Order_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Production_Order_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Production_Order_StatusArgs = {
  where: Inventory_Production_Order_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Production_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_RoleArgs = {
  where: Inventory_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Role_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_ShipmentArgs = {
  where: Inventory_Shipment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_InvoiceArgs = {
  where: Inventory_Shipment_Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_LineArgs = {
  where: Inventory_Shipment_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_Line_StatusArgs = {
  where: Inventory_Shipment_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_StatusArgs = {
  where: Inventory_Shipment_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Shipment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Stock_StatusArgs = {
  where: Inventory_Stock_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Stock_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_InvoiceArgs = {
  where: Inventory_Supplier_Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Invoice_LineArgs = {
  where: Inventory_Supplier_Invoice_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Invoice_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Invoice_Line_StatusArgs = {
  where: Inventory_Supplier_Invoice_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Invoice_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Invoice_StatusArgs = {
  where: Inventory_Supplier_Invoice_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Invoice_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_ProductArgs = {
  where: Inventory_Supplier_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_QuoteArgs = {
  where: Inventory_Supplier_Quote_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inventory_Supplier_Quote_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_ClientArgs = {
  where: Marketplace_Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Client_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Data_Collector_Merchant_Sales_TransformedArgs = {
  where: Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Data_Collector_Merchant_Sales_Transformed_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Master_SkuArgs = {
  where: Marketplace_Master_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Master_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Master_Sku_Marketplace_Unit_SkuArgs = {
  where: Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Master_Sku_Marketplace_Unit_Sku_By_PkArgs = {
  marketplace_master_sku_id: Scalars["Int"];
  marketplace_unit_sku_id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_MerchantsArgs = {
  where: Marketplace_Merchants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Floomx_Replens_OrderArgs = {
  where: Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Floomx_Replens_Order_By_PkArgs = {
  order_id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Floomx_Replens_Order_ItemsArgs = {
  where: Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Floomx_Replens_Order_Items_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_RawArgs = {
  where: Marketplace_Merchants_Raw_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_ReplensArgs = {
  where: Marketplace_Merchants_Replens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Replens_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Replens_OrderArgs = {
  where: Marketplace_Merchants_Replens_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Replens_Order_By_PkArgs = {
  order_id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_SalesArgs = {
  where: Marketplace_Merchants_Sales_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Sales_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Starting_StocksArgs = {
  where: Marketplace_Merchants_Starting_Stocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Starting_Stocks_By_PkArgs = {
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_StocksArgs = {
  where: Marketplace_Merchants_Stocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Stocks_By_PkArgs = {
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Stocks_V2Args = {
  where: Marketplace_Merchants_Stocks_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Merchants_Stocks_V2_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Replenishment_EmailArgs = {
  where: Marketplace_Replenishment_Email_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Replenishment_Email_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Replenishment_SuggestionsArgs = {
  where: Marketplace_Replenishment_Suggestions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Replenishment_Suggestions_By_PkArgs = {
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Replenishment_Suggestions_V2Args = {
  where: Marketplace_Replenishment_Suggestions_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Replenishment_Suggestions_V2_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Unit_SkuArgs = {
  where: Marketplace_Unit_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Marketplace_Unit_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  uid: Scalars["String"];
};

/** mutation root */
export type Mutation_RootInsert_Inventory_AccountArgs = {
  objects: Array<Inventory_Account_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_Meeting_NoteArgs = {
  objects: Array<Inventory_Account_Meeting_Note_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Meeting_Note_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_Meeting_Note_OneArgs = {
  object: Inventory_Account_Meeting_Note_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Meeting_Note_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_OneArgs = {
  object: Inventory_Account_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_ReferralArgs = {
  objects: Array<Inventory_Account_Referral_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Referral_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_Referral_OneArgs = {
  object: Inventory_Account_Referral_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Referral_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_RoleArgs = {
  objects: Array<Inventory_Account_Role_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_Role_OneArgs = {
  object: Inventory_Account_Role_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_SectorArgs = {
  objects: Array<Inventory_Account_Sector_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Sector_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_Sector_OneArgs = {
  object: Inventory_Account_Sector_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Sector_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_StatusArgs = {
  objects: Array<Inventory_Account_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Account_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Account_Status_OneArgs = {
  object: Inventory_Account_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Account_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_AddressArgs = {
  objects: Array<Inventory_Address_Insert_Input>;
  on_conflict?: Maybe<Inventory_Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Address_OneArgs = {
  object: Inventory_Address_Insert_Input;
  on_conflict?: Maybe<Inventory_Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_AttachmentArgs = {
  objects: Array<Inventory_Attachment_Insert_Input>;
  on_conflict?: Maybe<Inventory_Attachment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Attachment_FolderArgs = {
  objects: Array<Inventory_Attachment_Folder_Insert_Input>;
  on_conflict?: Maybe<Inventory_Attachment_Folder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Attachment_Folder_OneArgs = {
  object: Inventory_Attachment_Folder_Insert_Input;
  on_conflict?: Maybe<Inventory_Attachment_Folder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Attachment_OneArgs = {
  object: Inventory_Attachment_Insert_Input;
  on_conflict?: Maybe<Inventory_Attachment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Base_ProductArgs = {
  objects: Array<Inventory_Base_Product_Insert_Input>;
  on_conflict?: Maybe<Inventory_Base_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Base_Product_OneArgs = {
  object: Inventory_Base_Product_Insert_Input;
  on_conflict?: Maybe<Inventory_Base_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_BatchArgs = {
  objects: Array<Inventory_Batch_Insert_Input>;
  on_conflict?: Maybe<Inventory_Batch_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Batch_OneArgs = {
  object: Inventory_Batch_Insert_Input;
  on_conflict?: Maybe<Inventory_Batch_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_InvoiceArgs = {
  objects: Array<Inventory_Client_Invoice_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Invoice_LineArgs = {
  objects: Array<Inventory_Client_Invoice_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Invoice_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Invoice_Line_OneArgs = {
  object: Inventory_Client_Invoice_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Invoice_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Invoice_Line_StatusArgs = {
  objects: Array<Inventory_Client_Invoice_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Invoice_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Invoice_Line_Status_OneArgs = {
  object: Inventory_Client_Invoice_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Invoice_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Invoice_OneArgs = {
  object: Inventory_Client_Invoice_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Invoice_StatusArgs = {
  objects: Array<Inventory_Client_Invoice_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Invoice_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Invoice_Status_OneArgs = {
  object: Inventory_Client_Invoice_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Invoice_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_OrderArgs = {
  objects: Array<Inventory_Client_Order_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_LineArgs = {
  objects: Array<Inventory_Client_Order_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_Line_OneArgs = {
  object: Inventory_Client_Order_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_Line_StatusArgs = {
  objects: Array<Inventory_Client_Order_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_Line_Status_OneArgs = {
  object: Inventory_Client_Order_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_Line_TypeArgs = {
  objects: Array<Inventory_Client_Order_Line_Type_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Line_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_Line_Type_OneArgs = {
  object: Inventory_Client_Order_Line_Type_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Line_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_OneArgs = {
  object: Inventory_Client_Order_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_StatusArgs = {
  objects: Array<Inventory_Client_Order_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_Status_OneArgs = {
  object: Inventory_Client_Order_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_TypeArgs = {
  objects: Array<Inventory_Client_Order_Type_Insert_Input>;
  on_conflict?: Maybe<Inventory_Client_Order_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Client_Order_Type_OneArgs = {
  object: Inventory_Client_Order_Type_Insert_Input;
  on_conflict?: Maybe<Inventory_Client_Order_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_CountryArgs = {
  objects: Array<Inventory_Country_Insert_Input>;
  on_conflict?: Maybe<Inventory_Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Country_OneArgs = {
  object: Inventory_Country_Insert_Input;
  on_conflict?: Maybe<Inventory_Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_CourierArgs = {
  objects: Array<Inventory_Courier_Insert_Input>;
  on_conflict?: Maybe<Inventory_Courier_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Courier_OneArgs = {
  object: Inventory_Courier_Insert_Input;
  on_conflict?: Maybe<Inventory_Courier_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Courier_ServiceArgs = {
  objects: Array<Inventory_Courier_Service_Insert_Input>;
  on_conflict?: Maybe<Inventory_Courier_Service_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Courier_Service_OneArgs = {
  object: Inventory_Courier_Service_Insert_Input;
  on_conflict?: Maybe<Inventory_Courier_Service_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Courier_TypeArgs = {
  objects: Array<Inventory_Courier_Type_Insert_Input>;
  on_conflict?: Maybe<Inventory_Courier_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Courier_Type_OneArgs = {
  object: Inventory_Courier_Type_Insert_Input;
  on_conflict?: Maybe<Inventory_Courier_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_CurrencyArgs = {
  objects: Array<Inventory_Currency_Insert_Input>;
  on_conflict?: Maybe<Inventory_Currency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Currency_OneArgs = {
  object: Inventory_Currency_Insert_Input;
  on_conflict?: Maybe<Inventory_Currency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Date_DArgs = {
  objects: Array<Inventory_Date_D_Insert_Input>;
  on_conflict?: Maybe<Inventory_Date_D_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Date_D_OneArgs = {
  object: Inventory_Date_D_Insert_Input;
  on_conflict?: Maybe<Inventory_Date_D_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Exchange_Rate_GbpArgs = {
  objects: Array<Inventory_Exchange_Rate_Gbp_Insert_Input>;
  on_conflict?: Maybe<Inventory_Exchange_Rate_Gbp_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Exchange_Rate_Gbp_OneArgs = {
  object: Inventory_Exchange_Rate_Gbp_Insert_Input;
  on_conflict?: Maybe<Inventory_Exchange_Rate_Gbp_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_FolderArgs = {
  objects: Array<Inventory_Folder_Insert_Input>;
  on_conflict?: Maybe<Inventory_Folder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Folder_OneArgs = {
  object: Inventory_Folder_Insert_Input;
  on_conflict?: Maybe<Inventory_Folder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_LocaleArgs = {
  objects: Array<Inventory_Locale_Insert_Input>;
  on_conflict?: Maybe<Inventory_Locale_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Locale_OneArgs = {
  object: Inventory_Locale_Insert_Input;
  on_conflict?: Maybe<Inventory_Locale_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_LocationArgs = {
  objects: Array<Inventory_Location_Insert_Input>;
  on_conflict?: Maybe<Inventory_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Location_OneArgs = {
  object: Inventory_Location_Insert_Input;
  on_conflict?: Maybe<Inventory_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_LocationsArgs = {
  objects: Array<Inventory_Management_Locations_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Locations_AncestryArgs = {
  objects: Array<Inventory_Management_Locations_Ancestry_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Locations_Ancestry_OneArgs = {
  object: Inventory_Management_Locations_Ancestry_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Locations_OneArgs = {
  object: Inventory_Management_Locations_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_OwnersArgs = {
  objects: Array<Inventory_Management_Owners_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Owners_OneArgs = {
  object: Inventory_Management_Owners_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_ServicesArgs = {
  objects: Array<Inventory_Management_Services_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Services_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Services_OneArgs = {
  object: Inventory_Management_Services_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Services_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_SkuArgs = {
  objects: Array<Inventory_Management_Sku_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Sku_OneArgs = {
  object: Inventory_Management_Sku_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Stock_ChangeArgs = {
  objects: Array<Inventory_Management_Stock_Change_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Stock_Change_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Stock_Change_OneArgs = {
  object: Inventory_Management_Stock_Change_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Stock_Change_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_StocksArgs = {
  objects: Array<Inventory_Management_Stocks_Insert_Input>;
  on_conflict?: Maybe<Inventory_Management_Stocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Management_Stocks_OneArgs = {
  object: Inventory_Management_Stocks_Insert_Input;
  on_conflict?: Maybe<Inventory_Management_Stocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Meeting_NoteArgs = {
  objects: Array<Inventory_Meeting_Note_Insert_Input>;
  on_conflict?: Maybe<Inventory_Meeting_Note_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Meeting_Note_OneArgs = {
  object: Inventory_Meeting_Note_Insert_Input;
  on_conflict?: Maybe<Inventory_Meeting_Note_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Meeting_Note_StatusArgs = {
  objects: Array<Inventory_Meeting_Note_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Meeting_Note_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Meeting_Note_Status_OneArgs = {
  object: Inventory_Meeting_Note_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Meeting_Note_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_ProductArgs = {
  objects: Array<Inventory_Product_Insert_Input>;
  on_conflict?: Maybe<Inventory_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Product_OneArgs = {
  object: Inventory_Product_Insert_Input;
  on_conflict?: Maybe<Inventory_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Product_PricingArgs = {
  objects: Array<Inventory_Product_Pricing_Insert_Input>;
  on_conflict?: Maybe<Inventory_Product_Pricing_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Product_Pricing_OneArgs = {
  object: Inventory_Product_Pricing_Insert_Input;
  on_conflict?: Maybe<Inventory_Product_Pricing_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Product_StockArgs = {
  objects: Array<Inventory_Product_Stock_Insert_Input>;
  on_conflict?: Maybe<Inventory_Product_Stock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Product_Stock_OneArgs = {
  object: Inventory_Product_Stock_Insert_Input;
  on_conflict?: Maybe<Inventory_Product_Stock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Product_Stock_OverviewArgs = {
  objects: Array<Inventory_Product_Stock_Overview_Insert_Input>;
  on_conflict?: Maybe<Inventory_Product_Stock_Overview_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Product_Stock_Overview_OneArgs = {
  object: Inventory_Product_Stock_Overview_Insert_Input;
  on_conflict?: Maybe<Inventory_Product_Stock_Overview_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Production_OrderArgs = {
  objects: Array<Inventory_Production_Order_Insert_Input>;
  on_conflict?: Maybe<Inventory_Production_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Production_Order_LineArgs = {
  objects: Array<Inventory_Production_Order_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Production_Order_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Production_Order_Line_OneArgs = {
  object: Inventory_Production_Order_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Production_Order_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Production_Order_Line_StatusArgs = {
  objects: Array<Inventory_Production_Order_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Production_Order_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Production_Order_Line_Status_OneArgs = {
  object: Inventory_Production_Order_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Production_Order_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Production_Order_OneArgs = {
  object: Inventory_Production_Order_Insert_Input;
  on_conflict?: Maybe<Inventory_Production_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Production_Order_StatusArgs = {
  objects: Array<Inventory_Production_Order_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Production_Order_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Production_Order_Status_OneArgs = {
  object: Inventory_Production_Order_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Production_Order_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_RoleArgs = {
  objects: Array<Inventory_Role_Insert_Input>;
  on_conflict?: Maybe<Inventory_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Role_OneArgs = {
  object: Inventory_Role_Insert_Input;
  on_conflict?: Maybe<Inventory_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_ShipmentArgs = {
  objects: Array<Inventory_Shipment_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_InvoiceArgs = {
  objects: Array<Inventory_Shipment_Invoice_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_Invoice_OneArgs = {
  object: Inventory_Shipment_Invoice_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_LineArgs = {
  objects: Array<Inventory_Shipment_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_Line_OneArgs = {
  object: Inventory_Shipment_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_Line_StatusArgs = {
  objects: Array<Inventory_Shipment_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_Line_Status_OneArgs = {
  object: Inventory_Shipment_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_OneArgs = {
  object: Inventory_Shipment_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_StatusArgs = {
  objects: Array<Inventory_Shipment_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Shipment_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Shipment_Status_OneArgs = {
  object: Inventory_Shipment_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Shipment_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Stock_StatusArgs = {
  objects: Array<Inventory_Stock_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Stock_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Stock_Status_OneArgs = {
  object: Inventory_Stock_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Stock_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_InvoiceArgs = {
  objects: Array<Inventory_Supplier_Invoice_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Invoice_LineArgs = {
  objects: Array<Inventory_Supplier_Invoice_Line_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Invoice_Line_OneArgs = {
  object: Inventory_Supplier_Invoice_Line_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Invoice_Line_StatusArgs = {
  objects: Array<Inventory_Supplier_Invoice_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Invoice_Line_Status_OneArgs = {
  object: Inventory_Supplier_Invoice_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Invoice_OneArgs = {
  object: Inventory_Supplier_Invoice_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Invoice_StatusArgs = {
  objects: Array<Inventory_Supplier_Invoice_Status_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Invoice_Status_OneArgs = {
  object: Inventory_Supplier_Invoice_Status_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Invoice_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_ProductArgs = {
  objects: Array<Inventory_Supplier_Product_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Product_OneArgs = {
  object: Inventory_Supplier_Product_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_QuoteArgs = {
  objects: Array<Inventory_Supplier_Quote_Insert_Input>;
  on_conflict?: Maybe<Inventory_Supplier_Quote_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inventory_Supplier_Quote_OneArgs = {
  object: Inventory_Supplier_Quote_Insert_Input;
  on_conflict?: Maybe<Inventory_Supplier_Quote_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_ClientArgs = {
  objects: Array<Marketplace_Client_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Client_OneArgs = {
  object: Marketplace_Client_Insert_Input;
  on_conflict?: Maybe<Marketplace_Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Data_Collector_Merchant_Sales_TransformedArgs = {
  objects: Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Data_Collector_Merchant_Sales_Transformed_OneArgs = {
  object: Marketplace_Data_Collector_Merchant_Sales_Transformed_Insert_Input;
  on_conflict?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Master_SkuArgs = {
  objects: Array<Marketplace_Master_Sku_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Master_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Master_Sku_Marketplace_Unit_SkuArgs = {
  objects: Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Master_Sku_Marketplace_Unit_Sku_OneArgs = {
  object: Marketplace_Master_Sku_Marketplace_Unit_Sku_Insert_Input;
  on_conflict?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Master_Sku_OneArgs = {
  object: Marketplace_Master_Sku_Insert_Input;
  on_conflict?: Maybe<Marketplace_Master_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_MerchantsArgs = {
  objects: Array<Marketplace_Merchants_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Floomx_Replens_OrderArgs = {
  objects: Array<Marketplace_Merchants_Floomx_Replens_Order_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Floomx_Replens_Order_ItemsArgs = {
  objects: Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Floomx_Replens_Order_Items_OneArgs = {
  object: Marketplace_Merchants_Floomx_Replens_Order_Items_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Floomx_Replens_Order_OneArgs = {
  object: Marketplace_Merchants_Floomx_Replens_Order_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_OneArgs = {
  object: Marketplace_Merchants_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_RawArgs = {
  objects: Array<Marketplace_Merchants_Raw_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Raw_OneArgs = {
  object: Marketplace_Merchants_Raw_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_ReplensArgs = {
  objects: Array<Marketplace_Merchants_Replens_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Replens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Replens_OneArgs = {
  object: Marketplace_Merchants_Replens_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Replens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Replens_OrderArgs = {
  objects: Array<Marketplace_Merchants_Replens_Order_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Replens_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Replens_Order_OneArgs = {
  object: Marketplace_Merchants_Replens_Order_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Replens_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_SalesArgs = {
  objects: Array<Marketplace_Merchants_Sales_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Sales_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Sales_OneArgs = {
  object: Marketplace_Merchants_Sales_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Sales_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Starting_StocksArgs = {
  objects: Array<Marketplace_Merchants_Starting_Stocks_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Starting_Stocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Starting_Stocks_OneArgs = {
  object: Marketplace_Merchants_Starting_Stocks_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Starting_Stocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_StocksArgs = {
  objects: Array<Marketplace_Merchants_Stocks_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Stocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Stocks_OneArgs = {
  object: Marketplace_Merchants_Stocks_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Stocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Stocks_V2Args = {
  objects: Array<Marketplace_Merchants_Stocks_V2_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Merchants_Stocks_V2_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Merchants_Stocks_V2_OneArgs = {
  object: Marketplace_Merchants_Stocks_V2_Insert_Input;
  on_conflict?: Maybe<Marketplace_Merchants_Stocks_V2_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Replenishment_EmailArgs = {
  objects: Array<Marketplace_Replenishment_Email_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Replenishment_Email_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Replenishment_Email_OneArgs = {
  object: Marketplace_Replenishment_Email_Insert_Input;
  on_conflict?: Maybe<Marketplace_Replenishment_Email_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Replenishment_SuggestionsArgs = {
  objects: Array<Marketplace_Replenishment_Suggestions_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Replenishment_Suggestions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Replenishment_Suggestions_OneArgs = {
  object: Marketplace_Replenishment_Suggestions_Insert_Input;
  on_conflict?: Maybe<Marketplace_Replenishment_Suggestions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Replenishment_Suggestions_V2Args = {
  objects: Array<Marketplace_Replenishment_Suggestions_V2_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Replenishment_Suggestions_V2_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Replenishment_Suggestions_V2_OneArgs = {
  object: Marketplace_Replenishment_Suggestions_V2_Insert_Input;
  on_conflict?: Maybe<Marketplace_Replenishment_Suggestions_V2_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Unit_SkuArgs = {
  objects: Array<Marketplace_Unit_Sku_Insert_Input>;
  on_conflict?: Maybe<Marketplace_Unit_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Marketplace_Unit_Sku_OneArgs = {
  object: Marketplace_Unit_Sku_Insert_Input;
  on_conflict?: Maybe<Marketplace_Unit_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_AccountArgs = {
  _append?: Maybe<Inventory_Account_Append_Input>;
  _delete_at_path?: Maybe<Inventory_Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Inventory_Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Inventory_Account_Delete_Key_Input>;
  _inc?: Maybe<Inventory_Account_Inc_Input>;
  _prepend?: Maybe<Inventory_Account_Prepend_Input>;
  _set?: Maybe<Inventory_Account_Set_Input>;
  where: Inventory_Account_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_By_PkArgs = {
  _append?: Maybe<Inventory_Account_Append_Input>;
  _delete_at_path?: Maybe<Inventory_Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Inventory_Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Inventory_Account_Delete_Key_Input>;
  _inc?: Maybe<Inventory_Account_Inc_Input>;
  _prepend?: Maybe<Inventory_Account_Prepend_Input>;
  _set?: Maybe<Inventory_Account_Set_Input>;
  pk_columns: Inventory_Account_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_Meeting_NoteArgs = {
  _inc?: Maybe<Inventory_Account_Meeting_Note_Inc_Input>;
  _set?: Maybe<Inventory_Account_Meeting_Note_Set_Input>;
  where: Inventory_Account_Meeting_Note_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_Meeting_Note_By_PkArgs = {
  _inc?: Maybe<Inventory_Account_Meeting_Note_Inc_Input>;
  _set?: Maybe<Inventory_Account_Meeting_Note_Set_Input>;
  pk_columns: Inventory_Account_Meeting_Note_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_ReferralArgs = {
  _inc?: Maybe<Inventory_Account_Referral_Inc_Input>;
  _set?: Maybe<Inventory_Account_Referral_Set_Input>;
  where: Inventory_Account_Referral_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_Referral_By_PkArgs = {
  _inc?: Maybe<Inventory_Account_Referral_Inc_Input>;
  _set?: Maybe<Inventory_Account_Referral_Set_Input>;
  pk_columns: Inventory_Account_Referral_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_RoleArgs = {
  _inc?: Maybe<Inventory_Account_Role_Inc_Input>;
  _set?: Maybe<Inventory_Account_Role_Set_Input>;
  where: Inventory_Account_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_Role_By_PkArgs = {
  _inc?: Maybe<Inventory_Account_Role_Inc_Input>;
  _set?: Maybe<Inventory_Account_Role_Set_Input>;
  pk_columns: Inventory_Account_Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_SectorArgs = {
  _inc?: Maybe<Inventory_Account_Sector_Inc_Input>;
  _set?: Maybe<Inventory_Account_Sector_Set_Input>;
  where: Inventory_Account_Sector_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_Sector_By_PkArgs = {
  _inc?: Maybe<Inventory_Account_Sector_Inc_Input>;
  _set?: Maybe<Inventory_Account_Sector_Set_Input>;
  pk_columns: Inventory_Account_Sector_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_StatusArgs = {
  _inc?: Maybe<Inventory_Account_Status_Inc_Input>;
  _set?: Maybe<Inventory_Account_Status_Set_Input>;
  where: Inventory_Account_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Account_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Account_Status_Inc_Input>;
  _set?: Maybe<Inventory_Account_Status_Set_Input>;
  pk_columns: Inventory_Account_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_AddressArgs = {
  _inc?: Maybe<Inventory_Address_Inc_Input>;
  _set?: Maybe<Inventory_Address_Set_Input>;
  where: Inventory_Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Address_By_PkArgs = {
  _inc?: Maybe<Inventory_Address_Inc_Input>;
  _set?: Maybe<Inventory_Address_Set_Input>;
  pk_columns: Inventory_Address_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_AttachmentArgs = {
  _append?: Maybe<Inventory_Attachment_Append_Input>;
  _delete_at_path?: Maybe<Inventory_Attachment_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Inventory_Attachment_Delete_Elem_Input>;
  _delete_key?: Maybe<Inventory_Attachment_Delete_Key_Input>;
  _inc?: Maybe<Inventory_Attachment_Inc_Input>;
  _prepend?: Maybe<Inventory_Attachment_Prepend_Input>;
  _set?: Maybe<Inventory_Attachment_Set_Input>;
  where: Inventory_Attachment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Attachment_By_PkArgs = {
  _append?: Maybe<Inventory_Attachment_Append_Input>;
  _delete_at_path?: Maybe<Inventory_Attachment_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Inventory_Attachment_Delete_Elem_Input>;
  _delete_key?: Maybe<Inventory_Attachment_Delete_Key_Input>;
  _inc?: Maybe<Inventory_Attachment_Inc_Input>;
  _prepend?: Maybe<Inventory_Attachment_Prepend_Input>;
  _set?: Maybe<Inventory_Attachment_Set_Input>;
  pk_columns: Inventory_Attachment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Attachment_FolderArgs = {
  _inc?: Maybe<Inventory_Attachment_Folder_Inc_Input>;
  _set?: Maybe<Inventory_Attachment_Folder_Set_Input>;
  where: Inventory_Attachment_Folder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Attachment_Folder_By_PkArgs = {
  _inc?: Maybe<Inventory_Attachment_Folder_Inc_Input>;
  _set?: Maybe<Inventory_Attachment_Folder_Set_Input>;
  pk_columns: Inventory_Attachment_Folder_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Base_ProductArgs = {
  _inc?: Maybe<Inventory_Base_Product_Inc_Input>;
  _set?: Maybe<Inventory_Base_Product_Set_Input>;
  where: Inventory_Base_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Base_Product_By_PkArgs = {
  _inc?: Maybe<Inventory_Base_Product_Inc_Input>;
  _set?: Maybe<Inventory_Base_Product_Set_Input>;
  pk_columns: Inventory_Base_Product_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_BatchArgs = {
  _inc?: Maybe<Inventory_Batch_Inc_Input>;
  _set?: Maybe<Inventory_Batch_Set_Input>;
  where: Inventory_Batch_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Batch_By_PkArgs = {
  _inc?: Maybe<Inventory_Batch_Inc_Input>;
  _set?: Maybe<Inventory_Batch_Set_Input>;
  pk_columns: Inventory_Batch_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_InvoiceArgs = {
  _inc?: Maybe<Inventory_Client_Invoice_Inc_Input>;
  _set?: Maybe<Inventory_Client_Invoice_Set_Input>;
  where: Inventory_Client_Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Invoice_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Invoice_Inc_Input>;
  _set?: Maybe<Inventory_Client_Invoice_Set_Input>;
  pk_columns: Inventory_Client_Invoice_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Invoice_LineArgs = {
  _inc?: Maybe<Inventory_Client_Invoice_Line_Inc_Input>;
  _set?: Maybe<Inventory_Client_Invoice_Line_Set_Input>;
  where: Inventory_Client_Invoice_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Invoice_Line_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Invoice_Line_Inc_Input>;
  _set?: Maybe<Inventory_Client_Invoice_Line_Set_Input>;
  pk_columns: Inventory_Client_Invoice_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Invoice_Line_StatusArgs = {
  _inc?: Maybe<Inventory_Client_Invoice_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Client_Invoice_Line_Status_Set_Input>;
  where: Inventory_Client_Invoice_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Invoice_Line_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Invoice_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Client_Invoice_Line_Status_Set_Input>;
  pk_columns: Inventory_Client_Invoice_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Invoice_StatusArgs = {
  _inc?: Maybe<Inventory_Client_Invoice_Status_Inc_Input>;
  _set?: Maybe<Inventory_Client_Invoice_Status_Set_Input>;
  where: Inventory_Client_Invoice_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Invoice_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Invoice_Status_Inc_Input>;
  _set?: Maybe<Inventory_Client_Invoice_Status_Set_Input>;
  pk_columns: Inventory_Client_Invoice_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_OrderArgs = {
  _inc?: Maybe<Inventory_Client_Order_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Set_Input>;
  where: Inventory_Client_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Order_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Set_Input>;
  pk_columns: Inventory_Client_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_LineArgs = {
  _inc?: Maybe<Inventory_Client_Order_Line_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Line_Set_Input>;
  where: Inventory_Client_Order_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_Line_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Order_Line_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Line_Set_Input>;
  pk_columns: Inventory_Client_Order_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_Line_StatusArgs = {
  _inc?: Maybe<Inventory_Client_Order_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Line_Status_Set_Input>;
  where: Inventory_Client_Order_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_Line_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Order_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Line_Status_Set_Input>;
  pk_columns: Inventory_Client_Order_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_Line_TypeArgs = {
  _inc?: Maybe<Inventory_Client_Order_Line_Type_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Line_Type_Set_Input>;
  where: Inventory_Client_Order_Line_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_Line_Type_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Order_Line_Type_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Line_Type_Set_Input>;
  pk_columns: Inventory_Client_Order_Line_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_StatusArgs = {
  _inc?: Maybe<Inventory_Client_Order_Status_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Status_Set_Input>;
  where: Inventory_Client_Order_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Order_Status_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Status_Set_Input>;
  pk_columns: Inventory_Client_Order_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_TypeArgs = {
  _inc?: Maybe<Inventory_Client_Order_Type_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Type_Set_Input>;
  where: Inventory_Client_Order_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Client_Order_Type_By_PkArgs = {
  _inc?: Maybe<Inventory_Client_Order_Type_Inc_Input>;
  _set?: Maybe<Inventory_Client_Order_Type_Set_Input>;
  pk_columns: Inventory_Client_Order_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_CountryArgs = {
  _set?: Maybe<Inventory_Country_Set_Input>;
  where: Inventory_Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Country_By_PkArgs = {
  _set?: Maybe<Inventory_Country_Set_Input>;
  pk_columns: Inventory_Country_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_CourierArgs = {
  _inc?: Maybe<Inventory_Courier_Inc_Input>;
  _set?: Maybe<Inventory_Courier_Set_Input>;
  where: Inventory_Courier_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Courier_By_PkArgs = {
  _inc?: Maybe<Inventory_Courier_Inc_Input>;
  _set?: Maybe<Inventory_Courier_Set_Input>;
  pk_columns: Inventory_Courier_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Courier_ServiceArgs = {
  _inc?: Maybe<Inventory_Courier_Service_Inc_Input>;
  _set?: Maybe<Inventory_Courier_Service_Set_Input>;
  where: Inventory_Courier_Service_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Courier_Service_By_PkArgs = {
  _inc?: Maybe<Inventory_Courier_Service_Inc_Input>;
  _set?: Maybe<Inventory_Courier_Service_Set_Input>;
  pk_columns: Inventory_Courier_Service_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Courier_TypeArgs = {
  _inc?: Maybe<Inventory_Courier_Type_Inc_Input>;
  _set?: Maybe<Inventory_Courier_Type_Set_Input>;
  where: Inventory_Courier_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Courier_Type_By_PkArgs = {
  _inc?: Maybe<Inventory_Courier_Type_Inc_Input>;
  _set?: Maybe<Inventory_Courier_Type_Set_Input>;
  pk_columns: Inventory_Courier_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_CurrencyArgs = {
  _set?: Maybe<Inventory_Currency_Set_Input>;
  where: Inventory_Currency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Currency_By_PkArgs = {
  _set?: Maybe<Inventory_Currency_Set_Input>;
  pk_columns: Inventory_Currency_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Date_DArgs = {
  _inc?: Maybe<Inventory_Date_D_Inc_Input>;
  _set?: Maybe<Inventory_Date_D_Set_Input>;
  where: Inventory_Date_D_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Date_D_By_PkArgs = {
  _inc?: Maybe<Inventory_Date_D_Inc_Input>;
  _set?: Maybe<Inventory_Date_D_Set_Input>;
  pk_columns: Inventory_Date_D_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Exchange_Rate_GbpArgs = {
  _inc?: Maybe<Inventory_Exchange_Rate_Gbp_Inc_Input>;
  _set?: Maybe<Inventory_Exchange_Rate_Gbp_Set_Input>;
  where: Inventory_Exchange_Rate_Gbp_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Exchange_Rate_Gbp_By_PkArgs = {
  _inc?: Maybe<Inventory_Exchange_Rate_Gbp_Inc_Input>;
  _set?: Maybe<Inventory_Exchange_Rate_Gbp_Set_Input>;
  pk_columns: Inventory_Exchange_Rate_Gbp_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_FolderArgs = {
  _inc?: Maybe<Inventory_Folder_Inc_Input>;
  _set?: Maybe<Inventory_Folder_Set_Input>;
  where: Inventory_Folder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Folder_By_PkArgs = {
  _inc?: Maybe<Inventory_Folder_Inc_Input>;
  _set?: Maybe<Inventory_Folder_Set_Input>;
  pk_columns: Inventory_Folder_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_LocaleArgs = {
  _set?: Maybe<Inventory_Locale_Set_Input>;
  where: Inventory_Locale_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Locale_By_PkArgs = {
  _set?: Maybe<Inventory_Locale_Set_Input>;
  pk_columns: Inventory_Locale_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_LocationArgs = {
  _inc?: Maybe<Inventory_Location_Inc_Input>;
  _set?: Maybe<Inventory_Location_Set_Input>;
  where: Inventory_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Location_By_PkArgs = {
  _inc?: Maybe<Inventory_Location_Inc_Input>;
  _set?: Maybe<Inventory_Location_Set_Input>;
  pk_columns: Inventory_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_LocationsArgs = {
  _inc?: Maybe<Inventory_Management_Locations_Inc_Input>;
  _set?: Maybe<Inventory_Management_Locations_Set_Input>;
  where: Inventory_Management_Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_Locations_AncestryArgs = {
  _inc?: Maybe<Inventory_Management_Locations_Ancestry_Inc_Input>;
  _set?: Maybe<Inventory_Management_Locations_Ancestry_Set_Input>;
  where: Inventory_Management_Locations_Ancestry_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_Locations_By_PkArgs = {
  _inc?: Maybe<Inventory_Management_Locations_Inc_Input>;
  _set?: Maybe<Inventory_Management_Locations_Set_Input>;
  pk_columns: Inventory_Management_Locations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_OwnersArgs = {
  _inc?: Maybe<Inventory_Management_Owners_Inc_Input>;
  _set?: Maybe<Inventory_Management_Owners_Set_Input>;
  where: Inventory_Management_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_Owners_By_PkArgs = {
  _inc?: Maybe<Inventory_Management_Owners_Inc_Input>;
  _set?: Maybe<Inventory_Management_Owners_Set_Input>;
  pk_columns: Inventory_Management_Owners_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_ServicesArgs = {
  _inc?: Maybe<Inventory_Management_Services_Inc_Input>;
  _set?: Maybe<Inventory_Management_Services_Set_Input>;
  where: Inventory_Management_Services_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_Services_By_PkArgs = {
  _inc?: Maybe<Inventory_Management_Services_Inc_Input>;
  _set?: Maybe<Inventory_Management_Services_Set_Input>;
  pk_columns: Inventory_Management_Services_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_SkuArgs = {
  _inc?: Maybe<Inventory_Management_Sku_Inc_Input>;
  _set?: Maybe<Inventory_Management_Sku_Set_Input>;
  where: Inventory_Management_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_Sku_By_PkArgs = {
  _inc?: Maybe<Inventory_Management_Sku_Inc_Input>;
  _set?: Maybe<Inventory_Management_Sku_Set_Input>;
  pk_columns: Inventory_Management_Sku_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_Stock_ChangeArgs = {
  _inc?: Maybe<Inventory_Management_Stock_Change_Inc_Input>;
  _set?: Maybe<Inventory_Management_Stock_Change_Set_Input>;
  where: Inventory_Management_Stock_Change_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_Stock_Change_By_PkArgs = {
  _inc?: Maybe<Inventory_Management_Stock_Change_Inc_Input>;
  _set?: Maybe<Inventory_Management_Stock_Change_Set_Input>;
  pk_columns: Inventory_Management_Stock_Change_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_StocksArgs = {
  _inc?: Maybe<Inventory_Management_Stocks_Inc_Input>;
  _set?: Maybe<Inventory_Management_Stocks_Set_Input>;
  where: Inventory_Management_Stocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Management_Stocks_By_PkArgs = {
  _inc?: Maybe<Inventory_Management_Stocks_Inc_Input>;
  _set?: Maybe<Inventory_Management_Stocks_Set_Input>;
  pk_columns: Inventory_Management_Stocks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Meeting_NoteArgs = {
  _inc?: Maybe<Inventory_Meeting_Note_Inc_Input>;
  _set?: Maybe<Inventory_Meeting_Note_Set_Input>;
  where: Inventory_Meeting_Note_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Meeting_Note_By_PkArgs = {
  _inc?: Maybe<Inventory_Meeting_Note_Inc_Input>;
  _set?: Maybe<Inventory_Meeting_Note_Set_Input>;
  pk_columns: Inventory_Meeting_Note_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Meeting_Note_StatusArgs = {
  _inc?: Maybe<Inventory_Meeting_Note_Status_Inc_Input>;
  _set?: Maybe<Inventory_Meeting_Note_Status_Set_Input>;
  where: Inventory_Meeting_Note_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Meeting_Note_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Meeting_Note_Status_Inc_Input>;
  _set?: Maybe<Inventory_Meeting_Note_Status_Set_Input>;
  pk_columns: Inventory_Meeting_Note_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_ProductArgs = {
  _inc?: Maybe<Inventory_Product_Inc_Input>;
  _set?: Maybe<Inventory_Product_Set_Input>;
  where: Inventory_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Product_By_PkArgs = {
  _inc?: Maybe<Inventory_Product_Inc_Input>;
  _set?: Maybe<Inventory_Product_Set_Input>;
  pk_columns: Inventory_Product_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Product_PricingArgs = {
  _inc?: Maybe<Inventory_Product_Pricing_Inc_Input>;
  _set?: Maybe<Inventory_Product_Pricing_Set_Input>;
  where: Inventory_Product_Pricing_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Product_Pricing_By_PkArgs = {
  _inc?: Maybe<Inventory_Product_Pricing_Inc_Input>;
  _set?: Maybe<Inventory_Product_Pricing_Set_Input>;
  pk_columns: Inventory_Product_Pricing_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Product_StockArgs = {
  _inc?: Maybe<Inventory_Product_Stock_Inc_Input>;
  _set?: Maybe<Inventory_Product_Stock_Set_Input>;
  where: Inventory_Product_Stock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Product_Stock_By_PkArgs = {
  _inc?: Maybe<Inventory_Product_Stock_Inc_Input>;
  _set?: Maybe<Inventory_Product_Stock_Set_Input>;
  pk_columns: Inventory_Product_Stock_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Product_Stock_OverviewArgs = {
  _inc?: Maybe<Inventory_Product_Stock_Overview_Inc_Input>;
  _set?: Maybe<Inventory_Product_Stock_Overview_Set_Input>;
  where: Inventory_Product_Stock_Overview_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Production_OrderArgs = {
  _inc?: Maybe<Inventory_Production_Order_Inc_Input>;
  _set?: Maybe<Inventory_Production_Order_Set_Input>;
  where: Inventory_Production_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Production_Order_By_PkArgs = {
  _inc?: Maybe<Inventory_Production_Order_Inc_Input>;
  _set?: Maybe<Inventory_Production_Order_Set_Input>;
  pk_columns: Inventory_Production_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Production_Order_LineArgs = {
  _inc?: Maybe<Inventory_Production_Order_Line_Inc_Input>;
  _set?: Maybe<Inventory_Production_Order_Line_Set_Input>;
  where: Inventory_Production_Order_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Production_Order_Line_By_PkArgs = {
  _inc?: Maybe<Inventory_Production_Order_Line_Inc_Input>;
  _set?: Maybe<Inventory_Production_Order_Line_Set_Input>;
  pk_columns: Inventory_Production_Order_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Production_Order_Line_StatusArgs = {
  _inc?: Maybe<Inventory_Production_Order_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Production_Order_Line_Status_Set_Input>;
  where: Inventory_Production_Order_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Production_Order_Line_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Production_Order_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Production_Order_Line_Status_Set_Input>;
  pk_columns: Inventory_Production_Order_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Production_Order_StatusArgs = {
  _inc?: Maybe<Inventory_Production_Order_Status_Inc_Input>;
  _set?: Maybe<Inventory_Production_Order_Status_Set_Input>;
  where: Inventory_Production_Order_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Production_Order_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Production_Order_Status_Inc_Input>;
  _set?: Maybe<Inventory_Production_Order_Status_Set_Input>;
  pk_columns: Inventory_Production_Order_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_RoleArgs = {
  _inc?: Maybe<Inventory_Role_Inc_Input>;
  _set?: Maybe<Inventory_Role_Set_Input>;
  where: Inventory_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Role_By_PkArgs = {
  _inc?: Maybe<Inventory_Role_Inc_Input>;
  _set?: Maybe<Inventory_Role_Set_Input>;
  pk_columns: Inventory_Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_ShipmentArgs = {
  _inc?: Maybe<Inventory_Shipment_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Set_Input>;
  where: Inventory_Shipment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_By_PkArgs = {
  _inc?: Maybe<Inventory_Shipment_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Set_Input>;
  pk_columns: Inventory_Shipment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_InvoiceArgs = {
  _inc?: Maybe<Inventory_Shipment_Invoice_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Invoice_Set_Input>;
  where: Inventory_Shipment_Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_Invoice_By_PkArgs = {
  _inc?: Maybe<Inventory_Shipment_Invoice_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Invoice_Set_Input>;
  pk_columns: Inventory_Shipment_Invoice_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_LineArgs = {
  _inc?: Maybe<Inventory_Shipment_Line_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Line_Set_Input>;
  where: Inventory_Shipment_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_Line_By_PkArgs = {
  _inc?: Maybe<Inventory_Shipment_Line_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Line_Set_Input>;
  pk_columns: Inventory_Shipment_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_Line_StatusArgs = {
  _inc?: Maybe<Inventory_Shipment_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Line_Status_Set_Input>;
  where: Inventory_Shipment_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_Line_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Shipment_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Line_Status_Set_Input>;
  pk_columns: Inventory_Shipment_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_StatusArgs = {
  _inc?: Maybe<Inventory_Shipment_Status_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Status_Set_Input>;
  where: Inventory_Shipment_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Shipment_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Shipment_Status_Inc_Input>;
  _set?: Maybe<Inventory_Shipment_Status_Set_Input>;
  pk_columns: Inventory_Shipment_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Stock_StatusArgs = {
  _inc?: Maybe<Inventory_Stock_Status_Inc_Input>;
  _set?: Maybe<Inventory_Stock_Status_Set_Input>;
  where: Inventory_Stock_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Stock_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Stock_Status_Inc_Input>;
  _set?: Maybe<Inventory_Stock_Status_Set_Input>;
  pk_columns: Inventory_Stock_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_InvoiceArgs = {
  _inc?: Maybe<Inventory_Supplier_Invoice_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Invoice_Set_Input>;
  where: Inventory_Supplier_Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Invoice_By_PkArgs = {
  _inc?: Maybe<Inventory_Supplier_Invoice_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Invoice_Set_Input>;
  pk_columns: Inventory_Supplier_Invoice_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Invoice_LineArgs = {
  _inc?: Maybe<Inventory_Supplier_Invoice_Line_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Invoice_Line_Set_Input>;
  where: Inventory_Supplier_Invoice_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Invoice_Line_By_PkArgs = {
  _inc?: Maybe<Inventory_Supplier_Invoice_Line_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Invoice_Line_Set_Input>;
  pk_columns: Inventory_Supplier_Invoice_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Invoice_Line_StatusArgs = {
  _inc?: Maybe<Inventory_Supplier_Invoice_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Invoice_Line_Status_Set_Input>;
  where: Inventory_Supplier_Invoice_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Invoice_Line_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Supplier_Invoice_Line_Status_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Invoice_Line_Status_Set_Input>;
  pk_columns: Inventory_Supplier_Invoice_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Invoice_StatusArgs = {
  _inc?: Maybe<Inventory_Supplier_Invoice_Status_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Invoice_Status_Set_Input>;
  where: Inventory_Supplier_Invoice_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Invoice_Status_By_PkArgs = {
  _inc?: Maybe<Inventory_Supplier_Invoice_Status_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Invoice_Status_Set_Input>;
  pk_columns: Inventory_Supplier_Invoice_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_ProductArgs = {
  _inc?: Maybe<Inventory_Supplier_Product_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Product_Set_Input>;
  where: Inventory_Supplier_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Product_By_PkArgs = {
  _inc?: Maybe<Inventory_Supplier_Product_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Product_Set_Input>;
  pk_columns: Inventory_Supplier_Product_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_QuoteArgs = {
  _inc?: Maybe<Inventory_Supplier_Quote_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Quote_Set_Input>;
  where: Inventory_Supplier_Quote_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inventory_Supplier_Quote_By_PkArgs = {
  _inc?: Maybe<Inventory_Supplier_Quote_Inc_Input>;
  _set?: Maybe<Inventory_Supplier_Quote_Set_Input>;
  pk_columns: Inventory_Supplier_Quote_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_ClientArgs = {
  _inc?: Maybe<Marketplace_Client_Inc_Input>;
  _set?: Maybe<Marketplace_Client_Set_Input>;
  where: Marketplace_Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Client_By_PkArgs = {
  _inc?: Maybe<Marketplace_Client_Inc_Input>;
  _set?: Maybe<Marketplace_Client_Set_Input>;
  pk_columns: Marketplace_Client_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Data_Collector_Merchant_Sales_TransformedArgs = {
  _inc?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Inc_Input>;
  _set?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Set_Input>;
  where: Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Data_Collector_Merchant_Sales_Transformed_By_PkArgs = {
  _inc?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Inc_Input>;
  _set?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Set_Input>;
  pk_columns: Marketplace_Data_Collector_Merchant_Sales_Transformed_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Master_SkuArgs = {
  _inc?: Maybe<Marketplace_Master_Sku_Inc_Input>;
  _set?: Maybe<Marketplace_Master_Sku_Set_Input>;
  where: Marketplace_Master_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Master_Sku_By_PkArgs = {
  _inc?: Maybe<Marketplace_Master_Sku_Inc_Input>;
  _set?: Maybe<Marketplace_Master_Sku_Set_Input>;
  pk_columns: Marketplace_Master_Sku_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Master_Sku_Marketplace_Unit_SkuArgs = {
  _inc?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Inc_Input>;
  _set?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Set_Input>;
  where: Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Master_Sku_Marketplace_Unit_Sku_By_PkArgs = {
  _inc?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Inc_Input>;
  _set?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Set_Input>;
  pk_columns: Marketplace_Master_Sku_Marketplace_Unit_Sku_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_MerchantsArgs = {
  _inc?: Maybe<Marketplace_Merchants_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Set_Input>;
  where: Marketplace_Merchants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_By_PkArgs = {
  _inc?: Maybe<Marketplace_Merchants_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Set_Input>;
  pk_columns: Marketplace_Merchants_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Floomx_Replens_OrderArgs = {
  _inc?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Set_Input>;
  where: Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Floomx_Replens_Order_By_PkArgs = {
  _inc?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Set_Input>;
  pk_columns: Marketplace_Merchants_Floomx_Replens_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Floomx_Replens_Order_ItemsArgs = {
  _inc?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Set_Input>;
  where: Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Floomx_Replens_Order_Items_By_PkArgs = {
  _inc?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Set_Input>;
  pk_columns: Marketplace_Merchants_Floomx_Replens_Order_Items_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_RawArgs = {
  _inc?: Maybe<Marketplace_Merchants_Raw_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Raw_Set_Input>;
  where: Marketplace_Merchants_Raw_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_ReplensArgs = {
  _inc?: Maybe<Marketplace_Merchants_Replens_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Replens_Set_Input>;
  where: Marketplace_Merchants_Replens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Replens_By_PkArgs = {
  _inc?: Maybe<Marketplace_Merchants_Replens_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Replens_Set_Input>;
  pk_columns: Marketplace_Merchants_Replens_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Replens_OrderArgs = {
  _set?: Maybe<Marketplace_Merchants_Replens_Order_Set_Input>;
  where: Marketplace_Merchants_Replens_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Replens_Order_By_PkArgs = {
  _set?: Maybe<Marketplace_Merchants_Replens_Order_Set_Input>;
  pk_columns: Marketplace_Merchants_Replens_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_SalesArgs = {
  _inc?: Maybe<Marketplace_Merchants_Sales_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Sales_Set_Input>;
  where: Marketplace_Merchants_Sales_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Sales_By_PkArgs = {
  _inc?: Maybe<Marketplace_Merchants_Sales_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Sales_Set_Input>;
  pk_columns: Marketplace_Merchants_Sales_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Starting_StocksArgs = {
  _inc?: Maybe<Marketplace_Merchants_Starting_Stocks_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Starting_Stocks_Set_Input>;
  where: Marketplace_Merchants_Starting_Stocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Starting_Stocks_By_PkArgs = {
  _inc?: Maybe<Marketplace_Merchants_Starting_Stocks_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Starting_Stocks_Set_Input>;
  pk_columns: Marketplace_Merchants_Starting_Stocks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_StocksArgs = {
  _inc?: Maybe<Marketplace_Merchants_Stocks_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Stocks_Set_Input>;
  where: Marketplace_Merchants_Stocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Stocks_By_PkArgs = {
  _inc?: Maybe<Marketplace_Merchants_Stocks_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Stocks_Set_Input>;
  pk_columns: Marketplace_Merchants_Stocks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Stocks_V2Args = {
  _inc?: Maybe<Marketplace_Merchants_Stocks_V2_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Stocks_V2_Set_Input>;
  where: Marketplace_Merchants_Stocks_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Merchants_Stocks_V2_By_PkArgs = {
  _inc?: Maybe<Marketplace_Merchants_Stocks_V2_Inc_Input>;
  _set?: Maybe<Marketplace_Merchants_Stocks_V2_Set_Input>;
  pk_columns: Marketplace_Merchants_Stocks_V2_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Replenishment_EmailArgs = {
  _inc?: Maybe<Marketplace_Replenishment_Email_Inc_Input>;
  _set?: Maybe<Marketplace_Replenishment_Email_Set_Input>;
  where: Marketplace_Replenishment_Email_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Replenishment_Email_By_PkArgs = {
  _inc?: Maybe<Marketplace_Replenishment_Email_Inc_Input>;
  _set?: Maybe<Marketplace_Replenishment_Email_Set_Input>;
  pk_columns: Marketplace_Replenishment_Email_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Replenishment_SuggestionsArgs = {
  _inc?: Maybe<Marketplace_Replenishment_Suggestions_Inc_Input>;
  _set?: Maybe<Marketplace_Replenishment_Suggestions_Set_Input>;
  where: Marketplace_Replenishment_Suggestions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Replenishment_Suggestions_By_PkArgs = {
  _inc?: Maybe<Marketplace_Replenishment_Suggestions_Inc_Input>;
  _set?: Maybe<Marketplace_Replenishment_Suggestions_Set_Input>;
  pk_columns: Marketplace_Replenishment_Suggestions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Replenishment_Suggestions_V2Args = {
  _inc?: Maybe<Marketplace_Replenishment_Suggestions_V2_Inc_Input>;
  _set?: Maybe<Marketplace_Replenishment_Suggestions_V2_Set_Input>;
  where: Marketplace_Replenishment_Suggestions_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Replenishment_Suggestions_V2_By_PkArgs = {
  _inc?: Maybe<Marketplace_Replenishment_Suggestions_V2_Inc_Input>;
  _set?: Maybe<Marketplace_Replenishment_Suggestions_V2_Set_Input>;
  pk_columns: Marketplace_Replenishment_Suggestions_V2_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Unit_SkuArgs = {
  _inc?: Maybe<Marketplace_Unit_Sku_Inc_Input>;
  _set?: Maybe<Marketplace_Unit_Sku_Set_Input>;
  where: Marketplace_Unit_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Marketplace_Unit_Sku_By_PkArgs = {
  _inc?: Maybe<Marketplace_Unit_Sku_Inc_Input>;
  _set?: Maybe<Marketplace_Unit_Sku_Set_Input>;
  pk_columns: Marketplace_Unit_Sku_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars["numeric"]>;
  _gt?: Maybe<Scalars["numeric"]>;
  _gte?: Maybe<Scalars["numeric"]>;
  _in?: Maybe<Array<Scalars["numeric"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["numeric"]>;
  _lte?: Maybe<Scalars["numeric"]>;
  _neq?: Maybe<Scalars["numeric"]>;
  _nin?: Maybe<Array<Scalars["numeric"]>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = "asc",
  /** in the ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in the ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in the descending order, nulls first */
  Desc = "desc",
  /** in the descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in the descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** query root */
export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "inventory.account" */
  inventory_account: Array<Inventory_Account>;
  /** fetch aggregated fields from the table: "inventory.account" */
  inventory_account_aggregate: Inventory_Account_Aggregate;
  /** fetch data from the table: "inventory.account" using primary key columns */
  inventory_account_by_pk?: Maybe<Inventory_Account>;
  /** fetch data from the table: "inventory.account_meeting_note" */
  inventory_account_meeting_note: Array<Inventory_Account_Meeting_Note>;
  /** fetch aggregated fields from the table: "inventory.account_meeting_note" */
  inventory_account_meeting_note_aggregate: Inventory_Account_Meeting_Note_Aggregate;
  /** fetch data from the table: "inventory.account_meeting_note" using primary key columns */
  inventory_account_meeting_note_by_pk?: Maybe<Inventory_Account_Meeting_Note>;
  /** fetch data from the table: "inventory.account_metadata_keys" */
  inventory_account_metadata_keys: Array<Inventory_Account_Metadata_Keys>;
  /** fetch aggregated fields from the table: "inventory.account_metadata_keys" */
  inventory_account_metadata_keys_aggregate: Inventory_Account_Metadata_Keys_Aggregate;
  /** fetch data from the table: "inventory.account_referral" */
  inventory_account_referral: Array<Inventory_Account_Referral>;
  /** fetch aggregated fields from the table: "inventory.account_referral" */
  inventory_account_referral_aggregate: Inventory_Account_Referral_Aggregate;
  /** fetch data from the table: "inventory.account_referral" using primary key columns */
  inventory_account_referral_by_pk?: Maybe<Inventory_Account_Referral>;
  /** fetch data from the table: "inventory.account_role" */
  inventory_account_role: Array<Inventory_Account_Role>;
  /** fetch aggregated fields from the table: "inventory.account_role" */
  inventory_account_role_aggregate: Inventory_Account_Role_Aggregate;
  /** fetch data from the table: "inventory.account_role" using primary key columns */
  inventory_account_role_by_pk?: Maybe<Inventory_Account_Role>;
  /** fetch data from the table: "inventory.account_sector" */
  inventory_account_sector: Array<Inventory_Account_Sector>;
  /** fetch aggregated fields from the table: "inventory.account_sector" */
  inventory_account_sector_aggregate: Inventory_Account_Sector_Aggregate;
  /** fetch data from the table: "inventory.account_sector" using primary key columns */
  inventory_account_sector_by_pk?: Maybe<Inventory_Account_Sector>;
  /** fetch data from the table: "inventory.account_status" */
  inventory_account_status: Array<Inventory_Account_Status>;
  /** fetch aggregated fields from the table: "inventory.account_status" */
  inventory_account_status_aggregate: Inventory_Account_Status_Aggregate;
  /** fetch data from the table: "inventory.account_status" using primary key columns */
  inventory_account_status_by_pk?: Maybe<Inventory_Account_Status>;
  /** fetch data from the table: "inventory.address" */
  inventory_address: Array<Inventory_Address>;
  /** fetch aggregated fields from the table: "inventory.address" */
  inventory_address_aggregate: Inventory_Address_Aggregate;
  /** fetch data from the table: "inventory.address" using primary key columns */
  inventory_address_by_pk?: Maybe<Inventory_Address>;
  /** fetch data from the table: "inventory.attachment" */
  inventory_attachment: Array<Inventory_Attachment>;
  /** fetch aggregated fields from the table: "inventory.attachment" */
  inventory_attachment_aggregate: Inventory_Attachment_Aggregate;
  /** fetch data from the table: "inventory.attachment" using primary key columns */
  inventory_attachment_by_pk?: Maybe<Inventory_Attachment>;
  /** fetch data from the table: "inventory.attachment_folder" */
  inventory_attachment_folder: Array<Inventory_Attachment_Folder>;
  /** fetch aggregated fields from the table: "inventory.attachment_folder" */
  inventory_attachment_folder_aggregate: Inventory_Attachment_Folder_Aggregate;
  /** fetch data from the table: "inventory.attachment_folder" using primary key columns */
  inventory_attachment_folder_by_pk?: Maybe<Inventory_Attachment_Folder>;
  /** fetch data from the table: "inventory.base_product" */
  inventory_base_product: Array<Inventory_Base_Product>;
  /** fetch aggregated fields from the table: "inventory.base_product" */
  inventory_base_product_aggregate: Inventory_Base_Product_Aggregate;
  /** fetch data from the table: "inventory.base_product" using primary key columns */
  inventory_base_product_by_pk?: Maybe<Inventory_Base_Product>;
  /** fetch data from the table: "inventory.batch" */
  inventory_batch: Array<Inventory_Batch>;
  /** fetch aggregated fields from the table: "inventory.batch" */
  inventory_batch_aggregate: Inventory_Batch_Aggregate;
  /** fetch data from the table: "inventory.batch" using primary key columns */
  inventory_batch_by_pk?: Maybe<Inventory_Batch>;
  /** fetch data from the table: "inventory.client_invoice" */
  inventory_client_invoice: Array<Inventory_Client_Invoice>;
  /** fetch aggregated fields from the table: "inventory.client_invoice" */
  inventory_client_invoice_aggregate: Inventory_Client_Invoice_Aggregate;
  /** fetch data from the table: "inventory.client_invoice" using primary key columns */
  inventory_client_invoice_by_pk?: Maybe<Inventory_Client_Invoice>;
  /** fetch data from the table: "inventory.client_invoice_line" */
  inventory_client_invoice_line: Array<Inventory_Client_Invoice_Line>;
  /** fetch aggregated fields from the table: "inventory.client_invoice_line" */
  inventory_client_invoice_line_aggregate: Inventory_Client_Invoice_Line_Aggregate;
  /** fetch data from the table: "inventory.client_invoice_line" using primary key columns */
  inventory_client_invoice_line_by_pk?: Maybe<Inventory_Client_Invoice_Line>;
  /** fetch data from the table: "inventory.client_invoice_line_status" */
  inventory_client_invoice_line_status: Array<Inventory_Client_Invoice_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.client_invoice_line_status" */
  inventory_client_invoice_line_status_aggregate: Inventory_Client_Invoice_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.client_invoice_line_status" using primary key columns */
  inventory_client_invoice_line_status_by_pk?: Maybe<Inventory_Client_Invoice_Line_Status>;
  /** fetch data from the table: "inventory.client_invoice_status" */
  inventory_client_invoice_status: Array<Inventory_Client_Invoice_Status>;
  /** fetch aggregated fields from the table: "inventory.client_invoice_status" */
  inventory_client_invoice_status_aggregate: Inventory_Client_Invoice_Status_Aggregate;
  /** fetch data from the table: "inventory.client_invoice_status" using primary key columns */
  inventory_client_invoice_status_by_pk?: Maybe<Inventory_Client_Invoice_Status>;
  /** fetch data from the table: "inventory.client_order" */
  inventory_client_order: Array<Inventory_Client_Order>;
  /** fetch aggregated fields from the table: "inventory.client_order" */
  inventory_client_order_aggregate: Inventory_Client_Order_Aggregate;
  /** fetch data from the table: "inventory.client_order" using primary key columns */
  inventory_client_order_by_pk?: Maybe<Inventory_Client_Order>;
  /** fetch data from the table: "inventory.client_order_line" */
  inventory_client_order_line: Array<Inventory_Client_Order_Line>;
  /** fetch aggregated fields from the table: "inventory.client_order_line" */
  inventory_client_order_line_aggregate: Inventory_Client_Order_Line_Aggregate;
  /** fetch data from the table: "inventory.client_order_line" using primary key columns */
  inventory_client_order_line_by_pk?: Maybe<Inventory_Client_Order_Line>;
  /** fetch data from the table: "inventory.client_order_line_status" */
  inventory_client_order_line_status: Array<Inventory_Client_Order_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.client_order_line_status" */
  inventory_client_order_line_status_aggregate: Inventory_Client_Order_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.client_order_line_status" using primary key columns */
  inventory_client_order_line_status_by_pk?: Maybe<Inventory_Client_Order_Line_Status>;
  /** fetch data from the table: "inventory.client_order_line_type" */
  inventory_client_order_line_type: Array<Inventory_Client_Order_Line_Type>;
  /** fetch aggregated fields from the table: "inventory.client_order_line_type" */
  inventory_client_order_line_type_aggregate: Inventory_Client_Order_Line_Type_Aggregate;
  /** fetch data from the table: "inventory.client_order_line_type" using primary key columns */
  inventory_client_order_line_type_by_pk?: Maybe<Inventory_Client_Order_Line_Type>;
  /** fetch data from the table: "inventory.client_order_status" */
  inventory_client_order_status: Array<Inventory_Client_Order_Status>;
  /** fetch aggregated fields from the table: "inventory.client_order_status" */
  inventory_client_order_status_aggregate: Inventory_Client_Order_Status_Aggregate;
  /** fetch data from the table: "inventory.client_order_status" using primary key columns */
  inventory_client_order_status_by_pk?: Maybe<Inventory_Client_Order_Status>;
  /** fetch data from the table: "inventory.client_order_type" */
  inventory_client_order_type: Array<Inventory_Client_Order_Type>;
  /** fetch aggregated fields from the table: "inventory.client_order_type" */
  inventory_client_order_type_aggregate: Inventory_Client_Order_Type_Aggregate;
  /** fetch data from the table: "inventory.client_order_type" using primary key columns */
  inventory_client_order_type_by_pk?: Maybe<Inventory_Client_Order_Type>;
  /** fetch data from the table: "inventory.country" */
  inventory_country: Array<Inventory_Country>;
  /** fetch aggregated fields from the table: "inventory.country" */
  inventory_country_aggregate: Inventory_Country_Aggregate;
  /** fetch data from the table: "inventory.country" using primary key columns */
  inventory_country_by_pk?: Maybe<Inventory_Country>;
  /** fetch data from the table: "inventory.courier" */
  inventory_courier: Array<Inventory_Courier>;
  /** fetch aggregated fields from the table: "inventory.courier" */
  inventory_courier_aggregate: Inventory_Courier_Aggregate;
  /** fetch data from the table: "inventory.courier" using primary key columns */
  inventory_courier_by_pk?: Maybe<Inventory_Courier>;
  /** fetch data from the table: "inventory.courier_service" */
  inventory_courier_service: Array<Inventory_Courier_Service>;
  /** fetch aggregated fields from the table: "inventory.courier_service" */
  inventory_courier_service_aggregate: Inventory_Courier_Service_Aggregate;
  /** fetch data from the table: "inventory.courier_service" using primary key columns */
  inventory_courier_service_by_pk?: Maybe<Inventory_Courier_Service>;
  /** fetch data from the table: "inventory.courier_type" */
  inventory_courier_type: Array<Inventory_Courier_Type>;
  /** fetch aggregated fields from the table: "inventory.courier_type" */
  inventory_courier_type_aggregate: Inventory_Courier_Type_Aggregate;
  /** fetch data from the table: "inventory.courier_type" using primary key columns */
  inventory_courier_type_by_pk?: Maybe<Inventory_Courier_Type>;
  /** fetch data from the table: "inventory.currency" */
  inventory_currency: Array<Inventory_Currency>;
  /** fetch aggregated fields from the table: "inventory.currency" */
  inventory_currency_aggregate: Inventory_Currency_Aggregate;
  /** fetch data from the table: "inventory.currency" using primary key columns */
  inventory_currency_by_pk?: Maybe<Inventory_Currency>;
  /** fetch data from the table: "inventory.date_d" */
  inventory_date_d: Array<Inventory_Date_D>;
  /** fetch aggregated fields from the table: "inventory.date_d" */
  inventory_date_d_aggregate: Inventory_Date_D_Aggregate;
  /** fetch data from the table: "inventory.date_d" using primary key columns */
  inventory_date_d_by_pk?: Maybe<Inventory_Date_D>;
  /** fetch data from the table: "inventory.exchange_rate_gbp" */
  inventory_exchange_rate_gbp: Array<Inventory_Exchange_Rate_Gbp>;
  /** fetch aggregated fields from the table: "inventory.exchange_rate_gbp" */
  inventory_exchange_rate_gbp_aggregate: Inventory_Exchange_Rate_Gbp_Aggregate;
  /** fetch data from the table: "inventory.exchange_rate_gbp" using primary key columns */
  inventory_exchange_rate_gbp_by_pk?: Maybe<Inventory_Exchange_Rate_Gbp>;
  /** fetch data from the table: "inventory.folder" */
  inventory_folder: Array<Inventory_Folder>;
  /** fetch aggregated fields from the table: "inventory.folder" */
  inventory_folder_aggregate: Inventory_Folder_Aggregate;
  /** fetch data from the table: "inventory.folder" using primary key columns */
  inventory_folder_by_pk?: Maybe<Inventory_Folder>;
  /** fetch data from the table: "inventory.locale" */
  inventory_locale: Array<Inventory_Locale>;
  /** fetch aggregated fields from the table: "inventory.locale" */
  inventory_locale_aggregate: Inventory_Locale_Aggregate;
  /** fetch data from the table: "inventory.locale" using primary key columns */
  inventory_locale_by_pk?: Maybe<Inventory_Locale>;
  /** fetch data from the table: "inventory.location" */
  inventory_location: Array<Inventory_Location>;
  /** fetch aggregated fields from the table: "inventory.location" */
  inventory_location_aggregate: Inventory_Location_Aggregate;
  /** fetch data from the table: "inventory.location" using primary key columns */
  inventory_location_by_pk?: Maybe<Inventory_Location>;
  /** fetch data from the table: "inventory_management_locations" */
  inventory_management_locations: Array<Inventory_Management_Locations>;
  /** fetch aggregated fields from the table: "inventory_management_locations" */
  inventory_management_locations_aggregate: Inventory_Management_Locations_Aggregate;
  /** fetch data from the table: "inventory_management_locations_ancestry" */
  inventory_management_locations_ancestry: Array<Inventory_Management_Locations_Ancestry>;
  /** fetch aggregated fields from the table: "inventory_management_locations_ancestry" */
  inventory_management_locations_ancestry_aggregate: Inventory_Management_Locations_Ancestry_Aggregate;
  /** fetch data from the table: "inventory_management_locations" using primary key columns */
  inventory_management_locations_by_pk?: Maybe<Inventory_Management_Locations>;
  /** fetch data from the table: "inventory_management_owners" */
  inventory_management_owners: Array<Inventory_Management_Owners>;
  /** fetch aggregated fields from the table: "inventory_management_owners" */
  inventory_management_owners_aggregate: Inventory_Management_Owners_Aggregate;
  /** fetch data from the table: "inventory_management_owners" using primary key columns */
  inventory_management_owners_by_pk?: Maybe<Inventory_Management_Owners>;
  /** fetch data from the table: "inventory_management_services" */
  inventory_management_services: Array<Inventory_Management_Services>;
  /** fetch aggregated fields from the table: "inventory_management_services" */
  inventory_management_services_aggregate: Inventory_Management_Services_Aggregate;
  /** fetch data from the table: "inventory_management_services" using primary key columns */
  inventory_management_services_by_pk?: Maybe<Inventory_Management_Services>;
  /** fetch data from the table: "inventory_management_sku" */
  inventory_management_sku: Array<Inventory_Management_Sku>;
  /** fetch aggregated fields from the table: "inventory_management_sku" */
  inventory_management_sku_aggregate: Inventory_Management_Sku_Aggregate;
  /** fetch data from the table: "inventory_management_sku" using primary key columns */
  inventory_management_sku_by_pk?: Maybe<Inventory_Management_Sku>;
  /** fetch data from the table: "inventory_management_stock_change" */
  inventory_management_stock_change: Array<Inventory_Management_Stock_Change>;
  /** fetch aggregated fields from the table: "inventory_management_stock_change" */
  inventory_management_stock_change_aggregate: Inventory_Management_Stock_Change_Aggregate;
  /** fetch data from the table: "inventory_management_stock_change" using primary key columns */
  inventory_management_stock_change_by_pk?: Maybe<Inventory_Management_Stock_Change>;
  /** fetch data from the table: "inventory_management_stock_position" */
  inventory_management_stock_position: Array<Inventory_Management_Stock_Position>;
  /** fetch aggregated fields from the table: "inventory_management_stock_position" */
  inventory_management_stock_position_aggregate: Inventory_Management_Stock_Position_Aggregate;
  /** fetch data from the table: "inventory_management_stock_position_by_date" */
  inventory_management_stock_position_by_date: Array<Inventory_Management_Stock_Position_By_Date>;
  /** fetch aggregated fields from the table: "inventory_management_stock_position_by_date" */
  inventory_management_stock_position_by_date_aggregate: Inventory_Management_Stock_Position_By_Date_Aggregate;
  /** fetch data from the table: "inventory_management_stocks" */
  inventory_management_stocks: Array<Inventory_Management_Stocks>;
  /** fetch aggregated fields from the table: "inventory_management_stocks" */
  inventory_management_stocks_aggregate: Inventory_Management_Stocks_Aggregate;
  /** fetch data from the table: "inventory_management_stocks" using primary key columns */
  inventory_management_stocks_by_pk?: Maybe<Inventory_Management_Stocks>;
  /** fetch data from the table: "inventory.meeting_note" */
  inventory_meeting_note: Array<Inventory_Meeting_Note>;
  /** fetch aggregated fields from the table: "inventory.meeting_note" */
  inventory_meeting_note_aggregate: Inventory_Meeting_Note_Aggregate;
  /** fetch data from the table: "inventory.meeting_note" using primary key columns */
  inventory_meeting_note_by_pk?: Maybe<Inventory_Meeting_Note>;
  /** fetch data from the table: "inventory.meeting_note_status" */
  inventory_meeting_note_status: Array<Inventory_Meeting_Note_Status>;
  /** fetch aggregated fields from the table: "inventory.meeting_note_status" */
  inventory_meeting_note_status_aggregate: Inventory_Meeting_Note_Status_Aggregate;
  /** fetch data from the table: "inventory.meeting_note_status" using primary key columns */
  inventory_meeting_note_status_by_pk?: Maybe<Inventory_Meeting_Note_Status>;
  /** fetch data from the table: "inventory.product" */
  inventory_product: Array<Inventory_Product>;
  /** fetch aggregated fields from the table: "inventory.product" */
  inventory_product_aggregate: Inventory_Product_Aggregate;
  /** fetch data from the table: "inventory.product" using primary key columns */
  inventory_product_by_pk?: Maybe<Inventory_Product>;
  /** fetch data from the table: "inventory.product_pricing" */
  inventory_product_pricing: Array<Inventory_Product_Pricing>;
  /** fetch aggregated fields from the table: "inventory.product_pricing" */
  inventory_product_pricing_aggregate: Inventory_Product_Pricing_Aggregate;
  /** fetch data from the table: "inventory.product_pricing" using primary key columns */
  inventory_product_pricing_by_pk?: Maybe<Inventory_Product_Pricing>;
  /** fetch data from the table: "inventory.product_stock" */
  inventory_product_stock: Array<Inventory_Product_Stock>;
  /** fetch aggregated fields from the table: "inventory.product_stock" */
  inventory_product_stock_aggregate: Inventory_Product_Stock_Aggregate;
  /** fetch data from the table: "inventory.product_stock" using primary key columns */
  inventory_product_stock_by_pk?: Maybe<Inventory_Product_Stock>;
  /** fetch data from the table: "inventory.product_stock_overview" */
  inventory_product_stock_overview: Array<Inventory_Product_Stock_Overview>;
  /** fetch aggregated fields from the table: "inventory.product_stock_overview" */
  inventory_product_stock_overview_aggregate: Inventory_Product_Stock_Overview_Aggregate;
  /** fetch data from the table: "inventory.production_order" */
  inventory_production_order: Array<Inventory_Production_Order>;
  /** fetch aggregated fields from the table: "inventory.production_order" */
  inventory_production_order_aggregate: Inventory_Production_Order_Aggregate;
  /** fetch data from the table: "inventory.production_order" using primary key columns */
  inventory_production_order_by_pk?: Maybe<Inventory_Production_Order>;
  /** fetch data from the table: "inventory.production_order_line" */
  inventory_production_order_line: Array<Inventory_Production_Order_Line>;
  /** fetch aggregated fields from the table: "inventory.production_order_line" */
  inventory_production_order_line_aggregate: Inventory_Production_Order_Line_Aggregate;
  /** fetch data from the table: "inventory.production_order_line" using primary key columns */
  inventory_production_order_line_by_pk?: Maybe<Inventory_Production_Order_Line>;
  /** fetch data from the table: "inventory.production_order_line_status" */
  inventory_production_order_line_status: Array<Inventory_Production_Order_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.production_order_line_status" */
  inventory_production_order_line_status_aggregate: Inventory_Production_Order_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.production_order_line_status" using primary key columns */
  inventory_production_order_line_status_by_pk?: Maybe<Inventory_Production_Order_Line_Status>;
  /** fetch data from the table: "inventory.production_order_status" */
  inventory_production_order_status: Array<Inventory_Production_Order_Status>;
  /** fetch aggregated fields from the table: "inventory.production_order_status" */
  inventory_production_order_status_aggregate: Inventory_Production_Order_Status_Aggregate;
  /** fetch data from the table: "inventory.production_order_status" using primary key columns */
  inventory_production_order_status_by_pk?: Maybe<Inventory_Production_Order_Status>;
  /** fetch data from the table: "inventory.role" */
  inventory_role: Array<Inventory_Role>;
  /** fetch aggregated fields from the table: "inventory.role" */
  inventory_role_aggregate: Inventory_Role_Aggregate;
  /** fetch data from the table: "inventory.role" using primary key columns */
  inventory_role_by_pk?: Maybe<Inventory_Role>;
  /** fetch data from the table: "inventory.shipment" */
  inventory_shipment: Array<Inventory_Shipment>;
  /** fetch aggregated fields from the table: "inventory.shipment" */
  inventory_shipment_aggregate: Inventory_Shipment_Aggregate;
  /** fetch data from the table: "inventory.shipment" using primary key columns */
  inventory_shipment_by_pk?: Maybe<Inventory_Shipment>;
  /** fetch data from the table: "inventory.shipment_invoice" */
  inventory_shipment_invoice: Array<Inventory_Shipment_Invoice>;
  /** fetch aggregated fields from the table: "inventory.shipment_invoice" */
  inventory_shipment_invoice_aggregate: Inventory_Shipment_Invoice_Aggregate;
  /** fetch data from the table: "inventory.shipment_invoice" using primary key columns */
  inventory_shipment_invoice_by_pk?: Maybe<Inventory_Shipment_Invoice>;
  /** fetch data from the table: "inventory.shipment_line" */
  inventory_shipment_line: Array<Inventory_Shipment_Line>;
  /** fetch aggregated fields from the table: "inventory.shipment_line" */
  inventory_shipment_line_aggregate: Inventory_Shipment_Line_Aggregate;
  /** fetch data from the table: "inventory.shipment_line" using primary key columns */
  inventory_shipment_line_by_pk?: Maybe<Inventory_Shipment_Line>;
  /** fetch data from the table: "inventory.shipment_line_status" */
  inventory_shipment_line_status: Array<Inventory_Shipment_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.shipment_line_status" */
  inventory_shipment_line_status_aggregate: Inventory_Shipment_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.shipment_line_status" using primary key columns */
  inventory_shipment_line_status_by_pk?: Maybe<Inventory_Shipment_Line_Status>;
  /** fetch data from the table: "inventory.shipment_status" */
  inventory_shipment_status: Array<Inventory_Shipment_Status>;
  /** fetch aggregated fields from the table: "inventory.shipment_status" */
  inventory_shipment_status_aggregate: Inventory_Shipment_Status_Aggregate;
  /** fetch data from the table: "inventory.shipment_status" using primary key columns */
  inventory_shipment_status_by_pk?: Maybe<Inventory_Shipment_Status>;
  /** fetch data from the table: "inventory.stock_status" */
  inventory_stock_status: Array<Inventory_Stock_Status>;
  /** fetch aggregated fields from the table: "inventory.stock_status" */
  inventory_stock_status_aggregate: Inventory_Stock_Status_Aggregate;
  /** fetch data from the table: "inventory.stock_status" using primary key columns */
  inventory_stock_status_by_pk?: Maybe<Inventory_Stock_Status>;
  /** fetch data from the table: "inventory.supplier_invoice" */
  inventory_supplier_invoice: Array<Inventory_Supplier_Invoice>;
  /** fetch aggregated fields from the table: "inventory.supplier_invoice" */
  inventory_supplier_invoice_aggregate: Inventory_Supplier_Invoice_Aggregate;
  /** fetch data from the table: "inventory.supplier_invoice" using primary key columns */
  inventory_supplier_invoice_by_pk?: Maybe<Inventory_Supplier_Invoice>;
  /** fetch data from the table: "inventory.supplier_invoice_line" */
  inventory_supplier_invoice_line: Array<Inventory_Supplier_Invoice_Line>;
  /** fetch aggregated fields from the table: "inventory.supplier_invoice_line" */
  inventory_supplier_invoice_line_aggregate: Inventory_Supplier_Invoice_Line_Aggregate;
  /** fetch data from the table: "inventory.supplier_invoice_line" using primary key columns */
  inventory_supplier_invoice_line_by_pk?: Maybe<Inventory_Supplier_Invoice_Line>;
  /** fetch data from the table: "inventory.supplier_invoice_line_status" */
  inventory_supplier_invoice_line_status: Array<Inventory_Supplier_Invoice_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.supplier_invoice_line_status" */
  inventory_supplier_invoice_line_status_aggregate: Inventory_Supplier_Invoice_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.supplier_invoice_line_status" using primary key columns */
  inventory_supplier_invoice_line_status_by_pk?: Maybe<Inventory_Supplier_Invoice_Line_Status>;
  /** fetch data from the table: "inventory.supplier_invoice_status" */
  inventory_supplier_invoice_status: Array<Inventory_Supplier_Invoice_Status>;
  /** fetch aggregated fields from the table: "inventory.supplier_invoice_status" */
  inventory_supplier_invoice_status_aggregate: Inventory_Supplier_Invoice_Status_Aggregate;
  /** fetch data from the table: "inventory.supplier_invoice_status" using primary key columns */
  inventory_supplier_invoice_status_by_pk?: Maybe<Inventory_Supplier_Invoice_Status>;
  /** fetch data from the table: "inventory.supplier_product" */
  inventory_supplier_product: Array<Inventory_Supplier_Product>;
  /** fetch aggregated fields from the table: "inventory.supplier_product" */
  inventory_supplier_product_aggregate: Inventory_Supplier_Product_Aggregate;
  /** fetch data from the table: "inventory.supplier_product" using primary key columns */
  inventory_supplier_product_by_pk?: Maybe<Inventory_Supplier_Product>;
  /** fetch data from the table: "inventory.supplier_quote" */
  inventory_supplier_quote: Array<Inventory_Supplier_Quote>;
  /** fetch aggregated fields from the table: "inventory.supplier_quote" */
  inventory_supplier_quote_aggregate: Inventory_Supplier_Quote_Aggregate;
  /** fetch data from the table: "inventory.supplier_quote" using primary key columns */
  inventory_supplier_quote_by_pk?: Maybe<Inventory_Supplier_Quote>;
  /** fetch data from the table: "marketplace_client" */
  marketplace_client: Array<Marketplace_Client>;
  /** fetch aggregated fields from the table: "marketplace_client" */
  marketplace_client_aggregate: Marketplace_Client_Aggregate;
  /** fetch data from the table: "marketplace_client" using primary key columns */
  marketplace_client_by_pk?: Maybe<Marketplace_Client>;
  /** fetch data from the table: "marketplace_data_collector_merchant_sales_transformed" */
  marketplace_data_collector_merchant_sales_transformed: Array<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
  /** fetch aggregated fields from the table: "marketplace_data_collector_merchant_sales_transformed" */
  marketplace_data_collector_merchant_sales_transformed_aggregate: Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate;
  /** fetch data from the table: "marketplace_data_collector_merchant_sales_transformed" using primary key columns */
  marketplace_data_collector_merchant_sales_transformed_by_pk?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
  /** fetch data from the table: "marketplace_master_sku" */
  marketplace_master_sku: Array<Marketplace_Master_Sku>;
  /** fetch aggregated fields from the table: "marketplace_master_sku" */
  marketplace_master_sku_aggregate: Marketplace_Master_Sku_Aggregate;
  /** fetch data from the table: "marketplace_master_sku" using primary key columns */
  marketplace_master_sku_by_pk?: Maybe<Marketplace_Master_Sku>;
  /** fetch data from the table: "marketplace_master_sku_marketplace_unit_sku" */
  marketplace_master_sku_marketplace_unit_sku: Array<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
  /** fetch aggregated fields from the table: "marketplace_master_sku_marketplace_unit_sku" */
  marketplace_master_sku_marketplace_unit_sku_aggregate: Marketplace_Master_Sku_Marketplace_Unit_Sku_Aggregate;
  /** fetch data from the table: "marketplace_master_sku_marketplace_unit_sku" using primary key columns */
  marketplace_master_sku_marketplace_unit_sku_by_pk?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
  /** fetch data from the table: "marketplace_merchants" */
  marketplace_merchants: Array<Marketplace_Merchants>;
  /** fetch aggregated fields from the table: "marketplace_merchants" */
  marketplace_merchants_aggregate: Marketplace_Merchants_Aggregate;
  /** fetch data from the table: "marketplace_merchants" using primary key columns */
  marketplace_merchants_by_pk?: Maybe<Marketplace_Merchants>;
  /** fetch data from the table: "marketplace_merchants_floomx_replens_order" */
  marketplace_merchants_floomx_replens_order: Array<Marketplace_Merchants_Floomx_Replens_Order>;
  /** fetch aggregated fields from the table: "marketplace_merchants_floomx_replens_order" */
  marketplace_merchants_floomx_replens_order_aggregate: Marketplace_Merchants_Floomx_Replens_Order_Aggregate;
  /** fetch data from the table: "marketplace_merchants_floomx_replens_order" using primary key columns */
  marketplace_merchants_floomx_replens_order_by_pk?: Maybe<Marketplace_Merchants_Floomx_Replens_Order>;
  /** fetch data from the table: "marketplace_merchants_floomx_replens_order_items" */
  marketplace_merchants_floomx_replens_order_items: Array<Marketplace_Merchants_Floomx_Replens_Order_Items>;
  /** fetch aggregated fields from the table: "marketplace_merchants_floomx_replens_order_items" */
  marketplace_merchants_floomx_replens_order_items_aggregate: Marketplace_Merchants_Floomx_Replens_Order_Items_Aggregate;
  /** fetch data from the table: "marketplace_merchants_floomx_replens_order_items" using primary key columns */
  marketplace_merchants_floomx_replens_order_items_by_pk?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items>;
  /** fetch data from the table: "marketplace_merchants_raw" */
  marketplace_merchants_raw: Array<Marketplace_Merchants_Raw>;
  /** fetch aggregated fields from the table: "marketplace_merchants_raw" */
  marketplace_merchants_raw_aggregate: Marketplace_Merchants_Raw_Aggregate;
  /** fetch data from the table: "marketplace_merchants_replens" */
  marketplace_merchants_replens: Array<Marketplace_Merchants_Replens>;
  /** fetch aggregated fields from the table: "marketplace_merchants_replens" */
  marketplace_merchants_replens_aggregate: Marketplace_Merchants_Replens_Aggregate;
  /** fetch data from the table: "marketplace_merchants_replens" using primary key columns */
  marketplace_merchants_replens_by_pk?: Maybe<Marketplace_Merchants_Replens>;
  /** fetch data from the table: "marketplace_merchants_replens_order" */
  marketplace_merchants_replens_order: Array<Marketplace_Merchants_Replens_Order>;
  /** fetch aggregated fields from the table: "marketplace_merchants_replens_order" */
  marketplace_merchants_replens_order_aggregate: Marketplace_Merchants_Replens_Order_Aggregate;
  /** fetch data from the table: "marketplace_merchants_replens_order" using primary key columns */
  marketplace_merchants_replens_order_by_pk?: Maybe<Marketplace_Merchants_Replens_Order>;
  /** fetch data from the table: "marketplace_merchants_sales" */
  marketplace_merchants_sales: Array<Marketplace_Merchants_Sales>;
  /** fetch aggregated fields from the table: "marketplace_merchants_sales" */
  marketplace_merchants_sales_aggregate: Marketplace_Merchants_Sales_Aggregate;
  /** fetch data from the table: "marketplace_merchants_sales" using primary key columns */
  marketplace_merchants_sales_by_pk?: Maybe<Marketplace_Merchants_Sales>;
  /** fetch data from the table: "marketplace_merchants_starting_stocks" */
  marketplace_merchants_starting_stocks: Array<Marketplace_Merchants_Starting_Stocks>;
  /** fetch aggregated fields from the table: "marketplace_merchants_starting_stocks" */
  marketplace_merchants_starting_stocks_aggregate: Marketplace_Merchants_Starting_Stocks_Aggregate;
  /** fetch data from the table: "marketplace_merchants_starting_stocks" using primary key columns */
  marketplace_merchants_starting_stocks_by_pk?: Maybe<Marketplace_Merchants_Starting_Stocks>;
  /** fetch data from the table: "marketplace_merchants_stocks" */
  marketplace_merchants_stocks: Array<Marketplace_Merchants_Stocks>;
  /** fetch aggregated fields from the table: "marketplace_merchants_stocks" */
  marketplace_merchants_stocks_aggregate: Marketplace_Merchants_Stocks_Aggregate;
  /** fetch data from the table: "marketplace_merchants_stocks" using primary key columns */
  marketplace_merchants_stocks_by_pk?: Maybe<Marketplace_Merchants_Stocks>;
  /** fetch data from the table: "marketplace_merchants_stocks_v2" */
  marketplace_merchants_stocks_v2: Array<Marketplace_Merchants_Stocks_V2>;
  /** fetch aggregated fields from the table: "marketplace_merchants_stocks_v2" */
  marketplace_merchants_stocks_v2_aggregate: Marketplace_Merchants_Stocks_V2_Aggregate;
  /** fetch data from the table: "marketplace_merchants_stocks_v2" using primary key columns */
  marketplace_merchants_stocks_v2_by_pk?: Maybe<Marketplace_Merchants_Stocks_V2>;
  /** fetch data from the table: "marketplace_replenishment_email" */
  marketplace_replenishment_email: Array<Marketplace_Replenishment_Email>;
  /** fetch aggregated fields from the table: "marketplace_replenishment_email" */
  marketplace_replenishment_email_aggregate: Marketplace_Replenishment_Email_Aggregate;
  /** fetch data from the table: "marketplace_replenishment_email" using primary key columns */
  marketplace_replenishment_email_by_pk?: Maybe<Marketplace_Replenishment_Email>;
  /** fetch data from the table: "marketplace_replenishment_suggestions" */
  marketplace_replenishment_suggestions: Array<Marketplace_Replenishment_Suggestions>;
  /** fetch aggregated fields from the table: "marketplace_replenishment_suggestions" */
  marketplace_replenishment_suggestions_aggregate: Marketplace_Replenishment_Suggestions_Aggregate;
  /** fetch data from the table: "marketplace_replenishment_suggestions" using primary key columns */
  marketplace_replenishment_suggestions_by_pk?: Maybe<Marketplace_Replenishment_Suggestions>;
  /** fetch data from the table: "marketplace_replenishment_suggestions_v2" */
  marketplace_replenishment_suggestions_v2: Array<Marketplace_Replenishment_Suggestions_V2>;
  /** fetch aggregated fields from the table: "marketplace_replenishment_suggestions_v2" */
  marketplace_replenishment_suggestions_v2_aggregate: Marketplace_Replenishment_Suggestions_V2_Aggregate;
  /** fetch data from the table: "marketplace_replenishment_suggestions_v2" using primary key columns */
  marketplace_replenishment_suggestions_v2_by_pk?: Maybe<Marketplace_Replenishment_Suggestions_V2>;
  /** fetch data from the table: "marketplace_unit_sku" */
  marketplace_unit_sku: Array<Marketplace_Unit_Sku>;
  /** fetch aggregated fields from the table: "marketplace_unit_sku" */
  marketplace_unit_sku_aggregate: Marketplace_Unit_Sku_Aggregate;
  /** fetch data from the table: "marketplace_unit_sku" using primary key columns */
  marketplace_unit_sku_by_pk?: Maybe<Marketplace_Unit_Sku>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};

/** query root */
export type Query_RootInventory_AccountArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Order_By>>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Order_By>>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Account_Meeting_NoteArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Meeting_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Meeting_Note_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Account_Metadata_KeysArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Metadata_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Metadata_Keys_Order_By>>;
  where?: Maybe<Inventory_Account_Metadata_Keys_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Metadata_Keys_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Metadata_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Metadata_Keys_Order_By>>;
  where?: Maybe<Inventory_Account_Metadata_Keys_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_ReferralArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Referral_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Referral_Order_By>>;
  where?: Maybe<Inventory_Account_Referral_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Referral_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Referral_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Referral_Order_By>>;
  where?: Maybe<Inventory_Account_Referral_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Referral_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Account_RoleArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Role_Order_By>>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Role_Order_By>>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Role_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Account_SectorArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Sector_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Sector_Order_By>>;
  where?: Maybe<Inventory_Account_Sector_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Sector_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Sector_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Sector_Order_By>>;
  where?: Maybe<Inventory_Account_Sector_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Sector_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Account_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Status_Order_By>>;
  where?: Maybe<Inventory_Account_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Status_Order_By>>;
  where?: Maybe<Inventory_Account_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Account_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_AddressArgs = {
  distinct_on?: Maybe<Array<Inventory_Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Address_Order_By>>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Address_Order_By>>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Address_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_AttachmentArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Order_By>>;
  where?: Maybe<Inventory_Attachment_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Attachment_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Order_By>>;
  where?: Maybe<Inventory_Attachment_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Attachment_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Attachment_FolderArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Folder_Order_By>>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Attachment_Folder_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Folder_Order_By>>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Attachment_Folder_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Base_ProductArgs = {
  distinct_on?: Maybe<Array<Inventory_Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Base_Product_Order_By>>;
  where?: Maybe<Inventory_Base_Product_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Base_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Base_Product_Order_By>>;
  where?: Maybe<Inventory_Base_Product_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Base_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_BatchArgs = {
  distinct_on?: Maybe<Array<Inventory_Batch_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Batch_Order_By>>;
  where?: Maybe<Inventory_Batch_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Batch_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Batch_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Batch_Order_By>>;
  where?: Maybe<Inventory_Batch_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Batch_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_InvoiceArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Invoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_Invoice_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Invoice_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Invoice_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_Invoice_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Invoice_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Invoice_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_Invoice_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Invoice_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Invoice_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_OrderArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_Order_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_Order_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_Order_Line_TypeArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Type_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Line_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Type_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Line_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_Order_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Client_Order_TypeArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Type_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Type_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Type_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Type_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Client_Order_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_CountryArgs = {
  distinct_on?: Maybe<Array<Inventory_Country_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Country_Order_By>>;
  where?: Maybe<Inventory_Country_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Country_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Country_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Country_Order_By>>;
  where?: Maybe<Inventory_Country_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Country_By_PkArgs = {
  country_code: Scalars["String"];
};

/** query root */
export type Query_RootInventory_CourierArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Order_By>>;
  where?: Maybe<Inventory_Courier_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Courier_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Order_By>>;
  where?: Maybe<Inventory_Courier_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Courier_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Courier_ServiceArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Service_Order_By>>;
  where?: Maybe<Inventory_Courier_Service_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Courier_Service_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Service_Order_By>>;
  where?: Maybe<Inventory_Courier_Service_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Courier_Service_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Courier_TypeArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Type_Order_By>>;
  where?: Maybe<Inventory_Courier_Type_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Courier_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Type_Order_By>>;
  where?: Maybe<Inventory_Courier_Type_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Courier_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_CurrencyArgs = {
  distinct_on?: Maybe<Array<Inventory_Currency_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Currency_Order_By>>;
  where?: Maybe<Inventory_Currency_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Currency_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Currency_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Currency_Order_By>>;
  where?: Maybe<Inventory_Currency_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Currency_By_PkArgs = {
  currency_code: Scalars["String"];
};

/** query root */
export type Query_RootInventory_Date_DArgs = {
  distinct_on?: Maybe<Array<Inventory_Date_D_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Date_D_Order_By>>;
  where?: Maybe<Inventory_Date_D_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Date_D_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Date_D_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Date_D_Order_By>>;
  where?: Maybe<Inventory_Date_D_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Date_D_By_PkArgs = {
  date_key: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Exchange_Rate_GbpArgs = {
  distinct_on?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Order_By>>;
  where?: Maybe<Inventory_Exchange_Rate_Gbp_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Exchange_Rate_Gbp_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Order_By>>;
  where?: Maybe<Inventory_Exchange_Rate_Gbp_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Exchange_Rate_Gbp_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_FolderArgs = {
  distinct_on?: Maybe<Array<Inventory_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Folder_Order_By>>;
  where?: Maybe<Inventory_Folder_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Folder_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Folder_Order_By>>;
  where?: Maybe<Inventory_Folder_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Folder_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_LocaleArgs = {
  distinct_on?: Maybe<Array<Inventory_Locale_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Locale_Order_By>>;
  where?: Maybe<Inventory_Locale_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Locale_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Locale_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Locale_Order_By>>;
  where?: Maybe<Inventory_Locale_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Locale_By_PkArgs = {
  locale: Scalars["String"];
};

/** query root */
export type Query_RootInventory_LocationArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Location_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Management_LocationsArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Locations_AncestryArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Ancestry_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Locations_Ancestry_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Ancestry_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Locations_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Management_OwnersArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Owners_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Owners_Order_By>>;
  where?: Maybe<Inventory_Management_Owners_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Owners_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Owners_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Owners_Order_By>>;
  where?: Maybe<Inventory_Management_Owners_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Owners_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Management_ServicesArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Services_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Services_Order_By>>;
  where?: Maybe<Inventory_Management_Services_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Services_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Services_Order_By>>;
  where?: Maybe<Inventory_Management_Services_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Services_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Management_SkuArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Sku_Order_By>>;
  where?: Maybe<Inventory_Management_Sku_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Sku_Order_By>>;
  where?: Maybe<Inventory_Management_Sku_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Management_Stock_ChangeArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Change_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Stock_Change_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Change_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Stock_Change_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Management_Stock_PositionArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Stock_Position_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Stock_Position_By_DateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Stock_Position_By_Date_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_StocksArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stocks_Order_By>>;
  where?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stocks_Order_By>>;
  where?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Management_Stocks_By_PkArgs = {
  location_id: Scalars["Int"];
  sku_id: Scalars["Int"];
  status: Scalars["String"];
};

/** query root */
export type Query_RootInventory_Meeting_NoteArgs = {
  distinct_on?: Maybe<Array<Inventory_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Meeting_Note_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Meeting_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Meeting_Note_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Meeting_Note_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Meeting_Note_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Meeting_Note_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Meeting_Note_Status_Order_By>>;
  where?: Maybe<Inventory_Meeting_Note_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Meeting_Note_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Meeting_Note_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Meeting_Note_Status_Order_By>>;
  where?: Maybe<Inventory_Meeting_Note_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Meeting_Note_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_ProductArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Order_By>>;
  where?: Maybe<Inventory_Product_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Order_By>>;
  where?: Maybe<Inventory_Product_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Product_PricingArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Pricing_Order_By>>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Product_Pricing_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Pricing_Order_By>>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Product_Pricing_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Product_StockArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Product_Stock_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Product_Stock_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Product_Stock_OverviewArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Overview_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Overview_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Overview_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Product_Stock_Overview_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Overview_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Overview_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Overview_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_OrderArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_Order_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Production_Order_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_Order_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_Order_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Production_Order_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_Order_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_Order_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Production_Order_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Status_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_Order_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Status_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Production_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_RoleArgs = {
  distinct_on?: Maybe<Array<Inventory_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Role_Order_By>>;
  where?: Maybe<Inventory_Role_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Role_Order_By>>;
  where?: Maybe<Inventory_Role_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Role_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_ShipmentArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Order_By>>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Order_By>>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Shipment_InvoiceArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Invoice_Order_By>>;
  where?: Maybe<Inventory_Shipment_Invoice_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_Invoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Invoice_Order_By>>;
  where?: Maybe<Inventory_Shipment_Invoice_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Shipment_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Shipment_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Shipment_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Status_Order_By>>;
  where?: Maybe<Inventory_Shipment_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Status_Order_By>>;
  where?: Maybe<Inventory_Shipment_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Shipment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Stock_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Stock_Status_Order_By>>;
  where?: Maybe<Inventory_Stock_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Stock_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Stock_Status_Order_By>>;
  where?: Maybe<Inventory_Stock_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Stock_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Supplier_InvoiceArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Status_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Status_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Status_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Invoice_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Supplier_ProductArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Product_Order_By>>;
  where?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Product_Order_By>>;
  where?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootInventory_Supplier_QuoteArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Quote_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** query root */
export type Query_RootInventory_Supplier_Quote_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_ClientArgs = {
  distinct_on?: Maybe<Array<Marketplace_Client_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Client_Order_By>>;
  where?: Maybe<Marketplace_Client_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Client_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Client_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Client_Order_By>>;
  where?: Maybe<Marketplace_Client_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Client_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Data_Collector_Merchant_Sales_TransformedArgs = {
  distinct_on?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Order_By>>;
  where?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Data_Collector_Merchant_Sales_Transformed_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Order_By>>;
  where?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Data_Collector_Merchant_Sales_Transformed_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Master_SkuArgs = {
  distinct_on?: Maybe<Array<Marketplace_Master_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Master_Sku_Order_By>>;
  where?: Maybe<Marketplace_Master_Sku_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Master_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Master_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Master_Sku_Order_By>>;
  where?: Maybe<Marketplace_Master_Sku_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Master_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Master_Sku_Marketplace_Unit_SkuArgs = {
  distinct_on?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Order_By>>;
  where?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Master_Sku_Marketplace_Unit_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Order_By>>;
  where?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Master_Sku_Marketplace_Unit_Sku_By_PkArgs = {
  marketplace_master_sku_id: Scalars["Int"];
  marketplace_unit_sku_id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_MerchantsArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Merchants_Floomx_Replens_OrderArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Floomx_Replens_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Floomx_Replens_Order_By_PkArgs = {
  order_id: Scalars["String"];
};

/** query root */
export type Query_RootMarketplace_Merchants_Floomx_Replens_Order_ItemsArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Floomx_Replens_Order_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Floomx_Replens_Order_Items_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Merchants_RawArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Raw_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Raw_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Raw_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Raw_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Raw_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Raw_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Raw_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_ReplensArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Replens_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Replens_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Merchants_Replens_OrderArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Replens_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Replens_Order_By_PkArgs = {
  order_id: Scalars["String"];
};

/** query root */
export type Query_RootMarketplace_Merchants_SalesArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Sales_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Sales_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Sales_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Sales_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Sales_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Sales_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Sales_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Sales_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Merchants_Starting_StocksArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Starting_Stocks_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Starting_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Starting_Stocks_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Starting_Stocks_By_PkArgs = {
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Merchants_StocksArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Stocks_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Stocks_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Stocks_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Stocks_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Stocks_By_PkArgs = {
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Merchants_Stocks_V2Args = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Stocks_V2_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Stocks_V2_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Stocks_V2_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Merchants_Stocks_V2_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Replenishment_EmailArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Email_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Email_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Replenishment_Email_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Email_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Email_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Replenishment_Email_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Replenishment_SuggestionsArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Suggestions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Suggestions_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Replenishment_Suggestions_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Suggestions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Suggestions_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Replenishment_Suggestions_By_PkArgs = {
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Replenishment_Suggestions_V2Args = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_V2_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Replenishment_Suggestions_V2_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_V2_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Replenishment_Suggestions_V2_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootMarketplace_Unit_SkuArgs = {
  distinct_on?: Maybe<Array<Marketplace_Unit_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Unit_Sku_Order_By>>;
  where?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Unit_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Unit_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Unit_Sku_Order_By>>;
  where?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
};

/** query root */
export type Query_RootMarketplace_Unit_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** query root */
export type Query_RootUsers_By_PkArgs = {
  uid: Scalars["String"];
};

/** subscription root */
export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "inventory.account" */
  inventory_account: Array<Inventory_Account>;
  /** fetch aggregated fields from the table: "inventory.account" */
  inventory_account_aggregate: Inventory_Account_Aggregate;
  /** fetch data from the table: "inventory.account" using primary key columns */
  inventory_account_by_pk?: Maybe<Inventory_Account>;
  /** fetch data from the table: "inventory.account_meeting_note" */
  inventory_account_meeting_note: Array<Inventory_Account_Meeting_Note>;
  /** fetch aggregated fields from the table: "inventory.account_meeting_note" */
  inventory_account_meeting_note_aggregate: Inventory_Account_Meeting_Note_Aggregate;
  /** fetch data from the table: "inventory.account_meeting_note" using primary key columns */
  inventory_account_meeting_note_by_pk?: Maybe<Inventory_Account_Meeting_Note>;
  /** fetch data from the table: "inventory.account_metadata_keys" */
  inventory_account_metadata_keys: Array<Inventory_Account_Metadata_Keys>;
  /** fetch aggregated fields from the table: "inventory.account_metadata_keys" */
  inventory_account_metadata_keys_aggregate: Inventory_Account_Metadata_Keys_Aggregate;
  /** fetch data from the table: "inventory.account_referral" */
  inventory_account_referral: Array<Inventory_Account_Referral>;
  /** fetch aggregated fields from the table: "inventory.account_referral" */
  inventory_account_referral_aggregate: Inventory_Account_Referral_Aggregate;
  /** fetch data from the table: "inventory.account_referral" using primary key columns */
  inventory_account_referral_by_pk?: Maybe<Inventory_Account_Referral>;
  /** fetch data from the table: "inventory.account_role" */
  inventory_account_role: Array<Inventory_Account_Role>;
  /** fetch aggregated fields from the table: "inventory.account_role" */
  inventory_account_role_aggregate: Inventory_Account_Role_Aggregate;
  /** fetch data from the table: "inventory.account_role" using primary key columns */
  inventory_account_role_by_pk?: Maybe<Inventory_Account_Role>;
  /** fetch data from the table: "inventory.account_sector" */
  inventory_account_sector: Array<Inventory_Account_Sector>;
  /** fetch aggregated fields from the table: "inventory.account_sector" */
  inventory_account_sector_aggregate: Inventory_Account_Sector_Aggregate;
  /** fetch data from the table: "inventory.account_sector" using primary key columns */
  inventory_account_sector_by_pk?: Maybe<Inventory_Account_Sector>;
  /** fetch data from the table: "inventory.account_status" */
  inventory_account_status: Array<Inventory_Account_Status>;
  /** fetch aggregated fields from the table: "inventory.account_status" */
  inventory_account_status_aggregate: Inventory_Account_Status_Aggregate;
  /** fetch data from the table: "inventory.account_status" using primary key columns */
  inventory_account_status_by_pk?: Maybe<Inventory_Account_Status>;
  /** fetch data from the table: "inventory.address" */
  inventory_address: Array<Inventory_Address>;
  /** fetch aggregated fields from the table: "inventory.address" */
  inventory_address_aggregate: Inventory_Address_Aggregate;
  /** fetch data from the table: "inventory.address" using primary key columns */
  inventory_address_by_pk?: Maybe<Inventory_Address>;
  /** fetch data from the table: "inventory.attachment" */
  inventory_attachment: Array<Inventory_Attachment>;
  /** fetch aggregated fields from the table: "inventory.attachment" */
  inventory_attachment_aggregate: Inventory_Attachment_Aggregate;
  /** fetch data from the table: "inventory.attachment" using primary key columns */
  inventory_attachment_by_pk?: Maybe<Inventory_Attachment>;
  /** fetch data from the table: "inventory.attachment_folder" */
  inventory_attachment_folder: Array<Inventory_Attachment_Folder>;
  /** fetch aggregated fields from the table: "inventory.attachment_folder" */
  inventory_attachment_folder_aggregate: Inventory_Attachment_Folder_Aggregate;
  /** fetch data from the table: "inventory.attachment_folder" using primary key columns */
  inventory_attachment_folder_by_pk?: Maybe<Inventory_Attachment_Folder>;
  /** fetch data from the table: "inventory.base_product" */
  inventory_base_product: Array<Inventory_Base_Product>;
  /** fetch aggregated fields from the table: "inventory.base_product" */
  inventory_base_product_aggregate: Inventory_Base_Product_Aggregate;
  /** fetch data from the table: "inventory.base_product" using primary key columns */
  inventory_base_product_by_pk?: Maybe<Inventory_Base_Product>;
  /** fetch data from the table: "inventory.batch" */
  inventory_batch: Array<Inventory_Batch>;
  /** fetch aggregated fields from the table: "inventory.batch" */
  inventory_batch_aggregate: Inventory_Batch_Aggregate;
  /** fetch data from the table: "inventory.batch" using primary key columns */
  inventory_batch_by_pk?: Maybe<Inventory_Batch>;
  /** fetch data from the table: "inventory.client_invoice" */
  inventory_client_invoice: Array<Inventory_Client_Invoice>;
  /** fetch aggregated fields from the table: "inventory.client_invoice" */
  inventory_client_invoice_aggregate: Inventory_Client_Invoice_Aggregate;
  /** fetch data from the table: "inventory.client_invoice" using primary key columns */
  inventory_client_invoice_by_pk?: Maybe<Inventory_Client_Invoice>;
  /** fetch data from the table: "inventory.client_invoice_line" */
  inventory_client_invoice_line: Array<Inventory_Client_Invoice_Line>;
  /** fetch aggregated fields from the table: "inventory.client_invoice_line" */
  inventory_client_invoice_line_aggregate: Inventory_Client_Invoice_Line_Aggregate;
  /** fetch data from the table: "inventory.client_invoice_line" using primary key columns */
  inventory_client_invoice_line_by_pk?: Maybe<Inventory_Client_Invoice_Line>;
  /** fetch data from the table: "inventory.client_invoice_line_status" */
  inventory_client_invoice_line_status: Array<Inventory_Client_Invoice_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.client_invoice_line_status" */
  inventory_client_invoice_line_status_aggregate: Inventory_Client_Invoice_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.client_invoice_line_status" using primary key columns */
  inventory_client_invoice_line_status_by_pk?: Maybe<Inventory_Client_Invoice_Line_Status>;
  /** fetch data from the table: "inventory.client_invoice_status" */
  inventory_client_invoice_status: Array<Inventory_Client_Invoice_Status>;
  /** fetch aggregated fields from the table: "inventory.client_invoice_status" */
  inventory_client_invoice_status_aggregate: Inventory_Client_Invoice_Status_Aggregate;
  /** fetch data from the table: "inventory.client_invoice_status" using primary key columns */
  inventory_client_invoice_status_by_pk?: Maybe<Inventory_Client_Invoice_Status>;
  /** fetch data from the table: "inventory.client_order" */
  inventory_client_order: Array<Inventory_Client_Order>;
  /** fetch aggregated fields from the table: "inventory.client_order" */
  inventory_client_order_aggregate: Inventory_Client_Order_Aggregate;
  /** fetch data from the table: "inventory.client_order" using primary key columns */
  inventory_client_order_by_pk?: Maybe<Inventory_Client_Order>;
  /** fetch data from the table: "inventory.client_order_line" */
  inventory_client_order_line: Array<Inventory_Client_Order_Line>;
  /** fetch aggregated fields from the table: "inventory.client_order_line" */
  inventory_client_order_line_aggregate: Inventory_Client_Order_Line_Aggregate;
  /** fetch data from the table: "inventory.client_order_line" using primary key columns */
  inventory_client_order_line_by_pk?: Maybe<Inventory_Client_Order_Line>;
  /** fetch data from the table: "inventory.client_order_line_status" */
  inventory_client_order_line_status: Array<Inventory_Client_Order_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.client_order_line_status" */
  inventory_client_order_line_status_aggregate: Inventory_Client_Order_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.client_order_line_status" using primary key columns */
  inventory_client_order_line_status_by_pk?: Maybe<Inventory_Client_Order_Line_Status>;
  /** fetch data from the table: "inventory.client_order_line_type" */
  inventory_client_order_line_type: Array<Inventory_Client_Order_Line_Type>;
  /** fetch aggregated fields from the table: "inventory.client_order_line_type" */
  inventory_client_order_line_type_aggregate: Inventory_Client_Order_Line_Type_Aggregate;
  /** fetch data from the table: "inventory.client_order_line_type" using primary key columns */
  inventory_client_order_line_type_by_pk?: Maybe<Inventory_Client_Order_Line_Type>;
  /** fetch data from the table: "inventory.client_order_status" */
  inventory_client_order_status: Array<Inventory_Client_Order_Status>;
  /** fetch aggregated fields from the table: "inventory.client_order_status" */
  inventory_client_order_status_aggregate: Inventory_Client_Order_Status_Aggregate;
  /** fetch data from the table: "inventory.client_order_status" using primary key columns */
  inventory_client_order_status_by_pk?: Maybe<Inventory_Client_Order_Status>;
  /** fetch data from the table: "inventory.client_order_type" */
  inventory_client_order_type: Array<Inventory_Client_Order_Type>;
  /** fetch aggregated fields from the table: "inventory.client_order_type" */
  inventory_client_order_type_aggregate: Inventory_Client_Order_Type_Aggregate;
  /** fetch data from the table: "inventory.client_order_type" using primary key columns */
  inventory_client_order_type_by_pk?: Maybe<Inventory_Client_Order_Type>;
  /** fetch data from the table: "inventory.country" */
  inventory_country: Array<Inventory_Country>;
  /** fetch aggregated fields from the table: "inventory.country" */
  inventory_country_aggregate: Inventory_Country_Aggregate;
  /** fetch data from the table: "inventory.country" using primary key columns */
  inventory_country_by_pk?: Maybe<Inventory_Country>;
  /** fetch data from the table: "inventory.courier" */
  inventory_courier: Array<Inventory_Courier>;
  /** fetch aggregated fields from the table: "inventory.courier" */
  inventory_courier_aggregate: Inventory_Courier_Aggregate;
  /** fetch data from the table: "inventory.courier" using primary key columns */
  inventory_courier_by_pk?: Maybe<Inventory_Courier>;
  /** fetch data from the table: "inventory.courier_service" */
  inventory_courier_service: Array<Inventory_Courier_Service>;
  /** fetch aggregated fields from the table: "inventory.courier_service" */
  inventory_courier_service_aggregate: Inventory_Courier_Service_Aggregate;
  /** fetch data from the table: "inventory.courier_service" using primary key columns */
  inventory_courier_service_by_pk?: Maybe<Inventory_Courier_Service>;
  /** fetch data from the table: "inventory.courier_type" */
  inventory_courier_type: Array<Inventory_Courier_Type>;
  /** fetch aggregated fields from the table: "inventory.courier_type" */
  inventory_courier_type_aggregate: Inventory_Courier_Type_Aggregate;
  /** fetch data from the table: "inventory.courier_type" using primary key columns */
  inventory_courier_type_by_pk?: Maybe<Inventory_Courier_Type>;
  /** fetch data from the table: "inventory.currency" */
  inventory_currency: Array<Inventory_Currency>;
  /** fetch aggregated fields from the table: "inventory.currency" */
  inventory_currency_aggregate: Inventory_Currency_Aggregate;
  /** fetch data from the table: "inventory.currency" using primary key columns */
  inventory_currency_by_pk?: Maybe<Inventory_Currency>;
  /** fetch data from the table: "inventory.date_d" */
  inventory_date_d: Array<Inventory_Date_D>;
  /** fetch aggregated fields from the table: "inventory.date_d" */
  inventory_date_d_aggregate: Inventory_Date_D_Aggregate;
  /** fetch data from the table: "inventory.date_d" using primary key columns */
  inventory_date_d_by_pk?: Maybe<Inventory_Date_D>;
  /** fetch data from the table: "inventory.exchange_rate_gbp" */
  inventory_exchange_rate_gbp: Array<Inventory_Exchange_Rate_Gbp>;
  /** fetch aggregated fields from the table: "inventory.exchange_rate_gbp" */
  inventory_exchange_rate_gbp_aggregate: Inventory_Exchange_Rate_Gbp_Aggregate;
  /** fetch data from the table: "inventory.exchange_rate_gbp" using primary key columns */
  inventory_exchange_rate_gbp_by_pk?: Maybe<Inventory_Exchange_Rate_Gbp>;
  /** fetch data from the table: "inventory.folder" */
  inventory_folder: Array<Inventory_Folder>;
  /** fetch aggregated fields from the table: "inventory.folder" */
  inventory_folder_aggregate: Inventory_Folder_Aggregate;
  /** fetch data from the table: "inventory.folder" using primary key columns */
  inventory_folder_by_pk?: Maybe<Inventory_Folder>;
  /** fetch data from the table: "inventory.locale" */
  inventory_locale: Array<Inventory_Locale>;
  /** fetch aggregated fields from the table: "inventory.locale" */
  inventory_locale_aggregate: Inventory_Locale_Aggregate;
  /** fetch data from the table: "inventory.locale" using primary key columns */
  inventory_locale_by_pk?: Maybe<Inventory_Locale>;
  /** fetch data from the table: "inventory.location" */
  inventory_location: Array<Inventory_Location>;
  /** fetch aggregated fields from the table: "inventory.location" */
  inventory_location_aggregate: Inventory_Location_Aggregate;
  /** fetch data from the table: "inventory.location" using primary key columns */
  inventory_location_by_pk?: Maybe<Inventory_Location>;
  /** fetch data from the table: "inventory_management_locations" */
  inventory_management_locations: Array<Inventory_Management_Locations>;
  /** fetch aggregated fields from the table: "inventory_management_locations" */
  inventory_management_locations_aggregate: Inventory_Management_Locations_Aggregate;
  /** fetch data from the table: "inventory_management_locations_ancestry" */
  inventory_management_locations_ancestry: Array<Inventory_Management_Locations_Ancestry>;
  /** fetch aggregated fields from the table: "inventory_management_locations_ancestry" */
  inventory_management_locations_ancestry_aggregate: Inventory_Management_Locations_Ancestry_Aggregate;
  /** fetch data from the table: "inventory_management_locations" using primary key columns */
  inventory_management_locations_by_pk?: Maybe<Inventory_Management_Locations>;
  /** fetch data from the table: "inventory_management_owners" */
  inventory_management_owners: Array<Inventory_Management_Owners>;
  /** fetch aggregated fields from the table: "inventory_management_owners" */
  inventory_management_owners_aggregate: Inventory_Management_Owners_Aggregate;
  /** fetch data from the table: "inventory_management_owners" using primary key columns */
  inventory_management_owners_by_pk?: Maybe<Inventory_Management_Owners>;
  /** fetch data from the table: "inventory_management_services" */
  inventory_management_services: Array<Inventory_Management_Services>;
  /** fetch aggregated fields from the table: "inventory_management_services" */
  inventory_management_services_aggregate: Inventory_Management_Services_Aggregate;
  /** fetch data from the table: "inventory_management_services" using primary key columns */
  inventory_management_services_by_pk?: Maybe<Inventory_Management_Services>;
  /** fetch data from the table: "inventory_management_sku" */
  inventory_management_sku: Array<Inventory_Management_Sku>;
  /** fetch aggregated fields from the table: "inventory_management_sku" */
  inventory_management_sku_aggregate: Inventory_Management_Sku_Aggregate;
  /** fetch data from the table: "inventory_management_sku" using primary key columns */
  inventory_management_sku_by_pk?: Maybe<Inventory_Management_Sku>;
  /** fetch data from the table: "inventory_management_stock_change" */
  inventory_management_stock_change: Array<Inventory_Management_Stock_Change>;
  /** fetch aggregated fields from the table: "inventory_management_stock_change" */
  inventory_management_stock_change_aggregate: Inventory_Management_Stock_Change_Aggregate;
  /** fetch data from the table: "inventory_management_stock_change" using primary key columns */
  inventory_management_stock_change_by_pk?: Maybe<Inventory_Management_Stock_Change>;
  /** fetch data from the table: "inventory_management_stock_position" */
  inventory_management_stock_position: Array<Inventory_Management_Stock_Position>;
  /** fetch aggregated fields from the table: "inventory_management_stock_position" */
  inventory_management_stock_position_aggregate: Inventory_Management_Stock_Position_Aggregate;
  /** fetch data from the table: "inventory_management_stock_position_by_date" */
  inventory_management_stock_position_by_date: Array<Inventory_Management_Stock_Position_By_Date>;
  /** fetch aggregated fields from the table: "inventory_management_stock_position_by_date" */
  inventory_management_stock_position_by_date_aggregate: Inventory_Management_Stock_Position_By_Date_Aggregate;
  /** fetch data from the table: "inventory_management_stocks" */
  inventory_management_stocks: Array<Inventory_Management_Stocks>;
  /** fetch aggregated fields from the table: "inventory_management_stocks" */
  inventory_management_stocks_aggregate: Inventory_Management_Stocks_Aggregate;
  /** fetch data from the table: "inventory_management_stocks" using primary key columns */
  inventory_management_stocks_by_pk?: Maybe<Inventory_Management_Stocks>;
  /** fetch data from the table: "inventory.meeting_note" */
  inventory_meeting_note: Array<Inventory_Meeting_Note>;
  /** fetch aggregated fields from the table: "inventory.meeting_note" */
  inventory_meeting_note_aggregate: Inventory_Meeting_Note_Aggregate;
  /** fetch data from the table: "inventory.meeting_note" using primary key columns */
  inventory_meeting_note_by_pk?: Maybe<Inventory_Meeting_Note>;
  /** fetch data from the table: "inventory.meeting_note_status" */
  inventory_meeting_note_status: Array<Inventory_Meeting_Note_Status>;
  /** fetch aggregated fields from the table: "inventory.meeting_note_status" */
  inventory_meeting_note_status_aggregate: Inventory_Meeting_Note_Status_Aggregate;
  /** fetch data from the table: "inventory.meeting_note_status" using primary key columns */
  inventory_meeting_note_status_by_pk?: Maybe<Inventory_Meeting_Note_Status>;
  /** fetch data from the table: "inventory.product" */
  inventory_product: Array<Inventory_Product>;
  /** fetch aggregated fields from the table: "inventory.product" */
  inventory_product_aggregate: Inventory_Product_Aggregate;
  /** fetch data from the table: "inventory.product" using primary key columns */
  inventory_product_by_pk?: Maybe<Inventory_Product>;
  /** fetch data from the table: "inventory.product_pricing" */
  inventory_product_pricing: Array<Inventory_Product_Pricing>;
  /** fetch aggregated fields from the table: "inventory.product_pricing" */
  inventory_product_pricing_aggregate: Inventory_Product_Pricing_Aggregate;
  /** fetch data from the table: "inventory.product_pricing" using primary key columns */
  inventory_product_pricing_by_pk?: Maybe<Inventory_Product_Pricing>;
  /** fetch data from the table: "inventory.product_stock" */
  inventory_product_stock: Array<Inventory_Product_Stock>;
  /** fetch aggregated fields from the table: "inventory.product_stock" */
  inventory_product_stock_aggregate: Inventory_Product_Stock_Aggregate;
  /** fetch data from the table: "inventory.product_stock" using primary key columns */
  inventory_product_stock_by_pk?: Maybe<Inventory_Product_Stock>;
  /** fetch data from the table: "inventory.product_stock_overview" */
  inventory_product_stock_overview: Array<Inventory_Product_Stock_Overview>;
  /** fetch aggregated fields from the table: "inventory.product_stock_overview" */
  inventory_product_stock_overview_aggregate: Inventory_Product_Stock_Overview_Aggregate;
  /** fetch data from the table: "inventory.production_order" */
  inventory_production_order: Array<Inventory_Production_Order>;
  /** fetch aggregated fields from the table: "inventory.production_order" */
  inventory_production_order_aggregate: Inventory_Production_Order_Aggregate;
  /** fetch data from the table: "inventory.production_order" using primary key columns */
  inventory_production_order_by_pk?: Maybe<Inventory_Production_Order>;
  /** fetch data from the table: "inventory.production_order_line" */
  inventory_production_order_line: Array<Inventory_Production_Order_Line>;
  /** fetch aggregated fields from the table: "inventory.production_order_line" */
  inventory_production_order_line_aggregate: Inventory_Production_Order_Line_Aggregate;
  /** fetch data from the table: "inventory.production_order_line" using primary key columns */
  inventory_production_order_line_by_pk?: Maybe<Inventory_Production_Order_Line>;
  /** fetch data from the table: "inventory.production_order_line_status" */
  inventory_production_order_line_status: Array<Inventory_Production_Order_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.production_order_line_status" */
  inventory_production_order_line_status_aggregate: Inventory_Production_Order_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.production_order_line_status" using primary key columns */
  inventory_production_order_line_status_by_pk?: Maybe<Inventory_Production_Order_Line_Status>;
  /** fetch data from the table: "inventory.production_order_status" */
  inventory_production_order_status: Array<Inventory_Production_Order_Status>;
  /** fetch aggregated fields from the table: "inventory.production_order_status" */
  inventory_production_order_status_aggregate: Inventory_Production_Order_Status_Aggregate;
  /** fetch data from the table: "inventory.production_order_status" using primary key columns */
  inventory_production_order_status_by_pk?: Maybe<Inventory_Production_Order_Status>;
  /** fetch data from the table: "inventory.role" */
  inventory_role: Array<Inventory_Role>;
  /** fetch aggregated fields from the table: "inventory.role" */
  inventory_role_aggregate: Inventory_Role_Aggregate;
  /** fetch data from the table: "inventory.role" using primary key columns */
  inventory_role_by_pk?: Maybe<Inventory_Role>;
  /** fetch data from the table: "inventory.shipment" */
  inventory_shipment: Array<Inventory_Shipment>;
  /** fetch aggregated fields from the table: "inventory.shipment" */
  inventory_shipment_aggregate: Inventory_Shipment_Aggregate;
  /** fetch data from the table: "inventory.shipment" using primary key columns */
  inventory_shipment_by_pk?: Maybe<Inventory_Shipment>;
  /** fetch data from the table: "inventory.shipment_invoice" */
  inventory_shipment_invoice: Array<Inventory_Shipment_Invoice>;
  /** fetch aggregated fields from the table: "inventory.shipment_invoice" */
  inventory_shipment_invoice_aggregate: Inventory_Shipment_Invoice_Aggregate;
  /** fetch data from the table: "inventory.shipment_invoice" using primary key columns */
  inventory_shipment_invoice_by_pk?: Maybe<Inventory_Shipment_Invoice>;
  /** fetch data from the table: "inventory.shipment_line" */
  inventory_shipment_line: Array<Inventory_Shipment_Line>;
  /** fetch aggregated fields from the table: "inventory.shipment_line" */
  inventory_shipment_line_aggregate: Inventory_Shipment_Line_Aggregate;
  /** fetch data from the table: "inventory.shipment_line" using primary key columns */
  inventory_shipment_line_by_pk?: Maybe<Inventory_Shipment_Line>;
  /** fetch data from the table: "inventory.shipment_line_status" */
  inventory_shipment_line_status: Array<Inventory_Shipment_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.shipment_line_status" */
  inventory_shipment_line_status_aggregate: Inventory_Shipment_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.shipment_line_status" using primary key columns */
  inventory_shipment_line_status_by_pk?: Maybe<Inventory_Shipment_Line_Status>;
  /** fetch data from the table: "inventory.shipment_status" */
  inventory_shipment_status: Array<Inventory_Shipment_Status>;
  /** fetch aggregated fields from the table: "inventory.shipment_status" */
  inventory_shipment_status_aggregate: Inventory_Shipment_Status_Aggregate;
  /** fetch data from the table: "inventory.shipment_status" using primary key columns */
  inventory_shipment_status_by_pk?: Maybe<Inventory_Shipment_Status>;
  /** fetch data from the table: "inventory.stock_status" */
  inventory_stock_status: Array<Inventory_Stock_Status>;
  /** fetch aggregated fields from the table: "inventory.stock_status" */
  inventory_stock_status_aggregate: Inventory_Stock_Status_Aggregate;
  /** fetch data from the table: "inventory.stock_status" using primary key columns */
  inventory_stock_status_by_pk?: Maybe<Inventory_Stock_Status>;
  /** fetch data from the table: "inventory.supplier_invoice" */
  inventory_supplier_invoice: Array<Inventory_Supplier_Invoice>;
  /** fetch aggregated fields from the table: "inventory.supplier_invoice" */
  inventory_supplier_invoice_aggregate: Inventory_Supplier_Invoice_Aggregate;
  /** fetch data from the table: "inventory.supplier_invoice" using primary key columns */
  inventory_supplier_invoice_by_pk?: Maybe<Inventory_Supplier_Invoice>;
  /** fetch data from the table: "inventory.supplier_invoice_line" */
  inventory_supplier_invoice_line: Array<Inventory_Supplier_Invoice_Line>;
  /** fetch aggregated fields from the table: "inventory.supplier_invoice_line" */
  inventory_supplier_invoice_line_aggregate: Inventory_Supplier_Invoice_Line_Aggregate;
  /** fetch data from the table: "inventory.supplier_invoice_line" using primary key columns */
  inventory_supplier_invoice_line_by_pk?: Maybe<Inventory_Supplier_Invoice_Line>;
  /** fetch data from the table: "inventory.supplier_invoice_line_status" */
  inventory_supplier_invoice_line_status: Array<Inventory_Supplier_Invoice_Line_Status>;
  /** fetch aggregated fields from the table: "inventory.supplier_invoice_line_status" */
  inventory_supplier_invoice_line_status_aggregate: Inventory_Supplier_Invoice_Line_Status_Aggregate;
  /** fetch data from the table: "inventory.supplier_invoice_line_status" using primary key columns */
  inventory_supplier_invoice_line_status_by_pk?: Maybe<Inventory_Supplier_Invoice_Line_Status>;
  /** fetch data from the table: "inventory.supplier_invoice_status" */
  inventory_supplier_invoice_status: Array<Inventory_Supplier_Invoice_Status>;
  /** fetch aggregated fields from the table: "inventory.supplier_invoice_status" */
  inventory_supplier_invoice_status_aggregate: Inventory_Supplier_Invoice_Status_Aggregate;
  /** fetch data from the table: "inventory.supplier_invoice_status" using primary key columns */
  inventory_supplier_invoice_status_by_pk?: Maybe<Inventory_Supplier_Invoice_Status>;
  /** fetch data from the table: "inventory.supplier_product" */
  inventory_supplier_product: Array<Inventory_Supplier_Product>;
  /** fetch aggregated fields from the table: "inventory.supplier_product" */
  inventory_supplier_product_aggregate: Inventory_Supplier_Product_Aggregate;
  /** fetch data from the table: "inventory.supplier_product" using primary key columns */
  inventory_supplier_product_by_pk?: Maybe<Inventory_Supplier_Product>;
  /** fetch data from the table: "inventory.supplier_quote" */
  inventory_supplier_quote: Array<Inventory_Supplier_Quote>;
  /** fetch aggregated fields from the table: "inventory.supplier_quote" */
  inventory_supplier_quote_aggregate: Inventory_Supplier_Quote_Aggregate;
  /** fetch data from the table: "inventory.supplier_quote" using primary key columns */
  inventory_supplier_quote_by_pk?: Maybe<Inventory_Supplier_Quote>;
  /** fetch data from the table: "marketplace_client" */
  marketplace_client: Array<Marketplace_Client>;
  /** fetch aggregated fields from the table: "marketplace_client" */
  marketplace_client_aggregate: Marketplace_Client_Aggregate;
  /** fetch data from the table: "marketplace_client" using primary key columns */
  marketplace_client_by_pk?: Maybe<Marketplace_Client>;
  /** fetch data from the table: "marketplace_data_collector_merchant_sales_transformed" */
  marketplace_data_collector_merchant_sales_transformed: Array<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
  /** fetch aggregated fields from the table: "marketplace_data_collector_merchant_sales_transformed" */
  marketplace_data_collector_merchant_sales_transformed_aggregate: Marketplace_Data_Collector_Merchant_Sales_Transformed_Aggregate;
  /** fetch data from the table: "marketplace_data_collector_merchant_sales_transformed" using primary key columns */
  marketplace_data_collector_merchant_sales_transformed_by_pk?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed>;
  /** fetch data from the table: "marketplace_master_sku" */
  marketplace_master_sku: Array<Marketplace_Master_Sku>;
  /** fetch aggregated fields from the table: "marketplace_master_sku" */
  marketplace_master_sku_aggregate: Marketplace_Master_Sku_Aggregate;
  /** fetch data from the table: "marketplace_master_sku" using primary key columns */
  marketplace_master_sku_by_pk?: Maybe<Marketplace_Master_Sku>;
  /** fetch data from the table: "marketplace_master_sku_marketplace_unit_sku" */
  marketplace_master_sku_marketplace_unit_sku: Array<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
  /** fetch aggregated fields from the table: "marketplace_master_sku_marketplace_unit_sku" */
  marketplace_master_sku_marketplace_unit_sku_aggregate: Marketplace_Master_Sku_Marketplace_Unit_Sku_Aggregate;
  /** fetch data from the table: "marketplace_master_sku_marketplace_unit_sku" using primary key columns */
  marketplace_master_sku_marketplace_unit_sku_by_pk?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku>;
  /** fetch data from the table: "marketplace_merchants" */
  marketplace_merchants: Array<Marketplace_Merchants>;
  /** fetch aggregated fields from the table: "marketplace_merchants" */
  marketplace_merchants_aggregate: Marketplace_Merchants_Aggregate;
  /** fetch data from the table: "marketplace_merchants" using primary key columns */
  marketplace_merchants_by_pk?: Maybe<Marketplace_Merchants>;
  /** fetch data from the table: "marketplace_merchants_floomx_replens_order" */
  marketplace_merchants_floomx_replens_order: Array<Marketplace_Merchants_Floomx_Replens_Order>;
  /** fetch aggregated fields from the table: "marketplace_merchants_floomx_replens_order" */
  marketplace_merchants_floomx_replens_order_aggregate: Marketplace_Merchants_Floomx_Replens_Order_Aggregate;
  /** fetch data from the table: "marketplace_merchants_floomx_replens_order" using primary key columns */
  marketplace_merchants_floomx_replens_order_by_pk?: Maybe<Marketplace_Merchants_Floomx_Replens_Order>;
  /** fetch data from the table: "marketplace_merchants_floomx_replens_order_items" */
  marketplace_merchants_floomx_replens_order_items: Array<Marketplace_Merchants_Floomx_Replens_Order_Items>;
  /** fetch aggregated fields from the table: "marketplace_merchants_floomx_replens_order_items" */
  marketplace_merchants_floomx_replens_order_items_aggregate: Marketplace_Merchants_Floomx_Replens_Order_Items_Aggregate;
  /** fetch data from the table: "marketplace_merchants_floomx_replens_order_items" using primary key columns */
  marketplace_merchants_floomx_replens_order_items_by_pk?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items>;
  /** fetch data from the table: "marketplace_merchants_raw" */
  marketplace_merchants_raw: Array<Marketplace_Merchants_Raw>;
  /** fetch aggregated fields from the table: "marketplace_merchants_raw" */
  marketplace_merchants_raw_aggregate: Marketplace_Merchants_Raw_Aggregate;
  /** fetch data from the table: "marketplace_merchants_replens" */
  marketplace_merchants_replens: Array<Marketplace_Merchants_Replens>;
  /** fetch aggregated fields from the table: "marketplace_merchants_replens" */
  marketplace_merchants_replens_aggregate: Marketplace_Merchants_Replens_Aggregate;
  /** fetch data from the table: "marketplace_merchants_replens" using primary key columns */
  marketplace_merchants_replens_by_pk?: Maybe<Marketplace_Merchants_Replens>;
  /** fetch data from the table: "marketplace_merchants_replens_order" */
  marketplace_merchants_replens_order: Array<Marketplace_Merchants_Replens_Order>;
  /** fetch aggregated fields from the table: "marketplace_merchants_replens_order" */
  marketplace_merchants_replens_order_aggregate: Marketplace_Merchants_Replens_Order_Aggregate;
  /** fetch data from the table: "marketplace_merchants_replens_order" using primary key columns */
  marketplace_merchants_replens_order_by_pk?: Maybe<Marketplace_Merchants_Replens_Order>;
  /** fetch data from the table: "marketplace_merchants_sales" */
  marketplace_merchants_sales: Array<Marketplace_Merchants_Sales>;
  /** fetch aggregated fields from the table: "marketplace_merchants_sales" */
  marketplace_merchants_sales_aggregate: Marketplace_Merchants_Sales_Aggregate;
  /** fetch data from the table: "marketplace_merchants_sales" using primary key columns */
  marketplace_merchants_sales_by_pk?: Maybe<Marketplace_Merchants_Sales>;
  /** fetch data from the table: "marketplace_merchants_starting_stocks" */
  marketplace_merchants_starting_stocks: Array<Marketplace_Merchants_Starting_Stocks>;
  /** fetch aggregated fields from the table: "marketplace_merchants_starting_stocks" */
  marketplace_merchants_starting_stocks_aggregate: Marketplace_Merchants_Starting_Stocks_Aggregate;
  /** fetch data from the table: "marketplace_merchants_starting_stocks" using primary key columns */
  marketplace_merchants_starting_stocks_by_pk?: Maybe<Marketplace_Merchants_Starting_Stocks>;
  /** fetch data from the table: "marketplace_merchants_stocks" */
  marketplace_merchants_stocks: Array<Marketplace_Merchants_Stocks>;
  /** fetch aggregated fields from the table: "marketplace_merchants_stocks" */
  marketplace_merchants_stocks_aggregate: Marketplace_Merchants_Stocks_Aggregate;
  /** fetch data from the table: "marketplace_merchants_stocks" using primary key columns */
  marketplace_merchants_stocks_by_pk?: Maybe<Marketplace_Merchants_Stocks>;
  /** fetch data from the table: "marketplace_merchants_stocks_v2" */
  marketplace_merchants_stocks_v2: Array<Marketplace_Merchants_Stocks_V2>;
  /** fetch aggregated fields from the table: "marketplace_merchants_stocks_v2" */
  marketplace_merchants_stocks_v2_aggregate: Marketplace_Merchants_Stocks_V2_Aggregate;
  /** fetch data from the table: "marketplace_merchants_stocks_v2" using primary key columns */
  marketplace_merchants_stocks_v2_by_pk?: Maybe<Marketplace_Merchants_Stocks_V2>;
  /** fetch data from the table: "marketplace_replenishment_email" */
  marketplace_replenishment_email: Array<Marketplace_Replenishment_Email>;
  /** fetch aggregated fields from the table: "marketplace_replenishment_email" */
  marketplace_replenishment_email_aggregate: Marketplace_Replenishment_Email_Aggregate;
  /** fetch data from the table: "marketplace_replenishment_email" using primary key columns */
  marketplace_replenishment_email_by_pk?: Maybe<Marketplace_Replenishment_Email>;
  /** fetch data from the table: "marketplace_replenishment_suggestions" */
  marketplace_replenishment_suggestions: Array<Marketplace_Replenishment_Suggestions>;
  /** fetch aggregated fields from the table: "marketplace_replenishment_suggestions" */
  marketplace_replenishment_suggestions_aggregate: Marketplace_Replenishment_Suggestions_Aggregate;
  /** fetch data from the table: "marketplace_replenishment_suggestions" using primary key columns */
  marketplace_replenishment_suggestions_by_pk?: Maybe<Marketplace_Replenishment_Suggestions>;
  /** fetch data from the table: "marketplace_replenishment_suggestions_v2" */
  marketplace_replenishment_suggestions_v2: Array<Marketplace_Replenishment_Suggestions_V2>;
  /** fetch aggregated fields from the table: "marketplace_replenishment_suggestions_v2" */
  marketplace_replenishment_suggestions_v2_aggregate: Marketplace_Replenishment_Suggestions_V2_Aggregate;
  /** fetch data from the table: "marketplace_replenishment_suggestions_v2" using primary key columns */
  marketplace_replenishment_suggestions_v2_by_pk?: Maybe<Marketplace_Replenishment_Suggestions_V2>;
  /** fetch data from the table: "marketplace_unit_sku" */
  marketplace_unit_sku: Array<Marketplace_Unit_Sku>;
  /** fetch aggregated fields from the table: "marketplace_unit_sku" */
  marketplace_unit_sku_aggregate: Marketplace_Unit_Sku_Aggregate;
  /** fetch data from the table: "marketplace_unit_sku" using primary key columns */
  marketplace_unit_sku_by_pk?: Maybe<Marketplace_Unit_Sku>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};

/** subscription root */
export type Subscription_RootInventory_AccountArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Order_By>>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Order_By>>;
  where?: Maybe<Inventory_Account_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Account_Meeting_NoteArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Meeting_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Account_Meeting_Note_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Meeting_Note_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Account_Metadata_KeysArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Metadata_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Metadata_Keys_Order_By>>;
  where?: Maybe<Inventory_Account_Metadata_Keys_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Metadata_Keys_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Metadata_Keys_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Metadata_Keys_Order_By>>;
  where?: Maybe<Inventory_Account_Metadata_Keys_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_ReferralArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Referral_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Referral_Order_By>>;
  where?: Maybe<Inventory_Account_Referral_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Referral_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Referral_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Referral_Order_By>>;
  where?: Maybe<Inventory_Account_Referral_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Referral_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Account_RoleArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Role_Order_By>>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Role_Order_By>>;
  where?: Maybe<Inventory_Account_Role_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Role_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Account_SectorArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Sector_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Sector_Order_By>>;
  where?: Maybe<Inventory_Account_Sector_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Sector_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Sector_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Sector_Order_By>>;
  where?: Maybe<Inventory_Account_Sector_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Sector_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Account_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Status_Order_By>>;
  where?: Maybe<Inventory_Account_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Account_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Account_Status_Order_By>>;
  where?: Maybe<Inventory_Account_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Account_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_AddressArgs = {
  distinct_on?: Maybe<Array<Inventory_Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Address_Order_By>>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Address_Order_By>>;
  where?: Maybe<Inventory_Address_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Address_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_AttachmentArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Order_By>>;
  where?: Maybe<Inventory_Attachment_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Attachment_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Order_By>>;
  where?: Maybe<Inventory_Attachment_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Attachment_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Attachment_FolderArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Folder_Order_By>>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Attachment_Folder_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Attachment_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Attachment_Folder_Order_By>>;
  where?: Maybe<Inventory_Attachment_Folder_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Attachment_Folder_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Base_ProductArgs = {
  distinct_on?: Maybe<Array<Inventory_Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Base_Product_Order_By>>;
  where?: Maybe<Inventory_Base_Product_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Base_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Base_Product_Order_By>>;
  where?: Maybe<Inventory_Base_Product_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Base_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_BatchArgs = {
  distinct_on?: Maybe<Array<Inventory_Batch_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Batch_Order_By>>;
  where?: Maybe<Inventory_Batch_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Batch_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Batch_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Batch_Order_By>>;
  where?: Maybe<Inventory_Batch_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Batch_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_InvoiceArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Invoice_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Invoice_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Invoice_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_OrderArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Line_TypeArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Type_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Line_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Line_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Line_Type_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Line_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Line_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Status_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_TypeArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Type_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Client_Order_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Client_Order_Type_Order_By>>;
  where?: Maybe<Inventory_Client_Order_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Client_Order_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_CountryArgs = {
  distinct_on?: Maybe<Array<Inventory_Country_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Country_Order_By>>;
  where?: Maybe<Inventory_Country_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Country_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Country_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Country_Order_By>>;
  where?: Maybe<Inventory_Country_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Country_By_PkArgs = {
  country_code: Scalars["String"];
};

/** subscription root */
export type Subscription_RootInventory_CourierArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Order_By>>;
  where?: Maybe<Inventory_Courier_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Courier_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Order_By>>;
  where?: Maybe<Inventory_Courier_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Courier_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Courier_ServiceArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Service_Order_By>>;
  where?: Maybe<Inventory_Courier_Service_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Courier_Service_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Service_Order_By>>;
  where?: Maybe<Inventory_Courier_Service_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Courier_Service_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Courier_TypeArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Type_Order_By>>;
  where?: Maybe<Inventory_Courier_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Courier_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Courier_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Courier_Type_Order_By>>;
  where?: Maybe<Inventory_Courier_Type_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Courier_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_CurrencyArgs = {
  distinct_on?: Maybe<Array<Inventory_Currency_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Currency_Order_By>>;
  where?: Maybe<Inventory_Currency_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Currency_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Currency_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Currency_Order_By>>;
  where?: Maybe<Inventory_Currency_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Currency_By_PkArgs = {
  currency_code: Scalars["String"];
};

/** subscription root */
export type Subscription_RootInventory_Date_DArgs = {
  distinct_on?: Maybe<Array<Inventory_Date_D_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Date_D_Order_By>>;
  where?: Maybe<Inventory_Date_D_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Date_D_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Date_D_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Date_D_Order_By>>;
  where?: Maybe<Inventory_Date_D_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Date_D_By_PkArgs = {
  date_key: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Exchange_Rate_GbpArgs = {
  distinct_on?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Order_By>>;
  where?: Maybe<Inventory_Exchange_Rate_Gbp_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Exchange_Rate_Gbp_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Exchange_Rate_Gbp_Order_By>>;
  where?: Maybe<Inventory_Exchange_Rate_Gbp_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Exchange_Rate_Gbp_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_FolderArgs = {
  distinct_on?: Maybe<Array<Inventory_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Folder_Order_By>>;
  where?: Maybe<Inventory_Folder_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Folder_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Folder_Order_By>>;
  where?: Maybe<Inventory_Folder_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Folder_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_LocaleArgs = {
  distinct_on?: Maybe<Array<Inventory_Locale_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Locale_Order_By>>;
  where?: Maybe<Inventory_Locale_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Locale_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Locale_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Locale_Order_By>>;
  where?: Maybe<Inventory_Locale_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Locale_By_PkArgs = {
  locale: Scalars["String"];
};

/** subscription root */
export type Subscription_RootInventory_LocationArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Location_Order_By>>;
  where?: Maybe<Inventory_Location_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Location_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Management_LocationsArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Locations_AncestryArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Ancestry_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Locations_Ancestry_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Locations_Ancestry_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Locations_Ancestry_Order_By>>;
  where?: Maybe<Inventory_Management_Locations_Ancestry_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Locations_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Management_OwnersArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Owners_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Owners_Order_By>>;
  where?: Maybe<Inventory_Management_Owners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Owners_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Owners_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Owners_Order_By>>;
  where?: Maybe<Inventory_Management_Owners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Owners_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Management_ServicesArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Services_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Services_Order_By>>;
  where?: Maybe<Inventory_Management_Services_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Services_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Services_Order_By>>;
  where?: Maybe<Inventory_Management_Services_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Services_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Management_SkuArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Sku_Order_By>>;
  where?: Maybe<Inventory_Management_Sku_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Sku_Order_By>>;
  where?: Maybe<Inventory_Management_Sku_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Management_Stock_ChangeArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Change_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Stock_Change_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Change_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Change_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Stock_Change_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Management_Stock_PositionArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Stock_Position_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Stock_Position_By_DateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Stock_Position_By_Date_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stock_Position_By_Date_Order_By>>;
  where?: Maybe<Inventory_Management_Stock_Position_By_Date_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_StocksArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stocks_Order_By>>;
  where?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Management_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Management_Stocks_Order_By>>;
  where?: Maybe<Inventory_Management_Stocks_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Management_Stocks_By_PkArgs = {
  location_id: Scalars["Int"];
  sku_id: Scalars["Int"];
  status: Scalars["String"];
};

/** subscription root */
export type Subscription_RootInventory_Meeting_NoteArgs = {
  distinct_on?: Maybe<Array<Inventory_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Meeting_Note_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Meeting_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Meeting_Note_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Meeting_Note_Order_By>>;
  where?: Maybe<Inventory_Meeting_Note_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Meeting_Note_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Meeting_Note_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Meeting_Note_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Meeting_Note_Status_Order_By>>;
  where?: Maybe<Inventory_Meeting_Note_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Meeting_Note_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Meeting_Note_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Meeting_Note_Status_Order_By>>;
  where?: Maybe<Inventory_Meeting_Note_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Meeting_Note_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_ProductArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Order_By>>;
  where?: Maybe<Inventory_Product_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Order_By>>;
  where?: Maybe<Inventory_Product_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Product_PricingArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Pricing_Order_By>>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Product_Pricing_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Pricing_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Pricing_Order_By>>;
  where?: Maybe<Inventory_Product_Pricing_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Product_Pricing_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Product_StockArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Product_Stock_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Product_Stock_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Product_Stock_OverviewArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Overview_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Overview_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Overview_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Product_Stock_Overview_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Product_Stock_Overview_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Product_Stock_Overview_Order_By>>;
  where?: Maybe<Inventory_Product_Stock_Overview_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_OrderArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Status_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Production_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Production_Order_Status_Order_By>>;
  where?: Maybe<Inventory_Production_Order_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Production_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_RoleArgs = {
  distinct_on?: Maybe<Array<Inventory_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Role_Order_By>>;
  where?: Maybe<Inventory_Role_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Role_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Role_Order_By>>;
  where?: Maybe<Inventory_Role_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Role_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_ShipmentArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Order_By>>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Order_By>>;
  where?: Maybe<Inventory_Shipment_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Shipment_InvoiceArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Invoice_Order_By>>;
  where?: Maybe<Inventory_Shipment_Invoice_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Invoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Invoice_Order_By>>;
  where?: Maybe<Inventory_Shipment_Invoice_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Shipment_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Shipment_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Shipment_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Status_Order_By>>;
  where?: Maybe<Inventory_Shipment_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Shipment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Shipment_Status_Order_By>>;
  where?: Maybe<Inventory_Shipment_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Shipment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Stock_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Stock_Status_Order_By>>;
  where?: Maybe<Inventory_Stock_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Stock_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Stock_Status_Order_By>>;
  where?: Maybe<Inventory_Stock_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Stock_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Supplier_InvoiceArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_LineArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Line_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Line_Status_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Line_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_StatusArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Status_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Invoice_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Invoice_Status_Order_By>>;
  where?: Maybe<Inventory_Supplier_Invoice_Status_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Invoice_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Supplier_ProductArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Product_Order_By>>;
  where?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Product_Order_By>>;
  where?: Maybe<Inventory_Supplier_Product_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootInventory_Supplier_QuoteArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Quote_AggregateArgs = {
  distinct_on?: Maybe<Array<Inventory_Supplier_Quote_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inventory_Supplier_Quote_Order_By>>;
  where?: Maybe<Inventory_Supplier_Quote_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootInventory_Supplier_Quote_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_ClientArgs = {
  distinct_on?: Maybe<Array<Marketplace_Client_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Client_Order_By>>;
  where?: Maybe<Marketplace_Client_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Client_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Client_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Client_Order_By>>;
  where?: Maybe<Marketplace_Client_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Client_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Data_Collector_Merchant_Sales_TransformedArgs = {
  distinct_on?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Order_By>>;
  where?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Data_Collector_Merchant_Sales_Transformed_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Data_Collector_Merchant_Sales_Transformed_Order_By>>;
  where?: Maybe<Marketplace_Data_Collector_Merchant_Sales_Transformed_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Data_Collector_Merchant_Sales_Transformed_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Master_SkuArgs = {
  distinct_on?: Maybe<Array<Marketplace_Master_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Master_Sku_Order_By>>;
  where?: Maybe<Marketplace_Master_Sku_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Master_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Master_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Master_Sku_Order_By>>;
  where?: Maybe<Marketplace_Master_Sku_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Master_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Master_Sku_Marketplace_Unit_SkuArgs = {
  distinct_on?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Order_By>>;
  where?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Master_Sku_Marketplace_Unit_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Master_Sku_Marketplace_Unit_Sku_Order_By>>;
  where?: Maybe<Marketplace_Master_Sku_Marketplace_Unit_Sku_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Master_Sku_Marketplace_Unit_Sku_By_PkArgs = {
  marketplace_master_sku_id: Scalars["Int"];
  marketplace_unit_sku_id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_MerchantsArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Floomx_Replens_OrderArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Floomx_Replens_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Floomx_Replens_Order_By_PkArgs = {
  order_id: Scalars["String"];
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Floomx_Replens_Order_ItemsArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Floomx_Replens_Order_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Floomx_Replens_Order_Items_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Floomx_Replens_Order_Items_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Floomx_Replens_Order_Items_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_RawArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Raw_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Raw_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Raw_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Raw_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Raw_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Raw_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Raw_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_ReplensArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Replens_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Replens_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Replens_OrderArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Replens_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Replens_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Replens_Order_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Replens_Order_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Replens_Order_By_PkArgs = {
  order_id: Scalars["String"];
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_SalesArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Sales_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Sales_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Sales_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Sales_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Sales_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Sales_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Sales_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Sales_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Starting_StocksArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Starting_Stocks_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Starting_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Starting_Stocks_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Starting_Stocks_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Starting_Stocks_By_PkArgs = {
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_StocksArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Stocks_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Stocks_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Stocks_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Stocks_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Stocks_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Stocks_By_PkArgs = {
  create_date: Scalars["timestamptz"];
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Stocks_V2Args = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Stocks_V2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Stocks_V2_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Merchants_Stocks_V2_Order_By>>;
  where?: Maybe<Marketplace_Merchants_Stocks_V2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Merchants_Stocks_V2_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_EmailArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Email_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Email_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_Email_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Email_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Email_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Email_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_Email_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_SuggestionsArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Suggestions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Suggestions_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_Suggestions_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Suggestions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Suggestions_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_Suggestions_By_PkArgs = {
  merchant_id: Scalars["Int"];
  unit_sku_id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_Suggestions_V2Args = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_V2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_Suggestions_V2_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Replenishment_Suggestions_V2_Order_By>>;
  where?: Maybe<Marketplace_Replenishment_Suggestions_V2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Replenishment_Suggestions_V2_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootMarketplace_Unit_SkuArgs = {
  distinct_on?: Maybe<Array<Marketplace_Unit_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Unit_Sku_Order_By>>;
  where?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Unit_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Marketplace_Unit_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Marketplace_Unit_Sku_Order_By>>;
  where?: Maybe<Marketplace_Unit_Sku_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootMarketplace_Unit_Sku_By_PkArgs = {
  id: Scalars["Int"];
};

/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  uid: Scalars["String"];
};

/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: "users";
  created_at: Scalars["timestamptz"];
  email: Scalars["String"];
  name: Scalars["String"];
  nickname: Scalars["String"];
  uid: Scalars["String"];
  verified: Scalars["Boolean"];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: "users_aggregate";
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: "users_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  nickname?: Maybe<String_Comparison_Exp>;
  uid?: Maybe<String_Comparison_Exp>;
  verified?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = "users_pkey",
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  uid?: Maybe<Scalars["String"]>;
  verified?: Maybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: "users_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  uid?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  nickname?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: "users_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  uid?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  nickname?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: "users_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  nickname?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  verified?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  uid: Scalars["String"];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Name = "name",
  /** column name */
  Nickname = "nickname",
  /** column name */
  Uid = "uid",
  /** column name */
  Verified = "verified",
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  uid?: Maybe<Scalars["String"]>;
  verified?: Maybe<Scalars["Boolean"]>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Email = "email",
  /** column name */
  Name = "name",
  /** column name */
  Nickname = "nickname",
  /** column name */
  Uid = "uid",
  /** column name */
  Verified = "verified",
}

export type AddShipmentMutationVariables = Exact<{
  courier_service_id: Scalars["Int"];
  arrival_time?: Maybe<Scalars["timestamptz"]>;
  destination_location_id?: Maybe<Scalars["Int"]>;
  estimated_arrival_days?: Maybe<Scalars["Int"]>;
  shipped_time?: Maybe<Scalars["timestamptz"]>;
  estimated_shipped_time?: Maybe<Scalars["timestamptz"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  user_id: Scalars["String"];
}>;

export type AddShipmentMutation = {
  __typename?: "mutation_root";
  insert_inventory_shipment_one?: Maybe<{ __typename?: "inventory_shipment"; id: number }>;
};

export type AddShipmentLineMutationVariables = Exact<{
  client_order_line_id: Scalars["Int"];
  product_id: Scalars["Int"];
  source_location_id: Scalars["Int"];
  destination_location_id: Scalars["Int"];
  source_product_stock_id: Scalars["Int"];
  updated_source_product_stock_qty: Scalars["Int"];
  source_product_stock_account_id: Scalars["Int"];
  reserved_qty: Scalars["Int"];
  user_id: Scalars["String"];
  shipment_id?: Maybe<Scalars["Int"]>;
}>;

export type AddShipmentLineMutation = {
  __typename?: "mutation_root";
  insert_inventory_shipment_line_one?: Maybe<{
    __typename?: "inventory_shipment_line";
    id: number;
    product_stocks: Array<{
      __typename?: "inventory_product_stock";
      id: number;
      batch: {
        __typename?: "inventory_batch";
        id: number;
        supplier_account_id: number;
        user_id: string;
      };
      stock_status: { __typename?: "inventory_stock_status"; stock_status_name: string };
    }>;
  }>;
  update_inventory_product_stock_by_pk?: Maybe<{
    __typename?: "inventory_product_stock";
    id: number;
    qty: number;
    stock_status: { __typename?: "inventory_stock_status"; stock_status_name: string };
  }>;
};

export type CancelShipmentMutationVariables = Exact<{
  shipment_id: Scalars["Int"];
  updated_product_stocks:
    | Array<Inventory_Product_Stock_Insert_Input>
    | Inventory_Product_Stock_Insert_Input;
}>;

export type CancelShipmentMutation = {
  __typename?: "mutation_root";
  update_inventory_shipment_by_pk?: Maybe<{
    __typename?: "inventory_shipment";
    id: number;
    shipment_status: {
      __typename?: "inventory_shipment_status";
      id: number;
      shipment_status_name: string;
    };
  }>;
  update_inventory_shipment_line?: Maybe<{
    __typename?: "inventory_shipment_line_mutation_response";
    returning: Array<{
      __typename?: "inventory_shipment_line";
      id: number;
      shipment_line_status: {
        __typename?: "inventory_shipment_line_status";
        id: number;
        name: string;
      };
    }>;
  }>;
  insert_inventory_product_stock?: Maybe<{
    __typename?: "inventory_product_stock_mutation_response";
    returning: Array<{
      __typename?: "inventory_product_stock";
      id: number;
      qty: number;
      initial_qty: number;
      stock_status: { __typename?: "inventory_stock_status"; id: number; name: string };
      location: { __typename?: "inventory_location"; id: number; location_name: string };
    }>;
  }>;
};

export type DeleteShipmentLineMutationVariables = Exact<{
  shipment_line_id: Scalars["Int"];
  source_product_stock_id: Scalars["Int"];
  updated_source_product_stock_qty: Scalars["Int"];
  user_id: Scalars["String"];
}>;

export type DeleteShipmentLineMutation = {
  __typename?: "mutation_root";
  update_inventory_shipment_line_by_pk?: Maybe<{
    __typename?: "inventory_shipment_line";
    id: number;
  }>;
  update_inventory_product_stock_by_pk?: Maybe<{
    __typename?: "inventory_product_stock";
    id: number;
    qty: number;
    stock_status: { __typename?: "inventory_stock_status"; stock_status_name: string };
  }>;
  update_inventory_product_stock?: Maybe<{
    __typename?: "inventory_product_stock_mutation_response";
    affected_rows: number;
    returning: Array<{ __typename?: "inventory_product_stock"; id: number; qty: number }>;
  }>;
};

export type UpdateShipmentMutationVariables = Exact<{
  id: Scalars["Int"];
  user_id: Scalars["String"];
  destination_location_id: Scalars["Int"];
  courier_service_id: Scalars["Int"];
  estimated_arrival_days: Scalars["Int"];
  arrival_time?: Maybe<Scalars["timestamptz"]>;
  estimated_shipped_time?: Maybe<Scalars["timestamptz"]>;
  shipped_time?: Maybe<Scalars["timestamptz"]>;
  shipment_status_id: Scalars["Int"];
  shipment_line_status_id: Scalars["Int"];
  updated_product_stock:
    | Array<Inventory_Product_Stock_Insert_Input>
    | Inventory_Product_Stock_Insert_Input;
}>;

export type UpdateShipmentMutation = {
  __typename?: "mutation_root";
  update_inventory_shipment_by_pk?: Maybe<{ __typename?: "inventory_shipment"; id: number }>;
  insert_inventory_product_stock?: Maybe<{
    __typename?: "inventory_product_stock_mutation_response";
    returning: Array<{
      __typename?: "inventory_product_stock";
      id: number;
      qty: number;
      initial_qty: number;
      stock_status: { __typename?: "inventory_stock_status"; id: number; name: string };
      location: { __typename?: "inventory_location"; id: number; location_name: string };
    }>;
  }>;
  update_inventory_shipment_line?: Maybe<{
    __typename?: "inventory_shipment_line_mutation_response";
    returning: Array<{
      __typename?: "inventory_shipment_line";
      id: number;
      shipment_line_status: {
        __typename?: "inventory_shipment_line_status";
        id: number;
        name: string;
      };
    }>;
  }>;
};

export type ClientDetailQueryVariables = Exact<{
  client_id: Scalars["Int"];
}>;

export type ClientDetailQuery = {
  __typename?: "query_root";
  inventory_account: Array<{
    __typename?: "inventory_account";
    id: number;
    local_name: string;
    account_description: string;
    website: string;
    logo_url: string;
    email: string;
    telephone_number: string;
    account_sector: { __typename?: "inventory_account_sector"; account_sector_name: string };
    account_roles: Array<{
      __typename?: "inventory_account_role";
      role: { __typename?: "inventory_role"; role_name: string; id: number };
    }>;
    account_locations: Array<{
      __typename?: "inventory_location";
      id: number;
      parent_location_id?: Maybe<number>;
      name: string;
      child_locations: Array<{
        __typename?: "inventory_location";
        name: string;
        address: { __typename?: "inventory_address"; name: string };
        child_locations: Array<{
          __typename?: "inventory_location";
          name: string;
          address: { __typename?: "inventory_address"; name: string };
          child_locations: Array<{
            __typename?: "inventory_location";
            name: string;
            address: { __typename?: "inventory_address"; name: string };
          }>;
        }>;
      }>;
    }>;
  }>;
  referrals: Array<{
    __typename?: "inventory_account_referral";
    account: { __typename?: "inventory_account"; local_name: string };
  }>;
  referrers: Array<{
    __typename?: "inventory_account_referral";
    referrerAccountId: { __typename?: "inventory_account"; local_name: string };
  }>;
};

export type ClientOrderLinesQueryVariables = Exact<{
  destination_location_id?: Maybe<Scalars["Int"]>;
  source_location_id?: Maybe<Scalars["Int"]>;
  statusFilter?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
  minQty?: Maybe<Scalars["Int"]>;
}>;

export type ClientOrderLinesQuery = {
  __typename?: "query_root";
  inventory_client_order_line: Array<{
    __typename?: "inventory_client_order_line";
    id: number;
    client_order_line_type_id: number;
    requested_qty: number;
    unit_price?: Maybe<any>;
    product_pricing_id?: Maybe<number>;
    client_order_id: number;
    outstanding_qty?: Maybe<number>;
    product?: Maybe<{
      __typename?: "inventory_product";
      id: number;
      case_qty: number;
      base_product: {
        __typename?: "inventory_base_product";
        id: number;
        base_product_name: string;
        image_url: string;
        base_product_description: string;
      };
    }>;
    client_order_line_type: {
      __typename?: "inventory_client_order_line_type";
      line_type_name: string;
    };
    product_pricing?: Maybe<{
      __typename?: "inventory_product_pricing";
      unit_price: any;
      currency_code: string;
    }>;
    client_order: {
      __typename?: "inventory_client_order";
      account_id: number;
      location_id: number;
      destination: { __typename?: "inventory_location"; name: string };
      client_account: {
        __typename?: "inventory_account";
        international_name: string;
        local_name: string;
      };
    };
  }>;
};

export type GetClientsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientsQuery = {
  __typename?: "query_root";
  inventory_account: Array<{
    __typename?: "inventory_account";
    id: number;
    local_name: string;
    account_description: string;
    account_status: { __typename?: "inventory_account_status"; account_status_name: string };
    account_roles: Array<{
      __typename?: "inventory_account_role";
      role: { __typename?: "inventory_role"; role_name: string };
    }>;
  }>;
};

export type CourierServiceQueryVariables = Exact<{ [key: string]: never }>;

export type CourierServiceQuery = {
  __typename?: "query_root";
  inventory_courier_service: Array<{
    __typename?: "inventory_courier_service";
    id: number;
    name: string;
  }>;
};

export type GetFolderQueryVariables = Exact<{
  folder_id: Scalars["Int"];
}>;

export type GetFolderQuery = {
  __typename?: "query_root";
  inventory_folder_by_pk?: Maybe<{
    __typename?: "inventory_folder";
    id: number;
    user_id: string;
    attachment_folders: Array<{
      __typename?: "inventory_attachment_folder";
      folder_id: number;
      attachment: {
        __typename?: "inventory_attachment";
        id: number;
        attachment_uuid: string;
        notes: string;
        original_filename: string;
        last_updated: any;
      };
    }>;
  }>;
};

export type LocationsQueryVariables = Exact<{ [key: string]: never }>;

export type LocationsQuery = {
  __typename?: "query_root";
  inventory_location: Array<{ __typename?: "inventory_location"; id: number; name: string }>;
};

export type ProductStockQueryVariables = Exact<{
  product_id: Scalars["Int"];
  location_id?: Maybe<Scalars["Int"]>;
  statusFilter?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type ProductStockQuery = {
  __typename?: "query_root";
  inventory_product_stock: Array<{
    __typename?: "inventory_product_stock";
    id: number;
    initial_qty: number;
    qty: number;
    account_id: number;
    source_client_invoice_line_id?: Maybe<number>;
    source_production_order_line_id?: Maybe<number>;
    source_shipment_line_id?: Maybe<number>;
    stock_status: { __typename?: "inventory_stock_status"; id: number; stock_status_name: string };
    location: { __typename?: "inventory_location"; id: number; location_name: string };
    product: {
      __typename?: "inventory_product";
      id: number;
      base_product: {
        __typename?: "inventory_base_product";
        id: number;
        base_product_name: string;
      };
    };
    account: {
      __typename?: "inventory_account";
      id: number;
      local_name: string;
      international_name: string;
    };
  }>;
};

export type ProductsQueryVariables = Exact<{ [key: string]: never }>;

export type ProductsQuery = {
  __typename?: "query_root";
  inventory_product: Array<{
    __typename?: "inventory_product";
    id: number;
    case_qty: number;
    base_product: { __typename?: "inventory_base_product"; id: number; base_product_name: string };
  }>;
};

export type ShipmentQueryVariables = Exact<{
  shipment_id: Scalars["Int"];
}>;

export type ShipmentQuery = {
  __typename?: "query_root";
  inventory_shipment_by_pk?: Maybe<{
    __typename?: "inventory_shipment";
    id: number;
    source_location_id: number;
    destination_location_id: number;
    shipped_time?: Maybe<any>;
    estimated_shipped_time?: Maybe<any>;
    estimated_arrival_days: number;
    arrival_time?: Maybe<any>;
    source: {
      __typename?: "inventory_location";
      location_name: string;
      location?: Maybe<{ __typename?: "inventory_location"; id: number }>;
    };
    shipment_lines: Array<{
      __typename?: "inventory_shipment_line";
      id: number;
      reserved_qty: number;
      product_stocks: Array<{
        __typename?: "inventory_product_stock";
        id: number;
        qty: number;
        product_id: number;
        batch_id: number;
        location_id: number;
        initial_qty: number;
        account_id: number;
        stock_status_id: number;
        source_client_invoice_line_id?: Maybe<number>;
        source_shipment_line_id?: Maybe<number>;
        source_production_order_line_id?: Maybe<number>;
        user_id: string;
        stock_status: {
          __typename?: "inventory_stock_status";
          id: number;
          stock_status_name: string;
        };
      }>;
      source_product_stock: {
        __typename?: "inventory_product_stock";
        id: number;
        qty: number;
        product_id: number;
        batch_id: number;
        location_id: number;
        initial_qty: number;
        account_id: number;
        stock_status_id: number;
        source_client_invoice_line_id?: Maybe<number>;
        source_shipment_line_id?: Maybe<number>;
        source_production_order_line_id?: Maybe<number>;
        user_id: string;
      };
    }>;
    destination: {
      __typename?: "inventory_location";
      location_name: string;
      location?: Maybe<{ __typename?: "inventory_location"; id: number }>;
    };
    courier_service: {
      __typename?: "inventory_courier_service";
      id: number;
      courier_service_name: string;
    };
    shipment_status: {
      __typename?: "inventory_shipment_status";
      id: number;
      shipment_status_name: string;
    };
  }>;
};

export type ShipmentStatusQueryVariables = Exact<{ [key: string]: never }>;

export type ShipmentStatusQuery = {
  __typename?: "query_root";
  inventory_shipment_status: Array<{
    __typename?: "inventory_shipment_status";
    id: number;
    name: string;
  }>;
};

export type ShipmentsQueryVariables = Exact<{ [key: string]: never }>;

export type ShipmentsQuery = {
  __typename?: "query_root";
  inventory_shipment: Array<{
    __typename?: "inventory_shipment";
    arrival_time?: Maybe<any>;
    created_time: any;
    destination_location_id: number;
    estimated_arrival_days: number;
    id: number;
    last_updated: any;
    estimated_shipped_time?: Maybe<any>;
    shipped_time?: Maybe<any>;
    shipment_status_id: number;
    source_location_id: number;
    courier_service: { __typename?: "inventory_courier_service"; courier_service_name: string };
    source: { __typename?: "inventory_location"; location_name: string };
    destination: { __typename?: "inventory_location"; location_name: string };
    shipment_status: { __typename?: "inventory_shipment_status"; shipment_status_name: string };
    shipment_lines_aggregate: {
      __typename?: "inventory_shipment_line_aggregate";
      aggregate?: Maybe<{
        __typename?: "inventory_shipment_line_aggregate_fields";
        count?: Maybe<number>;
      }>;
    };
  }>;
};

export type ShipmentLinesByShipmentIdQueryVariables = Exact<{
  shipment_id: Scalars["Int"];
}>;

export type ShipmentLinesByShipmentIdQuery = {
  __typename?: "query_root";
  inventory_shipment_line: Array<{
    __typename?: "inventory_shipment_line";
    shipment_id?: Maybe<number>;
    reserved_qty: number;
    client_order_line_id: number;
    source_product_stock_id: number;
    id: number;
    shipment_line_status_id: number;
    client_order_line: {
      __typename?: "inventory_client_order_line";
      client_order: {
        __typename?: "inventory_client_order";
        client_account: {
          __typename?: "inventory_account";
          id: number;
          local_name: string;
          international_name: string;
        };
      };
    };
    product_stock: {
      __typename?: "inventory_product_stock";
      id: number;
      qty: number;
      account: {
        __typename?: "inventory_account";
        id: number;
        local_name: string;
        international_name: string;
      };
      product: {
        __typename?: "inventory_product";
        id: number;
        case_qty: number;
        weight_grams: number;
        length_mm: number;
        width_mm: number;
        height_mm: number;
        base_product: {
          __typename?: "inventory_base_product";
          base_product_name: string;
          image_url: string;
        };
      };
    };
    shipment_line_status: {
      __typename?: "inventory_shipment_line_status";
      shipment_line_status_name: string;
    };
  }>;
};

export type SupplierDetailQueryVariables = Exact<{
  supplier_id: Scalars["Int"];
}>;

export type SupplierDetailQuery = {
  __typename?: "query_root";
  inventory_account: Array<{
    __typename?: "inventory_account";
    id: number;
    local_name: string;
    account_description: string;
    website: string;
    logo_url: string;
    email: string;
    telephone_number: string;
    account_roles: Array<{
      __typename?: "inventory_account_role";
      role: { __typename?: "inventory_role"; role_name: string; id: number };
    }>;
    account_locations: Array<{
      __typename?: "inventory_location";
      id: number;
      parent_location_id?: Maybe<number>;
      name: string;
      child_locations: Array<{
        __typename?: "inventory_location";
        name: string;
        address: { __typename?: "inventory_address"; name: string };
        child_locations: Array<{
          __typename?: "inventory_location";
          name: string;
          address: { __typename?: "inventory_address"; name: string };
          child_locations: Array<{
            __typename?: "inventory_location";
            name: string;
            address: { __typename?: "inventory_address"; name: string };
          }>;
        }>;
      }>;
    }>;
  }>;
};

export type SuppliersQueryVariables = Exact<{ [key: string]: never }>;

export type SuppliersQuery = {
  __typename?: "query_root";
  inventory_account: Array<{
    __typename?: "inventory_account";
    id: number;
    local_name: string;
    account_description: string;
    account_status: { __typename?: "inventory_account_status"; account_status_name: string };
    account_roles: Array<{
      __typename?: "inventory_account_role";
      role: { __typename?: "inventory_role"; role_name: string };
    }>;
  }>;
};

export type GetCompatibleShipmentLinesQueryVariables = Exact<{
  source_location_id: Scalars["Int"];
  destination_location_id: Scalars["Int"];
}>;

export type GetCompatibleShipmentLinesQuery = {
  __typename?: "query_root";
  inventory_shipment_line: Array<{
    __typename?: "inventory_shipment_line";
    client_order_line_id: number;
    source_product_stock_id: number;
    id: number;
    shipment_line_status_id: number;
    product_stock: {
      __typename?: "inventory_product_stock";
      qty: number;
      product: {
        __typename?: "inventory_product";
        case_qty: number;
        weight_grams: number;
        length_mm: number;
        width_mm: number;
        height_mm: number;
        base_product: {
          __typename?: "inventory_base_product";
          base_product_name: string;
          image_url: string;
        };
      };
    };
    shipment_line_status: {
      __typename?: "inventory_shipment_line_status";
      shipment_line_status_name: string;
    };
  }>;
};

export const AddShipmentDocument = gql`
  mutation AddShipment(
    $courier_service_id: Int!
    $arrival_time: timestamptz
    $destination_location_id: Int = 10
    $estimated_arrival_days: Int = 10
    $shipped_time: timestamptz
    $estimated_shipped_time: timestamptz
    $source_location_id: Int = 10
    $user_id: String!
  ) {
    insert_inventory_shipment_one(
      object: {
        arrival_time: $arrival_time
        courier_service_id: $courier_service_id
        destination_location_id: $destination_location_id
        estimated_shipped_time: $estimated_shipped_time
        estimated_arrival_days: $estimated_arrival_days
        shipment_status_id: 1
        shipped_time: $shipped_time
        source_location_id: $source_location_id
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export type AddShipmentMutationFn = Apollo.MutationFunction<
  AddShipmentMutation,
  AddShipmentMutationVariables
>;

/**
 * __useAddShipmentMutation__
 *
 * To run a mutation, you first call `useAddShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShipmentMutation, { data, loading, error }] = useAddShipmentMutation({
 *   variables: {
 *      courier_service_id: // value for 'courier_service_id'
 *      arrival_time: // value for 'arrival_time'
 *      destination_location_id: // value for 'destination_location_id'
 *      estimated_arrival_days: // value for 'estimated_arrival_days'
 *      shipped_time: // value for 'shipped_time'
 *      estimated_shipped_time: // value for 'estimated_shipped_time'
 *      source_location_id: // value for 'source_location_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useAddShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddShipmentMutation, AddShipmentMutationVariables>
) {
  return Apollo.useMutation<AddShipmentMutation, AddShipmentMutationVariables>(
    AddShipmentDocument,
    baseOptions
  );
}
export type AddShipmentMutationHookResult = ReturnType<typeof useAddShipmentMutation>;
export type AddShipmentMutationResult = Apollo.MutationResult<AddShipmentMutation>;
export type AddShipmentMutationOptions = Apollo.BaseMutationOptions<
  AddShipmentMutation,
  AddShipmentMutationVariables
>;
export const AddShipmentLineDocument = gql`
  mutation AddShipmentLine(
    $client_order_line_id: Int!
    $product_id: Int!
    $source_location_id: Int!
    $destination_location_id: Int!
    $source_product_stock_id: Int!
    $updated_source_product_stock_qty: Int!
    $source_product_stock_account_id: Int!
    $reserved_qty: Int!
    $user_id: String!
    $shipment_id: Int
  ) {
    insert_inventory_shipment_line_one(
      object: {
        client_order_line_id: $client_order_line_id
        source_location_id: $source_location_id
        source_product_stock_id: $source_product_stock_id
        destination_location_id: $destination_location_id
        reserved_qty: $reserved_qty
        user_id: $user_id
        shipment_id: $shipment_id
        product_stocks: {
          data: [
            {
              product_id: $product_id
              stock_status_id: 3
              location_id: $source_location_id
              account_id: $source_product_stock_account_id
              initial_qty: $reserved_qty
              qty: $reserved_qty
              user_id: $user_id
              batch: {
                data: { supplier_account_id: $source_product_stock_account_id, user_id: $user_id }
              }
            }
          ]
        }
      }
    ) {
      id
      product_stocks {
        id
        batch {
          id
          supplier_account_id
          user_id
        }
        stock_status {
          stock_status_name
        }
      }
    }
    update_inventory_product_stock_by_pk(
      pk_columns: { id: $source_product_stock_id }
      _set: { qty: $updated_source_product_stock_qty, user_id: $user_id }
    ) {
      id
      stock_status {
        stock_status_name
      }
      qty
    }
  }
`;
export type AddShipmentLineMutationFn = Apollo.MutationFunction<
  AddShipmentLineMutation,
  AddShipmentLineMutationVariables
>;

/**
 * __useAddShipmentLineMutation__
 *
 * To run a mutation, you first call `useAddShipmentLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShipmentLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShipmentLineMutation, { data, loading, error }] = useAddShipmentLineMutation({
 *   variables: {
 *      client_order_line_id: // value for 'client_order_line_id'
 *      product_id: // value for 'product_id'
 *      source_location_id: // value for 'source_location_id'
 *      destination_location_id: // value for 'destination_location_id'
 *      source_product_stock_id: // value for 'source_product_stock_id'
 *      updated_source_product_stock_qty: // value for 'updated_source_product_stock_qty'
 *      source_product_stock_account_id: // value for 'source_product_stock_account_id'
 *      reserved_qty: // value for 'reserved_qty'
 *      user_id: // value for 'user_id'
 *      shipment_id: // value for 'shipment_id'
 *   },
 * });
 */
export function useAddShipmentLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddShipmentLineMutation,
    AddShipmentLineMutationVariables
  >
) {
  return Apollo.useMutation<AddShipmentLineMutation, AddShipmentLineMutationVariables>(
    AddShipmentLineDocument,
    baseOptions
  );
}
export type AddShipmentLineMutationHookResult = ReturnType<typeof useAddShipmentLineMutation>;
export type AddShipmentLineMutationResult = Apollo.MutationResult<AddShipmentLineMutation>;
export type AddShipmentLineMutationOptions = Apollo.BaseMutationOptions<
  AddShipmentLineMutation,
  AddShipmentLineMutationVariables
>;
export const CancelShipmentDocument = gql`
  mutation CancelShipment(
    $shipment_id: Int!
    $updated_product_stocks: [inventory_product_stock_insert_input!]!
  ) {
    update_inventory_shipment_by_pk(
      pk_columns: { id: $shipment_id }
      _set: { shipment_status_id: 4 }
    ) {
      id
      shipment_status {
        id
        shipment_status_name
      }
    }
    update_inventory_shipment_line(
      where: { shipment_id: { _eq: $shipment_id } }
      _set: { shipment_line_status_id: 4 }
    ) {
      returning {
        id
        shipment_line_status {
          id
          name: shipment_line_status_name
        }
      }
    }
    insert_inventory_product_stock(
      objects: $updated_product_stocks
      on_conflict: { constraint: product_stock_pkey, update_columns: [qty, user_id] }
    ) {
      returning {
        id
        qty
        initial_qty
        stock_status {
          id
          name: stock_status_name
        }
        location {
          id
          location_name
        }
      }
    }
  }
`;
export type CancelShipmentMutationFn = Apollo.MutationFunction<
  CancelShipmentMutation,
  CancelShipmentMutationVariables
>;

/**
 * __useCancelShipmentMutation__
 *
 * To run a mutation, you first call `useCancelShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelShipmentMutation, { data, loading, error }] = useCancelShipmentMutation({
 *   variables: {
 *      shipment_id: // value for 'shipment_id'
 *      updated_product_stocks: // value for 'updated_product_stocks'
 *   },
 * });
 */
export function useCancelShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelShipmentMutation, CancelShipmentMutationVariables>
) {
  return Apollo.useMutation<CancelShipmentMutation, CancelShipmentMutationVariables>(
    CancelShipmentDocument,
    baseOptions
  );
}
export type CancelShipmentMutationHookResult = ReturnType<typeof useCancelShipmentMutation>;
export type CancelShipmentMutationResult = Apollo.MutationResult<CancelShipmentMutation>;
export type CancelShipmentMutationOptions = Apollo.BaseMutationOptions<
  CancelShipmentMutation,
  CancelShipmentMutationVariables
>;
export const DeleteShipmentLineDocument = gql`
  mutation DeleteShipmentLine(
    $shipment_line_id: Int!
    $source_product_stock_id: Int!
    $updated_source_product_stock_qty: Int!
    $user_id: String!
  ) {
    update_inventory_shipment_line_by_pk(
      pk_columns: { id: $shipment_line_id }
      _set: { shipment_id: null, user_id: $user_id, shipment_line_status_id: 4 }
    ) {
      id
    }
    update_inventory_product_stock_by_pk(
      pk_columns: { id: $source_product_stock_id }
      _set: { qty: $updated_source_product_stock_qty, user_id: $user_id }
    ) {
      id
      stock_status {
        stock_status_name
      }
      qty
    }
    update_inventory_product_stock(
      where: { source_shipment_line_id: { _eq: $shipment_line_id } }
      _set: { qty: 0 }
    ) {
      affected_rows
      returning {
        id
        qty
      }
    }
  }
`;
export type DeleteShipmentLineMutationFn = Apollo.MutationFunction<
  DeleteShipmentLineMutation,
  DeleteShipmentLineMutationVariables
>;

/**
 * __useDeleteShipmentLineMutation__
 *
 * To run a mutation, you first call `useDeleteShipmentLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShipmentLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShipmentLineMutation, { data, loading, error }] = useDeleteShipmentLineMutation({
 *   variables: {
 *      shipment_line_id: // value for 'shipment_line_id'
 *      source_product_stock_id: // value for 'source_product_stock_id'
 *      updated_source_product_stock_qty: // value for 'updated_source_product_stock_qty'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteShipmentLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShipmentLineMutation,
    DeleteShipmentLineMutationVariables
  >
) {
  return Apollo.useMutation<DeleteShipmentLineMutation, DeleteShipmentLineMutationVariables>(
    DeleteShipmentLineDocument,
    baseOptions
  );
}
export type DeleteShipmentLineMutationHookResult = ReturnType<typeof useDeleteShipmentLineMutation>;
export type DeleteShipmentLineMutationResult = Apollo.MutationResult<DeleteShipmentLineMutation>;
export type DeleteShipmentLineMutationOptions = Apollo.BaseMutationOptions<
  DeleteShipmentLineMutation,
  DeleteShipmentLineMutationVariables
>;
export const UpdateShipmentDocument = gql`
  mutation UpdateShipment(
    $id: Int!
    $user_id: String!
    $destination_location_id: Int!
    $courier_service_id: Int!
    $estimated_arrival_days: Int!
    $arrival_time: timestamptz
    $estimated_shipped_time: timestamptz
    $shipped_time: timestamptz
    $shipment_status_id: Int!
    $shipment_line_status_id: Int!
    $updated_product_stock: [inventory_product_stock_insert_input!]!
  ) {
    update_inventory_shipment_by_pk(
      pk_columns: { id: $id }
      _set: {
        user_id: $user_id
        destination_location_id: $destination_location_id
        courier_service_id: $courier_service_id
        shipment_status_id: $shipment_status_id
        shipped_time: $shipped_time
        estimated_shipped_time: $estimated_shipped_time
        estimated_arrival_days: $estimated_arrival_days
        arrival_time: $arrival_time
      }
    ) {
      id
    }
    insert_inventory_product_stock(
      objects: $updated_product_stock
      on_conflict: { constraint: product_stock_pkey, update_columns: [qty, user_id] }
    ) {
      returning {
        id
        qty
        initial_qty
        stock_status {
          id
          name: stock_status_name
        }
        location {
          id
          location_name
        }
      }
    }
    update_inventory_shipment_line(
      where: { shipment_id: { _eq: $id } }
      _set: {
        shipment_line_status_id: $shipment_line_status_id
        destination_location_id: $destination_location_id
      }
    ) {
      returning {
        id
        shipment_line_status {
          id
          name: shipment_line_status_name
        }
      }
    }
  }
`;
export type UpdateShipmentMutationFn = Apollo.MutationFunction<
  UpdateShipmentMutation,
  UpdateShipmentMutationVariables
>;

/**
 * __useUpdateShipmentMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentMutation, { data, loading, error }] = useUpdateShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user_id: // value for 'user_id'
 *      destination_location_id: // value for 'destination_location_id'
 *      courier_service_id: // value for 'courier_service_id'
 *      estimated_arrival_days: // value for 'estimated_arrival_days'
 *      arrival_time: // value for 'arrival_time'
 *      estimated_shipped_time: // value for 'estimated_shipped_time'
 *      shipped_time: // value for 'shipped_time'
 *      shipment_status_id: // value for 'shipment_status_id'
 *      shipment_line_status_id: // value for 'shipment_line_status_id'
 *      updated_product_stock: // value for 'updated_product_stock'
 *   },
 * });
 */
export function useUpdateShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateShipmentMutation, UpdateShipmentMutationVariables>
) {
  return Apollo.useMutation<UpdateShipmentMutation, UpdateShipmentMutationVariables>(
    UpdateShipmentDocument,
    baseOptions
  );
}
export type UpdateShipmentMutationHookResult = ReturnType<typeof useUpdateShipmentMutation>;
export type UpdateShipmentMutationResult = Apollo.MutationResult<UpdateShipmentMutation>;
export type UpdateShipmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateShipmentMutation,
  UpdateShipmentMutationVariables
>;
export const ClientDetailDocument = gql`
  query ClientDetail($client_id: Int!) {
    inventory_account(where: { id: { _eq: $client_id } }) {
      id
      local_name
      account_description
      website
      logo_url
      email
      telephone_number
      account_sector {
        account_sector_name
      }
      account_roles {
        role {
          role_name
          id
        }
      }
      account_locations(where: { parent_location_id: { _is_null: true } }) {
        id
        name: location_name
        parent_location_id
        child_locations {
          name: location_name
          address {
            name
          }
          child_locations {
            name: location_name
            address {
              name
            }
            child_locations {
              name: location_name
              address {
                name
              }
            }
          }
        }
      }
    }
    referrals: inventory_account_referral(where: { referrer_account_id: { _eq: $client_id } }) {
      account {
        local_name
      }
    }
    referrers: inventory_account_referral(where: { account_id: { _eq: $client_id } }) {
      referrerAccountId {
        local_name
      }
    }
  }
`;

/**
 * __useClientDetailQuery__
 *
 * To run a query within a React component, call `useClientDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailQuery({
 *   variables: {
 *      client_id: // value for 'client_id'
 *   },
 * });
 */
export function useClientDetailQuery(
  baseOptions: Apollo.QueryHookOptions<ClientDetailQuery, ClientDetailQueryVariables>
) {
  return Apollo.useQuery<ClientDetailQuery, ClientDetailQueryVariables>(
    ClientDetailDocument,
    baseOptions
  );
}
export function useClientDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientDetailQuery, ClientDetailQueryVariables>
) {
  return Apollo.useLazyQuery<ClientDetailQuery, ClientDetailQueryVariables>(
    ClientDetailDocument,
    baseOptions
  );
}
export type ClientDetailQueryHookResult = ReturnType<typeof useClientDetailQuery>;
export type ClientDetailLazyQueryHookResult = ReturnType<typeof useClientDetailLazyQuery>;
export type ClientDetailQueryResult = Apollo.QueryResult<
  ClientDetailQuery,
  ClientDetailQueryVariables
>;
export const ClientOrderLinesDocument = gql`
  query ClientOrderLines(
    $destination_location_id: Int
    $source_location_id: Int
    $statusFilter: [String!]
    $minQty: Int
  ) {
    inventory_client_order_line(
      where: {
        client_order: { destination: { id: { _eq: $destination_location_id } } }
        product: {
          product_stocks: {
            location_id: { _eq: $source_location_id }
            qty: { _gte: $minQty }
            stock_status: { stock_status_name: { _eq: "available" } }
          }
        }
        client_order_line_status: { line_status_name: { _in: $statusFilter } }
      }
    ) {
      id
      client_order_line_type_id
      product {
        id
        base_product {
          id
          base_product_name
          image_url
          base_product_description
        }
        case_qty
      }
      client_order_line_type {
        line_type_name
      }
      requested_qty
      unit_price
      product_pricing_id
      product_pricing {
        unit_price
        currency_code
      }
      client_order_id
      outstanding_qty
      client_order {
        account_id
        location_id
        destination {
          name: location_name
        }
        client_account {
          international_name
          local_name
        }
      }
    }
  }
`;

/**
 * __useClientOrderLinesQuery__
 *
 * To run a query within a React component, call `useClientOrderLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientOrderLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientOrderLinesQuery({
 *   variables: {
 *      destination_location_id: // value for 'destination_location_id'
 *      source_location_id: // value for 'source_location_id'
 *      statusFilter: // value for 'statusFilter'
 *      minQty: // value for 'minQty'
 *   },
 * });
 */
export function useClientOrderLinesQuery(
  baseOptions?: Apollo.QueryHookOptions<ClientOrderLinesQuery, ClientOrderLinesQueryVariables>
) {
  return Apollo.useQuery<ClientOrderLinesQuery, ClientOrderLinesQueryVariables>(
    ClientOrderLinesDocument,
    baseOptions
  );
}
export function useClientOrderLinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientOrderLinesQuery, ClientOrderLinesQueryVariables>
) {
  return Apollo.useLazyQuery<ClientOrderLinesQuery, ClientOrderLinesQueryVariables>(
    ClientOrderLinesDocument,
    baseOptions
  );
}
export type ClientOrderLinesQueryHookResult = ReturnType<typeof useClientOrderLinesQuery>;
export type ClientOrderLinesLazyQueryHookResult = ReturnType<typeof useClientOrderLinesLazyQuery>;
export type ClientOrderLinesQueryResult = Apollo.QueryResult<
  ClientOrderLinesQuery,
  ClientOrderLinesQueryVariables
>;
export const GetClientsDocument = gql`
  query GetClients {
    inventory_account(where: { account_roles: { role: { id: { _eq: 3 } } } }) {
      id
      local_name
      account_description
      account_status {
        account_status_name
      }
      account_roles {
        role {
          role_name
        }
      }
    }
  }
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>
) {
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    baseOptions
  );
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>
) {
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    baseOptions
  );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const CourierServiceDocument = gql`
  query CourierService {
    inventory_courier_service {
      id
      name: courier_service_name
    }
  }
`;

/**
 * __useCourierServiceQuery__
 *
 * To run a query within a React component, call `useCourierServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourierServiceQuery(
  baseOptions?: Apollo.QueryHookOptions<CourierServiceQuery, CourierServiceQueryVariables>
) {
  return Apollo.useQuery<CourierServiceQuery, CourierServiceQueryVariables>(
    CourierServiceDocument,
    baseOptions
  );
}
export function useCourierServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourierServiceQuery, CourierServiceQueryVariables>
) {
  return Apollo.useLazyQuery<CourierServiceQuery, CourierServiceQueryVariables>(
    CourierServiceDocument,
    baseOptions
  );
}
export type CourierServiceQueryHookResult = ReturnType<typeof useCourierServiceQuery>;
export type CourierServiceLazyQueryHookResult = ReturnType<typeof useCourierServiceLazyQuery>;
export type CourierServiceQueryResult = Apollo.QueryResult<
  CourierServiceQuery,
  CourierServiceQueryVariables
>;
export const GetFolderDocument = gql`
  query GetFolder($folder_id: Int!) {
    inventory_folder_by_pk(id: $folder_id) {
      id
      user_id
      attachment_folders {
        folder_id
        attachment {
          id
          attachment_uuid
          notes
          original_filename
          last_updated
        }
      }
    }
  }
`;

/**
 * __useGetFolderQuery__
 *
 * To run a query within a React component, call `useGetFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolderQuery({
 *   variables: {
 *      folder_id: // value for 'folder_id'
 *   },
 * });
 */
export function useGetFolderQuery(
  baseOptions: Apollo.QueryHookOptions<GetFolderQuery, GetFolderQueryVariables>
) {
  return Apollo.useQuery<GetFolderQuery, GetFolderQueryVariables>(GetFolderDocument, baseOptions);
}
export function useGetFolderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFolderQuery, GetFolderQueryVariables>
) {
  return Apollo.useLazyQuery<GetFolderQuery, GetFolderQueryVariables>(
    GetFolderDocument,
    baseOptions
  );
}
export type GetFolderQueryHookResult = ReturnType<typeof useGetFolderQuery>;
export type GetFolderLazyQueryHookResult = ReturnType<typeof useGetFolderLazyQuery>;
export type GetFolderQueryResult = Apollo.QueryResult<GetFolderQuery, GetFolderQueryVariables>;
export const LocationsDocument = gql`
  query Locations {
    inventory_location {
      id
      name: location_name
    }
  }
`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>
) {
  return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, baseOptions);
}
export function useLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>
) {
  return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    baseOptions
  );
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const ProductStockDocument = gql`
  query ProductStock($product_id: Int!, $location_id: Int, $statusFilter: [String!]) {
    inventory_product_stock(
      where: {
        product_id: { _eq: $product_id }
        location_id: { _eq: $location_id }
        stock_status: { stock_status_name: { _in: $statusFilter } }
      }
    ) {
      id
      initial_qty
      qty
      stock_status {
        id
        stock_status_name
      }
      location {
        id
        location_name
      }
      product {
        id
        base_product {
          id
          base_product_name
        }
      }
      account_id
      account {
        id
        local_name
        international_name
      }
      source_client_invoice_line_id
      source_production_order_line_id
      source_shipment_line_id
    }
  }
`;

/**
 * __useProductStockQuery__
 *
 * To run a query within a React component, call `useProductStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductStockQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      location_id: // value for 'location_id'
 *      statusFilter: // value for 'statusFilter'
 *   },
 * });
 */
export function useProductStockQuery(
  baseOptions: Apollo.QueryHookOptions<ProductStockQuery, ProductStockQueryVariables>
) {
  return Apollo.useQuery<ProductStockQuery, ProductStockQueryVariables>(
    ProductStockDocument,
    baseOptions
  );
}
export function useProductStockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductStockQuery, ProductStockQueryVariables>
) {
  return Apollo.useLazyQuery<ProductStockQuery, ProductStockQueryVariables>(
    ProductStockDocument,
    baseOptions
  );
}
export type ProductStockQueryHookResult = ReturnType<typeof useProductStockQuery>;
export type ProductStockLazyQueryHookResult = ReturnType<typeof useProductStockLazyQuery>;
export type ProductStockQueryResult = Apollo.QueryResult<
  ProductStockQuery,
  ProductStockQueryVariables
>;
export const ProductsDocument = gql`
  query Products {
    inventory_product {
      base_product {
        id
        base_product_name
      }
      id
      case_qty
    }
  }
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, baseOptions);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ShipmentDocument = gql`
  query Shipment($shipment_id: Int!) {
    inventory_shipment_by_pk(id: $shipment_id) {
      id
      source_location_id
      destination_location_id
      source {
        location {
          id
        }
        location_name
      }
      shipment_lines {
        id
        reserved_qty
        product_stocks {
          id
          qty
          product_id
          batch_id
          location_id
          initial_qty
          account_id
          stock_status_id
          stock_status {
            id
            stock_status_name
          }
          source_client_invoice_line_id
          source_shipment_line_id
          source_production_order_line_id
          user_id
        }
        source_product_stock: product_stock {
          id
          qty
          product_id
          batch_id
          location_id
          initial_qty
          account_id
          stock_status_id
          source_client_invoice_line_id
          source_shipment_line_id
          source_production_order_line_id
          user_id
        }
      }
      source_location_id
      destination_location_id
      shipped_time
      estimated_shipped_time
      estimated_arrival_days
      arrival_time
      destination {
        location {
          id
        }
        location_name
      }
      courier_service {
        id
        courier_service_name
      }
      shipment_status {
        id
        shipment_status_name
      }
    }
  }
`;

/**
 * __useShipmentQuery__
 *
 * To run a query within a React component, call `useShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentQuery({
 *   variables: {
 *      shipment_id: // value for 'shipment_id'
 *   },
 * });
 */
export function useShipmentQuery(
  baseOptions: Apollo.QueryHookOptions<ShipmentQuery, ShipmentQueryVariables>
) {
  return Apollo.useQuery<ShipmentQuery, ShipmentQueryVariables>(ShipmentDocument, baseOptions);
}
export function useShipmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentQuery, ShipmentQueryVariables>
) {
  return Apollo.useLazyQuery<ShipmentQuery, ShipmentQueryVariables>(ShipmentDocument, baseOptions);
}
export type ShipmentQueryHookResult = ReturnType<typeof useShipmentQuery>;
export type ShipmentLazyQueryHookResult = ReturnType<typeof useShipmentLazyQuery>;
export type ShipmentQueryResult = Apollo.QueryResult<ShipmentQuery, ShipmentQueryVariables>;
export const ShipmentStatusDocument = gql`
  query ShipmentStatus {
    inventory_shipment_status {
      id
      name: shipment_status_name
    }
  }
`;

/**
 * __useShipmentStatusQuery__
 *
 * To run a query within a React component, call `useShipmentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipmentStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<ShipmentStatusQuery, ShipmentStatusQueryVariables>
) {
  return Apollo.useQuery<ShipmentStatusQuery, ShipmentStatusQueryVariables>(
    ShipmentStatusDocument,
    baseOptions
  );
}
export function useShipmentStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentStatusQuery, ShipmentStatusQueryVariables>
) {
  return Apollo.useLazyQuery<ShipmentStatusQuery, ShipmentStatusQueryVariables>(
    ShipmentStatusDocument,
    baseOptions
  );
}
export type ShipmentStatusQueryHookResult = ReturnType<typeof useShipmentStatusQuery>;
export type ShipmentStatusLazyQueryHookResult = ReturnType<typeof useShipmentStatusLazyQuery>;
export type ShipmentStatusQueryResult = Apollo.QueryResult<
  ShipmentStatusQuery,
  ShipmentStatusQueryVariables
>;
export const ShipmentsDocument = gql`
  query Shipments {
    inventory_shipment {
      arrival_time
      courier_service {
        courier_service_name
      }
      created_time
      destination_location_id
      estimated_arrival_days
      id
      last_updated
      estimated_shipped_time
      shipped_time
      shipment_status_id
      source_location_id
      source {
        location_name
      }
      destination {
        location_name
      }
      shipment_status {
        shipment_status_name
      }
      shipment_lines_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

/**
 * __useShipmentsQuery__
 *
 * To run a query within a React component, call `useShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ShipmentsQuery, ShipmentsQueryVariables>
) {
  return Apollo.useQuery<ShipmentsQuery, ShipmentsQueryVariables>(ShipmentsDocument, baseOptions);
}
export function useShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsQuery, ShipmentsQueryVariables>
) {
  return Apollo.useLazyQuery<ShipmentsQuery, ShipmentsQueryVariables>(
    ShipmentsDocument,
    baseOptions
  );
}
export type ShipmentsQueryHookResult = ReturnType<typeof useShipmentsQuery>;
export type ShipmentsLazyQueryHookResult = ReturnType<typeof useShipmentsLazyQuery>;
export type ShipmentsQueryResult = Apollo.QueryResult<ShipmentsQuery, ShipmentsQueryVariables>;
export const ShipmentLinesByShipmentIdDocument = gql`
  query ShipmentLinesByShipmentId($shipment_id: Int!) {
    inventory_shipment_line(where: { shipment_id: { _eq: $shipment_id } }) {
      shipment_id
      reserved_qty
      client_order_line_id
      client_order_line {
        client_order {
          client_account {
            id
            local_name
            international_name
          }
        }
      }
      source_product_stock_id
      product_stock {
        id
        account {
          id
          local_name
          international_name
        }
        product {
          id
          base_product {
            base_product_name
            image_url
          }
          case_qty
          weight_grams
          length_mm
          width_mm
          height_mm
        }
        qty
      }
      id
      shipment_line_status_id
      shipment_line_status {
        shipment_line_status_name
      }
    }
  }
`;

/**
 * __useShipmentLinesByShipmentIdQuery__
 *
 * To run a query within a React component, call `useShipmentLinesByShipmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentLinesByShipmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentLinesByShipmentIdQuery({
 *   variables: {
 *      shipment_id: // value for 'shipment_id'
 *   },
 * });
 */
export function useShipmentLinesByShipmentIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShipmentLinesByShipmentIdQuery,
    ShipmentLinesByShipmentIdQueryVariables
  >
) {
  return Apollo.useQuery<ShipmentLinesByShipmentIdQuery, ShipmentLinesByShipmentIdQueryVariables>(
    ShipmentLinesByShipmentIdDocument,
    baseOptions
  );
}
export function useShipmentLinesByShipmentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShipmentLinesByShipmentIdQuery,
    ShipmentLinesByShipmentIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ShipmentLinesByShipmentIdQuery,
    ShipmentLinesByShipmentIdQueryVariables
  >(ShipmentLinesByShipmentIdDocument, baseOptions);
}
export type ShipmentLinesByShipmentIdQueryHookResult = ReturnType<
  typeof useShipmentLinesByShipmentIdQuery
>;
export type ShipmentLinesByShipmentIdLazyQueryHookResult = ReturnType<
  typeof useShipmentLinesByShipmentIdLazyQuery
>;
export type ShipmentLinesByShipmentIdQueryResult = Apollo.QueryResult<
  ShipmentLinesByShipmentIdQuery,
  ShipmentLinesByShipmentIdQueryVariables
>;
export const SupplierDetailDocument = gql`
  query SupplierDetail($supplier_id: Int!) {
    inventory_account(where: { id: { _eq: $supplier_id } }) {
      id
      local_name
      account_description
      website
      logo_url
      email
      telephone_number
      account_roles {
        role {
          role_name
          id
        }
      }
      account_locations(where: { parent_location_id: { _is_null: true } }) {
        id
        name: location_name
        parent_location_id
        child_locations {
          name: location_name
          address {
            name
          }
          child_locations {
            name: location_name
            address {
              name
            }
            child_locations {
              name: location_name
              address {
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSupplierDetailQuery__
 *
 * To run a query within a React component, call `useSupplierDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierDetailQuery({
 *   variables: {
 *      supplier_id: // value for 'supplier_id'
 *   },
 * });
 */
export function useSupplierDetailQuery(
  baseOptions: Apollo.QueryHookOptions<SupplierDetailQuery, SupplierDetailQueryVariables>
) {
  return Apollo.useQuery<SupplierDetailQuery, SupplierDetailQueryVariables>(
    SupplierDetailDocument,
    baseOptions
  );
}
export function useSupplierDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SupplierDetailQuery, SupplierDetailQueryVariables>
) {
  return Apollo.useLazyQuery<SupplierDetailQuery, SupplierDetailQueryVariables>(
    SupplierDetailDocument,
    baseOptions
  );
}
export type SupplierDetailQueryHookResult = ReturnType<typeof useSupplierDetailQuery>;
export type SupplierDetailLazyQueryHookResult = ReturnType<typeof useSupplierDetailLazyQuery>;
export type SupplierDetailQueryResult = Apollo.QueryResult<
  SupplierDetailQuery,
  SupplierDetailQueryVariables
>;
export const SuppliersDocument = gql`
  query Suppliers {
    inventory_account(where: { account_roles: { role: { id: { _eq: 2 } } } }) {
      id
      local_name
      account_description
      account_status {
        account_status_name
      }
      account_roles {
        role {
          role_name
        }
      }
    }
  }
`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersQuery(
  baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>
) {
  return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, baseOptions);
}
export function useSuppliersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>
) {
  return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(
    SuppliersDocument,
    baseOptions
  );
}
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const GetCompatibleShipmentLinesDocument = gql`
  query GetCompatibleShipmentLines($source_location_id: Int!, $destination_location_id: Int!) {
    inventory_shipment_line(
      where: {
        shipment_id: { _is_null: true }
        source_location_id: { _eq: $source_location_id }
        destination_location_id: { _eq: $destination_location_id }
      }
    ) {
      client_order_line_id
      source_product_stock_id
      product_stock {
        product {
          base_product {
            base_product_name
            image_url
          }
          case_qty
          weight_grams
          length_mm
          width_mm
          height_mm
        }
        qty
      }
      id
      shipment_line_status_id
      shipment_line_status {
        shipment_line_status_name
      }
    }
  }
`;

/**
 * __useGetCompatibleShipmentLinesQuery__
 *
 * To run a query within a React component, call `useGetCompatibleShipmentLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompatibleShipmentLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompatibleShipmentLinesQuery({
 *   variables: {
 *      source_location_id: // value for 'source_location_id'
 *      destination_location_id: // value for 'destination_location_id'
 *   },
 * });
 */
export function useGetCompatibleShipmentLinesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompatibleShipmentLinesQuery,
    GetCompatibleShipmentLinesQueryVariables
  >
) {
  return Apollo.useQuery<GetCompatibleShipmentLinesQuery, GetCompatibleShipmentLinesQueryVariables>(
    GetCompatibleShipmentLinesDocument,
    baseOptions
  );
}
export function useGetCompatibleShipmentLinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompatibleShipmentLinesQuery,
    GetCompatibleShipmentLinesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCompatibleShipmentLinesQuery,
    GetCompatibleShipmentLinesQueryVariables
  >(GetCompatibleShipmentLinesDocument, baseOptions);
}
export type GetCompatibleShipmentLinesQueryHookResult = ReturnType<
  typeof useGetCompatibleShipmentLinesQuery
>;
export type GetCompatibleShipmentLinesLazyQueryHookResult = ReturnType<
  typeof useGetCompatibleShipmentLinesLazyQuery
>;
export type GetCompatibleShipmentLinesQueryResult = Apollo.QueryResult<
  GetCompatibleShipmentLinesQuery,
  GetCompatibleShipmentLinesQueryVariables
>;
