import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import SimpleSelect from "./SimpleSelect";

const ManageClientReferral = ({ change_handler, id_ref }) => {
  const CLIENTS = gql`
    query GetClients {
      inventory_account(where: { account_roles: { role: { id: { _eq: 3 } } } }) {
        id
        local_name
        account_description
        account_status {
          account_status_name
        }
        account_roles {
          role {
            role_name
          }
        }
      }
    }
  `;

  const SUPPLIERS = gql`
    query GetSuppliers {
      inventory_account(where: { account_roles: { role: { id: { _eq: 2 } } } }) {
        id
        local_name
        account_description
        account_status {
          account_status_name
        }
        account_roles {
          role {
            role_name
          }
        }
      }
    }
  `;

  const ADMINS = gql`
    query GetAdmins {
      inventory_account(where: { account_roles: { role: { id: { _eq: 1 } } } }) {
        id
        local_name
        account_description
        account_status {
          account_status_name
        }
        account_roles {
          role {
            role_name
          }
        }
      }
    }
  `;

  const PARTNERS = gql`
    query GetPartners {
      inventory_account(where: { account_roles: { role: { id: { _eq: 4 } } } }) {
        id
        local_name
        account_description
        account_status {
          account_status_name
        }
        account_roles {
          role {
            role_name
          }
        }
      }
    }
  `;

  const INVESTORS = gql`
    query GetPartners {
      inventory_account(where: { account_roles: { role: { id: { _eq: 5 } } } }) {
        id
        local_name
        account_description
        account_status {
          account_status_name
        }
        account_roles {
          role {
            role_name
          }
        }
      }
    }
  `;

  const REFERRAL = [
    { id: 1, name: "Referred by Client" },
    { id: 2, name: "Referred by Supplier" },
    { id: 3, name: "Referred by Partner" },
    { id: 4, name: "Referred by Investor" },
    { id: 5, name: "Referred by Team" },
  ];

  const { loading: loadingClients, data: dataClients } = useQuery(CLIENTS, {
    fetchPolicy: "network-only",
  });

  const { loading: loadingSuppliers, data: dataSuppliers } = useQuery(SUPPLIERS, {
    fetchPolicy: "network-only",
  });

  const { loading: loadingAdmins, data: dataAdmins } = useQuery(ADMINS, {
    fetchPolicy: "network-only",
  });

  const { loading: loadingPartners, data: dataPartners } = useQuery(PARTNERS, {
    fetchPolicy: "network-only",
  });

  const { loading: loadingInvestors, data: dataInvestors } = useQuery(INVESTORS, {
    fetchPolicy: "network-only",
  });

  const REFERRER_ACCOUNT = {
    1: dataClients ? dataClients.inventory_account : [],
    2: dataSuppliers ? dataSuppliers.inventory_account : [],
    3: dataPartners ? dataPartners.inventory_account : [],
    4: dataInvestors ? dataInvestors.inventory_account : [],
    5: dataAdmins ? dataAdmins.inventory_account : [],
  };

  const [inputs, setInputs] = useState([{}]);

  const handleInputChange = idx => event => {
    event.persist();

    const clone = [...inputs];
    clone[idx][event.target.name] = event.target.value;
    setInputs(clone);
    change_handler({
      target: {
        name: id_ref,
        value: inputs,
      },
    });
  };

  const addReferral = () => {
    setInputs([...inputs, {}]);
  };

  return (
    <>
      <div className="flex w-full mb-4" onClick={addReferral}>
        <div
          className="flex w-full bg-gray-100 border-t border-b border-gray-500 text-gray-700 px-3 py-3"
          role="alert"
        >
          <p>
            You can add multiple referrals from here:
            <i className="px-3 far fa-plus-square fa-lg"></i>
          </p>
        </div>
      </div>

      {inputs.map((input, idx) => (
        <div className="flex w-full">
          <div className="flex-1 pr-3">
            <SimpleSelect
              placeholder="How did you find the client?"
              options={REFERRAL}
              change_handler={handleInputChange(idx)}
              label="Acquisition Channel"
              id_ref="acquisition_channel"
              name_func={option => {
                return option.name;
              }}
            />
          </div>
          <div className="flex-1 pl-3">
            <SimpleSelect
              placeholder="Select Referrer Account"
              options={REFERRER_ACCOUNT[inputs[idx]["acquisition_channel"]]}
              change_handler={handleInputChange(idx)}
              label="Referrer Account"
              id_ref="account_id"
              name_func={option => {
                return option.local_name;
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default ManageClientReferral;
