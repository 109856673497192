import React from "react";

interface MutationSuccessProps {
  header?: string;
  message: string;
}

const MutationSuccess = ({ header = "Success", message }: MutationSuccessProps) => (
  <div
    className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
    role="alert"
  >
    <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
      {header}
    </span>
    <span className="font-semibold mr-2 text-left flex-auto">{message}</span>
  </div>
);

export default MutationSuccess;
