import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import TitleHeading from "../../components/TitleHeading";
import { Link } from "react-router-dom";
import StatusBadge from "../../components/StatusBadge";
import HoverCell from "../../components/forms/HoverCell";
import SearchBar from "../../components/search/SearchBar";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";

const CLIENTS = gql`
  query GetClientOrders {
    inventory_client_order {
      id
      last_updated
      client_order_reference
      client_account {
        local_name
        id
      }
      destination {
        name: location_name
        id
      }
      client_order_lines_aggregate {
        aggregate {
          count
        }
      }
      client_order_type {
        order_type_name
      }
      client_order_status {
        order_status_name
      }
    }
  }
`;

const ClientOrders = () => {
  const { loading, error, data } = useQuery(CLIENTS, {
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateClientOrder = client_order_id => event => {
    event.preventDefault();
    openInNewTab(`client-orders/edit/${client_order_id}`);
  };

  if (data == null || loading) return null;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="Sales"
            selectedSubMenu="all-client-orders"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="Sales"
            selectedSubMenu="all-client-orders"
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Client Orders" />
                  <SimpleLinkButton
                    label="Add new Client Order"
                    keyboard_shortcut="n"
                    new_tab
                    url="/client-orders/add"
                    id_ref="add_client_order"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>id</TH>
                        <TH>order reference</TH>
                        <TH>client</TH>
                        <TH>destination</TH>
                        <TH>lines</TH>
                        <TH>type</TH>
                        <TH>status</TH>
                        <TH />
                      </TableHead>

                      <tbody>
                        {data.inventory_client_order.map(inventory_client_order => {
                          return (
                            <TR key={inventory_client_order.id}>
                              <TD className="font-semibold text-blue-700">
                                <Link to={`/client-orders/edit/${inventory_client_order.id}`}>
                                  {inventory_client_order.id}
                                </Link>
                              </TD>
                              <TD className="font-semibold text-blue-700">
                                {inventory_client_order.client_order_reference}
                              </TD>
                              <TD width={2}>
                                <HoverCell
                                  view_url={`/client/${inventory_client_order.client_account.id}`}
                                  edit_url={`/client/edit/${inventory_client_order.client_account.id}`}
                                >
                                  <h2 className="font-semibold text-blue-700 product-result-truncate">
                                    <Link
                                      to={`/client/${inventory_client_order.client_account.id}`}
                                    >
                                      {inventory_client_order.client_account.local_name}
                                    </Link>
                                  </h2>
                                </HoverCell>
                              </TD>
                              <TD width={4}>
                                <h2 className="font-semibold text-blue-700 product-result-truncate">
                                  <Link to={`/location/${inventory_client_order.destination.id}`}>
                                    {inventory_client_order.destination.name}{" "}
                                  </Link>
                                </h2>
                              </TD>
                              <TD width={2}>
                                <h2 className="font-semibold text-blue-700 product-result-truncate">
                                  {
                                    inventory_client_order.client_order_lines_aggregate.aggregate
                                      .count
                                  }
                                </h2>
                              </TD>
                              <TD width={2}>
                                <h2 className="font-semibold text-blue-700 product-result-truncate">
                                  {inventory_client_order.client_order_type.order_type_name}
                                </h2>
                              </TD>
                              <TD width={3}>
                                <h2 className="font-semibold text-blue-700 product-result-truncate">
                                  <StatusBadge
                                    label={
                                      inventory_client_order.client_order_status.order_status_name
                                    }
                                  />
                                </h2>
                              </TD>
                              <TD>
                                <button
                                  className="float-right"
                                  onClick={updateClientOrder(inventory_client_order.id)}
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientOrders;
