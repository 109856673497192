import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import TitleHeading from "../../components/TitleHeading";
import { Link } from "react-router-dom";
import StatusBadge from "../../components/StatusBadge";
import SearchBar from "../../components/search/SearchBar";

const CLIENTS = gql`
  query GetLocations {
    inventory_location(where: { parent_location_id: { _is_null: true } }) {
      id
      parent_location_id
      user_id
      can_hold_stock
      is_external
      location_name
      user_id
      address {
        id
        user_id
        country_code
        address_line_1
        address_line_2
        address_line_3
        address_description
        account_id
        last_updated
      }
      child_locations {
        id
        parent_location_id
        user_id
        can_hold_stock
        is_external
        location_name
        user_id
        address {
          id
          user_id
          country_code
          address_line_1
          address_line_2
          address_line_3
          address_description
          account_id
          last_updated
        }
        child_locations {
          id
          parent_location_id
          user_id
          can_hold_stock
          is_external
          location_name
          user_id
          address {
            id
            user_id
            country_code
            address_line_1
            address_line_2
            address_line_3
            address_description
            account_id
            last_updated
          }
          child_locations {
            id
            parent_location_id
            user_id
            can_hold_stock
            is_external
            location_name
            user_id
            address {
              id
              user_id
              country_code
              address_line_1
              address_line_2
              address_line_3
              address_description
              account_id
              last_updated
            }
          }
        }
      }
    }
  }
`;

const Locations = () => {
  const { loading: loadingLocations, error: errorLocations, data: dataLocations } = useQuery(
    CLIENTS,
    {
      fetchPolicy: "network-only",
    }
  );

  if (dataLocations == null || loadingLocations) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Locations"
          selectedSubMenu="all-locations"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Locations"
          selectedSubMenu="all-locations"
        />
      </div>
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Locations" />
                  <SimpleLinkButton
                    label="Add Location"
                    keyboard_shortcut="n"
                    new_tab
                    url="/locations/add"
                    id_ref="add_location"
                  />
                </div>

                <div className="h-full">
                  <div className="flex flex-row flex-wrap py-2">
                    {dataLocations.inventory_location.map(location => {
                      return (
                        <div
                          key={location.id}
                          className={`flex w-full border mx-2 mb-2 rounded flex-col bg-white`}
                        >
                          <div className="w-full p-4 flex flex-col">
                            <div className="w-full flex flex-col">
                              <div className="w-full">
                                <h2 className="mt-4 mb-2 font-semibold text-blue-700 product-result-truncate">
                                  <i class={`lni  mr-2`}></i>
                                  {location.location_name.split("/").pop().trim()}
                                  {/* <i className="fas fa-trash-alt mx-2"></i> */}
                                </h2>
                              </div>
                            </div>
                          </div>
                          {location.child_locations &&
                            location.child_locations.map(child_location => {
                              return (
                                <div>
                                  <div className="w-full flex flex-col">
                                    <div className="w-full border-t flex sm:flex-row flex-col">
                                      <div className="ml-16 w-full">
                                        <h2 className="mt-4 mb-2 font-semibold text-blue-700 product-result-truncate">
                                          {child_location.location_name.split("/").pop().trim()}
                                          {/* <i className="fas fa-trash-alt mx-2"></i> */}
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  {child_location.child_locations &&
                                    child_location.child_locations.map(child_child_location => {
                                      return (
                                        <div className="w-full flex flex-col">
                                          <div className="w-full border-t flex sm:flex-row flex-col">
                                            <div className="ml-20 w-full">
                                              <h2 className="mt-4 mb-2 font-semibold text-blue-700 product-result-truncate">
                                                {child_child_location.location_name
                                                  .split("/")
                                                  .pop()
                                                  .trim()}
                                                {/* <i className="fas fa-trash-alt mx-2"></i> */}
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
