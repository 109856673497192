import React from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { useQuery, gql } from "@apollo/client";
import fileDownload from "js-file-download";
import objToQueryString from "../../styles/objToQueryString";
import SimpleAccordion from "../../components/SimpleAccordion";

const TH = ({ children, ...props }) => (
  <th className="font-normal p-2 m-0 w-1/5 text-sm" {...props}>
    {children}
  </th>
);

const TD = ({ children, ...props }) => (
  <td className="font-normal p-2 text-blue-700  w-1/5 text-sm" {...props}>
    {children}
  </td>
);

const GET_FOLDER = gql`
  query GetFolder($folder_id: Int!) {
    inventory_folder_by_pk(id: $folder_id) {
      id
      user_id
      attachment_folders {
        folder_id
        attachment {
          id
          attachment_uuid
          notes
          original_filename
          last_updated
        }
      }
    }
  }
`;

const AttachmentPreview = ({ folder_id }) => {
  const { getTokenSilently } = useAuth0();

  const { loading: loading_folder, data: data_folder } = useQuery(GET_FOLDER, {
    fetchPolicy: "network-only",
    variables: {
      folder_id: folder_id,
    },
  });

  const downloadAttachment = async (uuid_name, original_filename) => {
    const token = await getTokenSilently();

    const queryString = objToQueryString({
      filename: original_filename,
      uuid_name: uuid_name,
    });

    const path = "gcs-file-uploader/download";

    const response = await fetch(
      process.env.REACT_APP_API_ORIGIN
        ? `${process.env.REACT_APP_API_ORIGIN}/${path}?${queryString}`
        : `${window.REACT_APP_API_ORIGIN}/${path}?${queryString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        mode: "cors",
      }
    );

    const blob = await response.blob();
    fileDownload(blob, original_filename);
  };

  if (data_folder == null || loading_folder) return null;

  const hasAttachments = data_folder.inventory_folder_by_pk.attachment_folders.length > 0;

  return (
    <SimpleAccordion
      renderLabel={() => (
        <h3 className="font-semibold mr-2 pl-1 text-gray-800">
          Attachments{" "}
          <span className="text-sm">
            ({data_folder.inventory_folder_by_pk.attachment_folders.length})
          </span>
        </h3>
      )}
    >
      {hasAttachments ? (
        <table id="table_product_pricings" className="w-full text-sm text-left">
          <thead>
            <tr className="text-gray-800 uppercase">
              <TH>attachment name</TH>
              <TH>attachment id</TH>
              <TH>last updated</TH>
            </tr>
          </thead>
          <tbody>
            {data_folder.inventory_folder_by_pk.attachment_folders.map(attachment_folders => {
              return (
                <tr
                  className={`p-4 mb-2 border-t rounded bg-white`}
                  key={attachment_folders.folder_id}
                >
                  <TD>
                    <h2 className="font-semibold product-result-truncate">
                      {attachment_folders.attachment.original_filename}
                    </h2>
                  </TD>
                  <TD>
                    <h2 className="font-semibold product-result-truncate">
                      {attachment_folders.attachment.attachment_uuid}
                    </h2>
                  </TD>
                  <TD>
                    <h2 className="font-semibold product-result-truncate">
                      {attachment_folders.attachment.last_updated}
                      <button
                        className="float-right"
                        onClick={() =>
                          downloadAttachment(
                            attachment_folders.attachment.attachment_uuid,
                            attachment_folders.attachment.original_filename
                          )
                        }
                      >
                        <i class="fas fa-download"></i>
                      </button>{" "}
                    </h2>
                  </TD>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="p-2 text-sm">No Attachments</div>
      )}
    </SimpleAccordion>
  );
};

export default AttachmentPreview;
