import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import SideMenu from "../../../components/SideMenu";
import { useParams } from "react-router-dom";
import TitleHeading from "../../../components/TitleHeading";
import QtySupplierQuoteSelector from "../../../components/forms/QtySupplierQuoteSelector";
import { useHotkeys } from "react-hotkeys-hook";
import EditProductionOrderForm from "../../../components/forms/EditProductionOrderForm";
import SearchBar from "../../../components/search/SearchBar";
import Attachment from "../../attachments/Attachment";
import Table, { TableHead, TD, TH, TR } from "../../../components/Table";

const DELETE_PRODUCTION_ORDER_LINE = gql`
  mutation AddProductionOrder($line_id: Int!) {
    delete_inventory_production_order_line_by_pk(id: $line_id) {
      id
    }
  }
`;

const PRODUCTION_ORDER = gql`
  query GetProductionOrder($production_order_id: Int!) {
    inventory_production_order_by_pk(id: $production_order_id) {
      id
      folder {
        id
        user_id
      }
    }
  }
`;

const PRODUCTION_ORDER_LINES = gql`
  query GetProductionOrderLines($production_order_id: Int!) {
    inventory_production_order_line(where: { production_order_id: { _eq: $production_order_id } }) {
      id
      supplier_quote {
        supplier_product {
          product {
            base_product {
              base_product_name
              image_url
            }
            case_qty
          }
        }
      }
      requested_qty
      unit_cost
      supplier_quote_id
      supplier_quote {
        unit_cost
        currency_code
      }
    }
  }
`;

const SUPPLIER_QUOTES = gql`
  query SupplierQuotes {
    inventory_supplier_quote {
      id
      currency_code
      unit_cost
      min_qty
      supplier_product {
        product {
          id
          case_qty
          base_product {
            base_product_name
            image_url
          }
        }
      }
    }
  }
`;

const SUPPLIERS = gql`
  query GetSuppliers {
    inventory_account(where: { account_roles: { role: { id: { _eq: 2 } } } }) {
      id
      name: local_name
    }
  }
`;

const LOCATIONS = gql`
  query GetLocations {
    inventory_location {
      id
      name: location_name
    }
  }
`;

const AddProductionOrderLines = () => {
  const [inputs, setInputs] = useState({});
  const [showQuotes, setShowQuotes] = useState(false);
  const [successCode, setSuccessCode] = useState(0);
  const { order_id } = useParams();

  const {
    loading: loading_supplier_quotes,
    error: error_supplier_quotes,
    data: data_supplier_quotes,
    refetch: refreshSupplierQuotes,
  } = useQuery(SUPPLIER_QUOTES, {
    fetchPolicy: "network-only",
  });

  const [
    deleteProductionOrderLine,
    {
      loading: mutationLoadingDeleteProductionOrderLine,
      error: mutationErrorDeleteProductionOrderLine,
    },
  ] = useMutation(DELETE_PRODUCTION_ORDER_LINE, {
    onCompleted() {
      refetch_lines();
    },
  });

  const handleDelete = (event, line_id) => {
    if (event) {
      event.preventDefault();
    }
    deleteProductionOrderLine({
      variables: {
        line_id: line_id,
      },
    });
  };

  useHotkeys("n", (e, h) => {
    console.log(e);
    if (e.target.localName !== "input") {
      setShowQuotes(true);
      window.location = "#table_supplier_quotes";
    }
  });

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateSupplierQuote = supplier_quote_id => event => {
    event.preventDefault();
    openInNewTab(`/supplier-quotes/edit/${supplier_quote_id}`);
  };

  const {
    loading_lines,
    error_lines,
    data: production_order_lines,
    refetch: refetch_lines,
  } = useQuery(PRODUCTION_ORDER_LINES, {
    variables: {
      production_order_id: order_id,
    },
    fetchPolicy: "network-only",
  });

  const {
    loading_production_order,
    error_production_order,
    data: data_production_order,
    refetch: refetch_production_order,
  } = useQuery(PRODUCTION_ORDER, {
    variables: {
      production_order_id: order_id,
    },
    fetchPolicy: "network-only",
  });

  const { loading, error, data: suppliers } = useQuery(SUPPLIERS, {
    fetchPolicy: "network-only",
  });

  const { loading_locations, error_locations, data: locations } = useQuery(LOCATIONS, {
    fetchPolicy: "network-only",
  });

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const resetForm = event => {
    if (event) {
      event.preventDefault();
    }
    setInputs({
      name: "",
    });
    setSuccessCode(0);
  };

  if (
    suppliers == null ||
    data_supplier_quotes == null ||
    locations == null ||
    loading ||
    loading_locations
  )
    return null;

  if (data_production_order == null || loading_production_order) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Purchases"
          selectedSubMenu="add-supplier-order"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Purchases"
          selectedSubMenu="add-supplier-order"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label={"Supplier Order #" + order_id} />

                  {successCode === 200 && (
                    <div
                      className="p-2 bg-green-800 items-center float-right -mt-12 text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Success
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        The Supplier Order was updated successfully.
                      </span>
                    </div>
                  )}
                  {mutationErrorDeleteProductionOrderLine && (
                    <div
                      className="p-2 bg-red-800 items-center float-right -mt-12 text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Error
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        There was a problem with the data - please check.
                      </span>
                    </div>
                  )}
                </div>

                <EditProductionOrderForm order_id={order_id} setSuccessCode={setSuccessCode} />

                <form className="w-full flex flex-col lg:flex-row">
                  <div className="w-full flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>Line ID</TH>
                        <TH>product</TH>
                        <TH>case qty</TH>
                        <TH>qty</TH>
                        <TH>total unit qty</TH>
                        <TH>total price</TH>
                        <TH></TH>
                      </TableHead>

                      <tbody>
                        {production_order_lines &&
                          production_order_lines.inventory_production_order_line.map(
                            inventory_production_order_line => {
                              return (
                                <TR key={inventory_production_order_line.id}>
                                  <TD>{inventory_production_order_line.id}</TD>
                                  <TD width={3}>
                                    <h3 className="inline font-semibold text-blue-700 product-result-truncate">
                                      <img
                                        alt=""
                                        className="float-left mr-4 object-cover w-10 h-10 "
                                        src={
                                          inventory_production_order_line.supplier_quote
                                            .supplier_product.product.base_product.image_url
                                        }
                                      />
                                      {
                                        inventory_production_order_line.supplier_quote
                                          .supplier_product.product.base_product.base_product_name
                                      }
                                    </h3>
                                  </TD>
                                  <TD>
                                    <h2 className="font-semibold text-blue-700 product-result-truncate">
                                      {inventory_production_order_line.supplier_quote.supplier_product.product.case_qty.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </h2>
                                  </TD>
                                  <TD>
                                    <h2 className="font-semibold text-blue-700 product-result-truncate">
                                      {inventory_production_order_line.requested_qty.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </h2>
                                  </TD>
                                  <TD width={2}>
                                    <h2 className="font-semibold text-blue-700 product-result-truncate">
                                      {(
                                        inventory_production_order_line.supplier_quote
                                          .supplier_product.product.case_qty *
                                        inventory_production_order_line.requested_qty
                                      ).toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                      })}
                                    </h2>
                                  </TD>
                                  <TD width={3} isEmphasised>
                                    {inventory_production_order_line.supplier_quote.currency_code}{" "}
                                    {(
                                      inventory_production_order_line.supplier_quote
                                        .supplier_product.product.case_qty *
                                      inventory_production_order_line.requested_qty *
                                      inventory_production_order_line.unit_cost
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 2,
                                    })}
                                  </TD>
                                  <TD isFullWidth>
                                    <button
                                      className="flex-shrink-0 bg-red-500 hover:bg-red-700 border-red-500 hover:border-red-700 text-sm border-4 text-white py-1 px-2 rounded"
                                      type="button"
                                      onClick={event =>
                                        handleDelete(event, inventory_production_order_line.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </TD>
                                </TR>
                              );
                            }
                          )}
                        <TR>
                          <TD colSpan={7} isFullWidth>
                            <button
                              className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                              type="button"
                              onClick={() => {
                                setShowQuotes(true);
                                window.location = "#table_supplier_quotes";
                              }}
                            >
                              Add a new Line item{" "}
                              <span className="p-1 pt-0 ml-1 rounded-full bg-teal-700">n</span>
                            </button>
                          </TD>
                        </TR>
                      </tbody>
                    </Table>
                  </div>
                </form>

                {showQuotes && (
                  <>
                    <h2 className="text-2xl mt-8  xl:text-2xl mb-4 text-gray-900 font-bold">
                      Add from a Supplier Quote{" "}
                      <span className="text-sm">
                        <button
                          onClick={event => {
                            event.preventDefault();
                            refreshSupplierQuotes();
                          }}
                        >
                          <i className="fas fa-sync-alt mx-2"></i>
                        </button>{" "}
                        <a target="_blank" rel="noopener noreferrer" href="/supplier-quotes/add">
                          <i className="fas fa-plus-circle"></i>
                        </a>
                      </span>
                    </h2>

                    <form className="w-full flex flex-col lg:flex-row">
                      <div className="w-full flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                        <Table id="table_supplier_quotes">
                          <TableHead>
                            <TH>quote id</TH>
                            <TH>product image</TH>
                            <TH>product name</TH>
                            <TH>case qty</TH>
                            <TH>min cases</TH>
                            <TH>quote price</TH>
                            <TH># cases</TH>
                            <TH>unit price</TH>
                            <TH></TH>
                          </TableHead>

                          <tbody>
                            {data_supplier_quotes.inventory_supplier_quote.map(
                              inventory_supplier_quote => {
                                return (
                                  <TR key="inventory_account.id">
                                    <TD>
                                      {inventory_supplier_quote.id}
                                      <button
                                        className="ml-2"
                                        onClick={updateSupplierQuote(inventory_supplier_quote.id)}
                                      >
                                        <i class="fas fa-edit"></i>
                                      </button>{" "}
                                    </TD>
                                    <TD>
                                      <img
                                        alt=""
                                        className="object-cover w-10 h-10 "
                                        src={
                                          inventory_supplier_quote.supplier_product.product
                                            .base_product.image_url
                                        }
                                      />
                                    </TD>
                                    <TD className="w-3/12  p-4">
                                      <h2 className="font-semibold product-result-truncate">
                                        {
                                          inventory_supplier_quote.supplier_product.product
                                            .base_product.base_product_name
                                        }
                                      </h2>
                                    </TD>
                                    <TD>
                                      <h2 className="font-semibold  product-result-truncate">
                                        {inventory_supplier_quote.supplier_product.product.case_qty.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </h2>
                                    </TD>
                                    <TD>
                                      <h2 className="font-semibold product-result-truncate">
                                        {inventory_supplier_quote.min_qty.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </h2>
                                    </TD>
                                    <TD>
                                      <h2 className="font-semibold product-result-truncate">
                                        {inventory_supplier_quote.currency_code}{" "}
                                        {inventory_supplier_quote.unit_cost.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </h2>
                                    </TD>

                                    <QtySupplierQuoteSelector
                                      unit_cost={inventory_supplier_quote.unit_cost}
                                      hide_callback={setShowQuotes}
                                      refresh_callback={refetch_lines}
                                      supplier_product_id={
                                        inventory_supplier_quote.supplier_product.id
                                      }
                                      order_id={order_id}
                                      quote_id={inventory_supplier_quote.id}
                                      minQty={inventory_supplier_quote.min_qty}
                                      maxQty={10000000000000}
                                    />
                                  </TR>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </form>
                  </>
                )}
                <Attachment
                  folder_id={data_production_order.inventory_production_order_by_pk.folder.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductionOrderLines;
