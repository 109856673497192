import React from "react";
import Select, { OptionProps, ValueType } from "react-select";
import { ProductStockQuery } from "../../generated/graphql";
import { ProductStockQueryItem } from "../../graphql/queries/productStock";
import { SelectItem } from "../../types/SelectItem";
import { customReactSelectStyle } from "../../utils/customReactSelectStyle";
import ProductStockSummary from "./ProductStockSummary";

const CustomOption = ({
  innerProps,
  isDisabled,
  data,
  isFocused,
}: OptionProps<SelectItem<ProductStockQueryItem>, false>) => {
  const optionData: SelectItem<ProductStockQueryItem> = data;
  const { data: stock_record } = optionData;
  if (isDisabled || !stock_record) return null;

  return (
    <ProductStockSummary
      innerProps={innerProps}
      stock_record={stock_record}
      isFocused={isFocused}
    />
  );
};

interface ProductStockSelectorProps {
  isLabelHidden?: boolean;
  label?: string;
  product_stock: ProductStockQuery["inventory_product_stock"];
  handleSelect: (value: ValueType<SelectItem<ProductStockQueryItem>, false>) => void;
  value?: SelectItem<ProductStockQueryItem> | null;
  noOptionsMessage?: string;
  customOption?: ({
    innerProps,
    isDisabled,
    data,
    isFocused,
  }: OptionProps<SelectItem<ProductStockQueryItem>, false>) => JSX.Element | null;
}

const ProductStockSelector = ({
  isLabelHidden,
  label,
  product_stock,
  handleSelect,
  value,
  noOptionsMessage = "No options",
  customOption,
}: ProductStockSelectorProps) => {
  const options: SelectItem<ProductStockQueryItem>[] = product_stock.map(stock_record => {
    return {
      value: stock_record.id,
      label: stock_record.account.local_name,
      data: stock_record,
    };
  });

  return (
    <div className="w-full">
      {!isLabelHidden && (
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor="shipment-selector"
        >
          {label || "Product Stock"}
        </label>
      )}

      <Select
        id="product-stock-selector"
        options={options}
        value={value || null} // null hides the selected option in case we want to clear the input on select
        components={{ Option: customOption || CustomOption }}
        placeholder="Select Product Stock"
        onChange={handleSelect}
        noOptionsMessage={() => noOptionsMessage}
        blurInputOnSelect
        isClearable
        styles={customReactSelectStyle}
      />
    </div>
  );
};

export default ProductStockSelector;
