import React from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import TitleHeading from "../../components/TitleHeading";
import SearchBar from "../../components/search/SearchBar";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";

const GET_ADDRESSES = gql`
  query GetAddresses {
    inventory_address {
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      postcode
      country_code
      account {
        international_name
      }
    }
  }
`;

const Addresses = () => {
  const { loading: loadingAddresses, error: errorAddresses, data: dataAddresses } = useQuery(
    GET_ADDRESSES,
    {
      fetchPolicy: "network-only",
    }
  );

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateAddress = address_id => event => {
    event.preventDefault();
    openInNewTab(`addresses/edit/${address_id}`);
  };

  if (dataAddresses == null || loadingAddresses) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Locations"
          selectedSubMenu="all-addresses"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Locations"
          selectedSubMenu="all-addresses"
        />
      </div>
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Addresses" />
                  <SimpleLinkButton
                    label="Add Address"
                    keyboard_shortcut="n"
                    new_tab
                    url="/addresses/add"
                    id_ref="add_address"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>id</TH>
                        <TH>name</TH>
                        <TH>address line 1</TH>
                        <TH>address line 2</TH>
                        <TH>city</TH>
                        <TH>postcode</TH>
                        <TH>country</TH>
                        <TH>client name</TH>
                        <TH />
                      </TableHead>

                      <tbody>
                        {dataAddresses.inventory_address.map(address => {
                          return (
                            <TR key={address.id}>
                              <TD>{address.id}</TD>
                              <TD width={2} isEmphasised>
                                {address.name}
                              </TD>
                              <TD width={3} isEmphasised>
                                {address.address_line_1}
                              </TD>
                              <TD width={2} isEmphasised>
                                {address.address_line_2}
                              </TD>
                              <TD width={2} isEmphasised>
                                {address.city}
                              </TD>
                              <TD isFullWidth isEmphasised>
                                {address.postcode}
                              </TD>
                              <TD isFullWidth isEmphasised>
                                {address.country_code}
                              </TD>
                              <TD isFullWidth isEmphasised>
                                {address.account.international_name}
                              </TD>
                              <TD>
                                <button className="float-right" onClick={updateAddress(address.id)}>
                                  <i class="fas fa-edit"></i>
                                </button>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addresses;
