// Allows you to pick the qty and see the total price as a sense check
// Multi component with inputs and button

import React, { useState, useContext } from "react";
import SimpleRangedIntegerInput from "./SimpleRangedIntegerInput";
import { useQuery, useMutation, gql } from "@apollo/client";
import SimpleTextInput from "./SimpleTextInput";
import { useAuth0 } from "../../react-auth0-spa";

const QtyQuoteSelector = ({
  hide_callback,
  order_id,
  unit_price,
  refresh_callback,
  product_id,
  quote_id,
  minQty,
  maxQty,
}) => {
  const [inputs, setInputs] = useState({ qty: minQty, unit_price: unit_price });
  const [successCode, setSuccessCode] = useState(0);

  const ADD_CLIENT_ORDER_LINE = gql`
    mutation AddClientOrderLine(
      $product_pricing_id: Int = 1
      $requested_qty: Int = 10
      $client_order_id: Int = 1
      $client_order_line_type_id: Int = 1
      $product_id: Int = 10
      $unit_price: numeric
      $user_id: String!
    ) {
      insert_inventory_client_order_line_one(
        object: {
          client_order_id: $client_order_id
          client_order_line_type_id: $client_order_line_type_id
          client_order_line_status_id: 1
          product_pricing_id: $product_pricing_id
          requested_qty: $requested_qty
          unit_price: $unit_price
          product_id: $product_id
          user_id: $user_id
        }
      ) {
        id
      }
    }
  `;

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    addClientOrderLine({
      variables: {
        product_pricing_id: quote_id,
        requested_qty: inputs.qty,
        client_order_id: order_id,
        product_id: product_id,
        unit_price: inputs.unit_price,
        user_id: user_id,
      },
    });
  };

  const [addClientOrderLine, { loading: mutationLoading, error: mutationError }] = useMutation(
    ADD_CLIENT_ORDER_LINE,
    {
      onCompleted() {
        setSuccessCode(200);
        refresh_callback();
        hide_callback(false);
      },
    }
  );

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  return (
    <>
      <td className="w-2/12 p-4">
        <div class="flex -ml-3 -mb-3">
          <SimpleRangedIntegerInput
            label=""
            placeholder={minQty}
            change_handler={handleInputChange}
            id_ref="qty"
            minQty={minQty}
            maxQty={maxQty}
            value_ref={inputs.qty}
          />
        </div>
      </td>
      <td className="w-2/12 p-4">
        <div class="flex -ml-3 -mb-3">
          <SimpleTextInput
            label=""
            placeholder={unit_price}
            change_handler={handleInputChange}
            id_ref="unit_price"
            value_ref={inputs.unit_price}
          />
        </div>
      </td>
      <td className="w-2/12 p-4">
        {" "}
        <div class="flex -ml-3 -mb-3">
          <button
            className="flex-shrink-0 mb-3 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
            type="button"
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
      </td>
    </>
  );
};
export default QtyQuoteSelector;
