import React, { useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useAuth0 } from "../../react-auth0-spa";
import SimpleTextInput from "./SimpleTextInput";
import SimpleSelect from "./SimpleSelect";
import SimpleButton from "./SimpleButton";
import { breakpoints } from "../../utils/breakPoints";

const InputGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr 1fr max-content;
  }
`;

const EditClientOrderForm = ({ order_id, setSuccessCode }) => {
  const [inputs, setInputs] = useState({ account_id: -1, location_id: -1 });

  const UPDATE_CLIENT_ORDER = gql`
    mutation UpdateClientOrder($id: Int!, $user_id: String!, $client_order_status_id: Int!) {
      update_inventory_client_order_by_pk(
        pk_columns: { id: $id }
        _set: { user_id: $user_id, client_order_status_id: $client_order_status_id }
      ) {
        id
      }
    }
  `;

  const GET_CLIENT_ORDER = gql`
    query GetClientOrder($id: Int!) {
      inventory_client_order_by_pk(id: $id) {
        account_id
        location_id
        client_order_reference
        client_account {
          local_name
        }
        destination {
          location_name
        }
        client_order_status_id
      }
    }
  `;

  const GET_CLIENT_ORDER_STATUSES = gql`
    query ClientOrderStatus {
      inventory_client_order_status {
        id
        name: order_status_name
      }
    }
  `;

  const [updateClientOrder, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_CLIENT_ORDER,
    {
      onCompleted() {
        setSuccessCode(200);
        setTimeout(() => setSuccessCode(0), 3000);
      },
    }
  );

  const {
    loading_statuses,
    error_statuses,
    data: client_order_statuses,
    refetch: refetch_statuses,
  } = useQuery(GET_CLIENT_ORDER_STATUSES, {
    fetchPolicy: "network-only",
  });

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    updateClientOrder({
      variables: {
        client_order_status_id: inputs.client_order_status_id,
        id: order_id,
        user_id: user_id,
      },
    });
  };

  const { loading: loadingClientOrder, error: errorClientOrder, data: dataClientOrder } = useQuery(
    GET_CLIENT_ORDER,
    {
      variables: { id: order_id },
      fetchPolicy: "network-only",
      onCompleted() {
        setInputs({
          client_name: dataClientOrder.inventory_client_order_by_pk.client_account.local_name,
          location_name: dataClientOrder.inventory_client_order_by_pk.destination.location_name,
          client_order_reference:
            dataClientOrder.inventory_client_order_by_pk.client_order_reference,
          client_order_status_id:
            dataClientOrder.inventory_client_order_by_pk.client_order_status_id,
        });
      },
    }
  );

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (
    dataClientOrder == null ||
    loadingClientOrder ||
    client_order_statuses == null ||
    loading_statuses ||
    inputs === {}
  )
    return null;

  return (
    <form className="w-full mt-8 lg:mt-0" onSubmit={handleSubmit}>
      <InputGrid>
        <SimpleTextInput
          placeholder="Enter an Order Reference"
          value_ref={inputs.client_order_reference}
          read_only
          change_handler={handleInputChange}
          label="Order Reference"
          id_ref="client_order_reference"
        />

        <SimpleSelect
          placeholder="Order Status"
          change_handler={handleInputChange}
          label="Order Status"
          options={client_order_statuses.inventory_client_order_status}
          id_ref="client_order_status_id"
          selected_id={inputs.client_order_status_id}
          required
          name_func={option => {
            return option.name;
          }}
        />

        <SimpleTextInput
          placeholder="Client Name"
          value_ref={inputs.client_name}
          change_handler={handleInputChange}
          read_only
          label="Client Name"
          id_ref="client_name"
        />

        <SimpleTextInput
          placeholder="Location Name"
          value_ref={inputs.location_name}
          change_handler={handleInputChange}
          read_only
          label="Location Name"
          id_ref="location_name"
        />

        <div className={`w-full mt-8 px-3 md:w-1/2 -mb-4`}>
          <SimpleButton label="Save" />
        </div>
      </InputGrid>
    </form>
  );
};
export default EditClientOrderForm;
