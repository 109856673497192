import React, { FunctionComponent, HTMLAttributes } from "react";

interface TableProps extends HTMLAttributes<HTMLTableElement> {
  textSize?: string;
}

const tableStyles = ({ textSize, className }: TableProps = {}) =>
  ["w-full text-left", textSize || "text-sm", className].join(" ");

const Table: FunctionComponent<TableProps> = ({ children, textSize, className, ...props }) => {
  return (
    <table className={tableStyles({ textSize, className })} {...props}>
      {children}
    </table>
  );
};

export const TableHead: FunctionComponent = ({ children }) => (
  <thead>
    <tr className="bg-gray-100 text-gray-800 uppercase">{children}</tr>
  </thead>
);

export const TR: FunctionComponent<HTMLAttributes<HTMLTableRowElement>> = ({
  children,
  ...props
}) => {
  return (
    <tr className={`p-4 mb-2 border-t rounded bg-white`} {...props}>
      {children}
    </tr>
  );
};

interface TDProps extends HTMLAttributes<HTMLTableDataCellElement> {
  width?: number;
  isFullWidth?: boolean;
  colSpan?: number;
  className?: string;
  isEmphasised?: boolean;
}

const tdStyles = ({ isFullWidth, width, className }: TDProps = {}) =>
  ["p-4", `w-${isFullWidth ? "full" : `${width || 1}/12`}`, className].join(" ");

export const TD: FunctionComponent<TDProps> = ({
  className,
  children,
  isFullWidth,
  width,
  isEmphasised,
  ...props
}) => {
  return (
    <td className={tdStyles({ className, isFullWidth, width })} {...props}>
      {isEmphasised ? (
        <span className="font-semibold text-blue-700 product-result-truncate">{children}</span>
      ) : (
        children
      )}
    </td>
  );
};

export const TH: FunctionComponent = ({ children }) => {
  return <th className="p-4 m-0">{children}</th>;
};

export default Table;
