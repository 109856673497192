import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

import PrivateRoute from "./components/PrivateRoute";
import Sorry from "./views/Sorry";
import history from "./utils/history";

// styles
import "./App.css";
import "./assets/main.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { QueryParamProvider } from "use-query-params";

import LogRocket from "logrocket";

import Toast from "./components/Toast";

import ApolloWrapper from "./providers/ApolloWrapper";
import Suppliers from "./views/supplier/Suppliers";
import Add_Supplier from "./views/supplier/AddSupplier";
import EditSupplier from "./views/supplier/EditSupplier";
import Clients from "./views/client/Clients";
import AddClient from "./views/client/AddClient";
import EditClient from "./views/client/EditClients";
import Supplier from "./views/supplier/Supplier";
import Client from "./views/client/Client";
import ClientOrders from "./views/client-orders/ClientOrders";
import AddClientOrder from "./views/client-orders/AddClientOrder";
import AddClientOrderLines from "./views/client-orders/AddClientOrderLines";
import SKUs from "./views/sku/SKUs";
import AddBaseSKU from "./views/sku/AddBaseSKU";
import AddSKU from "./views/sku/AddSKU";
import SupplierProduct from "./views/sku/SupplierProduct";
import AddSupplierProduct from "./views/sku/AddSupplierProduct";

import SupplierQuotes from "./views/purchases/supplier-quotes/SupplierQuotes";
import AddSupplierQuote from "./views/purchases/supplier-quotes/AddSupplierQuote";
import SupplierOrders from "./views/purchases/supplier-orders/SupplierOrders";
import BaseSKUs from "./views/sku/BaseSKUs";
import EditBaseSKU from "./views/sku/EditBaseSKU";
import EditSKU from "./views/sku/EditSKU";
import AddPurchaseOrder from "./views/purchases/supplier-orders/AddPurchaseOrder";
import AddProductionOrderLines from "./views/purchases/supplier-orders/AddPurchaseOrderLines";
import Invoices from "./views/invoices/Invoices";
import Product_Stocks from "./views/stock/Stocks";
import Locations from "./views/locations/Locations";
import AddLocation from "./views/locations/AddLocation";
import AddAddress from "./views/locations/AddAddress";
import Shipments from "./views/shipments/Shipments";
import AddShipment from "./views/shipments/AddShipment";
import AddShipmentLines from "./views/shipments/EditShipment";
import Addresses from "./views/locations/Addresses";
import EditAddress from "./views/locations/EditAddress";
import ProductPricings from "./views/product-pricings/ProductPricings";
import EditProductPricing from "./views/product-pricings/EditProductPricing";
import AddProductPricing from "./views/product-pricings/AddProductPricing";
import EditSupplierQuote from "./views/purchases/supplier-quotes/EditSupplierQuote";
import AddMeeting from "./views/meetings/AddMeeting";
import EditMeeting from "./views/meetings/EditMeeting";
import Meetings from "./views/meetings/Meetings";
import Invoice from "./views/invoices/Invoice";
import AddStandaloneShipmentLine from "./views/shipments/AddStandaloneShipmentLine";

LogRocket.init(
  process.env.REACT_APP_LOGROCKET_URL
    ? process.env.REACT_APP_LOGROCKET_URL
    : window.REACT_APP_LOGROCKET_URL
);

initFontAwesome();

const App = () => {
  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ApolloWrapper>
          <ToastProvider autoDismiss autoDismissTimeout={3000} components={{ Toast }}>
            <CoreApp />
          </ToastProvider>
        </ApolloWrapper>
      </QueryParamProvider>
    </Router>
  );
};

const CoreApp = () => {
  return (
    <div className="antialiased text-gray-900 h-full justify-center ">
      <Switch>
        <PrivateRoute path="/" exact component={BaseSKUs} />
        <PrivateRoute path="/suppliers" exact component={Suppliers} />
        <PrivateRoute path="/suppliers/add" exact component={Add_Supplier} />
        <PrivateRoute path="/supplier/edit/:supplier_id" exact component={EditSupplier} />
        <PrivateRoute path="/supplier/:supplier_id" exact component={Supplier} />
        <PrivateRoute path="/client-orders" exact component={ClientOrders} />
        <PrivateRoute path="/client-orders/add" exact component={AddClientOrder} />
        <PrivateRoute path="/client-orders/edit/:order_id" exact component={AddClientOrderLines} />
        <PrivateRoute path="/supplier-orders" exact component={SupplierOrders} />
        <PrivateRoute path="/supplier-orders/add" exact component={AddPurchaseOrder} />
        <PrivateRoute
          path="/supplier-orders/edit/:order_id"
          exact
          component={AddProductionOrderLines}
        />
        <PrivateRoute path="/supplier-quotes" exact component={SupplierQuotes} />
        <PrivateRoute path="/supplier-quotes/add" exact component={AddSupplierQuote} />
        <PrivateRoute path="/supplier-quotes/edit/:quote_id" exact component={EditSupplierQuote} />
        <PrivateRoute path="/product-pricings" exact component={ProductPricings} />
        <PrivateRoute path="/product-pricings/add" exact component={AddProductPricing} />
        <PrivateRoute
          path="/product-pricings/edit/:product_pricing_id"
          exact
          component={EditProductPricing}
        />
        <PrivateRoute path="/clients" exact component={Clients} />
        <PrivateRoute path="/client/:client_id" exact component={Client} />
        <PrivateRoute path="/clients/add" exact component={AddClient} />
        <PrivateRoute path="/client/edit/:client_id" exact component={EditClient} />
        <PrivateRoute path="/products" exact component={SKUs} />
        <PrivateRoute path="/products/base" exact component={BaseSKUs} />
        <PrivateRoute path="/product/base/edit/:base_product_id" exact component={EditBaseSKU} />
        <PrivateRoute path="/products/add" exact component={AddSKU} />
        <PrivateRoute path="/products/supplier-product" exact component={SupplierProduct} />
        <PrivateRoute path="/products/supplier-product/add" exact component={AddSupplierProduct} />
        <PrivateRoute path="/products/base/add" exact component={AddBaseSKU} />
        <PrivateRoute path="/products/update/:product_id" exact component={EditSKU} />
        <PrivateRoute path="/invoices" exact component={Invoices} />
        <PrivateRoute path="/invoice/:invoice_id" exact component={Invoice} />
        <PrivateRoute path="/product-stocks" exact component={Product_Stocks} />
        <PrivateRoute path="/shipments" exact component={Shipments} />
        <PrivateRoute path="/shipments/add" exact component={AddShipment} />
        <PrivateRoute path="/shipments/edit/:shipment_id" exact component={AddShipmentLines} />
        <PrivateRoute path="/shipment-lines/add" exact component={AddStandaloneShipmentLine} />

        <PrivateRoute path="/addresses/add" exact component={AddAddress} />
        <PrivateRoute path="/addresses/edit/:address_id" exact component={EditAddress} />
        <PrivateRoute path="/addresses" exact component={Addresses} />
        <PrivateRoute path="/locations/add" exact component={AddLocation} />
        <PrivateRoute path="/locations" exact component={Locations} />

        <PrivateRoute path="/meetings/add/:account_id" exact component={AddMeeting} />
        <PrivateRoute path="/meetings/edit/:meeting_id" exact component={EditMeeting} />

        <Route path="/sorry" component={Sorry} />
      </Switch>
    </div>
  );
};

export default App;
