import React from "react";
import { ClientDetailAccount } from "../../graphql/queries/clientDetail";
import { SupplierDetailAccount } from "../../graphql/queries/supplierDetail";

interface AccountLocationsList {
  locations: ClientDetailAccount["account_locations"] | SupplierDetailAccount["account_locations"];
}

const AccountLocationsList = ({ locations }: AccountLocationsList) => {
  return (
    <div className="flex flex-row flex-wrap py-2">
      {locations.map(location => {
        return (
          <div key={location.id} className="flex w-full border mb-2 rounded flex-col bg-white">
            <div className="w-full p-4 flex flex-col">
              <div className="w-full flex flex-col">
                <div className="w-full">
                  <h2 className="mt-4 mb-2 font-semibold text-blue-700 product-result-truncate">
                    <i className={`lni lni-home mr-2`}></i> {location.name}
                  </h2>
                </div>
              </div>
            </div>

            {location.child_locations &&
              location.child_locations.map(child_location => {
                return (
                  <div key={child_location.name}>
                    <div className="w-full flex flex-col">
                      <div className="w-full border-t flex sm:flex-row flex-col">
                        <div className="ml-16 w-full">
                          <h2 className="mt-4 mb-2 font-semibold text-blue-700 product-result-truncate">
                            {child_location.name}
                          </h2>
                        </div>
                      </div>
                    </div>
                    {child_location.child_locations &&
                      child_location.child_locations.map(child_child_location => {
                        return (
                          <div className="w-full flex flex-col">
                            <div className="w-full border-t flex sm:flex-row flex-col">
                              <div className="ml-20 w-full">
                                <h2 className="mt-4 mb-2 font-semibold text-blue-700 product-result-truncate">
                                  {child_child_location.name}
                                </h2>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default AccountLocationsList;
