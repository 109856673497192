import React, { useState, useEffect } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { useParams } from "react-router-dom";
import SideMenu from "../../components/SideMenu";
import TitleHeading from "../../components/TitleHeading";
import { parseXeroDate } from "../../utils/parseXeroDate";
import SearchBar from "../../components/search/SearchBar";

const TH = ({ children, ...props }) => (
  <th className="p-2 m-0" {...props}>
    {children}
  </th>
);

const TD = ({ children, ...props }) => (
  <td className={`font-semibold p-2 text-gray-700`} {...props}>
    {children}
  </td>
);

const Invoice = () => {
  const { invoice_id } = useParams();

  const { getTokenSilently } = useAuth0();
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    async function get_invoice() {
      try {
        setInvoice(null);
        const token = await getTokenSilently();

        const response = await fetch(
          process.env.REACT_APP_API_ORIGIN
            ? `${process.env.REACT_APP_API_ORIGIN}/xero-api/invoice?invoice_id=${invoice_id}`
            : `${window.REACT_APP_API_ORIGIN}/xero-api/invoice?invoice_id=${invoice_id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            mode: "cors",
            method: "GET",
          }
        );

        const responseData = await response.json();

        if (!responseData || !responseData.Invoices.length) {
          throw new Error("No Invoice Found");
        }

        setInvoice(responseData.Invoices[0]);
      } catch (error) {
        console.error(error);
      }
    }
    get_invoice();
  }, []);

  if (!invoice) return null;

  const xeroHref =
    invoice.Type === "ACCPAY"
      ? `https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${invoice.InvoiceID}`
      : `https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${invoice.InvoiceID}`;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="Invoices"
            selectedSubMenu="all-invoices"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="Invoices"
            selectedSubMenu="all-invoicess"
          />
        </div>
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className=" xl:pl-2 fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Invoice" />
                </div>
                <div className="h-full my-8 xl:my-0">
                  <div className="mb-4 xl:mb-8">
                    <a
                      href={xeroHref}
                      className="px-2 text-blue-700 font-bold"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View in Xero
                    </a>
                  </div>

                  <table className="w-full text-sm text-left">
                    <thead>
                      <tr className="text-gray-800 uppercase">
                        <TH>to</TH>
                        <TH>reference</TH>
                        <TH>invoice number</TH>
                        <TH>issue date</TH>
                        <TH>due date</TH>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <TD>{invoice.Contact.Name}</TD>
                        <TD>{invoice.Reference}</TD>
                        <TD>{invoice.InvoiceID}</TD>
                        <TD>{parseXeroDate(invoice.Date).toLocaleDateString()}</TD>
                        <TD>{parseXeroDate(invoice.DueDate).toLocaleDateString()}</TD>
                        <TD></TD>
                      </tr>
                    </tbody>
                  </table>

                  <table className="mt-8 w-full text-sm text-left">
                    <thead>
                      <tr className="text-gray-800 uppercase">
                        <TH>item / description</TH>
                        <TH>quantity</TH>
                        <TH>price</TH>
                        <TH>tax amount</TH>
                        <TH>amount</TH>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice.LineItems.map(lineItem => {
                        return (
                          <tr
                            key={lineItem.LineItemID}
                            className={`p-4 mb-2 border-t rounded bg-white`}
                          >
                            <TD>{lineItem.Description}</TD>
                            <TD>{lineItem.Quantity}</TD>
                            <TD>{lineItem.UnitAmount}</TD>
                            <TD>{lineItem.TaxAmount}</TD>
                            <TD>{lineItem.UnitAmount * lineItem.Quantity}</TD>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="flex md:flex-row-reverse">
                    <table className="mt-8 text-left sm:w-5/12 text-sm">
                      <tbody>
                        <tr className="text-gray-800">
                          <TD>Subtotal excl. tax</TD>
                          <TD>{invoice.SubTotal}</TD>
                        </tr>
                        <tr className="text-gray-800">
                          <TD>Total VAT</TD>
                          <TD>{invoice.TotalTax}</TD>
                        </tr>
                        <tr className="text-gray-800 border-t">
                          <TD>Total</TD>
                          <TD>{invoice.Total}</TD>
                        </tr>

                        {invoice.Payments &&
                          invoice.Payments.length > 0 &&
                          invoice.Payments.map(payment => (
                            <tr key={payment.PaymentID} className="text-blue-700 border-t">
                              <TD>{`Less payment - ${parseXeroDate(
                                payment.Date
                              ).toLocaleDateString()}`}</TD>
                              <TD>{payment.Amount}</TD>
                            </tr>
                          ))}

                        <tr className="text-gray-800 border-t">
                          <TD>Amount Due</TD>
                          <TD>{invoice.AmountDue}</TD>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
