import React from "react";
import { ToastProps } from "react-toast-notifications";

const toastStates = {
  entering: { transform: "scale(0.66)", opacity: 0 },
  entered: { transform: "scale(1)", opacity: 1 },
  exiting: { transform: "scale(0.66)", opacity: 0 },
  exited: { transform: "scale(0.66)", opacity: 0 },
};

interface ToastColourMap {
  [index: string]: string;
}

const appearanceColorMap = (intensity: number): ToastColourMap => ({
  error: `bg-red-${intensity} text-white`,
  success: `bg-green-${intensity} text-white`,
});

const toastStyles = ({ appearance }: Partial<ToastProps>) =>
  [
    "flex items-center p-2 rounded-full z-50 shadow mb-3 text-small font-semibold",
    appearance && appearanceColorMap(800)[appearance],
  ].join(" ");

const labelStyles = ({ appearance }: Partial<ToastProps>) =>
  [
    "flex rounded-full uppercase px-2 py-1 text-xs font-bold mr-3",
    appearance && appearanceColorMap(500)[appearance],
  ].join(" ");

const Toast = ({ children, appearance, transitionDuration, transitionState }: ToastProps) => {
  return (
    <div
      role="alert"
      style={{
        transition: `transform ${transitionDuration}ms linear, opacity ${transitionDuration}ms`,
        ...toastStates[transitionState],
      }}
      className={toastStyles({ appearance })}
    >
      <span className={labelStyles({ appearance })}>{appearance}</span>
      {children}
    </div>
  );
};

export default Toast;
