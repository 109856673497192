import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import TitleHeading from "../../components/TitleHeading";
import SearchBar from "../../components/search/SearchBar";
import { useAuth0 } from "../../react-auth0-spa";
import {
  LocationsQuery,
  ClientOrderLinesQuery,
  AddShipmentLineMutationVariables,
  AddShipmentLineMutation,
  LocationsQueryVariables,
  ShipmentsQuery,
  ShipmentsQueryVariables,
  ProductStockQuery,
  ProductStockQueryVariables,
  ClientOrderLinesQueryVariables,
} from "../../generated/graphql";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import CLIENT_ORDER_LINES_QUERY from "../../graphql/queries/clientOrderLines";
import ADD_SHIPMENT_LINE_MUTATION from "../../graphql/mutations/addShipmentLine";
import LOCATIONS_QUERY from "../../graphql/queries/locations";
import ClientOrderLineSelector from "../../components/ClientOrderLineSelector";
import { ClientOrderLineSelectItem } from "../../types/ClientOrderLineOption";
import OrderLineSummary from "../../components/ClientOrderLineSelector/OrderLineSummary";
import SHIPMENTS_QUERY from "../../graphql/queries/shipments";
import { ShipmentSelectItem } from "../../types/ShipmentOption";
import PRODUCT_STOCK_QUERY, { ProductStockQueryItem } from "../../graphql/queries/productStock";
import { ValueType } from "react-select";
import { SelectItem } from "../../types/SelectItem";
import ProductStockSelector from "../../components/ProductStockSelector";
import ProductStockSummary from "../../components/ProductStockSelector/ProductStockSummary";
import MutationSuccess from "../../components/MutationSuccess";
import MutationError from "../../components/MutationError";

const validationSchema = Yup.object({
  client_order_line: Yup.object({
    value: Yup.string().required(),
    label: Yup.string(),
    data: Yup.object().required(),
  })
    .required("Please select a client order line")
    .nullable(),
  source_product_stock: Yup.object({
    value: Yup.string().required(),
    label: Yup.string(),
    data: Yup.object().required(),
  })
    .nullable()
    .required("Please select product stock"),
  reserved_qty: Yup.string().required("Please enter reserved quantity"),
});

interface ShipmentLineFormValues {
  client_order_line: ClientOrderLineSelectItem | null;
  shipment: ShipmentSelectItem | null;
  reserved_qty: string;
  destination_location_id: string;
  source_product_stock: SelectItem<ProductStockQueryItem> | null;
}

const AddStandaloneShipmentLine = () => {
  const [successCode, setSuccessCode] = useState(0);

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  const [addShippingLine, { error: mutationError, data: addShippingLineResponse }] = useMutation<
    AddShipmentLineMutation,
    AddShipmentLineMutationVariables
  >(ADD_SHIPMENT_LINE_MUTATION, {
    onCompleted: () => setSuccessCode(200),
    onError: () => setSuccessCode(0),
  });

  const { loading: loading_client_order_lines, data: clientOrderLinesResponse } = useQuery<
    ClientOrderLinesQuery,
    ClientOrderLinesQueryVariables
  >(CLIENT_ORDER_LINES_QUERY, {
    variables: {
      minQty: 1,
      statusFilter: ["new", "processing"],
    },
    fetchPolicy: "network-only",
  });

  const { loading: loading_locations, data: locationsResponse } = useQuery<
    LocationsQuery,
    LocationsQueryVariables
  >(LOCATIONS_QUERY, {
    fetchPolicy: "network-only",
  });

  // TODO: filter shipments by source/destination
  const { loading: loading_shipments, data: shipmentsResponse } = useQuery<
    ShipmentsQuery,
    ShipmentsQueryVariables
  >(SHIPMENTS_QUERY, {
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  const [fetchProductStock, { data: productStockResponse }] = useLazyQuery<
    ProductStockQuery,
    ProductStockQueryVariables
  >(PRODUCT_STOCK_QUERY, {
    fetchPolicy: "network-only",
  });

  const onSubmit = async (values: ShipmentLineFormValues) => {
    await addShippingLine({
      variables: {
        client_order_line_id: values.client_order_line!.data.id,
        product_id: values.client_order_line!.data.product!.id,
        source_location_id: values.source_product_stock!.data.location.id,
        destination_location_id: Number(values.client_order_line!.data.client_order.location_id),
        source_product_stock_id: values.source_product_stock!.data.id,
        source_product_stock_account_id: values.source_product_stock!.data.account_id,
        updated_source_product_stock_qty:
          values.source_product_stock!.data.qty - Number(values.reserved_qty), // TODO: validation that this is above 0
        reserved_qty: Number(values.reserved_qty),
        user_id: user_id,
        shipment_id: values.shipment?.data.id,
      },
    });
  };

  if (locationsResponse == null || loading_locations) return null;
  if (!shipmentsResponse || loading_shipments) return null;
  if (clientOrderLinesResponse == null || loading_client_order_lines) return null;

  return (
    <>
      <div className="h-full bg-gray-100 bg-opacity-25">
        <div className="flex flex-row">
          <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
          <div className="xl:w-9/12 w-full">
            <div className="h-screen container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-overlay">
              <SearchBar />
              <div className="flex pt-4 mb-4">
                <div className="w-full h-auto  ml-2">
                  <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                    <TitleHeading label="Shipment Line &gt; Add a new Shipment Line" />
                    {addShippingLineResponse && (
                      <MutationSuccess message="The Shipment Line was added successfully." />
                    )}
                    {mutationError && <MutationError error={mutationError} />}
                  </div>
                  <Formik
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    initialValues={
                      {
                        client_order_line: null,
                        shipment: null,
                        source_product_stock: null,
                        reserved_qty: "",
                        source_location_id: "",
                        destination_location_id: "",
                      } as ShipmentLineFormValues
                    }
                  >
                    {({ values, handleChange, errors, touched, setFieldValue, handleSubmit }) => {
                      return (
                        <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                          <div className="w-full lg:w-1/2 p-4 lg:p-0">
                            <div className="mb-4">
                              <ClientOrderLineSelector
                                handleSelect={async selectedOrderLine => {
                                  setFieldValue("client_order_line", selectedOrderLine);
                                  // reset source product stock
                                  setFieldValue("source_product_stock", null);
                                  console.log("selectedOrderLIne", selectedOrderLine);
                                  if (selectedOrderLine) {
                                    fetchProductStock({
                                      variables: {
                                        product_id: selectedOrderLine.data.product?.id!,
                                        statusFilter: ["available"],
                                        location_id:
                                          selectedOrderLine.data.client_order.location_id,
                                      },
                                    });
                                  }
                                }}
                                client_order_lines={
                                  clientOrderLinesResponse.inventory_client_order_line
                                }
                              />

                              <div className="mt-3">
                                {values?.client_order_line && (
                                  <OrderLineSummary order_line={values.client_order_line.data} />
                                )}
                              </div>
                              {errors.client_order_line && touched.client_order_line && (
                                <div className="text-red-800">{errors.client_order_line}</div>
                              )}
                            </div>

                            <div className="flex flex-wrap mb-2">
                              <SimpleTextInput
                                placeholder="Enter reserved qty"
                                value_ref={values.reserved_qty}
                                required
                                change_handler={handleChange}
                                label="reserved qty"
                                id_ref="reserved_qty"
                              />
                            </div>

                            <div className="mb-4">
                              <ProductStockSelector
                                noOptionsMessage={
                                  values.client_order_line
                                    ? "No compatible product stock"
                                    : "Please select a client order line"
                                }
                                product_stock={
                                  (productStockResponse &&
                                    productStockResponse?.inventory_product_stock) ||
                                  []
                                }
                                handleSelect={(
                                  selected: ValueType<SelectItem<ProductStockQueryItem>, false>
                                ) => setFieldValue("source_product_stock", selected)}
                              />

                              <div className="mt-3">
                                {values?.source_product_stock && (
                                  <ProductStockSummary
                                    stock_record={values.source_product_stock.data}
                                  />
                                )}
                              </div>
                              {errors.source_product_stock && touched.source_product_stock && (
                                <div className="text-red-800">{errors.source_product_stock}</div>
                              )}
                            </div>

                            {successCode !== 200 && (
                              <button
                                className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                type="submit"
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStandaloneShipmentLine;
