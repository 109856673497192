import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import SearchBar from "../../components/search/SearchBar";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import SimpleSelect from "../../components/forms/SimpleSelect";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

const GET_ADDRESS = gql`
  query GetAddress($address_id: Int!) {
    inventory_address_by_pk(id: $address_id) {
      id
      name
      address_line_1
      address_line_2
      address_line_3
      address_description
      city
      postcode
      country_code
      account_id
      phone_number
      account {
        name: local_name
        international_name
      }
    }
  }
`;

const EDIT_ADDRESS = gql`
  mutation UpdateAddress(
    $address_id: Int!
    $account_id: Int
    $address_description: String!
    $address_line_1: String!
    $address_line_2: String
    $address_line_3: String
    $city: String!
    $country_code: String!
    $name: String!
    $phone_number: String!
    $postcode: String!
    $user_id: String!
  ) {
    update_inventory_address_by_pk(
      pk_columns: { id: $address_id }
      _set: {
        account_id: $account_id
        name: $name
        address_description: $address_description
        address_line_1: $address_line_1
        address_line_2: $address_line_2
        address_line_3: $address_line_3
        city: $city
        postcode: $postcode
        phone_number: $phone_number
        country_code: $country_code
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;

const GET_ACCOUNTS = gql`
  query GetAccounts {
    inventory_account {
      id
      locale
      name: local_name
      international_name
      user_id
      account_description
      account_status_id
      acquisition_channel
      last_updated
    }
  }
`;

const GET_COUNTRY_CODE = gql`
  query GetCountryCode {
    inventory_country(order_by: { country_code: asc }) {
      id: country_code
      name: country_code
    }
  }
`;

const EditAddress = () => {
  const { user } = useAuth0();
  const user_id = user ? user.sub : null;
  const { address_id } = useParams();
  const [inputs, setInputs] = useState({});

  const [countryCodes, setCountryCodes] = useState([]);
  const [successCode, setSuccessCode] = useState(0);
  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const {
    loading: loadingAddress,
    error: errorAddress,
    data: dataAddress,
    refetch: refreshAddress,
  } = useQuery(GET_ADDRESS, {
    fetchPolicy: "network-only",
    variables: {
      address_id: address_id,
    },
  });

  const {
    loading: loadingAccounts,
    error: errorAccounts,
    data: dataAccounts,
    refetch: refreshAccounts,
  } = useQuery(GET_ACCOUNTS, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingCountryCodes,
    error: errorCountryCodes,
    data: dataCountryCodes,
  } = useQuery(GET_COUNTRY_CODE, {
    fetchPolicy: "network-only",
  });

  const [EditAddress, { loading: mutationLoading, error: mutationError }] = useMutation(
    EDIT_ADDRESS,
    {
      onCompleted(data) {
        setSuccessCode(200);
      },
    }
  );

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    EditAddress({
      variables: {
        address_id: address_id,
        user_id: user_id,
        account_id: inputs.account_id,
        name: inputs.name,
        address_description: inputs.address_description,
        address_line_1: inputs.address_line_1,
        address_line_2: inputs.address_line_2,
        address_line_3: inputs.address_line_3,
        city: inputs.city,
        postcode: inputs.postcode,
        phone_number: inputs.phone_number,
        country_code: inputs.country_code,
      },
    });
  };
  const handleCheckBoxChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.checked,
    }));
  };

  useEffect(() => {
    if (dataCountryCodes == null || loadingCountryCodes) return;

    let copy = dataCountryCodes.inventory_country.slice();

    copy.unshift({
      id: "CN",
      name: "CN",
    });
    copy.unshift({
      id: "GB",
      name: "GB",
    });
    setCountryCodes(copy);
  }, [dataCountryCodes]);

  useEffect(() => {
    if (dataAddress == null || loadingAddress) return;
    setInputs({
      account_name: dataAddress.inventory_address_by_pk.account.name,
      account_id: dataAddress.inventory_address_by_pk.account_id,
      name: dataAddress.inventory_address_by_pk.name,
      address_description: dataAddress.inventory_address_by_pk.address_description,
      address_line_1: dataAddress.inventory_address_by_pk.address_line_1,
      address_line_2: dataAddress.inventory_address_by_pk.address_line_2,
      address_line_3: dataAddress.inventory_address_by_pk.address_line_3,
      city: dataAddress.inventory_address_by_pk.city,
      postcode: dataAddress.inventory_address_by_pk.postcode,
      phone_number: dataAddress.inventory_address_by_pk.phone_number,
      country_code: dataAddress.inventory_address_by_pk.country_code,
    });
  }, [dataAddress]);

  if (dataAccounts == null || loadingAccounts) return null;
  if (dataCountryCodes == null || loadingCountryCodes) return null;
  if (dataAddress == null || loadingAddress) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <h1 className="text-3xl xl:text-4xl mx-2 font-bold">
                    Locations &gt; Edit Address
                  </h1>
                  {successCode === 200 && (
                    <div
                      className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Success
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        The Address was edited successfully.
                      </span>
                    </div>
                  )}
                  {successCode === 400 && (
                    <div
                      className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Error
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        There was a problem with the data - please check.
                      </span>
                    </div>
                  )}
                </div>

                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Select Client/Supplier Account"
                        required
                        label="Account"
                        id_ref="account_id"
                        value_ref={inputs.account_name}
                        read_only
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Address Name"
                        value_ref={inputs.name}
                        required={true}
                        change_handler={handleInputChange}
                        label="Address Name"
                        id_ref="name"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Address Description"
                        value_ref={inputs.address_description}
                        required={true}
                        change_handler={handleInputChange}
                        label="Address Description"
                        id_ref="address_description"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Address Line 1"
                        value_ref={inputs.address_line_1}
                        required={true}
                        change_handler={handleInputChange}
                        label="Address Line 1"
                        id_ref="address_line_1"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Address Line 2"
                        value_ref={inputs.address_line_2}
                        change_handler={handleInputChange}
                        label="Address Line 2"
                        id_ref="address_line_2"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Address Line 3"
                        value_ref={inputs.address_line_3}
                        change_handler={handleInputChange}
                        label="Address Line 3"
                        id_ref="address_line_3"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Address City"
                        value_ref={inputs.city}
                        change_handler={handleInputChange}
                        label="City"
                        id_ref="city"
                        two_column={true}
                      />
                      <SimpleTextInput
                        placeholder="Enter Post Code"
                        value_ref={inputs.postcode}
                        change_handler={handleInputChange}
                        label="Postcode"
                        id_ref="postcode"
                        two_column={true}
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Phone Number"
                        value_ref={inputs.phone_number}
                        change_handler={handleInputChange}
                        label="Phone Number"
                        id_ref="phone_number"
                        two_column={true}
                        required={true}
                      />
                      <SimpleSelect
                        placeholder="Select Country Code"
                        options={countryCodes}
                        required={true}
                        change_handler={handleInputChange}
                        refresh_handler={refreshAccounts}
                        label="Country Code"
                        id_ref="country_code"
                        selected_id={inputs.country_code}
                        two_column={true}
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>

                    {successCode !== 200 && (
                      <button
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                      >
                        Edit Address
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAddress;
