import React from "react";
import { SupplierDetailAccount } from "../graphql/queries/supplierDetail";
import { ClientDetailAccount } from "../graphql/queries/clientDetail";

interface AccountHeaderProps {
  account: ClientDetailAccount | SupplierDetailAccount;
}

const AccountHeader = ({ account }: AccountHeaderProps) => {
  return (
    <div className="flex items-center">
      <h2 className="mt-4 mr-2 lg:mt-0 text-2xl xl:text-3xl font-semibold text-blue-700 product-result-truncate">
        {account.local_name}
      </h2>
      {account.logo_url && (
        <img
          style={{ minWidth: "35px", width: "35px", height: "35px" }}
          src={account.logo_url}
          alt="client-logo"
        />
      )}
    </div>
  );
};

export default AccountHeader;
