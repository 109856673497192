import React, { useState } from "react";
import SideMenu from "../../components/SideMenu";
import { useMutation, gql } from "@apollo/client";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import TitleHeading from "../../components/TitleHeading";
import SearchBar from "../../components/search/SearchBar";
import { useAuth0 } from "../../react-auth0-spa";

const ADD_BASE_SKU = gql`
  mutation AddBaseProduct(
    $user_id: String!
    $base_product_name: String!
    $image_url: String!
    $hs_code: String!
    $base_product_description: String!
  ) {
    insert_inventory_base_product_one(
      object: {
        user_id: $user_id
        base_product_name: $base_product_name
        base_product_description: $base_product_description
        image_url: $image_url
        hs_code: $hs_code
      }
    ) {
      id
    }
  }
`;

const AddBaseSKU = () => {
  const [inputs, setInputs] = useState({});
  const [successCode, setSuccessCode] = useState(0);
  const [addBaseProduct, { loading: mutationLoading, error: mutationError }] = useMutation(
    ADD_BASE_SKU,
    {
      onCompleted(data) {
        setSuccessCode(200);
        // window.location = "/client-orders/" + data.insert_inventory_base_product_one.id + "/add-order-items"
      },
    }
  );

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const resetForm = event => {
    if (event) {
      event.preventDefault();
    }
    setInputs({
      name: "",
    });
    setSuccessCode(0);
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    addBaseProduct({
      variables: {
        base_product_name: inputs.base_product_name,
        image_url: inputs.image_url,
        hs_code: inputs.hs_code,
        base_product_description: inputs.base_product_description,
        user_id: user_id,
      },
    });
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="SKUs"
          selectedSubMenu="add-base-sku"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="SKUs"
          selectedSubMenu="add-base-sku"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Base SKU &gt; Add a new Base SKU" />
                  {successCode === 200 && (
                    <div
                      className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Success
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        The SKU was added successfully.
                      </span>
                    </div>
                  )}
                  {successCode === 400 && (
                    <div
                      className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Error
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        There was a problem with the data - please check.
                      </span>
                    </div>
                  )}
                </div>

                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter the name for the base product"
                        value_ref={inputs.base_product_name}
                        required={true}
                        label="base product name"
                        change_handler={handleInputChange}
                        id_ref="base_product_name"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2 ">
                      <div className="w-full mb-2 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Image URL
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="image_url"
                          type="text"
                          name="image_url"
                          placeholder="Enter the link to the Image URL"
                          onChange={handleInputChange}
                          value={inputs.image_url}
                          required
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter the HS code"
                        change_handler={handleInputChange}
                        value_ref={inputs.hs_code}
                        required={true}
                        label="hs code"
                        id_ref="hs_code"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter a description"
                        change_handler={handleInputChange}
                        value_ref={inputs.base_product_description}
                        required={true}
                        label="BASE PRODUCT DESCRIPTION"
                        id_ref="base_product_description"
                      />
                    </div>

                    {successCode !== 200 && (
                      <button
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                      >
                        Add
                      </button>
                    )}
                  </div>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div>{inputs.image_url && <img src={inputs.image_url} alt="preview" />}</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBaseSKU;
