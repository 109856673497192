import React from "react";

interface SimpleRangedIntegerInputProps {
  label: string;
  value_ref: string | number;
  change_handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id_ref: string;
  placeholder: string;
  required?: boolean;
  two_column?: boolean;
  minQty?: number;
  maxQty?: number;
  isMarginDisabled?: boolean;
  isDisabled?: boolean;
}

const inputStyles = ({ isDisabled, isMarginDisabled }: Partial<SimpleRangedIntegerInputProps>) =>
  [
    "appearance-none block w-full text-gray-900 border rounded py-3 px-4 leading-tight",
    "focus:outline-none focus:bg-white focus:border-gray-500",
    !isMarginDisabled && `mb-3`,
    isDisabled && "bg-gray-300 opacity-50",
  ].join(" ");

const SimpleRangedIntegerInput = ({
  label,
  value_ref,
  change_handler,
  id_ref,
  placeholder,
  required,
  two_column,
  minQty,
  maxQty,
  isMarginDisabled,
  isDisabled,
}: SimpleRangedIntegerInputProps) => {
  return (
    <div
      className={`w-full ${!isMarginDisabled && `mb-2`} md:mb-0 ${
        two_column ? `md:w-1/2 ${!isMarginDisabled && `mb-4`} lg:mb-6` : ``
      }`}
    >
      {label === "" ? (
        <></>
      ) : (
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor={id_ref}
        >
          {label} {required ? `*` : null}
        </label>
      )}

      <input
        className={inputStyles({ isDisabled, isMarginDisabled })}
        id={id_ref}
        type="number"
        name={id_ref}
        placeholder={placeholder}
        onChange={change_handler}
        value={value_ref}
        required={required}
        min={minQty}
        max={maxQty}
        disabled={isDisabled}
      />
    </div>
  );
};
export default SimpleRangedIntegerInput;
