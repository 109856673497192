import React, { useState } from "react";
import _ from "lodash";
import { useMutation, useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import TitleHeading from "../../components/TitleHeading";
import SimpleSelect from "../../components/forms/SimpleSelect";
import ManageClientReferral from "../../components/forms/ManageClientReferral";
import SearchBar from "../../components/search/SearchBar";
import { useAuth0 } from "../../react-auth0-spa";
import MutationError from "../../components/MutationError";
import MutationSuccess from "../../components/MutationSuccess";

const ADD_CLIENT = gql`
  mutation AddClient(
    $user_id: String!
    $local_name: String!
    $locale: String!
    $international_name: String!
    $acquisition_channel: String = ""
    $account_description: String!
    $account_sector_id: Int!
    $email: String = ""
    $telephone_number: String = ""
    $website: String = ""
    $logo_url: String = ""
  ) {
    insert_inventory_account_one(
      object: {
        user_id: $user_id
        locale: $locale
        local_name: $local_name
        acquisition_channel: $acquisition_channel
        international_name: $international_name
        account_roles: { data: { role_id: 3 } }
        account_status_id: 1
        account_description: $account_description
        email: $email
        telephone_number: $telephone_number
        website: $website
        logo_url: $logo_url
        account_sector_id: $account_sector_id
      }
    ) {
      id
    }
  }
`;

const ADD_REFERRAL = gql`
  mutation AddReferral($account_id: Int!, $referrer_account_id: Int!) {
    insert_inventory_account_referral_one(
      object: { account_id: $account_id, referrer_account_id: $referrer_account_id }
    ) {
      id
    }
  }
`;

const LOCALES = gql`
  query GetLocales {
    inventory_locale {
      id: locale
      name: locale
    }
  }
`;

const SECTORS = gql`
  query GetSectors {
    inventory_account_sector {
      id
      account_sector_name
    }
  }
`;

const AddClient = () => {
  const [inputs, setInputs] = useState({
    international_name: "",
    website: "",
    logo_url: "",
    account_id: "",
    referral: [],
    account_sector_id: 1,
  });
  const [successCode, setSuccessCode] = useState(0);
  const [addClient, { error: mutationError }] = useMutation(ADD_CLIENT, {
    onCompleted() {
      setSuccessCode(200);
    },
    onError() {
      setSuccessCode(0);
    },
  });
  const [addReferral, { error: addReferralMutationError }] = useMutation(ADD_REFERRAL, {
    onCompleted() {
      setSuccessCode(200);
    },
    onError() {
      setSuccessCode(0);
    },
  });

  const {
    loading: loadingLocales,
    error: errorLocales,
    data: locales,
    refetch: refreshLocale,
  } = useQuery(LOCALES, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingSectors,
    error: errorSectors,
    data: sectors,
    refetch: refreshSectors,
  } = useQuery(SECTORS, {
    fetchPolicy: "network-only",
  });

  const handleInputChange = event => {
    event.persist && event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }
    const client = await addClient({
      variables: {
        local_name: inputs.local_name,
        account_description: inputs.client_description,
        user_id: user_id,
        international_name:
          inputs.international_name === "" ? inputs.local_name : inputs.international_name,
        acquisition_channel: "",
        locale: inputs.locale,
        account_sector_id: inputs.account_sector_id === "" ? 1 : inputs.account_sector_id,
        email: inputs.email,
        telephone_number: inputs.telephone_number,
        website: inputs.website,
        logo_url: inputs.logo_url,
      },
    });

    if (!client) {
      return;
    }

    const id = client.data.insert_inventory_account_one.id;
    _.uniq(inputs.referral.map(field => field.account_id).filter(field => !!field)).forEach(
      referer_id => {
        addReferral({
          variables: {
            account_id: id,
            referrer_account_id: referer_id,
          },
        });
      }
    );
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (loadingLocales || locales === null) return null;
  if (loadingSectors || sectors === null) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Clients"
          selectedSubMenu="add-client"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Clients"
          selectedSubMenu="add-client"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Client &gt; Add a new Client" />
                  {successCode === 200 && (
                    <MutationSuccess message="The Client was added successfully." />
                  )}

                  {mutationError && <MutationError error={mutationError} />}
                  {addReferralMutationError && <MutationError error={addReferralMutationError} />}
                </div>
                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter the Client Name (Local)"
                        value_ref={inputs.local_name}
                        required={true}
                        change_handler={handleInputChange}
                        label="Client Name (Local)"
                        id_ref="local_name"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Locale"
                        options={locales.inventory_locale}
                        add_url="/locales/add"
                        selected_id={inputs.client_status_id}
                        required
                        change_handler={handleInputChange}
                        refresh_handler={refreshLocale}
                        label="Locale"
                        id_ref="locale"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Leave blank if same in English"
                        value_ref={inputs.international_name}
                        change_handler={handleInputChange}
                        label="Client Name (International)"
                        id_ref="international_name"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Sector for the Client"
                        options={sectors.inventory_account_sector}
                        selected_id={inputs.client_status_id}
                        change_handler={handleInputChange}
                        refresh_handler={refreshSectors}
                        label="Sector"
                        id_ref="account_sector_id"
                        name_func={option => {
                          return option.account_sector_name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Client Description"
                        value_ref={inputs.client_description}
                        required
                        change_handler={handleInputChange}
                        label="Client Description"
                        id_ref="client_description"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for email address */}
                      <SimpleTextInput
                        placeholder="Enter Client Email Address (Primary Contact)"
                        value_ref={inputs.email}
                        change_handler={handleInputChange}
                        label="Client Email Address"
                        id_ref="email"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for phone number */}
                      <SimpleTextInput
                        placeholder="Enter Client Phone Number (Primary Contact)"
                        value_ref={inputs.telephone_number}
                        change_handler={handleInputChange}
                        label="Client Phone Number"
                        id_ref="telephone_number"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for links */}
                      <SimpleTextInput
                        placeholder="Enter Client Website"
                        value_ref={inputs.website}
                        change_handler={handleInputChange}
                        label="Client Website"
                        id_ref="website"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter Client Logo URL"
                        value_ref={inputs.logo_url}
                        change_handler={handleInputChange}
                        label="Client Logo URL"
                        id_ref="logo_url"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <ManageClientReferral change_handler={handleInputChange} id_ref="referral" />
                    </div>

                    {successCode !== 200 && (
                      <button
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                      >
                        Add Client
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
