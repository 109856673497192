import { gql } from "@apollo/client";
import { ShipmentsQuery } from "../../generated/graphql";
import { ArrayElement } from "../../types/ArrayElement";

export type ShipmentsQueryItem = ArrayElement<ShipmentsQuery["inventory_shipment"]>;

const SHIPMENTS_QUERY = gql`
  query Shipments {
    inventory_shipment {
      arrival_time
      courier_service {
        courier_service_name
      }
      created_time
      destination_location_id
      estimated_arrival_days
      id
      last_updated
      estimated_shipped_time
      shipped_time
      shipment_status_id
      source_location_id
      source {
        location_name
      }
      destination {
        location_name
      }
      shipment_status {
        shipment_status_name
      }
      shipment_lines_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export default SHIPMENTS_QUERY;
