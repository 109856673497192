import React, { useState, useEffect } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import SimpleButton from "../../components/forms/SimpleButton";
import TitleHeading from "../../components/TitleHeading";
import SimpleSelect from "../../components/forms/SimpleSelect";
import SearchBar from "../../components/search/SearchBar";
import Attachment from "../attachments/Attachment";
import { useAuth0 } from "../../react-auth0-spa";

const UPDATE_PRODUCT_PRICING = gql`
  mutation UpdateProductPricing(
    $product_pricing_id: Int!
    $product_id: Int!
    $currency_code: String!
    $min_qty: Int!
    $user_id: String!
    $unit_price: numeric!
    $notes: String!
  ) {
    update_inventory_product_pricing_by_pk(
      pk_columns: { id: $product_pricing_id }
      _set: {
        currency_code: $currency_code
        min_qty: $min_qty
        notes: $notes
        user_id: $user_id
        product_id: $product_id
        unit_price: $unit_price
      }
    ) {
      id
    }
  }
`;

const GET_PRODUCT_PRICING = gql`
  query GetProductPricing($product_pricing_id: Int!) {
    inventory_product_pricing_by_pk(id: $product_pricing_id) {
      folder {
        id
        user_id
      }
      currency {
        currency_code
        currency_name
      }
      notes
      min_qty
      unit_price
      product {
        id
        case_qty
        base_product {
          id
          base_product_name
          hs_code
        }
      }
    }
  }
`;

const CURRENCY_CODES = gql`
  query CurrencyCodes {
    inventory_currency {
      id: currency_code
      name: currency_code
    }
  }
`;

const PRODUCTS = gql`
  query Products {
    inventory_base_product {
      id
      name: base_product_name
      image_url
    }
  }
`;

const EditProductPricing = () => {
  const [inputs, setInputs] = useState({});
  const [successCode, setSuccessCode] = useState(0);
  const { product_pricing_id } = useParams();
  const [updateProductPricing, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_PRODUCT_PRICING,
    {
      onCompleted() {
        setSuccessCode(200);
      },
    }
  );

  const {
    loading: loading_product_pricing,
    error: error_product_pricing,
    data: product_pricing,
  } = useQuery(GET_PRODUCT_PRICING, {
    variables: {
      product_pricing_id: product_pricing_id,
    },
    fetchPolicy: "network-only",
  });

  const {
    loading: loading_currencies,
    error: error_currencies,
    data: currencies,
    refetch: refetchCurrencies,
  } = useQuery(CURRENCY_CODES, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loading_products,
    error: error_products,
    data: products,
    refetch: refetchProducts,
  } = useQuery(PRODUCTS, {
    fetchPolicy: "network-only",
  });

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    if (loading_product_pricing || error_product_pricing) return;
    if (loading_currencies || error_currencies) return;
    if (loading_products || error_products) return;
    setInputs({
      currency_code: product_pricing.inventory_product_pricing_by_pk.currency.currency_code,
      product_name:
        product_pricing.inventory_product_pricing_by_pk.product.base_product.base_product_name,
      product_id: product_pricing.inventory_product_pricing_by_pk.product.base_product.id,
      min_qty: product_pricing.inventory_product_pricing_by_pk.min_qty,
      unit_price: product_pricing.inventory_product_pricing_by_pk.unit_price,
      notes: product_pricing.inventory_product_pricing_by_pk.notes,
    });
  }, [
    loading_product_pricing,
    error_product_pricing,
    product_pricing,
    loading_currencies,
    error_currencies,
    currencies,
    loading_products,
    error_products,
    products,
  ]);

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    updateProductPricing({
      variables: {
        product_pricing_id: product_pricing_id,
        currency_code: inputs.currency_code,
        unit_price: inputs.unit_price,
        notes: inputs.notes,
        min_qty: inputs.min_qty,
        product_id: inputs.product_id,
        user_id: user_id,
      },
    });
  };

  if (currencies == null || loading_currencies) return null;
  if (products == null || loading_products) return null;
  if (product_pricing == null || loading_product_pricing) return null;
  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label=" Product Pricings &gt; Edit" />
                  {successCode === 200 && (
                    <div
                      className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Success
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        The Client was updated successfully.
                      </span>
                    </div>
                  )}
                  {mutationError && (
                    <div
                      className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Error
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        There was a problem with the data - please check.
                      </span>
                    </div>
                  )}
                </div>
                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select the Currency"
                        value_ref={inputs.currency_code}
                        change_handler={handleInputChange}
                        label="Currency"
                        add_url="/currencies/add"
                        options={currencies.inventory_currency}
                        id_ref="currency_code"
                        selected_id={
                          product_pricing.inventory_product_pricing_by_pk.currency.currency_code
                        }
                        refresh_handler={refetchCurrencies}
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select the Product"
                        value_ref={inputs.product_name}
                        change_handler={handleInputChange}
                        label="Product"
                        refresh_handler={refetchProducts}
                        add_url="/products/add"
                        options={products.inventory_base_product}
                        id_ref="product_id"
                        selected_id={
                          product_pricing.inventory_product_pricing_by_pk.product.base_product.id
                        }
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter min qty"
                        value_ref={inputs.min_qty}
                        required={true}
                        change_handler={handleInputChange}
                        label="min qty"
                        id_ref="min_qty"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter min qty"
                        value_ref={inputs.unit_price}
                        required={true}
                        change_handler={handleInputChange}
                        label="unit cost"
                        id_ref="unit_price"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter notes"
                        value_ref={inputs.notes}
                        change_handler={handleInputChange}
                        label="notes"
                        id_ref="notes"
                      />
                    </div>
                    <SimpleButton label="Update" />
                  </div>
                </form>
                <Attachment folder_id={product_pricing.inventory_product_pricing_by_pk.folder.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductPricing;
