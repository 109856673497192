import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import SideMenu from "../../../components/SideMenu";
import SimpleTextInput from "../../../components/forms/SimpleTextInput";
import TitleHeading from "../../../components/TitleHeading";
import SimpleSelect from "../../../components/forms/SimpleSelect";
import SearchBar from "../../../components/search/SearchBar";
import { useAuth0 } from "../../../react-auth0-spa";

const ADD_PURCHASE_ORDER = gql`
  mutation AddPurchaseOrder(
    $user_id: String!
    $account_id: Int!
    $location_id: Int!
    $purchase_order_reference: String!
  ) {
    insert_inventory_production_order_one(
      object: {
        user_id: $user_id
        supplier_account_id: $account_id
        location_id: $location_id
        production_order_status_id: 1
        production_order_reference: $purchase_order_reference
        folder: { data: { user_id: $user_id } }
      }
    ) {
      id
    }
  }
`;

const SUPPLIERS = gql`
  query GetSuppliers {
    inventory_account(where: { account_roles: { role: { id: { _eq: 2 } } } }) {
      id
      name: local_name
      international_name
    }
  }
`;

const LOCATIONS = gql`
  query GetLocations {
    inventory_location {
      id
      name: location_name
    }
  }
`;

const AddPurchaseOrder = () => {
  const [inputs, setInputs] = useState({});
  const [successCode, setSuccessCode] = useState(0);
  const [addPurchaseOrder, { loading: mutationLoading, error: mutationError }] = useMutation(
    ADD_PURCHASE_ORDER,
    {
      onCompleted(data) {
        setSuccessCode(200);
        window.location = "/supplier-orders/edit/" + data.insert_inventory_production_order_one.id;
      },
    }
  );

  const { loading, error, data: suppliers, refetch: refreshSuppliers } = useQuery(SUPPLIERS, {
    fetchPolicy: "network-only",
  });

  const {
    loading_locations,
    error_locations,
    data: locations,
    refetch: refreshLocations,
  } = useQuery(LOCATIONS, {
    fetchPolicy: "network-only",
  });

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const resetForm = event => {
    if (event) {
      event.preventDefault();
    }
    setInputs({
      name: "",
    });
    setSuccessCode(0);
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    addPurchaseOrder({
      variables: {
        account_id: inputs.account_id,
        user_id: user_id,
        location_id: inputs.location_id,
        purchase_order_reference: inputs.purchase_order_reference,
      },
    });
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (suppliers == null || locations == null || loading || loading_locations) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Purchases"
          selectedSubMenu="add-purchase-order"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Purchases"
          selectedSubMenu="add-purchase-order"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Purchase Orders &gt; Add a new Purchase Order" />

                  {successCode === 200 && (
                    <div
                      className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Success
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        The Purchase Order was added successfully.
                      </span>
                    </div>
                  )}
                  {mutationError && (
                    <div
                      className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Error
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        There was a problem with the data - please check.
                      </span>
                    </div>
                  )}
                </div>
                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter an Order Reference"
                        value_ref={inputs.purchase_order_reference}
                        required={true}
                        change_handler={handleInputChange}
                        label="Order Reference"
                        id_ref="purchase_order_reference"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select the Supplier"
                        change_handler={handleInputChange}
                        refresh_handler={refreshSuppliers}
                        add_url="/suppliers/add"
                        label="Suppliers"
                        options={suppliers.inventory_account}
                        id_ref="account_id"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select the Location"
                        change_handler={handleInputChange}
                        refresh_handler={refreshLocations}
                        add_url="/locations/add"
                        label="Location"
                        options={locations.inventory_location}
                        id_ref="location_id"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    {successCode !== 200 && (
                      <button
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                      >
                        Create
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPurchaseOrder;
