import { gql } from "@apollo/client";

//  update shipment
// keep status in sync between shipment and associated shipment lines

const UPDATE_SHIPMENT_MUTATION = gql`
  mutation UpdateShipment(
    $id: Int!
    $user_id: String!
    $destination_location_id: Int!
    $courier_service_id: Int!
    $estimated_arrival_days: Int!
    $arrival_time: timestamptz
    $estimated_shipped_time: timestamptz
    $shipped_time: timestamptz
    $shipment_status_id: Int!
    $shipment_line_status_id: Int!
    $updated_product_stock: [inventory_product_stock_insert_input!]!
  ) {
    update_inventory_shipment_by_pk(
      pk_columns: { id: $id }
      _set: {
        user_id: $user_id
        destination_location_id: $destination_location_id
        courier_service_id: $courier_service_id
        shipment_status_id: $shipment_status_id
        shipped_time: $shipped_time
        estimated_shipped_time: $estimated_shipped_time
        estimated_arrival_days: $estimated_arrival_days
        arrival_time: $arrival_time
      }
    ) {
      id
    }
    insert_inventory_product_stock(
      objects: $updated_product_stock
      on_conflict: { constraint: product_stock_pkey, update_columns: [qty, user_id] }
    ) {
      returning {
        id
        qty
        initial_qty
        stock_status {
          id
          name: stock_status_name
        }
        location {
          id
          location_name
        }
      }
    }
    update_inventory_shipment_line(
      where: { shipment_id: { _eq: $id } }
      _set: {
        shipment_line_status_id: $shipment_line_status_id
        destination_location_id: $destination_location_id
      }
    ) {
      returning {
        id
        shipment_line_status {
          id
          name: shipment_line_status_name
        }
      }
    }
  }
`;

export default UPDATE_SHIPMENT_MUTATION;
