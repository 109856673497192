import React from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../../components/SideMenu";
import TitleHeading from "../../../components/TitleHeading";
import SimpleLinkButton from "../../../components/SimpleLinkButton";
import SearchBar from "../../../components/search/SearchBar";
import Table, { TableHead, TD, TH, TR } from "../../../components/Table";

const SUPPLIER_QUOTES = gql`
  query SupplierQuotes {
    inventory_supplier_quote {
      id
      currency_code
      unit_cost
      min_qty
      location {
        id
        name: location_name
      }
      valid_from
      valid_to
      supplier_product {
        id
        supplier_product_name
        supplier_product_reference
        product {
          id
          case_qty
          base_product {
            base_product_name
            base_product_description
            image_url
          }
        }
      }
    }
  }
`;

const SupplierQuotes = () => {
  const { loading: loadingQuery, error: errorQuery, data: dataQuery } = useQuery(SUPPLIER_QUOTES, {
    fetchPolicy: "network-only",
  });

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateSupplierQuote = supplier_quote_id => event => {
    event.preventDefault();
    openInNewTab(`supplier-quotes/edit/${supplier_quote_id}`);
  };

  if (dataQuery == null || loadingQuery || errorQuery) return null;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="Purchases"
            selectedSubMenu="all-supplier-quotes"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="Purchases"
            selectedSubMenu="all-supplier-quotes"
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Supplier Quotes" />
                  <SimpleLinkButton
                    label="Add new Supplier Quote"
                    keyboard_shortcut="n"
                    new_tab
                    url="/supplier-quotes/add"
                    id_ref="add_supplier_order"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>id</TH>
                        <TH>product image</TH>
                        <TH>supplier reference</TH>
                        <TH>product name</TH>
                        <TH>location</TH>
                        <TH>case qty</TH>
                        <TH>min qty</TH>
                        <TH>unit cost</TH>
                        <TH>valid from</TH>
                        <TH>valid to</TH>
                      </TableHead>
                      <tbody>
                        {dataQuery.inventory_supplier_quote.map(inventory_supplier_quote => {
                          return (
                            <TR key="inventory_account.id">
                              <TD>{inventory_supplier_quote.id}</TD>
                              <TD>
                                <img
                                  alt=""
                                  className="object-cover w-10 h-10 "
                                  src={
                                    inventory_supplier_quote.supplier_product.product.base_product
                                      .image_url
                                  }
                                />
                              </TD>
                              <TD width={3} isEmphasised>
                                {inventory_supplier_quote.supplier_product
                                  .supplier_product_reference +
                                  " (" +
                                  inventory_supplier_quote.supplier_product.supplier_product_name +
                                  ")"}
                              </TD>
                              <TD width={3} isEmphasised>
                                {inventory_supplier_quote.supplier_product.product.base_product
                                  .base_product_name +
                                  "(" +
                                  inventory_supplier_quote.supplier_product.product.base_product
                                    .base_product_description +
                                  ")"}
                              </TD>
                              <TD className="w-2/12 p-4" isEmphasised>
                                {inventory_supplier_quote.location.name}
                              </TD>
                              <TD isEmphasised>
                                {inventory_supplier_quote.supplier_product.product.case_qty}
                              </TD>
                              <TD isEmphasised>{inventory_supplier_quote.min_qty}</TD>
                              <TD isEmphasised>
                                {inventory_supplier_quote.currency_code}{" "}
                                {inventory_supplier_quote.unit_price}
                              </TD>
                              <TD className="w-2/12  p-4" isEmphasised>
                                {inventory_supplier_quote.valid_from.substr(0, 10)}
                              </TD>
                              <TD className="w-2/12  p-4" isEmphasised>
                                {inventory_supplier_quote.valid_to.substr(0, 10)}
                                <button
                                  className="float-right"
                                  onClick={updateSupplierQuote(inventory_supplier_quote.id)}
                                >
                                  <i class="fas fa-edit"></i>
                                </button>{" "}
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierQuotes;
