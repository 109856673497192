import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import TitleHeading from "../../components/TitleHeading";
import SearchBar from "../../components/search/SearchBar";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";

const GET_SUPPLIER_PRODUCT = gql`
  query GetSupplierProduct {
    inventory_supplier_product {
      id
      account {
        id
        local_name
        account_roles(where: { role: { role_name: { _eq: "Supplier" } } }) {
          role {
            role_name
          }
        }
      }
      product {
        id
        base_product {
          base_product_name
          base_product_description
          image_url
        }
        width_mm
        length_mm
        height_mm
        weight_grams
        case_qty
      }
      supplier_product_name
      supplier_product_reference
    }
  }
`;

const SupplierProduct = () => {
  const [showResult, setShowResult] = useState(false);
  const { loading, error, data } = useQuery(GET_SUPPLIER_PRODUCT, {
    fetchPolicy: "network-only",
  });

  if (data == null || loading) return null;
  console.log(data);

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="SKUs"
            selectedSubMenu="suppliers-product"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="SKUs"
            selectedSubMenu="suppliers-product"
          />
        </div>
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Supplier SKU Product" />
                  <SimpleLinkButton
                    label="Add new Supplier SKU"
                    keyboard_shortcut="n"
                    new_tab
                    url="/products/supplier-product/add"
                    id_ref="add_suppliers_product_sku"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>id</TH>
                        <TH>image </TH>
                        <TH>supplier</TH>
                        <TH>product</TH>
                        <TH>supplier product</TH>
                        <TH>product description</TH>
                        <TH />
                      </TableHead>

                      <tbody>
                        {data.inventory_supplier_product.map(supplier_product => {
                          return (
                            <TR key={supplier_product.id}>
                              <TD>{supplier_product.id}</TD>
                              <TD>
                                <img
                                  alt=""
                                  className="object-cover w-104 h-10 "
                                  src={supplier_product.product.base_product.image_url}
                                />
                              </TD>
                              <TD width={2}>
                                <h2 className="font-semibold text-blue-700 product-result-truncate">
                                  {supplier_product.account.local_name}
                                </h2>
                              </TD>
                              <TD width={3}>
                                <p>
                                  {supplier_product.product.base_product.base_product_name} (x
                                  {supplier_product.product.case_qty})
                                </p>
                                <p className="text-xs italic">
                                  {supplier_product.product.base_product.base_product_description}{" "}
                                  (Case total: {supplier_product.product.weight_grams}g)
                                </p>
                              </TD>

                              <TD width={3}>
                                <p>{supplier_product.supplier_product_name}</p>
                                <p className="text-xs italic">
                                  {supplier_product.supplier_product_reference}
                                </p>
                              </TD>

                              <TD width={2}>
                                {supplier_product.product.width_mm} mm *{" "}
                                {supplier_product.product.length_mm} mm *{" "}
                                {supplier_product.product.height_mm} mm
                              </TD>
                              <TD>
                                <button
                                  className="float-right"
                                  // onClick={updateClient(supplier_product.id)}
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierProduct;
