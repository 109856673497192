import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import { useParams } from "react-router-dom";
import TitleHeading from "../../components/TitleHeading";
import QtyQuoteSelector from "../../components/forms/QtyQuoteSelector";
import { useHotkeys } from "react-hotkeys-hook";
import EditClientOrderForm from "../../components/forms/EditClientOrderForm";
import SearchBar from "../../components/search/SearchBar";
import { useAuth0 } from "../../react-auth0-spa";
import Attachment from "../attachments/Attachment";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";
import MutationSuccess from "../../components/MutationSuccess";
import MutationError from "../../components/MutationError";

const DELETE_CLIENT_ORDER_LINE = gql`
  mutation DeleteClientOrderLine($line_id: Int!) {
    delete_inventory_client_order_line_by_pk(id: $line_id) {
      id
    }
  }
`;

const CLIENT_ORDER_LINES = gql`
  query GetClientOrderLines($client_order_id: Int!) {
    inventory_client_order_line(where: { client_order_id: { _eq: $client_order_id } }) {
      client_order_line_type_id
      id
      product {
        base_product {
          base_product_name
          image_url
        }
        case_qty
      }
      client_order_line_type {
        line_type_name
      }
      requested_qty
      unit_price
      product_pricing_id
      product_pricing {
        unit_price
        currency_code
      }
    }
  }
`;

const GET_CLIENT_ORDER = gql`
  query GetClientOrder($id: Int!) {
    inventory_client_order_by_pk(id: $id) {
      client_order_reference
      client_order_status {
        description
        order_status_name
      }
      client_order_type {
        order_type_name
      }
      destination {
        location_name
      }
      folder {
        id
        user_id
        attachment_folders {
          folder_id
          attachment {
            id
            attachment_uuid
            notes
            original_filename
            last_updated
          }
        }
      }
    }
  }
`;

const PRODUCT_PRICINGS = gql`
  query ProductPricings {
    inventory_product_pricing {
      id
      currency_code
      unit_price
      min_qty
      product {
        id
        case_qty
        base_product {
          base_product_name
          image_url
        }
      }
    }
  }
`;

const CLIENTS = gql`
  query GetClients {
    inventory_account(where: { account_roles: { role: { id: { _eq: 3 } } } }) {
      id
      name: local_name
    }
  }
`;

const LOCATIONS = gql`
  query GetLocations {
    inventory_location {
      id
      name: location_name
    }
  }
`;

const AddClientOrderLines = () => {
  const { loading: loadingAuth0, user, getTokenSilently } = useAuth0();
  const [inputs, setInputs] = useState({});
  const [showQuotes, setShowQuotes] = useState(false);
  const [successCode, setSuccessCode] = useState(0);
  const { order_id } = useParams();

  const {
    loading: loading_product_pricings,
    error: error_product_pricings,
    data: data_product_pricings,
    refetch: refresh_product_pricings,
  } = useQuery(PRODUCT_PRICINGS, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loading_client_order,
    error: error_client_order,
    data: data_client_order,
    refetch: refresh_client_order,
  } = useQuery(GET_CLIENT_ORDER, {
    fetchPolicy: "network-only",
    variables: {
      id: order_id,
    },
  });

  const [
    deleteClientOrderLine,
    { loading: mutationLoadingDeleteClientOrderLine, error: mutationErrorDeleteClientOrderLine },
  ] = useMutation(DELETE_CLIENT_ORDER_LINE, {
    onCompleted() {
      refetch_lines();
    },
  });

  const handleDelete = (event, line_id) => {
    if (event) {
      event.preventDefault();
    }
    deleteClientOrderLine({
      variables: {
        line_id: line_id,
      },
    });
  };

  useHotkeys("n", (e, h) => {
    console.log(e);
    if (e.target.localName !== "input") {
      setShowQuotes(true);
      window.location = "#table_product_pricings";
    }
  });

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateClientQuote = product_pricing_id => event => {
    event.preventDefault();
    openInNewTab(`/product-pricings/edit/${product_pricing_id}`);
  };

  const {
    loading: loading_lines,
    error: error_lines,
    data: client_order_lines,
    refetch: refetch_lines,
  } = useQuery(CLIENT_ORDER_LINES, {
    variables: {
      client_order_id: order_id,
    },
    fetchPolicy: "network-only",
  });

  const { loading, error, data: clients } = useQuery(CLIENTS, {
    fetchPolicy: "network-only",
  });

  const { loading_locations, error_locations, data: locations } = useQuery(LOCATIONS, {
    fetchPolicy: "network-only",
  });

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const resetForm = event => {
    if (event) {
      event.preventDefault();
    }
    setInputs({
      name: "",
    });
    setSuccessCode(0);
  };

  if (
    clients == null ||
    data_product_pricings == null ||
    locations == null ||
    loading ||
    loading_locations
  )
    return null;

  if (data_client_order == null || loading_client_order) return null;

  const formatQty = qty => {
    return qty.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Sales"
          selectedSubMenu="add-client-order"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Sales"
          selectedSubMenu="add-client-order"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label={"Client Order #" + order_id} />

                  {successCode === 200 && (
                    <MutationSuccess message="The Client Order was updated successfully." />
                  )}
                  {mutationErrorDeleteClientOrderLine && (
                    <MutationError error={mutationErrorDeleteClientOrderLine} />
                  )}
                </div>

                <EditClientOrderForm order_id={order_id} setSuccessCode={setSuccessCode} />

                <form className="w-full flex flex-col lg:flex-row">
                  <div className="w-full flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>Line ID</TH>
                        <TH>product</TH>
                        <TH>case qty</TH>
                        <TH>qty</TH>
                        <TH>total unit qty</TH>
                        <TH>total price</TH>
                        <TH></TH>
                      </TableHead>

                      <tbody>
                        {client_order_lines &&
                          client_order_lines.inventory_client_order_line.map(
                            inventory_client_order_line => {
                              return (
                                <TR key="inventory_client_order_line.id">
                                  <TD>{inventory_client_order_line.id}</TD>
                                  <TD width={3}>
                                    <h3 className="inline font-semibold text-blue-700 product-result-truncate">
                                      <img
                                        alt=""
                                        className="float-left mr-4 object-cover w-10 h-10 "
                                        src={
                                          inventory_client_order_line.product.base_product.image_url
                                        }
                                      />
                                      {
                                        inventory_client_order_line.product.base_product
                                          .base_product_name
                                      }
                                    </h3>
                                  </TD>

                                  <TD>
                                    <h2 className="font-semibold text-blue-700 product-result-truncate">
                                      {formatQty(inventory_client_order_line)}
                                    </h2>
                                  </TD>
                                  <TD>
                                    <h2 className="font-semibold text-blue-700 product-result-truncate">
                                      {formatQty(inventory_client_order_line.requested_qty)}
                                    </h2>
                                  </TD>
                                  <TD width={2}>
                                    <h2 className="font-semibold text-blue-700 product-result-truncate">
                                      {formatQty(
                                        inventory_client_order_line.product.case_qty *
                                          inventory_client_order_line.requested_qty
                                      )}
                                    </h2>
                                  </TD>
                                  <TD width={3}>
                                    <h2 className="font-semibold text-blue-700 product-result-truncate">
                                      {inventory_client_order_line.product_pricing.currency_code}{" "}
                                      {formatQty(
                                        inventory_client_order_line.product.case_qty *
                                          inventory_client_order_line.requested_qty *
                                          inventory_client_order_line.unit_price
                                      )}
                                    </h2>
                                  </TD>
                                  <TD isFullWidth>
                                    <button
                                      className="flex-shrink-0 bg-red-500 hover:bg-red-700 border-red-500 hover:border-red-700 text-sm border-4 text-white py-1 px-2 rounded"
                                      type="button"
                                      onClick={event =>
                                        handleDelete(event, inventory_client_order_line.id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </TD>
                                </TR>
                              );
                            }
                          )}
                        <TR>
                          <TD colSpan={7} isFullWidth>
                            <button
                              className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                              type="button"
                              onClick={() => {
                                setShowQuotes(true);
                                window.location = "#table_product_pricings";
                              }}
                            >
                              Add a new Line item{" "}
                              <span className="p-1 pt-0 ml-1 rounded-full bg-teal-700">n</span>
                            </button>
                          </TD>
                        </TR>
                      </tbody>
                    </Table>
                  </div>
                </form>

                {showQuotes && (
                  <>
                    <h2 className="text-2xl mt-8  xl:text-2xl mb-4 text-gray-900 font-bold">
                      Add from a Product Pricing{" "}
                      <span className="text-sm">
                        <button
                          onClick={event => {
                            event.preventDefault();
                            refresh_product_pricings();
                          }}
                        >
                          <i className="fas fa-sync-alt mx-2"></i>
                        </button>{" "}
                        <a target="_blank" rel="noopener noreferrer" href="/product-pricings/add">
                          <i className="fas fa-plus-circle"></i>
                        </a>
                      </span>
                    </h2>

                    <form className="w-full flex flex-col lg:flex-row">
                      <div className="w-full flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                        <Table id="table_product_pricings">
                          <TableHead>
                            <TH>quote id</TH>
                            <TH>product image</TH>
                            <TH>product name</TH>
                            <TH>case qty</TH>
                            <TH>min cases</TH>
                            <TH>quote price</TH>
                            <TH># cases</TH>
                            <TH>unit price</TH>
                            <TH></TH>
                          </TableHead>

                          <tbody>
                            {data_product_pricings.inventory_product_pricing.map(
                              inventory_product_pricing => {
                                return (
                                  <TR key={inventory_product_pricing.id}>
                                    <TD>
                                      {inventory_product_pricing.id}
                                      <button
                                        className="ml-2"
                                        onClick={updateClientQuote(inventory_product_pricing.id)}
                                      >
                                        <i class="fas fa-edit"></i>
                                      </button>{" "}
                                    </TD>
                                    <TD>
                                      <img
                                        alt=""
                                        className="object-cover w-10 h-10 "
                                        src={
                                          inventory_product_pricing.product.base_product.image_url
                                        }
                                      />
                                    </TD>
                                    <td className="w-3/12  p-4">
                                      <h2 className="font-semibold product-result-truncate">
                                        {
                                          inventory_product_pricing.product.base_product
                                            .base_product_name
                                        }
                                      </h2>
                                    </td>
                                    <TD>
                                      <h2 className="font-semibold  product-result-truncate">
                                        {inventory_product_pricing.product.case_qty.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </h2>
                                    </TD>
                                    <TD>
                                      <h2 className="font-semibold product-result-truncate">
                                        {inventory_product_pricing.min_qty.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </h2>
                                    </TD>
                                    <TD>
                                      <h2 className="font-semibold product-result-truncate">
                                        {inventory_product_pricing.currency_code}{" "}
                                        {inventory_product_pricing.unit_price.toLocaleString(
                                          undefined,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </h2>
                                    </TD>

                                    <QtyQuoteSelector
                                      unit_price={inventory_product_pricing.unit_price}
                                      hide_callback={setShowQuotes}
                                      refresh_callback={refetch_lines}
                                      product_id={inventory_product_pricing.product.id}
                                      order_id={order_id}
                                      quote_id={inventory_product_pricing.id}
                                      minQty={inventory_product_pricing.min_qty}
                                      maxQty={10000000000000}
                                    />
                                  </TR>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </form>
                  </>
                )}
                {data_client_order.inventory_client_order_by_pk.folder && (
                  <Attachment
                    folder_id={data_client_order.inventory_client_order_by_pk.folder.id}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClientOrderLines;
