import { ShipmentQuery, Inventory_Product_Stock_Insert_Input } from "../../../../generated/graphql";
import { StockStatus } from "../../../../types/ProductStockStatus";
import { ShipmentStatus } from "../../../../types/ShipmentStatus";
import { stockStatusNameToIdMap } from "../../../../utils/stockStatusNameToIdMap";
import { EditShipmentFormValues } from "../index";

const PRODUCT_STOCK_DUE_IN_STATUS_ID = stockStatusNameToIdMap[StockStatus.DUE_IN];
const PRODUCT_STOCK_AVAILABLE_STATUS_ID = stockStatusNameToIdMap[StockStatus.AVAILABLE];

type Shipment = Exclude<ShipmentQuery["inventory_shipment_by_pk"], undefined | null>;

interface ProductStockByStatus {
  [statusId: string]: Inventory_Product_Stock_Insert_Input[];
}

export const sortProductStocksByStatus = (shipment: Shipment) => {
  return shipment.shipment_lines.reduce(
    (stocksByStatus: ProductStockByStatus, shipment_line) => {
      const updatedTotals = { ...stocksByStatus };

      shipment_line.product_stocks.forEach(({ __typename, stock_status, ...stock }) => {
        const stockStatus = stock_status.stock_status_name;
        if (!updatedTotals[stockStatus]) {
          updatedTotals[stockStatus] = [];
        }

        updatedTotals[stockStatus].push(stock);
      });

      return updatedTotals;
    },
    {
      [StockStatus.DUE_IN]: [],
      [StockStatus.AVAILABLE]: [],
      [StockStatus.RESERVED]: [],
    }
  );
};

interface UpdatedProductStocks {
  dueIn: Inventory_Product_Stock_Insert_Input[];
  reserved: Inventory_Product_Stock_Insert_Input[];
  available: Inventory_Product_Stock_Insert_Input[];
}

// function to generate the updated product stock objects in response to the status of the shipment / destination changing
// these objects will be passed to the update shipment mutation and upserted into the database
export const generateUpdatedStockOnEdit = (
  shipment: Shipment,
  newStatus: ShipmentStatus,
  updatedShipmentFields: EditShipmentFormValues,
  user_id: string
): UpdatedProductStocks => {
  const prevStatus = shipment.shipment_status.shipment_status_name;

  // shipments that are already cancelled or completed do not affect stock
  if (prevStatus === ShipmentStatus.CANCELLED || prevStatus === ShipmentStatus.COMPLETE) {
    return { dueIn: [], reserved: [], available: [] };
  }

  if (prevStatus === newStatus) {
    return { dueIn: [], reserved: [], available: [] };
  }

  const createStockAtDestination = (stock_status_id: number) =>
    shipment.shipment_lines.map(shipment_line => {
      return {
        product_id: shipment_line.source_product_stock.product_id,
        stock_status_id: stock_status_id,
        location_id: updatedShipmentFields.destination_location_id,
        account_id: shipment_line.source_product_stock.account_id, // owner is still the original seller until shipment destination === client order desstination and invoice is marked as complete
        initial_qty: shipment_line.reserved_qty,
        qty: shipment_line.reserved_qty,
        user_id,
        batch_id: shipment_line.product_stocks[0]?.batch_id,
        source_shipment_line_id: shipment_line.id,
      };
    });

  // get existng product stock associated with each shipping line
  const currentProductStock = sortProductStocksByStatus(shipment);
  const currentDueInStock = currentProductStock[StockStatus.DUE_IN];
  const currentReservedStock = currentProductStock[StockStatus.RESERVED];

  if (newStatus === ShipmentStatus.DISPATCHED) {
    const dueIn = createStockAtDestination(PRODUCT_STOCK_DUE_IN_STATUS_ID);
    return { dueIn, reserved: [], available: [] };
  } else if (newStatus === ShipmentStatus.COMPLETE) {
    const reserved = currentReservedStock.map(stock => ({ ...stock, qty: 0, user_id }));
    const dueIn = currentDueInStock.map(stock => ({ ...stock, qty: 0, user_id }));
    const available = createStockAtDestination(PRODUCT_STOCK_AVAILABLE_STATUS_ID);
    return { reserved, dueIn, available };
  } else if (prevStatus === ShipmentStatus.DISPATCHED) {
    // if shipment status changes back from dispatched to new / error then  due in product stock should be set to 0
    const dueIn = currentDueInStock.map(stock => ({ ...stock, qty: 0, user_id }));
    return { dueIn, reserved: [], available: [] };
  }

  return { dueIn: [], reserved: [], available: [] };
};
