import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { HTMLProps } from "react";
import ReactTooltip from "react-tooltip";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faLink,
  faPen,
  faPhone,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { ClientDetailAccount } from "../../graphql/queries/clientDetail";
import { SupplierDetailAccount } from "../../graphql/queries/supplierDetail";

interface ToolbarItemProps {
  accountId: number;
  accountType: "supplier" | "client";
  label: string;
  icon: IconDefinition;
  linkProps: HTMLProps<HTMLAnchorElement>;
  value: string;
  tooltipLabel: string;
  isDataMissing?: boolean;
  useReactRouter?: boolean;
}

const ToolbarItem = ({
  accountId,
  accountType,
  label,
  icon,
  value,
  linkProps,
  tooltipLabel,
}: ToolbarItemProps) => {
  const editUrl = `/${accountType}/edit/${accountId}`;

  return (
    <div>
      <a
        className={`flex flex-col justify-center items-center text-gray-${
          value ? 700 : 500
        } hover:text-blue-700`}
        data-for={`${label}-toolip`}
        data-tip={tooltipLabel}
        {...(value ? linkProps : { target: "_blank", href: editUrl })}
      >
        <FontAwesomeIcon icon={icon} />

        <div className="mt-1 text-sm text-center">{label}</div>
      </a>
      <ReactTooltip id={`${label}-toolip`} place="top" effect="solid" multiline={true} />
    </div>
  );
};

interface AccountToolbarProps {
  account: ClientDetailAccount | SupplierDetailAccount;
  accountType: "client" | "supplier";
}

const AccountToolbar = ({ account, accountType }: AccountToolbarProps) => {
  return (
    <div className="flex justify-around it gap-2 border rounded p-4 lg:w-6/12 mt-2 mb-4">
      <ToolbarItem
        accountType={accountType}
        accountId={account.id}
        label="Website"
        value={account.website}
        tooltipLabel={account.website ? `Visit ${account.website}` : "Add Website"}
        icon={faLink}
        linkProps={{
          target: "_blank",
          href: account.website,
        }}
      />
      <ToolbarItem
        accountType={accountType}
        accountId={account.id}
        label="Email"
        value={account.email}
        tooltipLabel={account.email ? `Email ${account.email}` : "Add Email"}
        icon={faEnvelope}
        linkProps={{
          href: `mailto:${account.email}`,
        }}
      />
      <ToolbarItem
        accountType={accountType}
        accountId={account.id}
        label="Phone"
        value={account.telephone_number}
        tooltipLabel={
          account.telephone_number ? `Call ${account.telephone_number}` : "Add Phone Number"
        }
        icon={faPhone}
        isDataMissing={!account.telephone_number}
        linkProps={{
          href: `tel:${account.telephone_number}`,
        }}
      />
      <ToolbarItem
        accountType={accountType}
        accountId={account.id}
        label="Meet"
        value="Add Meeting note"
        tooltipLabel="Add Meeting note"
        icon={faStickyNote}
        linkProps={{
          target: "_blank",
          href: `/meetings/add/${account.id}?account_type=${accountType}`,
        }}
      />
      <ToolbarItem
        accountType={accountType}
        accountId={account.id}
        label="Edit"
        value="Edit Client"
        tooltipLabel="Edit Client"
        icon={faPen}
        linkProps={{
          target: "_blank",
          href: `/${accountType}/edit/${account.id}`,
        }}
      />
    </div>
  );
};

export default AccountToolbar;
