import React from "react";

interface SimpleteDateInputProps {
  label?: string;
  value_ref: string;
  id_ref: string;
  change_handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  required?: boolean;
  two_column?: boolean;
  isDisabled?: boolean;
}

const wrapperStyles = ({ two_column }: Partial<SimpleteDateInputProps>) =>
  ["w-full mb-2", "mb-2 md:mb-0", two_column && "md:w-1/2 mb-4 lg:mb-6"].join(" ");

const inputStyles = ({ isDisabled }: Partial<SimpleteDateInputProps>) =>
  [
    "appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight",
    "focus:outline-none focus:bg-white",
    isDisabled && "bg-gray-300 opacity-50",
  ].join(" ");

const SimpleDateInput = ({
  label,
  value_ref,
  change_handler,
  id_ref,
  placeholder,
  required,
  two_column,
  isDisabled,
}: SimpleteDateInputProps) => {
  return (
    <div className={wrapperStyles({ two_column })}>
      {label === "" ? (
        <></>
      ) : (
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor={id_ref}
        >
          {label} {required ? `*` : null}
        </label>
      )}
      <input
        className={inputStyles({ isDisabled })}
        id={id_ref}
        name={id_ref}
        type="datetime-local"
        placeholder={placeholder}
        onChange={change_handler}
        value={value_ref ? value_ref.split("+")[0] : value_ref}
        required={required}
        disabled={isDisabled}
      />
    </div>
  );
};

export default SimpleDateInput;
