import React from "react";
import AddShipmentLineSelector from "../../../components/forms/EditShipmentForm/AddShipmentLineSelector";
import { ClientOrderLineQueryItem } from "../../../graphql/queries/clientOrderLines";
import { ShipmentQuery } from "../../../generated/graphql";
import { TD, TR } from "../../../components/Table";

interface AddShipmentLineRow {
  user_id: string;
  client_order_line: ClientOrderLineQueryItem;
  shipment: ShipmentQuery["inventory_shipment_by_pk"];
}

const AddShipmentLineRow = ({ client_order_line, shipment }: AddShipmentLineRow) => {
  return (
    <TR key={`client_order_line-${client_order_line.id}`}>
      <TD>{client_order_line.id}</TD>
      <TD>{client_order_line.client_order.client_account.international_name}</TD>
      <TD>
        <h3 className="inline font-semibold text-blue-700 product-result-truncate">
          <img
            alt={client_order_line?.product?.base_product?.base_product_description}
            className="float-left mr-4 object-cover w-10 h-10 "
            src={client_order_line?.product?.base_product.image_url}
          />
          {client_order_line?.product?.base_product.base_product_name}
        </h3>
      </TD>
      <TD>
        <h2 className="font-semibold text-blue-700 product-result-truncate">
          {client_order_line?.product?.case_qty.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </h2>
      </TD>
      <TD>
        <h2 className="font-semibold text-blue-700 product-result-truncate">
          {client_order_line.requested_qty.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </h2>
      </TD>
      <TD>
        <h2 className="font-semibold text-blue-700 product-result-truncate">
          {client_order_line?.outstanding_qty?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        </h2>
      </TD>
      <TD>{client_order_line?.client_order.destination.name}</TD>
      {shipment && (
        <AddShipmentLineSelector
          shipment_id={shipment?.id!}
          shipment={shipment}
          client_order_line={client_order_line}
          hide_callback={() => {}}
        />
      )}
    </TR>
  );
};

export default AddShipmentLineRow;
