import React from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SearchBar from "../../components/search/SearchBar";
import TitleHeading from "../../components/TitleHeading";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import { Link } from "react-router-dom";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";

const GET_Base_SKUs = gql`
  query GetBaseSKUs {
    inventory_base_product {
      id
      hs_code
      image_url
      base_product_name
      base_product_description
    }
  }
`;

const BaseSKUs = () => {
  console.log("Hello BASESKUS");

  const { loading: loadingSKUs, error: errorSKUs, data } = useQuery(GET_Base_SKUs, {
    fetchPolicy: "network-only",
  });

  console.log(data, loadingSKUs, errorSKUs);

  if (data == null || loadingSKUs || errorSKUs) return null;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="SKUs"
            selectedSubMenu="all-base-skus"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="SKUs"
            selectedSubMenu="all-base-skus"
          />
        </div>
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="All Base Products" />
                  <SimpleLinkButton
                    label="Add Base Product"
                    keyboard_shortcut="n"
                    new_tab
                    url="/products/base/add"
                    id_ref="add_base_product"
                  />
                  <SimpleLinkButton
                    label="Add Product Carton"
                    keyboard_shortcut="p"
                    new_tab
                    url="/products/add"
                    id_ref="add_product"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>id</TH>
                        <TH>image</TH>
                        <TH>name</TH>
                        <TH>description</TH>
                        <TH>hs_code</TH>
                      </TableHead>

                      <tbody>
                        {data.inventory_base_product.map(sku => {
                          return (
                            <TR key={sku.id}>
                              <TD>{sku.id}</TD>
                              <TD width={2}>
                                <img
                                  className="w-10"
                                  src={sku.image_url}
                                  alt={sku.base_product_name}
                                />
                              </TD>
                              <TD width={3}>{sku.base_product_name}</TD>
                              <TD width={3}>{sku.base_product_description}</TD>
                              <TD width={3}>
                                {sku.hs_code}
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="float-right"
                                  to={`/product/base/edit/${sku.id}`}
                                  aria-label={`edit-sku-${sku.id}`}
                                >
                                  <img width="18px" src="/edit-outline.svg" alt="edit-icon" />
                                </Link>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseSKUs;
