import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import SearchResults from "../SearchResults";
import { useHotkeys } from "react-hotkeys-hook";
import { useAuth0 } from "../../react-auth0-spa";
import { DelayInput } from "react-delay-input";

const SEARCH_RESULTS = gql`
  query GeneralSearch($search: String!) {
    inventory_client_order(where: { client_order_reference: { _ilike: $search } }) {
      id
      client_order_reference
      client_account {
        name: local_name
      }
      date: last_updated
    }
    inventory_client: inventory_account(
      where: {
        _or: [{ local_name: { _ilike: $search } }, { international_name: { _ilike: $search } }]
        account_roles: { role: { id: { _eq: 3 } } }
      }
    ) {
      id
      international_name
      local_name
      account_status {
        account_status_name
      }
      date: last_updated
    }
    inventory_supplier: inventory_account(
      where: {
        _or: [{ local_name: { _ilike: $search } }, { international_name: { _ilike: $search } }]
        account_roles: { role: { id: { _eq: 2 } } }
      }
    ) {
      id
      local_name
      international_name
      account_status {
        account_status_name
      }
      date: last_updated
    }
    inventory_location(where: { location_name: { _ilike: $search } }) {
      id
      location_name
      address {
        country_code
      }
      account {
        local_name
      }
      date: last_updated
    }
    inventory_base_product(where: { base_product_name: { _ilike: $search } }) {
      id
      base_product_name
      base_product_description
      image_url
      date: last_updated
    }
    inventory_product(where: { base_product: { base_product_name: { _ilike: $search } } }) {
      id
      case_qty
      base_product {
        base_product_name
        base_product_description
        image_url
      }
      date: last_updated
    }
    inventory_product_pricing(where: { notes: { _ilike: $search } }) {
      id
    }
  }
`;

const SearchBar = props => {
  const [invoices, setInvoices] = useState([]);
  const [inputs, setInputs] = useState({});
  const { getTokenSilently } = useAuth0();
  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    async function get_invoices() {
      try {
        const token = await getTokenSilently();

        const response = await fetch(
          process.env.REACT_APP_API_ORIGIN
            ? `${process.env.REACT_APP_API_ORIGIN}/xero-api/invoices`
            : `${window.REACT_APP_API_ORIGIN}/xero-api/invoices`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            mode: "cors",
            method: "GET",
          }
        );

        const responseData = await response.json();
        console.log("responseData");
        console.log(responseData);
        setInvoices(responseData.Invoices);
      } catch (error) {
        console.error(error);
      }
    }
    get_invoices();
  }, []);

  const handleSearch = event => {
    setInputs({ search: event.target.value });
    // if (event.target.value !== "") {
    //   refetch();
    // }
  };

  const toggleBar = () => {
    console.log(showBar);
    setShowBar(showBar => !showBar);
  };

  useHotkeys(
    "s",
    (e, h) => {
      console.log(e);
      if (e.target.localName !== "input") toggleBar();
    },
    { keyup: true }
  );

  useHotkeys(
    "Escape",
    () => {
      setShowBar(false);
    },
    { filter: () => true, keyup: true }
  );

  const { loading, error, data, refetch } = useQuery(SEARCH_RESULTS, {
    fetchPolicy: "network-only",
    variables: {
      search: "%" + inputs.search + "%",
    },
  });

  const search_invoices = (invoice, search) => {
    console.log(invoice);
    if (invoice.Reference.toLowerCase().includes(search)) return true;
    if (invoice.Contact.Name.toLowerCase().includes(search)) return true;
    if (invoice.InvoiceNumber.toLowerCase().includes(search)) return true;
    if (invoice.InvoiceID.toLowerCase().includes(search)) return true;
  };

  useEffect(() => {
    if (inputs.search !== "") {
      console.log(inputs.search);
      refetch();
    }
  }, [inputs.search]);

  console.log(showBar);

  if (showBar) {
    return (
      <>
        <div className="absolute w-screen h-screen bg-white opacity-75 z-100"></div>
        <div>
          <div className="fixed w-6/12 flex flex-col z-100">
            {" "}
            <DelayInput
              minLength={2}
              delayTimeout={300}
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mt-4 leading-tight focus:outline-none focus:bg-white"
              type="text"
              autoFocus
              placeholder="Graph Search..."
              onChange={handleSearch}
              value={inputs.search}
            ></DelayInput>
            {data && inputs.search !== "" && (
              <SearchResults
                results={data}
                invoices={invoices.filter(invoice => search_invoices(invoice, inputs.search))}
              />
            )}
          </div>
        </div>
        <div className="mb-10"></div>
      </>
    );
  } else {
    return <></>;
  }
};

export default SearchBar;
