import React from "react";

interface PageHeaderProps {
  label: string;
}

const PageHeader = ({ label }: PageHeaderProps) => {
  return (
    <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
      <h1 className="text-3xl xl:text-4xl font-bold">{label}</h1>
    </div>
  );
};

export default PageHeader;
