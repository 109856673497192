import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import SearchBar from "../../components/search/SearchBar";
import Meetings from "../meetings/Meetings";
import SUPPLIER_DETAIL_QUERY from "../../graphql/queries/supplierDetail";
import { SupplierDetailQuery, SupplierDetailQueryVariables } from "../../generated/graphql";
import AccountLocationsList from "../client/AccountLocationsList";
import AccountToolbar from "../client/AccountToolbar";
import PageHeader from "../../components/layout/PageHeader";
import PageSection from "../../components/layout/PageSection";
import AccountHeader from "../../components/AccountHeader";
import MenuSection from "../../components/layout/MenuSection";

const Supplier = () => {
  const { supplier_id } = useParams<{ supplier_id: string }>();
  const { loading: queryLoading, data: queryData } = useQuery<
    SupplierDetailQuery,
    SupplierDetailQueryVariables
  >(SUPPLIER_DETAIL_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      supplier_id: Number(supplier_id),
    },
  });

  if (queryData == null || queryLoading || queryData.inventory_account.length === 0) return null;

  const account = queryData.inventory_account[0];
  return (
    <>
      <MenuSection selectedMainMenu="Supplier" selectedSubMenu="all-supplier" />
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <PageHeader label="Supplier" />

                <div className="h-full">
                  <AccountHeader account={account} />
                  <AccountToolbar account={account} accountType="supplier" />

                  {account.account_description && (
                    <PageSection title="Description">
                      <p className=" font-semibold text-blue-700 product-result-truncate">
                        {account.account_description}
                      </p>
                    </PageSection>
                  )}

                  <PageSection title="Roles">
                    <p className="font-semibold text-blue-700 product-result-truncate">
                      {account.account_roles
                        .map(account_role => {
                          return account_role.role.role_name;
                        })
                        .join(", ")}
                    </p>
                  </PageSection>

                  <h2 className="text-xl xl:text-xl font-bold">Locations</h2>
                  {account.account_locations.length > 0 && (
                    <AccountLocationsList locations={account.account_locations} />
                  )}

                  <Meetings account_id={supplier_id} account_type={"supplier"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Supplier;
