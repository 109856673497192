import React from "react";
import SimpleAccordion from "../../components/SimpleAccordion";

const MeetingNotes = ({ account_meeting_note }) => {
  return (
    <SimpleAccordion label="Notes">
      <div
        className="prose px-2 text-sm"
        dangerouslySetInnerHTML={{
          __html: account_meeting_note.meeting_note.content,
        }}
      />
    </SimpleAccordion>
  );
};

export default MeetingNotes;
