import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import SearchResultRow from "./search/SearchResultRow";

const SearchResults = ({ results, invoices }) => {
  const [cursor, setCursor] = useState(0);

  useHotkeys("up", () => {
    setCursor(cursor => Math.max(cursor - 1, 0));
  });
  useHotkeys("down", () => {
    setCursor(cursor => cursor + 1);
  });

  if (results == null) return null;
  console.log(results);

  // Figure out the next starting index to do the offset
  const count_client_orders = 0;
  const count_clients = results.inventory_client_order.length + count_client_orders;
  const count_suppliers = results.inventory_client.length + count_clients;
  const count_locations = results.inventory_supplier.length + count_suppliers;
  const count_base_products = results.inventory_location.length + count_locations;
  const count_products = results.inventory_base_product.length + count_base_products;
  const count_invoices = results.inventory_product.length + count_products;

  console.log(count_invoices);

  // TODO: Fix what happens if you keep pressing down
  if (cursor > 0 && cursor >= count_invoices + invoices.length) {
    setCursor(cursor => Math.min(count_invoices + invoices.length - 1, cursor));
  }

  return (
    <div
      style={{ maxHeight: "90vh" }}
      className="relative z-100 w-full h-100 flex mt-8 xl:my-4 shadow overflow-y-auto rounded-lg flex-row flex-wrap"
    >
      <table className="w-full border text-sm text-left">
        <tbody>
          {results.inventory_client_order.map((row, i) => {
            return (
              <SearchResultRow
                url={"/client-orders/edit/" + row.id}
                id={row.id}
                global_index={count_client_orders + i}
                cursor={cursor}
              >
                <div className="rounded p-2 w-10 h-10 text-sm text-center font-semibold bg-green-300">
                  {row.client_account.name.substring(0, 1)}
                </div>
                <div>
                  <p>Order #{row.id}</p>
                  <p className="text-xs italic">
                    {row.client_order_reference} {row.client_account.name}
                  </p>
                </div>
                <div className="float-right">
                  <p>{row.date.substring(0, 10)}</p>
                </div>
                <div className="float-right">
                  <p>Client Order</p>
                </div>
              </SearchResultRow>
            );
          })}
          {results.inventory_client.map((row, i) => {
            return (
              <SearchResultRow
                url={"/client/edit/" + row.id}
                id={row.id}
                global_index={count_clients + i}
                cursor={cursor}
              >
                <div className="rounded p-2 w-10 h-10 text-sm text-center font-semibold bg-green-300">
                  {row.local_name.substring(0, 1)}
                </div>
                <div>
                  <p>
                    Client: {row.local_name}{" "}
                    {row.local_name !== row.international_name
                      ? "(" + row.international_name + ")"
                      : ""}
                  </p>
                  <p className="text-xs italic">{row.account_status.account_status_name}</p>
                </div>
                <div className="float-right">
                  <p>{row.date.substring(0, 10)}</p>
                </div>
                <div className="float-right">
                  <p>Client</p>
                </div>
              </SearchResultRow>
            );
          })}
          {results.inventory_supplier.map((row, i) => {
            return (
              <SearchResultRow
                url={"/supplier/edit/" + row.id}
                id={row.id}
                global_index={count_suppliers + i}
                cursor={cursor}
              >
                <div className="rounded p-2 w-10 h-10 text-sm text-center font-semibold bg-green-300">
                  {row.local_name.substring(0, 1)}
                </div>
                <div>
                  <p>
                    Supplier: {row.local_name}{" "}
                    {row.local_name !== row.international_name
                      ? "(" + row.international_name + ")"
                      : ""}
                  </p>
                  <p className="text-xs italic">{row.account_status.account_status_name}</p>
                </div>
                <div className="float-right">
                  <p>{row.date.substring(0, 10)}</p>
                </div>
                <div className="float-right">
                  <p>Supplier</p>
                </div>
              </SearchResultRow>
            );
          })}
          {results.inventory_location.map((row, i) => {
            return (
              <SearchResultRow
                url={"/location/edit/" + row.id}
                id={row.id}
                global_index={count_locations + i}
                cursor={cursor}
              >
                <div className="rounded p-2 w-10 h-10 text-sm text-center font-semibold bg-green-300">
                  {row.address.country_code}
                </div>
                <div>
                  <p>Location: {row.location_name}</p>
                  <p className="text-xs italic">{row.account.local_name}</p>
                </div>
                <div className="float-right">
                  <p>{row.date.substring(0, 10)}</p>
                </div>
                <div className="float-right">
                  <p>Location</p>
                </div>
              </SearchResultRow>
            );
          })}
          {results.inventory_base_product.map((row, i) => {
            return (
              <SearchResultRow
                url={"product/base/edit/" + row.id}
                id={row.id}
                global_index={count_base_products + i}
                cursor={cursor}
              >
                <img src={row.image_url} className="w-10" />
                <div>
                  <p>Base Product: {row.base_product_name}</p>
                  <p className="text-xs italic">{row.base_product_description}</p>
                </div>
                <p>{row.date.substring(0, 10)}</p>
                <div className="float-right">
                  <p>Base Product</p>
                </div>
              </SearchResultRow>
            );
          })}
          {results.inventory_product.map((row, i) => {
            return (
              <SearchResultRow
                url={"/products/update/" + row.id}
                id={row.id}
                global_index={count_products + i}
                cursor={cursor}
              >
                <img src={row.base_product.image_url} className="w-10" />
                <div>
                  <p>
                    {row.base_product.base_product_name} (x{row.case_qty})
                  </p>
                  <p className="text-xs italic">{row.base_product.base_product_description}</p>
                </div>
                <p>{row.date.substring(0, 10)}</p>
                <div className="float-right">
                  <p>Product</p>
                </div>
              </SearchResultRow>
            );
          })}
          {invoices.map((row, i) => {
            let url =
              row.Type === "ACCPAY"
                ? "https://go.xero.com/AccountsPayable/View.aspx?InvoiceID="
                : "https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=";

            return (
              <SearchResultRow
                url={url + row.InvoiceID}
                id={row.InvoiceID}
                global_index={count_invoices + i}
                cursor={cursor}
              >
                <div className="rounded p-2 w-10 h-10 text-sm text-center font-semibold bg-green-300">
                  {row.Contact.Name.substring(0, 1)}
                </div>
                <div>
                  <p>
                    {row.Type === "ACCPAY" ? "Payable - " : "Receivable - "} {row.Contact.Name}{" "}
                    {row.CurrencyCode} {row.Total}
                  </p>
                  <p className="text-xs italic">
                    {row.Reference} {row.InvoiceNumber}
                  </p>
                </div>
                <p>{new Date(parseInt(row.Date.split("(")[1].split(")")[0])).toDateString()}</p>
                <div className="float-right">
                  <p>{row.Type === "ACCPAY" ? "XERO Payable" : "XERO Receivable"}</p>
                </div>
              </SearchResultRow>
            );
          })}
        </tbody>
      </table>{" "}
    </div>
  );
};
export default SearchResults;
