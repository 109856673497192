import React from "react";
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "../react-auth0-spa";

const ApolloWrapper = ({ children }) => {
  const { loading, getTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_ENDPOINT
      ? `${process.env.REACT_APP_HASURA_ENDPOINT}/v1/graphql`
      : `${window.REACT_APP_HASURA_ENDPOINT}/v1/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getTokenSilently();
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    } else {
      return {
        headers: {
          ...headers,
        },
      };
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  if (loading === null || !client) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
