import React from "react";
import Select, { OptionProps, ValueType } from "react-select";
import { ClientOrderLinesQuery } from "../../generated/graphql";
import { ClientOrderLineSelectItem } from "../../types/ClientOrderLineOption";
import { customReactSelectStyle } from "../../utils/customReactSelectStyle";
import OrderLineSummary from "./OrderLineSummary";

const CustomOption = ({
  innerProps,
  isDisabled,
  data,
  isFocused,
}: OptionProps<ClientOrderLineSelectItem, false>) => {
  const optionData: ClientOrderLineSelectItem = data;
  const { data: order_line } = optionData;
  if (isDisabled || !order_line) return null;

  return <OrderLineSummary innerProps={innerProps} order_line={order_line} isFocused={isFocused} />;
};

interface ClientOrderLineSelectorProps {
  client_order_lines: ClientOrderLinesQuery["inventory_client_order_line"];
  handleSelect: (value: ValueType<ClientOrderLineSelectItem, false>) => void;
  value?: ClientOrderLineSelectItem | null;
}

const ClientOrderLineSelector = ({
  client_order_lines,
  handleSelect,
  value,
}: ClientOrderLineSelectorProps) => {
  const clientOrderLineOptions: ClientOrderLineSelectItem[] = client_order_lines.map(
    client_order_line => {
      const orderId = client_order_line.client_order_id;
      const productName = client_order_line.product?.base_product.base_product_name;
      const clientName = client_order_line.client_order.client_account.international_name;

      return {
        value: `${orderId} ${productName} ${clientName}`,
        label: productName,
        data: client_order_line,
      };
    }
  );

  return (
    <div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="order-line-selector"
      >
        Client Order Line
      </label>
      <Select
        id="order-line-selector"
        options={clientOrderLineOptions}
        value={value || null} // null hides the selected option in case we want to clear the input on select
        components={{ Option: CustomOption }}
        placeholder="Select Client Order Line"
        onChange={handleSelect}
        blurInputOnSelect
        styles={customReactSelectStyle}
      />
    </div>
  );
};

export default ClientOrderLineSelector;
