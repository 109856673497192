import { ApolloError } from "@apollo/client";
import React from "react";

interface MutationErrorProps {
  error: ApolloError;
}

const MutationError = ({ error }: MutationErrorProps) => (
  <div
    className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
    role="alert"
  >
    <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
      Error
    </span>
    <span className="font-semibold mr-2 text-left flex-auto">
      {error.graphQLErrors.map(({ message }) => {
        return <div key={message}>{message}</div>;
      })}
    </span>
  </div>
);

export default MutationError;
