import { gql } from "@apollo/client";

// RESERVED_STATUS_ID = 3;
// DUE_IN_STATUS_ID = 1;

// inserting a shipment line triggers the following state changes
// 1. the selected product stock value is decreased by the reserved amount
// 2. a new product_stock record is created - owner = original owner, location = original location, but status is set to reserved (id = 3)

const ADD_SHIPMENT_LINE_MUTATION = gql`
  mutation AddShipmentLine(
    $client_order_line_id: Int!
    $product_id: Int!
    $source_location_id: Int!
    $destination_location_id: Int!
    $source_product_stock_id: Int!
    $updated_source_product_stock_qty: Int!
    $source_product_stock_account_id: Int!
    $reserved_qty: Int!
    $user_id: String!
    $shipment_id: Int
  ) {
    insert_inventory_shipment_line_one(
      object: {
        client_order_line_id: $client_order_line_id
        source_location_id: $source_location_id
        source_product_stock_id: $source_product_stock_id
        destination_location_id: $destination_location_id
        reserved_qty: $reserved_qty
        user_id: $user_id
        shipment_id: $shipment_id
        product_stocks: {
          data: [
            {
              product_id: $product_id
              stock_status_id: 3
              location_id: $source_location_id
              account_id: $source_product_stock_account_id
              initial_qty: $reserved_qty
              qty: $reserved_qty
              user_id: $user_id
              batch: {
                data: { supplier_account_id: $source_product_stock_account_id, user_id: $user_id }
              }
            }
          ]
        }
      }
    ) {
      id
      product_stocks {
        id
        batch {
          id
          supplier_account_id
          user_id
        }
        stock_status {
          stock_status_name
        }
      }
    }
    update_inventory_product_stock_by_pk(
      pk_columns: { id: $source_product_stock_id }
      _set: { qty: $updated_source_product_stock_qty, user_id: $user_id }
    ) {
      id
      stock_status {
        stock_status_name
      }
      qty
    }
  }
`;

export default ADD_SHIPMENT_LINE_MUTATION;
