import React, { useState } from "react";
import SideMenu from "../../components/SideMenu";
import { useQuery, useMutation, gql } from "@apollo/client";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import TitleHeading from "../../components/TitleHeading";
import SimpleSelect from "../../components/forms/SimpleSelect";
import SearchBar from "../../components/search/SearchBar";
import { useAuth0 } from "../../react-auth0-spa";

const BASE_PRODUCTS = gql`
  query GetBaseProduct {
    inventory_base_product {
      id
      name: base_product_name
      hs_code
      image_url
    }
  }
`;

const ADD_SKU = gql`
  mutation AddProduct(
    $user_id: String!
    $base_product_id: Int!
    $case_qty: Int!
    $height_mm: Int!
    $length_mm: Int!
    $width_mm: Int!
    $weight_grams: Int!
  ) {
    insert_inventory_product_one(
      object: {
        user_id: $user_id
        base_product_id: $base_product_id
        case_qty: $case_qty
        height_mm: $height_mm
        length_mm: $length_mm
        width_mm: $width_mm
        weight_grams: $weight_grams
      }
    ) {
      id
    }
  }
`;

const AddSKU = () => {
  const [inputs, setInputs] = useState({});
  const [successCode, setSuccessCode] = useState(0);
  const {
    loading: loadingBaseProducts,
    error: errorBaseProducts,
    data: dataBaseProducts,
    refetch,
  } = useQuery(BASE_PRODUCTS, {
    fetchPolicy: "network-only",
  });
  const [addProduct, { loading: mutationLoading, error: mutationError }] = useMutation(ADD_SKU, {
    onCompleted(data) {
      setSuccessCode(200);
      // window.location = "/client-orders/" + data.insert_inventory_base_product_one.id + "/add-order-items"
    },
  });

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const resetForm = event => {
    if (event) {
      event.preventDefault();
    }
    setInputs({
      name: "",
    });
    setSuccessCode(0);
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    addProduct({
      variables: {
        base_product_id: inputs.base_product_id,
        case_qty: inputs.case_qty,
        height_mm: inputs.height_mm,
        length_mm: inputs.length_mm,
        width_mm: inputs.width_mm,
        weight_grams: inputs.weight_grams,
        user_id: user_id,
      },
    });
  };
  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (dataBaseProducts == null || loadingBaseProducts) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="SKUs"
          selectedSubMenu="add-sku"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="SKUs"
          selectedSubMenu="add-sku"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Product Carton &gt; Add a new Product Carton" />
                  {successCode === 200 && (
                    <div
                      className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Success
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        The Product Carton was added successfully.
                      </span>
                    </div>
                  )}
                  {successCode === 400 && (
                    <div
                      className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Error
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        There was a problem with the data - please check.
                      </span>
                    </div>
                  )}
                </div>

                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select the Product"
                        change_handler={handleInputChange}
                        label="Base Product"
                        refresh_handler={refetch}
                        add_url="/products/base/add"
                        options={dataBaseProducts.inventory_base_product}
                        id_ref="base_product_id"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <div className="w-full md:w-1/2 px-3 mb-4 lg:mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="case-qty"
                        >
                          Case Qty
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="case_qty"
                          name="case_qty"
                          type="text"
                          placeholder="1000"
                          onChange={handleInputChange}
                          value={inputs.case_qty}
                          required
                        />
                      </div>

                      <div className="w-1/2 px-3 mb-2 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="height_mm"
                        >
                          Height (MM)
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="height_mm"
                          type="text"
                          name="height_mm"
                          placeholder=""
                          onChange={handleInputChange}
                          value={inputs.height_mm}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <div className="w-full md:w-1/2 px-3 mb-4 lg:mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="length_mm"
                        >
                          Length (MM)
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="length_mm"
                          name="length_mm"
                          type="text"
                          placeholder=""
                          onChange={handleInputChange}
                          value={inputs.length_mm}
                          required
                        />
                      </div>

                      <div className="w-full md:w-1/2 px-3 mb-4 lg:mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="width_mm"
                        >
                          Width (MM)
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="width_mm"
                          type="text"
                          placeholder=""
                          name="width_mm"
                          onChange={handleInputChange}
                          value={inputs.width_mm}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter weight in grams"
                        value_ref={inputs.weight_grams}
                        required={true}
                        change_handler={handleInputChange}
                        label="Weight (grams)"
                        id_ref="weight_grams"
                      />
                    </div>

                    {successCode !== 200 && (
                      <button
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                      >
                        Add
                      </button>
                    )}
                  </div>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div>{inputs.image_url && <img src={inputs.image_url} alt="preview" />}</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSKU;
