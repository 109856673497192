import React, { FunctionComponent } from "react";

interface PageSectionProps {
  title: string;
}

const PageSection: FunctionComponent<PageSectionProps> = ({ title, children }) => {
  return (
    <>
      <h2 className="text-xl xl:text-xl font-bold">{title}</h2>
      <div className="flex flex-row flex-wrap py-2">
        <div key="1" className="flex w-full rounded flex-col bg-white">
          <div className="w-full py-2 flex flex-col">
            <div className="w-full flex flex-col">
              <div className="w-full">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSection;
