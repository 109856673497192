import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import TitleHeading from "../../components/TitleHeading";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import { CSVLink } from "react-csv";
import SearchBar from "../../components/search/SearchBar";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";

const PRODUCT_PRICINGS = gql`
  query ProductPricings {
    inventory_product_pricing {
      id
      currency_code
      unit_price
      min_qty
      product {
        id
        case_qty
        base_product {
          base_product_name
          image_url
        }
      }
    }
  }
`;

const ProductPricings = () => {
  const { loading: loadingQuery, error: errorQuery, data: dataQuery } = useQuery(PRODUCT_PRICINGS, {
    fetchPolicy: "network-only",
  });

  const [ProductPricingsCSV, setProductPricingsCSV] = useState([]);

  useEffect(() => {
    if (loadingQuery) return;
    console.log(dataQuery);

    let csvData = [
      ["id", "product_image", "product name", "case qty", "min qty", "currency code", "unit cost"],
    ];

    dataQuery.inventory_product_pricing.forEach(function (inventory_product_pricing) {
      csvData.push([
        inventory_product_pricing.id,
        inventory_product_pricing.product.base_product.image_url,
        inventory_product_pricing.product.base_product.base_product_name,
        inventory_product_pricing.product.case_qty,
        inventory_product_pricing.min_qty,
        inventory_product_pricing.currency_code,
        inventory_product_pricing.unit_price,
      ]);
    });
    setProductPricingsCSV(csvData);
  }, [dataQuery]);

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateClientQuote = product_pricing_id => event => {
    event.preventDefault();
    openInNewTab(`product-pricings/edit/${product_pricing_id}`);
  };

  if (dataQuery == null || loadingQuery || errorQuery) return null;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="Sales"
            selectedSubMenu="all-product-pricings"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="Sales"
            selectedSubMenu="all-product-pricings"
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Product Pricings" />
                  <SimpleLinkButton
                    label="Add new Product Pricing"
                    keyboard_shortcut="n"
                    new_tab
                    url="/product-pricings/add"
                    id_ref="add_client_order"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>id</TH>
                        <TH>product image</TH>
                        <TH>product name</TH>
                        <TH>case qty</TH>
                        <TH>min qty</TH>
                        <TH>unit cost</TH>
                        <TH />
                      </TableHead>

                      <tbody>
                        {dataQuery.inventory_product_pricing.map(inventory_product_pricing => {
                          return (
                            <TR key="inventory_account.id">
                              <TD>{inventory_product_pricing.id}</TD>
                              <TD width={2}>
                                <img
                                  alt=""
                                  className="object-cover w-24 h-24 "
                                  src={inventory_product_pricing.product.base_product.image_url}
                                />
                              </TD>
                              <TD width={3} isEmphasised>
                                {inventory_product_pricing.product.base_product.base_product_name}
                              </TD>
                              <TD width={2} isEmphasised>
                                {inventory_product_pricing.product.case_qty}
                              </TD>
                              <TD width={2} isEmphasised>
                                {inventory_product_pricing.min_qty}
                              </TD>
                              <TD isFullWidth isEmphasised>
                                {inventory_product_pricing.currency_code}{" "}
                                {inventory_product_pricing.unit_price}
                              </TD>
                              <TD>
                                <button
                                  className="float-right"
                                  onClick={updateClientQuote(inventory_product_pricing.id)}
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <CSVLink
                    className="flex-shrink-0 mr-2 bg-teal-500 hover:shadow-lg shadow border-teal-500 text-sm border-4 text-white py-1 px-2 rounded"
                    data={ProductPricingsCSV}
                  >
                    Export to CSV
                  </CSVLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPricings;
