import React from "react";
import { OptionProps } from "react-select";
import { ProductStockQueryItem } from "../../graphql/queries/productStock";
import { SelectItem } from "../../types/SelectItem";

// custom react-select option for picking AVAILABLE product stock by owner (where the product /location is already fixed)

interface OptionInfoItemProps {
  label: string;
  value?: string | number | null;
  isFocused?: boolean;
  colSpan?: number;
}

const InfoItem = ({ label, value, isFocused, colSpan }: OptionInfoItemProps) => {
  return (
    <div className={`col-span-${colSpan || 1}`}>
      <div className={`text-xs  ${isFocused ? "text-white" : "text-gray-700"}`}>{label}</div>
      <div className={`text-sm`}>{value}</div>
    </div>
  );
};

const SelectStockOwnerOption = ({
  innerProps,
  isDisabled,
  data,
  isFocused,
}: OptionProps<SelectItem<ProductStockQueryItem>, false>) => {
  const optionData: SelectItem<ProductStockQueryItem> = data;
  const { data: stock_record } = optionData;
  if (isDisabled || !stock_record) return null;

  return (
    <div
      {...innerProps}
      className={`grid grid-cols-3 gap-2 p-3 ${isFocused && "bg-blue-600 text-white"}`}
    >
      <InfoItem label="Owner" value={stock_record.account.local_name} isFocused={isFocused} />
      <InfoItem label="Qty" value={stock_record.qty} isFocused={isFocused} colSpan={1} />
      <InfoItem label="Initial Qty" value={stock_record.initial_qty} isFocused={isFocused} />
    </div>
  );
};

export default SelectStockOwnerOption;
