import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import SearchBar from "../../components/search/SearchBar";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import SimpleSelect from "../../components/forms/SimpleSelect";
import { useAuth0 } from "../../react-auth0-spa";

const ADD_LOCATION = gql`
  mutation AddLocation(
    $parent_location_id: Int
    $location_name: String!
    $account_id: Int!
    $address_id: Int!
    $is_external: Boolean!
    $can_hold_stock: Boolean!
    $notes: String!
    $user_id: String!
  ) {
    insert_inventory_location_one(
      object: {
        parent_location_id: $parent_location_id
        location_name: $location_name
        account_id: $account_id
        address_id: $address_id
        is_external: $is_external
        can_hold_stock: $can_hold_stock
        notes: $notes
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;

const GET_LOCATIONS = gql`
  query GetLocations {
    inventory_location(order_by: { address: { name: asc } }) {
      id
      parent_location_id
      user_id
      can_hold_stock
      is_external
      name: location_name
      user_id
      address {
        id
        user_id
        country_code
        address_line_1
        address_line_2
        address_line_3
        address_description
        account_id
        last_updated
      }
    }
  }
`;

const GET_ADDRESSES = gql`
  query GetAddresses {
    inventory_address(order_by: { name: asc }) {
      id
      name
      address_line_1
      address_line_2
      address_line_3
      city
      postcode
      country_code
      account_id
    }
  }
`;

const GET_ACCOUNTS = gql`
  query GetAccounts {
    inventory_account {
      id
      locale
      name: local_name
      international_name
      user_id
      account_description
      account_status_id
      acquisition_channel
      last_updated
    }
  }
`;

const AddLocation = () => {
  const { user } = useAuth0();
  const user_id = user ? user.sub : null;
  const [inputs, setInputs] = useState({
    is_external: false,
    can_hold_stock: false,
  });
  const [successCode, setSuccessCode] = useState(0);
  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const resetForm = event => {
    setInputs({
      name: "",
      addressline1: "",
      addressline2: "",
      addressline3: "",
      city: "",
      county: "",
      country_code: "",
      postcode: "",
    });
    setSuccessCode(0);
  };

  const [AddLocation, { loading: mutationLoading, error: mutationError }] = useMutation(
    ADD_LOCATION,
    {
      onCompleted(data) {
        setSuccessCode(200);
      },
    }
  );

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    AddLocation({
      variables: {
        user_id: user_id,
        account_id: inputs.account_id,
        parent_location_id: inputs.parent_location_id,
        location_name: inputs.location_name,
        address_id: inputs.address_id,
        notes: inputs.location_notes,
        is_external: inputs.is_external,
        can_hold_stock: inputs.can_hold_stock,
      },
    });
  };
  const handleCheckBoxChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.checked,
    }));
    console.log("inputs");
    console.log(inputs);
  };

  const {
    loading: loadingAddresses,
    error: errorAddresses,
    data: dataAddresses,
    refetch: refreshAddresses,
  } = useQuery(GET_ADDRESSES, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
    refetch: refreshLocations,
  } = useQuery(GET_LOCATIONS, {
    fetchPolicy: "network-only",
  });
  const {
    loading: loadingAccounts,
    error: errorAccounts,
    data: dataAccounts,
    refetch: refreshAccounts,
  } = useQuery(GET_ACCOUNTS, {
    fetchPolicy: "network-only",
  });

  if (dataAddresses == null || loadingAddresses) return null;
  if (dataLocations == null || loadingLocations) return null;
  if (dataAccounts == null || loadingAccounts) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <h1 className="text-3xl xl:text-4xl mx-2 font-bold">
                    Locations &gt; Add a new Location
                  </h1>

                  {successCode === 200 && (
                    <div
                      className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Success
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        The Location was added successfully.
                      </span>
                    </div>
                  )}
                  {successCode === 400 && (
                    <div
                      className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Error
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        There was a problem with the data - please check.
                      </span>
                    </div>
                  )}
                </div>

                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter an Location Name"
                        value_ref={inputs.location_name}
                        required={true}
                        change_handler={handleInputChange}
                        label="Location Name"
                        id_ref="location_name"
                        two_column
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter notes on Location"
                        value_ref={inputs.location_notes}
                        required={true}
                        change_handler={handleInputChange}
                        label="Location Notes"
                        id_ref="location_notes"
                        two_column
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Address (Leave Blank if same as parent)"
                        options={dataAddresses.inventory_address}
                        add_url="/addresses/add"
                        required
                        change_handler={handleInputChange}
                        refresh_handler={refreshAddresses}
                        label="Address"
                        id_ref="address_id"
                        name_func={option => {
                          return `${option.name} ${option.address_line_1} ${option.city} ${option.postcode} ${option.country_code}`;
                        }}
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Parent Location (Null for Root Location)"
                        options={dataLocations.inventory_location}
                        change_handler={handleInputChange}
                        refresh_handler={refreshLocations}
                        add_url="/locations/add"
                        label="Parent Location"
                        id_ref="parent_location_id"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Client/Supplier Account"
                        options={dataAccounts.inventory_account}
                        required
                        change_handler={handleInputChange}
                        refresh_handler={refreshAccounts}
                        add_url="/clients/add"
                        label="Account"
                        id_ref="account_id"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <div class="w-full px-3 mb-2 md:mb-0 py-3 mb-3">
                        <label class="inline-flex items-center  tracking-wide  uppercase text-gray-700 text-xs font-bold mb-2">
                          <input
                            type="checkbox"
                            name="is_external"
                            value="is_external"
                            onClick={handleCheckBoxChange}
                          />
                          <span class="ml-2">Is external?</span>
                        </label>
                      </div>
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <div class="w-full px-3 mb-2 md:mb-0  py-3 mb-3">
                        <label class="inline-flex items-center  tracking-wide  uppercase text-gray-700 text-xs font-bold mb-2">
                          <input
                            type="checkbox"
                            name="can_hold_stock"
                            value="can_hold_stock"
                            onClick={handleCheckBoxChange}
                          />
                          <span class="ml-2">Can hold stock?</span>
                        </label>
                      </div>
                    </div>

                    {successCode !== 200 && (
                      <button
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                      >
                        Add Location
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocation;
