import React from "react";
import { OptionProps } from "react-select";
import { ProductStockQueryItem } from "../../graphql/queries/productStock";

import { ClientOrderLineSelectItem } from "../../types/ClientOrderLineOption";

interface OptionInfoItemProps {
  label: string;
  value?: string | number | null;
  isFocused?: boolean;
  colSpan?: number;
}

const InfoItem = ({ label, value, isFocused, colSpan }: OptionInfoItemProps) => {
  return (
    <div className={`col-span-${colSpan || 1}`}>
      <div className={`text-xs  ${isFocused ? "text-white" : "text-gray-700"}`}>{label}</div>
      <div className={`text-sm`}>{value}</div>
    </div>
  );
};

interface ProductStockSummaryProps {
  stock_record: ProductStockQueryItem;
  isFocused?: boolean;
  innerProps?: OptionProps<ClientOrderLineSelectItem, false>["innerProps"];
}

const ProductStockSummary = ({ stock_record, innerProps, isFocused }: ProductStockSummaryProps) => {
  return (
    <div
      {...innerProps}
      className={`grid grid-cols-5 grid-rows-2 gap-2 p-3 ${isFocused && "bg-blue-600 text-white"}`}
    >
      <InfoItem
        label="Location"
        value={stock_record.location.location_name}
        isFocused={isFocused}
        colSpan={2}
      />
      <InfoItem
        label="Product"
        value={stock_record.product.base_product.base_product_name}
        isFocused={isFocused}
        colSpan={2}
      />
      <InfoItem label="Qty" value={stock_record.qty} isFocused={isFocused} colSpan={1} />
      <InfoItem
        label="Initial Qty"
        value={stock_record.initial_qty}
        isFocused={isFocused}
        colSpan={2}
      />
      <InfoItem
        label="Status"
        value={stock_record.stock_status.stock_status_name}
        isFocused={isFocused}
        colSpan={2}
      />
      <InfoItem
        label="Owner"
        value={stock_record.account.local_name}
        isFocused={isFocused}
        colSpan={1}
      />
    </div>
  );
};

export default ProductStockSummary;
