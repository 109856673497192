import { gql } from "@apollo/client";

const SHIPMENT_QUERY = gql`
  query Shipment($shipment_id: Int!) {
    inventory_shipment_by_pk(id: $shipment_id) {
      id
      source_location_id
      destination_location_id
      source {
        location {
          id
        }
        location_name
      }
      shipment_lines {
        id
        reserved_qty
        product_stocks {
          id
          qty
          product_id
          batch_id
          location_id
          initial_qty
          account_id
          stock_status_id
          stock_status {
            id
            stock_status_name
          }
          source_client_invoice_line_id
          source_shipment_line_id
          source_production_order_line_id
          user_id
        }
        source_product_stock: product_stock {
          id
          qty
          product_id
          batch_id
          location_id
          initial_qty
          account_id
          stock_status_id
          source_client_invoice_line_id
          source_shipment_line_id
          source_production_order_line_id
          user_id
        }
      }
      source_location_id
      destination_location_id
      shipped_time
      estimated_shipped_time
      estimated_arrival_days
      arrival_time
      destination {
        location {
          id
        }
        location_name
      }
      courier_service {
        id
        courier_service_name
      }
      shipment_status {
        id
        shipment_status_name
      }
    }
  }
`;

export default SHIPMENT_QUERY;
