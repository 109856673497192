import React from "react";
const StatusBadge = ({ label }) => {
  const statusMap = {
    Active: "bg-green-300",
    Suspended: "bg-red-300",
    Inactive: "bg-gray-200",
    new: "bg-yellow-300",
    "due in": "bg-yellow-300",
    available: "bg-green-300",
    reserved: "bg-orange-300",
    damaged: "bg-red-300",
    unavailable: "bg-gray-300",
  };

  return (
    <span
      className={`inline-block text-xs text-gray-900 py-1 px-2 ${statusMap[label]} font-semibold uppercase rounded`}
    >
      {label}
    </span>
  );
};
export default StatusBadge;
