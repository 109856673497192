import React, { useState, useEffect, useRef } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import RichTextEditor from "react-rte";
import SimpleDateInput from "../../components/forms/SimpleDateInput";
import TitleHeading from "../../components/TitleHeading";
import SimpleSelect from "../../components/forms/SimpleSelect";
import SimpleButton from "../../components/forms/SimpleButton";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import Attachment from "../attachments/Attachment";
import { Link, useParams, useLocation } from "react-router-dom";

const MEETING_STATUSES = gql`
  query GetMeetingStatuses {
    inventory_meeting_note_status {
      id
      name: meeting_note_status_name
    }
  }
`;

const GET_MEETING_NOTE = gql`
  query GetMeetingNote($meeting_id: Int!) {
    inventory_account_meeting_note(where: { meeting_note_id: { _eq: $meeting_id } }) {
      meeting_note {
        contacts
        content
        last_updated
        meeting_date
        meeting_note_status {
          id
          meeting_note_status_name
        }
        user_id
        folder {
          id
        }
      }
      account_id
      id
      account {
        account_description
        account_created
        id
        international_name
        local_name
      }
    }
  }
`;

const UPDATE_MEETING = gql`
  mutation UpdateMeetingNote(
    $meeting_id: Int!
    $content: String!
    $contacts: _text!
    $meeting_note_status_id: Int!
    $meeting_date: timestamptz!
  ) {
    update_inventory_meeting_note_by_pk(
      pk_columns: { id: $meeting_id }
      _set: {
        contacts: $contacts
        content: $content
        meeting_date: $meeting_date
        meeting_note_status_id: $meeting_note_status_id
      }
    ) {
      id
    }
  }
`;

const EditMeeting = () => {
  const useRouteQuery = () => new URLSearchParams(useLocation().search);

  let query = useRouteQuery();

  let location = useLocation();
  const selectInputRef = useRef();
  const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());
  const [inputs, setInputs] = useState({});
  const [attendees, setAttendeesState] = useState({
    inputValue: "",
    value: [],
  });
  const [successCode, setSuccessCode] = useState(0);
  const { meeting_id } = useParams();

  const { loading: loading_meeting, error: error_meeting, data: data_meeting } = useQuery(
    GET_MEETING_NOTE,
    {
      fetchPolicy: "network-only",
      variables: {
        meeting_id: meeting_id,
      },
    }
  );

  const [updateMeeting, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_MEETING,
    {
      refetchQueries: [
        { query: GET_MEETING_NOTE, variables: { meeting_id }, fetchPolicy: "network-only" },
      ],
      onCompleted() {
        setSuccessCode(200);
      },
      onError() {
        console.log(mutationError);
        setSuccessCode(0);
      },
    }
  );

  const {
    loading: loading_meeting_statues,
    error: error_meeting_statues,
    data: meeting_statues,
  } = useQuery(MEETING_STATUSES, {
    fetchPolicy: "network-only",
  });

  const createOption = label => ({
    label,
    value: label,
  });

  const arrayToString = array => {
    let values = _.chain(array)
      .map(value => value.value)
      .filter(value => value)
      .map(value => {
        return '"' + value.replace("'", "''").trim() + '"';
      })
      .join(",")
      .value();

    return "{" + values + "}";
  };

  const handleAttendeesChange = (value, actionMeta) => {
    console.log("handleAttendeesChange");
    console.group("Value Changed");
    console.log(value);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    setAttendeesState({ value, inputValue: attendees.inputValue });
  };
  const handleInputAttendeesChange = inputValue => {
    console.log("handleInputAttendeesChange");
    console.log("attendees");
    console.log(attendees);
    setAttendeesState({ inputValue, value: attendees.value == null ? [] : attendees.value });
  };

  const handleAttendeesKeyDown = event => {
    const { inputValue, value } = attendees;
    console.log("handleAttendeesKeyDown");
    console.log(inputValue);
    console.log(value);
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        console.group("Value Added");
        console.log(value);
        console.groupEnd();
        setAttendeesState({
          inputValue: "",
          value: [...value, createOption(inputValue)],
        });
        return event.preventDefault();
      default:
        break;
    }
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    // strip any superfluous spaces introduced when pasting from other programs
    const content = editorState.toString("html").replace(/&nbsp;/g, "");

    updateMeeting({
      variables: {
        meeting_id: meeting_id,
        contacts: arrayToString(attendees.value),
        meeting_note_status_id: inputs.meeting_note_status_id,
        meeting_date: inputs.meeting_date,
        content: content,
      },
    });
  };

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    console.log(editorState.toString("html"));

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    if (loading_meeting || error_meeting) return;

    console.log(data_meeting);
    console.log(error_meeting);
    console.log(meeting_id);

    setInputs({
      meeting_note_status_id:
        data_meeting.inventory_account_meeting_note[0].meeting_note.meeting_note_status.id,
      meeting_date: data_meeting.inventory_account_meeting_note[0].meeting_note.meeting_date,
    });

    setEditorState(
      RichTextEditor.createValueFromString(
        data_meeting.inventory_account_meeting_note[0].meeting_note.content,
        "html"
      )
    );

    setAttendeesState({
      inputValue: "",
      value: data_meeting.inventory_account_meeting_note[0].meeting_note.contacts.map(val =>
        createOption(val)
      ),
    });

    // TODO: find proper fix to dependency issues @David
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_meeting, loading_meeting, error_meeting]);

  if (loading_meeting_statues || meeting_statues === null) return null;
  if (loading_meeting || data_meeting === null) return null;

  console.log("attendees");
  console.log(attendees);

  console.log("data_meeting");
  console.log(data_meeting);

  console.log("location.state");
  console.log(location.state);

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="flex flex-row">
          <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
          <div className="xl:w-9/12 w-full">
            <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
              <div className="flex pt-4 mb-4">
                <div className="w-full h-auto  ml-2">
                  <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                    <TitleHeading label="Edit Meeting" />
                    {successCode === 200 && (
                      <div
                        className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                        role="alert"
                      >
                        <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                          Success
                        </span>
                        <span className="font-semibold mr-2 text-left flex-auto">
                          The Meeting was edited successfully.
                        </span>
                      </div>
                    )}
                    {mutationError && (
                      <div
                        className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                        role="alert"
                      >
                        <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                          Error
                        </span>
                        <span className="font-semibold mr-2 text-left flex-auto">
                          There was a problem with the data - please check.
                        </span>
                      </div>
                    )}
                  </div>

                  {query.get("account_type") == "supplier" ? (
                    <Link
                      className="text-3xl xl:text-3xl mb-4 text-gray-900 font-bold"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/supplier/${data_meeting.inventory_account_meeting_note[0].account_id}`}
                    >
                      Account Name:{" "}
                      {data_meeting.inventory_account_meeting_note[0].account.local_name}
                    </Link>
                  ) : (
                    <Link
                      className="text-3xl xl:text-3xl mb-4 text-gray-900 font-bold"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/client/${data_meeting.inventory_account_meeting_note[0].account_id}`}
                    >
                      Account Name:{" "}
                      {data_meeting.inventory_account_meeting_note[0].account.local_name}
                    </Link>
                  )}

                  <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                    <div className=" w-full lg:w-1/2 p-4 lg:p-0">
                      <div className="w-8/12 p-4 -mx-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Attendees
                        </label>
                        <CreatableSelect
                          ref={selectInputRef}
                          placeholder="Add Attendee"
                          inputValue={attendees.inputValue}
                          isClearable
                          isMulti
                          menuIsOpen={false}
                          onChange={handleAttendeesChange}
                          onInputChange={handleInputAttendeesChange}
                          onKeyDown={handleAttendeesKeyDown}
                          value={attendees.value}
                          menuPortalTarget={document.querySelector("body")}
                        />
                      </div>
                      <div className="flex flex-wrap mb-2">
                        <SimpleDateInput
                          placeholder="Meeting Time"
                          change_handler={handleInputChange}
                          label="Meeting Date"
                          id_ref="meeting_date"
                          value_ref={inputs.meeting_date}
                        />
                      </div>
                      <div className="flex flex-wrap mb-2">
                        <SimpleSelect
                          placeholder="Select Meeting Status"
                          options={meeting_statues.inventory_meeting_note_status}
                          required
                          change_handler={handleInputChange}
                          selected_id={inputs.meeting_note_status_id}
                          label="Meeting Status"
                          id_ref="meeting_note_status_id"
                          name_func={option => {
                            return option.name;
                          }}
                        />
                      </div>

                      <div className="flex flex-wrap mb-2 ">
                        <RichTextEditor
                          value={editorState}
                          onChange={value => {
                            setEditorState(value);
                          }}
                        />
                      </div>
                      <SimpleButton label="Save" />
                    </div>
                  </form>
                  <div className="mx-3">
                    <Attachment
                      folder_id={
                        data_meeting.inventory_account_meeting_note[0].meeting_note.folder.id
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMeeting;
