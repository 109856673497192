import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import AttachmentPreview from "../attachments/AttachmentPreview";
import MeetingNotes from "./MeetingNotes";
import SimpleAccordion from "../../components/SimpleAccordion";

const TH = ({ children, className, ...props }) => (
  <th className={`font-semibold p-2 m-0 w-1/5 text-sm md:text-base ${className}`} {...props}>
    {children}
  </th>
);

const TD = ({ children, className, ...props }) => (
  <td
    className={`font-semibold p-2 text-blue-700  w-1/5 text-sm md:text-base ${className}`}
    {...props}
  >
    {children}
  </td>
);

const MeetingCard = ({ account_meeting_note, account_type }) => {
  const date = new Date(account_meeting_note.meeting_note.meeting_date);

  return (
    <div className="w-full border rounded p-3 bg-white">
      <table className="w-full text-left">
        <thead>
          <tr className="text-gray-800 uppercase">
            <TH>date</TH>
            <TH>time</TH>
            <TH>status</TH>
            <TH className="hidden md:table-cell">attendees</TH>
            <TH className="hidden md:table-cell">logged by</TH>
            <TH></TH>
          </tr>
        </thead>
        <tbody>
          <tr className={`p-4 mb-2 border-t rounded bg-white`} key={account_meeting_note.id}>
            <TD>{date.toLocaleDateString()}</TD>
            <TD>{date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</TD>
            <TD>
              {account_meeting_note.meeting_note.meeting_note_status.meeting_note_status_name}
            </TD>
            <TD className="hidden md:table-cell">
              {_.chain(account_meeting_note.meeting_note.contacts).join(", ").value()}
            </TD>
            <TD className="hidden md:table-cell">{account_meeting_note.user.name}</TD>
            <TD>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className="float-right"
                to={{
                  pathname: `/meetings/edit/${account_meeting_note.meeting_note.id}`,
                  search: `?account_type=${account_type}`,
                }}
              >
                <img
                  style={{ minWidth: "18px", width: "18px" }}
                  src="/edit-outline.svg"
                  alt="edit-icon"
                />
              </Link>
            </TD>
          </tr>
        </tbody>
      </table>

      <div className="md:hidden p-2 text-sm text-gray-800">
        <FontAwesomeIcon icon={faUserCircle} />
        <span className="ml-2">Logged by {account_meeting_note.user.name}</span>
      </div>
      <SimpleAccordion label="Attendees" wrapperClassName="md:hidden">
        <div className="p-2 text-sm">
          {_.chain(account_meeting_note.meeting_note.contacts).join(", ").value()}
        </div>
      </SimpleAccordion>

      <MeetingNotes account_meeting_note={account_meeting_note} />
      <AttachmentPreview folder_id={account_meeting_note.meeting_note.folder.id} />
    </div>
  );
};

export default MeetingCard;
