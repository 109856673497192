import { gql } from "@apollo/client";

// revert stock changes caused by the shipment line
// update shipment line to status cancelled (status id = 4)

const DELETE_SHIPMENT_LINE_MUTATION = gql`
  mutation DeleteShipmentLine(
    $shipment_line_id: Int!
    $source_product_stock_id: Int!
    $updated_source_product_stock_qty: Int!
    $user_id: String!
  ) {
    update_inventory_shipment_line_by_pk(
      pk_columns: { id: $shipment_line_id }
      _set: { shipment_id: null, user_id: $user_id, shipment_line_status_id: 4 }
    ) {
      id
    }
    update_inventory_product_stock_by_pk(
      pk_columns: { id: $source_product_stock_id }
      _set: { qty: $updated_source_product_stock_qty, user_id: $user_id }
    ) {
      id
      stock_status {
        stock_status_name
      }
      qty
    }
    update_inventory_product_stock(
      where: { source_shipment_line_id: { _eq: $shipment_line_id } }
      _set: { qty: 0 }
    ) {
      affected_rows
      returning {
        id
        qty
      }
    }
  }
`;

export default DELETE_SHIPMENT_LINE_MUTATION;
