import { ShipmentQuery, Inventory_Product_Stock_Insert_Input } from "../../../../generated/graphql";

// returns the updated product stock records when cancelling a shipment (to be passed to cancelShipment mutation)
export const generateUpdatedStockOnCancellation = (
  shipment: ShipmentQuery["inventory_shipment_by_pk"],
  user_id: string
) => {
  if (shipment?.shipment_lines === undefined || shipment?.shipment_lines === null) {
    return {
      updatedStock: null,
      error: "No Shipment Line Array Found",
    };
  }

  // Need prepare an array of updated product stocks for the cancelShipment mutation
  // 1. For each shipment line, find out how much stock to add back to the source_product_stock for that shipment line
  // 2. For each shipment line, any product_stock records generated when the line was created should have stock set to 0

  // track the reset stock for all source_product_stocks used to fulfil the shipment lines (for step 1.)
  const updatedSourceProductStocks: {
    [sourceStockId: number]: Inventory_Product_Stock_Insert_Input;
  } = {};

  // track the cancelled stocks generated by each shipment line (for step 2. )
  let updatedShipmentLineProductStocks: Inventory_Product_Stock_Insert_Input[] = [];

  shipment.shipment_lines.forEach(shipment_line => {
    // get the source product stock and add the reserved qty of the shipment line back to source
    const { __typename, ...source } = shipment_line.source_product_stock;

    // if the product source has not been added yet, do so
    if (!updatedSourceProductStocks[source.id]) {
      updatedSourceProductStocks[source.id] = {
        ...source,
        user_id, // update user_id to satisfy hasura post-update constraint
      };
    }

    // add the shipment line reserved qty back to the source product stock
    updatedSourceProductStocks[source.id] = {
      ...updatedSourceProductStocks[source.id],
      qty: updatedSourceProductStocks[source.id]!.qty! + shipment_line.reserved_qty,
    };

    // set reserved qty of all product stocks generated by the shipment line to 0 and update user_id
    // remove typename and stock_status object ass we just need the base record values for the upsert
    const dependentProductStocks = shipment_line.product_stocks.map(
      ({ __typename, stock_status, ...product_stock }) => ({
        ...product_stock,
        qty: 0,
        user_id,
      })
    );

    // add to flattened array of all product stocks generated by the shipment lines
    updatedShipmentLineProductStocks = [
      ...updatedShipmentLineProductStocks,
      ...dependentProductStocks,
    ];
  });

  // combine the updated stock records
  const allUpdatedProductStocks: Inventory_Product_Stock_Insert_Input[] = [
    ...Object.values(updatedSourceProductStocks),
    ...updatedShipmentLineProductStocks,
  ];

  return {
    updatedStock: allUpdatedProductStocks,
    error: null,
  };
};
