import React from "react";

interface SimpleTextInputProps {
  label: string;
  value_ref: string;
  change_handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id_ref: string;
  placeholder: string;
  required?: boolean;
  two_column?: boolean;
  read_only?: boolean;
}

const SimpleTextInput = ({
  label,
  value_ref,
  change_handler,
  id_ref,
  placeholder,
  required,
  two_column,
  read_only,
}: SimpleTextInputProps) => {
  return (
    <div className={`w-full mb-2 md:mb-0 ${two_column ? `md:w-1/2 mb-4 lg:mb-6` : ``}`}>
      {label === "" ? (
        <></>
      ) : (
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor={id_ref}
        >
          {label} {required ? `*` : null}
        </label>
      )}
      <input
        className={`${
          read_only ? `bg-gray-200` : ` focus:bg-white`
        }  focus:outline-none appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight`}
        id={id_ref}
        type="text"
        name={id_ref}
        readOnly={read_only}
        placeholder={placeholder}
        onChange={change_handler}
        value={value_ref}
        required={required}
      />
    </div>
  );
};
export default SimpleTextInput;
