import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
const SearchResultRow = props => {
  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useHotkeys(
    "enter",
    () => {
      if (props.cursor === props.global_index) {
        openInNewTab(props.url);
      }
    },
    [props]
  );

  return (
    <tr
      className={`p-4 mb-2 border-t rounded bg-white hover:bg-gray-100 cursor-pointer ${
        props.cursor === props.global_index ? `bg-gray-100` : ``
      }`}
      key={props.id}
      onClick={() => openInNewTab(props.url)}
    >
      <td className="w-1/12 p-4">{props.children[0]}</td>
      <td className="w-6/12  p-4">{props.children[1]}</td>
      <td className="w-3/12  p-4">{props.children[2]}</td>
      <td className="w-2/12  p-4">{props.children[3]}</td>
    </tr>
  );
};

export default SearchResultRow;
