import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SearchBar from "../../components/search/SearchBar";
import TitleHeading from "../../components/TitleHeading";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import { Link } from "react-router-dom";
import StatusBadge from "../../components/StatusBadge";
import Table, { TableHead, TD, TH, TR } from "../../components/Table";

const GET_PRODUCT_STOCKs = gql`
  query GetPRODUCT_STOCKs {
    inventory_product_stock_overview {
      date: last_updated
      product {
        last_updated
        width_mm
        height_mm
        length_mm
        weight_grams
        base_product {
          id
          hs_code
          image_url
          base_product_name
          base_product_description
        }
        case_qty
      }
      location {
        location_name
      }
      supplier {
        local_name
      }
      account {
        local_name
      }
      stock_status {
        stock_status_name
      }
      qty
    }
  }
`;

const Product_Stocks = () => {
  const { loading: loadingPRODUCT_STOCKs, error: errorPRODUCT_STOCKs, data } = useQuery(
    GET_PRODUCT_STOCKs,
    {
      fetchPolicy: "network-only",
    }
  );

  if (data == null || loadingPRODUCT_STOCKs || errorPRODUCT_STOCKs) return null;

  return (
    <>
      <div className="bg-gray-100 bg-opacity-25">
        <div className="hidden xl:block">
          <SideMenu
            menuOpen={true}
            layout={"large"}
            selectedMainMenu="Stocks"
            selectedSubMenu="all-product-stocks"
          />
        </div>
        <div className="block xl:hidden">
          <SideMenu
            menuOpen={false}
            layout={"mobile"}
            selectedMainMenu="Stocks"
            selectedSubMenu="all-product-stocks"
          />
        </div>
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="All Product Stocks" />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <TH>image</TH>
                        <TH>name</TH>
                        <TH>description</TH>
                        <TH>producer</TH>
                        <TH>owner</TH>
                        <TH>qty</TH>
                        <TH>location</TH>
                        <TH>status</TH>
                        <TH>date</TH>
                      </TableHead>

                      <tbody>
                        {data.inventory_product_stock_overview.map(product_stock => {
                          return (
                            <TR key={product_stock.id}>
                              <TD>
                                <img
                                  className="w-10"
                                  src={product_stock.product.base_product.image_url}
                                  alt={product_stock.product.base_product.base_product_description}
                                />
                              </TD>
                              <TD width={2}>
                                <p>
                                  {product_stock.product.base_product.base_product_name} (x
                                  {product_stock.product.case_qty})
                                </p>
                                <p className="text-xs italic">
                                  {product_stock.product.base_product.base_product_description}{" "}
                                  (Case total: {product_stock.product.weight_grams}g)
                                </p>
                              </TD>
                              <TD width={2}>
                                {product_stock.product.width_mm} mm *{" "}
                                {product_stock.product.length_mm} mm *{" "}
                                {product_stock.product.height_mm} mm
                              </TD>
                              <TD>{product_stock.supplier.local_name}</TD>
                              <TD>{product_stock.account.local_name}</TD>
                              <TD>{product_stock.qty}</TD>
                              <TD>{product_stock.location.location_name}</TD>
                              <TD>
                                <StatusBadge label={product_stock.stock_status.stock_status_name} />
                              </TD>
                              <TD>{product_stock.date.substring(0, 10)}</TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product_Stocks;
