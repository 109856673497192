import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import SimpleButton from "../../components/forms/SimpleButton";
import TitleHeading from "../../components/TitleHeading";
import SimpleSelect from "../../components/forms/SimpleSelect";
import SearchBar from "../../components/search/SearchBar";
import CreatableSelect from "react-select/creatable";
import { useAuth0 } from "../../react-auth0-spa";
import MutationError from "../../components/MutationError";
import MutationSuccess from "../../components/MutationSuccess";
import ManageClientReferral from "../../components/forms/ManageClientReferral";

const UPDATE_CLIENT = gql`
  mutation UpdateClient(
    $client_id: Int!
    $account_status_id: Int!
    $user_id: String!
    $local_name: String!
    $locale: String!
    $international_name: String!
    $acquisition_channel: String = ""
    $account_description: String!
    $email: String!
    $telephone_number: String!
    $website: String!
    $logo_url: String!
    $account_sector_id: Int!
    $metadata: jsonb!
  ) {
    update_inventory_account_by_pk(
      pk_columns: { id: $client_id }
      _set: {
        account_status_id: $account_status_id
        user_id: $user_id
        locale: $locale
        local_name: $local_name
        acquisition_channel: $acquisition_channel
        international_name: $international_name
        email: $email
        telephone_number: $telephone_number
        website: $website
        logo_url: $logo_url
        account_sector_id: $account_sector_id
        metadata: $metadata
        account_description: $account_description
      }
    ) {
      id
    }
  }
`;

const GET_CLIENT = gql`
  query GetClient($client_id: Int!) {
    inventory_account_by_pk(id: $client_id) {
      local_name
      international_name
      locale
      account_description
      email
      telephone_number
      website
      logo_url
      account_roles {
        role {
          id
          role_name
        }
      }
      account_sector {
        id
        account_sector_name
      }
      account_status {
        account_status_name
        id
      }
      metadata
    }
    referrers: inventory_account_referral(where: { account_id: { _eq: $client_id } }) {
      referrerAccountId {
        local_name
        id
      }
    }
  }
`;

const GET_ACCOUNT_METADATA_KEYS = gql`
  query AccountMetadataKeys {
    inventory_account_metadata_keys {
      jsonb_object_keys
    }
  }
`;

const LOCALES = gql`
  query GetLocales {
    inventory_locale {
      id: locale
      name: locale
    }
  }
`;

const ACCOUNT_STATUSES = gql`
  query AccountStatuses {
    inventory_account_status {
      name: account_status_name
      id
    }
  }
`;

const SECTORS = gql`
  query GetSectors {
    inventory_account_sector {
      id
      account_sector_name
    }
  }
`;

const ADD_REFERRAL = gql`
  mutation AddReferral($account_id: Int!, $referrer_account_id: Int!) {
    insert_inventory_account_referral_one(
      object: { account_id: $account_id, referrer_account_id: $referrer_account_id }
    ) {
      id
    }
  }
`;

const EditClient = () => {
  const [metadataKeyOptions, setNewMetadataKeyOptions] = useState([]);
  const [newMetadata, setNewMetadata] = useState({});
  const [inputs, setInputs] = useState({
    referral: [],
  });
  const [successCode, setSuccessCode] = useState(0);
  const { client_id } = useParams();
  const [updateClient, { loading: mutationLoading, error: mutationError }] = useMutation(
    UPDATE_CLIENT,
    {
      onCompleted() {
        setSuccessCode(200);
      },
      onError() {
        setSuccessCode(0);
      },
    }
  );
  const [addReferral, { error: addReferralMutationError }] = useMutation(ADD_REFERRAL, {
    onCompleted() {
      setSuccessCode(200);
    },
    onError() {
      setSuccessCode(0);
    },
  });

  const selectInputRef = useRef();

  const onClear = () => {
    console.log(selectInputRef.current);
    selectInputRef.current.select.select.clearValue();
  };

  const handleInputChange = event => {
    event.persist && event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleMetadataInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      metadata: {
        ...inputs.metadata,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const deleteMetadatInputChange = event => {
    event.persist();

    console.log(event.target);

    let copy = JSON.parse(JSON.stringify(inputs.metadata));
    delete copy[event.target.id];
    setInputs(inputs => ({
      ...inputs,
      metadata: {
        ...copy,
      },
    }));

    console.log(copy);
  };

  const handleMetadataSelect = (option, event) => {
    console.log(option, event);
    if (option == null) {
      return;
    }
    setNewMetadata({
      ...newMetadata,
      key: option.value,
    });
  };

  const handleMetadataValueChange = event => {
    event.preventDefault();
    if (event.target.value == null) {
      return;
    }
    setNewMetadata({
      ...newMetadata,
      value: event.target.value,
    });
  };

  const handleMetadataAddChange = event => {
    if (event == null || newMetadata.key == null || newMetadata.value == null) {
      return;
    }
    console.log("event:", event);
    event.preventDefault();
    console.log("newmeta", newMetadata);

    setInputs(inputs => ({
      ...inputs,
      metadata: {
        ...inputs.metadata,
        [newMetadata.key]: newMetadata.value,
      },
    }));
    setNewMetadata({ value: "" });
    onClear();
    console.log("Reset");
    console.log(newMetadata);
  };

  const {
    loading: loading_meta_data_keys,
    error: error_meta_data_keys,
    data: data_meta_data_keys,
  } = useQuery(GET_ACCOUNT_METADATA_KEYS, {
    fetchPolicy: "network-only",
  });

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_CLIENT, {
    variables: { client_id: client_id },
    fetchPolicy: "network-only",
  });

  const {
    loading: queryClientStatus,
    error: errorClientStatus,
    data: clientStatus,
    refetch: refreshClientStatus,
  } = useQuery(ACCOUNT_STATUSES, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (loading_meta_data_keys || error_meta_data_keys) return;

    const new_options = data_meta_data_keys.inventory_account_metadata_keys.map(metadata_key => {
      return {
        key: metadata_key.jsonb_object_keys,
        label: metadata_key.jsonb_object_keys,
        value: metadata_key.jsonb_object_keys,
      };
    });
    console.log(new_options);
    setNewMetadataKeyOptions(new_options);
  }, [loading_meta_data_keys, error_meta_data_keys, data_meta_data_keys]);

  useEffect(() => {
    if (queryLoading || queryError) return;
    setInputs({
      local_name: queryData.inventory_account_by_pk.local_name,
      international_name: queryData.inventory_account_by_pk.international_name,
      locale: queryData.inventory_account_by_pk.locale,
      client_description: queryData.inventory_account_by_pk.account_description,
      email: queryData.inventory_account_by_pk.email,
      telephone_number: queryData.inventory_account_by_pk.telephone_number,
      website: queryData.inventory_account_by_pk.website,
      logo_url: queryData.inventory_account_by_pk.logo_url,
      client_status_id: queryData.inventory_account_by_pk.account_status.id,
      account_sector_id: queryData.inventory_account_by_pk.account_sector.id,
      metadata: queryData.inventory_account_by_pk.metadata,
      referrers: queryData.referrers,
      referral: [],
    });
  }, [queryLoading, queryError, queryData]);

  const {
    loading: loadingLocales,
    error: errorLocales,
    data: locales,
    refetch: refreshLocale,
  } = useQuery(LOCALES, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingSectors,
    error: errorSectors,
    data: sectors,
    refetch: refreshSectors,
  } = useQuery(SECTORS, {
    fetchPolicy: "network-only",
  });

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    updateClient({
      variables: {
        client_id: client_id,
        local_name: inputs.local_name,
        user_id: user_id,
        account_description: inputs.client_description,
        account_status_id: inputs.client_status_id,
        locale: inputs.locale,
        international_name:
          inputs.international_name === "" ? inputs.local_name : inputs.international_name,
        acquisition_channel: "",
        account_sector_id: inputs.account_sector_id === "" ? 1 : inputs.account_sector_id,
        email: inputs.email,
        telephone_number: inputs.telephone_number,
        website: inputs.website,
        logo_url: inputs.logo_url,
        metadata: inputs.metadata,
      },
    });

    const refs = inputs.referrers.map(referrer => `${referrer.referrerAccountId.id}`);

    _.uniq(
      inputs.referral
        .map(field => field.account_id)
        .filter(field => !!field && refs.indexOf(field) === -1)
    ).forEach(referer_id => {
      addReferral({
        variables: {
          account_id: client_id,
          referrer_account_id: referer_id,
        },
      });
    });
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (clientStatus == null || queryClientStatus) return null;

  if (loadingLocales || locales === null) return null;
  if (loadingSectors || sectors === null) return null;
  if (queryLoading || queryData === null) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label=" Client &gt; Edit Client" />
                  {successCode === 200 && (
                    <MutationSuccess message="The Client was updated successfully." />
                  )}
                  {mutationError && <MutationError error={mutationError} />}
                  {addReferralMutationError && <MutationError error={addReferralMutationError} />}
                </div>
                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter the Client Name"
                        value_ref={inputs.local_name}
                        required={true}
                        change_handler={handleInputChange}
                        label="Client Name"
                        id_ref="local_name"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Locale for the Client"
                        options={locales.inventory_locale}
                        add_url="/locales/add"
                        selected_id={inputs.locale}
                        required
                        change_handler={handleInputChange}
                        refresh_handler={refreshLocale}
                        label="Locale"
                        id_ref="locale"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Leave blank if same in English"
                        value_ref={inputs.international_name}
                        change_handler={handleInputChange}
                        label="Client Name (International)"
                        id_ref="international_name"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Sector for the Client"
                        options={sectors.inventory_account_sector}
                        selected_id={inputs.account_sector_id}
                        change_handler={handleInputChange}
                        refresh_handler={refreshSectors}
                        label="Sector"
                        id_ref="account_sector_id"
                        name_func={option => {
                          return option.account_sector_name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter the Client Description"
                        value_ref={inputs.client_description}
                        change_handler={handleInputChange}
                        label="Client Description"
                        id_ref="client_description"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for email address */}
                      <SimpleTextInput
                        placeholder="Client Email Address"
                        value_ref={inputs.email}
                        change_handler={handleInputChange}
                        label="Client Email Address"
                        id_ref="email"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for phone number */}
                      <SimpleTextInput
                        placeholder="Client Phone Number"
                        value_ref={inputs.telephone_number}
                        change_handler={handleInputChange}
                        label="Client Phone Number"
                        id_ref="telephone_number"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for links */}
                      <SimpleTextInput
                        placeholder="Client Website"
                        value_ref={inputs.website}
                        change_handler={handleInputChange}
                        label="Client Website"
                        id_ref="website"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Client Logo URL"
                        value_ref={inputs.logo_url}
                        change_handler={handleInputChange}
                        label="Client Logo URL"
                        id_ref="logo_url"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Client Status"
                        options={clientStatus.inventory_account_status}
                        add_url="/account_statuses/add"
                        selected_id={inputs.client_status_id}
                        required
                        change_handler={handleInputChange}
                        refresh_handler={refreshClientStatus}
                        label="Client Status"
                        id_ref="client_status_id"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    {inputs.referrers && inputs.referrers.length > 0 && (
                      <div className="flex flex-wrap rounded border-t border-b border-r border-l border-gray-500 text-gray-700 mb-4 px-3 py-3">
                        <p className="font-bold flex w-full">
                          {inputs.local_name} has been referred by:{" "}
                        </p>
                        <ul className="pl-3">
                          {inputs.referrers.map(ref => (
                            <li>{ref.referrerAccountId.local_name}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="flex flex-wrap mb-2">
                      <ManageClientReferral change_handler={handleInputChange} id_ref="referral" />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <label className="px-3 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Metadata
                      </label>
                      <div className="w-full mx-3 px-3 xl:my-4 shadow rounded-lg flex-row flex-wrap ">
                        <thead>
                          <tr className="w-full text-gray-800 uppercase">
                            <th className="w-6/12 text-left">key</th>
                            <th className="w-3/12 text-left">value</th>
                            <th className="w-3/12 text-left"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {inputs.metadata &&
                            Object.keys(inputs.metadata).map(key => {
                              console.log(inputs.metadata);
                              return (
                                <tr className="p-4 mb-2 border-t rounded bg-white">
                                  <td className="w-1/12 p-4">{key}</td>
                                  <td className="w-1/12 p-4">
                                    <input
                                      className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                      id={key}
                                      name={key}
                                      type="text"
                                      value={inputs.metadata[key]}
                                      placeholder="metadatavalue"
                                      onChange={handleMetadataInputChange}
                                    />
                                  </td>
                                  <td className="w-1/12 p-4">
                                    <button
                                      id={key}
                                      className="float-right"
                                      onClick={deleteMetadatInputChange}
                                    >
                                      <i id={key} class="fas fa-trash-alt"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          <tr className="p-4 mb-2 border-t rounded bg-white">
                            <td className="w-1/12 p-4">
                              <CreatableSelect
                                ref={selectInputRef}
                                isClearable
                                menuPortalTarget={document.querySelector("body")}
                                onChange={handleMetadataSelect}
                                options={metadataKeyOptions.filter(
                                  x => inputs.metadata == null || !(x.key in inputs.metadata)
                                )}
                                inputId="metadata_key"
                                name="metadata_key"
                              />
                            </td>
                            <td className="w-1/12 p-4">
                              <input
                                className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                                id="metadata_value"
                                name="metadata_value"
                                type="text"
                                placeholder="Value"
                                value={newMetadata.value}
                                onChange={handleMetadataValueChange}
                              />
                            </td>
                            <td className="w-1/12 p-4">
                              <button
                                className={`float-right ${
                                  newMetadata.key == null ||
                                  newMetadata.value == null ||
                                  newMetadata.value === ""
                                    ? `hidden`
                                    : ``
                                }`}
                                id="metadata_add"
                                onClick={handleMetadataAddChange}
                              >
                                <i class="fas fa-check" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                    </div>
                    <SimpleButton label="Update" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClient;
