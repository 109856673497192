import React, { useRef, useState, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";

const HoverCell = props => {
  const [hoverRef, isHovered] = useHover();

  useHotkeys(
    "v",
    () => {
      if (isHovered) {
        window.location = props.view_url;
      }
    },
    {},
    [isHovered]
  );
  useHotkeys(
    "e",
    () => {
      if (isHovered) {
        window.location = props.edit_url;
      }
    },
    {},
    [isHovered]
  );

  return <div ref={hoverRef}>{props.children}</div>;
};

// Hook
function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => {
    console.log("IM HERE");
    setValue(true);
  };
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}
export default HoverCell;
