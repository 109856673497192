import React from "react";
import { useAuth0 } from "../react-auth0-spa";

const Sorry = () => {
  const { logout } = useAuth0();
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <div>
      <div class="container mx-auto leading-normal tracking-normal">
        <section class="text-gray-700 body-font">
          <div class="container mx-auto flex py-20 md:flex-row flex-col-reverse items-center">
            <div class="lg:flex-grow md:w-1/2 lg:pr-24 px-10 md:px-0 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
              <h1 class="title-font sm:text-4xl py-4 xs:py-0 text-3xl mb-4 font-medium text-gray-900">
                Sorry!
              </h1>
              <p class="mb-8 leading-relaxed">We've checked and you're not on the beta list yet.</p>
              <p class="mb-8 leading-relaxed">
                Fill in this questionnaire and we'll onboard you as soon as places become available.
              </p>
              <p class="mb-8 leading-relaxed">
                <button class="focus:outline-none w-full text-sm bg-indigo-900 shadow hover:bg-purple-700 hover:text-white rounded-md text-white py-4 pl-4 pr-4">
                  <a
                    href="https://wing309989.typeform.com/to/YTu828PL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign me up!
                  </a>
                </button>
              </p>
              <p class="mb-8 leading-relaxed">
                If you are having any problems, feel free to email us on hello@sourceful.io.
              </p>
              <p>
                <button
                  onClick={() => logoutWithRedirect({})}
                  class="text-sm hover:underline cursor-pointer"
                >
                  Log out for now
                </button>
              </p>
            </div>
            <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 xs:mb-0">
              <img
                class="object-cover mx-auto xs:mx-none xs:float-right object-center rounded"
                alt="hero"
                src="https://images.unsplash.com/photo-1541364983171-a8ba01e95cfc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Sorry;
