import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import TitleHeading from "../../components/TitleHeading";
import SimpleSelect from "../../components/forms/SimpleSelect";
import SearchBar from "../../components/search/SearchBar";
import { useAuth0 } from "../../react-auth0-spa";

const ADD_PRODUCT_PRICING = gql`
  mutation AddProductPricing(
    $product_id: Int!
    $currency_code: String!
    $min_qty: Int!
    $user_id: String!
    $unit_price: numeric!
    $notes: String!
    $product_pricing_reference: String!
  ) {
    insert_inventory_product_pricing_one(
      object: {
        product_id: $product_id
        user_id: $user_id
        product_pricing_reference: $product_pricing_reference
        currency_code: $currency_code
        min_qty: $min_qty
        unit_price: $unit_price
        notes: $notes
        folder: { data: { user_id: $user_id } }
      }
    ) {
      id
    }
  }
`;

const CURRENCY_CODES = gql`
  query CurrencyCodes {
    inventory_currency {
      id: currency_code
      name: currency_code
    }
  }
`;

const PRODUCTS = gql`
  query Products {
    inventory_product {
      base_product {
        base_product_name
      }
      id
      case_qty
    }
  }
`;

const AddProductPricing = () => {
  const [inputs, setInputs] = useState({ notes: "" });
  const [successCode, setSuccessCode] = useState(0);
  const [addProductPricing, { loading: mutationLoading, error: mutationError }] = useMutation(
    ADD_PRODUCT_PRICING,
    {
      onCompleted() {
        setSuccessCode(200);
      },
      onError() {
        setSuccessCode(0);
      },
    }
  );

  const { loading: loading_currencies, error: error_currencies, data: currencies } = useQuery(
    CURRENCY_CODES,
    {
      fetchPolicy: "network-only",
    }
  );

  const {
    loading: loading_products,
    error: error_products,
    data: products,
    refetch: refreshProducts,
  } = useQuery(PRODUCTS, {
    fetchPolicy: "network-only",
  });

  const transformProducts = products => {
    console.log(products);
    return products
      ? products.map(p => {
          return { id: p.id, name: p.base_product.base_product_name + " (" + p.case_qty + ")" };
        })
      : [];
  };

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const resetForm = event => {
    if (event) {
      event.preventDefault();
    }
    setInputs({
      name: "",
    });
    setSuccessCode(0);
  };

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    addProductPricing({
      variables: {
        currency_code: inputs.currency_code,
        product_id: inputs.product_id,
        min_qty: inputs.min_qty,
        unit_price: inputs.unit_price,
        notes: inputs.notes,
        product_pricing_reference: inputs.product_pricing_reference,
        user_id: user_id,
      },
    });
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (currencies == null || loading_currencies) return null;
  if (products == null || loading_products) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Sales"
          selectedSubMenu="add-product-pricings"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Sales"
          selectedSubMenu="add-product-pricings"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Product Pricings &gt; Add a new Product Pricings" />

                  {successCode === 200 && (
                    <div
                      className="p-2 bg-green-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Success
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        The Product Pricings was added successfully.
                      </span>
                    </div>
                  )}
                  {mutationError && (
                    <div
                      className="p-2 bg-red-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        Error
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        There was a problem with the data - please check.
                      </span>
                    </div>
                  )}
                </div>
                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter a Product Pricing Reference (leave blank for auto-generation)"
                        value_ref={inputs.product_pricing_reference}
                        required={true}
                        change_handler={handleInputChange}
                        label="Product Pricing Reference"
                        id_ref="product_pricing_reference"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select the Currency"
                        change_handler={handleInputChange}
                        label="Currency"
                        options={currencies.inventory_currency}
                        id_ref="currency_code"
                        required
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select the Product"
                        change_handler={handleInputChange}
                        label="Product"
                        refresh_handler={refreshProducts}
                        add_url="/products/add"
                        options={transformProducts(products.inventory_product)}
                        id_ref="product_id"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter min case qty"
                        value_ref={inputs.min_qty}
                        required={true}
                        change_handler={handleInputChange}
                        label="min qty"
                        id_ref="min_qty"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter price per unit"
                        value_ref={inputs.unit_price}
                        required={true}
                        change_handler={handleInputChange}
                        label="unit price"
                        id_ref="unit_price"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter notes"
                        value_ref={inputs.notes}
                        change_handler={handleInputChange}
                        label="notes"
                        id_ref="notes"
                      />
                    </div>

                    {successCode !== 200 && (
                      <button
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                      >
                        Add
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductPricing;
