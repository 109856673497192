import { gql } from "@apollo/client";
import { ClientDetailQuery } from "../../generated/graphql";
import { ArrayElement } from "../../types/ArrayElement";

export type ClientDetailAccount = ArrayElement<ClientDetailQuery["inventory_account"]>;

export type ClientDetailAccountLocation = ArrayElement<ClientDetailAccount["account_locations"]>;

const CLIENT_DETAIL_QUERY = gql`
  query ClientDetail($client_id: Int!) {
    inventory_account(where: { id: { _eq: $client_id } }) {
      id
      local_name
      account_description
      website
      logo_url
      email
      telephone_number
      account_sector {
        account_sector_name
      }
      account_roles {
        role {
          role_name
          id
        }
      }
      account_locations(where: { parent_location_id: { _is_null: true } }) {
        id
        name: location_name
        parent_location_id
        child_locations {
          name: location_name
          address {
            name
          }
          child_locations {
            name: location_name
            address {
              name
            }
            child_locations {
              name: location_name
              address {
                name
              }
            }
          }
        }
      }
    }
    referrals: inventory_account_referral(where: { referrer_account_id: { _eq: $client_id } }) {
      account {
        local_name
      }
    }
    referrers: inventory_account_referral(where: { account_id: { _eq: $client_id } }) {
      referrerAccountId {
        local_name
      }
    }
  }
`;

export default CLIENT_DETAIL_QUERY;
