import React, { useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import SideMenu from "../../components/SideMenu";
import SimpleTextInput from "../../components/forms/SimpleTextInput";
import TitleHeading from "../../components/TitleHeading";
import SimpleSelect from "../../components/forms/SimpleSelect";
import SearchBar from "../../components/search/SearchBar";
import { useAuth0 } from "../../react-auth0-spa";
import MutationError from "../../components/MutationError";
import MutationSuccess from "../../components/MutationSuccess";

const ADD_SUPPLIER = gql`
  mutation AddSupplier(
    $user_id: String!
    $local_name: String!
    $locale: String!
    $international_name: String!
    $acquisition_channel: String!
    $account_description: String!
    $email: String = ""
    $telephone_number: String = ""
    $website: String = ""
    $logo_url: String = ""
  ) {
    insert_inventory_account_one(
      object: {
        user_id: $user_id
        locale: $locale
        local_name: $local_name
        acquisition_channel: $acquisition_channel
        international_name: $international_name
        account_roles: { data: { role_id: 2 } }
        account_status_id: 1
        account_description: $account_description
        email: $email
        telephone_number: $telephone_number
        website: $website
        logo_url: $logo_url
      }
    ) {
      id
    }
  }
`;

const LOCALES = gql`
  query GetLocales {
    inventory_locale {
      id: locale
      name: locale
    }
  }
`;

const AddSupplier = () => {
  const [inputs, setInputs] = useState({ international_name: "" });
  const [successCode, setSuccessCode] = useState(0);
  const [addSupplier, { error: mutationError }] = useMutation(ADD_SUPPLIER, {
    onCompleted() {
      setSuccessCode(200);
    },
    onError() {
      setSuccessCode(0);
    },
  });

  const {
    loading: loadingLocales,
    error: errorLocales,
    data: locales,
    refetch: refreshLocale,
  } = useQuery(LOCALES, {
    fetchPolicy: "network-only",
  });

  const handleInputChange = event => {
    event.persist();
    console.log(inputs);

    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }
    await addSupplier({
      variables: {
        local_name: inputs.local_name,
        account_description: inputs.supplier_description,
        user_id: user_id,
        international_name:
          inputs.international_name === "" ? inputs.local_name : inputs.international_name,
        acquisition_channel: inputs.acquisition_channel,
        locale: inputs.locale,
        email: inputs.email,
        telephone_number: inputs.telephone_number,
        website: inputs.website,
        logo_url: inputs.logo_url,
      },
    });

    console.log("mutation error", mutationError);
  };

  const { user } = useAuth0();
  const user_id = user ? user.sub : null;

  if (loadingLocales || locales === null) return null;

  return (
    <div className="bg-gray-100 bg-opacity-25">
      <div className="hidden xl:block">
        <SideMenu
          menuOpen={true}
          layout={"large"}
          selectedMainMenu="Suppliers"
          selectedSubMenu="add-supplier"
        />
      </div>
      <div className="block xl:hidden">
        <SideMenu
          menuOpen={false}
          layout={"mobile"}
          selectedMainMenu="Suppliers"
          selectedSubMenu="add-supplier"
        />
      </div>

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />
            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Supplier &gt; Add a new Supplier" />
                  {successCode === 200 && (
                    <MutationSuccess message="The Supplier was added successfully." />
                  )}
                  {mutationError && <MutationError error={mutationError} />}
                </div>
                <form className="w-full flex flex-col lg:flex-row" onSubmit={handleSubmit}>
                  <div className="w-full lg:w-1/2 p-4 lg:p-0">
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Enter the Supplier Name (Local)"
                        value_ref={inputs.local_name}
                        required={true}
                        change_handler={handleInputChange}
                        label="Supplier Name (Local)"
                        id_ref="local_name"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleSelect
                        placeholder="Select Locale for the Supplier"
                        options={locales.inventory_locale}
                        add_url="/locales/add"
                        selected_id={inputs.supplier_status_id}
                        required
                        change_handler={handleInputChange}
                        refresh_handler={refreshLocale}
                        label="Locale"
                        id_ref="locale"
                        name_func={option => {
                          return option.name;
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Leave blank if same in English"
                        value_ref={inputs.international_name}
                        change_handler={handleInputChange}
                        label="Supplier Name (International)"
                        id_ref="international_name"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="How did you find this supplier"
                        value_ref={inputs.acquisition_channel}
                        required
                        change_handler={handleInputChange}
                        label="Acquisition Channel"
                        id_ref="acquisition_channel"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Supplier Description"
                        value_ref={inputs.supplier_description}
                        required
                        change_handler={handleInputChange}
                        label="Supplier Description"
                        id_ref="supplier_description"
                      />
                    </div>

                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for email address */}
                      <SimpleTextInput
                        placeholder="Supplier Email Address"
                        value_ref={inputs.email}
                        change_handler={handleInputChange}
                        label="Supplier Email Address"
                        id_ref="email"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for phone number */}
                      <SimpleTextInput
                        placeholder="Supplier Phone Number"
                        value_ref={inputs.telephone_number}
                        change_handler={handleInputChange}
                        label="Supplier Phone Number"
                        id_ref="telephone_number"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      {/* TODO: Create a new Form type to accommodate for links */}
                      <SimpleTextInput
                        placeholder="Supplier Website"
                        value_ref={inputs.website}
                        change_handler={handleInputChange}
                        label="Supplier Website"
                        id_ref="website"
                      />
                    </div>
                    <div className="flex flex-wrap mb-2">
                      <SimpleTextInput
                        placeholder="Supplier Logo URL"
                        value_ref={inputs.logo_url}
                        change_handler={handleInputChange}
                        label="Supplier Logo URL"
                        id_ref="logo_url"
                      />
                    </div>

                    {successCode !== 200 && (
                      <button
                        className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                        type="submit"
                      >
                        Add Supplier
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSupplier;
