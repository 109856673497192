import React from "react";
import { OptionProps } from "react-select";
import { ClientOrderLineQueryItem } from "../../graphql/queries/clientOrderLines";
import { ClientOrderLineSelectItem } from "../../types/ClientOrderLineOption";

interface OptionInfoItemProps {
  label: string;
  value?: string | number | null;
  isFocused?: boolean;
}

const InfoItem = ({ label, value, isFocused }: OptionInfoItemProps) => {
  return (
    <div>
      <div className={`text-xs  ${isFocused ? "text-white" : "text-gray-700"}`}>{label}</div>
      <div className={`text-sm`}>{value}</div>
    </div>
  );
};

interface OrderLineSummaryProps {
  order_line: ClientOrderLineQueryItem;
  isFocused?: boolean;
  innerProps?: OptionProps<ClientOrderLineSelectItem, false>["innerProps"];
}

const OrderLineSummary = ({ order_line, innerProps, isFocused }: OrderLineSummaryProps) => {
  return (
    <div
      {...innerProps}
      className={`grid grid-cols-4 grid-rows-2 gap-2 p-3 ${isFocused && "bg-blue-600 text-white"}`}
    >
      <img
        width={60}
        className="row-span-2 object-contain"
        src={order_line.product?.base_product.image_url}
        alt={`${order_line.product?.base_product.base_product_name}`}
      />
      <InfoItem
        label="Product"
        value={order_line.product?.base_product.base_product_name}
        isFocused={isFocused}
      />
      <InfoItem
        label="Client"
        value={order_line.client_order.client_account.international_name}
        isFocused={isFocused}
      />
      <InfoItem label="Order Id" value={order_line.client_order_id} isFocused={isFocused} />
      <InfoItem label="Case Qty" value={order_line.product?.case_qty} isFocused={isFocused} />
      <InfoItem label="Requested Qty" value={order_line.requested_qty} isFocused={isFocused} />
      <InfoItem label="Outstanding Qty" value={order_line.outstanding_qty} isFocused={isFocused} />
    </div>
  );
};

export default OrderLineSummary;
