import React from "react";
import { useQuery, gql } from "@apollo/client";
import SimpleLinkButton from "../../components/SimpleLinkButton";
import TitleHeading from "../../components/TitleHeading";
import { Link } from "react-router-dom";
import StatusBadge from "../../components/StatusBadge";
import SearchBar from "../../components/search/SearchBar";
import Table, { TableHead, TD, TR } from "../../components/Table";
import MenuSection from "../../components/layout/MenuSection";

const CLIENTS = gql`
  query GetClients {
    inventory_account(where: { account_roles: { role: { id: { _eq: 3 } } } }) {
      id
      local_name
      logo_url
      website
      account_description
      account_status {
        account_status_name
      }
      account_roles {
        role {
          role_name
        }
      }
    }
  }
`;

const Clients = () => {
  const { loading, error, data } = useQuery(CLIENTS, {
    fetchPolicy: "network-only",
  });

  const openInNewTab = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const updateClient = client_id => event => {
    event.preventDefault();
    openInNewTab(`client/edit/${client_id}`);
  };

  if (data == null || loading) return null;

  return (
    <>
      <MenuSection selectedMainMenu="Clients" selectedSubMenu="all-clients" />

      <div className="flex flex-row">
        <div className="xl:w-3/12 hidden xl:block">&nbsp;</div>
        <div className="xl:w-9/12 w-full">
          <div className="container max-w-screen-xl lg:pr-8 mt-20 xl:mt-0 leading-normal tracking-normal overflow-x-hidden overflow-y-hidden">
            <SearchBar />

            <div className="flex pt-4 mb-4">
              <div className="w-full h-auto  ml-2">
                <div className="fixed xl:relative xl:top-auto top-0 pt-8 pb-4 xl:pl-0 w-full bg-white xl:bg-transparent z-50 xl:left-auto left-0 xl:ml-0 pl-16 border-b xl:border-none">
                  <TitleHeading label="Clients" />
                  <SimpleLinkButton
                    label="Add Client"
                    keyboard_shortcut="n"
                    new_tab
                    url="/clients/add"
                    id_ref="add_client"
                  />
                  <SimpleLinkButton
                    label="Add Client Order"
                    keyboard_shortcut="c"
                    new_tab
                    url="/client-orders/add"
                    id_ref="add_client_order"
                  />
                  <SimpleLinkButton
                    label="Add Product Pricing"
                    keyboard_shortcut="q"
                    new_tab
                    url="/product-pricings/add"
                    id_ref="add_product_pricing"
                  />
                </div>
                <div className="h-full">
                  <div className="flex my-20 xl:my-4 shadow rounded-lg flex-row flex-wrap overflow-hidden">
                    <Table>
                      <TableHead>
                        <th className="p-4 m-0">id</th>
                        <th className="p-4 m-0">logo</th>
                        <th className="p-4 m-0">name</th>
                        <th className="p-4 m-0">description</th>
                        <th className="p-4 m-0">roles</th>
                        <th className="p-4 m-0">status</th>
                      </TableHead>
                      <tbody>
                        {data.inventory_account.map(inventory_account => {
                          return (
                            <TR key={inventory_account.id}>
                              <TD width={1}>{inventory_account.id}</TD>
                              <TD width={1}>
                                {inventory_account.logo_url ? (
                                  inventory_account.website ? (
                                    <a href={inventory_account.website}>
                                      <img
                                        style={{
                                          minWidth: "25px",
                                          width: "25px",
                                          height: "25px",
                                        }}
                                        src={inventory_account.logo_url}
                                        alt="client-logo"
                                      />
                                    </a>
                                  ) : (
                                    <img
                                      style={{ minWidth: "25px", width: "25px", height: "25px" }}
                                      src={inventory_account.logo_url}
                                      alt="client-logo"
                                    />
                                  )
                                ) : inventory_account.website ? (
                                  <div className="p-1">
                                    <a href={inventory_account.website}>
                                      <i className="far fa-image fa-lg"></i>
                                    </a>
                                  </div>
                                ) : (
                                  <div className="p-1">
                                    <i className="far fa-image fa-lg"></i>
                                  </div>
                                )}
                              </TD>
                              <TD width={2}>
                                <h2 className="font-semibold text-blue-700 product-result-truncate">
                                  <Link to={`/client/${inventory_account.id}`}>
                                    {inventory_account.local_name}
                                  </Link>
                                </h2>
                              </TD>
                              <TD width={3}>{inventory_account.account_description}</TD>
                              <TD width={3}>
                                <span class="text-xs">
                                  {inventory_account.account_roles
                                    .map(account_role => {
                                      return account_role.role.role_name;
                                    })
                                    .join(", ")}
                                </span>
                              </TD>
                              <TD width={3}>
                                <StatusBadge
                                  label={inventory_account.account_status.account_status_name}
                                />
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="float-right"
                                  to={`/meetings/add/${inventory_account.id}`}
                                >
                                  <img
                                    width="18px"
                                    src="/calendar-outline.svg"
                                    alt="calendar-icon"
                                  />
                                </Link>
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="float-right"
                                  to={`/client/edit/${inventory_account.id}`}
                                >
                                  <img width="18px" src="/edit-outline.svg" alt="edit-icon" />
                                </Link>
                              </TD>
                            </TR>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
